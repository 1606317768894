import { convertIsoToLocaleString, filterDateFormatter, makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import {
    Button,
    Col,
    ColumnProps,
    DeleteOutlined,
    EditOutlined,
    Row,
    SettingOutlined,
    Table,
    Tooltip,
    Tag,
    Modal,
    Switch,
    Form,
    DatePicker,
    message,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { saveFiltersData } from '@store/filters'
import { useDispatch, useSelector } from 'react-redux'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import RenderSearchFilters from '@library/components/search-filter'
import EditAndCreateDrawer from './editAndCreateDrawer'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getUserData, getMerchantDetails } from '@store/user/selectors'

export default function () {
    const userDetails = useSelector(getUserData)
    const merchant_details = useSelector(getMerchantDetails)

    const [total, setTotal] = useState(0)
    const [experimentData, setExperimentData] = useState([])
    const disptach = useDispatch()
    const [isModalOpen, setModalOpen] = useState(false)
    const [merchantList, setMerchantList] = useState([])
    const [action, setAction] = useState('create_exp')
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [exposure, setExposure] = useState(0)
    const [showDatePopup, setShowDatePopup] = useState(false)
    const [experimentToActivate, setExperimentToActivate] = useState({
        experiment_id: '',
        is_active: '',
        end_date: '',
        start_date: '',
    })
    const [experimentToDelete, setExperimentToDelete] = useState({
        experiment_id: '',
        experiment_name: '',
    })
    const [experiment, setExperiment] = useState<any>({
        name: '',
        description: '',
        start_date: '',
        end_date: '',
        is_active: '',
        merchants: [],
        exposure_percentage: '',
        experiment_id: '',
    })
    const [variantsList, setVariantList] = useState([])
    const [variantData, setVariantData] = useState({
        name: '',
        description: '',
        variant_percentage: 0,
        ip_addresses: [],
    })
    const [parameters, setParameters] = useState<any>({
        page: 1,
        sortKey: 'created_at',
        sortOrder: 25,
        pageSize: 25,
    })

    const handleEndDateChange = (date) => {
        setExperimentToActivate((prev) => ({
            ...prev,
            end_date: date ? (dayjs(date) as unknown as string) : null,
        }))
    }

    const toggleStatus = async (index) => {
        const updatedData = [...experimentData]

        setExperimentToActivate({
            experiment_id: updatedData[index].id,
            is_active: updatedData[index].is_active,
            end_date: updatedData[index].end_date,
            start_date: updatedData[index].start_date,
        })
        if (updatedData[index].is_active === true) {
            const payload = {
                start_date: updatedData[index].start_date,
                is_active: false,
            }
            const response = await makeAPICall({
                method: 'patch',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.experimentsEndpoint + `/${updatedData[index].id}`,
                payload,
            })
            if (response?.success) {
                console.log('[EXPERIMENT UPDATED]', response.data.message || 'Experiment updated successfully')
                message.success('Experiment disabled successfully')
            }

            form.resetFields()

            makeExperimentsListCall({ ...parameters })
        } else {
            setShowDatePopup(true)
        }
    }

    const fetchMerchantList = async () => {
        const respnse = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.getMerchantList,
        })
        setMerchantList(respnse?.data?.data)
    }

    const handleDelete = async (experimentId) => {
        if (!experimentId) {
            console.error('Delete Error: No discount id')
            setShowDeleteModal(false)
            return
        }
        try {
            let response = await makeAPICall({
                method: 'delete',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.experimentsEndpoint + `/${experimentId}`,
            })
            makeExperimentsListCall({ ...parameters })
            setShowDeleteModal(false)
            if (response?.data?.status_code === 200) {
                console.log('Experiment deleted successfully')
                message.success('Experiment deleted successfully')
            } else {
                console.error('Delete Error: Something went wrong')
            }
        } catch (err) {
            console.warn(err)
        }
    }

    const handleDeleteExperiment = async (selectedExperiment) => {
        setExperimentToDelete({
            experiment_id: selectedExperiment?.id,
            experiment_name: selectedExperiment?.name,
        })
        setShowDeleteModal(true)
    }

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'A/B Testing',
                href: '/general/ab-testing',
                text: 'A/B Testing',
            },
        ])
        fetchMerchantList()
        makeExperimentsListCall(parameters)
        logEvent(
            'admin_a/b_clicked',
            'click',
            'Admin A/B Testing',
            userDetails?.userDetails?.email,
            merchant_details?.m_id,
            merchant_details?.short_name,
            userDetails?.userDetails?.name,
        )
    }, [])

    const prefillExperimentDetails = (selectedExperiment) => {
        setExperiment({
            name: selectedExperiment.name,
            description: selectedExperiment.description,
            start_date: selectedExperiment.start_date,
            end_date: selectedExperiment.end_date,
            is_active: selectedExperiment.is_active,
            merchants:
                selectedExperiment.filters[0]?.conditions[0]?.value?.length === 0 ||
                selectedExperiment.filters.length < 1
                    ? ['all']
                    : selectedExperiment.filters[0]?.conditions[0]?.value?.filter((value) => value.length > 0),
            exposure_percentage: selectedExperiment.exposure_percentage,
            experiment_id: selectedExperiment.id,
        })
    }

    const prefillVariantDetails = (selectedVariants) => {
        let variantsArray = []

        selectedVariants?.forEach((variant) => {
            setExposure((prev) => prev + variant.variant_percentage)
            variantsArray.push({
                name: variant.name,
                description: variant.description,
                variant_percentage: variant.variant_percentage,
                ip_addresses:
                    variant?.forced_assignment_rules[0]?.conditions[0]?.value?.filter((value) => value.length > 0) ??
                    [],
            })
        })
        setVariantList(variantsArray)
    }

    const fetchExperimentData = async (experiment_id) => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.experimentsEndpoint + `/${experiment_id}`,
            })

            if (response.data?.data?.experiment) {
                prefillExperimentDetails(response.data?.data?.experiment)
            }

            if (response.data?.data?.variants) {
                prefillVariantDetails(response.data?.data?.variants)
            }
        } catch (err) {
            console.warn(err)
        }
    }

    const columns: ColumnProps<any>[] = [
        {
            title: 'Experiment Name',
            dataIndex: 'name',
            width: 40,
            render: (text) => (
                <Tooltip title={text}>
                    <span className='truncate text-ellipsis max-w-60 overflow-hidden whitespace-nowrap inline-block'>
                        {text}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            width: 30,
            render: (status) => (
                <div>
                    <>
                        {status ? (
                            <Tag
                                variant={'success'}
                                className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                            >
                                Active
                            </Tag>
                        ) : (
                            <Tag
                                variant={'error'}
                                className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                            >
                                Disabled
                            </Tag>
                        )}
                    </>
                </div>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: 50,
            render: (text) => (
                <Tooltip title={text}>
                    <span className='truncate text-ellipsis max-w-60 overflow-hidden whitespace-nowrap inline-block'>
                        {text}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            width: 40,
            render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            width: 40,
            render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            defaultSortOrder: 'descend',
            sorter: (a, b) => dayjs(a.created_at).unix() - dayjs(b.created_at).unix(),
            width: 30,
            render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        },
        {
            title: 'Updated At',
            dataIndex: 'updated_at',
            width: 30,
            render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
        },
        {
            title: 'Concluded',
            dataIndex: 'is_concluded',
            width: 30,
            render: (t) => <span>{t ? 'True' : 'False'}</span>,
        },
        {
            title: 'Exposure',
            dataIndex: 'exposure_percentage',
            width: 30,
            render: (t) => <span>{t}%</span>,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: 20,
            fixed: 'right',
            render: (_, record, index) => (
                <span className='text-lg'>
                    <Tooltip title={'Edit Experiment'}>
                        <EditOutlined
                            className='px-2 text-blue-500 cursor-pointer'
                            onClick={() => {
                                setAction('update_exp')
                                fetchExperimentData(record?.id)
                                setModalOpen(true)
                            }}
                        />
                    </Tooltip>
                    <Tooltip title={'Delete Experiment'}>
                        <DeleteOutlined
                            className='px-2 text-red-500 cursor-pointer'
                            onClick={() => handleDeleteExperiment(record)}
                        />
                    </Tooltip>
                    <Tooltip title={record?.is_active ? 'Active' : 'Disabled'}>
                        <Switch
                            checked={record?.is_active}
                            onClick={async () => await toggleStatus(index)}
                            size='small'
                        />
                    </Tooltip>
                </span>
            ),
        },
    ]

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
        makeExperimentsListCall({ ...parameters, page: current, pageSize })
    }

    async function makeExperimentsListCall(parameters) {
        if (!parameters.page) return
        disptach<any>(saveFiltersData('ab-testing', { ...parameters }))
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.experimentsEndpoint,
                params: {
                    ...parameters,
                },
            })

            if (response.data?.data?.experiments?.length > 0) {
                setExperimentData(response.data?.data?.experiments)
                setTotal(response.data?.data?.total_count)
            } else {
                setExperimentData([])
                setTotal(0)
            }
        } catch (err) {
            console.warn(err)
        }
    }

    const createExperiment = async (payload) => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.experimentsEndpoint,
            payload,
        })
        if (response?.success) {
            console.log('[EXPERIMENT CREATED]', response.data.message || 'Experiment created successfully')
        }

        makeExperimentsListCall({ ...parameters })
    }

    const updateExperiment = async (experiment_id, payload) => {
        const response = await makeAPICall({
            method: 'patch',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.experimentsEndpoint + `/${experiment_id}`,
            payload,
        })
        if (response?.success) {
            console.log('[EXPERIMENT UPDATED]', response.data.message || 'Experiment updated successfully')
        }

        makeExperimentsListCall({ ...parameters })
    }

    const reset = (e) => {
        e.preventDefault()
        const newParameters = {
            page: 1,
            sortKey: 'created_at',
            sortOrder: 25,
            pageSize: 25,
        }
        setParameters(newParameters)
        makeExperimentsListCall({ ...newParameters })
    }

    const handleSearchClick = (e) => {
        e?.preventDefault()
        const newParameters = {
            ...parameters,
            page: 1,
        }
        setParameters(newParameters)
        makeExperimentsListCall({ ...newParameters })
    }

    const handleExperimentActivated = async () => {
        const payload = {
            end_date: experimentToActivate.end_date,
            is_active: true,
        }

        const response = await makeAPICall({
            method: 'patch',
            url:
                process.env.REACT_APP_BASE_URL +
                APIEndPoints.experimentsEndpoint +
                `/${experimentToActivate.experiment_id}`,
            payload,
        })
        if (response?.success) {
            console.log('[EXPERIMENT UPDATED]', response.data.message || 'Experiment updated successfully')
            message.success('Experiment actived successfully')
        }
        form.resetFields()

        makeExperimentsListCall({ ...parameters })

        setShowDatePopup(false)
    }

    const [form] = Form.useForm()

    return (
        <>
            <Modal
                open={showDeleteModal}
                onCancel={() => {
                    setShowDeleteModal(false)
                    setExperimentToDelete({ experiment_id: '', experiment_name: '' })
                }}
                okText='Yes'
                cancelText='Cancel'
                title={
                    <div className='flex'>
                        <p>Are you sure you want to delete this Experiment ?</p>
                    </div>
                }
                centered
                onOk={() => {
                    handleDelete(experimentToDelete.experiment_id)
                }}
            >
                <div className='flex gap-2 mt-2'>
                    <span className='font-semibold'>Name :</span>
                    <div>{experimentToDelete?.experiment_name}</div>
                </div>
            </Modal>
            <Modal
                open={showDatePopup}
                onCancel={() => {
                    form.resetFields()
                    setShowDatePopup(false)
                }}
                title={
                    <div className='flex'>
                        <p>Add an end date for selected experiment </p>
                    </div>
                }
                onOk={async () => {
                    await form.validateFields()
                    handleExperimentActivated()
                }}
                centered
            >
                <div className='flex gap-2 mt-2'>
                    <Form
                        layout='horizontal'
                        className='h-full w-full'
                        onFinish={() => {}}
                        onFinishFailed={() => {}}
                        form={form}
                    >
                        <Form.Item
                            label='End Date'
                            name='end_date'
                            className='mt-5 w-[50%]'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter end date',
                                },
                            ]}
                        >
                            <DatePicker
                                placeholder={'End Date'}
                                value={dayjs(experimentToActivate.end_date)}
                                onChange={handleEndDateChange}
                                disabledDate={(current) => {
                                    return current && current < dayjs().startOf('day')
                                }}
                                allowClear={false}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <div className='w-full'>
                <div className='overflow-auto w-full dashboard-reports'>
                    <div className='bg-white'>
                        <>
                            <Row className='p-2'>
                                <Col sm={12} className='flex items-center gap-x-2 '>
                                    <Row className='text-black inter m-0 tracking-wide items-center'>
                                        <Tooltip
                                            title={
                                                'Schedule notifications for overall dashboard users, displayed at the top until specified expiration.'
                                            }
                                        ></Tooltip>

                                        <span className='ml-2'>Create Experiment for the A/B Testing.</span>
                                    </Row>
                                </Col>
                                <Col className='flex flex-grow justify-end gap-x-2'>
                                    <Button
                                        variant='primary'
                                        className='flex align-center items-center'
                                        onClick={() => {
                                            setAction('create_exp')
                                            setModalOpen(true)
                                        }}
                                    >
                                        <SettingOutlined width={16} color='white' />
                                        <span className='ml-2 text-white'>Create New Experiment</span>
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    </div>
                </div>

                <div className='my-4'>
                    <Row className='bg-white rounded'>
                        <RenderSearchFilters
                            values={parameters}
                            setValues={(data, reset) =>
                                setParameters((prev: any) => (reset ? { ...data } : { ...prev, ...data }))
                            }
                            page='ab-testing'
                            onSearch={handleSearchClick}
                            onReset={reset}
                        />
                    </Row>
                </div>

                <div className='mt-5 bg-white rounded overflow-clip w-full'>
                    <Table
                        columns={columns}
                        dataSource={experimentData || []}
                        pagination={{
                            current: parameters.page, // Current page number
                            pageSize: parameters.pageSize, // Number of items to display per page
                            total: total, // Total number of items in the data array
                            showSizeChanger: false,
                            onChange: handlePaginationChange,
                            position: ['topLeft', 'bottomLeft'],
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                        }}
                        scroll={{ x: 2050 }}
                    />
                </div>
                {isModalOpen && (
                    <EditAndCreateDrawer
                        isModalOpen={isModalOpen}
                        setModalOpen={setModalOpen}
                        action={action}
                        experiment={experiment}
                        setExperiment={setExperiment}
                        merchantList={merchantList}
                        createExperiment={createExperiment}
                        variantsList={variantsList}
                        setVariantList={setVariantList}
                        variantData={variantData}
                        setVariantData={setVariantData}
                        exposure={exposure}
                        setExposure={setExposure}
                        updateExperiment={updateExperiment}
                    />
                )}
            </div>
        </>
    )
}
