import { RootStoreType } from '..'

export const getSignUpEmail = (state: RootStoreType) => state.user.signup.email
export const getUserDetails = (state: RootStoreType) => state.user.userDetails
export const getMerchantDetails = (state: RootStoreType) => state.user.merchantDetails
export const getMerchantPlatform = (state: RootStoreType) => state.user.merchantDetails.platform
export const isMerchantSelector = (state: RootStoreType) => !state.user.userDetails.is_internal_user
export const getMode = (state: RootStoreType) => state.user.mode
export const getPermissions = (state: RootStoreType) => state.user?.userDetails?.role?.permissions
export const getMerchantConfigs = (state: RootStoreType) => state.user.config
export const getUserData = (state: RootStoreType) => state.user
export const getMerchantConfig = (key: string) => (state: RootStoreType) => state.user.config?.[key]
export const getNotificationData = (state: RootStoreType) => state.user.notification
export const getCampaignData = (state: RootStoreType) => state.user.campaignData
export const getApprovalStatus = (state: RootStoreType) => state.user.approvalStatus
