import { EditOutlined } from '@ant-design/icons'
import RenderSearchFilters from '@library/components/search-filter'
import { saveFiltersData } from '@store/filters'
import { previousInvitedUsersFilters } from '@store/filters/selectors'
import { Button, Col, ColumnProps, Row, Table, Tag, Tooltip, message } from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { handleError } from '@library/utilities/helpers/handleError'

const today = dayjs()

export default function ({ merchantList, isMerchantUser }) {
    const prevFilters = useSelector(previousInvitedUsersFilters)
    const [parameters, setParameters] = useState<any>({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,

        ...prevFilters,
    })

    const [total, setTotal] = useState(0)
    const [invitedUsersData, setInvitedUsersData] = useState([])
    const disptach = useDispatch()

    function DateChange(data) {
        if (new Date(data).getFullYear() == 1) {
            return ''
        }

        if (data) {
            return `${new Date(data).toLocaleString().split(',')[0]}
  
            ${new Date(data).toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true,
            })}`
        } else return ''
    }

    function titleCase(str) {
        var splitStr = str?.toLowerCase().split(' ')
        for (var i = 0; i < splitStr?.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i]?.charAt(0)?.toUpperCase() + splitStr[i]?.substring(1)
        }
        // Directly return the joined string
        return splitStr?.join(' ')
    }

    const getMerchantName = (merchant_id) => {
        const result = merchantList.find((item) => item.id === merchant_id)
        if (result) {
            return result.business_name
        } else {
            return '-'
        }
    }

    const columns: ColumnProps<any>[] = [
        {
            title: <span>Full Name</span>,
            dataIndex: 'name',
            width: 45,
            ellipsis: true,
            render: (text) => <span>{`${titleCase(text)}`}</span>,
        },
        {
            title: <span>Email</span>,
            dataIndex: 'email',
            width: 35,
            ellipsis: true,
            render: (text: string) => (
                <Tooltip title={text} placement='topLeft'>
                    {text}
                </Tooltip>
            ),
        },

        {
            title: <span>Account</span>,
            dataIndex: 'merchant_id',
            width: 20,
            render: (text: string) => <span>{`${getMerchantName(text)}`}</span>,
            align: 'left',
        },

        {
            title: <span>Role</span>,
            dataIndex: 'role_name',
            width: 20,
            render: (text: string) => <span>{text}</span>,
            align: 'left',
        },

        {
            title: <span>Status</span>,
            dataIndex: 'status',
            width: 20,
            render: (text: string) => (
                <span>
                    {text === 'expired' && (
                        <Tag
                            variant={'error'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Exipred
                        </Tag>
                    )}

                    {text === 'pending' && (
                        <Tag
                            variant={'warning'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Pending
                        </Tag>
                    )}

                    {text === 'suspended' && (
                        <Tag
                            variant={'success'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Accepted
                        </Tag>
                    )}
                </span>
            ),
            align: 'center',
        },
        {
            title: <span>Created At</span>,
            dataIndex: 'created_at',
            width: 27,
            ellipsis: true,
            render: (text: string) => <span>{DateChange(text)}</span>,
            sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: <span>Updated At</span>,
            dataIndex: 'updated_at',
            render: (text: string) => <span>{DateChange(text)}</span>,
            sorter: (a, b) => (dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? -1 : 1),
            sortDirections: ['ascend', 'descend'],
            width: 27,
        },
        // {
        //     title: <span style={{ color: '#737A87' }}>Actions</span>,
        //     dataIndex: 'id',
        //     width: 20,
        //     render: (id, record) => {
        //         return (
        //             <Row className={`m-0 `}>
        //                 <Tooltip title='Edit User'>
        //                     <EditOutlined
        //                         onClick={() => takeAction(id, record, 'update_user')}
        //                         style={{ color: 'red' }}
        //                         className='ml-4'
        //                     />
        //                 </Tooltip>
        //             </Row>
        //         )
        //     },
        // },
    ]

    async function makeInvitedUsersApiCall(parameters) {
        if (!parameters.page) return

        disptach<any>(saveFiltersData('invited_users', { ...parameters }))

        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getInvitedUser,
                params: {
                    ...parameters,
                    start_dt: parameters.start_dt,
                    end_dt: parameters.end_dt,
                },
            })

            console.log(response, 'users-')

            if (response.data?.data?.length > 0) {
                setInvitedUsersData(response.data?.data)
                setTotal(response.data?.data?.length)
            } else {
                setInvitedUsersData([])
                setTotal(0)
            }
        } catch (error) {
            handleError(error)
        }
    }

    const handleSearchClick = (e) => {
        e?.preventDefault()
        const newParams = { ...parameters, page: 1 }
        setParameters(newParams)
        makeInvitedUsersApiCall({ ...newParams })
    }

    useEffect(() => {
        makeInvitedUsersApiCall(parameters)
    }, [])

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'teams',
                href: '/general/teams',
                text: 'Teams',
            },
        ])
    }, [])

    useEffect(() => {
        if (Object.keys(prevFilters).length && JSON.stringify(prevFilters) !== JSON.stringify(parameters))
            setParameters({ ...prevFilters })
    }, [prevFilters])

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
        makeInvitedUsersApiCall({ ...parameters, page: current, pageSize })
    }

    const reset = (e) => {
        e.preventDefault()
        const newParams = {
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
            st_date: today.format('YYYY-MM-DD'),
            end_date: today.format('YYYY-MM-DD'),
            mid: null,
        }
        setParameters({ ...newParams })
        makeInvitedUsersApiCall(newParams)
    }
    return (
        <>
            <div className='w-full h-full'>
                <Row className='bg-white rounded'>
                    <RenderSearchFilters
                        values={parameters}
                        setValues={(data, reset) =>
                            setParameters((prev: any) => (reset ? { ...data } : { ...prev, ...data }))
                        }
                        page='invited_users'
                        onSearch={handleSearchClick}
                        onReset={reset}
                    />
                </Row>

                <div className='mt-5 bg-white rounded overflow-clip'>
                    <Table
                        columns={columns}
                        dataSource={invitedUsersData || []}
                        pagination={{
                            current: parameters.page, // Current page number
                            pageSize: parameters.pageSize, // Number of items to display per page
                            total: total, // Total number of items in the data array
                            showSizeChanger: false,
                            onChange: handlePaginationChange,
                            position: ['topLeft', 'bottomLeft'],
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                        }}
                        // scroll={{ x: 1150 }}
                    />
                </div>
            </div>
        </>
    )
}
