import React, { useState } from 'react';
import { Card, Button, Tooltip, Tag, DownOutlined, Space } from 'gokwik-ui-kit'
import { CheckOutlined } from 'gokwik-ui-kit';
import { EyeOutlined, UpOutlined } from '@ant-design/icons'
import ActivationModal from './planActivationModal';
import AdvancePlanDrawer from './advancePlanDrawer';
import CodPlusCheckoutDetails from './codPlusCheckoutDetails';
import kwikCheckoutAndCOD from '@library/images/icons/kwikCheckoutAndCOD.png';
import greyStars from '@library/images/icons/greyStars.svg';
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents';
import { getMerchantDetails, getUserDetails } from '@store/user/selectors';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { navigateToUrl } from 'single-spa';

const AdvancePlanCard = ({ updateMerchantOnboardingStatus, showActivationModal, setShowActivationModal }) => {
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)
    const [showDetails, setShowDetails] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [codPlusCheckoutDetailsVisible, setCodPlusCheckoutDetailsVisible] = useState(false);
    const location = useLocation();

    const showCodPlusCheckoutDetails = () => {
        logEvent(
            'know_more_opened',
            'click',
            'get_started',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { plan: 'cod_checkout' }
        )
        setCodPlusCheckoutDetailsVisible(true);
    };

    const showDrawer = () => {
        const isKwikCod = location?.state?.merchantType === 'kwik_cod';
        const eventAction = isKwikCod ? 'upgrade_preview_expanded' : 'preview_opened';
        // Log the event
        logEvent(eventAction,
            'click',
            'get_started',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { plan: 'cod_checkout' });
        setDrawerVisible(true);
    };


    const getStartedClicked = async (selectedPlans) => {
        
        if (location?.state?.merchantType === 'kwik_cod') {
             logEvent(
                'upgraded_to_checkout',
                'click',
                'get_started',
                userDetails?.email,
                merchantDetails?.m_id,
                merchantDetails?.short_name,
                userDetails?.name,
                { plan: 'cod_checkout' }
            )
            // update status to kyc_viewed
            await updateMerchantOnboardingStatus('kyc_viewed', selectedPlans)

        } else {
            logEvent(
                'get_started_clicked',
                'click',
                'get_started',
                userDetails?.email,
                merchantDetails?.m_id,
                merchantDetails?.short_name,
                userDetails?.name,
                { plan: 'cod_checkout' }
            )
            await updateMerchantOnboardingStatus("", selectedPlans)
        }
    };
    const toggleDetails = () => {
        const isKwikCod = location?.state?.merchantType === 'kwik_cod';
        const eventAction = showDetails ? (isKwikCod ? 'upgrade_pricing_collapsed' : 'pricing_closed') : (isKwikCod ? 'upgrade_pricing_expanded' : 'pricing_opened');
        // Common log parameters
        logEvent(
            eventAction,
            'click',
            'get_started',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { plan: 'cod_checkout' }
        )
        // Toggle the details visibility
        setShowDetails(!showDetails);
    };

    return (
        <Card
            bordered
            style={{ borderRadius: 10, border: '0.8px solid rgba(0, 75, 141, 1)', marginTop: "2.7rem", height: 'calc(100% - 70px)', overflow: 'hidden', paddingBottom: '20px' }}
        >
            <div style={{ height: 'calc(100vh - 240px)', flexGrow: 1, overflowY: 'scroll', display: 'flex', flexDirection: "column", justifyContent: "space-between", paddingBottom: "1rem" }}>
                <div>
                    <div  className='flex items-center gap-2 justify-between'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <p className="ant-typography" style={{ fontWeight: 'bold', color: '#1890ff', marginBottom: 0 }}>Advance Plan</p>
                                <p className="ant-typography" style={{ fontSize: '24px', fontWeight: 'bold', margin: 0 }}>COD Suite + Checkout</p>
                                <p>Get a complete solution with COD, prepaid options, and an <button style={{
                                    background: 'none',
                                    border: 'none', 
                                    padding: 0,
                                    fontSize: '13px',
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                }} onClick={showCodPlusCheckoutDetails}> Know more</button></p>
                            </div>
                        </div>
                        <div onClick={showDrawer} style={{ cursor: 'pointer', display: 'inline-block', position: 'relative' }}>
                            <img src={kwikCheckoutAndCOD} alt="Kwikcheckout plus COD Preview" style={{ width: '13rem', height: '9rem'}} />
                            <Button 
                                icon={<EyeOutlined />} 
                                style={{ color: 'rgba(22, 119, 255, 1)', position: 'absolute', bottom: '0.7rem', right: '0.7rem', border: 'none', padding: "0.6rem 0.2" }}>Preview</Button>
                        </div>
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p style={{ fontWeight: 'bold' }}>
                                Pricing <span>(Free COD for 3 Months)</span>
                            </p>
                            <p style={{ cursor: 'pointer' }} onClick={toggleDetails} >
                            {showDetails ? "Hide Details" : "View All Details"}<span style={{ transition: 'transform 0.6s',  transform: 'rotate(180deg)' }}>{showDetails ? <UpOutlined /> : <DownOutlined />}</span>
                            </p>
                        </div>

                        <div
                            style={{
                                maxHeight: showDetails ? '500px' : '0',
                                overflow: 'hidden',
                                transition: 'max-height 0.6s ease',
                                marginTop: showDetails ? '10px' : '0',
                            }}
                        >
                            {showDetails && (
                                <div>
                                    <div style={{ background: "rgba(234, 236, 240, 1)", borderRadius: "10px",  fontSize: "12px" }} >
                                        <div style={{ display: "flex", justifyContent: "space-between", padding: '0.5rem', marginLeft: "5px", fontWeight: "600" }}>
                                            <div>COD Pricing</div>
                                            <Tooltip 
                                                title="The plan is free upto max 3 months and charges post 3 months depends on your shopify plan" 
                                                placement="bottomRight" 
                                                overlayInnerStyle={{ backgroundColor: "white", color: "black", width: 275, borderRadius: '0.5rem', padding: '0.5rem', boxShadow: '1px 1px 1px 1px rgb(102, 112, 133)'}}
                                            >
                                                <span style={{ color: "rgba(102, 112, 133, 1)", cursor: "pointer" }}>
                                                    * What does this mean?
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <div style={{ background: "rgba(248, 250, 252, 1)", border: "1px solid rgba(234, 236, 240, 1)", borderRadius: '10px 10px 0px 0px', padding: '0.8rem'}}>
                                            <p style={{fontWeight: "600", marginBottom: "2px" }}>First 3 months *<span style={{ background:'linear-gradient(289.8deg, rgba(145, 202, 255, 0.6) 0%, rgba(230, 244, 255, 0) 100%)', fontSize: "12px", color: "rgba(0, 110, 210, 1)", padding: '0.2rem 0.6rem', borderRadius: '5px'}} >FREE</span></p>
                                            <p>Full control over COD, and all COD orders are free</p>
                                        </div>
                                        <div style={{ background: "rgba(248, 250, 252, 1)", border: "1px solid rgba(234, 236, 240, 1)", padding: '0.8rem', borderRadius: '0px 0px 10px 10px'}}>
                                            <p style={{fontWeight: "600", marginBottom: "2px" }}>After 3 months *</p>
                                            <p>First 1000 COD orders are free per month. Beyond that, a 0.5% fee per COD order</p>
                                        </div>
                                    </div>
                                        
                                    <div style={{ marginTop: 10, background: "rgba(234, 236, 240, 1)", borderRadius: "10px",  fontSize: "12px" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", padding: '0.5rem', marginLeft: "5px", fontWeight: "600" }}>
                                            <div>Prepaid Pricing</div>
                                            <Tooltip 
                                                title="Instruments such as UPI, Credit card, Debit card, Net Banking, Wallets, Amex/Diners will be charged at 2.50%/order. Cred Pay is charged at 3%/order, Twid Pay at 3.5%/order, and Snapmint at 5%/order." 
                                                placement="bottomRight" 
                                                overlayInnerStyle={{ backgroundColor: "white", color: "black", width: 275, borderRadius: '0.5rem', padding: '0.5rem', boxShadow: '1px 1px 1px 1px rgb(102, 112, 133)'}}
                                            >
                                                <span style={{ color: "rgba(102, 112, 133, 1)", cursor: "pointer" }}>
                                                    * What does this mean?
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <p style={{ background: "rgba(248, 250, 252, 1)", border: "1px solid rgba(234, 236, 240, 1)", borderRadius: '10px', padding: '0.8rem'}} ><strong>2.5% per prepaid order *</strong>, inclusive of payment gateway charges</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <p className="ant-typography" style={{ fontWeight: 'bold' }}>COD Features <span style={{ background:'linear-gradient(289.8deg, rgba(145, 202, 255, 0.6) 0%, rgba(230, 244, 255, 0) 100%)', fontSize: "12px", color: "rgba(0, 110, 210, 1)", padding: '0.2rem 0.6rem', borderRadius: '5px'}} >FREE</span></p>
                        <ul className="bg-[#f6fefc] p-2.5 rounded list-none m-0">
                            <li className="flex items-start gap-2">
                                <CheckOutlined className="text-[#52c41a] mt-1 flex-shrink-0" />
                                <span className="block text-left">Enable COD Orders</span>
                            </li>
                            <li className="flex items-start gap-2">
                                <CheckOutlined className="text-[#52c41a] mt-1 flex-shrink-0" />
                                <span className="block text-left">COD Interventions like COD OTP for Extra Security</span>
                            </li>
                            <li className="flex items-start gap-2">
                                <CheckOutlined className="text-[#52c41a] mt-1 flex-shrink-0" />
                                <span className="block text-left">Show/Hide Payment Based on Cart, Customers, Products, Location, and More</span>
                            </li>
                            <li className="flex items-start gap-2">
                                <CheckOutlined className="text-[#52c41a] mt-1 flex-shrink-0" />
                                <span className="block text-left">Reduce RTO with risk-based COD interventions like CAPTCHA, OTP, and confirmation layers.</span>
                            </li>
                        </ul>
                    </div>

                    <div style={{ marginTop: 20, }}>
                        <p style={{ fontWeight: 'bold' }}>Prepaid Features</p>
                        <ul className="bg-[#f6fefc] p-2.5 rounded list-none m-0">
                            <li style={{ background: 'linear-gradient(90deg, rgba(21, 183, 158, 0) 0%, rgba(21, 183, 158, 0.80) 100%)', borderRadius: '30px', display: 'inline-flex', paddingRight: '1rem'}}>
                                <CheckOutlined style={{ color: '#4CAF50', marginRight: 8 }} />
                                Address Autofill for ~80%* of Users <img src={greyStars} style={{marginLeft: 8}}/>
                            </li>
                            <li className="flex items-start gap-2">
                                <CheckOutlined className="text-[#52c41a] mt-1 flex-shrink-0"/>
                                <span className="block text-left">Prepaid Payments via UPI, Net-Banking, Wallets, Cards, BNPL, and More</span>
                            </li>
                            <li className="flex items-start gap-2">
                                <CheckOutlined className="text-[#52c41a] mt-1 flex-shrink-0" />
                                <span className="block text-left">Partial Payment on COD to Collect Upfront Amount</span>
                            </li>
                            <li className="flex items-start gap-2">
                                <CheckOutlined className="text-[#52c41a] mt-1 flex-shrink-0" />
                                <span className="block text-left">Discounts on Prepaid Orders</span>
                            </li>
                            <li className="flex items-start gap-2">
                                <CheckOutlined className="text-[#52c41a] mt-1 flex-shrink-0" />
                                <span className="block text-left">Display and Apply Discount Coupons at Checkout</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Button 
                type="primary" 
                block 
                onClick={() => getStartedClicked(["kwik_cod","kwik_checkout"])}
                style={{ width: "97%" }}
                className="absolute bottom-0 left-0 m-2 rounded-md"
            >
                {location?.state?.merchantType === 'kwik_cod' ? 'Upgrade Plan' : "Get Started"}
            </Button>
            <ActivationModal showActivationModal={showActivationModal} setShowActivationModal={setShowActivationModal} />
            <AdvancePlanDrawer drawerVisible={drawerVisible} setDrawerVisible={setDrawerVisible} />
            <CodPlusCheckoutDetails codPlusCheckoutDetailsVisible={codPlusCheckoutDetailsVisible} setCodPlusCheckoutDetailsVisible={setCodPlusCheckoutDetailsVisible} />
        </Card>
    );
}

export default AdvancePlanCard;
