import React from 'react';
import { Modal, Button } from "gokwik-ui-kit";
import greenCheck from '@library/images/icons/greenCheck.svg';
import { getMerchantDetails, getUserDetails } from '@store/user/selectors';
import { useSelector } from 'react-redux';
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents';

const ContactSupportSuccessModal = ({ contactSupportSuccessModal, onClose }) => {
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)

    const goBackClicked = () => {
        logEvent(
            'back_to_dashboard_clicked',
            'click',
            'checkout-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            {Step : 'checkout 3'},
        )
        onClose();
    }
    
    return (
        <Modal
            open={contactSupportSuccessModal}
            onCancel={onClose}
            footer={null}
            centered
            width={800}
            bodyStyle={{ padding: '0px' }}
        >
            <div style={{ borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '60vh', background: 'rgba(255, 255, 225, 0.9)' }}>
                <div style={{ fontSize: '3rem' }}>
                    <img src={greenCheck}></img>
                </div>
                <p style={{ fontSize: '1.5rem', fontWeight: 600, lineHeight: '2rem', marginBottom:'0.5rem' }}>
                    We have received your query.
                </p>
                <p style={{ fontSize: '1rem', fontWeight: 400, lineHeight: '1.5rem' }}>
                    Our support team will reach out to you at your registered Email : {userDetails?.email} within 1-2 days.
                </p>
                <Button
                    type="primary"
                    size="large"
                    style={{
                        marginTop: '1rem',
                        fontWeight: 'bold',
                        display: 'inline-flex',
                        alignItems: 'center',
                    }}
                    onClick={goBackClicked}
                >
                    Back to Dashboard
                </Button>
            </div>
        </Modal>
    );
};

export default ContactSupportSuccessModal;
