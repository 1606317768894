import React, { useState } from "react";
import { Modal, Button, TextArea } from "gokwik-ui-kit";
import errorAlert from "@library/images/icons/errorAlert.svg";
import lightExternalLink from '@library/images/icons/light-external-link.svg';
import { sendMerchantOnboardingDetails } from "../CommonComponents/Utils";
import { getMerchantDetails, getUserDetails } from '@store/user/selectors';
import { useSelector } from 'react-redux';
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents';

const ContactSupportModal = ({ showContactSupportModal, onClose, setShowContactSupportModal }) => {
  const [issueDescription, setIssueDescription] = useState("");
  const merchantDetails = useSelector(getMerchantDetails)
  const userDetails = useSelector(getUserDetails)

  const handleSubmit = async () => {
    logEvent(
      'contact_support_clicked',
      'click',
      'checkout-setup',
      userDetails?.email,
      merchantDetails?.m_id,
      merchantDetails?.short_name,
      userDetails?.name,
      {Step: "checkout 3" }
    )
    
    const payload = {
      error_description: issueDescription,
      event_name: "KWIK_CHECKOUT_MANUAL_BUTTON_IMPLEMENTATION_REQUEST",
    };

    try {
      await sendMerchantOnboardingDetails(payload, '', 'Failed to submit issue description', {setIssueDescription, onClose});
    } catch (error) {
      console.error("Error requesting payment methods activation:", error);
    }
  };

  return (
    <Modal
      open={showContactSupportModal}
      onCancel={() => setShowContactSupportModal(false)}
      footer={null}
      centered
      width={800}
      style={{
        borderRadius: "8px",
        padding: 0,
      }}
      styles={{
        body: {
          backgroundColor: "rgba(255, 241, 240, 1)",
          textAlign: "center"
        },
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", height: '60vh', borderRadius: "8px" }}>
        <div style={{ marginBottom: "0.5rem" }}>
          <img src={errorAlert} alt="error" />
        </div>

        <div style={{ fontSize: "1.5rem", fontWeight: "bold", lineHeight: '2rem' }}>
          <div>Sorry! it didn’t work. </div>
          <div>Do not worry, we’ll help you out.</div>
        </div>
        <p style={{ fontSize: "1rem", lineHeight: '1.5rem', color: "#555", marginTop: '0.5rem', marginBottom: "1rem" }}>
          Contact our support team and we’ll assist you the best way possible.
        </p>

        <TextArea
          placeholder="Describe your issue. (Optional)"
          value={issueDescription}
          onChange={(e) => setIssueDescription(e.target.value)}
          style={{
            marginBottom: "1.5rem",
            fontSize: "1rem",
          }}
          rows={6}
          cols={55}
        />

        <Button type="primary"  size="large"  onClick={() => {
          handleSubmit();
        }} >
          Contact Support <img style={{ marginLeft: '0.5rem', marginBottom: '0.2rem' }} src={lightExternalLink} alt="external link"/>
        </Button>
      </div>
    </Modal>
  );
};

export default ContactSupportModal;
