import {
    Alert,
    Button,
    Collapse,
    DownOutlined,
    ExportOutlined,
    Form,
    InfoCircleFilled,
    Row,
    Tag,
    Tooltip,
} from 'gokwik-ui-kit'
import { useCallback, useEffect, useRef, useState } from 'react'
import { getMerchantDetails, getUserData } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { useInterval } from '@library/utilities/hooks'
import { fetchUserDetails } from '@store/user'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { CollapsePanelStatusChips, getFormStatusConfigs } from '@pages/onboarding/kyc/utils'
import { stepConfiguration } from '@pages/onboarding/stepsConfig'

const VBC = ({
    currentStep,
    setCurrentStep,
    VBCLink,
    onFinish,
    isPanelClickable,
    setIsCpvMandatory,
    getCpvStatus,
    setVBClink,
    isCPVMandatory,
}) => {
    const userData = useSelector(getUserData)
    const [form] = Form.useForm()
    const copyTimerRef = useRef(null)
    const [showRefreshMessage, setShowRefreshMessage] = useState(false)
    const merchant_details = useSelector(getMerchantDetails)
    const [interval, initiateInterval, removeInterval] = useInterval(
        () => fetchLatestOnboardingStatusWithPollingHandling(),
        3000,
    )

    const [error, setError] = useState([])

    const intervalHandling = useCallback(
        (existingCpvSubmission, isCPVMandatory) => {
            if (!isCPVMandatory && existingCpvSubmission) {
                window.location.reload()
                removeInterval()
                dispatch(fetchUserDetails())
            } else {
                let timeout
                if (!timeout) {
                    window.setTimeout(() => {
                        removeInterval()
                        setShowRefreshMessage(true)
                    }, 300000)
                }
                if (!interval) {
                    initiateInterval()
                }
            }
        },
        [merchant_details?.onboarding_status?.kwik_checkout_app],
    )

    const fetchLatestOnboardingStatusWithPollingHandling = async () => {
        await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.userDetails,
            method: 'get',
            skipLoader: true,
        })
            .then((result) => {
                intervalHandling(
                    result?.data?.data?.kyc_details?.existing_cpv_submission,
                    result?.data?.data?.kyc_details?.is_cpv_mandatory,
                )
                setIsCpvMandatory(result?.data?.data?.kyc_details?.is_cpv_mandatory)
            })
            .catch((err) => {
                console.warn(err)
            })
    }

    const getBulletPoints = () => {
        return getCpvStatus() === 'rejected' ? (
            <ol>
                <li>Please ensure the information you submitted is accurate and re-submit the verification.</li>
            </ol>
        ) : getCpvStatus() === 'new' ? (
            <ol>
                <li>
                    Completing Contact Point Verification (CPV) is highly recommended, as it validates your business
                    authenticity and significantly improves your chances of getting KYC approval quickly.
                </li>
            </ol>
        ) : (
            <ol>
                <li className='mb-2'>
                    Your KYC is on hold until CPV is completed. Click here to complete verification
                </li>
                <li>Please complete CPV using the link below.</li>
            </ol>
        )
    }

    const getVPCLink = async () => {
        await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.generateKYCLinks,
            payload: {
                send_notification: 1,
            },
        }).then((res) => {
            if (!res.data.data.video_kyc_url) {
                getVPCLink()
            }
            setVBClink(res?.data?.data?.video_kyc_url)
        })
    }

    useEffect(() => {
        const getStatus = getFormStatusConfigs(userData, ['cpv_error'], 'kyc_vpc')
        const errs = getStatus?.generateErrorChips()
        setError(errs)

        if (!VBCLink) {
            getVPCLink()
        }
        return () => {
            if (copyTimerRef.current) {
                clearTimeout(copyTimerRef.current)
            }
        }
    }, [])

    const openInNewTab = () => {
        logEvent(
            'background_verification_new_tab_clicked',
            'click',
            'kyc',
            userData?.userDetails?.email,
            userData?.merchantDetails?.m_id,
        )
        const url = form.getFieldValue('vbc_link')
        if (url) {
            window.open(url, '_blank').focus()
            fetchLatestOnboardingStatusWithPollingHandling()
        }
    }

    const items = [
        {
            key: '1',
            label: (
                <>
                    <div className='flex flex-col gap-1'>
                        <div className='text-[#004B8D] text-xs font-semibold flex'>
                            <div className='mr-3'>Step 6</div>
                        </div>
                        <div className='flex gap-4 items-center'>
                            <div className={`text-xl font-semibold`}>Contact Point Verification</div>
                            <CollapsePanelStatusChips
                                errors={error}
                                stepConfiguration={stepConfiguration}
                                current_onboarding_status={
                                    userData?.merchantDetails?.onboarding_status?.kwik_checkout_app
                                }
                                sectionName={'kyc_vpc'}
                                isCPVMandatory={isCPVMandatory}
                            />
                        </div>
                    </div>
                </>
            ),
            children: (
                <>
                    {showRefreshMessage && (
                        <div className='flex justify-between mt-2 w-full'>
                            <Alert
                                type='info'
                                showIcon
                                icon={<InfoCircleFilled />}
                                className='w-full py-1'
                                message={
                                    <p className='text-sm font-normal leading-6 text-[#002C8C]'>
                                        Completed CPV? Refresh to update your status.
                                    </p>
                                }
                            />
                        </div>
                    )}

                    {VBCLink ? (
                        <>
                            <div className='flex'>{getBulletPoints()}</div>
                            <Form
                                layout='vertical'
                                form={form}
                                onFinish={(v) => onFinish(v, 5)}
                                className='mt-2'
                                initialValues={{ vbc_link: VBCLink }}
                            >
                                <Row>
                                    <Row className='ml-2 items-center'>
                                        <Tooltip title='Open In New Tab'>
                                            <Button
                                                // type='link'
                                                target='_blank'
                                                className='ml-2'
                                                variant='primary'
                                                onClick={openInNewTab}
                                            >
                                                {getCpvStatus() === 'rejected' ? 'Re-Submit CPV' : 'Complete CPV Now'}{' '}
                                                <ExportOutlined />
                                            </Button>
                                        </Tooltip>
                                    </Row>
                                </Row>
                            </Form>
                        </>
                    ) : (
                        <p>
                            Something went wrong! <a href='/onboarding/kyc'>Click here</a> to refresh the page
                        </p>
                    )}
                </>
            ),
        },
    ]

    const onChange = (e) => {
        setCurrentStep(5)
    }

    return (
        <div className=''>
            <Collapse
                collapsible={isPanelClickable(5)}
                className='bg-white'
                activeKey={
                    !userData?.kycData?.is_cpv_mandatory &&
                    userData?.kycData?.existing_cpv_submission &&
                    (userData?.merchantDetails?.onboarding_status?.kwik_checkout_app === 'kyc_verification_failed' ||
                        userData?.merchantDetails?.onboarding_status?.kwik_checkout_app === 'kyc_verification_pending')
                        ? null
                        : currentStep === 5
                        ? '1'
                        : null
                }
                onChange={onChange}
                items={items}
                accordion
                expandIconPosition='right'
                expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
            />
        </div>
    )
}

export default VBC
function dispatch(arg0: (dispatch: any) => Promise<any>) {
    throw new Error('Function not implemented.')
}
