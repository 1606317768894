import { Modal, Popconfirm, CloseCircleFilled, Button } from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'
import XSellBanner from '../preview-campaign/XSellBanner'
import { makeAPICall } from '@gokwik/utilities'
import { getCampaignData } from '@store/user/selectors'
import { useSelector } from 'react-redux'

type Props = {}

const ModalCampaign = (props: Props) => {
    const [openCampaignModal, setOpenCampaignModal] = useState(
        false || sessionStorage?.getItem('modalOpenState') === 'false' ? false : true,
    )
    const [modalContent, setModalContent] = useState(null)
    const campaignData = useSelector(getCampaignData)
    const [open, setOpen] = useState(false)

    const recordInteraction = async (interaction_type, campaign_id) => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + '/api/dashboard/campaign/user-interaction',
            payload: {
                interaction_type,
                campaign_id: Number(campaign_id),
            },
        })

        if (response?.status === 200) {
            if (interaction_type === 'dismiss' || interaction_type === 'view') {
                sessionStorage?.setItem('modalOpenState', 'false')
                setOpenCampaignModal(false)
            }
        }
    }

    const onStay = (e, campaign_id) => {
        setOpen(false)
    }

    const onDismiss = (e, campaign) => {
        const btnName = e?.target?.textContent

        if (campaign?.is_dismissed_permanently && btnName === 'Don’t show me again.') {
            recordInteraction('dismiss', campaign?.id)
        } else {
            recordInteraction('view', campaign?.id)
        }
        setOpen(false)
    }

    useEffect(() => {
        if (
            Array.isArray(campaignData?.modalBanner) &&
            campaignData?.modalBanner?.length > 0 &&
            sessionStorage?.getItem('modalOpenState') !== 'false'
        ) {
            const tempData = { ...campaignData?.modalBanner[0], setPaddingZero: true }
            setModalContent(tempData)
            setOpenCampaignModal(true)
        }
    }, [campaignData])

    return (
        <div className='relative z-[1000px]'>
            {modalContent && (
                <>
                    <style>
                        {`
                    .ant-modal .ant-modal-content {
                        padding: 0px !important;
                    }
                    .custom-close-icon {
                        position: absolute;
                        top: -10px;
                        left:22px;
                        z-index: 1000;
                    }
                `}
                    </style>
                    <Modal
                        className=' z-[1000px]'
                        onCancel={() => {}}
                        footer={null}
                        closable
                        open={openCampaignModal}
                        width={370}
                        maskClosable={false}
                        closeIcon={
                            <>
                                <style>{`
                                .ant-popconfirm-buttons {
                                    display: none;
                                    pointer-events:none;
                                }
                                `}</style>
                                <Popconfirm
                                    open={open}
                                    icon={false}
                                    className='custom-close-icon'
                                    title={<span className='text-red-500'></span>}
                                    description={
                                        <div className='flex gap-1'>
                                            {modalContent?.is_dismissed_permanently && (
                                                <Button onClick={(e) => onDismiss(e, modalContent)}>
                                                    Don’t show me again.
                                                </Button>
                                            )}
                                            <Button onClick={(e) => onDismiss(e, modalContent)}>Dismiss</Button>

                                            <Button variant='primary' onClick={(e) => onStay(e, modalContent?.id)}>
                                                Don’t Dismiss
                                            </Button>
                                        </div>
                                    }
                                    // onConfirm={(e) => onConfirm(e, modalContent?.id)}
                                    // onStay={(e) => onStay(e, modalContent?.id)}
                                    // okText='Yes'
                                    // cancelText='No'
                                >
                                    <CloseCircleFilled onClick={() => setOpen(true)} className='text-xl' />
                                </Popconfirm>
                            </>
                        }
                    >
                        {<XSellBanner previewImage={null} campaignConfigs={modalContent} />}
                    </Modal>
                </>
            )}
        </div>
    )
}

export default ModalCampaign
