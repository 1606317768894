import React, { useEffect, useRef, useState } from 'react'
import bgVector1 from '@library/images/common/bg-vector1.png'
import rectangle from '@library/images/common/rectangle.png'
import prefillThumbnail from '@library/images/common/prefill-address.png'
import rtoThumbnail from '@library/images/common/rto-thumbnail.png'
import discountThumbnail from '@library/images/common/discounts-thumbnail.png'
import { Row, Col, Button } from 'gokwik-ui-kit'

import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import DownloadIcon from '@library/images/icons/downloadIcon'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '@library/utilities/hooks'
import { fetchUserDetails } from '@store/user'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import FeatureTour from '@library/images/common/onboarding-images/FeatureTour.svg'

export default function () {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [current, setCurrent] = useState({
        title: 'Pre-Filled Shipping Address',
        url: 'https://s3.ap-south-1.amazonaws.com/devcdngokwik.co/shopify_documents/pre_fill_address.mp4',
        id: 0,
        description:
            '  Auto-filled addresses for over 80% shoppers within the GoKwik network. Eliminate the most common checkout hurdle, reduce drop-offs and watch your conversion rates soar',
    })
    const animatedVideos = [
        {
            id: 0,
            title: 'Pre-Filled Shipping Address',
            url: 'https://s3.ap-south-1.amazonaws.com/devcdngokwik.co/shopify_documents/pre_fill_address.mp4',
            img: prefillThumbnail,
            description:
                '  Auto-filled addresses for over 80% shoppers within the GoKwik network. Eliminate the most common checkout hurdle, reduce drop-offs and watch your conversion rates soar',
        },
        {
            id: 1,
            title: 'Exclusive Discounts Bouquet',
            url: 'https://s3.ap-south-1.amazonaws.com/devcdngokwik.co/shopify_documents/discount_conversion.mp4',
            img: discountThumbnail,
            description:
                'Set appealing yet cost-effective Discount use-cases that are unique to the GoKwik suite, over and above the Shopify-Specific discounts, that too at no additional fee. Increase your Profit Margins and conversions by X% while attracting and retaining customers',
        },
        {
            id: 2,
            title: 'RTO - Return to Origin',
            url: 'https://s3.ap-south-1.amazonaws.com/devcdngokwik.co/shopify_documents/rto.mp4',
            img: rtoThumbnail,
            description:
                'Reduce RTO rates Improve COD health by simply switching on the RTO Module. Enjoy opening up COD for low-risk users, while also limiting COD to ward off high-risk users, via risk-intelligence frictions',
        },
    ]
    const videoRef = useRef(null)
    const thumbnailRef = useRef(null)
    const merchant_details = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)

    const onRouteChange = async () => {
        logEvent('proceed_to_next_screen_clicked', 'click', 'feature tour', user_details.email, merchant_details.m_id)
        // if (merchant_details?.onboarding_status?.kwik_checkout_app === 'password_setup') {
        await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateMerchantOnboardingStatus + merchant_details.m_id,
            payload: {
                current_onboarding_status: 'smart_cod_app_disabled',
            },
        })
        dispatch(fetchUserDetails())
        // }
        // navigate('/onboarding/get-started')
    }

    useEffect(() => {
        logEvent('feature_tour_page_landed', 'pageLoad', 'feature tour', user_details.email, merchant_details.m_id)
    }, [])

    return (
        <div className="relative h-full w-full font-['Inter'] pb-8 overflow-hidden">
            <img
                src={bgVector1}
                className={`cube fixed w-[250px] bottom-[calc(100vh-40%)] right-[4px] pointer-events-none`}
            />
            <img src={rectangle} className='rectangle absolute left-[-12%] w-[72%] pointer-events-none' />
            <div className='absolute flex items-center justify-center left-[8%] top-[8%]'>
                <img src={FeatureTour} alt='feature-tour' className='w-[85%] h-[80%]' />
                <Button
                    onClick={onRouteChange}
                    className='opacity-0 absolute top-[32%] left-[6%] w-64 h-20'
                    variant='primary'
                >
                    Proceed to next screen
                </Button>
            </div>
            {/* <Row className='h-full'>
                <Col span={12} className='flex flex-col justify-center  items-center'>
                    <div className='flex flex-col  items-start'>
                        <h2 className='opacity-70 font-semibold  leading-[50px]'>Welcome to</h2>
                        <h1 className='text-[40px] leading-[0px]'>GoKwik Feature Tour</h1>
                    </div>
                    <div className='flex flex-col  items-start justify-center mt-[128px] w-[376px] '>
                        <p className='text-2xl font-semibold leading-[28px] text-blue-500 '>{current.title}</p>
                        <p className='text-sm font-normal leading-[22px] text-black opacity-60 w-full my-4 h-[100px]'>
                            {current.description}
                        </p>
                        <Button variant='default' type='link' className='flex justify-center pl-0'>
                            <DownloadIcon />
                            <a
                                onClick={() => {
                                    logEvent(
                                        'download_guide_clicked',
                                        'click',
                                        'feature tour',
                                        user_details.email,
                                        merchant_details.m_id,
                                    )
                                }}
                                href='https://s3.ap-south-1.amazonaws.com/devcdngokwik.co/shopify_documents/feature_tour.pdf'
                                target='_blank'
                                download
                            >
                                <span>Download the Comprehensive Features Guide</span>
                            </a>
                        </Button>
                        <Button onClick={onRouteChange} className='mt-8 ' variant='primary'>
                            Proceed to next screen
                        </Button>
                    </div>
                </Col>
                <Col span={12} className='flex flex-col justify-center'>
                    <div className='shadow-lg  w-[350px] h-[350px]'>
                        {current.url && (
                            <video
                                ref={videoRef}
                                onEnded={() => {
                                    if (current.id == 2) {
                                        setCurrent(animatedVideos[0])
                                    } else setCurrent(animatedVideos[current.id + 1])
                                    if (videoRef.current) {
                                        videoRef.current.load()
                                    }
                                }}
                                key={1}
                                width='100%'
                                className={`w-[350px] transition ease-in-out  duration-300 ${
                                    current.url ? 'opacity-100' : 'opacity-0'
                                }`}
                                autoPlay
                                muted
                                // loop
                            >
                                <source src={current.url} type='video/mp4' />
                                Your browser does not support the video tag.
                            </video>
                        )}
                     
                    </div>

                    <div className='mt-5 flex  w-auto overflow-scroll'>
                        {animatedVideos.map((item, index) => (
                            <div key={index}>
                                <div
                                    ref={thumbnailRef}
                                    className={`w-[150px] h-[150px] transition ease-in-out overflow-hidden  duration-300  m-2 ml-0 shadow-md cursor-pointer hover:shadow-lg rounded
                                      ${
                                          current.id == index
                                              ? 'shadow-lg scale-125'
                                              : ' transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300'
                                      }`}
                                    onClick={() => {
                                        setCurrent(item)
                                        if (videoRef.current) {
                                            videoRef.current.load()
                                        }
                                    }}
                                >
                                    <img className='w-full h-full' src={item.img} />
                                </div>
                                <p
                                    className={`text-sm  w-[70%] ${
                                        current.id == index ? 'mt-6  text-blue-500 font-semibold' : 'text-gray-400'
                                    }`}
                                >
                                    {item.title}
                                </p>
                            </div>
                        ))}
                    </div>
                </Col>
            </Row> */}
        </div>
    )
}
