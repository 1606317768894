import { merchantRoutes } from '../constants/constants'
import { SearchFilters, FilterCreatorModel } from '../interface'

const getDynamicSearchFilters = (searchFilters: SearchFilters[]): FilterCreatorModel => {
    const filterCreatorState: FilterCreatorModel = {
        title: 'Add More Filters +',
        options: searchFilters
            ?.filter((item) => item?.is_dynamic)
            ?.map((filter) => {
                return {
                    id: filter?.id,
                    text: filter?.label,
                }
            }),
    }
    return filterCreatorState
}

function deepCopy(obj) {
    try {
        // Check if the input is not an object or is null
        if (obj === null || typeof obj !== 'object') {
            return obj
        }

        // Handle Array
        if (Array.isArray(obj)) {
            const copy = []
            for (let i = 0; i < obj.length; i++) {
                copy[i] = deepCopy(obj[i])
            }
            return copy
        }

        // Handle Object
        if (obj instanceof Object) {
            const copy = {}
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    copy[key] = deepCopy(obj[key])
                }
            }
            return copy
        }
    } catch (error) {
        return obj
    }
}

function getExperimentsPayload(payload) {
    const { name, description, start_date, end_date, is_active, merchants, exposure_percentage, variantsList } = payload

    const variantsPayload = variantsList.map((variant) => {
        const { ip_addresses, ...rest } = variant
        return {
            ...rest,
            forced_assignment_rules:
                ip_addresses?.length < 1
                    ? []
                    : [
                          {
                              conditions: [
                                  {
                                      attribute: 'ip_address',
                                      operator: 'ONE_OF',
                                      value: ip_addresses,
                                  },
                              ],
                          },
                      ],
        }
    })

    let payloadObj = {
        name,
        description,
        start_date: new Date(start_date),
        end_date: new Date(end_date),
        exposure_control_rules: [],
        exposure_percentage,
        is_active,
        filters: merchants?.includes('all')
            ? []
            : [
                  {
                      conditions: [
                          {
                              attribute: 'm_id',
                              operator: 'ONE_OF',
                              value: merchants,
                          },
                      ],
                  },
              ],
        variants: variantsPayload,
    }

    return payloadObj
}

function isMerchantRoute(path) {
    return merchantRoutes.includes(path)
}

export { getDynamicSearchFilters, deepCopy, getExperimentsPayload, isMerchantRoute }

export const rangesOverlap = ([x1, x2], [y1, y2]) => x1 <= y2 && y1 <= x2
