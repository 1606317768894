import { Select } from 'gokwik-ui-kit'
import React, { useState } from 'react'
import RTOAnalytics from './charts/RTOAnalytics'
import CheckoutAnalytics from './charts/CheckoutAnalytics'

const Charts = () => {
    const [timeFrame, setTimeFrame] = useState('week')
    const handleTimeFrameChange = (value) => {
        setTimeFrame(value)
    }

    return (
        <div className='p-2'>
            <div className='flex justify-between'>
                <span className='text-xl font-semibold text-[#000000E0]'>Overview</span>

                <Select defaultValue={timeFrame} onChange={handleTimeFrameChange}>
                    <Select.Option value={'week'}>3 Weeks</Select.Option>
                    <Select.Option value={'month'}>3 Months</Select.Option>
                    <Select.Option value={'day'}>3 Days</Select.Option>
                </Select>
            </div>

            <br />
            <div>
                <CheckoutAnalytics timeFrame={timeFrame} />
                <br />
                <RTOAnalytics timeFrame={timeFrame} />
            </div>
        </div>
    )
}

export default Charts
