import { useEffect, useState } from 'react'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { handleError } from '@library/utilities/helpers/handleError'
import { makeAPICall } from '@gokwik/utilities'
import { Button, Col, Collapse, Drawer, Modal, Row, Select, Switch, Tag, message } from 'gokwik-ui-kit'
import { Method, SelectDropdown, SelectedProvider } from '@library/utilities/interface'
import { PaymentMethods, SuccessStatusCodes } from '@library/utilities/constants/constants'
import { deepCopy } from '@library/utilities/helpers/helper'

interface Props {
    isEdit?: boolean
    id?: string
    open: boolean
    onCancel: (e:boolean) => void
}

const tabs = {
    INPUT_FIELDS: 'inputFields',
    CONFIRM_CHANGES: 'confirmChanges',
}

export default function ({ isEdit = false, id = '', open, onCancel }: Props) {
    const [selectedProvider, setSelectedProvider] = useState<SelectedProvider>(null)
    const [providers, setProviders] = useState<SelectDropdown[]>([])
    const [collapseItems, setCollapseItems] = useState([])
    const [bluePrint, setBluePrint] = useState<Method[]>([])
    const [initialBluePrint, setInitialBluePrint] = useState<Method[]>([])
    const [updatedValues, setUpdatedValues] = useState<{ bluePrint: Method[] }>({ bluePrint: [] })
    const [isTerminalActive, setTerminalState] = useState<boolean>(false)
    const [initialTerminalStatus, setInitialTerminalStatus] = useState<boolean>(false)
    const [currentTab, setCurrentTab] = useState<string>(tabs.INPUT_FIELDS)
    const [showModal, setShowModal] = useState<boolean>(false)

    useEffect(() => {
        getSupportedProviders()
    }, [])

    useEffect(() => {
        if (id && isEdit && providers.length > 0) {
            getTerminal(id)
        }
    }, [id, isEdit, providers.length])

    async function getSupportedProviders() {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.supportedProviders,
            })
            if (response.data?.data?.length > 0) {
                const activeProviders = response.data.data
                const options = activeProviders.map(({ name, provider }) => ({
                    label: name,
                    value: provider,
                }))
                setProviders(options)
            } else {
                setProviders([])
                const errMessage = response?.response?.data?.error?.message ?? 'Failed to fetch providers'
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function getBluePrints(provider: string, selectedOption) {
        setSelectedProvider(selectedOption)
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.terminalConfigs.getBluePrints(provider),
            })
            if (SuccessStatusCodes.includes(response.data?.status_code) && response.data?.data) {
                const resData = response.data.data
                setSelectedProvider({
                    label: resData?.name ?? resData.provider,
                    value: resData.provider,
                    type: resData.type,
                })
                const bluePrintData = resData.methods.map((method) => {
                    let isInstrumentsVisible = true
                    const instruments = method.instruments.map((instrument) => {
                        if (instrument?.visible === false) isInstrumentsVisible = false
                        return {
                            ...instrument,
                            enabled: instrument.default_enabled,
                            active: instrument?.data?.active ?? false,
                        }
                    })
                    return {
                        ...method,
                        enabled: method.default_enabled,
                        active: method?.data?.active ?? false,
                        instruments,
                        visible: isInstrumentsVisible,
                    }
                })
                setBluePrint(bluePrintData)
                updateCollapseItems(bluePrintData)
            } else {
                setBluePrint([])
                const errMessage = response?.response?.data?.error?.message ?? 'Failed to fetch blueprint'
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        }
    }

    function convertToApiFormat(localstate = []) {
        try {
            return localstate.map((method) => ({
                method: method.method,
                enabled: method.enabled,
                data: {
                    active: method?.active ?? false,
                },
                instruments: method.instruments.map((instrument) => ({
                    instrument: instrument.instrument,
                    enabled: instrument.enabled,
                    data: {
                        active: instrument?.active ?? false,
                        international_enabled: instrument?.data?.international_enabled,
                    },
                })),
            }))
        } catch (error) {
            return []
        }
    }

    async function createTerminal() {
        const methods = convertToApiFormat(bluePrint)
        try {
            const body: any = {
                provider: selectedProvider.value,
                type: selectedProvider.type,
                methods,
            }
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.terminalConfigs.createTerminal,
                payload: body,
            })
            if (SuccessStatusCodes.includes(response.data?.status_code)) {
                message.success('Terminal created successfully')
                setTimeout(() => onCancel(true), 200)
            } else {
                const errMessage = response?.response?.data?.error?.message ?? 'Failed to create terminal'
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function getTerminal(id: string) {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.terminalConfigs.getTerminal(id),
            })
            if (SuccessStatusCodes.includes(response.data?.status_code) && response.data?.data) {
                const terminalData = response.data.data
                setSelectedProvider({
                    label: terminalData?.name ?? terminalData.provider,
                    value: terminalData.provider,
                    type: terminalData?.type,
                })
                setTerminalState(terminalData.active)
                setInitialTerminalStatus(terminalData.active)
                const bluePrintData = terminalData?.methods?.map((method) => {
                    let isInstrumentsVisible = true
                    const instruments = method?.instruments?.map((instrument) => {
                        if (instrument?.visible === false) isInstrumentsVisible = false
                        return {
                            ...instrument,
                            name: instrument.name,
                            enabled: instrument.enabled,
                            active: instrument?.data?.active ?? false,
                        }
                    })
                    return {
                        name: method.name,
                        method: method.method,
                        enabled: method.enabled,
                        active: method?.data?.active ?? false,
                        instruments,
                        visible: isInstrumentsVisible,
                    }
                })
                setBluePrint(bluePrintData)
                if (isEdit) setInitialBluePrint(bluePrintData)
                updateCollapseItems(bluePrintData)
            } else {
                const errMessage = response?.response?.data?.error?.message ?? 'Failed to fetch terminal'
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        }
    }

    async function updateTerminal() {
        try {
            const methods = convertToApiFormat(bluePrint)
            const body: any = {
                provider: selectedProvider.value,
                methods,
                active: isTerminalActive,
            }
            let response = await makeAPICall({
                method: 'put',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.terminalConfigs.updateTerminal(id),
                payload: body,
            })
            if (SuccessStatusCodes.includes(response.data?.status_code)) {
                message.success('Terminal updated successfully')
                setTimeout(() => onCancel(true), 200)
            } else {
                const errMessage = response?.response?.data?.error?.reference?.message ?? 'Failed to update terminal'
                message.error(errMessage)
            }
        } catch (error) {
            handleError(error)
        }
    }

    function updateCollapseItems(methods = []) {
        let items = []
        try {
            items = methods.map((blueprint, index) => {
                return {
                    key: `${blueprint.method}-${index}`,
                    label: (
                        <section className='flex space-around'>
                            <p className='flex-1'>{blueprint.name}</p>
                            <div className='flex-1 flex gap-2'>
                                <span className='pr-2'>Enable</span>
                                <Switch
                                    checked={blueprint.enabled}
                                    onChange={(value, event) =>
                                        updateBluePrint(event, blueprint.method, '', 'enabled', value)
                                    }
                                />
                            </div>
                            <div className='flex gap-2'>
                                <span className='pr-2'>Merchant Override</span>
                                <Switch
                                    checked={blueprint.active}
                                    onChange={(value, event) =>
                                        updateBluePrint(event, blueprint.method, '', 'active', value)
                                    }
                                />
                            </div>
                        </section>
                    ),
                    children:
                        blueprint?.instruments && blueprint.instruments?.length > 0 ? (
                            <>
                                <section className='border border-solid border-gray-200 rounded-lg bg-white'>
                                    <Row>
                                        <Col span={24}>
                                            <div className='flex gap-2 gray-background p-3 pl-4 pr-4'>
                                                <p className='text-sm flex-1 text-gray-500'>{blueprint.name} Type</p>
                                                <span className='text-sm flex-1 text-gray-500 flex justify-center'>
                                                    Enable
                                                </span>
                                                <span className='text-sm flex-1 text-gray-500 flex justify-center text whitespace-nowrap'>
                                                    Merchant override
                                                </span>
                                            </div>
                                        </Col>
                                        {blueprint.instruments.map((instrument, key) => {
                                            return (
                                                <Col span={24} key={`${instrument.name}-${key}`}>
                                                    <div className='flex p-4 gap-2 space-between bottom-border'>
                                                        <span className='text-sm flex-1'>{instrument.name}</span>
                                                        <div className='flex-1 flex justify-center'>
                                                            <Switch
                                                                disabled={instrument?.visible === false}
                                                                checked={instrument.enabled}
                                                                onChange={(value, event) =>
                                                                    updateBluePrint(
                                                                        event,
                                                                        blueprint.method,
                                                                        instrument.instrument,
                                                                        'enabled',
                                                                        value,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className='flex-1 flex justify-center'>
                                                            <Switch
                                                                checked={instrument.active}
                                                                onChange={(value, event) =>
                                                                    updateBluePrint(
                                                                        event,
                                                                        blueprint.method,
                                                                        instrument.instrument,
                                                                        'active',
                                                                        value,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </section>
                                {PaymentMethods.CREDIT_CARD === blueprint.method &&
                                    blueprint?.instruments?.length > 0 && (
                                        <section className='mt-3 flex gap-2'>
                                            <span className='text-sm'>International</span>
                                            <Switch
                                                checked={blueprint.instruments[0].data?.international_enabled ?? false}
                                                onChange={(value, event) =>
                                                    updateBluePrint(event, blueprint.method, 'all', '', value, true)
                                                }
                                            />
                                        </section>
                                    )}
                            </>
                        ) : (
                            <p>No methods available</p>
                        ),
                    configKey: blueprint.method,
                }
            })
        } catch (error) {
            items = []
        }
        setCollapseItems(items)
    }

    function updateBluePrint(
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        method: string,
        instrument: string,
        key: string,
        value: boolean,
        isInternational = false,
    ) {
        event?.stopPropagation()
        setBluePrint((prevBlueprint) => {
            try {
                const updatedBlueprint = prevBlueprint.map((item) => {
                    if (item?.method !== method) return item

                    if (instrument && item?.instruments?.length) {
                        let updatedInstruments = item.instruments.map((inst) => {
                            if (inst?.instrument !== instrument && !isInternational) return inst
                            if (isInternational) {
                                return { ...inst, data: { international_enabled: value } }
                            }
                            return { ...inst, [key]: value }
                        })
                        return { ...item, instruments: updatedInstruments }
                    } else {
                        let updatedInstruments = item.instruments ?? []
                        if (item?.visible === false && key === 'enabled') {
                            updatedInstruments = updateAllNonVisibleInstruments(updatedInstruments, value)
                        }
                        return { ...item, [key]: value, instruments: updatedInstruments }
                    }
                })
                updateCollapseItems(updatedBlueprint)
                return updatedBlueprint
            } catch (error) {
                return prevBlueprint
            }
        })
    }

    function updateAllNonVisibleInstruments(instruments = [], value) {
        return instruments?.map((inst) => {
            if (inst?.visible === false) {
                return { ...inst, enabled: value }
            }
            return inst
        })
    }

    function validateDetails() {
        try {
            if (!selectedProvider?.value) {
                message.error(`Please select provider`)
                return false
            }
            if (isEdit && id) {
                const updatedData = getUpdatedBluePrintValues(initialBluePrint, bluePrint)
                if (updatedData.length > 0 || initialTerminalStatus !== isTerminalActive) {
                    setUpdatedValues({ bluePrint: updatedData })
                    setCurrentTab(tabs.CONFIRM_CHANGES)
                } else {
                    message.info(`No changes have been made`)
                }
            } else {
                createTerminal()
            }
        } catch (_err) {}
    }

    function getUpdatedBluePrintValues(initialState: Method[], newState: Method[]) {
        try {
            const updatedValues = []
            for (const currItem of newState) {
                let newItem = deepCopy(currItem)
                let isItemUpdated = false
                const initialItem = initialState.find((item) => item.method === newItem.method)
                if (initialItem.enabled !== newItem.enabled || initialItem?.active !== newItem?.active) {
                    newItem['isUpdated'] = true
                    newItem['isEnableUpdated'] = initialItem.enabled !== newItem.enabled
                    newItem['isActiveUpdated'] = initialItem?.active !== newItem?.active
                    isItemUpdated = true
                }
                for (const newInstrument of newItem.instruments) {
                    const initialInstrument = initialItem.instruments.find(
                        (instrument) => instrument.instrument === newInstrument.instrument,
                    )
                    const isInternationalEnabledChanged =
                        initialInstrument?.data?.international_enabled !== newInstrument?.data?.international_enabled
                    if (
                        !initialInstrument ||
                        initialInstrument.enabled !== newInstrument.enabled ||
                        initialInstrument?.active !== newInstrument?.active
                    ) {
                        newInstrument['isUpdated'] = true
                        newItem['isInstrumentsUpdated'] = true
                        newInstrument['isEnableUpdated'] = initialInstrument.enabled !== newInstrument.enabled
                        newInstrument['isActiveUpdated'] = initialInstrument?.active !== newInstrument?.active
                        isItemUpdated = true
                    }
                    if (isInternationalEnabledChanged && !newItem['isInternationalUpdated']) {
                        newItem['isInternationalUpdated'] = true
                        isItemUpdated = true
                    }
                }
                if (isItemUpdated) updatedValues.push(newItem)
            }
            return updatedValues
        } catch (error) {
            return []
        }
    }

    function moveToInputTab() {
        setCurrentTab(tabs.INPUT_FIELDS)
    }

    function getTitle() {
        if (currentTab === tabs.CONFIRM_CHANGES) {
            return 'Verify information'
        } else if (isEdit) {
            return 'Edit Terminal'
        } else {
            return 'Add Terminal'
        }
    }

    function getFooter() {
        return (
            <Row gutter={12}>
                <Col span={12}>
                    <Button
                        className='w-full'
                        variant='primary'
                        onClick={currentTab === tabs.INPUT_FIELDS ? validateDetails : updateTerminal}
                    >
                        {currentTab === tabs.INPUT_FIELDS ? 'Save' : 'Confim Changes'}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button className='w-full' onClick={() => currentTab === tabs.INPUT_FIELDS ? onCancel(false) : moveToInputTab()}>
                        {currentTab === tabs.INPUT_FIELDS ? 'Discard' : 'Back'}
                    </Button>
                </Col>
            </Row>
        )
    }

    function handleStatusChange(status) {
        if (!status) {
            setShowModal(true)
        } else {
            setTerminalState(true)
        }
    }

    function deactivateTerminal() {
        setShowModal(false)
        setTerminalState(false)
    }

    function getTag(isSuccess = true, successText = 'Activated', failText = 'Deactivated') {
        try {
            return (
                <Tag
                    className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                    variant={isSuccess ? 'success' : 'error'}
                >
                    {isSuccess ? successText : failText}
                </Tag>
            )
        } catch (_) {
            return <></>
        }
    }

    return (
        <>
            <Drawer title={getTitle()} open={open} onClose={() => onCancel(false)} width={600} footer={getFooter()}>
                {currentTab === tabs.INPUT_FIELDS ? (
                    <>
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Select
                                    className='w-full'
                                    label='Provider*'
                                    placeholder='Please select'
                                    onChange={(value, option) => getBluePrints(value, option)}
                                    options={providers}
                                    value={selectedProvider?.value}
                                    disabled={isEdit}
                                />
                            </Col>
                        </Row>
                        <Row gutter={[8, 8]}>
                            {collapseItems.length > 0 && (
                                <>
                                    <Col span={24}>
                                        <div className='flex items-center gap-x-2'>
                                            <h4 className='text-base font-medium mb-1'>Methods</h4>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className='bg-white overflow-y-auto rounded'>
                                            <Collapse className='rounded-lg' items={collapseItems} />
                                        </div>
                                    </Col>
                                </>
                            )}
                        </Row>
                        {isEdit && id && (
                            <Row className='mt-2'>
                                <div className='flex items-center gap-x-2'>
                                    <h4 className='text-base font-medium pr-2'>Terminal Status</h4>
                                    <Switch
                                        checked={isTerminalActive}
                                        onChange={(value) => handleStatusChange(value)}
                                    />
                                </div>
                            </Row>
                        )}
                    </>
                ) : (
                    <>
                        <Row className='mb-4'>
                            <span className='front-normal'>Are you sure you want to update the below information?</span>
                        </Row>
                        {initialTerminalStatus !== isTerminalActive && (
                            <Row gutter={[16, 16]} className='mt-4 pl-2'>
                                <div className='flex items-center'>
                                    <p className='text-sm text-gray-500 pr-2'>Terminal Status -</p>
                                    <span className='text-sm'>{getTag(isTerminalActive)}</span>
                                </div>
                            </Row>
                        )}
                        <Row gutter={[16, 16]} className='mt-4'>
                            {updatedValues?.bluePrint?.map((item, index) => (
                                <section className='rounded-2xl bg-gray-100 w-full p-4' key={`${item.name}-${index}`}>
                                    <div className='flex justify-between'>
                                        <p className='text-sm'>{item?.name}</p>
                                        {item?.isUpdated && (
                                            <>
                                                {item?.isEnableUpdated && (
                                                    <span className='text-sm'>
                                                        Enable - {getTag(item?.enabled, 'Activated', 'Disabled')}
                                                    </span>
                                                )}
                                                {item?.isActiveUpdated && (
                                                    <span className='text-sm'>
                                                        Merchant Override -
                                                        {getTag(item?.active, 'Activated', 'Disabled')}
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    {item?.isInstrumentsUpdated && (
                                        <section className='border border-solid border-gray-200 rounded-lg bg-white mt-3'>
                                            <Row>
                                                <Col span={24}>
                                                    <div className='p-3 bg-gray-100 border-radius-top flex justify-between'>
                                                        <p className='flex-1 text-sm text-gray-500'>
                                                            {item?.name} Type
                                                        </p>
                                                        <p className='flex-1 text-sm text-gray-500 flex justify-center'>
                                                            Enable
                                                        </p>
                                                        <p className='flex-1 text-sm text-gray-500 flex justify-center'>
                                                            Merchant Override
                                                        </p>
                                                    </div>
                                                </Col>
                                                {item.instruments
                                                    ?.filter((item) => item?.isUpdated)
                                                    ?.map((instrument, key) => {
                                                        return (
                                                            <Col
                                                                span={24}
                                                                key={`${instrument.name}-${key}`}
                                                                className='mt-0 pt-0'
                                                            >
                                                                <div className='flex justify-between p-4 bottom-border'>
                                                                    <span className='flex-1 text-sm'>
                                                                        {instrument?.name}
                                                                    </span>
                                                                    <span className='flex-1 text-sm flex justify-center'>
                                                                        {instrument?.isEnableUpdated
                                                                            ? getTag(
                                                                                  instrument?.enabled,
                                                                                  'Activated',
                                                                                  'Disabled',
                                                                              )
                                                                            : '-'}
                                                                    </span>
                                                                    <span className='flex-1 text-sm flex justify-center'>
                                                                        {instrument?.isActiveUpdated
                                                                            ? getTag(
                                                                                  instrument?.active,
                                                                                  'Activated',
                                                                                  'Disabled',
                                                                              )
                                                                            : '-'}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        )
                                                    })}
                                            </Row>
                                        </section>
                                    )}
                                    {item?.isInternationalUpdated &&
                                        PaymentMethods.CREDIT_CARD === item.method &&
                                        item?.instruments?.length > 0 && (
                                            <section className='mt-1 flex gap-2 mt-3 border border-solid border-gray-200 rounded-lg p-2 items-center'>
                                                <span className='text-sm'>International -</span>
                                                {item?.isInternationalUpdated
                                                    ? getTag(
                                                          item?.instruments[0]?.data?.international_enabled,
                                                          'Activated',
                                                          'Disabled',
                                                      )
                                                    : ''}
                                            </section>
                                        )}
                                </section>
                            ))}
                        </Row>
                    </>
                )}
            </Drawer>
            <Modal
                title={'Deactive Terminal ?'}
                footer={null}
                onCancel={() => setShowModal(false)}
                open={showModal ? true : false}
                centered
                width={'450px'}
            >
                <p>Are you sure you want to deactive this terminal?</p>
                <Row className='flex justify-end gap-2 mt-4'>
                    <Col>
                        <Button className='w-40' onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </Col>
                    <Col>
                        <Button variant='primary' className='w-40' onClick={deactivateTerminal}>
                            Deactivate Terminal
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </>
    )
}
