export const UPIIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='14' height='18' viewBox='0 0 14 18' fill='none'>
            <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M13.8512 8.39989L10.057 0.745194C9.50979 -0.358798 7.88815 -0.201341 7.56354 0.987295L7.31168 1.90956L6.73638 0.747177C6.18965 -0.357471 4.5673 -0.200447 4.24257 0.988543L0.0611706 16.2986C-0.297228 17.6109 1.30147 18.5605 2.28239 17.618L3.33179 16.6097C3.27693 17.7532 4.70055 18.4861 5.60255 17.6201L13.5786 9.96168C14.0007 9.55642 14.111 8.92416 13.8512 8.39989ZM7.75928 2.81394L10.5242 8.40036C10.7835 8.92437 10.6732 9.55605 10.2516 9.96113L4.22651 15.7501L4.02802 16.4769C3.84887 17.1329 4.64795 17.6077 5.13847 17.1367L13.1145 9.47836C13.3256 9.27573 13.3807 8.9596 13.2508 8.69746L9.45665 1.04277C9.18305 0.490771 8.37223 0.569499 8.20992 1.16382L7.75928 2.81394Z'
                fill='#004B8D'
            />
        </svg>
    )
}

export const CardIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='14' viewBox='0 0 18 14' fill='none'>
            <path
                d='M4 0.570312C1.79086 0.570312 0 2.36117 0 4.57031V9.42746C0 11.6366 1.79086 13.4275 4 13.4275H14C16.2091 13.4275 18 11.6366 18 9.42745V4.57031C18 2.36117 16.2091 0.570312 14 0.570312H4ZM10.9286 10.856C9.86143 10.856 9 9.9946 9 8.92745C9 7.86031 9.86143 6.99888 10.9286 6.99888C11.9957 6.99888 12.8571 7.86031 12.8571 8.92745C12.8571 9.9946 11.9957 10.856 10.9286 10.856ZM13.5 10.856C13.2429 10.856 12.9986 10.8046 12.7736 10.7146C13.2236 10.2517 13.5 9.62174 13.5 8.92745C13.5 8.23317 13.2236 7.60317 12.7736 7.14031C12.9986 7.05031 13.2429 6.99888 13.5 6.99888C14.5671 6.99888 15.4286 7.86031 15.4286 8.92745C15.4286 9.9946 14.5671 10.856 13.5 10.856Z'
                fill='#004B8D'
            />
        </svg>
    )
}

export const CODIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='14' viewBox='0 0 18 14' fill='none'>
            <path
                d='M18 4.57031C18 2.36117 16.2091 0.570312 14 0.570312H4C1.79086 0.570312 0 2.36117 0 4.57031V9.42746C0 11.6366 1.79086 13.4275 4 13.4275H14C16.2091 13.4275 18 11.6366 18 9.42745V4.57031ZM11.97 4.42746H10.2857C10.5107 4.74888 10.6714 5.11531 10.755 5.51388H11.9764V6.7996H10.755C10.5364 7.7896 9.84214 8.5996 8.92286 8.96603L11.4557 11.4989H9.63643L6.02357 7.88603H7.81714C8.54357 7.88603 9.16071 7.43603 9.41786 6.7996H6.29357V5.51388H9.41786C9.16071 4.87746 8.54357 4.42746 7.81714 4.42746H6.29357V3.14174H11.9764V4.42746H11.97Z'
                fill='#004B8D'
            />
        </svg>
    )
}

export const WalletIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='16' viewBox='0 0 18 16' fill='none'>
            <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M12.6667 4.66406C10.8267 4.66406 9.33333 6.1574 9.33333 7.9974C9.33333 9.8374 10.8267 11.3307 12.6667 11.3307H18C18 13.5399 16.2091 15.3307 14 15.3307H4C1.79086 15.3307 0 13.5399 0 11.3307V4.66406C0 2.45492 1.79086 0.664062 4 0.664062H14C16.2091 0.664062 18 2.45492 18 4.66406H12.6667ZM14.6667 7.9974C14.6667 9.10197 13.7712 9.9974 12.6667 9.9974C11.5621 9.9974 10.6667 9.10197 10.6667 7.9974C10.6667 6.89283 11.5621 5.9974 12.6667 5.9974C13.7712 5.9974 14.6667 6.89283 14.6667 7.9974Z'
                fill='#004B8D'
            />
        </svg>
    )
}

export const BNPLIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
            <path
                d='M18.8025 9.25402C18.3592 8.15602 16.9526 8.08444 16.1152 8.92175L14.2283 10.8087C13.7243 11.3127 13.6287 12.1193 13.2712 12.7359C13.1524 12.9407 12.9784 13.1224 12.748 13.2606C12.2836 13.5418 11.6663 13.5295 11.2141 13.2301C10.4013 12.6984 10.3219 11.5984 10.9636 10.9568C11.0562 10.8642 11.1576 10.7871 11.2651 10.7253C11.8813 10.3708 12.6843 10.275 13.1869 9.77227L15.0802 7.87902C15.9161 7.04309 15.8464 5.63892 14.7505 5.1955C13.6172 4.73694 12.3432 4.55463 11.0125 4.72953C7.66967 5.16953 5.01134 7.94398 4.69967 11.299C4.27189 15.9007 8.10356 19.7262 12.7052 19.2984C16.0602 18.9868 18.8347 16.3284 19.2686 12.9856C19.4431 11.6574 19.2595 10.3857 18.8025 9.25402Z'
                fill='#004B8D'
            />
            <path
                d='M16.667 4.66406L19.3337 7.33073V6.66406C19.3337 5.55949 18.4382 4.66406 17.3337 4.66406H16.667Z'
                fill='#004B8D'
            />
            <path
                d='M12.9997 2.33594H10.9997C10.6315 2.33594 10.333 2.63441 10.333 3.0026C10.333 3.37079 10.6315 3.66927 10.9997 3.66927H12.9997C13.3679 3.66927 13.6663 3.37079 13.6663 3.0026C13.6663 2.63441 13.3679 2.33594 12.9997 2.33594Z'
                fill='#004B8D'
            />
        </svg>
    )
}

export const EMIIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
            <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M0 4C0 1.79086 1.79086 0 4 0H11.2308C13.4399 0 15.2308 1.79086 15.2308 4L15.2308 5.53846H9.69231C7.3982 5.53846 5.53846 7.3982 5.53846 9.69231V15.2308L4 15.2308C1.79086 15.2308 0 13.4399 0 11.2308V4ZM4.84615 3.46154V2.07692H3.46154V3.46154H2.07692V4.84615H3.46154V6.23077H4.84615V4.84615H6.23077V3.46154H4.84615Z'
                fill='#004B8D'
            />
            <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M9.69208 6.92188H15.2305C16.7599 6.92188 17.9998 8.1617 17.9998 9.69111V15.2296C17.9998 16.759 16.7599 17.9988 15.2305 17.9988H9.69208C8.16268 17.9988 6.92285 16.759 6.92285 15.2296V9.69111C6.92285 8.1617 8.16268 6.92188 9.69208 6.92188ZM10.9382 15.6451L15.0921 10.1066L13.9844 9.27588L9.83052 14.8143L10.9382 15.6451ZM15.9229 14.1913C15.9229 14.7649 15.4579 15.2298 14.8844 15.2298C14.3109 15.2298 13.8459 14.7649 13.8459 14.1913C13.8459 13.6178 14.3109 13.1529 14.8844 13.1529C15.4579 13.1529 15.9229 13.6178 15.9229 14.1913ZM10.0382 11.7683C10.6118 11.7683 11.0767 11.3033 11.0767 10.7298C11.0767 10.1563 10.6118 9.69133 10.0382 9.69133C9.46472 9.69133 8.99979 10.1563 8.99979 10.7298C8.99979 11.3033 9.46472 11.7683 10.0382 11.7683Z'
                fill='#004B8D'
            />
        </svg>
    )
}
