import { Button, Divider } from 'gokwik-ui-kit'
import { StickerSVG } from '../utils'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getUserData } from '@store/user/selectors'
import { useSelector } from 'react-redux'

const XSellBanner = ({ previewImage, campaignConfigs }) => {
    const userData = useSelector(getUserData)
    console.log(previewImage)

    return (
        <div className={`${campaignConfigs?.setPaddingZero ? 'p-0' : 'p-3'} rounded-lg flex gap-4 transform scale-90`}>
            <div
                className={`bg-white rounded-md shadow-sm flex flex-col gap-2 ${
                    campaignConfigs?.setPaddingZero ? 'p-0' : 'p-4'
                } w-[400px] min-h-[355px]`}
            >
                {campaignConfigs?.media_url && (
                    <div className='w-full aspect-video max-h-52'>
                        {(previewImage || campaignConfigs?.media_url) && (
                            <img
                                src={previewImage || campaignConfigs?.media_url}
                                className='w-full h-full object-cover'
                                onError={(e) => console.log('Image failed to load:', e)}
                            />
                        )}
                    </div>
                )}

                <div className='inline-flex gap-1 items-center bg-gradient-to-r from-blue-200 to-white p-0.5 rounded-md w-24 text-[#4096FF] text-[10px] font-bold'>
                    <div>
                        <StickerSVG />
                    </div>

                    <div>{campaignConfigs?.name}</div>
                </div>
                <div className='text-sm font-medium text-[#000000E0] text-left w-full'>
                    {<div dangerouslySetInnerHTML={{ __html: campaignConfigs?.title }}></div>}
                </div>
                <div className='text-xs font-normal text-[#000000E0] text-justify'>
                    {<div dangerouslySetInnerHTML={{ __html: campaignConfigs?.description }}></div>}
                </div>

                <Button
                    className='w-full'
                    variant='primary'
                    onClick={() => {
                        logEvent(
                            'es_modal_cta_clicked',
                            'click',
                            window?.location?.href,
                            userData?.userDetails?.email,
                            userData?.merchantDetails?.m_id,
                            userData?.merchantDetails?.short_name,
                            userData?.userDetails?.name,
                            { ctaURL: campaignConfigs?.cta_url },
                        )
                        window.open(campaignConfigs.cta_url, '_blank')
                    }}
                >
                    {campaignConfigs?.cta_text}
                </Button>
            </div>
        </div>
    )
}

export default XSellBanner
