import { dashboardStore, emptyUserData, makeAPICall, setMode as setSharedStoreMode } from '@gokwik/utilities'
import useAESEncryption from '@library/utilities/authorization/encryption'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { MerchantConfigKeys, PermissionValues } from '@library/utilities/constants/constants'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { AnyAction, Dispatch, createSlice } from '@reduxjs/toolkit'
import { message } from 'gokwik-ui-kit'
import { navigateToUrl } from 'single-spa'
const routesWithPermissions = [
    {
        route: '/checkout/analytics',
        permission: PermissionValues.checkoutAnalytics.view,
        configKey: MerchantConfigKeys.checkoutAnalytics,
    },
    {
        route: '/checkout/orders',
        permission: PermissionValues.orders.view,
    },
]

const userSlice = createSlice({
    name: 'userSlice',
    initialState: {
        config: dashboardStore.getState().userData?.config || {},
        merchantDetails: dashboardStore.getState().userData?.merchant_details || {},
        userDetails: dashboardStore.getState().userData?.user_details || {},
        kycData: dashboardStore.getState().kycData || {},
        mode: '',
        signup: {
            email: '',
        },
        appEmbedData: {
            enable_on_main_theme: false,
            enable_on_selected_theme: false,
        },
        notification: {
            message: '',
        },
    },
    reducers: {
        setSignUpEmail: (state, action) => {
            state.signup.email = action.payload
        },
        setUserData: (state, action) => {
            state.userDetails = action.payload.user_details || {}
            state.config = action.payload.config || {}
            state.merchantDetails = action.payload.merchant_details || {}
            state.kycData = action.payload.kyc_details || {}
            state.appEmbedData = action.payload.appEmbedData
            if (action.payload.mode) state.mode = action.payload.mode || ''
        },
        setMode: (state, action) => {
            state.mode = action.payload || ''
        },
        setNotification: (state, action) => {
            state.notification = action.payload
        },
    },
})

export const { setSignUpEmail, setUserData, setMode, setNotification } = userSlice.actions
export const sendVerifyEmailOTP =
    (data: { email: string }, callback: (err?: any) => void, isSignIn?: boolean) =>
    async (dispatch: Dispatch<AnyAction>) => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.sendOTP,
            payload: data,
            params: {
                isSignIn: isSignIn,
            },
        })
        if (response.success) {
            callback()
            return dispatch(setSignUpEmail(data.email))
        } else if (response.error) {
            callback({ error: true, errorMessage: response.response.data.message })
        }
    }

export const signin = (data: any, navigate?: any) => async (dispatch: any) => {
    const password = await useAESEncryption(data.password, process.env.REACT_APP_PUBLIC_CRYPTO_KEY)
    let response = await makeAPICall({
        method: 'post',
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.signin,
        payload: {
            ...data,
            password,
        },
    })

    const otpAlreadySent =
        response?.response?.data?.status_code === 400 && response?.response?.data?.message?.includes('otp already sent')

    if ((response?.success && response?.data?.status_code === 200) || otpAlreadySent) {
        logEvent('login_page_password_submitted', 'click', 'login', data.email)
        await dispatch(setSignUpEmail(data.email))
        navigate('/verify-otp', { state: { signIn: true } })
        if (otpAlreadySent) {
            message.success('OTP already sent to your email')
        }
    } else if (response?.error && !otpAlreadySent) {
        logEvent('login_page_validation_fail', 'click', 'login', data.email)
        if (response.response.data.status_code === 403) {
            dispatch(setSignUpEmail(data.email))
            dispatch(
                sendVerifyEmailOTP({ email: data.email }, (err) => {
                    if (!err) navigateToUrl('/verify-otp')
                    else message.error(err.errorMessage)
                }),
                true,
            )

            return
        }

        // let errorMsg = response.response && response.response.data ? response.response.data.data : 'Something failed'
        message.error(response?.response?.data?.message || 'Invalid Credentials')
    }
}

export const verifyOTP = (data: any, signIn?: boolean) => async (dispatch: any) => {
    let response = await makeAPICall({
        method: 'post',
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.verifyOTP,
        payload: {
            ...data,
        },
        params: {
            isSignIn: signIn,
        },
    })

    if (response.success) {
        logEvent('login_page_otp_submitted_success', 'click', 'login', localStorage.getItem('email'))
        logEvent('dashboard_landed_success_via_login_page', 'click', 'login', localStorage.getItem('email'))
        localStorage.setItem('merchant-mid', process.env.DEFAULT_MERCHANT_ID)
        if (response.data.data?.current_dashboard && response.data.data?.current_dashboard === 'old') {
            window.location.replace(
                process.env.REACT_APP_OLD_DASHBOARD_URL + '/login' + `?t=${response.data.data.token}`,
            )
            return
        }

        if (response.data.data.token?.token && response.data.data.token?.otp_verified) {
            dispatch(setSignUpEmail(data.email))
            localStorage.setItem('temptoken', response.data.data.token.token)
            navigateToUrl('/change-password')
        } else {
            localStorage.setItem('currentPage', 'dashboard')
            // localStorage.setItem('token', response.data.data.token)
            localStorage.setItem('email', data.email)
            if (!response.data.data.is_internal_user) {
                localStorage.setItem(
                    'mode',
                    response.data.data?.role?.permissions?.includes?.('live_mode') ? 'live' : 'test',
                )
            }
            const userPath = await dispatch(fetchUserDetails(null, true))
            const redirectTo = sessionStorage.getItem('redirectTo') || userPath || '/checkout/analytics'
            navigateToUrl(redirectTo)
            sessionStorage.removeItem('redirectTo')
        }
    } else if (response.error) {
        logEvent('login_page_validation_fail', 'click', 'login', data.email)
        if (response.response.data.status_code === 403) {
            dispatch(setSignUpEmail(data.email))
            dispatch(
                sendVerifyEmailOTP({ email: data.email }, (err) => {
                    if (!err) navigateToUrl('/verify-otp')
                    else message.error(err.errorMessage)
                }),
            )

            return
        }
        message.error(response.response?.message || 'Invalid OTP')
    }
}

export const changeMode = (mode: string) => async (dispatch: any) => {
    localStorage.setItem('mode', mode)
    setSharedStoreMode(mode)
    dispatch(setMode(mode))
}

export const switchMerchant = (merchant_id: string, breadCrumbsData: any) => async (dispatch) => {
    let detailsPage = breadCrumbsData[2] ? breadCrumbsData[2].key : ''
    if (
        detailsPage &&
        (detailsPage === 'order-details' || detailsPage === 'payment-link-details' || detailsPage === 'abandoned_cart')
    ) {
        navigateToUrl(breadCrumbsData[1].href)
    }

    const response = await makeAPICall({
        method: 'post',
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.switchMerchant,
        payload: {
            merchant_id,
        },
        skipMode: true,
    })
    if (response.success) {
        const mode = response.data.data.merchant.kyc_completion_status === 'Approved' ? 'live' : 'test'
        // localStorage.setItem('token', response.data.data.token)
        dispatch(fetchUserDetails(mode === 'test'))
    }
}

export const logout = () => async (dispatch: any) => {
    const response = await makeAPICall({
        method: 'get',
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.logout,
    })
    if (response.success) {
        localStorage.removeItem('token')
        document.cookie = `${encodeURIComponent('token')}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`
        document.cookie = `${encodeURIComponent('userSessionId')}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
        dispatch(setUserData({}))
        emptyUserData()
        navigateToUrl('/login')
    } else if (response.error) {
        let errorMsg = response.response && response.response.data ? response.response.data.message : 'Something failed'
        message.error(errorMsg)
    }
}
export const getDestination = (kwik_checkout_app_status, kwikpass_app_status, latest_app_onboarding_in_progress) => {
    const destinationMap = {
        kwik_checkout_app: () => {
            if (kwik_checkout_app_status === 'password_setup') {
                //commenting the following path as we are skiping the COD page
                //return '/onboarding/get-started'
                return '/onboarding/kc/onboarding-steps'
            } else if (
                kwik_checkout_app_status?.includes('kyc') ||
                kwik_checkout_app_status === 'kwik_checkout_button_activated' ||
                kwik_checkout_app_status === 'setup_completed' ||
                kwik_checkout_app_status === 'smart_cod_app_disabled'
            ) {
                return '/checkout/settings'
            } else if (
                [
                    'smart_cod_app_installed',
                    'smart_cod_app_activated',
                    'gokwik_shipping_setup_done',
                    // 'smart_cod_app_disabled',
                    // 'imp_checks_passed',
                ]?.includes(kwik_checkout_app_status)
            ) {
                return '/onboarding/kc/onboarding-steps'
            } else {
                //commenting the following path as we are skiping the COD page
                //return '/onboarding/get-started'
                return '/onboarding/kc/onboarding-steps'
            }
        },
        kwikpass_app: () => {
            return '/onboarding/kp/onboarding-steps'
        },
    }

    if (
        destinationMap.hasOwnProperty(latest_app_onboarding_in_progress) &&
        typeof destinationMap[latest_app_onboarding_in_progress] === 'function'
    ) {
        return destinationMap[latest_app_onboarding_in_progress]()
    }
    return '/checkout/settings'
}

export const fetchUserDetails = (skipMode?: boolean, reRouting?: boolean) => async (dispatch: any) => {
    let response = await makeAPICall({
        method: 'get',
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.userDetails,
    })

    if (response.success) {
        const data = response.data.data

        //setting merchant-mid in localstorage to be sent in the header of all api calls of AD 2.0
        if (data?.merchant_details?.m_id && data?.user_details?.is_internal_user) {
            localStorage.setItem('merchant-mid', data?.merchant_details?.m_id || process.env.DEFAULT_MERCHANT_ID)
        }
        //now we are also checking for empty string in kyc_completion_status due to merchants coming directly from shopify app install
        if (!skipMode && !data.user_details.is_internal_user) {
            const mode =
                data?.config?.enhancer_app ||
                data?.merchant_details?.kyc_completion_status === '' ||
                (data?.user_details?.role?.permissions?.includes?.('live_mode') &&
                    data?.merchant_details?.kyc_completion_status === 'Approved')
                    ? 'live'
                    : 'test'
            localStorage.setItem('mode', mode)
            data.mode = mode
        }
        dispatch(setUserData(data))
        dashboardStore.getState().setUserData(data)
        dispatch(fetchNotification())

        if (sessionStorage.getItem('redirectTo')) {
            navigateToUrl(sessionStorage.getItem('redirectTo'))
        }

        if (reRouting) {
            if (data?.user_details?.is_internal_user) return '/checkout/analytics'
            // if (data.user_details.is_internal_user) return '/checkout/orders'

            if (
                ((data.merchant_details.onboarding_status?.kwik_checkout_app &&
                    data.merchant_details.onboarding_status?.kwik_checkout_app !== 'setup_completed') ||
                    (data.merchant_details.onboarding_status?.kwikpass_app &&
                        data.merchant_details.onboarding_status?.kwikpass_app !== 'setup_completed')) &&
                data.merchant_details.platform === 'shopify'
            ) {
                const destination = getDestination(
                    data.merchant_details.onboarding_status?.kwik_checkout_app,
                    data.merchant_details.onboarding_status?.kwikpass_app,
                    data?.merchant_details?.latest_app_onboarding_in_progress,
                )

                return destination
            }
            if (data.config.selected_products?.kwik_pass) {
                return '/kwikpass/configuration'
            }
            if (
                data.merchant_details.onboarding_status?.kwik_checkout_app === 'setup_completed' &&
                data.merchant_details.platform === 'shopify'
            ) {
                return routesWithPermissions.find(
                    (item) =>
                        data.user_details.role?.permissions?.includes(item.permission) &&
                        (item.configKey ? data.config?.[item.configKey] : true),
                ).route
            }
        } else {
            const currentUrl = window.location.pathname
            if (currentUrl.includes('checkout/analytics') && !data.config?.[MerchantConfigKeys.checkoutAnalytics]) {
                navigateToUrl('/checkout/orders')
            }
        }
    } else if (response.error) {
        if (response.response.data.status_code !== 401) {
            let errorMsg =
                response.response && response.response.data ? response.response.data.message : 'Something failed'
            message.error(errorMsg)
        }
    }
}

export const verifyMerchantSignup = (data: any) => async (dispatch: any) => {
    // const password = await useAESEncryption(data.password, process.env.REACT_APP_PUBLIC_CRYPTO_KEY)
    let response = await makeAPICall({
        method: 'post',
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.verifyMerchant,
        payload: {
            ...data,
            // password,
        },
    })

    if (response.success) {
        localStorage.setItem('currentPage', '/onboarding/pricing-details')
        // localStorage.setItem('token', response.data.data.token)
        localStorage.setItem('email', data.email)
        const redirectTo = sessionStorage.getItem('redirectTo') || '/onboarding/pricing-details'
        sessionStorage.removeItem('redirectTo')
        dashboardStore.getState().setUserData(response.data.data)
        dispatch(setUserData(response.data.data))
        navigateToUrl(redirectTo)
    } else if (response.error) {
        if (response.response.data.status_code === 403) {
            dispatch(setSignUpEmail(data.email))
            dispatch(
                sendVerifyEmailOTP({ email: data.email }, (err) => {
                    if (!err) navigateToUrl('/verify-merchant')
                    else message.error(err.errorMessage)
                }),
            )

            return
        }
        let errorMsg = response.response && response.response.data ? response.response.data.message : 'Something failed'

        message.error(errorMsg)
    }
}

export const switchDashboard = () => {
    return async (dispatch) => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.switchDashboard,
            payload: {
                current_dashboard: 'old',
            },
            skipMode: true,
        })

        if (response.success) {
            window.location.replace(
                process.env.REACT_APP_OLD_DASHBOARD_URL + '/login' + `?t=${response.data.data.token}`,
            )

            localStorage.removeItem('token')
            document.cookie = `${encodeURIComponent('token')}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`
            dispatch(setUserData({}))
            dashboardStore.getState().setUserData({})
        } else if (response.error) {
            message.error(response.response.data.message || 'Unable to switch dashboard')
        }
        return response
    }
}
export const fetchNotification = (skipMode?: boolean, reRouting?: boolean) => async (dispatch: any) => {
    if (
        [
            '/signup',
            '/login',
            '/forgot-password',
            '/change-password',
            '/verify-otp',
            '/verify-merchant',
            '/set-password',
            '/existing-merchant',
            '/invitation/',
        ]?.includes(location.pathname)
    ) {
        return
    }
    let response = await makeAPICall({
        method: 'get',
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.shceduleNotificaiton,
    })
    if (response.success) {
        const data = response.data.data
        console.log('>>', data)
        dispatch(setNotification(data))
    } else if (response.error) {
        if (response.response.data.status_code !== 401) {
            let errorMsg =
                response.response && response.response.data ? response.response.data.message : 'Something failed'
            message.error(errorMsg)
        }
    }
}

export default userSlice.reducer
