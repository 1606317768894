import { ArrowRightOutlined, Button, Col, Row } from 'gokwik-ui-kit'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

export default function OnboardStep4({ showKycPrompt, showKycPromptPage, updateStatus, website }) {
    const [buttonClicked, setButtonClicked] = useState(false)
    const merchant_details = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)
    const activationFlag = localStorage.getItem('showKycPromptFlag');

    return (
        <Row className='bg-white p-4 py-6 border-solid border-gray-100 rounded-md '>
            <Col span={12}>
                <iframe
                    width='100%'
                    height='360'
                    src='https://www.youtube.com/embed/1M5Uz287Y4o?autoplay=1&mute=1'
                    className='rounded-xl'
                    allow='autoplay; encrypted-media'
                    allowFullScreen
                    title='video'
                ></iframe>
            </Col>

            <Col span={12}>
                <div className='bg-white w-full p-4 h-full'>
                    <p className='text-gray-900 font-inter text-xl font-semibold leading-7'>
                        Disable Manually COD App Method
                    </p>
                    <p className='text-gray-400 mt-2'>
                        Explain the need to install the app and what permissions are required
                    </p>
                    <ul className='mt-12 list-none p-0 '>
                        <li className='text-gray-800 flex'>
                            <ArrowRightOutlined className='text-lg text-black' />{' '}
                            <p className='pl-3'>Click on "Disable App" button that redirects to Shopify</p>
                        </li>
                        <li className='text-gray-800 flex py-5'>
                            <ArrowRightOutlined className='text-lg text-black' />{' '}
                            <p className='pl-3'>Follow the instructions as shown on animation, and activate app.</p>
                        </li>
                        <li className='text-gray-800 flex'>
                            <ArrowRightOutlined className='text-lg text-black' />{' '}
                            <p className='pl-3'>After the app activation come back to complete next quick step</p>
                        </li>
                    </ul>
                    <div className='flex gap-2'>
                        <Button
                            onClick={() => {
                                logEvent(
                                    'disable_app_button_clicked',
                                    'event',
                                    'onboarding',
                                    userDetails?.email,
                                    merchant_details?.m_id,
                                    merchant_details?.short_name,
                                    userDetails?.name,
                                )
                                setButtonClicked(true)
                            }}
                            href={`https://admin.shopify.com/store/${website}/settings/payments`}
                            target='_blank'
                            variant='primary'
                            className=' flex justify-center items-center w-fit'
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                viewBox='0 0 21 20'
                                fill='none'
                            >
                                <g clip-path='url(#clip0_6870_23394)'>
                                    <path
                                        d='M10.3132 5H5.31315C4.87112 5 4.4472 5.17559 4.13464 5.48816C3.82208 5.80072 3.64648 6.22464 3.64648 6.66667V15C3.64648 15.442 3.82208 15.866 4.13464 16.1785C4.4472 16.4911 4.87112 16.6667 5.31315 16.6667H13.6465C14.0885 16.6667 14.5124 16.4911 14.825 16.1785C15.1376 15.866 15.3132 15.442 15.3132 15V10'
                                        stroke='white'
                                        stroke-width='1.6'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                    />
                                    <path
                                        d='M9.47852 10.832L16.9785 3.33203'
                                        stroke='white'
                                        stroke-width='1.6'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                    />
                                    <path
                                        d='M12.8125 3.33203H16.9792V7.4987'
                                        stroke='white'
                                        stroke-width='1.6'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                    />
                                </g>
                                <defs>
                                    <clipPath id='clip0_6870_23394'>
                                        <rect width='20' height='20' fill='white' transform='translate(0.3125)' />
                                    </clipPath>
                                </defs>
                            </svg>
                            <span className='pl-2'>Disable App</span>
                        </Button>
                        <Button
                            variant='primary'
                            disabled={!buttonClicked}
                            onClick={() => {
                                logEvent(
                                    'next_button_clicked',
                                    'event',
                                    'onboarding',
                                    userDetails?.email,
                                    merchant_details?.m_id,
                                    merchant_details?.short_name,
                                    userDetails?.name,
                                )
                                showKycPrompt || activationFlag === 'true' ? showKycPromptPage() : updateStatus('setup_completed')
                            }}
                        >
                            I Did It, What Next?
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>
    )
}
