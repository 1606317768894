import React, { useEffect, useState } from 'react';
import { Button, Divider } from "gokwik-ui-kit";
import extraFreeCod from '@library/images/icons/extraFreeCod.svg';
import noExtraFeeCod from '@library/images/icons/noExtraFeeCod.svg';
import step1 from "@library/images/icons/circle-number-1.svg";
import step2 from "@library/images/icons/circle-number-2.svg";
import { getMerchantDetails, getUserDetails } from '@store/user/selectors';
import { useSelector } from 'react-redux';
import { updateMerchantOnboardingCheckoutConfigsStatus } from '../CommonComponents/Utils';
import CheckoutStepsHeader from '../CommonComponents/checkoutStepsHeader';
import { navigateToUrl } from 'single-spa';
import { fetchUserDetails } from '@store/user';
import { useAppDispatch } from '@library/utilities/hooks'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents';

const CodOnboardingStep2 = ({ onStepComplete, currentStep, expandedStep, toggleStep }) => {
    const [noExtraCharges, setNoExtraCharges] = useState(true);
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)
    const [showContent, setShowContent] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (expandedStep == 2) {
            setShowContent(false);
            setTimeout(() => setShowContent(true), 100);
        }
    }, [expandedStep]);

    const handleExtraCharges = () => {
        logEvent(
            "setup_cod_fee_clicked",
            'click',
            'cod-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            {Step : 'cod 2'},
        )
        navigateToUrl('/shopify-app/settings/shipping?' + 'cod-shipping-rate=' + true)
    }

    const handleShippingSetupDone = () => {
        logEvent(
            "continue_with_no_extra_charge_clicked",
            'click',
            'cod-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            {Step : 'cod 2'},
        )
        updateMerchantOnboardingCheckoutConfigsStatus('gokwik_shipping_setup_done', () => {
            dispatch(fetchUserDetails())
            onStepComplete();
        }, merchantDetails)
    }

    return (
        <div>
            <div className="border border-gray-300 rounded-lg bg-white w-[74vw]" >
                <CheckoutStepsHeader step={'Step 2'} heading={'Setup Shipping Method'} toggleStep={toggleStep} stepId={2} currentStep={currentStep} />

                {expandedStep === 2 ? (
                    <div style={{
                        opacity: showContent ? 1 : 0,
                        transform: showContent ? 'translateY(0)' : 'translateY(20px)',
                        transition: 'opacity 0.5s ease, transform 0.5s ease',
                    }}>
                        <Divider className='m-0'/>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <button style={{ border: 'none', outline: 'none', background: 'none', padding: '0', margin: '0', cursor: 'pointer', display: 'block'}} onClick={() => setNoExtraCharges(true)}>
                                    <div style={{ 
                                        borderRadius: '8px', 
                                        border: noExtraCharges ? '2px solid rgba(0, 75, 141, 1)' : '1px solid rgba(0, 0, 0, 0.15)' ,
                                        padding: '0.5rem', 
                                        display: 'flex', 
                                        justifyContent: 'center', 
                                        alignItems: 'center', 
                                        flexDirection: 'column', 
                                        height: '19rem', 
                                        width: '21rem', 
                                        margin: '1rem 0.5rem' 
                                    }}>
                                        <img className='mb-4' src={noExtraFeeCod} />
                                        <p className='mb-2' style={{ fontSize: '16px', fontWeight: '700', textAlign: "center", lineHeight: '20px' }}>I don’t charge extra fee on COD orders</p>
                                        <p className='mb-2' style={{ fontSize: '12px', fontWeight: '400', textAlign: "center", lineHeight: '20px' }}>Select this option if your COD orders are free of additional charges</p>
                                        <div style={{ height: '6.2rem' }}></div>
                                    </div>
                                </button>
                                <button style={{ border: 'none', background: 'none', outline: 'none', padding: '0', margin: '0', cursor: 'pointer', display: 'flex' }} onClick={() => setNoExtraCharges(false)}>
                                    <div style={{ border: noExtraCharges ? '1px solid rgba(0, 0, 0, 0.15)' : '2px solid rgba(0, 75, 141, 1)', borderRadius: '8px', padding: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '19rem', width: '21rem', margin: '1rem 0.5rem' }}>
                                        <img className='mb-4' src={extraFreeCod} />
                                        <p className='mb-2' style={{ fontSize: '16px', fontWeight: '700', textAlign: "center", lineHeight: '20px' }}>I charge extra fee on COD orders</p>
                                        <p className='mb-2' style={{ fontSize: '12px', fontWeight: '400', textAlign: "center", lineHeight: '20px' }}>Select this option if you charge extra fee on your COD orders</p>
                                        <div className="p-2 flex flex-col gap-3">
                                            <div className="flex items-start gap-1">
                                                <img src={step1} alt='Step 1'/>
                                                <span style={{ lineHeight: '22px' }} className="block text-left">Create the same shipping methods as on Shopify here.</span>
                                            </div>
                                            <div className="flex items-start gap-1">
                                                <img src={step2} alt='Step 1'/>
                                                <span style={{ lineHeight: '22px' }} className="block text-left">Map them to COD or Prepaid.</span>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        <Divider  className='m-0' />

                        <div  className={`flex flex-row-reverse justify-between items-center p-3`}>
                            {noExtraCharges ? (
                                <Button
                                    onClick={handleShippingSetupDone}
                                    variant='primary'
                                    className="flex items-center"
                                    size="large"
                                    disabled={(expandedStep === 2 && currentStep !== 2)}
                                >
                                    Continue with No Extra Charges on COD
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleExtraCharges}
                                    variant='primary'
                                    className="flex items-center"
                                    size="large"
                                    disabled={(expandedStep === 2 && currentStep !== 2)}
                                >
                                    Set COD Fee
                                </Button>
                            )}
                        </div>
                    </div>
                ) : "" }
            </div>
        </div>
    );
};

export default CodOnboardingStep2;
