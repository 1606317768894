import React, { useEffect } from 'react';
import { Button, Divider, message } from 'gokwik-ui-kit';
import { CheckCircleFilled, CheckOutlined, StarFilled } from '@ant-design/icons';
import greyStars from '@library/images/icons/greyStars.svg';
import greenCheck from '@library/images/icons/greenCheck.svg';
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useDispatch, useSelector } from 'react-redux';
import { navigateToUrl } from 'single-spa';
import { stepConfiguration } from '@pages/onboarding/stepsConfig';
import { makeAPICall } from '@gokwik/utilities';
import APIEndPoints from '@library/utilities/constants/apiEndpoints';
import { useAppDispatch } from '@library/utilities/hooks';
import { fetchUserDetails } from '@store/user';

const KYCActivation = () => {
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)
    const dispatch = useAppDispatch()
    const [messageApi, contextHolder] = message.useMessage()

    const startedKyc = () => {
        logEvent(
            'kyc_start_clicked',
            'click',
            'get_started',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { plan: 'cod_checkout' }
        )
        handleButtonClick('start_kyc_now')
    }

    const updateMerchantOnboardingStatus = async (status) => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url:
                    process.env.REACT_APP_BASE_URL +
                    APIEndPoints.updateMerchantOnboardingStatus +
                    merchantDetails?.m_id,
                payload: {
                    current_onboarding_status: status,
                },
            })

            if (response?.data?.status_code !== 200) {
                messageApi.open({
                    type: 'error',
                    content: 'Something went wrong. Try Later !',
                })
            }
            dispatch(fetchUserDetails())
        } catch (error) {
            console.log(error)
        }
    }

    const handleButtonClick = (cta) => {
        if (cta === 'do_it_later') {
            navigateToUrl('/shopify-app/settings/pincode')
        } else if (cta === 'start_kyc_now') {
            navigateToUrl('/onboarding/kyc')
        }
    }

    useEffect(() => {
        if (
            stepConfiguration[merchantDetails?.onboarding_status?.kwik_checkout_app]?.stepNumber <
            stepConfiguration['kyc_viewed']?.stepNumber
        ) {
            updateMerchantOnboardingStatus('kyc_viewed')
        }
    }, []);

    return (
        <div style={{ textAlign: 'center', fontFamily: 'inter', height: '100%'}}>
            <div style={{ background: "rgba(246, 255, 237, 1)", width: "100%", height: "30%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <img src={greenCheck}/>
                <div style={{ marginTop: 16, fontSize: "16px", color: 'rgba(152, 162, 179, 1)', fontWeight: '600' }}>
                    Congratulations
                </div>
                <div style={{ color: '#333', fontSize: "24px", marginBottom: 0 }}>
                    COD is Live on your store
                </div>
            </div>

            <div style={{ background: "rgba(255, 255, 255, 1)", width: "100%", height: "70%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                <div style={{ fontSize: "16px", lineHeight: "24px", color: 'rgba(152, 162, 179, 1)' }}>
                    Next step
                </div>
                <div style={{ fontSize: "24px", lineHeight: "32px", color: '#333', fontWeight: 500 }}>
                    Complete your KYC for Gokwik Payments activation
                </div>
                <div style={{ color: 'rgba(0, 110, 210, 1)', lineHeight: "20px", fontSize: "12px", marginBottom: "2rem", fontWeight: "400"}}>
                    Checkout will be enabled after 3-4 days of KYC completion
                </div>

                <div style={{ fontWeight: '600', fontSize: "12px", marginBottom: 8 }}>
                    Complete this step to access these features and more:
                </div>
                <ul style={{ listStyleType: 'none', display:"flex", alignItems: 'center', justifyContent: "center", flexDirection: "column", gap: 8, fontSize: "14px", fontWeight: '400', marginLeft: "-3rem" }}>
                    <li style={{ background: 'linear-gradient(90deg, rgba(21, 183, 158, 0) 0%, rgba(21, 183, 158, 0.80) 100%)', borderRadius: '30px', display: 'inline-flex', padding: '0.3rem 0rem'}}>
                        <CheckOutlined style={{ color: 'rgba(21, 183, 158, 1)', marginRight: 8 }} />
                        Address Autofill for ~80%* of Users <img src={greyStars} style={{marginLeft: 8, marginRight: "1rem"}}/>
                    </li>
                    <li>
                        <CheckOutlined style={{ color: 'rgba(21, 183, 158, 1)', marginRight: 8 }} />
                        Partial payment on cash on delivery
                    </li>
                    <li>
                        <CheckOutlined style={{ color: 'rgba(21, 183, 158, 1)', marginRight: 8 }} />
                        Discounts on prepaid payments
                    </li>
                </ul>

                <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "3rem"}} >
                    <Button
                        type="primary"
                        icon={<CheckCircleFilled />}
                        style={{ marginBottom: 8, padding: '1.5rem', display: "flex", justifyContent: "center", alignItems: "center", fontSize: "16px", fontWeight: '600' }}
                        onClick={startedKyc}
                    >
                        Start KYC and Register for Checkout
                    </Button>
                    <Button onClick={() => handleButtonClick('do_it_later')} type="link" style={{ color: 'rgba(22, 119, 255, 1)', fontSize: "14px", fontWeight: '400' }}>
                        Continue using COD, will do KYC Later
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default KYCActivation;
