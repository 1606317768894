import { Table, ColumnProps, Row, Button, TagTwoTone, Tag, Tooltip, EditOutlined } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { convertIsoToLocaleString, makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { handleError } from '@library/utilities/helpers/handleError'
import AddEditTerminals from './addEditTerminals'

export default function () {
    const columns: ColumnProps<any>[] = [
        {
            title: <span>Provider</span>,
            dataIndex: 'name',
            width: 200,
        },
        {
            title: <span>Status</span>,
            dataIndex: 'active',
            render: (e) => {
                return (
                    <span className='text-sm'>
                        <Tag
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                            variant={e ? 'success' : 'error'}
                        >
                            {e ? 'Active' : 'Inactive'}
                        </Tag>
                    </span>
                )
            },
        },
        {
            title: <span>Created At</span>,
            dataIndex: 'created_at',
            render: (e) => convertIsoToLocaleString(e),
        },
        {
            title: <span>Updated At</span>,
            dataIndex: 'updated_at',
            render: (e) => convertIsoToLocaleString(e),
        },
        {
            title: <span>Actions</span>,
            dataIndex: 'id',
            render: (id) => {
                return (
                    <Row className='gap-2'>
                        <Tooltip title={'Edit Terminal'}>
                            <EditOutlined
                                className='px-2 text-blue-500 cursor-pointer'
                                onClick={() => editTermial(id)}
                            />
                        </Tooltip>
                    </Row>
                )
            },
        },
    ]
    const [providers, setProviders] = useState([])
    const [openAddDrawer, setOpenAddDrawer] = useState<boolean>(false)
    const [editInfo, setEditInfo] = useState({ isEdit: false, id: '' })

    useEffect(() => {
        getGlobalTerminals()
    }, [])

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'providers',
                href: '/global-settings',
                text: 'Global Settings',
            },
            {
                key: 'providers',
                href: '/global-settings/global-terminals',
                text: 'Global Terminals',
            },
        ])
    }, [])

    async function getGlobalTerminals() {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.terminalConfigs.getTerminals('global'),
            })
            if (response.data?.data?.length > 0) {
                const activeProviders = response.data.data
                activeProviders.sort((a, b) => b.active - a.active)
                setProviders(activeProviders)
            } else {
                setProviders([])
            }
        } catch (error) {
            handleError(error)
        }
    }
    function editTermial(id: string) {
        setEditInfo({ isEdit: true, id })
        setOpenAddDrawer(true)
    }
    function resetState(fetchTerminals = false) {
        setOpenAddDrawer(false)
        setEditInfo({ isEdit: false, id: '' })
        if (fetchTerminals) getGlobalTerminals()
    }

    return (
        <div className='w-full h-full'>
            <div className='flex justify-between items-center'>
                <div>
                    <div className='flex items-baseline mb-1'>
                        <span className='pr-2'>
                            <TagTwoTone size={12} className='tag-icon' />
                        </span>
                        <p className='text-base font-medium mb-1'>Global Terminals</p>
                    </div>
                    <p className='text-xs text-gray-400 font-normal'>Add the onboarded payment partner details</p>
                </div>
                <Button onClick={() => setOpenAddDrawer(true)} variant='primary'>
                    Add Terminal
                </Button>
            </div>
            <div className='mt-4 bg-white rounded overflow-clip'>
                <Table
                    rowKey='id'
                    rowClassName={(record) => (record?.active ? '' : 'bg-gray-200')}
                    columns={columns}
                    dataSource={providers}
                    style={{
                        width: '100vw',
                    }}
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                />
            </div>

            {openAddDrawer && (
                <AddEditTerminals
                    isEdit={editInfo.isEdit}
                    id={editInfo.id}
                    open={openAddDrawer}
                    onCancel={resetState}
                />
            )}
        </div>
    )
}
