import {
    UserOutlined,
    PhoneOutlined,
    MailOutlined,
    StopOutlined,
    EnvironmentFilled,
    SolutionOutlined,
    CalendarOutlined,
    GlobalOutlined,
} from 'gokwik-ui-kit'

export const ICONS = {
    firstname: UserOutlined,
    lastname: UserOutlined,
    phone: PhoneOutlined,
    email: MailOutlined,
    is_customer_blocked: StopOutlined,
    city: EnvironmentFilled,
    country: GlobalOutlined,
    id: SolutionOutlined,
    created_at: CalendarOutlined,
    updated_at: CalendarOutlined,
}

export const LABELS = {
    firstname: 'First Name',
    lastname: 'Last Name',
    phone: 'Phone',
    email: 'Email',
    is_customer_blocked: 'Customer Blocked',
    id: 'Customer Id',
    country: 'Country',
    city: 'City',
    created_at: 'Created at',
    updated_at: 'Updated at',
}
