import { ArrowLeftOutlined, Button, Divider, ExportOutlined, message, Tag } from 'gokwik-ui-kit'
import MainIcon from '@library/images/icons/file-certificate.svg';
import { formatDateString } from '../CommonComponents/Utils'
import { navigateToUrl } from 'single-spa';
import { useSelector } from 'react-redux';
import { getUserData } from '@store/user/selectors';
import { useEffect, useRef, useState } from 'react';
import { makeAPICall } from '@gokwik/utilities';
import APIEndPoints from '@library/utilities/constants/apiEndpoints';

const GokwikPaymentAgreement = () => {
    const userData = useSelector(getUserData)
    const [eSignData, setEsignData] = useState(null)

    useEffect(() => {
        setLegalityData()
    }, [])

    const setLegalityData = async () => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.legality,
            })
            if (response?.data?.status_code === 200) {
                setEsignData(response?.data?.data)
            }
        } catch (error) {
            console.log('Error while fetching eSign Data', error)
        }
    }

    const { aus_name, business_address, city, pincode, state, business_category, business_name, aus_email } = userData?.kycData
    const tableData = [
        { key: 'Business Name', value: business_name },
        { key: 'Address', value: `${business_address}, ${city}, ${state}, ${pincode}` },
        { key: 'Authorised Signatory Name', value: aus_name },
        { key: 'Business Category', value: business_category },
        { key: 'Email ID', value: aus_email },
    ]

    const pollingRef = useRef(null)
    const startPolling = () => {
        if (!pollingRef.current) {
            pollingRef.current = setInterval(fetchLatestAgreementStatusWithPolling, 3000)
        }
    }
    const stopPolling = () => {
        if (pollingRef.current) {
            clearInterval(pollingRef.current)
            pollingRef.current = null
        }
    }

    const fetchLatestAgreementStatusWithPolling = async () => {
        await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.userDetails,
            method: 'get',
            skipLoader: true,
        })
        .then((result) => {
            if (result?.data?.data?.kyc_details?.sign_agreement_status === "Signed") {
                stopPolling()
                navigateToUrl("/onboarding/payment-setup?" + "gokwikk-agreement-signed=" + true);
            } else if (result?.data?.data?.kyc_details?.sign_agreement_status === "Failed") {
                stopPolling()
                message.error('Failed to sign agreement');
            }
        })
        .catch((err) => {
            console.warn(err)
        })
    }

    const signDocument = () => {
        stopPolling();
        window.open(eSignData?.sign_url, '_blank').focus()
        startPolling()
    }

    return (
        <div className='flex flex-col w-full pt-16'>
            <div className='flex flex-row gap-2 mt-8 ml-8'>
                <div
                    className='flex justify-center align-middle items-center border-2 border-solid border-gray-300  max-h-max p-4 rounded-md bg-gray-100 cursor-pointer'
                    onClick={() => {
                        navigateToUrl("/onboarding/payment-setup");
                    }}
                >
                    <ArrowLeftOutlined />
                </div>
                <div className='flex flex-col'>
                    <p className='inter text-xl font-semibold'>GoKwik Agreement</p>
                    <p className='inter text-sm font-normal text-gray-400'>
                        Sign your merchant agreement with GoKwik
                    </p>
                </div>
            </div>
            <div className='flex w-[800px] justify-center items-center mt-8 align-middle m-auto'>
                <div className='bg-white border border-solid w-full border-gray-300 rounded-md'>
                    <div className='flex gap-3 px-4 pt-4'>
                        <div className='flex justify-start items-start'>
                            <img src={MainIcon} width={24} />
                        </div>
                        <div className='flex justify-between items-center w-full mb-4'>
                            <div className='flex flex-col'>
                                <span className='text-base font-semibold mr-2'>
                                    GoKwik Agreement
                                    <span className='px-2'>
                                        <Tag
                                            variant='warning'
                                            className={`p-0 px-1 rounded-sm inter font-light inline-block capitalize text-xs`}
                                        >
                                            Pending
                                        </Tag>
                                    </span>
                                </span>
                                <span className='text-[#94380C] text-xs font-medium'>
                                    Sign before: {formatDateString(eSignData?.expiry_date)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <Divider className='m-0' />
                    <div className='px-4 pb-4'>
                        <div className='text-sm font-normal inter my-2'>
                            We will be sharing the agreement with the following details.
                        </div>
                        <div className='overflow-x-auto'>
                            <table className='table-auto border border-collapse border-solid border-gray-300 w-full rounded-lg text-sm font-normal inter'>
                                <tbody>
                                    {tableData?.map((item, index) => (
                                        <tr key={index} className='border border-solid border-gray-300'>
                                            <td className='px-4 py-2 border border-solid border-gray-300 text-gray-700'>
                                                {item?.key}
                                            </td>
                                            <td className='px-4 py-2 border border-solid border-gray-300 text-gray-700 font-semibold'>
                                                {item?.value}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Divider className='m-0' />
                    <div className='flex p-4 justify-end'>
                        <div className='flex justify-center items-center'>
                            <Button variant='primary' onClick={() => signDocument()} className='font-semibold cursor-pointer'>
                                Sign Agreement <ExportOutlined />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GokwikPaymentAgreement