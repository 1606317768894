import React, { useState, useEffect } from 'react'
import { Button, Col, Row, Tabs, Tooltip, message } from 'gokwik-ui-kit'
import TeamMembers from './teamMembers'
import InvitedMembers from './invitedMembers'
import { SearchOutlined, RedoOutlined, PlusCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { InviteOrEditTeamMember } from './inviteorEditTeamMember'
import { AllowedMerchant } from '@library/utilities/interface'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetails, isMerchantSelector } from '@store/user/selectors'
import { makeAPICall, validatePermission } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { PermissionValues } from '@library/utilities/constants/constants'
import { handleError } from '@library/utilities/helpers/handleError'

export default () => {
    const [action, setAction] = useState(null)
    const [openDrawer, setOpenDrawer] = useState(false)
    const isMerchantUser = useSelector(isMerchantSelector)
    const userData = useSelector(getUserDetails)
    const allowedMerchants: AllowedMerchant[] = userData?.allowed_merchants
    const [merchantList, setMerchantList] = useState([])
    const [roles, setRoles] = useState([])
    const [updateUserParams, setUpdateUserParams] = useState({})
    const [makeUserCall, setMakeUserCall] = useState(false)

    const takeAction = (id, record, user_action) => {
        setAction(user_action)

        if (user_action === 'update_user') {
            setUpdateUserParams({
                name: record?.name,
                email: record?.email,
                phone: record?.phone,
                role_id: record?.role_id,
                status: record?.status,
                id: record?.id,
                email_mask: record?.email_mask,
            })
        }
        setOpenDrawer(true)
    }

    const getMerchantList = async () => {
        try {
            if (isMerchantUser) {
                setMerchantList(allowedMerchants)
            } else {
                let response = await makeAPICall({
                    method: 'get',
                    url: process.env.REACT_APP_BASE_URL + APIEndPoints.getMerchantList,
                })

                if (response?.data?.data?.length) {
                    setMerchantList(response?.data?.data)
                }
            }
        } catch (err) {
            console.warn(err)
        }
    }

    const getUserRoles = async () => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getUserRoles,
            })
            console.log(response)

            if (response.data.data.roles.length > 0) {
                setRoles(response.data.data.roles?.filter((role) => role.permission_type === 'merchant'))
            } else {
                message.warning('No Roles Found')
            }
        } catch (error) {
            handleError(error)
        }
    }

    useEffect(() => {
        getMerchantList()
        getUserRoles()
    }, [])

    const items = [
        {
            key: '1',
            label: 'Team Members',
            children: (
                <TeamMembers
                    takeAction={takeAction}
                    makeUserCall={makeUserCall}
                    isMerchantUser={isMerchantUser}
                    userData={userData}
                />
            ),
        },
        {
            key: '2',
            label: 'Invited Members',
            children: <InvitedMembers merchantList={merchantList} isMerchantUser={isMerchantUser} />,
        },
    ]

    return (
        <>
            <InviteOrEditTeamMember
                setOpenDrawer={setOpenDrawer}
                openDrawer={openDrawer}
                action={action}
                allowedMerchants={allowedMerchants}
                isMerchantUser={isMerchantUser}
                merchantList={merchantList}
                roles={roles}
                updateUserParams={updateUserParams}
                makeUserApIcall={() => {
                    setMakeUserCall(!makeUserCall)
                }}
            />
            <div className='w-full h-full'>
                {validatePermission(PermissionValues.users.addUser) && (
                    <Row className='p-2 rounded bg-neutrals-15' align={'middle'} justify={'space-between'}>
                        <Col sm={12} className='flex items-center gap-x-2'>
                            <Row className='text-black inter m-0 tracking-wide items-center'>
                                <Tooltip
                                    title={
                                        'New users will get the details to sign up into the Gokwik platform to their respective email and must then follow along to sign up and use this dashboard.'
                                    }
                                >
                                    <InfoCircleOutlined className='text-blue fs20' />
                                </Tooltip>

                                <span className='ml-2'>Send invites to new team members here.</span>
                            </Row>
                        </Col>
                        <Col className='flex justify-end' sm={2}>
                            <Button onClick={() => takeAction(null, null, 'add_user')} variant='primary'>
                                <PlusCircleOutlined />
                                Invite New Members
                            </Button>
                        </Col>
                    </Row>
                )}
                <Tabs
                    tabBarStyle={{
                        paddingLeft: '1rem',
                        background: 'white',
                        maxWidth: '100%',
                        margin: 0,
                        borderTopLeftRadius: '0.25rem',
                        borderTopRightRadius: '0.25rem',
                        marginTop: '20px',
                    }}
                    className=' w-[100%] rounded-t'
                    items={items}
                />
            </div>
        </>
    )
}
