import Clock from '@library/images/icons/clock-bolt.svg';
import blurryStep from '@library/images/icons/blurryStep.svg'; 
import activeStep from '@library/images/icons/currentStep.svg'; 
import doneStep from '@library/images/icons/doneStep.svg';

const ConfigSteps = ({currentStep}) => {
    const getRequiredIcon = (incomingStep: number) => {
        if (currentStep === 4) {
            return doneStep
        } else if (incomingStep === currentStep) {
            return activeStep
        } else if (incomingStep < currentStep) {
            return doneStep
        } else if (incomingStep > currentStep) {
            return blurryStep
        }
    }

    const steps = [
        {
            id: 1,
            title: "Step 1",
            description: "Install COD Module",
            duration: "30-60 Sec",
            icon: getRequiredIcon(1),
            active: currentStep === 1 ? true : false,
        },
        {
            id: 2,
            title: "Step 2",
            description: "Setup Shipping Method",
            duration: "1-5 Min",
            icon: getRequiredIcon(2),
            active: currentStep === 2 ? true : false,
        },
        {
            id: 3,
            title: "Step 3",
            description: "Disable Shopify COD",
            icon: getRequiredIcon(3),
            active: currentStep === 3 ? true : false,
        }
    ];

    return (
        <div style={{ height: 'max-content'}} className="w-56 pt-4 bg-white rounded-md py-4">
            {steps.map((step, index) => (
                <div
                    key={step.id}
                    className="relative flex items-start p-4"
                    style={{ background: step.active ? 'linear-gradient(90deg, rgba(230, 237, 244, 1) 0%, rgba(255, 255, 255, 1) 100%)' : "#FFFFFF" }}
                >
                    {step.active ? <div className="absolute left-0 top-0 bottom-0 w-[3px] bg-[#004B8D]"></div> : ""}
                    <div>
                        <div className="mr-3 mb-1"><img src={step.icon}></img></div>
                        <div className="mr-3 h-16 w-0 text-center ml-1" style={{ border: "1.6px solid rgba(0, 0, 0, 0.06)" }} ></div>
                    </div>
                    <div>
                        <span style={{ color: step.active ? "#2A55E5" : "rgba(0, 0, 0, 0.25)" }} >{step.title}</span>
                        <div>
                            <div className={`mt-1 mb-1 text-black ${step.active ? "font-bold" : "font-normal"} leading-6`} >{step.description}</div>
                            {(step.duration && step.id >= currentStep) ? (
                                <div className="bg-[rgba(0,0,0,0.04)] rounded px-1 inline-block" ><img src={Clock}></img> <span>{step.duration}</span></div>
                            ) : (step.id === 3 && step.id >= currentStep) ? (
                                ""
                            ) : (
                                <div className="bg-[rgba(239,254,250,1)] rounded p-1 text-[rgba(3,182,150,1)] text-xs font-normal inline-block" >Complete</div>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ConfigSteps;
