import { useState, useEffect } from 'react'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getUserData, getMerchantDetails } from '@store/user/selectors'
import { Row, Col, Button, message } from 'gokwik-ui-kit'
import { useSelector } from 'react-redux'

export const Integration_1 = ({ setCurrentIntegrationScreen, navigateToStep, getCodeForManualTheme }) => {
    const userDetails = useSelector(getUserData)
    const merchant_details = useSelector(getMerchantDetails)
    const [showThemeExtension, setShowThemeExtension] = useState(false)

    useEffect(() => {
        const checkThemeCompatibility = async () => {
            try {
                const response = await makeAPICall({
                    method: 'get',
                    url: process.env.KWIKPASS_DASHBOARD_BASE_URL + APIEndPoints.shopifyThemeCompatibility,
                    headers: {
                        'kp-merchant-id': merchant_details?.m_id,
                    },
                })
                if (response?.data?.status_code === 200) {
                    setShowThemeExtension(response?.data?.data?.isAllowed)
                }
            } catch (error) {
                console.error('Error checking theme compatibility:', error)
                setShowThemeExtension(false)
            }
        }
        checkThemeCompatibility()
    }, [merchant_details?.m_id])

    const updateOnboardingStatus = async (onBoardStatus, onSuccess, onError) => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url:
                    process.env.REACT_APP_BASE_URL +
                    APIEndPoints.updateMerchantOnboardingStatus +
                    merchant_details.m_id,
                payload: {
                    kp_onboarding_status: onBoardStatus,
                },
            })
            if (response?.data?.status_code === 200 || response?.data?.status_code === 201) {
                onSuccess()
            } else {
                onError()
            }
        } catch (e) {
            onError()
        }
    }

    return (
        <div className='mt-6 mx-12 p-4 rounded-lg bg-white'>
            <Col span={16}>
                <div className='flex flex-col gap-6 mb-4'>
                    <div>
                        <span className='text-xl font-semibold'>
                            Integrating the app:{' '}
                            {showThemeExtension ? (
                                <>
                                    Use <span className='text-orange-400'>either</span> Theme App Extension{' '}
                                    <span className='text-orange-400'>or</span> Manual Integration
                                </>
                            ) : (
                                'Manual Integration'
                            )}
                        </span>{' '}
                        <br />
                    </div>
                </div>
                <div className='flex flex-col gap-16'>
                    <div className='flex flex-col gap-8'>
                        {showThemeExtension && (
                            <>
                                <div className='flex flex-col gap-4'>
                                    <div className='text-xl font-semibold'>Option 3.1: Add Shopify App Extension</div>
                                    <p className='text-neutral-500 font-normal'>
                                        This is no-code integration, appropriate for your theme!
                                    </p>
                                    <Button
                                        className='w-1/2'
                                        variant='primary'
                                        onClick={async () => {
                                            await updateOnboardingStatus(
                                                'auto_theme_extension_selected',
                                                () => {
                                                    logEvent(
                                                        'add_theme_extension_selected',
                                                        'click',
                                                        'intergration_1_screen',
                                                        userDetails?.userDetails?.email,
                                                        merchant_details?.m_id,
                                                        merchant_details?.short_name,
                                                    )
                                                    navigateToStep(null, 3)
                                                    setCurrentIntegrationScreen('add_theme_automatically')
                                                },
                                                () => {
                                                    message.error('Something went wrong. Please retry ! ')
                                                },
                                            )
                                        }}
                                    >
                                        Add Theme App Extension
                                    </Button>
                                </div>
                                <span className='text-center font-bold text-base'>OR</span>
                            </>
                        )}
                        <div className='flex flex-col gap-4'>
                            <div className='text-xl font-semibold'>
                                {showThemeExtension ? 'Option 3.2: ' : 'Step 3.1:'}Manually Integrate our app with your
                                Shopify Store
                            </div>
                            <p className='text-neutral-500 font-normal'>
                                You will be provided a piece of code and instructions to add it to your Shopify store.
                                This is advisable for all custom themes.
                            </p>
                            <Button
                                className='w-1/2'
                                variant='primary'
                                onClick={async () => {
                                    updateOnboardingStatus(
                                        'manual_theme_extension_selected',
                                        () => {
                                            getCodeForManualTheme()
                                            navigateToStep(null, 3)
                                            setCurrentIntegrationScreen('add_theme_manually')
                                        },
                                        () => {
                                            message.error('Something went wrong. Please retry ! ')
                                        },
                                    )
                                }}
                            >
                                Manually Integrate App
                            </Button>
                        </div>
                    </div>
                </div>
            </Col>
        </div>
    )
}
