import crossIcon from '@library/images/icons/whitecross.svg'
import avatar from '@library/images/icons/placeholder-avatar.png'
import exitIcon from '@library/images/icons/exitIcon.svg'
import { dashboardStore } from '@gokwik/utilities'
import { useEffect, useState } from 'react'
import { navigateToUrl } from 'single-spa'
import { UserOutlined } from '@ant-design/icons'
import user, { logout } from '@store/user'
import { useSelector } from 'react-redux'
import { getNotificationData, getUserDetails } from '@store/user/selectors'
import { useAppDispatch } from '@library/utilities/hooks'

const getUserInitials = (text: string) => {
    const names = text.split(' ')
    if (names.length > 1) {
        return `${names[0].charAt(0)}${names[1].charAt(0)}`
    } else return `${names[0].charAt(0)}${names[0].charAt(1)}`
}

const ProfileComponent = ({ isProfileDisplayed }) => {
    const userData = useSelector(getUserDetails)
    const [profileMenu, setProfileMenu] = useState(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        setProfileMenu(false)
    }, [isProfileDisplayed])

    return (
        <>
            <div className={`flex flex-col w-full px-2 gap-y-4 pb-4 mt-6`}>
                <div className='flex justify-between items-center pt-6 border-[0] border-t border-solid border-primary-600 px-2'>
                    <div className='flex items-center'>
                        <div
                            className='mr-2 cursor-pointer w-10 h-10 flex justify-center items-center bg-primary-750 rounded-lg'
                            onClick={() => setProfileMenu((prev) => !prev)}
                        >
                            <span className='uppercase font-semibold text-xs text-neutrals-15'>
                                {getUserInitials(userData?.name || '')}
                            </span>
                        </div>
                        <div className='flex flex-col w-28 truncate'>
                            <span className='text-primary-300 font-semibold w-full truncate text-sm'>
                                {userData?.name}
                            </span>
                            <span className='text-white font-semibold text-xs w-full truncate'>
                                {userData?.email || 'test@email.com'}
                            </span>
                        </div>
                    </div>

                    <img src={exitIcon} className='cursor-pointer' onClick={() => dispatch(logout())} />
                </div>
            </div>
            {profileMenu && isProfileDisplayed && (
                <div
                    className={
                        'flex bg-primary-700 rounded-md absolute bottom-[15px] left-[70px] min-w-[300px] max-w-2xl p-4'
                    }
                >
                    <div className='flex flex-col items-center justify-between gap-y-4  w-full'>
                        <div className='text-neutrals-15 font-semibold text-xs cursor-pointer w-full flex justify-between object-contain '>
                            <div className='text-primary-200'>
                                <p className='text-lg text-primary-300'>{userData?.name}</p>
                                <p className='font-light text-white'> {userData?.email || 'test@email.com'}</p>
                            </div>
                            <div
                                className='cursor-pointer w-10 h-10 flex justify-center items-center bg-primary-750 rounded-lg'
                                onClick={() => setProfileMenu((prev) => !prev)}
                            >
                                <span className='uppercase font-semibold text-xs text-neutrals-15'>
                                    {getUserInitials(userData?.name || '')}
                                </span>
                            </div>
                        </div>
                        <div
                            onClick={() => dispatch(logout())}
                            className='cursor-pointer flex hover:opacity-[.5] items-center w-[100%] justify-between'
                        >
                            <p className='cursor-pointer  text-white font-light '>Logout </p>
                            <img src={exitIcon} className='cursor-pointer' />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ProfileComponent
