import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getApprovalStatus } from '@store/user/selectors';
import incompleteStep from "@library/images/icons/incompleteStep.svg";
import blueCheck from "@library/images/icons/completeStep.svg";
import { newStepConfiguration } from '@pages/onboarding/stepsConfig';
import InstallKwikCheckout from './installKwikCheckoutModal';

export const ProgressStepper = () => {
    const merchant_details = useSelector(getMerchantDetails)
    const [currentOnboardingStatus, setCurrentOnboardingStatus] = useState('');
    const [isInstallModalVisible, setIsInstallModalVisible] = useState(false);
    const billingSubscriptionData = useSelector(getApprovalStatus);
    const approvedChargesStatus = billingSubscriptionData?.status

    useEffect(() => {
        const fetchData = async () => {
            setCurrentOnboardingStatus(merchant_details?.onboarding_status?.kwik_checkout_app);
            if (merchant_details?.onboarding_status?.kwik_checkout_app === 'kwik_checkout_app_uninstalled') {
                setIsInstallModalVisible(true);
            } else if (isInstallModalVisible) {
                setIsInstallModalVisible(false)
            }
        };
        fetchData();
    }, [merchant_details?.onboarding_status?.kwik_checkout_app])
    
    const isKycCompleted = () => {
        return merchant_details?.kyc_completion_status?.toLowerCase() === 'approved'
    }

    let userJourneySteps = [
        { id: 'cod-activation', text: 'Activate GoKwik COD' },
    ];
    if (merchant_details?.country && merchant_details?.country?.toLowerCase() === 'india') {
        userJourneySteps.push({ id: 'enable-gk-checkout', text: 'Enable GoKwik Checkout' });
        if (!isKycCompleted()) {
            userJourneySteps.push({ id: 'kyc-verification', text: 'Verify Your Business (KYC)' });
        }
        userJourneySteps.push({ id: 'payments-checkout-config', text: 'Enable Payment Options' });
    }

    const stepStatuses = useMemo(() => {
        const statuses: Record<string, string> = {};
        let currentStatus = currentOnboardingStatus;
        const codModuleStatus = merchant_details?.onboarding_status?.cod_module_status;
        const gkCheckoutStatus = merchant_details?.onboarding_status?.gk_checkout_status;
        const kycStatus = merchant_details?.onboarding_status?.kyc_status
        const gkPaymentsStatus = merchant_details?.onboarding_status?.gk_payments_status
        
        userJourneySteps.forEach((step) => {
            const currentStepId = newStepConfiguration[currentStatus]?.id;
    
            switch (step.id) {
                case 'cod-activation':
                    if (currentStepId >= newStepConfiguration['imp_checks_passed']?.id || codModuleStatus === 'imp_checks_passed') {
                        statuses[step.id] = 'Completed';
                    } else {
                        statuses[step.id] = 'Ongoing';
                    }
                    break;
    
                case 'enable-gk-checkout':
                    if (currentStepId >= newStepConfiguration['gk_checkout_buttons_not_working']?.id && approvedChargesStatus === 'APPROVED' || ['gk_checkout_buttons_not_working', 'gk_checkout_buttons_working'].includes(gkCheckoutStatus)) {
                        statuses[step.id] = 'Completed';
                    } else if (newStepConfiguration[currentStatus]?.id > newStepConfiguration['kyc_verification_failed']?.id) {
                        statuses[step.id] = 'Completed';
                    } else if (currentStepId < newStepConfiguration['imp_checks_passed']?.id) {
                        statuses[step.id] = 'Yet To Start';
                    } else {
                        statuses[step.id] = 'Ongoing';
                    }
                    break;
    
                case 'kyc-verification':
                    if (
                        currentStepId >= newStepConfiguration['kyc_completed']?.id ||
                        currentStepId >= newStepConfiguration['setup_completed']?.id ||
                        ['setup_completed', 'kyc_completed'].includes(kycStatus) 
                    ) {
                        statuses[step.id] = 'Completed';
                    } else if (currentStatus === 'kyc_verification_pending') {
                        statuses[step.id] = 'In-progress • ETA 2-3 Weeks';
                    } else if (currentStatus === 'kyc_verification_failed') {
                        statuses[step.id] = 'KYC Verification Failed';
                    } else if (currentStepId < newStepConfiguration['gk_checkout_buttons_not_working']?.id) {
                        statuses[step.id] = 'Yet To Start';
                    } else {
                        statuses[step.id] = 'Complete KYC to avoid extra charges';
                    }
                    break;
    
                case 'payments-checkout-config':
                    if (currentStepId >= newStepConfiguration['gk_payments_app_installed']?.id || gkPaymentsStatus === 'gk_payments_app_installed') {
                        statuses[step.id] = 'Completed';
                    } else if (currentStepId < newStepConfiguration['kyc_completed']?.id && !(isKycCompleted() && newStepConfiguration[currentStatus]?.id >=
                    newStepConfiguration['gk_checkout_buttons_not_working']?.id)) {
                        statuses[step.id] = 'Yet To Start';
                    } else {
                        statuses[step.id] = 'Ongoing';
                    }
                    break;
            }
        });

        return statuses;
    }, [currentOnboardingStatus]);

    const getCardStyle = (status) => {
        switch (status) {
            case 'KYC Verification Failed':
            case 'Complete KYC to avoid extra charges':
                return {
                    containerStyle: { border: '1px solid red', borderTop: '2px solid red', background: '#ffe6e6', fontWeight: '600' },
                    textStyle: { color: 'red', fontSize: '12px', fontWeight: '400' },
                };
            case 'Ongoing':
            case 'In-progress • ETA 2-3 Weeks':
                return {
                    containerStyle: { border: '1px solid #1677FF', borderTop: '2px solid blue', background: '#eff6fe', fontWeight: '600', color: 'black' },
                    textStyle: { color: 'rgba(0, 75, 141, 1)', fontSize: '12px', fontWeight: '400' },
                };
            case 'Completed':
                return {
                    containerStyle: { border: '0.2px solid rgba(0,0,0,0.3)', background: '#fff', color: 'rgba(0, 0, 0, 0.70)', fontWeight: '400' },
                    textStyle: { color: 'rgba(10, 91, 57, 1)', fontSize: '12px', fontWeight: '400' },
                };
            case 'Yet To Start':
                return {
                    containerStyle: { border: '0.2px solid rgba(0,0,0,0.3)', background: '#fff', color: 'rgba(0,0,0,0.7)' },
                    textStyle: { color: 'rgba(0,0,0,0.7)', fontSize: '12px', fontWeight: '400' },
                };
            default:
                return {
                    containerStyle: { border: '0.2px solid rgba(0,0,0,0.3)', background: '#fff' },
                    textStyle: { color: 'rgba(0,0,0,0.7)', fontSize: '12px', fontWeight: '400' },
                };
        }
    };

    return (
        <div className='w-full bg-[#fff]'>
            <div className='w-[full] bg-[#fff] mr-2 mb-2' style={{ padding: '0.6rem 2rem', borderRadius: '0.5rem' }}>
                <div style={{ boxSizing: 'border-box' }} className='flex justify-between items-center flex-wrap text-[14px] w-[full] gap-3'>
                    {userJourneySteps?.map((item, index) => {
                        const { containerStyle, textStyle } = getCardStyle(stepStatuses[item.id])
                        return (
                            <button
                                key={index}
                                style={{
                                    ...containerStyle,
                                    flex: '1',
                                    boxSizing: 'border-box',
                                    padding: '12px',
                                    textAlign: 'left',
                                    borderRadius: '6px',
                                }}
                                className={`flex items-center p-[10px] gap-1 text-[14px]`}
                                disabled={true}
                            >
                                {stepStatuses[item.id] === 'Completed' ? (
                                    <img src={blueCheck} className='w-[25px] h-[25px]' />
                                ) : stepStatuses[item.id] === 'KYC Verification Failed' ? (
                                    <img src={incompleteStep} className='w-[25px] h-[25px'/>
                                ) : stepStatuses[item.id] === 'Yet To Start' ? (
                                    <div className='w-[25px] h-[25px] flex items-center justify-center text-[rgba(0, 0, 0, 0.45)]' style={{ background: 'rgb(240, 240, 240)', borderRadius: '50%'}}>
                                        {index + 1}
                                    </div>
                                ) : (
                                    <div className='w-[25px] h-[25px] flex items-center justify-center text-[#fff]' style={{ background: '#1677FF', borderRadius: '50%' }} >
                                        {index + 1}
                                    </div>
                                )}
                                <div className='flex flex-col gap-1 ml-[10px]'>
                                    <div>{item.text}</div>
                                    <div style={textStyle}>{stepStatuses[item.id]}</div>
                                </div>
                            </button>
                        )
                    })}
                </div>
            </div>
            <InstallKwikCheckout isInstallModalVisible={isInstallModalVisible} setIsInstallModalVisible={setIsInstallModalVisible}/>
        </div>
    )
}
