import React, { useEffect, useState } from 'react';
import { Checkbox, Button, Divider, DownOutlined } from "gokwik-ui-kit";
import previewPlaceholder1 from '@library/images/icons/paymentStep1.png';
import darkExternalLink from '@library/images/icons/dark-external-link.svg';
import lightExternalLink from '@library/images/icons/light-external-link.svg';
import HowToStepsWithPreview from '../CommonComponents/howToStepsAndPreview';
import { logEvent } from "@library/utilities/userLogsEvents/userLogEvents";
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux';
import { updateMerchantOnboardingCheckoutConfigsStatus } from "../CommonComponents/Utils";
import CheckoutStepsHeader from '../CommonComponents/checkoutStepsHeader';
import { fetchUserDetails } from '@store/user';
import { useAppDispatch } from '@library/utilities/hooks'
import { navigateToUrl } from 'single-spa';

const PaymentActivationStep2 = ({ onStepComplete, currentStep, expandedStep, toggleStep }) => {
  const [siteVisited, setSiteVisited] = useState(false);
  const userDetails = useSelector(getUserDetails)
  const merchantDetails = useSelector(getMerchantDetails)
  const [showContent, setShowContent] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (expandedStep === 2 && currentStep !== 2) {
      setSiteVisited(true);
    }
    if (expandedStep == 2) {
      setShowContent(false);
      setTimeout(() => setShowContent(true), 100);
    }
  }, [expandedStep]);

  const steps = [
    { 
      description: (<>Click on <b>“Install GoKwik Payments App”</b> button.</>) ,
      step: 2,
    },
    { 
      description: (<>Click <b>“Install”</b> button on Shopify store.</>) 
    },
    { 
      description: (<>You'll be redirected to the <b>Shopify dashboard</b>. Refresh the page and click <b>'Activate'</b> to enable GoKwik Payments.</>)
    },
    { 
      description: (<>After installing the app, <b>come back to this page.</b></>) 
    },
    { 
      description: (<>Click on <b>“Mark as done”</b> and click on <b>“Finish Installation”</b> Button.</>) 
    }
  ]

  const handleInstallAppClick = () => {
    setSiteVisited(true)
    logEvent(
      siteVisited? "install_gokwik_payments_clicked_again" : 'install_gokwik_payments_clicked',
      'click',
      'payment-setup',
      userDetails?.email,
      merchantDetails?.m_id,
      merchantDetails?.short_name,
      userDetails?.name,
      {Step: 'payments 2'}
    )
    window.open('https://apps.shopify.com/gokwik-payments', '_blank');
  }

  const finishInstallationClicked = () => {
    logEvent(
      'gokwik_payments_finish_installation_clicked',
      'click',
      'payment-setup',
      userDetails?.email,
      merchantDetails?.m_id,
      merchantDetails?.short_name,
      userDetails?.name,
      {Step: 'payments 2'}
    )        
    updateMerchantOnboardingCheckoutConfigsStatus('gk_payments_app_installed', () => {
      dispatch(fetchUserDetails());
      onStepComplete();
      navigateToUrl('/shopify-app/settings');
    }, merchantDetails)      
  }

  return (
    <div className="border border-gray-300 rounded-lg bg-white w-[74vw]">
      <CheckoutStepsHeader step={'Step 2'} heading={'Install GoKwik Payment App'} toggleStep={toggleStep} stepId={2} currentStep={currentStep} />

      {expandedStep === 2 ? (
        <div style={{
          opacity: showContent ? 1 : 0,
          transform: showContent ? 'translateY(0)' : 'translateY(20px)',
          transition: 'opacity 0.5s ease, transform 0.5s ease',
        }}>
          <Divider className='m-0'/>
          <HowToStepsWithPreview steps={steps} previewImage={previewPlaceholder1} videoUrl={'https://pdp.gokwik.co/sa-login-ui/assets/1.Payment+App+Installation.mp4'} page={'payments'}/>
          <Divider  className='m-0' />
          <div className={`flex ${siteVisited ? "" : "flex-row-reverse"} justify-between items-center p-3`}>
            <Button
              onClick={handleInstallAppClick}
              variant={siteVisited ? 'default' : 'primary'}
              className="flex items-center"
              size="large"
            >
              Install Gokwik Payments App <img style={{ marginLeft: '0.4rem' }} src={siteVisited ? darkExternalLink : lightExternalLink} />
            </Button>
          
            {siteVisited && (
              <Button disabled={currentStep !== 2} onClick={finishInstallationClicked} type="primary" size="large">
                Finish Installation
              </Button>
            )}
          </div>
        </div>
      ) : "" }
    </div>
  );
};

export default PaymentActivationStep2;
