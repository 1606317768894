import WhatsNewImg from '@library/images/common/executive-summary-images/WhatsNewImg'
import index from '@pages/onboarding/kwikpass-onboarding'
import { Button, Divider } from 'gokwik-ui-kit'

const RecommendedSection = ({ previewImage, campaignConfigs }) => {
    const newsFeed = [
        {
            title: '80% reduced manual intervention',
            description:
                'How Vembley reduced manual intervention by 80% and boosted abandoned cart conversions by 4% using KwikEngage’s WhatsApp Commerce automation.',
            media_url: 'https://panel.gokwik.co/storage/app/public/images/vembley_1697615077.png',
            cta_text: 'Read Full Case Study',
            cta_url:
                'https://www.gokwik.co/success-story/vembley-reduces-manual-intervention-using-kwikengage?utm_source=whats_new&utm_medium=04',
        },
        {
            title: 'Crack the Valentine’s Code!',
            description:
                'Win big this Valentine’s! Get expert tips, WhatsApp & Instagram hacks, and proven strategies to boost sales, engagement, and conversions.',
            cta_text: 'Download Now',
            cta_url: 'https://drive.google.com/file/d/1xXNhTKhGrEvrnaAVWkwTzEOZexhM3Qje/view',
        },
    ]
    return (
        <div className='bg-white border border-solid border-[#EAECF0] h-full transform scale-[70%]'>
            <div className='flex justify-between items-center pb-5 pt-3 px-3 bg-gradient-to-b from-blue-100 to-white'>
                <div className='font-semibold text-sm'>Recommended For You</div>
                <WhatsNewImg />
            </div>

            <div className='m-auto w-11/12'>
                <div
                    className='overflow-y-auto max-h-[117vh]'
                    style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
                >
                    <style>
                        {`
                            .overflow-y-auto::-webkit-scrollbar {
                                display: none;
                            }
                        `}
                    </style>

                    <div>
                        <div className='bg-white rounded-md shadow-sm flex flex-col gap-3 p-2 mb-3 pb-0'>
                            {campaignConfigs?.media_url && (
                                <div className='w-full aspect-video max-h-52'>
                                    <img src={previewImage} className='w-full h-full object-cover' />
                                </div>
                            )}
                            <div className='text-base font-medium text-[#000000E0] text-left w-full'>
                                {campaignConfigs?.title}
                            </div>
                            <div className='text-xs font-normal text-[#000000E0] text-justify w-[92%]'>
                                {campaignConfigs?.description}
                            </div>
                            <div className='w-full'>
                                <Button
                                    className='w-full'
                                    variant='primary'
                                    onClick={() => window.open(campaignConfigs.cta_url, '_blank')}
                                >
                                    {campaignConfigs?.cta_text}
                                </Button>
                            </div>
                            {/* <Divider /> */}
                        </div>
                        <Divider className='mt-5 mb-1 bg-gray-200' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecommendedSection
