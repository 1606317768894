import backBtnIcon from '@library/images/icons/arrow-narrow-left.svg'; 
import { useNavigate } from 'react-router-dom';

const GoBackToSettings = ({ heading }) => {
    const navigate = useNavigate();

    const goBackToSettingsPage = () => {
        navigate('/shopify-app/settings');
    }

    return (
        <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '0.75rem' }}>
            <img src={backBtnIcon} style={{ padding: '0.4rem', background: 'rgba(0, 0, 0, 0.06)', border: '1px solid rgba(0, 0, 0, 0.06)', borderRadius: '2px', cursor: 'pointer' }} onClick={() => goBackToSettingsPage()}></img>
            <div style={{ textAlign: 'left' }}>
                <div style={{
                    fontSize: '24px',
                    fontWeight: '600', 
                    lineHeight: '28px',
                }}>{heading}</div>
            </div>
        </div>
    )
}

export default GoBackToSettings;
