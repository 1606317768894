import React, { useState } from "react";
import { PlayCircleOutlined, Modal } from "gokwik-ui-kit";
import step1 from "@library/images/icons/circle-number-1.svg";
import step2 from "@library/images/icons/circle-number-2.svg";
import step3 from "@library/images/icons/circle-number-3.svg";
import step4 from "@library/images/icons/circle-number-4.svg";
import step5 from "@library/images/icons/circle-number-5.svg";
import step6 from "@library/images/icons/circle-number-6.svg";
import { logEvent } from "@library/utilities/userLogsEvents/userLogEvents";
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux';
import TutorialVideoModal from "./stepTutorial";

const STEP_IMAGES = [step1, step2, step3, step4, step5, step6];

interface Step {
  description: React.ReactNode;
  step?: React.ReactNode;
}

interface HowToStepsWithPreviewProps {
  steps: Step[];
  previewImage: string;
  videoUrl: string;
  page: string;
}

const HowToStepsWithPreview: React.FC<HowToStepsWithPreviewProps> = ({ steps, previewImage, videoUrl, page }) => {
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
        logEvent(
            'Watch_tutorial_clicked',
            'click',
            page === 'cod' ? 'cod-setup' : page === 'checkout' ? "checkout-setup" : "payment-setup",
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            {Step: `${page} ${steps[0]?.step}`}
        )
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="flex gap-4 p-4">
            <div style={{
                flex: 4, 
                border: "1.2px solid rgba(145, 202, 255, 1)",
                borderRadius: "8px",
                background: "rgba(255, 255, 255, 1)" 
            }}>
                <p className="bg-[#EFF7FF] text-[#1677FF] text-sm font-semibold p-4 rounded-t-lg" >
                How to do it?
                </p>
                <div className="p-4 flex flex-col gap-3">
                {steps.map((step, index) => (
                    <div className="flex items-start gap-1" key={index}>
                        <img src={STEP_IMAGES[index]} alt={`Step ${index + 1}`}/>
                        <span style={{ lineHeight: '22px' }} className="block text-left">{step.description}</span>
                    </div>
                ))}
                </div>
            </div>

            <div 
                style={{ flex: 3 }} 
                className="rounded-lg bg-gray-300 flex items-center justify-center relative h-[250px] cursor-pointer" 
                onClick={handleOpenModal} 
            >
                <div className="absolute top-2/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-2 py-1 bg-white rounded shadow-md">
                    <PlayCircleOutlined style={{ marginRight: "4px" }} />
                    <span>Watch Tutorial</span>
                </div>
                <img 
                    src={previewImage} 
                    className="h-[95%] w-[97%]"
                    alt="Preview" 
                />
            </div>

            <TutorialVideoModal isModalOpen={isModalOpen} handleCloseModal={handleCloseModal} videoUrl={videoUrl} />
        </div>
    );
};

export default HowToStepsWithPreview;
