// Import Dependencies
import { navigateToUrl } from 'single-spa'
import React, { useState, useEffect } from 'react'

// Import Utilities
import useAESEncryption from '@library/utilities/authorization/encryption'
import { dashboardStore, makeAPICall } from '@gokwik/utilities'
import { Button, Col, Row, Input, Popover, Form, message, Checkbox, Modal, Tooltip } from 'gokwik-ui-kit'
import EmailIcon from '@library/images/icons/emailIcon'
import PasswordIcon from '@library/images/icons/passwordIcon'
import { redirect, useSearchParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserDetails, sendVerifyEmailOTP, setSignUpEmail, setUserData, signin } from '@store/user'
import LoginLayout from '@library/components/loginLayout'
import { getSignUpEmail } from '@store/user/selectors'
import { useAppDispatch } from '@library/utilities/hooks'
import { getMerchantDetails as getMerchantDetailsRedux } from '@store/user/selectors'
import { CloseCircleOutlined, EyeFilled } from '@ant-design/icons'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import shileds from '@library/images/icons/shield.svg'
import shiledsCross from '@library/images/icons/shield-cross.svg'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import Terms from '@library/images/terms.html'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import './password-signup.css'
import { PhoneNumberUtil } from 'google-libphonenumber'

export default function () {
    const [step, setStep] = useState(0)

    const [credentialsData, setCredentialsData] = useState({ confirmPass: '', password: '' })
    const [current, setCurrent] = useState('emailCheck')
    // const [error, setError] = useErrorContext();
    // const router = useRoute();
    const signupEmail = useSelector(getSignUpEmail)
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()
    const [isMerchantExist, setMerchantExist] = useState(false)
    const [passwordCheck, setPasswordCheck] = useState({ pass: false, confirm: false })
    const [showRcodeField, setShowRcodeField] = useState(false)
    const [phoneNumberError, setPhoneNumberError] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const u_id = searchParams.get('user_id')
    const m_id = searchParams.get('m_id')
    const merchant_details = useSelector(getMerchantDetailsRedux)
    const [initPhase, setInitPhase] = useState(true)
    const [formDetails, setFormDetails] = useState({
        business_categories: [],
        merchant_details: {},
        monthly_gmv: [],
        products: [],
        platforms: [],
    })
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        password: '',
        phoneNumber: '',
        refCode: '',
    })
    const [goodPassword, setGoodPassword] = useState({
        'Minimum 8 Length': false,
        '1 Small and 1 Capital Letter': false,
        '1 Digit': false,
        '1 Special Character': false,
    })
    const [confirm, setPassConfirm] = useState('')
    const [termsCondition, setTermsCondition] = useState(false)
    const [isTNCAccept, setIsTNCAcceptState] = useState(false)

    useEffect(() => {
        const customEvent = new CustomEvent('loader', {
            detail: {
                data: { reset: true },
            },
        })
        window.dispatchEvent(customEvent)
    }, [])

    const referralCodeAdded = async () => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.createLead + (m_id ?? merchant_details?.m_id),
                ...{ payload: { referral_code: formData?.refCode } },
            })

            if (response?.data?.status_code === 200) {
                message.success('Our team will reach out to you in 1-2 business days')
            } else {
                message.error('Error while submitting refferal code', response?.response?.data?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const phoneUtil = PhoneNumberUtil.getInstance()

    const isPhoneValid = (phone: string) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
        } catch (error) {
            return false
        }
    }

    const signin = async (e) => {
        logEvent('signup_button_clicked', 'click', 'signup', formData.email, m_id)
        // const isValid = validateFormData()
        if (credentialsData.password !== credentialsData.confirmPass) {
            message.warning('Password does not match ')
            return
        }
        e.preventDefault()

        if (!credentialsData.password || credentialsData.password.length < 8) {
            // setError({ ...error, password: "Invalid Password" });
            message.error('Invalid password')
            logEvent('password_validation_failed', 'click', 'signup', formData.email, m_id)
            return
        }
        const password = await useAESEncryption(credentialsData.password, process.env.REACT_APP_PUBLIC_CRYPTO_KEY)

        await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.signup + `/${m_id}/${u_id}`,
            payload: {
                password,
                phoneNumber: formData?.phoneNumber,
            },
        })
            .then(async (signupResponse) => {
                if (signupResponse?.data?.status_code === 200 && formData.refCode?.length > 0) {
                    await referralCodeAdded()
                }
            })
            .then(() => {
                localStorage.setItem('currentPage', 'dashboard')
                // localStorage.setItem('token', response.data.data.token)
                localStorage.setItem('email', formData.email)
                dispatch(fetchUserDetails()).then((res) => {
                    navigate('/onboarding/kc/onboarding-steps', { replace: true })
                })
            })
            .catch((err) => {
                // if (err?.response?.data.status_code === 403) {
                //     // router.push('/verify-otp');
                //     // return;
                // }
                let errorMsg = err?.response && err?.response.data ? err?.response.data.message : 'Something failed'
                message.error(errorMsg)
            })
    }

    const getMerchantDetails = async () => {
        let m_id = searchParams.get('user_id')
        if (m_id) {
            setMerchantExist(true)
        }
        let response = await makeAPICall({
            method: 'get',
            url:
                process.env.REACT_APP_BASE_URL +
                '/api/dashboard/onboarding/signup-details' +
                (m_id ? `?user_id=${m_id}` : ''),
        })
        if (response.data.status_code == 200) {
            setFormDetails(response.data?.data)
            if (m_id) {
                let merchant_details = response.data?.data?.merchant_details
                setFormData((prev) => ({
                    ...prev,
                    website: merchant_details?.website,
                    business_name: merchant_details?.brand_name,
                    platform: merchant_details?.platform,
                    email: merchant_details?.email,
                    products: merchant_details?.products,
                }))
                form.setFieldValue('email', merchant_details?.email)
                logEvent('shopify_app_installed', 'event', 'signup', merchant_details.email, m_id)
            }
        } else {
            message.error('Invalid merchant')
        }
    }

    useEffect(() => {
        getMerchantDetails()
    }, [])

    const checkGoodPassword = (password) => {
        let temp = {}
        temp['Minimum 8 Length'] = password.length >= 8
        temp['1 Small and 1 Capital Letter'] = /(?=.*[a-z])/.test(password) && /(?=.*[A-Z])/.test(password)
        temp['1 Digit'] = /(?=.*\d)/.test(password)
        temp['1 Special Character'] = /(?=.*\W])/.test(password) || /[-+_!@#$%^&*.,?]/.test(password)
        //@ts-ignore
        setGoodPassword(temp)
    }

    const handlePhoneChange = (phone, meta) => {
        const { dialCode } = meta.country
        const phNo = phone.replace(`+${dialCode}`, '').trim()

        setPhoneNumberError(!isPhoneValid(phone))

        if (initPhase && phNo?.length > 0) {
            setInitPhase(false)
        }

        form.setFieldValue('phoneNumber', phone)
        setFormData((prev) => ({ ...prev, phoneNumber: phNo }))
    }

    const handleRefcodeChange = (e) => {
        const refCode = e.target.value
        form.setFieldValue('refCode', refCode)
        setFormData((prev) => ({ ...prev, refCode: refCode }))
    }

    const getCurrentPendingInputMessage = () => {
        return formData?.phoneNumber?.length < 1 || phoneNumberError
            ? 'Please Add a Valid Phone Number'
            : credentialsData?.password?.length < 1
            ? 'Please Set a Valid Password'
            : credentialsData?.confirmPass?.length < 1
            ? 'Please confirm the Password'
            : !isTNCAccept
            ? 'Please accept terms and conditions'
            : ''
    }
    return (
        <LoginLayout
            titleContent={
                <>
                    {' '}
                    <p className='text-3xl text-[#060322] font-normal leading-normal inter tracking-tight'>
                        Finish Signup
                    </p>
                    <svg xmlns='http://www.w3.org/2000/svg' width='398' height='2' viewBox='0 0 398 2' fill='none'>
                        <path d='M1 1L397 0.999965' stroke='#828282' stroke-linecap='round' />
                    </svg>
                    <p className='font-normal text-normal'>
                        Please create password for account : <span className='text-blue-500'>{formData.email}</span>
                    </p>
                </>
            }
        >
            <Modal
                cancelText={'Decline'}
                okText={'I Agree'}
                centered
                title={
                    <Row className='gap-2'>
                        <CloseCircleOutlined
                            size={50}
                            onClick={() => {
                                setTermsCondition(false)
                            }}
                        />
                        <p>Terms and Condtions</p>
                    </Row>
                }
                open={termsCondition}
                closeIcon={null}
                onOk={() => {
                    logEvent('terms_and_conditions_agreed', 'click', 'signup', formData?.email, m_id)
                    setIsTNCAcceptState(true)
                    setTermsCondition(false)
                }}
                onCancel={() => {
                    logEvent('terms_and_conditions_rejected', 'click', 'signup', formData?.email, m_id)
                    setIsTNCAcceptState(false)
                    setTermsCondition(false)
                }}
            >
                <div className=' max-h-[70vh] overflow-auto'>
                    <div className='terms' id='terms' dangerouslySetInnerHTML={{ __html: Terms }}></div>
                </div>
            </Modal>
            <div className='m-auto flex flex-col align-middle justify-center gap-y-5'>
                <form
                    className='w-full flex flex-col gap-y-5'
                    onSubmit={(e) => {
                        e?.preventDefault()
                    }}
                >
                    <Form
                        form={form}
                        onFinish={(e) => {
                            signin(e)
                        }}
                        layout='vertical'
                        className='flex flex-col'
                    >
                        <div className='flex flex-col gap-y-4 mb-4'>
                            <Form.Item name={'email'} label={'Email ID'} className='w-full mb-0'>
                                <Input value={formData.email} type='email' disabled />
                            </Form.Item>
                            <Form.Item
                                className='w-full mb-0 ant-phone-input'
                                name='phoneNumber'
                                label='Phone Number'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your phone number!',
                                    },
                                ]}
                                required
                            >
                                <PhoneInput
                                    defaultCountry='in'
                                    value={formData?.phoneNumber}
                                    onChange={(phone, meta) => handlePhoneChange(phone, meta)}
                                    className='PhoneInput'
                                    forceDialCode={true}
                                    disableFormatting={true}
                                />
                            </Form.Item>
                            {!initPhase && phoneNumberError && (
                                <div className='text-red-500 mt-[-10px]'>Phone number is not valid</div>
                            )}
                            {showRcodeField && (
                                <Form.Item className='mb-0' name='refCode' label='Referral code'>
                                    <Input
                                        placeholder='Referral code'
                                        value={formData?.refCode}
                                        onChange={(e) => handleRefcodeChange(e)}
                                    />
                                </Form.Item>
                            )}
                        </div>
                        <div className='flex gap-x-4'>
                            <Form.Item
                                name={'password'}
                                label={'Password'}
                                rules={[
                                    {
                                        // min: 6,
                                        required: true,
                                        message: 'Please add your password',
                                    },
                                ]}
                                className='w-full mb-0'
                            >
                                <Popover
                                    open={passwordCheck.pass}
                                    content={[
                                        'Minimum 8 Length',
                                        '1 Small and 1 Capital Letter',
                                        '1 Digit',
                                        '1 Special Character',
                                    ].map((u) => (
                                        <div
                                            className={`text-xs flex justify-between p-1  ${
                                                goodPassword[u] ? 'good' : 'bad'
                                            }`}
                                        >
                                            <p className='mr-2'>{u}</p>
                                            {goodPassword[u] ? (
                                                <img src={shileds} className='tick-icon' />
                                            ) : (
                                                <img src={shiledsCross} className='tick-icon' />
                                            )}
                                        </div>
                                    ))}
                                >
                                    <Input.Password
                                        value={credentialsData?.password}
                                        onChange={(e) => {
                                            setCredentialsData((prev) => ({ ...prev, password: e.target.value }))
                                            checkGoodPassword(e.target.value)
                                        }}
                                        placeholder='*********'
                                        type='password'
                                        label='Password'
                                        onFocus={() => {
                                            setPasswordCheck((prev) => ({ ...prev, pass: true }))
                                        }}
                                        onBlur={() => {
                                            setPasswordCheck((prev) => ({ ...prev, pass: false }))
                                        }}
                                    />
                                </Popover>
                            </Form.Item>
                            <Form.Item
                                name={'password'}
                                label={'Confirm Password'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || credentialsData?.password === value) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(
                                                new Error('The new password that you entered do not match!'),
                                            )
                                        },
                                    }),
                                ]}
                                className='w-full mb-0'
                            >
                                <Input.Password
                                    value={credentialsData?.confirmPass}
                                    onChange={(e) =>
                                        setCredentialsData((prev) => ({ ...prev, confirmPass: e.target.value }))
                                    }
                                    placeholder='********'
                                    type='password'
                                    label='Confirm Password'
                                />
                            </Form.Item>
                        </div>

                        <div className='w-full flex mt-5'>
                            <Checkbox
                                checked={isTNCAccept}
                                onChange={() => {
                                    logEvent('terms_and_conditions_clicked', 'click', 'signup', formData?.email, m_id)
                                    setTermsCondition(true)
                                }}
                            >
                                Accept terms and conditions
                            </Checkbox>
                        </div>
                    </Form>
                    <div className='flex w-full'>
                        <Tooltip placement='topLeft' title={getCurrentPendingInputMessage()}>
                            <Button
                                variant='primary'
                                htmlType='submit'
                                className='w-full disabled:border-gray-300 '
                                disabled={
                                    credentialsData.confirmPass.length <= 5 ||
                                    credentialsData.password.length <= 5 ||
                                    !isTNCAccept ||
                                    phoneNumberError
                                }
                                onClick={(e) => {
                                    signin(e)
                                }}
                            >
                                <span className=' font-bold'>Next</span>
                            </Button>
                        </Tooltip>
                    </div>
                </form>
                <div
                    className='flex align-middle justify-center text-primary-500 font-semibold cursor-pointer'
                    onClick={() => (showRcodeField ? {} : setShowRcodeField(true))}
                >
                    Are you a shopify partner?
                </div>
            </div>
        </LoginLayout>
    )
}
