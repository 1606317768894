export const CardIcon = () => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='vuesax/linear/card'>
                <g id='card'>
                    <path
                        id='Vector'
                        d='M1.6665 7.08594H18.3332'
                        stroke='#004B8D'
                        stroke-width='1.2'
                        stroke-miterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <path
                        id='Vector_2'
                        d='M5 13.7539H6.66667'
                        stroke='#004B8D'
                        stroke-width='1.2'
                        stroke-miterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <path
                        id='Vector_3'
                        d='M8.75 13.7539H12.0833'
                        stroke='#004B8D'
                        stroke-width='1.2'
                        stroke-miterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <path
                        id='Vector_4'
                        d='M5.3665 2.92188H14.6248C17.5915 2.92188 18.3332 3.65521 18.3332 6.58021V13.4219C18.3332 16.3469 17.5915 17.0802 14.6332 17.0802H5.3665C2.40817 17.0885 1.6665 16.3552 1.6665 13.4302V6.58021C1.6665 3.65521 2.40817 2.92188 5.3665 2.92188Z'
                        stroke='#004B8D'
                        stroke-width='1.2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                </g>
            </g>
        </svg>
    )
}
