import { FC, useEffect, useState } from 'react'
// import Plans from './plans'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserData, getUserDetails } from '@store/user/selectors'
import InfoIcon from '@library/images/common/info.svg'
import { ArrowLeftIcon } from '@library/images/common/arrowLeft'
import bgVector1 from '@library/images/common/bg-vector1.png'
import rectangle from '@library/images/common/rectangle.png'
import {
    Alert,
    Col,
    Row,
    Modal,
    ExclamationCircleFilled,
    Button,
    InfoCircleFilled,
    InfoCircleOutlined,
} from 'gokwik-ui-kit'
import KYCForm from './kyc-forms'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { stepConfiguration } from '../new-pages/stepsConfig'
import { navigateToUrl } from 'single-spa'

interface KYCDetailsProps {}

const KYCDetailsPage: FC<KYCDetailsProps> = (props) => {
    const [showAlert, setShowAlert] = useState(true)
    const merchant_details = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const [popup, setPopup] = useState(false)
    const userData = useSelector(getUserData)

    useEffect(() => {
        logEvent('kyc_landed', 'pageLoad', 'kyc', user_details?.email, merchant_details?.m_id)
        if (
            stepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.id >
            stepConfiguration['kyc_completed']?.id
        ) {
            navigateToUrl('/checkout/settings')
        }
    }, [])

    useEffect(() => {
        if (merchant_details?.onboarding_status?.kwik_checkout_app === 'pricing_details_selected') setShowAlert(true)
    }, [merchant_details?.onboarding_status?.kwik_checkout_app])

    const onBackButtonClick = (e) => {
        e.preventDefault()
        setPopup(true)
    }
    const goBack = () => {
        setPopup(false)
        logEvent('unsaved_changes_leave_page_clicked', 'click', 'kyc', user_details.email, merchant_details.m_id)
        //commenting the following path as we are skiping the COD page
        //return '/onboarding/get-started'
        window.location.pathname = '/onboarding/kc/onboarding-steps'
    }
    const getKYCStatusAlert = () => {
        const stepId = stepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app].id
        if (
            stepId >= stepConfiguration['kyc_completed'].id &&
            stepId !== stepConfiguration['kyc_verification_pending'].id &&
            stepId !== stepConfiguration['kyc_verification_failed'].id
        ) {
            return (
                <Alert
                    message={<span className='text-[#084426] text-sm font-semibold'>KYC Approved</span>}
                    description={
                        <span className='text-[#084426] text-[13px] font-normal'>
                            You're all set. Thank you for the sign up and document submissions. Our team will reach out
                            to you in next 5 working days to activate checkout.
                            <p>
                                Go to <a href='/checkout/settings'>homepage</a>
                            </p>
                        </span>
                    }
                    type='success'
                    showIcon
                />
            )
        } else if (stepId === stepConfiguration['kyc_verification_failed'].id) {
            return (
                <Alert
                    className='p-2 w-full'
                    message={<span className='text-[#FF2618] text-sm font-semibold'>Attention Required</span>}
                    description={
                        <span className='text-red-400'>
                            Your KYC verification has failed due to the following reasons. Hover on the error(s) to know
                            more.
                        </span>
                    }
                    type='error'
                    showIcon
                />
            )
        } else if (stepId === stepConfiguration['kyc_verification_pending'].id) {
            return (
                <Alert
                    message={<span className='text-[#004B8D] text-sm font-semibold'>Application in Progress</span>}
                    description={
                        <span className='text-[#004B8D] text-[13px] font-normal'>
                            You’re all set! Thank you for the sign-up and document submissions. You will receive timely
                            updates on the approval of your application via email.{' '}
                            <span className='font-semibold'>It can take 2 business days to verify KYC</span>
                        </span>
                    }
                    type='info'
                    showIcon
                />
            )
        } else {
            return ''
        }
    }
    return (
        <>
            <div className="relative h-full w-full font-['Inter'] pb-8 bg-white overflow-auto">
                <div className='mt-16 mx-12 relative'>
                    <Row className='page-header mb-5' gutter={48} align={'bottom'}>
                        <Col span={24}>
                            {!user_details.email.includes('easebuzz') && (
                                <div
                                    className='go-back flex items-center cursor-pointer text-[#002547] my-5'
                                    onClick={onBackButtonClick}
                                >
                                    <ArrowLeftIcon />
                                    <p className='font-normal text-base inter ml-1'>Go Back</p>
                                </div>
                            )}

                            <div className='flex justify-between w-full'>
                                <div className='flex flex-col justify-start items-start gap-1'>
                                    <div className='text-stone-900 text-3xl font-semibold justify-start items-center gap-3 flex'>
                                        <div className=' '>KYC (Know Your Customers)</div>
                                    </div>
                                    <div className='text-stone-900 text-base font-normal'>
                                        Let’s get started with your Mandatory KYC
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={12}>
                            {stepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app].id >
                                stepConfiguration['kyc_document_upload'].id && (
                                <Col span={24}>
                                    {/* <div className='flex flex-col items-end'>{getKYCStatusAlert()}</div> */}
                                </Col>
                            )}
                        </Col>
                    </Row>

                    <KYCForm />

                    <div className='footer w-full mt-16'>
                        <div className='text-center text-[#000000A6] text-opacity-60 text-sm leading-[22px] font-normal'>
                            <span>For assistance, feel free to reach out to us via email at </span>
                            <span className='text-[#028DF5] text-sm font-normal underline leading-snug'>
                                <a href={`mailto:mybusiness@gokwik.co`}>mybusiness@gokwik.co</a>
                            </span>
                            <span>
                                . <br />
                                Alternatively, you can engage in a live chat using the convenient widget located on the
                                right-hand side of your screen
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                cancelText={'Stay on page'}
                okText={'Leave'}
                centered
                title={
                    <Row className='gap-2'>
                        <InfoCircleOutlined className='text-[#FAAD14] ' />
                        {merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_verification_pending' ||
                        merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_completed' ? (
                            <p>Leave Page</p>
                        ) : (
                            <p>Unsaved Changes</p>
                        )}
                    </Row>
                }
                open={popup}
                onOk={goBack}
                onCancel={() => {
                    logEvent(
                        'unsaved_changes_stay_page_clicked',
                        'click',
                        'kyc',
                        user_details.email,
                        merchant_details.m_id,
                    )
                    setPopup(false)
                }}
            >
                <p className='ml-6 my-4'>
                    {merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_verification_pending' ||
                    merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_completed'
                        ? 'Are you sure you want to leave this page?'
                        : 'Changes you have made will not be saved'}
                </p>
            </Modal>
        </>
    )
}

export default KYCDetailsPage
