import { CardIcon } from '@library/images/common/card'
import PercentageIcon from '@library/images/common/Percentage.svg'
import { BorderedStarIcon } from '@library/images/common/star'
import BasicPlanIcon from '@library/images/common/BasicPlan.svg'
import DownArrowIcon from '@library/images/common/DownArrow.svg'
import EnterprisePlanIcon from '@library/images/common/EnterprisePlan.svg'

import Tick from '@library/images/common/Tick.svg'
import InfoIcon from '@library/images/common/info.svg'
// DO-NOT-DELETE-COMMENTED-CODE
// export const planConfig = [
//     {
//         planName: 'Basic Plan',
//         planIcon: () => <img src={BasicPlanIcon} width={48} height={48} alt='Basic Plan Icon' />,
//         planSticker: () => (
//             <div className='plan-sticker bg-blue-50 rounded gap-3 flex p-3 pl-0 items-center mt-3'>
//                 <div className='w-1 bg-blue-600 rounded-tl rounded-bl' />
//                 <img
//                     src={PercentageIcon}
//                     width={24}
//                     height={24}
//                     className='justify-start items-center gap-3 inline-flex'
//                 />
//                 <div className='text-xs'>
//                     <span className='text-neutral-600 text-xs font-light leading-none'>Introductory Offer:</span>
//                     <span className='text-blue-600 text-xs font-bold leading-none'>
//                         {' '}
//                         Try Free Up to 1000 COD Transactions Per Month
//                     </span>
//                     <span className='text-blue-600 text-xs font-normal leading-none'>.</span>
//                 </div>
//             </div>
//         ),
//         planHeading: 'Pricing',
//         planSubheading: 'Start with this plan to maximise conversions and reduce RTO',
//         planData: [
//             {
//                 header: () => (
//                     <>
//                         <span className='pre-icon mr-1'>
//                             <img src={Tick} />
//                         </span>
//                         &nbsp;
//                         <span className='text-stone-900 text-sm !leading-[22px] font-normal'>Primary Features</span>
//                     </>
//                 ),
//                 content: ['COD OTP Prompt', 'COD Shipping Fee', 'Hide COD On 10+ Parameters']?.map((item) => (
//                     <div className='flex gap-1'>
//                         &bull; <span>{item}</span>
//                     </div>
//                 )),
//             },
//             {
//                 header: () => (
//                     <>
//                         <span className='pre-icon mr-1'>
//                             <img src={Tick} />
//                         </span>
//                         &nbsp;
//                         <span className='text-stone-900 text-sm !leading-[22px] font-normal'>
//                             GoKwik Exclusive Discounts
//                         </span>
//                     </>
//                 ),

//                 content: [
//                     'Cart Value Based Discounts',
//                     'Prepaid Discounts',
//                     'Product Quantity Based Discounts',
//                     'Buy X (Quantity) at Y (Flat Amount)',
//                 ]?.map((item) => (
//                     <div className='flex gap-1'>
//                         &bull; <span>{item}</span>
//                     </div>
//                 )),
//             },
//             {
//                 header: () => (
//                     <>
//                         <span className='pre-icon mr-1'>
//                             <img src={Tick} />
//                         </span>
//                         &nbsp;
//                         <span className='text-stone-900 text-sm !leading-[22px] font-normal'>
//                             Smart COD & RTO Protection
//                         </span>
//                     </>
//                 ),
//                 content: [
//                     'Risk-based COD Blocking',
//                     'Risk-based COD Captcha',
//                     'Risk-based COD Confirmation Prompt',
//                     // 'OTP for COD Orders',
//                 ]?.map((item) => (
//                     <div className='flex gap-1'>
//                         &bull; <span>{item}</span>
//                     </div>
//                 )),
//             },
//         ],

//         ctaName: 'Start Plan',
//     },
//     //ENT-PLAN
//     {
//         planName: 'Enterprise Plan',
//         planIcon: () => <img src={EnterprisePlanIcon} width={48} height={48} alt='Enterprise Plan Icon' />,
//         planSticker: () => (
//             <div className='plan-sticker  bg-blue-50 rounded gap-3 flex p-3 pl-0 items-center mt-3'>
//                 <div className='w-1 bg-blue-600 rounded-tl rounded-bl' />
//                 <img
//                     src={DownArrowIcon}
//                     width={24}
//                     height={24}
//                     className='justify-start items-center gap-3 inline-flex'
//                 />
//                 <div className='text-xs'>
//                     <span className='text-neutral-600 text-xs font-light leading-none'>Important update:</span>
//                     <span className='text-blue-600 text-xs font-normal leading-none'>
//                         {' '}
//                         Advance features will only work with <span className='!font-bold'>Shopify Plus plan</span>.
//                     </span>
//                 </div>
//             </div>
//         ),
//         planHeading: 'Custom Pricing',
//         planSubheading: 'Unlock the power of prepaid share uplift and unique discounts',
//         planData: [
//             {
//                 header: () => (
//                     <>
//                         <span className='pre-icon mr-1'>
//                             <img src={Tick} />
//                         </span>
//                         &nbsp;
//                         <span className='text-stone-900 text-sm !leading-[22px] font-normal'>
//                             All Features In Basic Blan +
//                         </span>
//                     </>
//                 ),
//                 content: [],
//             },
//             {
//                 header: () => (
//                     <>
//                         <span className='pre-icon mr-1'>
//                             <img src={Tick} />
//                         </span>
//                         &nbsp;
//                         <span className='text-stone-900 text-sm !leading-[22px] font-normal'>Advanced Discounts</span>
//                     </>
//                 ),

//                 content: ['Discount-coupon Suggestions', '‘Free Gift with Product’ Discount']?.map((item) => (
//                     <div className='flex gap-1'>
//                         &bull; <span>{item}</span>
//                     </div>
//                 )),
//             },
//             {
//                 header: () => (
//                     <>
//                         <span className='pre-icon mr-1'>
//                             <img src={Tick} />
//                         </span>
//                         &nbsp;
//                         <span className='text-stone-900 text-sm !leading-[22px] font-normal'>Advanced Shipping</span>
//                     </>
//                 ),
//                 content: ['Express Delivery Shipping', "Real time 'Estimated Delivery Date'"]?.map((item) => (
//                     <div className='flex gap-1'>
//                         &bull; <span>{item}</span>
//                     </div>
//                 )),
//             },
//             {
//                 header: () => (
//                     <>
//                         <span className='pre-icon mr-1'>
//                             <img src={Tick} />
//                         </span>
//                         &nbsp;
//                         <span className='text-stone-900 text-sm !leading-[22px] font-normal'>Advance RTO Configs</span>
//                     </>
//                 ),
//                 content: [
//                     'Risk-based Prepaid Discount',
//                     'Extra Fees on COD Orders',
//                     'Identification of deeper risk segments',
//                     'Risk-based Partial COD',
//                 ]?.map((item) => (
//                     <div className='flex gap-1'>
//                         &bull; <span>{item}</span>
//                     </div>
//                 )),
//             },
//         ],
//         ctaName: 'Contact Us',

//         customPricingDetails: {
//             card: {
//                 icon: <CardIcon />,
//                 rate: '1.0%',
//             },
//             upi: {
//                 icon: <CardIcon />,
//                 rate: '1.0%',
//             },
//             cod: {
//                 icon: <CardIcon />,
//                 rate: '1.0%',
//             },
//         },
//     },
// ]
export const planConfig = [
    {
        planName: 'Basic',
        planIcon: () => <img src={BasicPlanIcon} width={48} height={48} alt='Basic Plan Icon' />,
        planSticker: () => (
            <div className='plan-sticker rounded gap-3 flex p-3 pl-0 items-center mt-3'>
                <div className='text-sm'>
                    {/* <span className='text-neutral-600 text-xs font-light leading-none'>Introductory Offer:</span> */}
                    {/* <span className='text-blue-600 text-xs font-bold leading-none'>
                        {' '}
                        Single Sign On for users, OTP verified phone numbers -- all to increase your network!
                    </span>
                    <span className='text-blue-600 text-xs font-normal leading-none'>.</span> */}
                    <span className='font-medium text-stone-500'>Basic Plan Features</span>
                </div>
            </div>
        ),
        pricing: 0,
        planHeading: '',
        planSubheading: '',
        planData: [
            {
                header: () => (
                    <>
                        <span className='pre-icon mr-1'>
                            <img src={Tick} />
                        </span>
                        &nbsp;
                        <span className='text-stone-900 text-sm !leading-[22px] font-normal'>
                            Boost subscriptions via Single Sign-On (SSO)
                        </span>
                    </>
                ),
                content: [],
            },

            {
                header: () => (
                    <>
                        <span className='pre-icon mr-1'>
                            <img src={Tick} />
                        </span>
                        &nbsp;
                        <span className='text-stone-900 text-sm !leading-[22px] font-normal'>
                            Free 5,000 phone-based login credits monthly
                        </span>
                    </>
                ),

                content: [],
            },
            {
                header: () => (
                    <>
                        <span className='pre-icon mr-1'>
                            <img src={Tick} />
                        </span>
                        &nbsp;
                        <span className='text-stone-900 text-sm !leading-[22px] font-normal'>
                            Seamless retargeting on WhatsApp, SMS, and email
                        </span>
                    </>
                ),

                content: [],
            },
        ],

        ctaName: 'Select Plan',
    },
    //ENT-PLAN
    {
        planName: 'Premium',
        planIcon: () => <img src={EnterprisePlanIcon} width={48} height={48} alt='Enterprise Plan Icon' />,
        planSticker: () => (
            <div className='plan-sticker rounded gap-3 flex p-3 pl-0 items-center mt-3'>
                <div className='text-sm'>
                    {/* <span className='text-blue-600 font-bold text-xs leading-none'>
                        Get priority support for all Kwikpass features.
                    </span> */}
                    <span className='font-medium text-stone-500'>
                        Unlock Powerful Features to Grow Your Customer Network with no restrictions:
                    </span>
                </div>
            </div>
        ),
        pricing: 10,
        planHeading: 'Custom Pricing',
        planSubheading: 'Unlock the power of prepaid share uplift and unique discounts',
        planData: [
            {
                header: () => (
                    <>
                        <span className='pre-icon mr-1'>
                            <img src={Tick} />
                        </span>
                        &nbsp;
                        <span className='text-[#20619b] text-sm font-bold !leading-[22px]'>
                            All Features In Basic Plan *
                        </span>
                    </>
                ),
                content: [],
            },
            {
                header: () => (
                    <>
                        <span className='pre-icon mr-1'>
                            <img src={Tick} />
                        </span>
                        &nbsp;
                        <span className='text-stone-900 text-sm !leading-[22px] font-normal'>
                            Get priority customer support
                        </span>
                    </>
                ),

                content: [],
            },
            {
                header: () => (
                    <>
                        <span className='pre-icon mr-1'>
                            <img src={Tick} />
                        </span>
                        &nbsp;
                        <span className='text-stone-900 text-sm !leading-[22px] font-normal'>
                            Unlimited login credits
                        </span>
                    </>
                ),

                content: [],
            },

            // {
            //     header: () => (
            //         <>
            //             <span className='pre-icon mr-1'>
            //                 <img src={Tick} />
            //             </span>
            //             &nbsp;
            //             <span className='text-stone-900 text-sm !leading-[22px] font-normal'>Advanced Shipping</span>
            //         </>
            //     ),
            //     content: ['Express Delivery Shipping', "Real time 'Estimated Delivery Date'"]?.map((item) => (
            //         <div className='flex gap-1'>
            //             &bull; <span>{item}</span>
            //         </div>
            //     )),
            // },
            // {
            //     header: () => (
            //         <>
            //             <span className='pre-icon mr-1'>
            //                 <img src={Tick} />
            //             </span>
            //             &nbsp;
            //             <span className='text-stone-900 text-sm !leading-[22px] font-normal'>Advance RTO Configs</span>
            //         </>
            //     ),
            //     content: [
            //         'Risk-based Prepaid Discount',
            //         'Extra Fees on COD Orders',
            //         'Identification of deeper risk segments',
            //         'Risk-based Partial COD',
            //     ]?.map((item) => (
            //         <div className='flex gap-1'>
            //             &bull; <span>{item}</span>
            //         </div>
            //     )),
            // },
        ],
        ctaName: 'Select Plan',

        customPricingDetails: {
            card: {
                icon: <CardIcon />,
                rate: '1.0%',
            },
            upi: {
                icon: <CardIcon />,
                rate: '1.0%',
            },
            cod: {
                icon: <CardIcon />,
                rate: '1.0%',
            },
        },
    },
]
