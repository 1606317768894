import check from '@library/images/icons/check.svg'
import { Button, Layout } from 'gokwik-ui-kit'
import rightArrow from '@library/images/icons/arrow-right.svg'
import CongratsCard from '../CommonComponents/congratulationsCard'
import { useNavigate } from 'react-router-dom'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { updateMerchantOnboardingCheckoutConfigsStatus } from '../CommonComponents/Utils';
import { fetchUserDetails } from '@store/user'
import { useAppDispatch } from '@library/utilities/hooks'
import { navigateToUrl } from 'single-spa'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { useEffect } from 'react'

const { Content } = Layout

const StartKycPrompt = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)

    const startKycFlow = () => {
        logEvent(
            'complete_kyc_clicked',
            'click',
            'checkout-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name
        )
        updateMerchantOnboardingCheckoutConfigsStatus('kyc_viewed', () => {
            dispatch(fetchUserDetails());
            navigate('/onboarding/kyc');
        }, merchantDetails)
    }

    useEffect(() => {
        logEvent(
            'checkout_congratulations_page_landed',
            'click',
            'checkout-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name
        )
    }, [])

    return (
        <Layout>
            <Content style={{ height: '100%', paddingBottom: '2rem'}} className='bg-white px-8 flex flex-col gap-3'>
                <CongratsCard text='GoKwik Checkout is live!' />
                <div style={{ border: '1px solid #E5E7EB', maxWidth: '48%', padding: '2rem 2.5rem' }} className='bg-white rounded-lg mx-auto mt-3'>
                    <div className='text-center'>
                        <p className='text-[14px] font-semibold text-[#000000A6]'>Next Step</p>
                        <h1 className='text-2xl font-bold text-gray-900 mt-2 mb-2 px-8'>
                            Complete your KYC for Gokwik Payments activation
                        </h1>
                        <p className='text-sm text-green-700 mt-1'>
                            Payments will be enabled after 3-4 days of KYC completion
                        </p>
                    </div>
                    <div className='bg-gray-50 border border-gray-200 rounded-lg p-4 mt-6'>
                        <p className='mb-3 text-[12px] font-semibold text-gray-800'>Post KYC Access</p>
                        <div className='space-y-2'>
                            <li className='flex gap-2 items-center'>
                                <img src={check} alt='alt' />
                                <span className='font-normal text-[14px] leading-[22px] text-gray-700'>
                                    Partial Payment on COD to Collect Upfront Amount
                                </span>
                            </li>
                            <li className='flex gap-2 items-center'>
                                <img src={check} alt='alt' />
                                <span className='font-normal text-[14px] leading-[22px] text-gray-700'>
                                    Prepaid Payments via UPI, Net-Banking, Wallets, Cards, BNPL and More
                                </span>
                            </li>
                            <li className='flex gap-2 items-center'>
                                <img src={check} alt='alt' />
                                <span className='font-normal text-[14px] leading-[22px] text-gray-700'>
                                    Discounts on Prepaid Orders
                                </span>
                            </li>
                        </div>
                    </div>
                    <div className='bg-[#FFF1F0] border border-red-200 rounded-lg px-4 py-2 mt-4'>
                        <p className='text-sm text-[#820014] text-center'>
                            Warning : Incomplete KYC may incur ~5% charges on prepaid orders (2.5% GoKwik Checkout + your payment gateway charges). Complete KYC to get GoKwik Checkout and Payment Gateway both at just 2.5%.
                        </p>
                    </div>
                    <div className='mt-6 flex flex-col items-center space-y-3'>
                        <Button
                            variant='primary'
                            className='text-white px-6 py-3 rounded-lg flex items-center justify-center gap-2'
                            onClick={startKycFlow}
                        >
                            Complete KYC
                            <img src={rightArrow} alt='right aroow' width={24} height={24} />
                        </Button>
                        <button
                            className='p-2 flex items-center justify-center !border-none shadow-none bg-[#FFFFFF] !text-inherit focus:outline-none'
                            onClick={() => {
                                updateMerchantOnboardingCheckoutConfigsStatus('kyc_viewed', () => {
                                    dispatch(fetchUserDetails());
                                    navigateToUrl('/shopify-app/settings');
                                }, merchantDetails)
                            }}
                        >
                            <p className='text-[#1677FF]'>Skip for now, explore dashboard</p>
                        </button>
                    </div>
                </div>
            </Content>
        </Layout>
    )
}

export default StartKycPrompt