import React, { useState } from 'react';
import { Button, Drawer, RightOutlined, LeftOutlined } from 'gokwik-ui-kit'
import addressPrefill from '@library/images/icons/addressPrefill.mp4'
import paritialPrepaid from '@library/images/icons/paritialPrepaid.mp4'
import codAndPrepaid from '@library/images/icons/codAndPrepaid.mp4'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const AdvancePlanDrawer = ({ drawerVisible, setDrawerVisible }) => {
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)
    const [currentSlide, setCurrentSlide] = useState(0);
    const location = useLocation()

    const closeDrawer = () => {
        const eventType = location?.state?.merchantType === 'kwik_cod' 
            ? 'upgrade_preview_closed' 
            : 'preview_closed';
    
        logEvent(
            eventType,
            'click',
            'get_started',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { plan: 'cod_checkout' }
        );
    
        setDrawerVisible(false);
    };

    const carouselContent = [
        {
            videoSrc: addressPrefill,
            title: 'Address autofill for ~80% of users',
            description: "You’re customers don’t have to fill the address every time, Gokwik will automatically retrieve their address information if they have saved in any other platform using Gokwik."
        },
        {
            videoSrc: paritialPrepaid,
            title: 'Partial payment on COD',
            description: 'GoKwik will help you get partial payment on your COD order. It will give you security.'
        },
        {
            videoSrc: codAndPrepaid,
            title: 'GoKwik payments and cash on delivery',
            description: 'This will enable the GoKwik payments options and GoKwik cash on delivery options.'
        },
    ];

    const prevSlide = () => {
        let pageValue = currentSlide === 0 ? 3 : currentSlide === 2 ? 2 : 1
        logEvent(
            'preview_screen_changed',
            'click',
            'get_started',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { plan: 'cod_checkout', value: "previous", page_value: pageValue }
        )
        setCurrentSlide((prev) => (prev === 0 ? carouselContent.length - 1 : prev - 1));
    };

    const nextSlide = () => {
        let pageValue = currentSlide === 2 ? 1 : currentSlide === 0 ? 2 : 3 
        logEvent(
            'preview_screen_changed',
            'click',
            'get_started',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { plan: 'cod_checkout', value: "next", page_value: pageValue }
        )
        setCurrentSlide((prev) => (prev === carouselContent.length - 1 ? 0 : prev + 1));
    };

    return (
        <Drawer
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <div style={{ color: 'rgba(0, 110, 210, 1)', fontSize: '12px', fontWeight: '700' }}>Advance Plan</div>
                        <div style={{ fontSize: '24px', fontWeight: '700', marginBottom: '-10px' }}>COD Suite + Checkout</div>
                    </div>
                    <Button type="text" onClick={closeDrawer} style={{ fontSize: '16px', color: '#000' }}>✕</Button>
                </div>
            }
            open={drawerVisible}
            footer={
                <div style={{ padding: '0.5rem 0rem' }}>
                    <Button onClick={closeDrawer} variant="primary">
                        Understood
                    </Button>
                </div>
            }
            width={585}
            closable={false}
            onClose={closeDrawer}
            style={{
                borderTopLeftRadius: '16px',
                borderBottomLeftRadius: '16px',
            }}
        >
            <div className="mb-5" style={{ color: 'rgba(0, 110, 210, 1)', fontWeight: '500' }}>
                See how it will look on your Shopify store page
            </div>

            <div style={{ position: 'relative', width: '100%', textAlign: 'center', overflow: 'hidden' }}>
                <div style={{
                    display: 'flex',
                    transform: `translateX(-${currentSlide * 100}%)`,
                    transition: 'transform 0.5s ease-in-out',
                    width: '100%',
                }}>
                    {carouselContent.map((content, index) => (
                        <div key={index} style={{ minWidth: '100%', boxSizing: 'border-box' }}>
                            <video className='mb-10' src={content.videoSrc} autoPlay loop muted style={{ width: '100%', borderRadius: '8px' }} />
                            <div style={{ textAlign: 'left' }}>
                                <div className="mb-3 font-semibold" style={{ fontSize: '20px' }}>{content.title}</div>
                                <div style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.88)' }}>{content.description}</div>
                            </div>
                        </div>
                    ))}
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>
                    <button
                        onClick={prevSlide}
                        style={{
                            backgroundColor: 'white',
                            border: '1px solid rgba(0, 0, 0, 0.15)',
                            borderRadius: '5px',
                            width: '24px',
                            height: '24px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 2rem',
                            cursor: 'pointer',
                            outline: 'none',
                        }}
                    >
                        <LeftOutlined />
                    </button>
                    <div style={{ display: 'flex', gap: '5px' }}>
                        {carouselContent.map((_, index) => (
                            <span
                                key={index}
                                style={{
                                    display: 'inline-block',
                                    width: currentSlide === index ? '12px' : '7px',
                                    height: currentSlide === index ? '5px' : '4px',
                                    borderRadius: '35%',
                                    backgroundColor: currentSlide === index ? 'black' : '#ddd',
                                }}
                            />
                        ))}
                    </div>
                    <button
                        onClick={nextSlide}
                        style={{
                            backgroundColor: 'white',
                            border: '1px solid rgba(0, 0, 0, 0.15)',
                            borderRadius: '5px',
                            width: '24px',
                            height: '24px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '0 2rem',
                            cursor: 'pointer',
                            outline: 'none',
                        }}
                    >
                        <RightOutlined />
                    </button>
                </div>
            </div>
        </Drawer>
    );
};

export default AdvancePlanDrawer;
