import { navigateToUrl } from 'single-spa'
import { useLocation, useNavigate } from 'react-router-dom'
import React, { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'

// Import Gokwik UI Kit

// Import Icons
import CheckoutIcon from '@library/images/icons/nav/checkout'
import UsersIcon from '@library/images/icons/nav/users'
import RolesIcon from '@library/images/icons/nav/roles'
import OrdersIcon from '@library/images/icons/nav/ordersIcon'
import AbCartIcon from '@library/images/icons/nav/abcIcon'
import ReportsIcon from '@library/images/icons/nav/reports'
import RefundsIcon from '@library/images/icons/nav/refunds'
import SettingsIcon from '@library/images/icons/nav/settings'
import CustomersIcon from '@library/images/icons/nav/customers'
import MerchantsIcon from '@library/images/icons/nav/merchants'
import DashboardIcon from '@library/images/icons/nav/dashboard'
import PermissionsIcon from '@library/images/icons/nav/permissions'
import SettlementsIcon from '@library/images/icons/nav/settlements'
import TransactionsIcon from '@library/images/icons/nav/transactions'
import NotificationsIcon from '@library/images/icons/nav/scheduled-notifications'
import HistoricalDataIcon from '@library/images/icons/nav/historical'
import IdCard from '@library/images/icons/nav/idCard'
import {
    NavigationMenu,
    FieldTimeOutlined,
    LeftOutlined,
    LinkOutlined,
    PayCircleOutlined,
    RightOutlined,
    ControlOutlined,
    ShopOutlined,
    Tooltip,
    AreaChartOutlined,
    ApiOutlined,
    Badge,
    HeartOutlined,
    RetweetOutlined,
    DeploymentUnitOutlined,
    InfoCircleOutlined,
    BellOutlined,
    UserAddOutlined,
    DollarOutlined,
    UploadOutlined,
    WarningOutlined,
    BarChartOutlined,
    ContactsOutlined,
    InboxOutlined,
    GlobalOutlined,
} from 'gokwik-ui-kit'
import ProfileComponent from './profile'
import logoGokwik from '@library/images/common/logo-gk-white.svg'
import logo from '@library/images/common/GoKwik_Symbol.svg'
import Shop from '@library/images/icons/nav/shop'
import './styles.css'
import { useSelector } from 'react-redux'
import {
    getMerchantConfigs,
    getMerchantDetails,
    getMerchantPlatform,
    getMode,
    getPermissions,
    getUserDetails,
    isMerchantSelector,
} from '@store/user/selectors'
import { validatePermission } from '@gokwik/utilities'
import {
    KPMerchantPlatform,
    KPPlatform,
    MerchantConfigKeys,
    PermissionValues,
} from '@library/utilities/constants/constants'
import AnalyticsIcon from '@library/images/icons/nav/analytics'
import UserIcon from '@library/images/icons/userIcon'
import KwikPassIcon from '@library/images/icons/nav/kwik-pass'
import ControlIcon from '@library/images/icons/nav/controllIcon'
import MarketingAnalyticsIcon from '@library/images/icons/nav/marketingAnalyticsIcon'
import RTOIcon from '@library/images/icons/nav/rtoIcon'
import TimeLineIcon from '@library/images/icons/nav/timeline'
import MoneyIcon from '@library/images/icons/nav/moneyIcon'
import KwikCheckIcon from '@library/images/icons/nav/kwikcheckIcon'
import KwikRTO from '@library/images/icons/nav/kwikRTO'
import AnalyticsRTOIcon from '@library/images/icons/nav/analyticsRTOIcon'
import AccessControl from '@library/images/icons/nav/accessControl'
import { newStepConfiguration } from '@pages/onboarding/stepsConfig'
import KwikpassIntegrationIcon from '@library/images/icons/nav/kwikpass-integration'
import KwikpassFaqIcon from '@library/images/icons/nav/kwikpassFaqIcon'
import KwikFlowsIcon from '@library/images/icons/nav/kwikFlowsIcon'
import KwikpassMyPlanIcon from '@library/images/icons/nav/kwikpass-myplan'

// Define the type for the menu item
interface MenuItem {
    key: string
    label: ReactNode
    icon?: ReactNode
    children?: MenuItem[]
    type?: 'group'
}

// Utility function to create a menu item
function createMenuItem(
    label: ReactNode,
    key: string,
    icon?: ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon: <p className='icon-color ant-menu-item-active '>{icon}</p>,
        children,
        label,
        type,
    }
}

// Define the props interface for the SideNav component
interface SideNavProps {
    isMenuCollapsed: boolean
    toggleMenuCollapse: () => void
    setIsCollapsedMenu: (boolean) => void
}

// Define the SideNav component
const SideNav: React.FC<SideNavProps> = ({ isMenuCollapsed, toggleMenuCollapse, setIsCollapsedMenu }) => {
    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantConfigs = useSelector(getMerchantConfigs)
    const merchant_details = useSelector(getMerchantDetails)
    const route = useLocation()
    const mode = useSelector(getMode)
    const userDetails = useSelector(getUserDetails)
    const isLiveMode = useMemo(() => mode === 'live', [mode])
    const merchantPlatform = useSelector(getMerchantPlatform)
    const showProtectedPages = useMemo(() => {
        if (isMerchantUser || !userDetails) return false
        if (window.location.hostname === 'dashboard.gokwik.co')
            return ['pk@gokwik.co', 'sagar@gokwik.co'].includes(userDetails.email)
        else return true
    }, [userDetails])

    const previousOpenedItems = useRef({
        openedKey: '',
        selectedKey: '',
    })
    const permissions = useSelector(getPermissions)

    const onboardingCompleted = useCallback(
        (app) => {
            if (!isMerchantUser) return true

            const onboardingsStatusMap = {
                kwik_checkout_app:
                    !merchant_details?.onboarding_status?.kwik_checkout_app ||
                    newStepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.id >=
                    newStepConfiguration['imp_checks_passed']?.id,
                kwikpass_app:
                    !merchant_details?.onboarding_status?.kwikpass_app ||
                    merchant_details?.onboarding_status?.kwikpass_app === 'setup_completed',
            }
            return onboardingsStatusMap[app]
        },
        [merchant_details?.onboarding_status],
    )

    const featureFlag = (merchantDetails) => {
        if (process.env.NODE_ENV === 'production') {
            if (merchantDetails?.m_id === '12wyqc2guqmkrw6406j') {
                return true
            } else {
                return false
            }
        }

        return true
    }

    const getDestination = (shopify_app_status, shopify_app_name) => {
        const destinationMap = {
            kwik_checkout_app: () => {
                if (shopify_app_status === 'password_setup' || shopify_app_status === 'onboarding_initiated') {
                    //commenting the following path as we are skiping the COD page
                    return '/onboarding/get-started'
                } else if (
                    shopify_app_status?.includes('kyc') ||
                    shopify_app_status === 'kwik_checkout_button_activated' ||
                    shopify_app_status === 'setup_completed' || 
                    shopify_app_status === 'gk_payments_app_installed' || 
                    shopify_app_status === 'merchant_onboarded'
                ) {
                    return '/shopify-app/settings'
                } else if (
                    [
                        'smart_cod_app_installed',
                        'smart_cod_app_activated',
                        'gokwik_shipping_setup_done',
                        'smart_cod_app_disabled',
                        'imp_checks_passed',
                    ]?.includes(shopify_app_status)
                ) {
                    return '/onboarding/cod-setup'
                } else {
                    //commenting the following path as we are skiping the COD page
                    //return '/onboarding/get-started'
                    return '/onboarding/cod-setup'
                }
            },
            kwikpass_app: () => {
                if (shopify_app_status && !shopify_app_status?.includes('setup_completed')) {
                    return '/onboarding/kp/onboarding-steps'
                } else if (shopify_app_status?.includes('setup_completed')) {
                    return '/kwikpass/configuration'
                }
            },
        }

        if (destinationMap.hasOwnProperty(shopify_app_name) && typeof destinationMap[shopify_app_name] === 'function') {
            return destinationMap[shopify_app_name]()
        }

        return ''
    }

    const shouldShowKwikFlows = (
        isMerchantUser: boolean,
        merchantConfigs: any,
        onboardingCompleted: Function,
        isLiveMode: boolean,
    ) => {
        const hasRtoProduct = merchantConfigs.selected_products?.rto
        const showKwikAI = merchantConfigs?.[MerchantConfigKeys?.showKwikAI]
        const kwikAiRiskNavigation = merchantConfigs?.[MerchantConfigKeys?.kwikAiRiskNavigation]

        return (
            (isMerchantUser &&
                onboardingCompleted('kwik_checkout_app') &&
                isLiveMode &&
                hasRtoProduct &&
                showKwikAI &&
                kwikAiRiskNavigation) ||
            (!isMerchantUser && hasRtoProduct && showKwikAI && kwikAiRiskNavigation)
        )
    }

    const executiveSummaryFeatureFlags = [
        'sherali.hussain@gokwik.co',
        'anuj.agrawal@gokwik.co',
        'mahima.garg@gokwik.co',
        'aditya@gokwik.co',
        'lasya.priya@gokwik.co',
        'prashant.sharma@gokwik.co',
        'mandeep@gokwik.co',
        'srikant@gokwik.co',
        'suhag.vamja@gokwik.co',
    ]

    const showKwikPyaments = () => {
        if (merchantConfigs?.supported_checkout_configs?.kwik_checkout && newStepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.id >= newStepConfiguration['gk_payments_app_installed']?.id) {
            return true
        } else if (!merchant_details?.onboarding_status?.kwik_checkout_app || !merchantConfigs?.supported_checkout_configs?.kwik_checkout || merchantConfigs?.supported_checkout_configs?.checkout_1 || merchantConfigs?.supported_checkout_configs?.custom) {
            return true
        } else {
            return false
        }  
    }

    // Function to handle Menu Selection
    const items: MenuItem[] = useMemo(() => {
        //Temporary code - to be removed once KP app gets approval from shopify
        // if (
        //     merchant_details?.onboarding_status?.kwikpass_app &&
        //     merchant_details?.onboarding_status?.kwikpass_app === 'setup_completed'
        // ) {
        //     return [
        //         createMenuItem('Kwik Pass', 'kwikpass', <KwikPassIcon />, [
        //             createMenuItem('Configuration', '/kwikpass/configuration', <SettingsIcon />),
        //             createMenuItem('My Plan', '/kwikpass/yourplan', <AnalyticsIcon />),
        //         ]),
        //     ]
        // } else if (merchant_details?.onboarding_status?.kwikpass_app) {
        //     return [
        //         createMenuItem(
        //             'Kwik Pass',
        //             '/onboarding/kp/onboarding-steps',
        //             <Badge color='#faad14' count={1} status='warning' size='small' offset={[0, 5]}>
        //                 <KwikPassIcon />
        //             </Badge>,
        //         ),
        //     ]
        // }

        // this is the original array that needs to be returned once kp app gets approval from shopify
        return [
            ...(validatePermission(PermissionValues.dashboard.view) &&
            !isMerchantUser &&
            executiveSummaryFeatureFlags?.includes(userDetails?.email)
                ? [
                      createMenuItem(
                          'Executive Summary',
                          '/executive-summary',
                          <BarChartOutlined className='!text-2xl' />,
                      ),
                  ]
                : []),
            ...(validatePermission(PermissionValues.dashboard.view) && !isMerchantUser
                ? [createMenuItem('Dashboard', '/dashboard', <AreaChartOutlined className='!text-2xl' />)]
                : []),
            ...(onboardingCompleted('kwik_checkout_app') &&
            merchantConfigs?.selected_products?.checkout &&
            validatePermission(PermissionValues.orders.view)
                ? [createMenuItem('Orders', '/checkout/orders', <OrdersIcon />)]
                : [
                      createMenuItem(
                          <span className='blur-sm pointer-events-none'>Orders</span>,
                          '',
                          <span className='blur-sm pointer-events-none'>
                              <OrdersIcon />
                          </span>,
                      ),
                  ]),

            ...(merchantConfigs?.selected_products?.checkout &&
            onboardingCompleted('kwik_checkout_app') &&
            (validatePermission(PermissionValues.orders.view) ||
                validatePermission(PermissionValues.abandonedCarts.view) ||
                validatePermission(PermissionValues.checkoutAnalytics.view))
                ? [
                      createMenuItem('Kwik Checkout', 'checkout', <CheckoutIcon />, [
                          ...(validatePermission(PermissionValues.abandonedCarts.view)
                              ? [createMenuItem('Abandoned Cart', '/checkout/abandoned-cart', <AbCartIcon />)]
                              : []),
                          ...(validatePermission(PermissionValues.checkoutAnalytics.view) &&
                          ((isLiveMode && !!merchantConfigs?.[MerchantConfigKeys.checkoutAnalytics]) ||
                              (!isMerchantUser && !!merchantConfigs?.[MerchantConfigKeys.checkoutAnalytics]))
                              ? !!merchantConfigs?.supported_checkout_configs?.[MerchantConfigKeys.checkoutOne]
                                  ? [createMenuItem('Analytics', '/checkout/analytics', <AnalyticsIcon />)]
                                  : !!merchantConfigs?.supported_checkout_configs?.[MerchantConfigKeys.kwikCheckout]
                                  ? [createMenuItem('Analytics', '/shopify-app/analytics', <AnalyticsIcon />)]
                                  : [createMenuItem('Analytics', '/checkout/analytics', <AnalyticsIcon />)]
                              : []),
                          ...(validatePermission(PermissionValues.marketing_analytics.view) &&
                          (isLiveMode || !isMerchantUser) &&
                          !!merchantConfigs?.[MerchantConfigKeys.marketingAnalytics]
                              ? [
                                    createMenuItem(
                                        'Marketing',
                                        '/checkout/marketing-analytics',
                                        <MarketingAnalyticsIcon />,
                                    ),
                                ]
                              : []),
                          ...(userDetails.role.name === 'super_admin' && !isMerchantUser
                              ? [
                                    createMenuItem(
                                        'Checkout Health',
                                        '/checkout/health',
                                        <HeartOutlined className='!text-xl' />,
                                    ),
                                ]
                              : []),

                          ...(merchantPlatform === 'shopify' &&
                          mode !== 'test' &&
                          validatePermission(PermissionValues.settings.view)
                              ? !!merchantConfigs?.supported_checkout_configs?.[MerchantConfigKeys.checkoutOne]
                                  ? [createMenuItem('Settings', '/checkout/settings', <SettingsIcon />)]
                                  : !!merchantConfigs?.supported_checkout_configs?.[MerchantConfigKeys.kwikCheckout]
                                  ? [createMenuItem('Settings', '/shopify-app/settings', <SettingsIcon />)]
                                  : []
                              : merchantPlatform === 'custom' || merchantConfigs?.is_gkp_enabled
                              ? [createMenuItem('Settings', '/checkout-platform/settings', <SettingsIcon />)]
                              : []),

                          ...(merchantPlatform === 'shopify' &&
                          mode !== 'test' &&
                          !isMerchantUser &&
                          validatePermission(PermissionValues.checkoutAdmin.view) &&
                          !!merchantConfigs?.supported_checkout_configs?.[MerchantConfigKeys.kwikCheckout]
                              ? [createMenuItem('Admin', '/shopify-app/admin', <SettingsIcon />)]
                              : []),
                      ]),
                  ]
                : [
                      createMenuItem(
                          'Kwik Checkout',
                          getDestination(merchant_details?.onboarding_status?.kwik_checkout_app, 'kwik_checkout_app'),
                          <Badge color='#faad14' count={1} status='warning' size='small' offset={[0, 5]}>
                              <CheckoutIcon />
                          </Badge>,
                      ),
                  ]),

            ...((isMerchantUser &&
                onboardingCompleted('kwik_checkout_app') &&
                showKwikPyaments() &&
                isLiveMode &&
                merchantConfigs?.selected_products?.payments) ||
            (!isMerchantUser &&
                merchantConfigs?.selected_products?.payments &&
                (validatePermission(PermissionValues.refunds.view) ||
                    validatePermission(PermissionValues.transactions.view) ||
                    validatePermission(PermissionValues.settlements.view) ||
                    validatePermission(PermissionValues.paymentLinks.view) ||
                    validatePermission(PermissionValues.paymentAnalytics.view) ||
                    validatePermission(PermissionValues.settings.view)))
                ? [
                      createMenuItem('Kwik Payment', 'payment', <SettlementsIcon />, [
                          ...(validatePermission(PermissionValues.transactions.view)
                              ? // &&
                                // !!merchantConfigs?.[MerchantConfigKeys.transactions]
                                [createMenuItem('Transactions', '/payment/transactions', <TransactionsIcon />)]
                              : []),
                          ...(validatePermission(PermissionValues.paymentLinks.view) &&
                          !!merchantConfigs?.[MerchantConfigKeys.paymentLinks]
                              ? [
                                    createMenuItem(
                                        'Payment Links',
                                        '/payment/payment-links',
                                        <LinkOutlined className='!text-xl' />,
                                    ),
                                ]
                              : []),
                          ...(validatePermission(PermissionValues.refunds.view)
                              ? [createMenuItem('Refunds', '/payment/refunds', <RefundsIcon />)]
                              : []),
                          // ...(!isMerchantUser ? [createMenuItem('Chargebacks', '/payment/chargebacks', <RefundsIcon />)] :[]
                          //     ),
                          ...(!!merchantConfigs?.[MerchantConfigKeys.paymentsSettlementDashboardConfig]?.enable &&
                          validatePermission(PermissionValues.reports.view) &&
                          !isMerchantUser
                              ? [createMenuItem('Settlements', '/payment/settlements', <SettlementsIcon />)]
                              : []),
                          ...(validatePermission(PermissionValues.paymentAnalytics.view) &&
                          !!merchantConfigs?.[MerchantConfigKeys.paymentAnalytics]
                              ? [createMenuItem('Analytics', '/payment/analytics', <AnalyticsIcon />)]
                              : []),
                          ...(validatePermission(PermissionValues.settings.view)
                              ? [createMenuItem('Settings', '/payment/settings', <SettingsIcon />)]
                              : []),
                      ]),
                  ]
                : merchant_details?.onboarding_status?.kwik_checkout_app && !onboardingCompleted('kwik_checkout_app')
                ? [
                      createMenuItem(
                          <span className='blur-sm pointer-events-none'>Kwik Payment</span>,
                          '',
                          <span className='blur-sm pointer-events-none'>
                              <SettlementsIcon />
                          </span>,
                      ),
                  ]
                : []),
            ...(merchantConfigs?.kwikpass_merchant &&
            isMerchantUser &&
            onboardingCompleted('kwikpass_app') &&
            isLiveMode &&
            merchantConfigs.selected_products?.kwik_pass
                ? [
                      createMenuItem('Kwik Pass', 'kwikpass', <KwikPassIcon />, [
                          merchantConfigs?.kp_merchant_platform?.toLowerCase() !== KPMerchantPlatform.custom_headless &&
                              merchantConfigs.kp_platform.includes(KPPlatform.web) &&
                              createMenuItem('Configuration', '/kwikpass/configuration', <SettingsIcon />),
                          createMenuItem('Analytics', '/kwikpass/analytics', <AnalyticsIcon />),
                          (merchantConfigs?.kp_merchant_platform?.toLowerCase() === KPMerchantPlatform.custom_shopify ||
                              (merchantConfigs?.kp_merchant_platform?.toLowerCase() == KPMerchantPlatform.shopify &&
                                  merchantConfigs.kp_platform.includes(KPPlatform.web))) &&
                              createMenuItem('Integration', '/kwikpass/integration', <KwikpassIntegrationIcon />),
                          createMenuItem('FAQ', '/kwikpass/faq', <KwikpassFaqIcon />),
                          createMenuItem('My Plan', '/kwikpass/yourplan', <KwikpassMyPlanIcon />),
                      ]),
                  ]
                : !isMerchantUser && merchantConfigs.selected_products?.kwik_pass
                ? [
                      createMenuItem('Kwik Pass', 'kwikpass', <KwikPassIcon />, [
                          createMenuItem('Configuration', '/kwikpass/configuration', <SettingsIcon />),
                          createMenuItem('Analytics', '/kwikpass/analytics', <AnalyticsIcon />),
                          createMenuItem('Integration', '/kwikpass/integration', <KwikpassIntegrationIcon />),
                          createMenuItem('FAQ', '/kwikpass/faq', <KwikpassFaqIcon />),
                      ]),
                  ]
                : merchant_details?.onboarding_status?.kwikpass_app && !onboardingCompleted('kwikpass_app')
                ? [
                      createMenuItem(
                          'Kwik Pass',
                          getDestination(merchant_details?.onboarding_status?.kwikpass_app, 'kwikpass_app'),
                          <Badge color='#faad14' count={1} status='warning' size='small' offset={[0, 5]}>
                              <KwikPassIcon />
                          </Badge>,
                      ),
                  ]
                : []),
            ...((isMerchantUser &&
                onboardingCompleted('kwik_checkout_app') &&
                isLiveMode &&
                merchantConfigs.selected_products?.rto) ||
            (!isMerchantUser && merchantConfigs.selected_products?.rto)
                ? [
                      createMenuItem('KwikRTO', 'rto', <KwikRTO />, [
                          ...(merchantConfigs?.[MerchantConfigKeys?.showRTOAnalytics]
                              ? [createMenuItem('RTO Analytics', '/rto/analytics', <AnalyticsRTOIcon />)]
                              : []),
                          ...(merchantConfigs?.[MerchantConfigKeys?.kcConfig]?.[MerchantConfigKeys?.includeRTORisk]
                              ? [createMenuItem('Verify Orders', '/rto/postOrderVerification', <KwikCheckIcon />)]
                              : []),
                          createMenuItem('Settings', '/rto/settings', <SettingsIcon />),
                      ]),
                  ]
                : merchant_details?.onboarding_status?.kwik_checkout_app && !onboardingCompleted('kwik_checkout_app')
                ? [
                      createMenuItem(
                          <span className='blur-sm pointer-events-none'>KwikRTO</span>,
                          '',
                          <span className='blur-sm pointer-events-none'>
                              <KwikRTO />
                          </span>,
                      ),
                  ]
                : []),

            ...(shouldShowKwikFlows(isMerchantUser, merchantConfigs, onboardingCompleted, isLiveMode)
                ? [createMenuItem('KwikFlows', '/rto/kwikflows/all-workflows', <KwikFlowsIcon />)]
                : []),

            ...(!isMerchantUser || (isMerchantUser && onboardingCompleted('kwik_checkout_app'))
                ? [
                      createMenuItem('Admin', 'general', <CustomersIcon />, [
                          ...(validatePermission(PermissionValues.reports.view) &&
                          ((isMerchantUser && isLiveMode && !!merchantConfigs?.[MerchantConfigKeys.showReports]) ||
                              (!isMerchantUser && !!merchantConfigs?.[MerchantConfigKeys.showReports]))
                              ? [createMenuItem('Reports', '/general/reports', <ReportsIcon />)]
                              : []),
                          ...(!isMerchantUser && validatePermission(PermissionValues.bulkUpload.upload)
                              ? [
                                    createMenuItem(
                                        'Uploads',
                                        '/general/bulk-upload',
                                        <UploadOutlined style={{ fontSize: '24px' }} />,
                                    ),
                                ]
                              : []),
                          ...[
                              ...(!isMerchantUser
                                  ? [createMenuItem('Timeline', '/general/timeline', <TimeLineIcon />)]
                                  : []),
                              ...(validatePermission(PermissionValues.rate_capture.view) && !isMerchantUser
                                  ? [createMenuItem('Rate Capture', '/general/rateCapture', <MoneyIcon />)]
                                  : []),
                          ],

                          createMenuItem('Teams', '/general/teams', <UsersIcon />),
                          createMenuItem('Account', '/general/account', <IdCard />),
                          ...(isMerchantUser
                              ? [createMenuItem('Customers', '/admin/customers', <CustomersIcon />)]
                              : []),
                          ...(!isMerchantUser
                              ? [createMenuItem('A/B Testing', '/general/ab-testing', <SettingsIcon />)]
                              : []),
                          //   createMenuItem('Customers', '/general/customers', <CustomersIcon/>),
                          ...(!isMerchantUser
                              ? [
                                    createMenuItem(
                                        <Tooltip title='Schedule Notification'>Notifications</Tooltip>,
                                        '/general/schedule-notifications',
                                        <NotificationsIcon />,
                                    ),
                                    // validatePermission(PermissionValues.roles.view) &&
                                    //     validatePermission(PermissionValues.permissions.view) &&
                                    //     createMenuItem(
                                    //         <Tooltip title={'Roles and Permissions'}>
                                    //             {' '}
                                    //             <span className='truncate max-w-6'> Roles/Permiss... </span>{' '}
                                    //         </Tooltip>,
                                    //         '/general/roles-and-permissions',
                                    //         <CustomersIcon />,
                                    // ),
                                ]
                              : []),
                      ]),
                  ]
                : [
                      createMenuItem(
                          <span className='blur-sm pointer-events-none'>Admin</span>,
                          '',
                          <span className='blur-sm pointer-events-none'>
                              <CustomersIcon />
                          </span>,
                      ),
                  ]),

            ...(isMerchantUser && onboardingCompleted('kwik_checkout_app') && featureFlag(merchant_details)
                ? [createMenuItem('Integrations', '/data-integration', <ApiOutlined className='!text-2xl' />)]
                : []),

            ...(merchantConfigs.selected_products?.kwik_commerce
                ? [
                      createMenuItem('Kwik Commerce', 'kwikCommerce', <SettlementsIcon />, [
                          createMenuItem('Dark Store', '/kwikcomm/darkstore', <SettingsIcon />),
                          createMenuItem('Stockout', '/kwikcomm/stockout', <SettingsIcon />),
                          createMenuItem('Pincode Mapping', '/kwikcomm/storePincodeMapTable', <SettingsIcon />),
                          createMenuItem(
                              <Tooltip title={'Datewise Stockout Trends'}>
                                  <span>Datewise Sto...</span>
                              </Tooltip>,
                              '/kwikcomm/datewise-stockout-trends',
                              <AnalyticsIcon />,
                          ),
                          createMenuItem(
                              <Tooltip title={'Citywise Stockout Trends'}>
                                  <span>Citywise Sto...</span>
                              </Tooltip>,

                              '/kwikcomm/citywise-stockout-trends',
                              <AnalyticsIcon />,
                          ),
                          createMenuItem(
                              <Tooltip title={'Dark Store Count'}>
                                  <span>Dark Sto...</span>
                              </Tooltip>,
                              '/kwikcomm/darkstore-count',
                              <ContactsOutlined />,
                          ),
                          createMenuItem('Inventory', '/kwikcomm/inventory', <InboxOutlined />),
                          createMenuItem('Citywise Inventory', '/kwikcomm/citywise-inventory', <GlobalOutlined />),
                      ]),
                  ]
                : []),

            ...(merchantConfigs.selected_products?.kwikship
                ? [
                      createMenuItem('KwikShip', '/kwikship', <OrdersIcon />, [
                          createMenuItem('Shipments', '/kwikship/shipments', <OrdersIcon />),
                          ...(merchantConfigs.selected_products?.kwikship_shopifyOMS
                              ? [
                                    createMenuItem('Orders', '/kwikship/orders', <KwikCheckIcon />),
                                    createMenuItem(
                                        'Bulk Operations',
                                        '/kwikship/bulk-operations',
                                        <AnalyticsRTOIcon />,
                                    ),
                                ]
                              : []),
                          createMenuItem('Need Action', 'need-action', <WarningOutlined />),
                      ]),
                  ]
                : []),

            ...(!isMerchantUser
                ? [
                      createMenuItem(
                          <Tooltip title='Global Settings'>Global Settings</Tooltip>,
                          '/global-settings/*',
                          <SettingsIcon />,
                      ),
                  ]
                : []),
            //   ...(!isMerchantUser && validatePermission(PermissionValues.users.view)
            //       ? [
            //                 createMenuItem('Access Control', 'access', <AccessControl />, [
            //                ...( !isMerchantUser ? [createMenuItem('Customers', '/access-control/customers', <CustomersIcon />)] : []),
            //                 // createMenuItem('Users', '/access-control/users', <UsersIcon />),
            //                 createMenuItem(<p className='truncate'>Roles/Permissions</p>,'/general/rolesAndPermissions',<CustomersIcon />),
            //             ]),
            //         ]
            //       : []),

            ...(!isMerchantUser &&
            (validatePermission(PermissionValues.users.view) ||
                validatePermission(PermissionValues.roles.view) ||
                validatePermission(PermissionValues.permissions.view) ||
                validatePermission(PermissionValues.customers.view))
                ? [
                      createMenuItem('Access Control', 'access', <AccessControl />, [
                          //    ...( !isMerchantUser ? [createMenuItem('Customers', '/access-control/customers', <CustomersIcon />)] : []),
                          validatePermission(PermissionValues.users.view) &&
                              createMenuItem('Users', '/access-control/users', <UsersIcon />),
                          validatePermission(PermissionValues.roles.view) &&
                              validatePermission(PermissionValues.permissions.view) &&
                              createMenuItem(
                                  <Tooltip title={'Roles and Permissions'}>
                                      {' '}
                                      <span className='truncate max-w-6'> Roles/Permiss... </span>{' '}
                                  </Tooltip>,
                                  '/general/roles-and-permissions',
                                  <CustomersIcon />,
                              ),
                          validatePermission(PermissionValues.customers.view) &&
                              createMenuItem('Customers', '/access-control/customers', <CustomersIcon />),
                          // createMenuItem(<p className='truncate'>Roles/Permissions</p>,'/general/rolesAndPermissions',<CustomersIcon />),
                      ]),
                  ]
                : []),
            ...(showProtectedPages
                ? [
                      createMenuItem('Ellora', 'ellora', <DeploymentUnitOutlined className='!text-xl' />, [
                          createMenuItem('Items', '/ellora/items-service', <InfoCircleOutlined className='!text-xl' />),
                          createMenuItem(
                              'Notifications',
                              '/ellora/notifications-service',
                              <BellOutlined className='!text-xl' />,
                          ),
                          createMenuItem('Auth', '/ellora/auth-service', <UserAddOutlined className='!text-xl' />),
                      ]),
                  ]
                : []),
        ]
    }, [isMerchantUser, permissions])
    const openItems = useMemo(() => {
        if (route.pathname === '/executive-summary') {
            previousOpenedItems.current = {
                openedKey: 'Executive Summary',
                selectedKey: route.pathname,
            }
            return {
                openedKey: 'Executive Summary',
                selectedKey: route.pathname,
            }
        }
        if (route.pathname.includes('/global-settings')) {
            previousOpenedItems.current = {
                openedKey: 'Global Settings',
                selectedKey: '/global-settings/*',
            }
            return {
                openedKey: 'Global Settings',
                selectedKey: '/global-settings/*',
            }
        }
        if (route.pathname === '/dashboard') {
            previousOpenedItems.current = {
                openedKey: 'Dashboard',
                selectedKey: route.pathname,
            }
            return {
                openedKey: 'Dashboard',
                selectedKey: route.pathname,
            }
        }
        if (route.pathname === '/checkout/orders') {
            previousOpenedItems.current = {
                openedKey: 'Orders',
                selectedKey: route.pathname,
            }
            return {
                openedKey: 'Orders',
                selectedKey: route.pathname,
            }
        }
        if (route.pathname === '/data-integration') {
            previousOpenedItems.current = {
                openedKey: 'Integrations',
                selectedKey: route.pathname,
            }
            return {
                openedKey: 'Integrations',
                selectedKey: route.pathname,
            }
        }
        if (route.pathname === '/rto/kwikflows' || route.pathname == '/rto/kwikflows/all-workflows') {
            previousOpenedItems.current = {
                openedKey: 'KwikFlows',
                selectedKey: '/rto/kwikflows/all-workflows',
            }
            return {
                openedKey: 'KwikFlows',
                selectedKey: '/rto/kwikflows/all-workflows',
            }
        }
        const openedKey = items.find((item) => !!item.children?.find((child) => route.pathname === child.key))

        if (openedKey) {
            previousOpenedItems.current = {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
            return {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
        } else if (previousOpenedItems.current.openedKey && !openedKey) {
            return {
                ...previousOpenedItems.current,
            }
        } else {
            let similarSelectedKey: MenuItem
            const similarKey = items.find(
                (item) =>
                    !!item.children?.find((child) => {
                        if (route.pathname.includes(child.key)) {
                            similarSelectedKey = child
                            return true
                        } else return false
                    }),
            )
            previousOpenedItems.current = {
                openedKey: similarKey?.key,
                selectedKey: similarSelectedKey?.key,
            }
            return {
                openedKey: similarKey?.key,
                selectedKey: similarSelectedKey?.key,
            }
        }
    }, [items, route.pathname])

    const [openKeys, setOpenKeys] = useState(isMenuCollapsed ? [] : [openItems.openedKey])
    useEffect(() => {
        setOpenKeys(isMenuCollapsed ? [] : [openItems?.openedKey])
    }, [openItems])

    const handleMenuSelection = ({ key }: any) => {
        navigateToUrl(key)
    }
    const onOpenChange = (keys) => {
        let selectedKey = openItems?.openedKey
        if (keys.length <= 1) {
            setOpenKeys([...keys])
            return
        }
        if (keys.length <= 2) {
            if (keys.includes(selectedKey)) setOpenKeys([...keys])
            else setOpenKeys([keys[1]])
            return
        }

        let latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
        if (latestOpenKey) {
            setOpenKeys(isMenuCollapsed ? [latestOpenKey] : [latestOpenKey, openItems?.openedKey])
        } else {
            setOpenKeys([])
        }
    }

    useEffect(() => {
        if (
            (!onboardingCompleted('kwik_checkout_app') ||
                !onboardingCompleted('kwikpass_app') ||
                route.pathname.includes('kwikpass/') ||
                route.pathname?.includes('checkout/settings') ||
                route.pathname?.includes('shopify-app/settings') ||
                route.pathname.includes('onboarding')) &&
            !isMenuCollapsed
        ) {
            setIsCollapsedMenu(true)
        }
    }, [
        merchant_details?.onboarding_status?.kwik_checkout_app,
        route.pathname,
        localStorage.getItem('checkoutPlatform'),
    ])

    return (
        <div key={route.hash} className='relative inline-block z-[1]'>
            <aside
                className={`flex flex-col ${
                    !isMenuCollapsed ? 'min-w-[14rem] max-w-full' : 'max-w-[65px]'
                } bg-primary-600 overflow-x-hidden max-h-screen h-screen overflow-y-auto `}
                id='side-nav'
            >
                <img
                    className={`mt-3 mb-3 ${!isMenuCollapsed ? 'ml-4 w-[132px]' : 'ml-4 w-[35px]'} h-auto`}
                    src={isMenuCollapsed ? logo : logoGokwik}
                />
                <div className={`overflow-y-auto flex-grow nav-menu-wrapper bg-primary-600`}>
                    <NavigationMenu
                        onClick={handleMenuSelection}
                        openKeys={openKeys}
                        defaultOpenKeys={isMenuCollapsed ? [] : [openItems.openedKey]}
                        defaultSelectedKeys={[openItems.selectedKey]}
                        onOpenChange={onOpenChange}
                        style={{
                            width: '100%',
                            paddingLeft: '0.75rem',
                            color: 'white',
                        }}
                        key={route.pathname + `${isMenuCollapsed}`}
                        // @ts-ignore
                        items={items}
                        inlineCollapsed={isMenuCollapsed}
                    />
                </div>
                <ProfileComponent isProfileDisplayed={isMenuCollapsed} />
                <div
                    className='flex flex-1 justify-center items-center absolute h-8 w-8 rounded-full bottom-[65px] -right-2.5 cursor-pointer bg-[#61b5ff]'
                    onClick={() => {
                        setOpenKeys(!isMenuCollapsed ? [] : [openItems?.openedKey])
                        toggleMenuCollapse()
                    }}
                >
                    {!isMenuCollapsed ? <LeftOutlined size={15} /> : <RightOutlined size={15} />}
                </div>
            </aside>
        </div>
    )
}

export default SideNav
