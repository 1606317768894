import { shopifyAppNotification } from '@pages/onboarding/kwikpass-onboarding/steps/helper'
import { Alert, Radio, Form, message, Button, Input, Modal } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'

export default function CustomPlan({ plan, modalDisplay, onClose, handleCustomSubmit, merchant_details }) {
    const [form] = Form.useForm()
    const [onboardingConfirmation, setOnboardingConfirmation] = useState('')
    const [modalStatus, setModalStatus] = useState(modalDisplay)

    useEffect(() => {
        setModalStatus(modalDisplay)
        !modalDisplay && setOnboardingConfirmation('')
    }, [modalDisplay])

    const handleSubmit = async (values) => {
        const { verificationCode } = values
        if (verificationCode === 'FW74LJlLPb') {
            handleCustomSubmit(plan)
        } else {
            message.error('Invalid verification code, please try again.')
        }
    }
    return (
        <>
            <Modal
                open={modalStatus}
                destroyOnClose
                title='Upgrade to Custom Plan'
                centered
                onCancel={onClose}
                footer={null}
                width={400}
            >
                {!onboardingConfirmation && (
                    <>
                        <p className='mb-2'>The Kwikpass team has completed my onboarding?</p>
                        <Radio.Group
                            onChange={(e) => {
                                setOnboardingConfirmation(e.target.value)
                                e.target.value === 'no' && shopifyAppNotification(merchant_details?.m_id)
                            }}
                            options={[
                                {
                                    value: 'yes',
                                    label: 'Yes',
                                },
                                {
                                    value: 'no',
                                    label: 'No',
                                },
                            ]}
                        />
                    </>
                )}
                {onboardingConfirmation === 'yes' && (
                    <>
                        <Form form={form} name='verification-form' onFinish={handleSubmit} layout='vertical'>
                            <Form.Item
                                label='Enter access code shared by Kwikpass'
                                name='verificationCode'
                                rules={[{ required: true, message: 'Please input the verification code!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item>
                                <Button className='w-full bg-[#004b8c] text-white' htmlType='submit' block>
                                    Verify
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                )}
                {onboardingConfirmation === 'no' && (
                    <Alert
                        message='Our customer support team will be in touch with in 48 hours! Keep an eye on your email.'
                        type='info'
                    />
                )}
            </Modal>
        </>
    )
}
