// import React, { useEffect } from 'react'
// import ReactDOM from 'react-dom'

// const ScheduleBanner = ({ message }) => {
//     return ReactDOM.createPortal(
//         <div className='bg-[#efb136] p-1 text-white text-center fixed top-0 left-0 w-full z-10'>{message}</div>,
//         document.body,
//     )
// }

// export default ScheduleBanner

const ScheduleBanner = ({ message }) => {
    if (!message) return null // Render nothing if no message

    return <div className='bg-[#efb136] p-1 text-white text-center'>{message}</div>
}

export default ScheduleBanner
