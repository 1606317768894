import React, { useState, useEffect } from 'react'
import { Button, Col, Row, Tabs, Tooltip, message } from 'gokwik-ui-kit'
import { SearchOutlined, RedoOutlined, PlusCircleOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { AllowedMerchant } from '@library/utilities/interface'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetails, isMerchantSelector } from '@store/user/selectors'
import { makeAPICall, validatePermission } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { PermissionValues } from '@library/utilities/constants/constants'
import { handleError } from '@library/utilities/helpers/handleError'
import Roles from './roles'
import Permissions from './permissions'
export default () => {
    const [action, setAction] = useState(null)
    const [openDrawer, setOpenDrawer] = useState(false)
    const isMerchantUser = useSelector(isMerchantSelector)
    const userData = useSelector(getUserDetails)
    const allowedMerchants: AllowedMerchant[] = userData?.allowed_merchants
    const [merchantList, setMerchantList] = useState([])
    const [roles, setRoles] = useState([])
    const [updateUserParams, setUpdateUserParams] = useState({})
    const [makeUserCall, setMakeUserCall] = useState(false)
    const [modal, setModal] = useState('')
    const [currentTab, setCurrentTab] = useState('1')
    const takeAction = (id, record, user_action) => {
        setAction(user_action)

        if (user_action === 'update_user') {
            setUpdateUserParams({
                name: record?.name,
                email: record?.email,
                phone: record?.phone,
                role_id: record?.role_id,
                status: record?.status,
                id: record?.id,
            })
        }
        setOpenDrawer(true)
    }

    const getMerchantList = async () => {
        try {
            if (isMerchantUser) {
                setMerchantList(allowedMerchants)
            } else {
                let response = await makeAPICall({
                    method: 'get',
                    url: process.env.REACT_APP_BASE_URL + APIEndPoints.getMerchantList,
                })

                if (response?.data?.data?.length) {
                    setMerchantList(response?.data?.data)
                }
            }
        } catch (err) {
            console.warn(err)
        }
    }

    const getUserRoles = async () => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getUserRoles,
            })
            console.log(response)

            if (response?.data?.data?.roles.length) {
                setRoles(response?.data?.data?.roles)
            }
        } catch (error) {
            handleError(error)
        }
    }

    useEffect(() => {
        getMerchantList()
        getUserRoles()
    }, [])

    const items = [
        {
            key: '1',
            label: 'Roles',
            children: <Roles isModalOpen={modal} setModal={setModal}/>,
        },
        {
            key: '2',
            label: 'Permissions',
            children: <Permissions isModalOpen={modal} setModal={setModal}/>,
        },
    ]

    return (
        <>
            <div className='w-full h-full'>
                {(currentTab === '1'
                    ? validatePermission(PermissionValues?.roles?.add_roles)
                    : validatePermission(PermissionValues?.permissions?.add_permissions)) && (
                    <Row className='p-2 rounded bg-neutrals-15 mb-4' align={'middle'} justify={'space-between'}>
                        <Col sm={12} className='flex items-center'>
                            <div className='text-black inter m-0 tracking-wide items-center flex gap-2'>
                                <Tooltip
                                    title={
                                        'Create new roles or modify existing ones to fit specific job functions or responsibilities. Allocate specific permissions to each role, determining what actions users in those roles can perform'
                                    }
                                >
                                    <InfoCircleOutlined className='text-blue fs20' />
                                </Tooltip>

                            <span>Manage user access & capabilities by defining roles and assigning permissions</span>
                            </div>
                        </Col>
                        {/* <Col sm={12} className='flex items-center gap-x-2'></Col> */}
                        <Col className='flex justify-end' sm={2}>
                            <Button
                                onClick={() => {
                                if(currentTab === '1')
                                setModal('Roles')
                                else
                                setModal('Permissions')
                                }}
                                variant='primary'
                            >
                                <PlusCircleOutlined />
                                Add New
                            </Button>
                        </Col>
                    </Row>
                )}
                <Tabs
                    tabBarStyle={{
                        paddingLeft: '1rem',
                        background: 'white',
                        maxWidth: '100%',
                        margin: 0,
                        borderTopLeftRadius: '0.25rem',
                        borderTopRightRadius: '0.25rem',
                        // marginTop: '20px',
                    }}
                    className=' w-[100%] rounded-t'
                    items={items}
                    onChange={(value)=>{setCurrentTab(value)}}
                />
            </div>
        </>
    )
}
