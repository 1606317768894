import { FC, useCallback, useEffect, useState } from 'react'
import Plans from './plans'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import InfoIcon from '@library/images/common/info.svg'
import { ArrowLeftIcon } from '@library/images/common/arrowLeft'
import bgVector1 from '@library/images/common/bg-vector1.png'
import rectangle from '@library/images/common/rectangle.png'
import { Alert, Col, Row } from 'gokwik-ui-kit'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useAppDispatch, useInterval } from '@library/utilities/hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchUserDetails } from '@store/user'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

interface PricingDetailsProps {}

const PricingDetails: FC<PricingDetailsProps> = (props) => {
    const [showAlert, setShowAlert] = useState(false)
    const merchant_details = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const [interval, initiateInterval, removeInterval] = useInterval(() => fetchShopifyApprovalStatusOfMerchant(), 5000)
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const intervalHandling = useCallback(
        (result) => {
            const status_code = result?.data?.status_code
            const current_onboarding_status = result?.data?.data?.current_onboarding_status
            dispatch(fetchUserDetails())
            if (status_code === 200 && current_onboarding_status === 'pricing_details_confirmed') {
                logEvent('pricing_approval_success', 'click', 'pricing', user_details?.email, merchant_details?.m_id)
                if (interval) {
                    removeInterval()
                }
                //commenting the following path as we are skiping the COD page
                // navigate('/onboarding/get-started')
                navigate('/onboarding/kc/onboarding-steps')
            } else if (current_onboarding_status === 'pricing_approval_pending') {
                initiateInterval()
            } else {
                logEvent('pricing_approval_failure', 'click', 'pricing', user_details?.email, merchant_details?.m_id)
                if (interval) {
                    removeInterval()
                }
                //commenting the following path as we are skiping the COD page
                // navigate('/onboarding/get-started')
                navigate('/onboarding/kc/onboarding-steps')
            }
        },
        [merchant_details?.onboarding_status?.kwik_checkout_app],
    )

    useEffect(() => {
        logEvent(
            'pricing_step_avail_offer_clicked',
            'click',
            'get started',
            user_details?.email,
            merchant_details?.m_id,
        )
        if (merchant_details?.onboarding_status?.kwik_checkout_app === 'pricing_approval_pending') {
            fetchShopifyApprovalStatusOfMerchant()
        }
    }, [])

    const fetchShopifyApprovalStatusOfMerchant = async () => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url:
                    process.env.REACT_APP_BASE_URL +
                    APIEndPoints.getShopifyApprovalStatusForMerchant +
                    merchant_details?.m_id,
            })

            console.log(response, 'responsezzzz')
            intervalHandling(response)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (merchant_details?.onboarding_status?.kwik_checkout_app === 'pricing_details_selected') setShowAlert(true)
    }, [merchant_details?.onboarding_status?.kwik_checkout_app])

    return (
        <div className="relative h-full w-full font-['Inter'] pb-8">
            <img
                src={bgVector1}
                className={`cube fixed w-[300px] bottom-[calc(100vh-40%)] right-[4px] pointer-events-none`}
            />
            <img src={rectangle} className='rectangle absolute left-[-12%] w-[62%] pointer-events-none' />

            <div className='mt-6 mx-12 relative'>
                <Row className='page-header mb-5' gutter={48} align='middle'>
                    <Col span={12}>
                        <a
                            className='no-underline decoration-inherit'
                            href={window?.location?.origin + '/onboarding/kc/onboarding-steps'} //SKipping COD page
                        >
                            <div className='go-back flex items-center cursor-pointer text-[#002547]'>
                                <ArrowLeftIcon />
                                <h3 className='font-normal text-base inter ml-1'>Setup Steps</h3>
                            </div>
                        </a>

                        <div className='flex flex-col justify-start items-start gap-1'>
                            <div className='text-stone-900 text-3xl font-semibold justify-start items-center gap-3 flex'>
                                <div className=' '>
                                    Pricing Plan{`${location?.state?.onboardingType === 'CODFirst' ? '' : 's'}`}
                                </div>
                                <img src={InfoIcon} alt='Info Icon' />
                            </div>
                            {location?.state?.onboardingType !== 'CODFirst' && (
                                <div className='text-stone-900 text-base font-normal'>We have two options for you.</div>
                            )}
                        </div>
                    </Col>

                    {showAlert && (
                        <Col id='ent-plan-message' span={12} className='alert'>
                            <Alert
                                message={
                                    <span className='text-[#084426] text-sm font-semibold'>
                                        Thank you for choosing custom pricing
                                    </span>
                                }
                                description={
                                    <span className='text-[#084426] text-[13px] font-normal'>
                                        Our team is excited to speak with you and will reach out to you in 2 days.
                                    </span>
                                }
                                type='success'
                                showIcon
                            />
                        </Col>
                    )}
                </Row>

                <Plans setShowAlert={setShowAlert} onboardingType={location?.state} />

                <div className='footer w-full mt-16'>
                    <div className='text-center text-[#000000A6] text-opacity-60 text-sm leading-[22px] font-normal'>
                        <span>For assistance, feel free to reach out to us via email at </span>
                        <span className='text-[#028DF5] text-sm font-normal underline leading-snug'>
                            <a href={`mailto:merchantcare@gokwik.co`}>merchantcare@gokwik.co</a>
                        </span>
                        <span>
                            . <br />
                            Alternatively, you can engage in a live chat using the convenient widget located on the
                            right-hand side of your screen
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PricingDetails
