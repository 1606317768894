import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import LoginPage from '@pages/login/login'
import SignupPage from '@pages/signup/signup'
import VerifyOtp from '@pages/verifyOtp'
import ForgotPassword from '@pages/forgotPassword'
import ChangePassword from './changePassword'
import Reports from '@pages/reports'
import Customers from '@pages/customers'
import TimeLine from '@pages/timeline'
import RolesAndPermissions from '@pages/rolesAndPermissions'
import CustomerDetails from '@pages/customers/customer-details'
import ScheduleNotifications from '@pages/schedule-notifications'
import { useSelector } from 'react-redux'
import {
    getMerchantDetails,
    getMode,
    getUserData,
    isMerchantSelector,
    getMerchantConfigs,
    getNotificationData,
    getMerchantPlatform,
} from '@store/user/selectors'
import { getUserDetails } from '@store/user/selectors'
import VerifyMerchant from '@pages/signup/verifyMerchant'
import PricingDetails from '@pages/onboarding/pricing-details'
import Users from '@pages/users'
import PasswordSignup from '@pages/passwordSignup/passwordSignup'
import KYCDetailsPage from '@pages/onboarding/kyc'
import FeatureTour from '@pages/onboarding/feature-tour'
import SetupSteps from '@pages/onboarding/steps'
import Congratulations from '@pages/onboarding/congratulations'
import WelcomeBack from '@pages/onboarding/existing-merchant'
import KwikpassOnboarding from '@pages/onboarding/kwikpass-onboarding'

import { Button, WarningOutlined } from 'gokwik-ui-kit'
import Account from '@pages/merchantDetails'
import { useAppDispatch } from '@library/utilities/hooks'
import GlobalSettings from '@pages/global-settings'
import UsersAd from '@pages/users-ad'

const getBgColorforShopifyOnboardingPages = () => {
    if (window.location.pathname?.includes('get-started')) {
        return '!bg-white'
    } else if (window.location.pathname?.includes('pricing-details')) {
        return '!bg-blue-50'
    }
}

import InvitationForm from '@pages/invitation'
import { getCookie, makeAPICall, validatePermission } from '@gokwik/utilities'
import { MerchantConfigKeys, PermissionValues } from '@library/utilities/constants/constants'
import { useEffect, useState } from 'react'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import ScheduleBanner from '@library/components/common/scheduleBanner'
import { fetchNotification, getDestination } from '@store/user'
import TestBanner from '@library/components/common/testModeBanner'
import { updateLiveChat } from '@library/utilities/liveChat'
import CODFirstSteps from '@pages/onboarding/steps/COD-first-steps'
import RateCapture from '@pages/rateCapture'
import Dashboard from '@pages/dashboard'
import Merchants from '@pages/merchants'
import AbTesting from '@pages/abTesting'
import GA4DataFetch from '@pages/integrations/google-analytics'
import OAuthCallback from '@pages/integrations/google-analytics/OAuthCallback'
import GetStarted from '@pages/onboarding/new-pages/upsellFlow'
import CodCompletionPrompt from '@pages/onboarding/new-pages/codCompletionPage'
import ContactSalesConfirmation from '@pages/onboarding/new-pages/contactSalesConfirmation'
// import GetStarted from '@pages/onboarding/new-pages/getStarted'
import OnboardingSteps from '@pages/onboarding/new-pages/onboardingSteps'
import { stepConfiguration } from '@pages/onboarding/stepsConfig'
import OnboardingBanner from '@pages/onboarding/new-pages/onboardingBanner'
import ReactDOM from 'react-dom'
import { NotFound } from '@pages/notFound'
import { isMerchantRoute } from '@library/utilities/helpers/helper'
import UpgradePlan from '@pages/onboarding/new-pages/upgradePlan'

const renderInAppLayout = (component) => {
    return (
        <div
            className={`app-container h-screen ${
                window.location.pathname?.includes('onboarding')
                    ? `${getBgColorforShopifyOnboardingPages()}`
                    : 'px-5 pt-20'
            } overflow-y-auto`}
            style={{
                maxWidth: `calc(100vw - ${document.getElementById('side-nav')?.getBoundingClientRect().width}px)`,
                width: `calc(100vw - ${document.getElementById('side-nav')?.getBoundingClientRect().width}px)`,
            }}
        >
            {component}
        </div>
    )
}

const AppRoutes = ({ globalStoreChanged }: { globalStoreChanged: boolean }) => {
    const isMerchantUser = useSelector(isMerchantSelector)
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)
    const merchantConfigs = useSelector(getMerchantConfigs)
    const mode = useSelector(getMode)
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const userData = useSelector(getUserData)
    const notificationData = useSelector(getNotificationData)
    const platform = useSelector(getMerchantPlatform)

    useEffect(() => {
        if (location.pathname === '/' && userDetails.role && isMerchantUser) {
            if (
                (merchantDetails?.onboarding_status?.kwik_checkout_app &&
                    merchantDetails?.onboarding_status?.kwik_checkout_app !== 'setup_completed') ||
                (merchantDetails?.onboarding_status?.kwikpass_app &&
                    merchantDetails?.onboarding_status?.kwikpass_app !== 'setup_completed')
            ) {
                const destination = getDestination(
                    merchantDetails?.onboarding_status?.kwik_checkout_app,
                    merchantDetails?.onboarding_status?.kwikpass_app,
                    merchantDetails?.latest_app_onboarding_in_progress,
                )

                navigate(destination)
            } else {
                merchantConfigs.selected_products?.kwik_pass
                    ? navigate('/kwikpass/configuration')
                    : navigate('/checkout/orders')
            }
        } else if (location.pathname === '/' && !isMerchantUser) {
            navigate('/checkout/orders')
        }
        if (
            ![
                '/signup',
                '/login',
                '/forgot-password',
                '/change-password',
                '/verify-otp',
                '/verify-merchant',
                '/set-password',
                '/existing-merchant',
                '/invitation/',
            ]?.includes(location.pathname)
        ) {
            !!Object.keys(userDetails || {}).length && dispatch(fetchNotification())
        }
    }, [location.pathname, userDetails])

    const replaceThemeId = (url, newThemeId) => {
        return url?.replace('${theme_id}', `${newThemeId}`)
    }

    const getShopifyThemes = async () => {
        try {
            const response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getShopifyThemes + merchantDetails?.m_id,
            })
            if (response?.data?.status_code === 200) {
                if (response?.data?.data?.themes && response?.data?.data?.themes?.length > 0) {
                    let temp = response?.data?.data?.themes.filter((itm) => itm.role == 'main')
                    let mainUrl = replaceThemeId(merchantDetails?.redirect_urls?.shopify_theme_set_url, temp[0]?.id)
                    window.open(mainUrl, '_blank')
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        const appContainer = document.querySelector<HTMLElement>('.app-container')
        const sideNav = document.querySelector<HTMLElement>('#side-nav')
        if (notificationData?.message && isMerchantUser) {
            document.body.style.marginTop = '25px'
            document.body.style.position = 'fixed'

            if (appContainer && sideNav) {
                appContainer.style.height = `calc(100vh - 25px)`
                sideNav.style.height = `calc(100vh - 25px)`
            }
        } else {
            document.body.style.marginTop = '0px'
            document.body.style.position = ''
            if (appContainer && sideNav) {
                appContainer.style.height = `100vh`
                sideNav.style.height = `100vh`
            }
        }
    }, [notificationData, location.pathname])

    const verifyRoutest = () => {
        const authroutes = [
            '/signup',
            '/login',
            '/forgot-password',
            '/change-password',
            '/verify-otp',
            '/verify-merchant',
            '/set-password',
            '/existing-merchant',
            '/invitation/',
        ]
        if (authroutes.includes(location.pathname)) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('token') || getCookie('token')
        if (token && verifyRoutest()) {
            navigate('/checkout/orders')
        }
    }, [location.pathname])

    const removeChatOnUserLogOut = () => {
        const fcFrameId = 'fc_frame'
        const freshworksContainerId = 'freshworks-container'
        const fcFrame = document.getElementById(fcFrameId)
        const freshworksContainer = document.getElementById(freshworksContainerId)
        if (freshworksContainer) {
            freshworksContainer.style.display = 'none'
        }
        if (fcFrame) {
            fcFrame.style.display = 'none'
        }
    }

    useEffect(() => {
        if (Object?.keys(userDetails)?.length) {
            const loadChat = async () => {
                let response = await makeAPICall({
                    method: 'get',
                    url: process.env.REACT_APP_BASE_URL + APIEndPoints.getTrackersData,
                })
                updateLiveChat(merchantConfigs, merchantDetails, userDetails, response.data.data)
            }

            if (
                ![
                    '/signup',
                    '/login',
                    '/forgot-password',
                    '/change-password',
                    '/verify-otp',
                    '/verify-merchant',
                    '/set-password',
                    '/existing-merchant',
                    '/invitation/',
                    '/onboarding/kp/onboarding-steps',
                    '/kwikpass',
                    '/kwikpass/configuration',
                ]?.includes(location.pathname)
            ) {
                loadChat()
            }
        } else {
            removeChatOnUserLogOut()
        }
    }, [merchantConfigs?.supported_checkout_configs?.kwik_checkout, userDetails, window.location.href])

    const BannerContainer = ({ children }) => {
        return ReactDOM.createPortal(<div className='fixed top-0 left-0 w-full z-10'>{children}</div>, document.body)
    }
    const featureFlag = (merchantDetails) => {
        if (process.env.NODE_ENV === 'production') {
            if (merchantDetails?.m_id === '12wyqc2guqmkrw6406j') {
                return true
            } else {
                return false
            }
        }

        return true
    }

    const IsScheduleNtoficationActive = () =>
        !!Object.keys(userDetails || {}).length && notificationData != undefined && notificationData?.message

    const getCheckoutPath = () => {
        return merchantDetails?.onboarding_status?.kwik_checkout_app?.includes('kyc') &&
            merchantConfigs?.supported_checkout_configs?.[MerchantConfigKeys.checkoutOne]
            ? '/checkout'
            : '/shopify-app'
    }

    return (
        <>
            {mode === 'test' && !verifyRoutest() && <TestBanner />}

            {!verifyRoutest() && (
                <BannerContainer>
                    {isMerchantUser &&
                        !!Object.keys(userDetails || {}).length &&
                        notificationData != undefined &&
                        notificationData?.message && <ScheduleBanner message={notificationData?.message} />}
                    {!!Object.keys(userDetails || {}).length &&
                        !location?.pathname?.includes('/onboarding') &&
                        location?.pathname?.includes(getCheckoutPath()) && (
                            <OnboardingBanner
                                userData={userData}
                                key={location.pathname}
                                IsScheduleNtoficationActive={IsScheduleNtoficationActive}
                            />
                        )}
                </BannerContainer>
            )}

            {!userData?.appEmbedData?.enable_on_main_theme &&
                userData?.appEmbedData?.enable_on_selected_theme &&
                !!Object.keys(userDetails || {}).length &&
                merchantDetails.onboarding_status?.kwik_checkout_app !== 'setup_completed' && (
                    <div className='bg-orange-400  p-2 w-full flex justify-between'>
                        <div className='flex'>
                            <span>
                                <WarningOutlined />
                            </span>
                            <p className='text-sm mx-2'>
                                You seem to have Kwik Checkout Button on a non-live theme. To activate Kwik Checkout
                                Button, please ensure that you make that theme live or install buttons on live theme
                                through these steps
                            </p>
                        </div>
                        {
                            <Button
                                variant='primary'
                                onClick={() => {
                                    getShopifyThemes()
                                }}
                            >
                                Activate Theme
                            </Button>
                        }
                    </div>
                )}

            <Routes key={`${mode}-${globalStoreChanged}`}>
                <Route path='/login' element={<LoginPage />} />
                <Route path='/signup' element={<SignupPage />} />
                <Route path='/verify-otp' element={<VerifyOtp />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/change-password' element={<ChangePassword />} />
                <Route path='/verify-merchant' element={<VerifyMerchant />} />
                <Route path='/set-password' element={<PasswordSignup />} />
                <Route path='/existing-merchant' element={<WelcomeBack />} />
                <Route path='/invitation/:id' element={<InvitationForm />} />
                {!!Object.keys(userDetails || {}).length && (
                    <>
                        <Route
                            path='/dashboard'
                            element={
                                !isMerchantUser && validatePermission(PermissionValues.dashboard.view)
                                    ? renderInAppLayout(<Dashboard />)
                                    : renderInAppLayout(<NotFound hasPermission={false} />)
                            }
                        />

                        <Route
                            path='/general/reports'
                            element={
                                validatePermission(PermissionValues.reports.view)
                                    ? renderInAppLayout(<Reports />)
                                    : renderInAppLayout(<NotFound hasPermission={false} />)
                            }
                        />

                        <Route path='/general/teams' element={renderInAppLayout(<Users />)} />
                        <Route path='/general/account' element={renderInAppLayout(<Account />)} />
                        <Route path='/onboarding/kyc' element={renderInAppLayout(<KYCDetailsPage />)} />

                        {/* Routes only for Non-Merchant Users */}
                        {!isMerchantUser ? (
                            <>
                                <Route
                                    path='/general/schedule-notifications'
                                    element={renderInAppLayout(<ScheduleNotifications />)}
                                />
                                <Route path='/general/timeline' element={renderInAppLayout(<TimeLine />)} />
                                <Route
                                    path='/general/roles-and-permissions'
                                    element={renderInAppLayout(<RolesAndPermissions />)}
                                />
                                <Route
                                    path='/general/rateCapture'
                                    element={
                                        validatePermission(PermissionValues.rate_capture.view)
                                            ? renderInAppLayout(<RateCapture />)
                                            : renderInAppLayout(<NotFound hasPermission={false} />)
                                    }
                                />
                                <Route path='/general/merchants' element={renderInAppLayout(<Merchants />)} />
                                <Route path='/general/ab-testing' element={renderInAppLayout(<AbTesting />)} />
                                <Route path='/global-settings/*' element={renderInAppLayout(<GlobalSettings />)} />
                                <Route path='/access-control/users' element={renderInAppLayout(<UsersAd />)} />
                                <Route path='/access-control/customers' element={renderInAppLayout(<Customers />)} />
                                <Route
                                    path='/access-control/customers/:id'
                                    element={renderInAppLayout(<CustomerDetails />)}
                                />
                            </>
                        ) : (
                            /* Merchant-Specific Routes */
                            <>
                                <Route
                                    path='/onboarding/pricing-details'
                                    element={renderInAppLayout(<PricingDetails />)}
                                />
                                <Route path='/onboarding/upgrade-plan' element={renderInAppLayout(<UpgradePlan />)} />
                                <Route path='/onboarding/feature-tour' element={renderInAppLayout(<FeatureTour />)} />
                                <Route path='/admin/customers' element={renderInAppLayout(<Customers />)} />
                                <Route path='/admin/customers/:id' element={renderInAppLayout(<CustomerDetails />)} />
                                {/* <Route path='/onboarding/get-started' element={renderInAppLayout(<SetupSteps />)} /> */}
                                {merchantDetails.onboarding_status?.kwik_checkout_app !== 'setup_completed' && (
                                    <>
                                        <Route
                                            path='/onboarding/get-started'
                                            element={renderInAppLayout(<GetStarted />)}
                                        />
                                        <Route
                                            path='/onboarding/kc/onboarding-steps'
                                            element={renderInAppLayout(<OnboardingSteps />)}
                                        />
                                        <Route
                                            path='/onboarding/kyc-prompt'
                                            element={renderInAppLayout(<CodCompletionPrompt />)}
                                        />
                                        <Route
                                            path='/onboarding/contactsales'
                                            element={renderInAppLayout(<ContactSalesConfirmation />)}
                                        />
                                    </>
                                )}

                                <Route
                                    path='/onboarding/congratulations'
                                    element={renderInAppLayout(<Congratulations />)}
                                />

                                <Route
                                    path='/onboarding/kp/onboarding-steps'
                                    element={renderInAppLayout(<KwikpassOnboarding />)}
                                />

                                <Route
                                    path='/data-integration'
                                    element={
                                        featureFlag(merchantDetails)
                                            ? renderInAppLayout(<GA4DataFetch />)
                                            : renderInAppLayout(<NotFound hasPermission={false} />)
                                    }
                                />
                                <Route path='/oauth-redirect' element={renderInAppLayout(<OAuthCallback />)} />
                            </>
                        )}
                    </>
                )}

                {/* Additional Global Routes */}
                <Route path='/checkout/*' element={<></>} />
                <Route path='/checkout-platform/*' element={<></>} />
                <Route path='/payment/*' element={<></>} />
                <Route path='/kwikpass/*' element={<></>} />
                <Route path='/settings/*' element={<></>} />
                <Route path='/rto/*' element={<></>} />
                <Route path='/kwikship/*' element={<></>} />
                <Route path='/ellora/*' element={<></>} />
                <Route path='/shopify-app/*' element={<></>} />

                {/* Fallback to NotFound if no routes match */}
                <Route path='/*' element={renderInAppLayout(<NotFound hasPermission={true} />)} />
            </Routes>
        </>
    )
}

export default AppRoutes
