import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { Button, Col, Row, Table, Tag, Modal, message } from 'gokwik-ui-kit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    SolutionOutlined,
    UserOutlined,
    LinkOutlined,
    PhoneOutlined,
    MailOutlined,
    LaptopOutlined,
} from '@ant-design/icons'
import { customerOrdersDetailsColumns } from './data'

const CustomerDetails = () => {
    const { id } = useParams()
    const [customerDetailsData, setCustomerDetailsData] = useState<any>({})
    const [orderTablePage, setOrderTablePage] = useState<any>({
        page: 1,
        pageSize: 25,
    })
    const [modalOpen, setModalOpen] = useState<any>(false)

    const fetchCustomerDetails = useCallback(async () => {
        const response = await makeAPICall({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.getCustomerDetails}/${id}`,
        })

        if (response?.data?.status_code === 200 && Object.keys(response?.data?.data?.customer).length) {
            setCustomerDetailsData(response.data.data)
        } else {
            setCustomerDetailsData({})
        }
    }, [id])

    useEffect(() => {
        fetchCustomerDetails()
    }, [id])
    const customerInformation = useMemo(
        () => [
            { icon: SolutionOutlined, value: customerDetailsData?.customer?.id, label: 'ID' },
            {
                icon: UserOutlined,
                value: customerDetailsData?.customer?.firstname,
                label: 'Name',
            },
            { icon: PhoneOutlined, value: customerDetailsData?.customer?.phone_mask, label: 'Phone' },
            { icon: MailOutlined, value: customerDetailsData?.customer?.email, label: 'Email Address' },
            { icon: LinkOutlined, value: customerDetailsData?.customer?.ip, label: 'IP Address' },
            { icon: LaptopOutlined, value: customerDetailsData?.customer?.user_agent, label: 'User Agent' },
        ],
        [customerDetailsData],
    )

    const shippingInformation = useMemo(
        () => ({
            name: customerDetailsData?.customer?.first_name,
            phone: customerDetailsData?.customer?.phone,
            address: ` ${customerDetailsData?.customer?.city || ''}`,
            state: `${customerDetailsData?.customer?.state} - ${customerDetailsData?.customer?.pincode || ''}`,
        }),
        [customerDetailsData],
    )

    useEffect(() => {
        updateBreadcrumbs((prev) =>
            prev[prev?.length - 1]?.text?.includes(String(id))
                ? prev
                : [
                      prev[0],

                      {
                          key: 'customers',
                          href: '/general/customers',
                          text: 'Customers',
                      },
                      {
                          key: 'customer-details',
                          href: `/general/customers/${id}`,
                          text: String(id),
                      },
                  ],
        )
    }, [])

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        // setPage(current)
        // setPageSize(pageSize)
        setOrderTablePage((prev) => ({ ...prev, page: current, pageSize }))
    }

    async function customerBlock(customerCurrentStatus, customerId) {
        if (!customerId) return

        const params = { customerId: customerId, is_blocked: !customerCurrentStatus }

        try {
            // let response = dummyOrders?.data;
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + '/api/customer/block',
                params,
            })

            message.success(response?.response?.data?.message)

            console.log(response, 'test')
        } catch (err) {
            console.warn(err)
        }
    }

    return (
        <>
            <Modal
                title={
                    <p>
                        Are your sure, you want to{' '}
                        {customerDetailsData?.customer?.is_customer_blocked ? (
                            <span className='text-green-500'>unblock</span>
                        ) : (
                            <span className='text-red-500'>block</span>
                        )}{' '}
                        this customer ?
                    </p>
                }
                open={modalOpen ? true : false}
                onOk={() => {
                    customerBlock(customerDetailsData?.customer?.is_customer_blocked, customerDetailsData?.customer?.id)
                    setModalOpen(false)
                }}
                onCancel={() => {
                    setModalOpen(false)
                }}
                centered
                width={'450px'}
                footer={() => (
                    <Row className='flex justify-end gap-2'>
                        <Button onClick={() => setModalOpen(null)} variant='default'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                customerBlock(
                                    customerDetailsData?.customer?.is_customer_blocked,
                                    customerDetailsData?.customer?.id,
                                )
                                setModalOpen(null)
                            }}
                            variant='primary'
                        >
                            Confirm
                        </Button>
                    </Row>
                )}
            >
                <h3 className='mt-1'>Customer #{customerDetailsData?.customer?.id}</h3>
            </Modal>
            <div className='customer-details-page w-full inter'>
                <Row className='p-2 rounded bg-neutrals-15' align={'middle'} justify={'space-between'}>
                    <Col sm={12} className='flex items-center gap-x-2'>
                        <span className='text-black text-base inter font-semibold m-0 tracking-wide'>
                            Customer #{customerDetailsData?.customer?.id}
                        </span>
                        <Tag
                            variant={customerDetailsData?.customer?.is_customer_blocked ? 'error' : 'success'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            {customerDetailsData?.customer?.is_customer_blocked ? 'Blocked' : 'Unblocked'}
                        </Tag>
                    </Col>
                    <Col className='flex justify-end' sm={2}>
                        <Button
                            variant={customerDetailsData?.customer?.is_customer_blocked ? 'primary' : 'default'}
                            onClick={() => setModalOpen(true)}
                        >
                            {' '}
                            {customerDetailsData?.customer?.is_customer_blocked ? 'Unblock' : 'Block'}
                        </Button>
                    </Col>
                </Row>

                <Row className='mt-5 w-full bg-neutrals-15' justify={'space-between'}>
                    <Col className='bg-neutrals-15 p-2' sm={10}>
                        <Row>
                            <span className='text-black text-base inter font-semibold m-0'>Details</span>
                        </Row>

                        <Row className='flex flex-col gap-3 justify-start align-top mt-5 max-h-[120px] w-[65%]'>
                            {customerInformation.map((itm) => (
                                <div
                                    className='flex items-center justify-between w-full text-sm inter font-normal'
                                    key={itm.label}
                                >
                                    <div className='text-gray-600'>
                                        <itm.icon className='mr-2' width={20} height={20} />
                                        <span>{itm.label}</span>
                                    </div>
                                    <span className='text-black font-medium max-w-[50%] truncate'>
                                        {itm.value ? itm.value : ''}
                                    </span>{' '}
                                </div>
                            ))}
                        </Row>
                    </Col>
                    <Col className='bg-neutrals-15 p-2' sm={10}>
                        <Row>
                            <span className='text-black text-base inter font-semibold m-0'>Address</span>
                        </Row>

                        <Row className='flex flex-col gap-1 justify-start align-top flex-wrap mt-5 max-h-[176px] w-[100%]'>
                            <div className='flex items-center justify-between w-full text-sm inter font-normal'>
                                <span className='text-black font-medium'>
                                    {shippingInformation.address ? shippingInformation.address : ''}
                                </span>{' '}
                            </div>

                            <div className='flex items-center justify-between w-full text-sm inter font-normal'>
                                <span className='text-black font-medium'>
                                    {shippingInformation.state ? shippingInformation.state : ''}
                                </span>{' '}
                            </div>
                        </Row>
                    </Col>
                </Row>

                <Row className='bg-white mt-5 rounded'>
                    <span className='text-black text-base inter font-semibold m-2 p-15'>Orders</span>
                    <Col sm={24}>
                        <Table
                            scroll={{ x: 800, y: 150 }}
                            columns={customerOrdersDetailsColumns}
                            pagination={{
                                current: orderTablePage.page, // Current page number
                                pageSize: orderTablePage.pageSize, // Number of items to display per page
                                total: customerDetailsData?.length, // Total number of items in the data array
                                showSizeChanger: false,
                                onChange: handlePaginationChange,
                                position: ['topLeft', 'bottomLeft'],
                                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                            }}
                            dataSource={customerDetailsData?.orders || []}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default CustomerDetails
