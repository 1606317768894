// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* :global(.ant-steps-item-icon) {
    background-color: #0bc677;
} */
.customStepsClass .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #0bc677 !important;
}

.customStepsClass .ant-steps-item-active .ant-steps-item-icon {
    background-color: rgb(21, 130, 255) !important;
}

:where(.css-dev-only-do-not-override-jdbcns).ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #000;
}

.customStepsClass .ant-steps-item-finish .ant-steps-item-icon svg path {
    color: #fff !important;
    fill: #fff;
}

:where(.css-dev-only-do-not-override-jdbcns).ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #000000 !important;
}

:where(.css-dev-only-do-not-override-1s7905z).ant-form-item
    .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
}
.accept_terms_and_conditions
    :where(.css-dev-only-do-not-override-1s7905z).ant-form-item-with-help
    .ant-form-item-explain {
    margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/onboarding/kyc/kyc-forms/kyc.css"],"names":[],"mappings":"AAAA;;GAEG;AACH;IACI,oCAAoC;AACxC;;AAEA;IACI,8CAA8C;AAClD;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,UAAU;AACd;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;;;IAGI,aAAa;AACjB;AACA;;;IAGI,iBAAiB;AACrB","sourcesContent":["/* :global(.ant-steps-item-icon) {\n    background-color: #0bc677;\n} */\n.customStepsClass .ant-steps-item-finish .ant-steps-item-icon {\n    background-color: #0bc677 !important;\n}\n\n.customStepsClass .ant-steps-item-active .ant-steps-item-icon {\n    background-color: rgb(21, 130, 255) !important;\n}\n\n:where(.css-dev-only-do-not-override-jdbcns).ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {\n    color: #000;\n}\n\n.customStepsClass .ant-steps-item-finish .ant-steps-item-icon svg path {\n    color: #fff !important;\n    fill: #fff;\n}\n\n:where(.css-dev-only-do-not-override-jdbcns).ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {\n    color: #000000 !important;\n}\n\n:where(.css-dev-only-do-not-override-1s7905z).ant-form-item\n    .ant-form-item-label\n    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {\n    display: none;\n}\n.accept_terms_and_conditions\n    :where(.css-dev-only-do-not-override-1s7905z).ant-form-item-with-help\n    .ant-form-item-explain {\n    margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
