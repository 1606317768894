import React from 'react'

export default function Files() {
    return (
        <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='0.5' y='0.5' width='43' height='43' rx='15.5' fill='white' fill-opacity='0.24' />
            <rect x='0.5' y='0.5' width='43' height='43' rx='15.5' stroke='url(#paint0_linear_6250_24492)' />
            <g clip-path='url(#clip0_6250_24492)'>
                <path
                    d='M15 31V15C15 14.4696 15.2107 13.9609 15.5858 13.5858C15.9609 13.2107 16.4696 13 17 13H27C27.5304 13 28.0391 13.2107 28.4142 13.5858C28.7893 13.9609 29 14.4696 29 15V31L26 29L24 31L22 29L20 31L18 29L15 31Z'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M25 17H19H20C20.7956 17 21.5587 17.3161 22.1213 17.8787C22.6839 18.4413 23 19.2044 23 20C23 20.7956 22.6839 21.5587 22.1213 22.1213C21.5587 22.6839 20.7956 23 20 23H19L22 26'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M19 20H25'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
            </g>
            <defs>
                <linearGradient
                    id='paint0_linear_6250_24492'
                    x1='0.848501'
                    y1='2.2083'
                    x2='41.5344'
                    y2='34.45'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#91D0FF' stop-opacity='0.12' />
                    <stop offset='1' stop-color='#0094D5' stop-opacity='0.12' />
                </linearGradient>
                <clipPath id='clip0_6250_24492'>
                    <rect width='24' height='24' fill='white' transform='translate(10 10)' />
                </clipPath>
            </defs>
        </svg>
    )
}
