import { Button, Col, Collapse, DatePicker, Divider, DownOutlined, Form, Input, InputNumber, Row } from 'gokwik-ui-kit'
import { businessDetailsFormConfig } from './config'
import dayjs from 'dayjs'
import { getUserData } from '@store/user/selectors'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { businessAddressFormConfig } from '../business-address/config'
import { CollapsePanelStatusChips, getFormStatusConfigs, handleFieldChange } from '@pages/onboarding/kyc/utils'
import { stepConfiguration } from '@pages/onboarding/stepsConfig'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

type Props = {}

const BusinessDetails = ({
    kycData,
    onFinish,
    setCurrentStep,
    currentStep,
    isPanelClickable,
    undeterminedKycErrorState,
}) => {
    const [form] = Form.useForm()
    const userData = useSelector(getUserData)
    const [errors, setError] = useState([])
    const [business_details_fields, set_business_details_fields] = useState(businessDetailsFormConfig?.items)
    const current_onboarding_status = userData?.merchantDetails?.onboarding_status?.gokwik_cards_app

    useEffect(() => {
        const getStatus = getFormStatusConfigs(userData, business_details_fields, 'business_details')
        setError(getStatus?.generateErrorChips)
        set_business_details_fields(getStatus?.updateConfigWithErrors)
    }, [userData])

    const isFieldEnabled = (fieldName) => {
        if (current_onboarding_status === 'kyc_verification_failed') {
            if (Object.keys(userData?.kycData?.kyc_failure_reasons)?.includes(fieldName) || undeterminedKycErrorState) {
                return false //dont-disable-the-field
            } else {
                return true //yes-disable-the-field
            }
        } else if (
            current_onboarding_status === 'kyc_verification_pending' ||
            current_onboarding_status === 'kyc_completed'
        ) {
            return true //yes-disable-the-field
        }
    }

    const items = [
        {
            key: '1',
            label: (
                <>
                    <div className='flex flex-col gap-1'>
                        <div className='text-[#004B8D] text-xs font-semibold'>Step 4</div>

                        <div className='flex gap-4 items-center'>
                            <div className={`text-xl font-semibold ${errors?.length && 'text-red-700'}`}>
                                Business Details
                            </div>{' '}
                            <CollapsePanelStatusChips
                                errors={errors}
                                stepConfiguration={stepConfiguration}
                                current_onboarding_status={
                                    userData?.merchantDetails?.onboarding_status?.gokwik_cards_app
                                }
                                sectionName={'business_details'}
                            />
                        </div>
                    </div>
                </>
            ),
            children: (
                <Form
                    layout='vertical'
                    form={form}
                    initialValues={kycData || {}}
                    scrollToFirstError={{ behavior: 'auto', block: 'center' }}
                    onFinish={(v) => {
                        logEvent(
                            'business_details_save_continue_clicked',
                            'click',
                            'kyc',
                            userData?.userDetails?.email,
                            userData?.merchantDetails?.m_id,
                        )
                        onFinish(v, 3)
                    }}
                    onFieldsChange={(changedField, allFields) =>
                        handleFieldChange(changedField, business_details_fields, set_business_details_fields)
                    }
                >
                    <style>
                        {`
                            .custom-label-styles label {
                                width: 100%
                            }
                        `}
                    </style>
                    <Row gutter={16}>
                        {business_details_fields?.map((item, index) => (
                            <Col span={item?.span} key={index} className={'custom-label-styles'}>
                                <Form.Item
                                    label={item.label}
                                    name={item.name}
                                    rules={item.rules}
                                    {...((item as any)?.validateStatus && {
                                        validateStatus: (item as any)?.validateStatus,
                                    })}
                                    {...((item as any)?.help && {
                                        help: (item as any)?.help,
                                    })}
                                >
                                    {item.type === 'input' && (
                                        <Input
                                            placeholder={item.placeholder}
                                            disabled={isFieldEnabled(item?.name)}
                                            className={`${item?.class} ${
                                                ['gstin', 'aus_pan', 'business_pan'].includes(item?.name) && 'uppercase'
                                            }`}
                                            {...(['gstin', 'aus_pan', 'business_pan'].includes(item?.name) && {
                                                onChange: (e) => {
                                                    const value = e.target.value.toUpperCase() // Convert input to uppercase
                                                    form.setFieldValue(item.name, value) // Update form value
                                                },
                                            })}
                                        />
                                    )}
                                    {item.type === 'inputNumber' && (
                                        <InputNumber
                                            placeholder={item.placeholder}
                                            disabled={isFieldEnabled(item?.name)}
                                        />
                                    )}
                                    {item.type === 'date' && (
                                        <DatePicker
                                            disabled={isFieldEnabled(item?.name)}
                                            onChange={(e) => {
                                                form.setFieldValue(item.name, dayjs(e))
                                            }}
                                            placeholder={item.placeholder}
                                            className={item?.class}
                                            disabledDate={(current) => {
                                                // Can not select days after today
                                                return current > dayjs().endOf('day')
                                            }}
                                            allowClear={false}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>

                    <Divider className='my-4' />
                    <Form.Item className='flex justify-end mb-0'>
                        <Button
                            variant='primary'
                            htmlType='submit'
                            disabled={
                                (errors?.length === 0 &&
                                    current_onboarding_status === 'kyc_verification_failed' &&
                                    !userData?.kycData?.kyc_rejection_reason) ||
                                userData?.merchantDetails?.onboarding_status?.gokwik_cards_app ===
                                    'kyc_verification_pending' ||
                                current_onboarding_status === 'kyc_completed'
                            }
                        >
                            Save & Continue
                        </Button>
                    </Form.Item>
                </Form>
            ),
        },
    ]

    const onChange = (e) => {
        setCurrentStep(3)
    }

    return (
        <div className=''>
            <Collapse
                collapsible={isPanelClickable(3)}
                activeKey={currentStep === 3 && ['1']}
                className='bg-white'
                onChange={onChange}
                items={items}
                accordion
                expandIconPosition='right'
                expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
            />
        </div>
    )
}

export default BusinessDetails
