import React, { useState } from 'react';
import { Card, Button, Space, DownOutlined, Tooltip } from 'gokwik-ui-kit'
import { UpOutlined } from '@ant-design/icons';
import { CheckOutlined, EyeOutlined } from 'gokwik-ui-kit'
import OnlyCOD from '@library/images/icons/OnlyCOD.png';
import BasicPlanDrawer from './basicPlanDrawer';
import CodSuiteDetails from './codSuiteDetails';
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const BasicPlanCard = ({ updateMerchantOnboardingStatus }) => {
    const navigate = useNavigate();
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)
    const [showDetails, setShowDetails] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [codDetailsVisible, setCodDetailsVisible] = useState(false);

    const showDrawer = () => {
        logEvent(
            'preview_opened',
            'click',
            'get_started',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { plan: 'cod' }
        )
        setDrawerVisible(true);
    };

    const showCodDetails = () => {
        logEvent(
            'know_more_opened',
            'click',
            'get_started',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { plan: 'cod' }
        )
        setCodDetailsVisible(true);
    };
    
    const toggleDetails = () => {
        logEvent(
            showDetails ? 'pricing_closed' : 'pricing_opened',
            'click',
            'get_started',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { plan: 'cod' }
        )
        setShowDetails(!showDetails);
    };

    const getStartedClicked = (selectedPlans) => {
        logEvent(
            'get_started_clicked',
            'click',
            'get_started',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { plan: 'cod' }
        )
        updateMerchantOnboardingStatus("", selectedPlans)
        localStorage.setItem('showKycPromptFlag', 'false');
        navigate('/onboarding/kc/onboarding-steps', { state: { showKycPrompt: false } })
    }
    return (
        <Card
            bordered
            style={{ borderRadius: 10, marginTop: "2.7rem", height:'calc(100% - 70px)'}}
        >
            <div style={{ height: 'calc(100vh - 235px)', flexGrow: 1, overflowY: 'auto', display: 'flex', flexDirection: "column", justifyContent: "space-between"}}>
                <div>
                    <div  className='flex items-center gap-2 justify-between'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <p className="ant-typography" style={{ fontWeight: 'bold', color: '#1890ff', marginBottom: 0 }}>Basic Plan</p>
                                <p className="ant-typography" style={{ fontSize: '24px', fontWeight: 'bold', margin: 0 }}>COD Suite</p>
                                <p className="ant-typography">
                                    Optimize your cash-on-delivery process with seamless  
                                    <button style={{
                                        background: 'none', 
                                        border: 'none', 
                                        padding: 0,
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        paddingLeft: '2px'
                                    }} onClick={showCodDetails}> Know More</button>
                                </p>
                            </div>
                        </div>
                        <div onClick={showDrawer} style={{ cursor: 'pointer', display: 'inline-block', position: 'relative' }}>
                            <img src={OnlyCOD} alt="Kwikcheckout plus COD Preview" style={{ width: '13rem', height: '9rem'}} />
                            <Button 
                                icon={<EyeOutlined />} 
                                style={{ color: 'rgba(22, 119, 255, 1)', position: 'absolute', bottom: '0.7rem', right: '0.7rem', border: 'none', padding: "0.6rem 0.2" }}>Preview</Button>
                        </div>
                    </div>
            
                    <div style={{ marginTop: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <p style={{ fontWeight: 'bold' }}>
                                Pricing <span>(Free for 3 Months)</span>
                            </p>
                            <p style={{ cursor: 'pointer' }} onClick={toggleDetails} >
                                {showDetails ? "Hide Details" : "View All Details"}<span style={{ transition: 'transform 0.6s',  transform: 'rotate(180deg)' }}>{showDetails ? <UpOutlined /> : <DownOutlined />}</span>
                            </p>
                        </div>

                        <div
                            style={{
                                maxHeight: showDetails ? '500px' : '0',
                                overflow: 'hidden',
                                transition: 'max-height 0.6s ease',
                                marginTop: showDetails ? '10px' : '0',
                            }}
                        >
                            {showDetails && (
                                <div style={{ background: "rgba(234, 236, 240, 1)", borderRadius: "10px",  fontSize: "12px" }} >
                                    <div style={{ display: "flex", justifyContent: "space-between", padding: '0.5rem', marginLeft: "5px", fontWeight: "600" }}>
                                        <div>COD Pricing</div>
                                        <Tooltip 
                                            title="The plan is free upto max 3 months and charges post 3 months depends on your shopify plan" 
                                            placement="bottomRight" 
                                            overlayInnerStyle={{ backgroundColor: "white", color: "black", width: 275, borderRadius: '0.5rem', padding: '0.5rem', boxShadow: '1px 1px 1px 1px rgb(102, 112, 133)'}}
                                        >
                                            <span style={{ color: "rgba(102, 112, 133, 1)", cursor: "pointer" }}>
                                                * What does this mean?
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <div style={{ background: "rgba(248, 250, 252, 1)", border: "1px solid rgba(234, 236, 240, 1)", borderRadius: '10px 10px 0px 0px', padding: '0.8rem'}}>
                                        <p style={{fontWeight: "600",  marginBottom: "2px" }}>First 3 months *<span style={{ background:'linear-gradient(289.8deg, rgba(145, 202, 255, 0.6) 0%, rgba(230, 244, 255, 0) 100%)', fontSize: "12px", color: "rgba(0, 110, 210, 1)", padding: '0.2rem 0.6rem', borderRadius: '5px'}} >FREE</span></p>
                                        <p>Full control over COD, and all COD orders are free</p>
                                    </div>
                                    <div style={{ background: "rgba(248, 250, 252, 1)", border: "1px solid rgba(234, 236, 240, 1)", padding: '0.8rem', borderRadius: '0px 0px 10px 10px'}}>
                                        <p style={{fontWeight: "600", marginBottom: "2px" }}>After 3 months *</p>
                                        <p>First 1000 COD orders are free per month. Beyond that, a 0.5% fee per COD order</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
            
                    <div style={{ marginTop: 20 }}>
                        <p className="ant-typography" style={{ fontWeight: 'bold' }}>COD Features <span style={{ background:'linear-gradient(289.8deg, rgba(145, 202, 255, 0.6) 0%, rgba(230, 244, 255, 0) 100%)', fontSize: "12px", color: "rgba(0, 110, 210, 1)", padding: '0.2rem 0.6rem', borderRadius: '5px'}} >FREE</span></p>
                        <ul className="bg-[#f6fefc] p-2.5 rounded list-none m-0">
                            <li className="flex items-start gap-2">
                                <CheckOutlined className="text-[#52c41a] mt-1 flex-shrink-0" />
                                <span className="block text-left">Enable COD Orders</span>
                            </li>
                            <li className="flex items-start gap-2">
                                <CheckOutlined className="text-[#52c41a] mt-1 flex-shrink-0" />
                                <span className="block text-left">COD Interventions like COD OTP for Extra Security</span>
                            </li>
                            <li className="flex items-start gap-2">
                                <CheckOutlined className="text-[#52c41a] mt-1 flex-shrink-0" />
                                <span className="block text-left">Show/Hide Payment Based on Cart, Customers, Products, Location, and More</span>
                            </li>
                            <li className="flex items-start gap-2">
                                <CheckOutlined className="text-[#52c41a] mt-1 flex-shrink-0" />
                                <span className="block text-left">Reduce RTO with risk-based COD interventions like CAPTCHA, OTP, and confirmation layers.</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Button 
                type="default" 
                block 
                onClick={() => getStartedClicked(['kwik_cod'])} 
                style={{ width: "97%" }}
                className="absolute w-[97] bottom-0 left-0 m-2 rounded-md"
            >
                Get Started
            </Button>
            <CodSuiteDetails codDetailsVisible={codDetailsVisible} setCodDetailsVisible={setCodDetailsVisible}/>
            <BasicPlanDrawer drawerVisible={drawerVisible} setDrawerVisible={setDrawerVisible}/>
        </Card>
    )
}
  
export default BasicPlanCard;
