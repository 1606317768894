export default function KwikCheckIcon() {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_828_8330)'>
                <path
                    d='M12 21L4 16.5V7.5L12 3L20 7.5V14'
                    stroke='white'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M12 12L20 7.5'
                    stroke='white'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path d='M12 12V21' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round' />
                <path
                    d='M12 12L4 7.5'
                    stroke='white'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M20 18L17.0858 20.9142L15.6288 19.4572'
                    stroke='white'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_828_8330'>
                    <rect width='24' height='24' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}






