import { Button, Col, Divider, Row, Space, message } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { planConfig } from './planConfig'
import { ArrowCircleDown } from '@library/images/common/arrowCircleDown'
import { getMerchantDetails, getUserData } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import DownloadDoc from '@library/images/common/DownloadDoc.svg'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    BNPLIcon,
    CODIcon,
    CardIcon,
    UPIIcon,
    WalletIcon,
    EMIIcon,
} from '@library/images/common/onboarding-images/pricingPageIcons'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

export default function Plans({ setShowAlert, navigateToStep }) {
    const merchant_details = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserData)

    const [activeKey, setActiveKey] = useState(['1'])
    const [disableButton, setDisableButton] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()

    const customPlanRates = () => {
        const { pricing_values } = merchant_details
        return pricing_values
    }

    const basicPlanRates = () => {
        const transformData = (data) => {
            return data.map((item) => {
                const key = Object.keys(item)[0]
                return {
                    name: key,
                    children: item[key].map((child) => ({
                        name: child,
                        rate: `${item.value}%`,
                    })),
                }
            })
        }
        const { onboarding_pricing_details } = userDetails?.kycData

        return transformData(onboarding_pricing_details)
    }

    const handleCollapse = () => {
        setActiveKey((prevActiveKey) => (prevActiveKey.includes('1') ? [] : ['1']))
    }

    const getChildren = (children) => {
        return (
            <>
                {children?.map((child) => {
                    return (
                        <div className='flex justify-between items-center ml-7 mb-2' key={child?.name}>
                            <div>{child?.name}</div>
                            <div>{child?.rate}</div>
                        </div>
                    )
                })}
            </>
        )
    }

    const getRateCardIcon = (itemName) => {
        if (itemName === 'COD') {
            return <CODIcon />
        } else if (itemName?.includes('Card')) {
            return <CardIcon />
        } else if (itemName === 'UPI') {
            return <UPIIcon />
        } else if (itemName === 'Wallet') {
            return <WalletIcon />
        } else if (itemName === 'BNPL') {
            return <BNPLIcon />
        } else if (itemName === 'EMI') {
            return <EMIIcon />
        }
    }

    const RateListComponent = () => {
        return (
            <>
                {basicPlanRates()?.map((item, index) => {
                    return (
                        <div key={index}>
                            {item?.children?.length ? (
                                <>
                                    <div
                                        className='flex justify-between items-start flex-col text-[#000000e0] text-sm px-4 py-3 gap-5'
                                        key={item?.name}
                                    >
                                        <div className='flex items-center gap-2'>
                                            {getRateCardIcon(item?.name)} {item?.name}
                                        </div>

                                        <div className='children-rates w-full'>{getChildren(item?.children)}</div>
                                    </div>
                                    <Divider className='m-0 p-0' />
                                </>
                            ) : (
                                <>
                                    <div
                                        className='flex justify-between items-center text-[#000000e0] text-sm px-4 py-3'
                                        key={item?.rate}
                                    >
                                        <div className='flex items-center gap-2'>
                                            {getRateCardIcon(item?.name)} {item?.name}
                                        </div>
                                        <div>{item?.rate}</div>
                                    </div>

                                    {index !== basicPlanRates().length - 1 && <Divider className='m-0 p-0' />}
                                </>
                            )}
                        </div>
                    )
                })}
            </>
        )
    }

    const createSubscription = async (plan) => {
        try {
            const endPointKey = plan === 'Basic' ? 'createBasicPlanForCODFirst' : 'createSubscription'
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints[endPointKey],
                ...(plan === 'Premium' && {
                    payload: {
                        merchant_id: merchant_details?.m_id,
                        plan_type: plan === 'Basic' ? 'Free' : 'Premium',
                        return_url:
                            process.env.NODE_ENV === 'development'
                                ? 'https://dev-mdashboard.dev.gokwik.in/onboarding/kp/onboarding-steps'
                                : // ? 'http://localhost:9000/onboarding/kp/onboarding-steps'
                                  'https://qa-mdashboard.dev.gokwik.in/onboarding/kp/onboarding-steps',
                        app_name: 'kwikpass_app',
                    },
                }),

                ...(plan === 'Basic' && {
                    params: {
                        app_name: 'kwikpass_app',
                    },
                }),
            })

            if (response?.data?.status_code === 200) {
                if (plan === 'Basic') {
                    logEvent(
                        'kp_plan_basic',
                        'click',
                        'pricing_page',
                        userDetails?.userDetails?.email,
                        merchant_details?.m_id,
                        merchant_details?.short_name,
                    )
                    navigateToStep(null, 1)
                    message.success('Basic plan selected successfully')
                } else {
                    //premium kwikpass plan
                    logEvent(
                        'kp_plan_premium',
                        'click',
                        'pricing_page',
                        userDetails?.userDetails?.email,
                        merchant_details?.m_id,
                        merchant_details?.short_name,
                    )
                    if (response?.data?.data?.confirmation_url) {
                        window.open(response?.data?.data?.confirmation_url, '_self')
                    }
                }
            } else {
                message.error(response?.response?.data?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const basicPlanFlow = async () => {
        createSubscription('Basic')
    }

    const premiumPlanFlow = async () => {
        createSubscription('Premium')
        // if (merchant_details?.onboarding_status?.kwik_checkout_app === 'pricing_details_captured') {
        //     createSubscription('Premium')
        // }
        // else {
        //     try {
        //         const response = await makeAPICall({
        //             method: 'post',
        //             url: process.env.REACT_APP_BASE_URL + APIEndPoints.createLead + merchant_details?.m_id, //this url is salesforce leads wala
        //         })

        //         if (response?.data?.status_code === 200) {
        //             setShowAlert(true)
        //             setDisableButton(true)
        //             document.getElementById('ent-plan-message').scrollIntoView({ behavior: 'smooth' })
        //         } else {
        //             message.error(response?.response?.data?.data)
        //         }
        //     } catch (error) {
        //         console.log(error)
        //     }
        // }
    }

    const onPlanSelect = (planName) => {
        if (planName?.includes('Basic')) {
            basicPlanFlow()
        } else if (planName?.includes('Premium')) {
            premiumPlanFlow()
        }
    }

    const getButtonStatus = (planName) => {
        let buttonState
        if (planName === 'Basic Plan') {
            buttonState =
                merchant_details?.onboarding_status?.kwik_checkout_app === 'pricing_details_confirmed' ||
                merchant_details?.onboarding_status?.kwik_checkout_app === 'pricing_approval_pending' ||
                (merchant_details?.onboarding_status?.kwik_checkout_app === 'pricing_details_captured' &&
                    merchant_details?.pricing_values?.length !== 0)
        } else {
            buttonState =
                disableButton ||
                merchant_details?.onboarding_status?.kwik_checkout_app === 'pricing_details_selected' ||
                merchant_details?.onboarding_status?.kwik_checkout_app === 'pricing_details_confirmed' ||
                merchant_details?.onboarding_status?.kwik_checkout_app === 'pricing_approval_pending'
        }
        return buttonState
    }

    return (
        <Row gutter={48}>
            {planConfig?.length &&
                planConfig?.map((plan, index) => {
                    return (
                        <Col
                            key={plan?.planName}
                            span={12}
                            // span={24}
                        >
                            <div
                                className={`plans h-full ${
                                    activeKey?.length === 0 && ''
                                } bg-white rounded-2xl shadow-md`}
                            >
                                <div className='content-section p-3 flex flex-col   mx-2 mt-6'>
                                    <div className='flex justify-between items-center'>
                                        <div className='card-header flex gap-3 items-center'>
                                            {/* {plan?.planIcon()} */}
                                            <div className=' text-[#667085] text-xl leading-[32px] font-semibold'>
                                                {plan?.planName}
                                            </div>
                                        </div>
                                        <div className='text-[#667085]'>Monthly</div>
                                    </div>

                                    <Row justify={'space-between'}>
                                        <Col span={index === 1 ? 12 : 24} flex={'auto'}>
                                            <span className='text-2xl font-semibold'>{`$${plan?.pricing}`}</span>
                                        </Col>
                                        {index === 1 && (
                                            <Col className='cursor-pointer' span={12} flex={'10px'}>
                                                <span
                                                    className={`flex items-center ${
                                                        activeKey.length === 0 && 'origin-center rotate-180'
                                                    }`}
                                                    onClick={handleCollapse}
                                                >
                                                    <ArrowCircleDown />
                                                </span>
                                            </Col>
                                        )}
                                    </Row>

                                    {plan?.planSticker()}

                                    {/* <div className={index === 1 ? 'block' : 'hidden'}>
                                        <div className='rate-header-name mt-4 flex justify-between'>
                                            <span className='text-zinc-600 text-base leading-8 font-light'>
                                                {plan?.planHeading}
                                            </span>

                                            {userDetails?.kycData?.length !== 0 && (
                                                <span
                                                    className={`flex items-center ${
                                                        activeKey.length === 0 && 'origin-center rotate-180'
                                                    }`}
                                                    onClick={handleCollapse}
                                                >
                                                    <ArrowCircleDown />
                                                </span>
                                            )}
                                        </div>

                                        {userDetails?.kycData?.length !== 0 && activeKey.length > 0 && (
                                            <div className={`plan-overiew text-[#000000A6] mb-3`}>
                                                <div className='rates border border-solid border-[#0000000F] rounded-lg mb-3 ransition-opacity duration-500 ease-in-out opacity-100'>
                                                    <RateListComponent />
                                                </div>
                                            </div>
                                        )}
                                    </div> */}

                                    <div className='feature-card rounded-md px-5 pt-6 pb-3 flex-grow flex flex-col justify-between'>
                                        {activeKey.length > 0 && (
                                            <Row wrap={true} gutter={[0, 16]} className='text-neutral-700 font-medium'>
                                                {/* <Col span={24}>
                                                <span className='text- text-xs ml-6'>Offered Features</span>
                                            </Col> */}

                                                <Col span={24}>
                                                    <Row gutter={[0, 16]}>
                                                        {plan?.planData?.map((item, index) => {
                                                            return (
                                                                <Col span={24} key={index}>
                                                                    <div className='li-header mb-2'>
                                                                        {item?.header()}
                                                                    </div>
                                                                    {item?.content?.length > 0 && (
                                                                        <div className='li-content text-neutral-500 text-xs font-normal ml-6'>
                                                                            <Row gutter={[16, 8]}>
                                                                                {item?.content?.map((item, i) => (
                                                                                    <Col key={i} span={12}>
                                                                                        <span>{item}</span>
                                                                                    </Col>
                                                                                ))}
                                                                            </Row>
                                                                        </div>
                                                                    )}
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        )}

                                        <Row
                                            gutter={16}
                                            className='action-items mt-4'
                                            justify={'center'}
                                            align={'bottom'}
                                        >
                                            {/* <Col span={9}>
                                                <div className='flex items-center gap-1'>
                                                    <img src={DownloadDoc} />
                                                    <a
                                                        className='text-[#004B8D] text-sm font-light'
                                                        href='https://s3.ap-south-1.amazonaws.com/devcdngokwik.co/shopify_documents/basic_vs_enterprise.pdf'
                                                        target='_blank'
                                                        rel='noopener noreferrer'
                                                    >
                                                        Explore all features.
                                                    </a>
                                                </div>
                                            </Col> */}
                                            <Col span={24}>
                                                <Button
                                                    className='w-full'
                                                    onClick={() => onPlanSelect(plan?.planName)}
                                                    variant='primary'
                                                    disabled={getButtonStatus(plan?.planName)}
                                                >
                                                    {merchant_details?.onboarding_status?.kwik_checkout_app ===
                                                    'pricing_details_captured'
                                                        ? 'Start Plan'
                                                        : plan?.ctaName}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )
                })}
        </Row>
    )
}
