import { Button, Col, Row, message } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { ArrowCircleDown } from '@library/images/common/arrowCircleDown'
import { getMerchantDetails, getUserData } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import Tick from '@library/images/common/Tick.svg'
import {
    BNPLIcon,
    CODIcon,
    CardIcon,
    UPIIcon,
    WalletIcon,
    EMIIcon,
} from '@library/images/common/onboarding-images/pricingPageIcons'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import CustomPlan from './CustomPlan'
import { getPriceDetails } from '@pages/onboarding/kwikpass-onboarding/steps/helper'

export default function Plans({ setShowAlert, navigateToStep, planButtonStatus, subscriptionsPlans }) {
    const merchant_details = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserData)
    const [customPlanInfo, setCustomPlanInfo] = useState()
    const [activeKey, setActiveKey] = useState(['1'])
    const [disableButton, setDisableButton] = useState(planButtonStatus)
    const [customAppModal, setCustomAppModal] = useState(false)

    useEffect(() => {
        setDisableButton(planButtonStatus)
    }, [planButtonStatus])

    const handleCollapse = () => {
        setActiveKey((prevActiveKey) => (prevActiveKey.includes('1') ? [] : ['1']))
    }

    const getRateCardIcon = (itemName) => {
        if (itemName === 'COD') {
            return <CODIcon />
        } else if (itemName?.includes('Card')) {
            return <CardIcon />
        } else if (itemName === 'UPI') {
            return <UPIIcon />
        } else if (itemName === 'Wallet') {
            return <WalletIcon />
        } else if (itemName === 'BNPL') {
            return <BNPLIcon />
        } else if (itemName === 'EMI') {
            return <EMIIcon />
        }
    }

    const deletePremiumPlan = async () => {
        const response = await makeAPICall({
            method: 'delete',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.cancelPremiumKPPlan,
            params: {
                app_name: 'kwikpass_app',
            },
        })

        return response
    }

    const createSubscription = async (plan) => {
        try {
            const endPointKey = +plan?.price === 0 ? 'createBasicPlanForCODFirst' : 'createSubscription'
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints[endPointKey],
                ...(+plan?.price > 0 && {
                    payload: {
                        merchant_id: merchant_details?.m_id,
                        plan_type: plan?.planName,
                        plan_id: `${plan?.id}`,
                        return_url:
                            process.env.NODE_ENV === 'development'
                                ? 'https://dev-mdashboard.dev.gokwik.in/onboarding/kp/onboarding-steps'
                                : process.env.NODE_ENV === 'production'
                                ? 'https://dashboard.gokwik.co/onboarding/kp/onboarding-steps'
                                : 'https://qa-mdashboard.dev.gokwik.in/onboarding/kp/onboarding-steps',
                        app_name: 'kwikpass_app',
                    },
                }),

                ...(+plan?.price === 0 && {
                    payload: {
                        plan_type: plan?.planName,
                        plan_id: `${plan?.id}`,
                    },
                    params: {
                        app_name: 'kwikpass_app',
                    },
                }),
            })

            if (response?.data?.status_code === 200) {
                window.localStorage.setItem('kpSelectedPlan', plan.planName)
                if (+plan?.price === 0) {
                    logEvent(
                        'kp_plan_basic',
                        'click',
                        'pricing_page',
                        userDetails?.userDetails?.email,
                        merchant_details?.m_id,
                        merchant_details?.short_name,
                    )
                    deletePremiumPlan() // cancelling the premium plan subscription, if basic-plan is selected by user irrespective of the fact whether the first time or user is downgrading
                    navigateToStep(null, 1)
                    message.success('Basic plan selected successfully')
                } else {
                    //premium kwikpass plan
                    logEvent(
                        `kp_plan_${plan?.planName?.toLowerCase()}`,
                        'click',
                        'pricing_page',
                        userDetails?.userDetails?.email,
                        merchant_details?.m_id,
                        merchant_details?.short_name,
                    )
                    await deletePremiumPlan()
                    if (response?.data?.data?.confirmation_url) {
                        window.open(response?.data?.data?.confirmation_url, '_self')
                    }
                }
            } else {
                message.error(response?.response?.data?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const callCreateSubscription = async (plan) => {
        try {
            await createSubscription(plan)
        } catch (error) {
            console.error('Error creating subscription:', error)
        }
    }
    const onPlanSelect = async (plan) => {
        await callCreateSubscription(plan)
    }
    const onCustomPlanSelect = async (plan) => {
        setCustomPlanInfo(plan)
        setCustomAppModal(true)
    }
    return (
        <>
            <Row gutter={48}>
                {subscriptionsPlans?.length &&
                    subscriptionsPlans?.map((plan, index) => {
                        return (
                            <Col
                                key={plan?.planName}
                                span={8}
                                className={plan?.planName === 'Custom' ? 'order-3' : ''}
                                // span={24}
                            >
                                <div
                                    className={`plans h-full ${
                                        activeKey?.length === 0 && ''
                                    } bg-white rounded-2xl shadow-md mt-6`}
                                >
                                    <div className='content-section p-3 h-full flex flex-col justify-between"'>
                                        <div className='head'>
                                            <div className='flex justify-between items-center'>
                                                <div className='card-header flex gap-3 items-center'>
                                                    {/* {plan?.planIcon()} */}
                                                    <div className=' text-[#667085] text-xl leading-[32px] font-semibold'>
                                                        {plan?.planHeading}
                                                    </div>
                                                </div>
                                                <div className='text-[#667085]'>Monthly</div>
                                            </div>

                                            <Row justify={'space-between'}>
                                                <Col span={index === 1 ? 12 : 24} flex={'auto'}>
                                                    <span className='text-2xl font-semibold'>
                                                        {getPriceDetails(plan)}
                                                    </span>
                                                </Col>
                                                {index === 1 && (
                                                    <Col className='cursor-pointer' span={12} flex={'10px'}>
                                                        <span
                                                            className={`flex items-center ${
                                                                activeKey.length === 0 && 'origin-center rotate-180'
                                                            }`}
                                                            onClick={handleCollapse}
                                                        >
                                                            <ArrowCircleDown />
                                                        </span>
                                                    </Col>
                                                )}
                                            </Row>
                                        </div>
                                        <div className='feature-card rounded-md px-1 pt-6 pb-3 flex-grow flex flex-col justify-between'>
                                            {activeKey.length > 0 && (
                                                <Row
                                                    wrap={true}
                                                    gutter={[0, 16]}
                                                    className='text-neutral-700 font-medium'
                                                >
                                                    {/* <Col span={24}>
                                                <span className='text- text-xs ml-6'>Offered Features</span>
                                            </Col> */}

                                                    <Col span={24}>
                                                        <div className='plan-sticker rounded gap-3 flex p-3 pl-0 items-center mt-3'>
                                                            <div className='text-sm'>
                                                                <span className='font-medium text-stone-500'>
                                                                    {plan?.planStickerText}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <Row gutter={[0, 16]}>
                                                            {plan?.featureList?.map((item, index) => {
                                                                return (
                                                                    <Col span={24} key={index}>
                                                                        <div className='li-header flex items-baseline mb-2'>
                                                                            <span className='pre-icon mr-1'>
                                                                                <img src={Tick} />
                                                                            </span>
                                                                            &nbsp;
                                                                            <span className='!leading-[22px]'>
                                                                                {item}
                                                                            </span>
                                                                        </div>
                                                                        {item?.content?.length > 0 && (
                                                                            <div className='li-content text-neutral-500 text-xs font-normal ml-6'>
                                                                                <Row gutter={[16, 8]}>
                                                                                    {item?.content?.map((item, i) => (
                                                                                        <Col key={i} span={12}>
                                                                                            <span>{item}</span>
                                                                                        </Col>
                                                                                    ))}
                                                                                </Row>
                                                                            </div>
                                                                        )}
                                                                    </Col>
                                                                )
                                                            })}
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            )}
                                        </div>
                                        <Row
                                            gutter={16}
                                            className='action-items mt-4 p-2'
                                            justify={'center'}
                                            align={'bottom'}
                                        >
                                            <Col span={24}>
                                                <Button
                                                    className='w-full'
                                                    onClick={() => {
                                                        plan.planName === 'Custom'
                                                            ? onCustomPlanSelect(plan)
                                                            : onPlanSelect(plan)
                                                    }}
                                                    variant='primary'
                                                    disabled={disableButton}
                                                >
                                                    {merchant_details?.onboarding_status?.kwik_checkout_app ===
                                                    'pricing_details_captured'
                                                        ? 'Start Plan'
                                                        : plan?.CTAText}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        )
                    })}
            </Row>
            <CustomPlan
                plan={customPlanInfo}
                modalDisplay={customAppModal}
                handleCustomSubmit={() => callCreateSubscription(customPlanInfo)}
                onClose={() => setCustomAppModal(false)}
                merchant_details={merchant_details}
            />
        </>
    )
}
