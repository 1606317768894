// Import Dependencies
import React, { ReactNode } from 'react'

import linkedin from '@library/images/icons/socials/linkedin.svg'
import twitter from '@library/images/icons/socials/twitter.svg'
import youtube from '@library/images/icons/socials/youtube.svg'
import facebook from '@library/images/icons/socials/facebook.svg'
import instagram from '@library/images/icons/socials/instagram.svg'
import bgVector1 from '@library/images/common/bg-vector1.png'
import gkLogo from '@library/images/common/logo-gokwik-business-suit.svg'
import boat from '@library/images/merchants-logo/logo-grey-boat.png'
import manmatters from '@library/images/merchants-logo/logo-grey-mm.png'
import plum from '@library/images/merchants-logo/logo-grey-plum.png'
import noise from '@library/images/merchants-logo/logo-grey-noise.png'

import { Button, Col, Row, Input, Space } from 'gokwik-ui-kit'

export default function ({
    children,
    titleContent,
    showFooter,
    classNames,
}: {
    children: ReactNode
    titleContent?: any
    showFooter?: boolean
    classNames?: String
}) {
    return (
        <div className='bg-[#F5F5F5] w-full inter '>
            <div className=' w-screen h-screen  overflow-hidden relative'>
                <img
                    src={bgVector1}
                    className='w-[500px] absolute  top-[calc(100vh-35%)] right-[calc(100vw-330px)]  '
                />
                <Row>
                    <Col span={10}>
                        <Col className={'h-screen flex items-center justify-center  '}>
                            <Space direction='vertical' size={36}>
                                <img src={gkLogo} className='h-[40px]' />
                                <div>
                                    <h4 className='text-[38px] font-semibold p-0 m-0 text-[var(--primary-blue-750)] w-[400px] '>
                                        Grow Kwik with GoKwik
                                    </h4>
                                    <p className='m-0 text-[14px] font-normal text leading-[22px] text-[var(--colorTextSecondary)] mt-4'>
                                        1000+ Brands & 100Mn+ Shoppers Trust GoKwik
                                    </p>
                                </div>
                                <Row className='gap-6'>
                                    <img src={boat} className='h-6' />
                                    <img src={manmatters} className='h-6' />
                                    <img src={plum} className='h-6' />
                                    <img src={noise} className='h-6' />
                                </Row>
                                <Row className='gap-3   fixed bottom-5'>
                                    <a href='https://www.linkedin.com/company/gokwik'>
                                        {' '}
                                        <img src={linkedin} className='h-6' />
                                    </a>
                                    <a href='https://twitter.com/gokwik'>
                                        <img src={twitter} className='h-6' />
                                    </a>
                                    <a href='https://www.youtube.com/channel/UC7pFLM30cRSFZTMBqq2yF9Q'>
                                        {' '}
                                        <img src={youtube} className='h-6' />
                                    </a>
                                    <a href='https://www.facebook.com/GoKwikCo'>
                                        <img src={facebook} className='h-6' />
                                    </a>
                                    <a href='https://www.instagram.com/gokwik.co/'>
                                        <img src={instagram} className='h-6' />
                                    </a>
                                </Row>
                            </Space>
                        </Col>
                    </Col>
                    <Col span={14} className='h-screen flex items-center justify-center bg-[#E7EFFF] '>
                        <div className='w-full h-full absolute top-0 left-0 bottom-0 right-0 '>
                            <img
                                src={bgVector1}
                                className='w-[500px] fixed top-[-140px] right-[-181px] rotate-[352deg] '
                            />
                        </div>
                        <div className='flex flex-col'>
                            <div className='flex flex-col items-center justify-center  mb-5 gap-2'>{titleContent}</div>
                            <div
                                className={`w-auto p-9 m-auto  rounded-lg border bg-[#fff]  shadow-lg z-10 ${classNames}`}
                            >
                                {children}
                                {!showFooter && (
                                    <div className='text-center mt-5 text-sm !leading-[22px]'>
                                        <p className='font-normal text-sm text-center'>
                                            By singing up, you agree to our{' '}
                                            <a
                                                href={'https://www.gokwik.co/terms'}
                                                target='_blank'
                                                className='text-blue font-semibold'
                                            >
                                                Terms of service
                                            </a>{' '}
                                            and
                                            <a
                                                href={'https://www.gokwik.co/privacy-policy'}
                                                target='_blank'
                                                className='text-blue font-semibold'
                                            >
                                                {' '}
                                                Privacy Policy
                                            </a>
                                            .<br /> Need help?{' '}
                                            <a
                                                href={'https://www.gokwik.co/contact'}
                                                target='_blank'
                                                className='text-blue hover:text-blue  font-semibold'
                                            >
                                                Get in touch
                                            </a>
                                            .
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
