import { memo } from 'react'
import { Card, ColumnsType, ReactApexChart, Table } from 'gokwik-ui-kit'

const ProductWiseChart = ({ productWiseCount }) => {
    const {
        checkout_and_kwik_pass,
        checkout_and_kwik_pass_and_payments,
        checkout_and_kwik_pass_and_payments_and_rto,
        checkout_and_kwik_pass_and_rto,
        checkout_and_payments,
        checkout_and_payments_and_rto,
        checkout_and_rto,
        kwik_pass,
        kwik_pass_and_payments,
        kwik_pass_and_payments_and_rto,
        kwik_pass_and_rto,
        payments,
        payments_and_rto,
        rto,
        checkout,
    } = productWiseCount || {}

    const tableData = [
        {
            checkout_and_kwik_pass: checkout_and_kwik_pass || 0,
            checkout_and_kwik_pass_and_payments: checkout_and_kwik_pass_and_payments || 0,
            checkout_and_kwik_pass_and_payments_and_rto: checkout_and_kwik_pass_and_payments_and_rto || 0,
            checkout_and_kwik_pass_and_rto: checkout_and_kwik_pass_and_rto || 0,
            checkout_and_payments: checkout_and_payments || 0,
            checkout_and_payments_and_rto: checkout_and_payments_and_rto || 0,
            checkout_and_rto: checkout_and_rto || 0,
            kwik_pass: kwik_pass || 0,
            kwik_pass_and_payments: kwik_pass_and_payments || 0,
            kwik_pass_and_payments_and_rto: kwik_pass_and_payments_and_rto || 0,
            kwik_pass_and_rto: kwik_pass_and_rto || 0,
            payments: payments || 0,
            payments_and_rto: payments_and_rto || 0,
            rto: rto || 0,
            checkout: checkout || 0,
        },
    ]

    const transformedData = [
        { product: 'RTO', count: tableData[0].rto },
        { product: 'Checkout', count: tableData[0].checkout },
        { product: 'Payments', count: tableData[0].payments },
        { product: 'KwikPass', count: tableData[0].kwik_pass },
        { product: 'Checkout & KwikPass', count: tableData[0].checkout_and_kwik_pass },
        { product: 'Checkout & KwikPass & Payments', count: tableData[0].checkout_and_kwik_pass_and_payments },
        {
            product: 'Checkout & KwikPass & Payments & RTO',
            count: tableData[0].checkout_and_kwik_pass_and_payments_and_rto,
        },
        { product: 'Checkout & KwikPass & RTO', count: tableData[0].checkout_and_kwik_pass_and_rto },
        { product: 'Checkout & Payments', count: tableData[0].checkout_and_payments },
        { product: 'Checkout & Payments & RTO', count: tableData[0].checkout_and_payments_and_rto },
        { product: 'Checkout & RTO', count: tableData[0].checkout_and_rto },
        { product: 'KwikPass & Payments', count: tableData[0].kwik_pass_and_payments },
        { product: 'KwikPass & Payments & RTO', count: tableData[0].kwik_pass_and_payments_and_rto },
        { product: 'KwikPass & RTO', count: tableData[0].kwik_pass_and_rto },
        { product: 'Payments & RTO', count: tableData[0].payments_and_rto },
    ]?.filter((item) => item.count > 0)
    const columns: ColumnsType<any> = [
        {
            title: 'Products',
            dataIndex: 'product',
            key: 'platform',
            width: 200,
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            sorter: (a, b) => a.count - b.count,
            width: 100,
            defaultSortOrder: 'descend',
        },
    ]

    return (
        <Card className='overflow-x-auto h-[327px]' title='Product-wise merchants count'>
            <Table
                scroll={{
                    x: 300,
                    y: 250,
                }}
                columns={columns}
                dataSource={transformedData}
                pagination={false}
            />
        </Card>
    )
}

export default memo(ProductWiseChart, (prevProps, nextProps) => {
    return JSON.stringify(prevProps?.productWiseCount) === JSON.stringify(nextProps?.productWiseCount)
})
