import React from 'react'

export default function OffersIcon() {
    return (
        <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='0.5' y='0.5' width='43' height='43' rx='15.5' fill='white' fill-opacity='0.24' />
            <rect x='0.5' y='0.5' width='43' height='43' rx='15.5' stroke='url(#paint0_linear_6250_24508)' />
            <g clip-path='url(#clip0_6250_24508)'>
                <path
                    d='M19 25L25 19'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M19.5 20C19.7761 20 20 19.7761 20 19.5C20 19.2239 19.7761 19 19.5 19C19.2239 19 19 19.2239 19 19.5C19 19.7761 19.2239 20 19.5 20Z'
                    fill='#004B8D'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M24.5 25C24.7761 25 25 24.7761 25 24.5C25 24.2239 24.7761 24 24.5 24C24.2239 24 24 24.2239 24 24.5C24 24.7761 24.2239 25 24.5 25Z'
                    fill='#004B8D'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M15.0005 17.2005C15.0005 16.617 15.2322 16.0574 15.6448 15.6448C16.0574 15.2322 16.617 15.0005 17.2005 15.0005H18.2005C18.7814 15.0001 19.3386 14.7701 19.7505 14.3605L20.4505 13.6605C20.6549 13.4549 20.898 13.2917 21.1657 13.1804C21.4334 13.069 21.7205 13.0117 22.0105 13.0117C22.3004 13.0117 22.5875 13.069 22.8552 13.1804C23.1229 13.2917 23.366 13.4549 23.5705 13.6605L24.2705 14.3605C24.6824 14.7701 25.2396 15.0001 25.8205 15.0005H26.8205C27.4039 15.0005 27.9635 15.2322 28.3761 15.6448C28.7887 16.0574 29.0205 16.617 29.0205 17.2005V18.2005C29.0208 18.7814 29.2509 19.3386 29.6605 19.7505L30.3605 20.4505C30.5661 20.6549 30.7292 20.898 30.8406 21.1657C30.9519 21.4334 31.0092 21.7205 31.0092 22.0105C31.0092 22.3004 30.9519 22.5875 30.8406 22.8552C30.7292 23.1229 30.5661 23.366 30.3605 23.5705L29.6605 24.2705C29.2509 24.6824 29.0208 25.2396 29.0205 25.8205V26.8205C29.0205 27.4039 28.7887 27.9635 28.3761 28.3761C27.9635 28.7887 27.4039 29.0205 26.8205 29.0205H25.8205C25.2396 29.0208 24.6824 29.2509 24.2705 29.6605L23.5705 30.3605C23.366 30.5661 23.1229 30.7292 22.8552 30.8406C22.5875 30.9519 22.3004 31.0092 22.0105 31.0092C21.7205 31.0092 21.4334 30.9519 21.1657 30.8406C20.898 30.7292 20.6549 30.5661 20.4505 30.3605L19.7505 29.6605C19.3386 29.2509 18.7814 29.0208 18.2005 29.0205H17.2005C16.617 29.0205 16.0574 28.7887 15.6448 28.3761C15.2322 27.9635 15.0005 27.4039 15.0005 26.8205V25.8205C15.0001 25.2396 14.7701 24.6824 14.3605 24.2705L13.6605 23.5705C13.4549 23.366 13.2917 23.1229 13.1804 22.8552C13.069 22.5875 13.0117 22.3004 13.0117 22.0105C13.0117 21.7205 13.069 21.4334 13.1804 21.1657C13.2917 20.898 13.4549 20.6549 13.6605 20.4505L14.3605 19.7505C14.7701 19.3386 15.0001 18.7814 15.0005 18.2005V17.2005Z'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
            </g>
            <defs>
                <linearGradient
                    id='paint0_linear_6250_24508'
                    x1='0.848501'
                    y1='2.2083'
                    x2='41.5344'
                    y2='34.45'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#91D0FF' stop-opacity='0.12' />
                    <stop offset='1' stop-color='#0094D5' stop-opacity='0.12' />
                </linearGradient>
                <clipPath id='clip0_6250_24508'>
                    <rect width='24' height='24' fill='white' transform='translate(10 10)' />
                </clipPath>
            </defs>
        </svg>
    )
}
