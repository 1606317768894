import React from 'react'

export default function () {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 22 22' fill='none'>
            <path
                d='M14.4837 2.02237C14.1079 1.64653 13.457 1.9032 13.457 2.4257V5.62487C13.457 6.9632 14.5937 8.07237 15.9779 8.07237C16.8487 8.08153 18.0587 8.08153 19.0945 8.08153C19.617 8.08153 19.892 7.46737 19.5254 7.1007C18.2054 5.77153 15.8404 3.37903 14.4837 2.02237Z'
                fill='#028DF5'
            />
            <path
                d='M18.792 9.34344H16.1428C13.9703 9.34344 12.2012 7.57427 12.2012 5.40177V2.7526C12.2012 2.24844 11.7887 1.83594 11.2845 1.83594H7.39783C4.57449 1.83594 2.29199 3.66927 2.29199 6.94177V15.0634C2.29199 18.3359 4.57449 20.1693 7.39783 20.1693H14.6028C17.4262 20.1693 19.7087 18.3359 19.7087 15.0634V10.2601C19.7087 9.75594 19.2962 9.34344 18.792 9.34344ZM10.542 16.2734H6.87533C6.49949 16.2734 6.18783 15.9618 6.18783 15.5859C6.18783 15.2101 6.49949 14.8984 6.87533 14.8984H10.542C10.9178 14.8984 11.2295 15.2101 11.2295 15.5859C11.2295 15.9618 10.9178 16.2734 10.542 16.2734ZM12.3753 12.6068H6.87533C6.49949 12.6068 6.18783 12.2951 6.18783 11.9193C6.18783 11.5434 6.49949 11.2318 6.87533 11.2318H12.3753C12.7512 11.2318 13.0628 11.5434 13.0628 11.9193C13.0628 12.2951 12.7512 12.6068 12.3753 12.6068Z'
                fill='#028DF5'
            />
        </svg>
    )
}
