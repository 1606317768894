import { InfoCircleOutlined, InfoOutlined, Tooltip } from 'gokwik-ui-kit'

const DocumentAnalysis = ({ response }) => {
    const interpretResponse = (response) => {
        const { doc_type, validity_score, document_number, date_of_birth, year_of_birth, visibility, name, address } =
            response

        let prominentReason = ''
        let emoji

        if (validity_score === 'Low') {
            emoji = '❌'
            if (doc_type === 'Not Aadhaar' || doc_type === 'Not PAN') {
                prominentReason = `The document uploaded is ${doc_type}`
            } else if (!document_number) {
                prominentReason = 'The document number could not be extracted.'
            } else if (visibility === 'Unclear') {
                prominentReason = 'The image visibility is unclear.'
            } else if (!name) {
                prominentReason = 'The name could not be extracted.'
            } else if (!date_of_birth && !year_of_birth) {
                prominentReason = 'The date of birth could not be extracted.'
            } else if (doc_type === 'Aadhaar Back' && !address) {
                prominentReason = 'The address could not be extracted.'
            } else {
                prominentReason = 'Multiple attributes could not be extracted, leading to a low confidence score.'
            }
        } else {
            emoji = '✅'
        }

        if (doc_type && validity_score) {
            return {
                validity_score,
                prominentReason,
                emoji,
            }
        }
    }

    if (!response) return

    const AIResponse = interpretResponse(response)

    return (
        <div className='p-2'>
            <div className='flex gap-2'>
                <div className='flex flex-col gap-1'>
                    <div className='flex gap-1'>
                        <div>
                            <strong>Likelihood of Acceptance:</strong> {AIResponse?.validity_score} {AIResponse?.emoji}
                        </div>
                        <div>
                            <Tooltip
                                title={
                                    <div className=''>
                                        <p>
                                            <strong>Document Type:</strong> {response?.doc_type}
                                        </p>
                                        <p>
                                            <strong>Visibility:</strong> {response?.visibility}
                                        </p>
                                        <p>
                                            <strong>Validity Score:</strong> {response?.validity_score}
                                        </p>
                                        <p>
                                            <strong>Name:</strong> {response?.name ? response?.name : 'N/A'}
                                        </p>
                                        <p>
                                            <strong>Date of Birth:</strong>{' '}
                                            {response?.date_of_birth ? response?.date_of_birth : 'N/A'}
                                        </p>

                                        {response?.year_of_birth && (
                                            <p>
                                                <strong>Year Of Birth:</strong> {response?.year_of_birth}
                                            </p>
                                        )}
                                        <p>
                                            <strong>Document Number:</strong>{' '}
                                            {response?.document_number ? response?.document_number : 'N/A'}
                                        </p>
                                        {response?.address && (
                                            <p>
                                                <strong>Address:</strong> {response?.address}
                                            </p>
                                        )}
                                    </div>
                                }
                            >
                                <InfoCircleOutlined className='cursor-pointer text-orange-400' />
                            </Tooltip>
                        </div>
                    </div>

                    {AIResponse?.validity_score === 'Low' && (
                        <div>
                            <strong>Reason:</strong> {AIResponse?.prominentReason}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DocumentAnalysis
