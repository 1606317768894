// Import Dependencies
import { navigateToUrl } from 'single-spa'
import React, { useState, useEffect, useCallback } from 'react'

// Import Utilities
import useAESEncryption from '@library/utilities/authorization/encryption'
import { dashboardStore, makeAPICall } from '@gokwik/utilities'
import { Button, Col, Row, Input, Popover, Form, message, Checkbox, Modal, Tooltip } from 'gokwik-ui-kit'
import EmailIcon from '@library/images/icons/emailIcon'
import PasswordIcon from '@library/images/icons/passwordIcon'
import { redirect, useSearchParams, useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    fetchUserDetails,
    sendOtpOnPhone,
    setSignUpEmail,
    setUserData,
    signin,
    verifyOTP,
    verifyOtpOnPhone,
} from '@store/user'
import LoginLayout from '@library/components/loginLayout'
import { getSignUpEmail } from '@store/user/selectors'
import { useAppDispatch } from '@library/utilities/hooks'
import { getMerchantDetails as getMerchantDetailsRedux } from '@store/user/selectors'
import {
    CheckCircleFilled,
    CheckCircleOutlined,
    CloseCircleFilled,
    CloseCircleOutlined,
    EyeFilled,
    MailOutlined,
} from '@ant-design/icons'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import shileds from '@library/images/icons/shield.svg'
import shiledsCross from '@library/images/icons/shield-cross.svg'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import Terms from '@library/images/terms.html'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import './password-signup.css'
import { PhoneNumberUtil } from 'google-libphonenumber'

export default function () {
    const [step, setStep] = useState(0)

    const [credentialsData, setCredentialsData] = useState({ confirmPass: '', password: '' })
    const [current, setCurrent] = useState('emailCheck')
    // const [error, setError] = useErrorContext();
    // const router = useRoute();
    const dispatch = useAppDispatch()
    const [form] = Form.useForm()
    const [isMerchantExist, setMerchantExist] = useState(false)
    const [passwordCheck, setPasswordCheck] = useState({ pass: false, confirm: false })
    const [showRcodeField, setShowRcodeField] = useState(false)
    const [phoneNumberError, setPhoneNumberError] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const u_id = searchParams.get('user_id')
    const m_id = searchParams.get('m_id')
    const app = searchParams.get('app')
    const merchant_details = useSelector(getMerchantDetailsRedux)
    const [initPhase, setInitPhase] = useState(true)
    const [otpError, setOtpError] = useState(false)
    const [formDetails, setFormDetails] = useState({
        business_categories: [],
        merchant_details: {},
        monthly_gmv: [],
        products: [],
        platforms: [],
    })
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        password: '',
        phoneNumber: '',
        refCode: '',
        otp: '',
    })
    const [goodPassword, setGoodPassword] = useState({
        'Minimum 8 Length': false,
        '1 Small and 1 Capital Letter': false,
        '1 Digit': false,
        '1 Special Character': false,
    })
    const [termsCondition, setTermsCondition] = useState(false)
    const [isTNCAccept, setIsTNCAcceptState] = useState(false)
    const [otpVerified, setIsOtpVerified] = useState(false)
    const [showOtpField, setShowOtpField] = useState(false)
    const [dialCode, setDialCode] = useState('')
    const [timerOtp, setTimerOtp] = useState(60)

    useEffect(() => {
        sessionStorage.setItem("navigatedFromSetPassword", "true");
        const customEvent = new CustomEvent('loader', {
            detail: {
                data: { reset: true },
            },
        })
        window.dispatchEvent(customEvent)
    }, [])

    const referralCodeAdded = async () => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.createLead + (m_id ?? merchant_details?.m_id),
                ...{ payload: { referral_code: formData?.refCode } },
            })

            if (response?.data?.status_code === 200) {
                message.success('Our team will reach out to you in 1-2 business days')
            } else {
                message.error('Error while submitting refferal code', response?.response?.data?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const phoneUtil = PhoneNumberUtil.getInstance()

    const isPhoneValid = (phone: string) => {
        try {
            return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
        } catch (error) {
            return false
        }
    }

    const signin = async () => {
        logEvent('signup_button_clicked', 'click', 'signup', formData.email, m_id, '', '', app && { app })
        // const isValid = validateFormData()
        if (credentialsData.password !== credentialsData.confirmPass) {
            message.warning('Password does not match ')
            return
        }

        if (!credentialsData.password || credentialsData.password.length < 8) {
            // setError({ ...error, password: "Invalid Password" });
            message.error('Invalid password')
            logEvent('password_validation_failed', 'click', 'signup', formData.email, m_id)
            return
        }
        const password = await useAESEncryption(credentialsData.password, process.env.REACT_APP_PUBLIC_CRYPTO_KEY)

        await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.signup + `/${m_id}/${u_id}`,
            payload: {
                password,
                phoneNumber: formData?.phoneNumber,
            },
            ...(app && { params: { app } }),
        })
            .then(async (signupResponse) => {
                if (signupResponse?.data?.status_code === 200 && formData.refCode?.length > 0) {
                    await referralCodeAdded()
                }
            })
            .then(async () => {
                logEvent(
                    'signup_success',
                    'click',
                    'signup',
                    localStorage?.getItem('email'),
                    '',
                    '',
                    '',
                    app && { app },
                )
                localStorage.setItem('currentPage', 'dashboard')
                // localStorage.setItem('token', response.data.data.token)
                localStorage.setItem('email', formData.email)
                let redirectTo = await dispatch(fetchUserDetails(null, true))
                if (redirectTo === '/login') {
                    redirectTo = `/login?app=${app}`
                }

                logEvent('merchant_signup_process_successful', 'click', 'signup', formData.email, m_id)
                navigate(redirectTo, { replace: true })
            })
            .catch((err) => {
                // if (err?.response?.data.status_code === 403) {
                //     // router.push('/verify-otp');
                //     // return;
                // }
                let errorMsg = err?.response && err?.response.data ? err?.response.data.message : 'Something failed'
                message.error(errorMsg)
                logEvent('merchant_signup_process_Failed', 'click', 'signup', formData.email, m_id, '', '', {
                    error: err.errorMessage,
                })
            })
    }

    const getMerchantDetails = async () => {
        let user_id = searchParams.get('user_id')
        let m_id = searchParams.get('m_id')
        if (m_id) {
            setMerchantExist(true)
        }
        let response = await makeAPICall({
            method: 'get',
            url:
                process.env.REACT_APP_BASE_URL +
                '/api/dashboard/onboarding/signup-details' +
                (user_id ? `?user_id=${user_id}` : ''),
        })
        if (response.data.status_code == 200) {
            setFormDetails(response.data?.data)
            if (user_id) {
                let merchant_details = response.data?.data?.merchant_details
                setFormData((prev) => ({
                    ...prev,
                    website: merchant_details?.website,
                    business_name: merchant_details?.brand_name,
                    platform: merchant_details?.platform,
                    email: merchant_details?.email,
                    products: merchant_details?.products,
                }))
                form.setFieldValue('email', merchant_details?.email)
                sessionStorage.setItem('invited_email', response.data?.data?.merchant_details.email)
            }

            logEvent(
                'kwikcodapp_password_setup_landed',
                'event',
                'signup',
                response.data?.data?.merchant_details.email,
                m_id,
                '',
                '',
                app && { app },
            )
        } else {
            message.error('Invalid merchant')
        }
    }

    useEffect(() => {
        if (!timerOtp) return

        if (!showOtpField) return

        const timeout = setTimeout(
            () =>
                setTimerOtp((prev) => {
                    return prev - 1
                }),
            1000,
        )
        return () => clearTimeout(timeout)
    }, [timerOtp, showOtpField])

    const callBack = (err?: any) => {
        if (!err) {
            setTimerOtp(60)
            setShowOtpField(true)
            logEvent('merchant_signup_otp_sent_success', 'click', 'signup', formData.email, m_id)
            message.success('OTP sent on your Phone Number!')
        } else {
            message.error(err.errorMessage)
            if (err?.errorMessage?.includes('already sent')) {
                setShowOtpField(true)
            } else {
                logEvent('merchant_signup_otp_sent_failed', 'click', 'signup', formData.email, m_id, '', '', {
                    error: err.errorMessage,
                })
            }
        }
    }

    const OtpVerificationCallback = (err?: any) => {
        if (err) {
            setOtpError(true)
            setFormData((prev) => ({ ...prev, otp: '' }))
            message.error(err.errorMessage)
            logEvent('merchant_signup_otp_verification_failed', 'click', 'signup', formData.email, m_id, '', '', {
                error: err.errorMessage,
            })
            return false
        } else {
            setIsOtpVerified(true)
            setShowOtpField(false)
            setOtpError(false)
            sessionStorage.setItem('phone_otp_status', 'verified')
            logEvent('merchant_signup_otp_verification_successful', 'click', 'signup', formData.email, m_id)
            message.success('OTP verified successfully!')
            return true
        }
    }

    const sendOtp = async () => {
        sessionStorage.setItem('signup_phone', `${dialCode}${formData.phoneNumber}`)
        sessionStorage.setItem('phone_otp_status', 'sent')

        await sendOtpOnPhone(formData.phoneNumber, callBack)
    }

    const resend = async () => {
        logEvent('merchant_signup_otp_resent', 'click', 'signup', formData.email, m_id)
        await sendOtp()
    }

    useEffect(() => {
        const invitedEmail = sessionStorage.getItem('invited_email')
        const signupPhone = sessionStorage.getItem('signup_phone')

        if (invitedEmail) {
            form.setFieldValue('email', invitedEmail)
        }

        if (signupPhone) {
            form.setFieldValue('phoneNumber', signupPhone)
        }
        if (!sessionStorage.getItem('merchant_data_fetched')) {
            getMerchantDetails()
        }
        sessionStorage.setItem('merchant_data_fetched', 'true')

        if (sessionStorage.getItem('phone_otp_status') === 'sent' && signupPhone) {
            setShowOtpField(true)
        }

        if (sessionStorage.getItem('phone_otp_status') === 'verified') {
            setIsOtpVerified(true)
        }
    }, [])

    const checkGoodPassword = (password) => {
        let temp = {}
        temp['Minimum 8 Length'] = password.length >= 8
        temp['1 Small and 1 Capital Letter'] = /(?=.*[a-z])/.test(password) && /(?=.*[A-Z])/.test(password)
        temp['1 Digit'] = /(?=.*\d)/.test(password)
        temp['1 Special Character'] = /(?=.*\W])/.test(password) || /[-+_!@#$%^&*.,?]/.test(password)
        //@ts-ignore
        setGoodPassword(temp)
    }

    const handlePhoneChange = (phone, meta) => {
        const { dialCode } = meta.country
        setDialCode(dialCode)
        const phNo = phone.replace(`+${dialCode}`, '').trim()

        setPhoneNumberError(!isPhoneValid(phone))

        if (initPhase && phNo?.length > 0) {
            setInitPhase(false)
        }

        form.setFieldValue('phoneNumber', phone)

        setFormData((prev) => ({ ...prev, phoneNumber: phNo }))
    }

    const handleOtpChange = (e) => {
        const otp = e.target.value.replace(/\D/g, '')
        if (otp?.length === 6) {
            setOtpError(false)
        }
        form.setFieldValue('otp', otp)
        setFormData((prev) => ({
            ...prev,
            otp: otp,
        }))
    }

    const handleRefcodeChange = (e) => {
        const refCode = e.target.value
        form.setFieldValue('refCode', refCode)
        setFormData((prev) => ({ ...prev, refCode: refCode }))
    }

    const getCurrentPendingInputMessage = () => {
        if (!otpVerified) {
            return
        }
        return formData?.phoneNumber?.length < 1 || phoneNumberError
            ? 'Please Add a Valid Phone Number'
            : credentialsData?.password?.length < 1
            ? 'Please Set a Valid Password'
            : credentialsData?.confirmPass?.length < 1
            ? 'Please confirm the Password'
            : !isTNCAccept
            ? 'Please accept terms and conditions'
            : ''
    }

    const getOtp = async () => {
        await sendOtp()
    }

    const verifyOtp = async (otp = formData.otp) => {
        if (!otp || otp.length < 6) {
            message.error('Invalid OTP')
            return false
        }

        await verifyOtpOnPhone(formData.phoneNumber, otp, OtpVerificationCallback)
    }

    const resetPhoneNumber = () => {
        sessionStorage.removeItem('signup_phone')
        sessionStorage.setItem('phone_otp_status', 'pending')
        logEvent('merchant_signup_phone_number_change_clicked', 'click', 'signup', formData.email, m_id)
        setShowOtpField(false)
    }

    return (
        <LoginLayout
            titleContent={
                <>
                    {' '}
                    <p className='text-3xl text-[#060322] font-normal leading-normal inter tracking-tight'>
                        Finish Signup
                    </p>
                    <svg xmlns='http://www.w3.org/2000/svg' width='398' height='2' viewBox='0 0 398 2' fill='none'>
                        <path d='M1 1L397 0.999965' stroke='#828282' stroke-linecap='round' />
                    </svg>
                    <p className='font-normal text-normal'>
                        Please create password for account :{' '}
                        <span className='text-blue-500'>
                            {formData.email || sessionStorage.getItem('invited_email')}
                        </span>
                    </p>
                </>
            }
        >
            <Modal
                cancelText={'Decline'}
                okText={'I Agree'}
                centered
                title={
                    <Row className='gap-2'>
                        <CloseCircleOutlined
                            size={50}
                            onClick={() => {
                                setTermsCondition(false)
                            }}
                        />
                        <p>Terms and Condtions</p>
                    </Row>
                }
                open={termsCondition}
                closeIcon={null}
                onOk={() => {
                    logEvent('terms_and_conditions_agreed', 'click', 'signup', formData?.email, m_id)
                    setIsTNCAcceptState(true)
                    setTermsCondition(false)
                }}
                onCancel={() => {
                    logEvent('terms_and_conditions_rejected', 'click', 'signup', formData?.email, m_id)
                    setIsTNCAcceptState(false)
                    setTermsCondition(false)
                }}
            >
                <div className=' max-h-[70vh] overflow-auto'>
                    <div className='terms' id='terms' dangerouslySetInnerHTML={{ __html: Terms }}></div>
                </div>
            </Modal>
            <div className='m-auto flex flex-col align-middle justify-center gap-y-5'>
                <div className='w-full flex flex-col gap-y-5'>
                    <Form
                        form={form}
                        layout='vertical'
                        className='flex flex-col'
                        onFinish={(values) => {
                            if (showOtpField && values.otp?.length === 6) {
                                verifyOtp()
                            } else if (otpVerified) {
                                signin()
                            } else {
                                getOtp()
                            }
                        }}
                    >
                        <div className='flex flex-col gap-y-4 mb-4'>
                            <Form.Item name={'email'} label={'Email ID'} className='w-full mb-0'>
                                <Input type='email' value={formData.email} disabled />
                            </Form.Item>
                            <div className='relative'>
                                <Form.Item
                                    className='w-full mb-0 ant-phone-input'
                                    name='phoneNumber'
                                    label='Phone Number'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your phone number!',
                                        },
                                    ]}
                                    required
                                >
                                    <PhoneInput
                                        defaultCountry='in'
                                        onChange={(phone, meta) => handlePhoneChange(phone, meta)}
                                        value={formData?.phoneNumber}
                                        className='PhoneInput'
                                        forceDialCode={true}
                                        disableFormatting={true}
                                        disabled={otpVerified || showOtpField}
                                    />
                                </Form.Item>
                                {!phoneNumberError && (
                                    <div className='absolute right-3 bottom-2'>
                                        <span className='flex justify-end mt-1'>
                                            {otpVerified && <span className='text-xs mr-1'>Verified</span>}
                                            <CheckCircleFilled className='text-green-500 ' />
                                        </span>
                                    </div>
                                )}
                            </div>

                            {showOtpField && sessionStorage.getItem('phone_otp_status') === 'sent' && (
                                <Form.Item
                                    className='w-full mb-0 ant-phone-input'
                                    name='otp'
                                    label='Your 6-digit code'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter OTP',
                                        },
                                    ]}
                                    required
                                >
                                    <Input
                                        suffix={otpError && <CloseCircleFilled className='text-red-500' />}
                                        className='email-input gk-text-input'
                                        type='text'
                                        maxLength={6}
                                        value={formData.otp}
                                        inputMode='numeric'
                                        pattern='[0-9]*'
                                        onChange={(e) => handleOtpChange(e)}
                                        placeholder='Enter verification code'
                                    />
                                    {otpError && (
                                        <span className='text-red-700 text-xs flex justify-end mt-1'>Invalid OTP</span>
                                    )}
                                </Form.Item>
                            )}

                            {!initPhase && phoneNumberError && (
                                <div className='text-red-500 mt-[-10px]'>Phone number is not valid</div>
                            )}
                            {showRcodeField && (
                                <Form.Item className='mb-0' name='refCode' label='Referral code'>
                                    <Input
                                        placeholder='Referral code'
                                        value={formData?.refCode}
                                        onChange={(e) => handleRefcodeChange(e)}
                                    />
                                </Form.Item>
                            )}
                        </div>

                        {otpVerified && (
                            <>
                                <div className='flex flex-col gap-4'>
                                    <Form.Item
                                        name={'password'}
                                        label={'Password'}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your password!',
                                            },
                                        ]}
                                        className='w-full mb-0'
                                    >
                                        <Popover
                                            open={passwordCheck.pass}
                                            content={[
                                                'Minimum 8 Length',
                                                '1 Small and 1 Capital Letter',
                                                '1 Digit',
                                                '1 Special Character',
                                            ].map((u) => (
                                                <div
                                                    className={`text-xs flex justify-between p-1  ${
                                                        goodPassword[u] ? 'good' : 'bad'
                                                    }`}
                                                >
                                                    <p className='mr-2'>{u}</p>
                                                    {goodPassword[u] ? (
                                                        <img src={shileds} className='tick-icon' />
                                                    ) : (
                                                        <img src={shiledsCross} className='tick-icon' />
                                                    )}
                                                </div>
                                            ))}
                                        >
                                            <Input.Password
                                                value={credentialsData?.password}
                                                onChange={(e) => {
                                                    setCredentialsData((prev) => ({
                                                        ...prev,
                                                        password: e.target.value,
                                                    }))
                                                    form.setFieldsValue({ password: e.target.value })
                                                    checkGoodPassword(e.target.value)
                                                }}
                                                type='password'
                                                label='Password'
                                                onFocus={() => {
                                                    setPasswordCheck((prev) => ({ ...prev, pass: true }))
                                                }}
                                                onBlur={() => {
                                                    setPasswordCheck((prev) => ({ ...prev, pass: false }))
                                                }}
                                            />
                                        </Popover>
                                    </Form.Item>
                                    <Form.Item
                                        name={'confirmPass'}
                                        label={'Confirm Password'}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your password!',
                                            },
                                            () => ({
                                                validator(_, value) {
                                                    if (!value || credentialsData?.password === value) {
                                                        return Promise.resolve()
                                                    }
                                                    return Promise.reject(
                                                        new Error('The new password that you entered do not match!'),
                                                    )
                                                },
                                            }),
                                        ]}
                                        className='w-full mb-0'
                                    >
                                        <Input.Password
                                            value={credentialsData?.confirmPass}
                                            onChange={(e) => {
                                                setCredentialsData((prev) => ({ ...prev, confirmPass: e.target.value }))
                                                form.setFieldsValue({ confirmPass: e.target.value })
                                            }}
                                            type='password'
                                            label='Confirm Password'
                                        />
                                    </Form.Item>
                                </div>
                                <div className='w-full flex mt-5 mb-2'>
                                    <Checkbox
                                        checked={isTNCAccept}
                                        onChange={() => {
                                            logEvent(
                                                'terms_and_conditions_clicked',
                                                'click',
                                                'signup',
                                                formData?.email,
                                                m_id,
                                            )
                                            setTermsCondition(true)
                                        }}
                                    >
                                        Accept terms and conditions
                                    </Checkbox>
                                </div>
                            </>
                        )}
                        <div className='flex flex-col w-full'>
                            <Tooltip placement='topLeft' title={getCurrentPendingInputMessage()}>
                                <Button
                                    variant='primary'
                                    htmlType='submit'
                                    className='w-full disabled:border-gray-300'
                                    disabled={
                                        showOtpField
                                            ? formData?.otp?.length !== 6
                                            : !otpVerified
                                            ? phoneNumberError
                                            : credentialsData.confirmPass.length <= 5 ||
                                              credentialsData.password.length <= 5 ||
                                              !isTNCAccept ||
                                              phoneNumberError
                                    }
                                >
                                    <span className='font-bold'>
                                        {showOtpField && formData.otp?.length === 6
                                            ? 'Verify OTP'
                                            : otpVerified
                                            ? 'Set Password'
                                            : 'Get OTP'}
                                    </span>
                                </Button>
                            </Tooltip>
                            {showOtpField && (
                                <div className='flex justify-between text-center inter mt-2'>
                                    {timerOtp === 0 ? (
                                        <span
                                            className='flex body py-2 text-primary-700 max-w-max cursor-pointer'
                                            onClick={resend}
                                        >
                                            Resend OTP
                                        </span>
                                    ) : (
                                        <span className='flex body py-2 max-w-max font-normal text-gray-500'>
                                            Resend code in {timerOtp} Sec
                                        </span>
                                    )}

                                    <span
                                        className='flex body py-2 text-primary-700 max-w-max cursor-pointer'
                                        onClick={resetPhoneNumber}
                                    >
                                        Change Number
                                    </span>
                                </div>
                            )}
                        </div>
                    </Form>
                </div>
                {otpVerified && (
                    <div
                        className='flex align-middle justify-center text-primary-500 font-semibold cursor-pointer'
                        onClick={() => (showRcodeField ? {} : setShowRcodeField(true))}
                    >
                        Are you a shopify partner?
                    </div>
                )}
            </div>
        </LoginLayout>
    )
}
