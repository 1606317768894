import { Button, Col, Row } from 'gokwik-ui-kit'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { SearchFilters } from '@library/utilities/interface'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useSelector } from 'react-redux'
import { RootStoreType } from '@store/index'
import RenderFilter from './renderFilter'
import { FilterCreatorComponent } from '../filter-creator'
import { SearchOutlined, RedoOutlined } from '@ant-design/icons'
import { getMerchantDetails, isMerchantSelector } from '@store/user/selectors'

interface SearchFiltersProps {
    values: Record<string, any>
    setValues: (data: { [key: string]: any }, reset?) => void
    page: string
    onSearch: (e: React.MouseEvent<HTMLElement>) => void
    onReset: (e: React.MouseEvent<HTMLElement>) => void
    children?: ReactNode
}

const RenderSearchFilters = ({ values, setValues, page, onSearch, onReset, children }: SearchFiltersProps) => {
    const [selectedFilters, setSelectedFilters] = useState<string[]>([])
    const [filters, setFilters] = useState<SearchFilters[]>([])
    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantDetails = useSelector(getMerchantDetails)
    const dynamicFilters = useMemo(() => filters?.filter((item) => item?.is_dynamic), [filters])
    const staticFilters = useMemo(() => {
        const dateRangeInput = filters.findIndex((filter) => filter.input_details.input_type === 'date-range')
        if (dateRangeInput !== -1) {
            return filters
                .filter((item) => !item?.is_dynamic)
                .sort(function (x: SearchFilters, y: SearchFilters) {
                    return x.input_details.input_type === 'date-range'
                        ? -1
                        : y.input_details.input_type === 'date-range'
                        ? 1
                        : 0
                })
        }
        return filters.filter((item) => !item?.is_dynamic)
    }, [filters])
    const prevFilters = useSelector((state: RootStoreType) => state.filters?.[page])
    const dynamicFilterKeys = dynamicFilters.reduce((result, curr) => ({ ...result, [curr.key]: curr }), {})

    async function fetchFilters() {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: page === "timeline" ? process.env.AUDIT_SERVICEURL+ "/get-filters/"+merchantDetails.id :  process.env.REACT_APP_BASE_URL + APIEndPoints.getFilters,
                params: {
                    dashboard: isMerchantUser ? 'merchant' : 'admin',
                    page,
                },
            })
            setFilters(response?.data?.data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchFilters()
    }, [])

    useEffect(() => {
        const prevFiltersKey = Object?.keys(prevFilters || {})
        if (prevFiltersKey.length && dynamicFilters.length) {
            const prevDynamicFilters = dynamicFilters.filter((filter) => prevFilters[filter.key])
            setSelectedFilters(prevDynamicFilters.map((itm) => itm.key))
        }
    }, [prevFilters, filters])
    if (!filters.length) return <></>

    return (
        <>
            <Row
                className='w-full p-3 gap-2'
                style={{
                    borderBottom: '0.5px dashed #D7D7D7',
                }}
                justify={'space-between'}
                {...(selectedFilters.length && { wrap: true })}
            >
                {staticFilters.map((filter) => {
                    const value =
                        filter.input_details.input_type === 'date-range'
                            ? `${values[filter.key.split('/')[0]]},${values[filter.key.split('/')[1]]}`
                            : values[filter.key]

                    return (
                        <Col className='w-[19%]' key={filter.key}>
                            <RenderFilter
                                {...filter}
                                value={value}
                                inputKey={filter.key}
                                onChange={setValues}
                                {...(filter?.input_details?.disabled_key && {
                                    disabled:
                                        values[filter?.input_details?.disabled_key] ===
                                        filter?.input_details?.disabled_value,
                                })}
                            />
                        </Col>
                    )
                })}
                {staticFilters.length < 5 && (
                    <>
                        {children ? (
                            children
                        ) : (
                            <>
                                {!!staticFilters.length &&
                                    !!(staticFilters.length % 4) &&
                                    Array.from(Array(4 - (staticFilters.length % 4)).keys()).map((itm) => (
                                        <Col key={itm} className='w-[19%]'></Col>
                                    ))}
                                <Col className='flex items-center gap-x-3'>
                                    <Button onClick={onSearch} variant='primary'>
                                        <SearchOutlined />
                                        Search
                                    </Button>
                                    <Button variant='default' onClick={onReset}>
                                        <RedoOutlined />
                                        Reset
                                    </Button>
                                </Col>
                            </>
                        )}
                    </>
                )}

                {selectedFilters.map((filterKey, i) => {
                    const filter: SearchFilters = dynamicFilterKeys[filterKey]
                    const value =
                        filter.input_details.input_type === 'date-range'
                            ? `${values[filterKey.split('/')[0]]},${values[filterKey.split('/')[1]]}`
                            : values[filter.key]

                    return (
                        <Col className='w-[19%]' key={filter.key}>
                            <RenderFilter
                                {...filter}
                                value={value}
                                inputKey={filter.key}
                                onChange={setValues}
                                removable
                                onRemove={(key) => {
                                    setSelectedFilters((prev) => prev.filter((filterKey) => filterKey !== filter.key))
                                    const temp = { ...values }
                                    delete temp[filter.key]
                                    setValues({ ...temp }, true)
                                }}
                            />
                        </Col>
                    )
                })}
                {!!selectedFilters.length &&
                    !!(selectedFilters.length % 5) &&
                    Array.from(Array(5 - (selectedFilters.length % 5)).keys()).map((itm) => (
                        <Col key={itm} className='w-[19%]'></Col>
                    ))}
            </Row>
            {(!!dynamicFilters.length || staticFilters?.length === 5) && (
                <Row
                    className='w-full p-3'
                    style={{
                        borderBottom: '0.5px dashed #D7D7D7',
                    }}
                    justify={!!dynamicFilters.length ? 'space-between' : 'end'}
                >
                    {!!dynamicFilters.length && (
                        <Col>
                            <FilterCreatorComponent
                                addFilterBtnRef={null}
                                selectedValues={selectedFilters}
                                options={dynamicFilters.map((filter) => ({ key: filter.key, title: filter.label }))}
                                setSelectedValues={(key) => {
                                    if (selectedFilters.includes(key))
                                        setSelectedFilters((prev) => prev.filter((filterKey) => filterKey !== key))
                                    else {
                                        // customFilterOnSelect?.(key)
                                        setSelectedFilters((prev) => [...prev, key])
                                    }
                                }}
                            />
                        </Col>
                    )}
                    {children ? (
                        children
                    ) : (
                        <Col className='flex items-center gap-x-3'>
                            <Button onClick={onSearch} variant='primary'>
                                <SearchOutlined />
                                Search
                            </Button>
                            <Button variant='default' onClick={onReset}>
                                <RedoOutlined />
                                Reset
                            </Button>
                        </Col>
                    )}
                </Row>
            )}
        </>
    )
}

export default RenderSearchFilters
