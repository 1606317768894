import React, { useEffect, useState } from 'react'
import {
    Form,
    Button,
    Upload,
    Select,
    Collapse,
    DownOutlined,
    Switch,
    Row,
    Col,
    Tooltip,
    UploadOutlined,
    QuestionCircleOutlined,
    EyeOutlined,
    Checkbox,
    CloseCircleOutlined,
    Modal,
    Tag,
    Spin,
} from 'gokwik-ui-kit'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import {
    customRequest,
    getKYCDetails,
    getPreviewImageForDoc,
    kycFieldsMap,
    sectionWiseNonErrorStatusChips,
    UploadedFileComponent,
} from '../../../utils'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getUserData } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import Terms from '@library/images/terms.html'
import DocumentPreview from '../../components/DocumentPreview'
import { stepConfiguration } from '@pages/onboarding/stepsConfig'
import { DEFAULT_FORMAT_REQUIREMENTS } from '../../constants'
import ComplienceModal from '../../components/ComplienceModal'

const DocumentsUpload = ({
    kycData,
    onFinish,
    setCurrentStep,
    currentStep,
    isPanelClickable,
    undeterminedKycErrorState,
    setIsCpvMandatory,
    setKycData,
    setOpenComplienceModal,
    openComplienceModal,
    complienceAgreed,
    setComplienceAgreed,
    sendPriceAgreed,
    isCPVMandatory,
}) => {
    const [form] = Form.useForm()
    const [documentList, setDocumentList] = useState({})
    const [fassai_ayurvedic_status, set_fassai_ayurvedic_status] = useState({})
    const [disabledInput, setEnableInput] = useState({})
    const [showTNCModal, setShowTNCModal] = useState(false)
    const [termsCondition, setTermsCondition] = useState(false)
    const userData = useSelector(getUserData)
    const [errorDocsList, setErrorDocsList] = useState([])
    const [showPreview, setShowPreview] = useState(false)
    const [previewDocs, setPreviewDocs] = useState([])
    const [formData, setFormData] = useState({})
    const current_onboarding_status = userData?.merchantDetails?.onboarding_status?.kwik_checkout_app
    const [resolvedErrors, setResolvedErrors] = useState<string[]>([])
    const [isPending, setIsPending] = useState(false)
    const [ocrResult, setOcrResult] = useState([])
    const [loadingAI, setLoadingAI] = useState(false)
    const [inputImageName, setInputImageName] = useState(null)
    const handleUploadSuccess = (itemName: string) => {
        setResolvedErrors((prev) => [...prev, itemName])
    }
    const additionalDocsNames = ['additional_document_1', 'additional_document_2', 'additional_document_3']
    const productSpecificDocs = ['fssai_license', 'ayurvedic_license', 'bis_license']

    const isPreviewAllowed = (docsSection) => {
        for (const section of docsSection || []) {
            if (section?.show_preview_document) {
                return true
            }
        }
        return false
    }

    const setPreviewDocuments = (docsSection) => {
        let previews = []
        docsSection?.forEach((section) => {
            if (section?.show_preview_document) {
                previews.push({
                    label: section.label,
                    value:
                        section?.label === 'GST Certificate of the Entity(Front)'
                            ? 'business_registration_proof'
                            : section.name,
                })
            }
        })
        setPreviewDocs(previews)
    }

    useEffect(() => {
        setTermsCondition(userData?.kycData?.accepted_terms_and_conditions)
    }, [userData?.kycData?.accepted_terms_and_conditions])

    const getDocumentList = async () => {
        let res = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.documentList,
            skipLoader: true,
        })
        if (res.data.data[0].other_details?.allowed_options.length === 1) {
            form.setFieldValue('address_type', Object.keys(res.data.data[0].other_details?.allowed_options[0])[0])
        }
        groupByTitle(res.data.data)

        if (userData?.kycData?.kyc_rejection_reason) {
            getKYCDetails(setIsCpvMandatory, setKycData, setCurrentStep, setIsPending)
        }
    }

    function groupByTitle(dataArray) {
        const fassai_ayurvedic_map = dataArray.reduce((acc, doc) => {
            if (productSpecificDocs.includes(doc.name)) {
                acc[`${doc.name}`] = doc.uploaded
            }
            return acc
        }, {})
        set_fassai_ayurvedic_status({ ...fassai_ayurvedic_map })
        setEnableInput({ ...fassai_ayurvedic_map })
        const groupedData = {}

        dataArray.forEach((item) => {
            const title = item.title || 'Address Proof' // 'Others' is used for items without a title

            if (!groupedData[title]) {
                groupedData[title] = [] // Initialize the array if not exist
            }

            groupedData[title].push(item) // Append the item to the array for this title
        })

        setDocumentList(groupedData)
    }
    const renderToolTip = (tooltips) => {
        let tips = ''
        tooltips.map((itm) => {
            itm?.tooltip
            tips = itm?.tooltip ? itm?.tooltip : tips
        })
        return tips
    }

    useEffect(() => {
        getDocumentList()
    }, [])

    const isInputDisabled = (name) => {
        return !disabledInput[name]
    }

    const customRequestHandler = async (options, item) => {
        await customRequest(options, item, form, null, getDocumentList)
        handleUploadSuccess(item.name)
        handleImageChange(options?.file, item)
    }

    const getEntityTypeValue = (item) => {
        let value
        for (let obj of item) {
            if (obj[kycData?.address_type]) {
                value = obj[kycData?.address_type]
                break
            }
        }

        return value
    }

    const deleteFile = async (name) => {
        await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.deleteKycDocument,
            payload: {
                file_name: name,
            },
        }).then((res) => {
            getDocumentList()
        })
    }

    const handleDelete = (item) => {
        setOcrResult(ocrResult?.filter((i) => i?.imageName !== item?.name))
        deleteFile(item.name)
    }

    const getErrors = (item) => {
        const kycErrorList = Object.keys(userData?.kycData?.kyc_failure_reasons)

        if (kycErrorList?.includes(item.name)) {
            return {
                validateStatus: 'error',
                help: Array.isArray(userData?.kycData?.kyc_failure_reasons[item?.name])
                    ? userData?.kycData?.kyc_failure_reasons[item?.name][0]
                    : userData?.kycData?.kyc_failure_reasons[item?.name],
            }
        } else {
            return {
                validateStatus: '',
                help: '',
            }
        }
    }

    useEffect(() => {
        const docItems = [...Object.values(documentList)].flat(1) as { name: string }[]
        const kycErrorList = Object.keys(userData?.kycData?.kyc_failure_reasons)

        const newErrorDocsList: JSX.Element[] = docItems.reduce((acc, item) => {
            if (kycErrorList?.includes(item.name)) {
                acc.push(item.name as any)
            }
            return acc
        }, [] as JSX.Element[])

        const mappedErrorList = newErrorDocsList?.map(
            (item) => kycFieldsMap(userData)?.document_upload?.[item as any] || item,
        )

        setErrorDocsList(mappedErrorList)
    }, [documentList])

    const handleImageUpload = async (image, imageName, setLoadingAI, setInputImageName, userData) => {
        if (!image) return

        setLoadingAI(true)
        setInputImageName(imageName)

        const response = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.openAIOCR,
            method: 'post',
            payload: { file: imageName },
            skipLoader: true,
        })

        if (response?.status === 200) {
            const aiResponse = response?.data?.data?.ai_response
            console.log('AI Response: ', aiResponse?.ai_response)
            setLoadingAI(false)
            return { aiResponse, imageName }
        } else {
            setLoadingAI(false)
            return { aiResponse: null, imageName: null }
        }
    }

    const handleImageChange = async (e, item) => {
        if (
            item?.name === 'pan_card' ||
            item?.name === 'aus_aadhar_card_front_page' ||
            item?.name === 'aus_aadhar_card_back_page' ||
            item?.name === 'aus_pan_card'
        ) {
            const OcrResponse = await handleImageUpload(e, item?.name, setLoadingAI, setInputImageName, userData)

            setOcrResult((prev) => {
                const isDuplicate = prev.some(
                    (entry) =>
                        entry?.imageName === OcrResponse?.imageName &&
                        entry?.aiResponse?.doc_type === OcrResponse?.aiResponse?.doc_type,
                )
                return isDuplicate ? prev : [...prev, OcrResponse]
            })
        }
    }

    const onSubmit = (v) => {
        logEvent(
            'document_upload_save_continue_clicked',
            'click',
            'kyc',
            userData?.userDetails?.email,
            userData?.merchantDetails?.m_id,
        )
        setFormData(v)
        setOpenComplienceModal(true)
    }

    const renderFormItems = (documents: Record<string, any[]>) => {
        return Object.entries(documents).map(([title, items], index) => {
            return (
                <div key={title} className='mb-6'>
                    <div className='flex justify-between items-end'>
                        <div className='flex'>
                            <h3 className='text-base font-semibold mb-2'>
                                {index + 1 + '.' + ' '}
                                {title}
                                <Tooltip title={renderToolTip(documentList[title])}>
                                    <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4] text-base' />
                                </Tooltip>
                            </h3>
                        </div>
                        {isPreviewAllowed(documentList[title]) && (
                            <Button
                                className='flex px-2 text-sm border-solid border-gray-300 rounded-md cursor-pointer justify-center items-center py-0 mb-2'
                                onClick={() => {
                                    setPreviewDocuments(documentList[title])
                                    setShowPreview(true)
                                }}
                            >
                                <EyeOutlined className='m-0' />
                                <span className='ml-2 m-0'>View Sample Document</span>
                            </Button>
                        )}
                    </div>

                    {items.map((item, index) => {
                        if (index === 0 && item.other_details?.allowed_options) {
                            return (
                                <Form.Item key={item.name} name={item.name} rules={[{ required: item.is_required }]}>
                                    <Select
                                        placeholder={`Select ${item.label.toLowerCase()}`}
                                        filterSort={null}
                                        disabled={isFieldEnabled(item?.name)}
                                    >
                                        {item?.other_details?.allowed_options?.length > 1 ? (
                                            item?.other_details?.allowed_options?.map((option, index) => {
                                                for (let key in option) {
                                                    return (
                                                        <Select.Option key={index} value={Number(key)}>
                                                            {option[key]}
                                                        </Select.Option>
                                                    )
                                                }
                                            })
                                        ) : (
                                            <Select.Option
                                                value={Object.keys(item?.other_details?.allowed_options?.[0])[0]}
                                            >
                                                {
                                                    Object.values(
                                                        item?.other_details?.allowed_options?.[0],
                                                    )[0] as React.ReactNode
                                                }
                                            </Select.Option>
                                        )}
                                    </Select>
                                </Form.Item>
                            )
                        } else if (item.other_details?.allowed_format) {
                            return (
                                <div key={item.name}>
                                    <Row
                                        gutter={24}
                                        className='border border-solid border-[#D0D5DA] rounded-md py-2 mx-1 my-4'
                                    >
                                        <Col
                                            span={10}
                                            className='flex flex-col align-middle justify-center items-start'
                                        >
                                            {item.uploaded ? (
                                                <div>
                                                    <UploadedFileComponent
                                                        item={item}
                                                        handleDelete={handleDelete}
                                                        itemName={item.name}
                                                        text={item.label}
                                                        disableDeleteBtn={isFieldEnabled(item?.name)}
                                                        loadingAI={loadingAI}
                                                        inputImageName={inputImageName}
                                                        ocrResult={ocrResult}
                                                    />
                                                    {!resolvedErrors.includes(item.name) && getErrors(item)?.help && (
                                                        <span className='text-red-500 text-sm'>
                                                            Kyc Doc Error : {getErrors(item)?.help}
                                                        </span>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className='ml-6'>
                                                    <span className='inter text-base font-semibold'>{item.label}</span>
                                                    <div className='text-xs text-[#004B8D] mt-1'>
                                                        Accepted Format:{' '}
                                                        {item.other_details.allowed_format.toUpperCase()}
                                                        <br />
                                                        Max size: 5 MB
                                                    </div>

                                                    <Form.Item
                                                        name={item.name}
                                                        rules={[
                                                            {
                                                                required: item.uploaded ? false : item.is_required,
                                                                message: `${item.label} is required`,
                                                            },
                                                        ]}
                                                        required={!item.uploaded}
                                                        {...((item as any)?.validateStatus && {
                                                            validateStatus: (item as any)?.validateStatus,
                                                        })}
                                                        {...((item as any)?.help && {
                                                            help: (item as any)?.help,
                                                        })}
                                                    >
                                                        <Upload
                                                            onChange={(e) => {
                                                                logEvent(
                                                                    item.name + '_' + 'upload_clicked',
                                                                    'click',
                                                                    'kyc',
                                                                    userData?.userDetails?.email,
                                                                    userData?.merchantDetails?.m_id,
                                                                )
                                                            }}
                                                            action={item.upload_url}
                                                            accept={item.other_details.allowed_format
                                                                .split(', ')
                                                                .map((format) => `.${format}`)
                                                                .join(', ')}
                                                            disabled={
                                                                (productSpecificDocs.includes(item.name) ||
                                                                additionalDocsNames.includes(item.name)
                                                                    ? Object.keys(
                                                                          userData?.kycData?.kyc_failure_reasons,
                                                                      )?.includes(item?.name)
                                                                        ? false
                                                                        : isInputDisabled(item.name)
                                                                    : false) || isFieldEnabled(item?.name)
                                                            }
                                                            customRequest={(options) =>
                                                                customRequestHandler(options, {
                                                                    ...item,
                                                                    field: item.name,
                                                                })
                                                            }
                                                            method='PUT'
                                                            listType='text'
                                                            maxCount={1}
                                                        >
                                                            <Button
                                                                disabled={
                                                                    (productSpecificDocs.includes(item.name) ||
                                                                    additionalDocsNames.includes(item.name)
                                                                        ? Object.keys(
                                                                              userData?.kycData?.kyc_failure_reasons,
                                                                          )?.includes(item?.name)
                                                                            ? false
                                                                            : isInputDisabled(item.name)
                                                                        : false) || isFieldEnabled(item?.name)
                                                                }
                                                                variant='primary'
                                                                className='mt-2 text-sm font-normal inter rounded-md shadow-none'
                                                            >
                                                                <UploadOutlined /> Upload Document
                                                            </Button>
                                                            <div className='w-2/3 mt-2'>
                                                                {additionalDocsNames.includes(item.name) &&
                                                                    getErrors(item)?.help && (
                                                                        <span className='text-red-500 text-xs'>
                                                                            Kyc Doc Error : {getErrors(item)?.help}
                                                                        </span>
                                                                    )}
                                                            </div>
                                                        </Upload>
                                                    </Form.Item>
                                                </div>
                                            )}
                                        </Col>

                                        <Col span={8}>
                                            <div className='bg-[#F8F8F8] rounded-md p-1 min-h-40 align-middle justify-center items-center'>
                                                <img src={getPreviewImageForDoc(item.name)} className='flex mx-auto' />
                                            </div>
                                        </Col>
                                        <Col span={6} className='flex flex-col align-top justify-start items-start'>
                                            <div className='inter text-xs font-normal text-[#000000A6] mr-4 ml-[-20px]'>
                                                <ol>
                                                    {item?.format_requirements ? (
                                                        item.format_requirements?.map((item, key) => (
                                                            <li key={key}>{item}</li>
                                                        ))
                                                    ) : (
                                                        <li>{DEFAULT_FORMAT_REQUIREMENTS}</li>
                                                    )}
                                                </ol>
                                            </div>
                                        </Col>

                                        <Col span={24}></Col>
                                    </Row>
                                </div>
                            )
                        } else {
                            return (
                                <Form.Item key={item.name} name={item.name} valuePropName='checked' className='flex'>
                                    <span>{item.label}</span>
                                    <Switch
                                        disabled={
                                            Object.keys(userData?.kycData?.kyc_failure_reasons)?.includes(item?.for)
                                                ? false
                                                : isFieldEnabled(item?.name)
                                        }
                                        className='ml-2'
                                        id={item.name}
                                        onChange={(e) => {
                                            form.setFieldValue(item?.name, e)
                                            setEnableInput((prev) => ({
                                                ...prev,
                                                [item.for]: e,
                                            }))

                                            set_fassai_ayurvedic_status((prev) => ({
                                                ...prev,
                                                [item.for]: e,
                                            }))
                                        }}
                                        checked={
                                            Object.keys(userData?.kycData?.kyc_failure_reasons)?.includes(item?.for) ||
                                            fassai_ayurvedic_status[item.for]
                                        }
                                    />
                                </Form.Item>
                            )
                        }
                    })}
                </div>
            )
        })
    }

    const onChange = (e) => {
        setCurrentStep(4)
    }

    const handleValidation = () => {
        form.validateFields()
            .then((values) => {
                // setOpenComplienceModal(true)
                form.submit()
            })
            .catch((errorInfo) => {
                if (errorInfo.errorFields.length > 0) {
                    const firstErrorField = errorInfo.errorFields[0].name[0]
                    form.scrollToField(firstErrorField, {
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
            })
    }

    const isFieldEnabled = (fieldName) => {
        if (current_onboarding_status === 'kyc_verification_failed') {
            if (Object.keys(userData?.kycData?.kyc_failure_reasons)?.includes(fieldName) || undeterminedKycErrorState) {
                return false //dont-disable-the-field
            } else {
                return true //yes-disable-the-field
            }
        } else if (
            current_onboarding_status === 'kyc_verification_pending' ||
            current_onboarding_status === 'kyc_completed'
        ) {
            return true //yes-disable-the-field
        }
    }

    const onDocsSubmit = () => {
        logEvent(
            'all_documents_submitted',
            'click',
            'kyc',
            userData?.userDetails?.email,
            userData?.merchantDetails?.m_id,
        )
        onFinish(formData, 4)
        sendPriceAgreed()
        setOpenComplienceModal(false)
    }

    return (
        <div className=''>
            <Collapse
                collapsible={isPanelClickable(4)}
                onChange={onChange}
                activeKey={currentStep === 4 && ['1']}
                className='bg-white'
                items={[
                    {
                        key: '1',
                        label: (
                            <div className='flex flex-col gap-1'>
                                <div className='text-[#004B8D] text-xs font-semibold'>Step 5</div>
                                <div className='flex gap-4 items-center'>
                                    <div
                                        className={`text-xl font-semibold ${
                                            errorDocsList?.length &&
                                            userData?.merchantDetails?.onboarding_status?.kwik_checkout_app ===
                                                'kyc_verification_failed' &&
                                            'text-red-700'
                                        }`}
                                    >
                                        Document Upload
                                    </div>
                                    <div>
                                        {errorDocsList?.length > 0 &&
                                        userData?.merchantDetails?.onboarding_status?.kwik_checkout_app ===
                                            'kyc_verification_failed' ? (
                                            <>
                                                {errorDocsList?.map((item, i) => (
                                                    <Tag key={i} variant='error'>
                                                        {additionalDocsNames.includes(item)
                                                            ? 'Additional Document'
                                                            : item}
                                                    </Tag>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {isPending ? (
                                                    <Tag variant='warning'>Pending</Tag>
                                                ) : (
                                                    sectionWiseNonErrorStatusChips(
                                                        stepConfiguration,
                                                        userData?.merchantDetails?.onboarding_status?.kwik_checkout_app,
                                                        isCPVMandatory,
                                                    )?.document_upload
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ),
                        children: (
                            <>
                                <Form
                                    scrollToFirstError={{ behavior: 'auto', block: 'center' }}
                                    form={form}
                                    onFinish={(v) => {
                                        onSubmit(v)
                                    }}
                                    layout='vertical'
                                    initialValues={{ ...kycData }}
                                >
                                    {renderFormItems(documentList)}

                                    <div className='flex justify-between items-end'>
                                        <Form.Item className='flex justify-end mb-0'>
                                            <div className='h-[35px] ml-4 mt-[2.5rem] rounded'>
                                                <Checkbox
                                                    disabled={
                                                        userData?.merchantDetails?.onboarding_status
                                                            ?.kwik_checkout_app === 'kyc_verification_pending' ||
                                                        userData?.merchantDetails?.onboarding_status
                                                            ?.kwik_checkout_app === 'kyc_completed' ||
                                                        (errorDocsList?.length === 0 &&
                                                            userData?.merchantDetails?.onboarding_status
                                                                ?.kwik_checkout_app === 'kyc_verification_failed' &&
                                                            !userData?.kycData?.kyc_rejection_reason)
                                                    }
                                                    checked={form.getFieldValue('accepted_terms_and_conditions')}
                                                    onChange={() => {
                                                        setShowTNCModal(!showTNCModal)
                                                    }}
                                                    className='w-2/3  text-blue-600'
                                                >
                                                    I have reviewed all KYC fields and documents and am ready to submit
                                                    for final review. I accept the Terms & Conditions.
                                                </Checkbox>
                                            </div>
                                        </Form.Item>

                                        {/* <Form.Item className='flex justify-end mb-0'>
                                            
                                        </Form.Item> */}

                                        <Button
                                            type='primary'
                                            onClick={handleValidation}
                                            disabled={
                                                !termsCondition ||
                                                userData?.merchantDetails?.onboarding_status?.kwik_checkout_app ===
                                                    'kyc_verification_pending' ||
                                                userData?.merchantDetails?.onboarding_status?.kwik_checkout_app ===
                                                    'kyc_completed' ||
                                                (errorDocsList?.length === 0 &&
                                                    userData?.merchantDetails?.onboarding_status?.kwik_checkout_app ===
                                                        'kyc_verification_failed' &&
                                                    !userData?.kycData?.kyc_rejection_reason)
                                            }
                                        >
                                            Submit Documents
                                        </Button>
                                    </div>
                                </Form>
                                {showPreview && (
                                    <DocumentPreview
                                        isOpen={showPreview}
                                        setIsOpen={setShowPreview}
                                        previewDocs={previewDocs}
                                        setPreviewDocs={setPreviewDocs}
                                    />
                                )}
                            </>
                        ),
                    },
                ]}
                accordion
                expandIconPosition='right'
                expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
            />

            <Modal
                cancelText={'Decline'}
                okText={'I Agree'}
                centered
                // className='w-1/2'
                width={'45vw'}
                title={
                    <Row className='gap-2'>
                        <CloseCircleOutlined
                            size={50}
                            onClick={() => {
                                form.setFieldValue('accept_terms_and_conditions', false)
                                setTermsCondition(false)
                                setShowTNCModal(false)
                            }}
                        />
                        <p>Terms and Condtions</p>
                    </Row>
                }
                open={showTNCModal}
                closeIcon={null}
                onOk={() => {
                    logEvent(
                        'basic_info_tc_agree_clicked',
                        'click',
                        'kyc',
                        userData?.userDetails?.email,
                        userData?.merchantDetails?.m_id,
                    )
                    form.setFieldValue('accepted_terms_and_conditions', true)
                    setTermsCondition(true)
                    setShowTNCModal(false)
                }}
                onCancel={() => {
                    logEvent(
                        'basic_info_tc_decline_clicked',
                        'click',
                        'kyc',
                        userData?.userDetails?.email,
                        userData?.merchantDetails?.m_id,
                    )
                    form.setFieldValue('accepted_terms_and_conditions', false)
                    setTermsCondition(false)
                    setShowTNCModal(false)
                }}
            >
                <div className='max-h-[70vh] overflow-auto'>
                    <div className='terms' id='terms' dangerouslySetInnerHTML={{ __html: Terms }}></div>
                </div>
            </Modal>
            <ComplienceModal
                isOpen={openComplienceModal}
                setIsOpen={setOpenComplienceModal}
                complienceAgreed={complienceAgreed}
                setComplienceAgreed={setComplienceAgreed}
                onKycSubmit={onDocsSubmit}
            />
        </div>
    )
}

export default DocumentsUpload
