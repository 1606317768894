import React from 'react'

export default function VerifiedUser() {
    return (
        <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='0.5' y='0.5' width='43' height='43' rx='15.5' fill='white' fill-opacity='0.24' />
            <rect x='0.5' y='0.5' width='43' height='43' rx='15.5' stroke='url(#paint0_linear_6250_24484)' />
            <g clip-path='url(#clip0_6250_24484)'>
                <path
                    d='M16 31V29C16 27.9391 16.4214 26.9217 17.1716 26.1716C17.9217 25.4214 18.9391 25 20 25H22'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M32 26C32 30 29.5 32 28.5 32C27.5 32 25 30 25 26C26 26 27.5 25.5 28.5 24.5C29.5 25.5 31 26 32 26Z'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M18 17C18 18.0609 18.4214 19.0783 19.1716 19.8284C19.9217 20.5786 20.9391 21 22 21C23.0609 21 24.0783 20.5786 24.8284 19.8284C25.5786 19.0783 26 18.0609 26 17C26 15.9391 25.5786 14.9217 24.8284 14.1716C24.0783 13.4214 23.0609 13 22 13C20.9391 13 19.9217 13.4214 19.1716 14.1716C18.4214 14.9217 18 15.9391 18 17Z'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
            </g>
            <defs>
                <linearGradient
                    id='paint0_linear_6250_24484'
                    x1='0.848501'
                    y1='2.2083'
                    x2='41.5344'
                    y2='34.45'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#91D0FF' stop-opacity='0.12' />
                    <stop offset='1' stop-color='#0094D5' stop-opacity='0.12' />
                </linearGradient>
                <clipPath id='clip0_6250_24484'>
                    <rect width='24' height='24' fill='white' transform='translate(10 10)' />
                </clipPath>
            </defs>
        </svg>
    )
}
