import React, { useEffect, useRef, useState } from 'react'
import { Card } from 'gokwik-ui-kit'
import { CheckOutlined } from 'gokwik-ui-kit'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import GokwikLogo from '@library/images/common/logo-gokwik-business-suit.svg'
import Graph from '@library/images/icons/Graph.svg'
const BasicPlanCard = () => {
    return (
        <Card bordered className='mt-11 pb-3' style={{ borderRadius: 10, height: 'calc(100% - 70px)' }}>
            <div className='flex flex-col between' style={{ overflowY: 'auto', height: 'calc(100vh - 180px)' }}>
                <div>
                    <img src={GokwikLogo} alt='gokwik loo' />
                </div>
                <div style={{ marginTop: 'auto' }}>
                    <div>
                        <h1 className='m-0 font-[800]'>Upgrade to</h1>
                        <h1 className='m-0 font-[800]' style={{ color: '#004B8D' }}>
                            Advance plan <img src={Graph} width={'28px'} />{' '}
                        </h1>
                        <p style={{ margin: '10px 0' }}>Your current plan: Basic</p>
                    </div>
                    <div className='p-5 border-solid border-gray-300 rounded-md'>
                        <div style={{ margin: '10px 0', fontWeight: '700' }}>
                            <p style={{ fontSize: '12px', color: '#667085' }}>Basic Plan </p>
                            <p style={{ fontSize: '24px' }}>COD Suite</p>
                        </div>

                        <p className='ant-typography' style={{ fontWeight: 'bold', margin: '5px 0' }}>
                            COD Features
                        </p>
                        <div
                            style={{
                                backgroundColor: '#F9FAFB',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '6px',
                                fontSize: '14px',
                                padding: '10px',
                                borderRadius: '10px'
                            }}
                        >
                            <div className='ant-list-item'>
                                <CheckOutlined style={{ color: '#52c41a', fontSize: '14px' }} />{' '}
                                <span style={{ marginLeft: 8 }}>Enable COD Orders</span>
                            </div>
                            <div className='ant-list-item'>
                                <CheckOutlined style={{ color: '#52c41a', fontSize: '14px' }} />{' '}
                                <span style={{ marginLeft: 8 }}>COD Interventions like COD OTP for Extra Security</span>
                            </div>
                            <div className='ant-list-item'>
                                <CheckOutlined style={{ color: '#52c41a', fontSize: '14px' }} />{' '}
                                <span style={{ marginLeft: 8 }}>
                                    Show/Hide Payment Based on Cart, Customers, Products, Location and More
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default BasicPlanCard
