import React from 'react';
import ReactDOM from 'react-dom';
import Root from './root.component';
import singleSpaReact from 'single-spa-react';
import { BrowserRouter } from 'react-router-dom';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: (props) => (
    <BrowserRouter>
      <Root {...props} />
    </BrowserRouter>
  ),
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
