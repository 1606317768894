// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(.css-dev-only-do-not-override-17we33g).ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    display: none;
}

:where(.css-dev-only-do-not-override-17we33g).ant-steps
    .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    display: none;
}

.onboarding-steps
    :where(.css-dev-only-do-not-override-17we33g).ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-description {
    display: none;
}

.onboarding-steps .ant-steps-item-description {
    display: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/onboarding/new-pages/onboarding.css"],"names":[],"mappings":"AAAA;;;;;IAKI,aAAa;AACjB;;AAEA;;;;;IAKI,aAAa;AACjB;;AAEA;;;;;;IAMI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[":where(.css-dev-only-do-not-override-17we33g).ant-steps\n    .ant-steps-item-finish\n    > .ant-steps-item-container\n    > .ant-steps-item-content\n    > .ant-steps-item-description {\n    display: none;\n}\n\n:where(.css-dev-only-do-not-override-17we33g).ant-steps\n    .ant-steps-item-wait\n    > .ant-steps-item-container\n    > .ant-steps-item-content\n    > .ant-steps-item-description {\n    display: none;\n}\n\n.onboarding-steps\n    :where(.css-dev-only-do-not-override-17we33g).ant-steps\n    .ant-steps-item-process\n    > .ant-steps-item-container\n    > .ant-steps-item-content\n    > .ant-steps-item-description {\n    display: none;\n}\n\n.onboarding-steps .ant-steps-item-description {\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
