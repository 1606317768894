export const ArrowCircleDown = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='vuesax/linear/arrow-circle-down'>
                <g id='arrow-circle-down'>
                    <path
                        id='Vector'
                        d='M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2Z'
                        stroke='#002D55'
                        stroke-width='1.5'
                        stroke-miterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <path
                        id='Vector_2'
                        d='M8.47021 13.2617L12.0002 9.74172L15.5302 13.2617'
                        stroke='#002D55'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                </g>
            </g>
        </svg>
    )
}
