export const ArrowLeftIcon = () => {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='vuesax/linear/arrow-left'>
                <g id='arrow-left'>
                    <path
                        id='Vector'
                        d='M7.97484 4.94531L2.9165 10.0036L7.97484 15.062'
                        stroke='#002547'
                        stroke-width='2'
                        stroke-miterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <path
                        id='Vector_2'
                        d='M17.0831 10H3.05811'
                        stroke='#002547'
                        stroke-width='2'
                        stroke-miterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                </g>
            </g>
        </svg>
    )
}
