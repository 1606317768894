import {
    Table,
    ColumnsType,
    Button,
    Row,
    Col,
    Breadcrumb,
    Popconfirm,
    DatePicker,
    Modal,
    Tooltip,
    EyeOutlined,
    FileAddOutlined,
    FormOutlined,
    BackwardFilled,
    ArrowLeftOutlined,
    message,
    Tag,
    Checkbox,
    Form,
} from 'gokwik-ui-kit'
// import AgreementForm from './agreement/agreementForm';
import { Children, useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { makeAPICall, updateBreadcrumbs, validatePermission } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import AgreementForm from './agrrementForm'
import { PermissionValues } from '@library/utilities/constants/constants'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

const RateCapture = ({}) => {
    const [openForm, setOpenForm] = useState('')
    const [mode, setMode] = useState(null)
    const [agreementId, setAgreementId] = useState(null)
    const [addendumId, setAddendumId] = useState(null)
    const [data, setData] = useState([])
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)
    const [latestConfirmed, setLatestConfirmed] = useState(null)
    const [latestConfirmedType, setLatestConfirmedType] = useState(null)
    const [pricingReports, setOpenPricingReport] = useState(false)
    const [newestConfirmedMap, setNewestConfirmedMap] = useState(null)
    const [downloadSelectedMerchantDataOnly, setDownloadSelectedMerchantDataOnly] = useState(false)
    const [downloadWithRawData, setDownloadWithRawData] = useState(false)

    const [reportDateRange, setReportDateRange]: any = useState({
        end_date: '',
        start_date: '',
    })
    const [form] = Form.useForm()

    const columns = [
        {
            title: 'Agreement / Addendum',
            dataIndex: 'file_name',
            key: 'name',
            render: (text, record) => (
                <p>
                    <span
                        onClick={() => {
                            window.open(record.signed_url, '_blank')
                        }}
                        className='cursor-pointer hover:text-blue-400'
                    >
                        {text}
                    </span>{' '}
                    {newestConfirmedMap?.includes(record.id) && (
                        <Tag
                            variant={'success'}
                            className='px-1 py-0 rounded-sm inter font-medium inline-block text-xs'
                        >
                            Latest
                        </Tag>
                    )}
                    {record?.status === 'draft' && (
                        <Tag
                            variant={'warning'}
                            className='px-1 py-0 rounded-sm inter font-medium inline-block text-xs'
                        >
                            Draft
                        </Tag>
                    )}
                </p>
            ),
            width: 40,
        },
        {
            title: 'Products Purchased',
            dataIndex: 'products_enabled',
            key: 'products_enabled',
            render: (text, record) => (
                <p>
                    {record.products_enabled?.map((itm, i, array) => (
                        <span key={itm} className='mr-1'>
                            {itm}
                            {array.length - 1 !== i ? ',' : ''}
                        </span>
                    ))}
                </p>
            ),
            width: 25,
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            render: (text, record) => <p>{dayjs(text).format('DD/MM/YYYY')}</p>,
            width: 10,
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'endDate',
            render: (text, record) => <p>{dayjs(text).format('DD/MM/YYYY')}</p>,
            sorter: (a, b) => dayjs(a.end_date).diff(b.end_date),
            width: 10,
        },
        {
            title: 'Created By',
            dataIndex: 'created_by',
            key: 'created_by',
            width: 10,
        },
        {
            title: 'Leegality Status',
            dataIndex: 'leegality_status',
            key: 'leegality_status',
            width: 20,
            render: (status: string) => {
                return status?.length > 0 ? (
                    <span>
                        <Tag
                            variant={
                                status.toLowerCase() === 'completed'
                                    ? 'success'
                                    : status.toLowerCase() === 'pending'
                                    ? 'warning'
                                    : 'default'
                            }
                            className='!px-3 !py-1 rounded-md inter font-medium inline-block capitalize text-xs'
                        >
                            {status}
                        </Tag>
                    </span>
                ) : (
                    <Tag
                        variant={'default'}
                        className='!px-3 !py-1 rounded-md inter font-medium inline-block capitalize text-xs'
                    >
                        NA
                    </Tag>
                )
            },
        },
        {
            title: 'Action',
            key: 'action',
            width: 40,
            render: (text, record) => (
                <Row className='text-black flex justify-start gap-3'>
                    <Col span={'5'}>
                        <Tooltip
                            color='white'
                            overlayClassName='inter font-semibold text-black text-sm lh-18'
                            overlayInnerStyle={{
                                color: '#000000',
                            }}
                            title={'View'}
                        >
                            <span
                                className='cursor-pointer'
                                onClick={() => {
                                    setMode('view')
                                    setOpenForm(record.isAddendum ? 'View addendum' : 'View agreement')
                                    setAgreementId(record.isAddendum ? record.parent_id : record.id)
                                    setLatestConfirmed(record.id)
                                    setLatestConfirmedType(record.type)
                                    record.isAddendum && setAddendumId(record.id)
                                }}
                            >
                                <EyeOutlined className='text-black hover:text-blue-300' />
                            </span>
                        </Tooltip>
                    </Col>
                    {record?.status === 'draft' && validatePermission(PermissionValues.rate_capture.edit) && (
                        <Col span={'5'} offset={4}>
                            <Tooltip
                                color='white'
                                overlayClassName='inter font-semibold text-black text-sm lh-18'
                                overlayInnerStyle={{
                                    color: '#000000',
                                }}
                                title={'Edit'}
                            >
                                <span
                                    className=' cursor-pointer'
                                    onClick={() => {
                                        setMode('edit')
                                        setOpenForm(record.isAddendum ? 'Edit addendum' : 'Edit agreement')
                                        setAgreementId(record.isAddendum ? record.parent_id : record.id)
                                        setLatestConfirmed(record.id)
                                        setLatestConfirmedType(record.type)
                                        record.isAddendum && setAddendumId(record.id)
                                    }}
                                >
                                    <FormOutlined className='text-black hover:text-blue-300' />
                                </span>
                            </Tooltip>
                        </Col>
                    )}
                    {record?.status === 'confirmed' &&
                        newestConfirmedMap?.includes(record.id) &&
                        !hasChildren(record.id) &&
                        validatePermission(PermissionValues.rate_capture.edit) && (
                            <Col span={'5'} offset={4}>
                                <Tooltip
                                    color='white'
                                    overlayClassName='inter font-semibold text-black text-sm lh-18'
                                    overlayInnerStyle={{
                                        color: '#000000',
                                    }}
                                    title={'Add addendum'}
                                >
                                    <span
                                        className='cursor-pointer'
                                        onClick={() => {
                                            setOpenForm('Add addendum')
                                            setAgreementId(record.id)
                                            setLatestConfirmed(record.id)
                                            setLatestConfirmedType(record.type)
                                        }}
                                    >
                                        <FileAddOutlined className='text-black hover:text-blue-300' />
                                    </span>
                                </Tooltip>
                            </Col>
                        )}
                </Row>
            ),
        },
        {
            title: 'Updated By',
            dataIndex: 'updated_by',
            key: 'updated_by',
            width: 10,
        },
    ]

    useEffect(() => {
        logEvent(
            'admin_rate_capture_clicked',
            'click',
            'Admin Rate Capture',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
        )
    }, [])

    const saveAgreement = async () => {
        if (!latestConfirmed) {
            return
        }

        if (latestConfirmedType === 'agreement') {
            try {
                await makeAPICall({
                    method: 'get',
                    url: process.env.REACT_APP_BASE_URL + APIEndPoints.legalityESign,
                })
            } catch (error) {
                console.log('Error while making leegality e-sign request', error)
            }
        }

        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.rateCapture.agreement,
            payload: {
                id: latestConfirmed,
                status: 'confirmed',
            },
        })
        if (response.success) {
            setAgreementId('')
            setAddendumId('')
            setLatestConfirmed(null)
            setLatestConfirmedType(null)
            setOpenForm(null)
            setMode('')
        }
    }

    const fetchAgreements = useCallback(async () => {
        const response = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.rateCapture.agreement,
            method: 'get',
        })
        if (response.success) {
            processFetchedData(response.data.data)
        }
    }, [merchantDetails.id])

    const processFetchedData = (data) => {
        const addendums = data.reduce(
            (result, itm) => ({
                ...result,
                [itm.parent_id]: [...(result[itm.parent_id] || []), { ...itm, key: itm.id, isAddendum: true }],
            }),
            {},
        )

        const processedData = buildNestedStructure(data, addendums)

        setLatestConfirmedChildrens(processedData)
        setData(processedData)
    }

    function hasChildren(id) {
        const findNode = (nodes, nodeToFind) => {
            for (const node of nodes) {
                if (node.id === nodeToFind) {
                    return node
                }

                if (node?.children?.length > 0) {
                    const foundNode = findNode(node.children, nodeToFind)

                    if (foundNode) {
                        return foundNode
                    }
                }
            }

            return null
        }

        const node = findNode(data, id)

        return node?.children?.length > 0
    }

    const setLatestConfirmedChildrens = (processedData) => {
        let result = []

        const findDeepest = (node) => {
            let currentNode = node

            while (currentNode?.children?.length > 0) {
                const confirmedChild = currentNode.children.find((item) => item.status === 'confirmed')

                if (confirmedChild) {
                    currentNode = confirmedChild
                } else {
                    break
                }
            }

            return currentNode.id
        }

        processedData.forEach((item) => {
            if (item.status === 'confirmed') {
                result.push(findDeepest(item))
            }
        })
        setNewestConfirmedMap(result)
    }

    function buildNestedStructure(data, addendums) {
        const buildChildren = (parent_id) => {
            return addendums[parent_id]?.map((child) => ({
                ...child,
                children: buildChildren(child.id),
                key: child.id,
            }))
        }

        return data
            ?.filter((item) => !item?.parent_id)
            ?.map((root) => ({
                ...root,
                children: buildChildren(root.id),
                key: root.id,
            }))
    }

    useEffect(() => {
        !openForm && fetchAgreements()
    }, [openForm])

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'rateCapture',
                href: '/rateCapture',
                text: 'Rate Capture',
            },
        ])
    }, [])

    const handleOk = () => {
        form.resetFields()
        setOpenPricingReport(false)
        fetchReport()
    }

    const handleCancel = () => {
        form.resetFields()
        setOpenPricingReport(false)
    }

    const fetchReport = async () => {
        if (!reportDateRange.start_date || !reportDateRange.end_date) return message.error('Date range not valid')
        const start_date = dayjs(reportDateRange.start_date).format('YYYY-MM-DD')
        const end_date = dayjs(reportDateRange.end_date).format('YYYY-MM-DD')
        const response = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.billing.pricingReport,
            method: 'get',
            params: {
                start_date,
                end_date,
                selected_merchant_only: downloadSelectedMerchantDataOnly,
                include_raw_data: downloadWithRawData,
            },
            responseType: 'blob',
        })

        if (response.success) {
            const href = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = href
            link.setAttribute('download', `merchant_pricing-${start_date}-${end_date}.xlsx`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            window.URL.revokeObjectURL(href)
        }
    }

    return (
        <div className='w-full'>
            <Row className='flex  mb-2'>
                {openForm && (
                    <Row className='w-full bg-white rounded-lg p-3 py-3 flex items-center border-solid border-gray-200 border-[1px] '>
                        <Col span={20}>
                            <p
                                className='text-base text-gray-500 cursor-pointer'
                                onClick={() => {
                                    setOpenForm(null)
                                    setMode(null)
                                    setAgreementId(null)
                                    setAddendumId(null)
                                    setLatestConfirmed(null)
                                    setLatestConfirmedType(null)
                                }}
                            >
                                <ArrowLeftOutlined /> Agreements
                            </p>
                        </Col>
                        {openForm && mode !== 'view' && (
                            <Col span={4} className='flex justify-end'>
                                <Popconfirm
                                    title='No changes in agreement will be allowed post confirmation?'
                                    onConfirm={() => {
                                        saveAgreement()
                                    }}
                                >
                                    <Button type='primary' disabled={!latestConfirmed}>
                                        Confirm
                                    </Button>
                                </Popconfirm>
                            </Col>
                        )}
                    </Row>
                )}
            </Row>
            {openForm ? (
                <AgreementForm
                    mode={mode}
                    merchantId={merchantDetails.m_id}
                    id={agreementId}
                    pageAddendumId={addendumId}
                    setPageAgreementId={setAgreementId}
                    setPageAddendumId={setAgreementId}
                    setLatestConfirmed={setLatestConfirmed}
                    setLatestConfirmedType={setLatestConfirmedType}
                    latestConfirmed={latestConfirmed}
                    type={openForm.includes('addendum') ? 'addendum' : null}
                />
            ) : (
                <>
                    {validatePermission(PermissionValues.rate_capture.edit) && (
                        <Row className='w-full bg-white rounded-lg p-3 py-3 flex items-center border-solid border-gray-200 border-[1px]'>
                            <Col className='flex justify-end mr-2'>
                                <Button type='primary' onClick={() => setOpenForm('Add agreement')}>
                                    <span className='mr-1 text-sm'> + </span>
                                    Add agreement
                                </Button>
                            </Col>
                            {validatePermission(PermissionValues.billing_reports.view) && (
                                <Col>
                                    <Button variant='default' onClick={() => setOpenPricingReport(true)}>
                                        Pricing Report
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    )}

                    <div className='mt-3 bg-white rounded overflow-clip'>
                        <Table
                            className='w-full'
                            columns={columns}
                            dataSource={data || []}
                            pagination={{
                                position: ['topLeft', 'bottomLeft'],
                                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                            }}
                            scroll={{ x: 'max-content' }}
                        />
                    </div>
                </>
            )}
            <Modal
                open={pricingReports}
                title=''
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key='back' onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key='submit' type='primary' onClick={handleOk}>
                        Download
                    </Button>,
                ]}
            >
                <Form layout='vertical' className='h-full w-full' form={form}>
                    <div className='my-4'>
                        <p className='text-xl font-semibold '>Pricing Report</p>
                        <p className='text-base my-2'>Download report of pricing for specific date range</p>
                    </div>
                    <Form.Item name={'date_range'}>
                        <DatePicker.RangePicker
                            className='w-full my-2'
                            onChange={(val) => {
                                setReportDateRange((prev) => ({ ...prev, end_date: val[1], start_date: val[0] }))
                            }}
                            format={'DD/MM/YYYY'}
                        />
                    </Form.Item>
                    <div className='mt-2'>
                        <Checkbox
                            className='text-xs'
                            value={downloadSelectedMerchantDataOnly}
                            onChange={() => {
                                setDownloadSelectedMerchantDataOnly((prev) => !prev)
                            }}
                        >
                            Select here to download report of pricing for selected merchant only
                        </Checkbox>
                        <Checkbox
                            className='text-xs'
                            value={downloadWithRawData}
                            onChange={() => {
                                setDownloadWithRawData((prev) => !prev)
                            }}
                        >
                            Select here to download report of pricing with raw data
                        </Checkbox>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

export default RateCapture
