export default function KwikRTO() {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_828_8403)'>
                <path
                    d='M12 21L4 16.5V7.5L12 3L20 7.5V19H16'
                    stroke='white'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M17 17L15 19L17 21'
                    stroke='white'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M12 12L20 7.5'
                    stroke='white'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path d='M12 12V21' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round' />
                <path
                    d='M12 12L4 7.5'
                    stroke='white'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_828_8403'>
                    <rect width='24' height='24' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}