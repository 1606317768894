import { Layout, Col, Row, Button } from 'gokwik-ui-kit'
import lock from '@library/images/icons/lock.svg'
import check from '@library/images/icons/check.svg'
import sparkles from '@library/images/icons/sparkles.svg'
import rightArrow from '@library/images/icons/arrow-right.svg'
import number1 from '@library/images/icons/circle-1.svg'
import number2 from '@library/images/icons/circle-2.svg'
import closeIcon from '@library/images/icons/close.png'
import addressPreFill from '@library/images/icons/addressPrefill.mp4'
import { useEffect, useState } from 'react'
import CongratsCard from '../CommonComponents/congratulationsCard'
import { updateMerchantOnboardingCheckoutConfigsStatus } from '../CommonComponents/Utils';
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchUserDetails } from '@store/user'
import { useAppDispatch } from '@library/utilities/hooks'
import { navigateToUrl } from 'single-spa'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

const { Content } = Layout

const data = [
    {
        heading: 'Immediate Access',
        object: [
            {
                icon: check,
                text: 'Address Autofill for ~80%* of Users',
                isRightIcon: true,
            },
            {
                icon: check,
                text: 'Showcase Discount Coupons at Checkout',
                isRightIcon: false,
            },
        ],
    },
    {
        heading: 'Post KYC Access',
        object: [
            {
                icon: lock,
                text: 'Partial Payment on COD to Collect Upfront Amount',
                isRightIcon: false,
                isLock: true,
            },
            {
                icon: lock,
                text: 'Prepaid Payments via UPI, Net-Banking, Wallets, Cards, BNPL and More',
                isRightIcon: false,
                isLock: true,
            },
            {
                icon: lock,
                text: 'Discounts on Prepaid Orders',
                isRightIcon: false,
                isLock: true,
            },
        ],
    },
]

const Card = ({ heading, object }) => {

    return (
        <div className='py-2 px-8'>
            <p className='font-inter font-semibold text-[12px] leading-[20px] mb-3'>{heading}</p>
            {object?.map((item, idx) => (
                <div
                    key={idx}
                    className={`flex items-center mb-2 ${
                        item.isRightIcon
                            ? 'w-[265px] bg-gradient-to-r from-[rgba(245,250,255,0.24)] to-[rgba(132,202,255,0.24)]'
                            : ''
                    } rounded-md py-1 px-1`}
                >
                    <img src={item.icon} alt='check icon' className='w-4 h-4 mr-2' />
                    <p style={{ color: item?.isLock ? 'rgba(0, 0, 0, 0.50)' : '#000' }} className="font-inter font-normal text-[14px] leading-[20px]">{item.text}</p>
                    {item.isRightIcon && <img src={sparkles} alt='right icon' className='w-4 h-4 ml-2' />}
                </div>
            ))}
        </div>
    )
}

export default function EnableGokwikCheckout() {
    const [showPopUp, setShowPopUp] = useState(false)
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const impChecksPassed = () => {
        logEvent(
            "start_checkout_setup_clicked",
            'click',
            'cod-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
        )
        updateMerchantOnboardingCheckoutConfigsStatus('imp_checks_passed', () => {
            dispatch(fetchUserDetails());
            navigate('/onboarding/checkout-setup');
        }, merchantDetails)
    }

    useEffect(() => {
        logEvent(
            "cod_congratulations_page_landed",
            'click',
            'cod-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
        )
        if (merchantDetails?.country && merchantDetails?.country?.toLowerCase() !== 'india') {
            navigateToUrl('/shopify-app/settings')
        }
    }, [])
    

    return (
        <Layout>
            <Content>
                <Row gutter={16} style={{ height: '100%', background: '#FFFFFF', paddingBottom: '2.2rem' }}>
                    <div className='flex flex-col h-full gap-3 px-44'>
                        <CongratsCard text="GoKwik COD (Cash On Delivery) is live on your site"/>
                        <div
                            className='flex items-center justify-center px-10 pt-6 pb-4 gap-4'
                            style={{ border: '1px solid #E5E7EB', borderRadius: '6px' }}
                        >
                            <div className='flex flex-col justify-between h-full w-1/2'>
                                <div className='flex flex-col'>
                                    <p>Next Step</p>
                                    <div className='flex items-center gap-2'>
                                        <div className='font-inter font-bold text-[24px] leading-[32px] tracking-[0em]'>
                                            Enable GoKwik Checkout
                                        </div>
                                        <p className='text-[#006ED2] text-[12px] font-bold w-[135px] rounded-md py-1 bg-gradient-to-r from-[#E6F4FF00] to-[#91CAFF99]'>
                                            30 Days free trial*
                                        </p>
                                    </div>
                                </div>
                                <div style={{ background: 'rgba(252, 252, 253, 1)' }} className='my-6'>
                                    {data?.map((item, index) => (
                                        <Card key={index} heading={item.heading} object={item.object} />
                                    ))}
                                </div>
                                <div className='flex flex-col gap-2 items-center'>
                                    <Button
                                        variant='primary'
                                        className='p-5 flex items-center justify-center font-semibold text-[16px] leading-[24px] gap-2 mt-2'
                                        onClick={() => {
                                            setShowPopUp(true)
                                            logEvent(
                                                "complete_checkout_setup_clicked",
                                                'click',
                                                'cod-setup',
                                                userDetails?.email,
                                                merchantDetails?.m_id,
                                                merchantDetails?.short_name,
                                                userDetails?.name,
                                            )
                                        }}
                                    >
                                        <p>Continue Checkout Setup</p>
                                        <img src={rightArrow} alt='right arrow' />
                                    </Button>
                                    <button
                                        className='p-2 flex items-center justify-center !border-none shadow-none bg-[#FFFFFF] !text-inherit focus:outline-none'
                                        onClick={() => {
                                            logEvent(
                                                "skip_checkout_setup_clicked",
                                                'click',
                                                'cod-setup',
                                                userDetails?.email,
                                                merchantDetails?.m_id,
                                                merchantDetails?.short_name,
                                                userDetails?.name,
                                            )
                                            updateMerchantOnboardingCheckoutConfigsStatus('imp_checks_passed', () => {
                                                dispatch(fetchUserDetails());
                                                navigateToUrl('/shopify-app/settings');
                                            }, merchantDetails)
                                        }}
                                    >
                                        <p className='text-[#1677FF]'>Skip for now, explore dashboard</p>
                                    </button>
                                </div>
                                {showPopUp && (
                                    <div
                                        className='absolute top-[36%] left-[34%] transform -translate-x-1/2 flex flex-col w-[340px] bg-white shadow-[0px_4px_12px_rgba(0,0,0,0.15)]'
                                        style={{ borderRadius: '8px' }}
                                    >
                                        <div className='bg-gradient-to-b from-[#A9C3D9] to-white px-2 pt-2 rounded-lg'>
                                            <div
                                                className='flex justify-end cursor-pointer'
                                                onClick={() => setShowPopUp(false)}
                                            >
                                                <img src={closeIcon} alt='close icon' width={16} height={16} />
                                            </div>
                                            <p className='font-inter font-semibold text-[20px] leading-[28px] text-center text-[#004B8D] px-8 py-4'>
                                                Enjoy GoKwik Checkout Free for 30 Days!
                                            </p>
                                        </div>
                                        <div className='flex flex-col px-5 pt-2 pb-6 gap-4'>
                                            <div className='flex gap-2'>
                                                <div className='flex'>
                                                    <img src={number1} alt='number 1' width={24} height={24} />
                                                </div>
                                                <p className='font-inter font-medium text-[14px] leading-[22px]'>
                                                    Unlimited free Checkouts for the first 30 days or until KYC completion (whichever comes first).
                                                </p>
                                            </div>
                                            <div className='flex gap-2'>
                                                <div className='flex'>
                                                    <img src={number2} alt='number 2' width={24} height={24} />
                                                </div>
                                                <p className='font-inter font-medium text-[14px] leading-[22px]'>
                                                    <b>Charges after the trial period:</b>
                                                    <ul className='pl-4'>
                                                        <li><b>Completed KYC:</b> 2.5% charges (including payment gateway charges) applies to all prepaid orders.</li>
                                                        <li><b>Incomplete KYC:</b> 2.5% charges applies to all prepaid orders + your payment gateway charges.</li>
                                                    </ul>
                                                </p>
                                            </div>
                                            <Button
                                                variant='primary'
                                                className='p-5 flex items-center justify-center gap-2 font-semibold text-[16px] leading-[24px] mt-4'
                                                onClick={impChecksPassed}
                                            >
                                                <p>Start checkout Setup</p>
                                                <img src={rightArrow} alt='right arrow' />
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='flex items-center justify-center w-1/2'>
                                <div style={{ minWidth: '90%', marginTop: '1rem', boxSizing: 'border-box' }}>
                                    <video
                                        className='mb-10'
                                        src={addressPreFill}
                                        autoPlay
                                        loop
                                        muted
                                        style={{ width: '90%', borderRadius: '8px' }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </Content>
        </Layout>
    )
}