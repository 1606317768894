import { Card, ColumnsType, ReactApexChart, Table } from 'gokwik-ui-kit'
import { memo } from 'react'

const PlatformWiseChart = ({ platformWiseCount }) => {
    const {
        checkout_1,
        checkout_1_and_custom,
        checkout_1_and_custom_and_kwik_checkout,
        checkout_1_and_kwik_checkout,
        custom,
        custom_and_kwik_checkout,
        kwik_checkout,
    } = platformWiseCount || {}

    const tableData = [
        {
            checkout_1: checkout_1 || 0,
            kwik_checkout: kwik_checkout || 0,
            custom: custom || 0,
            checkout_1_and_kwik_checkout: checkout_1_and_kwik_checkout || 0,
            checkout_1_and_custom: checkout_1_and_custom || 0,
            checkout_1_and_custom_and_kwik_checkout: checkout_1_and_custom_and_kwik_checkout || 0,
            custom_and_kwik_checkout: custom_and_kwik_checkout || 0,
        },
    ]

    const transformedData = [
        { platform: 'Checkout 1', count: tableData[0].checkout_1 },
        { platform: 'Kwik Checkout', count: tableData[0].kwik_checkout },
        { platform: 'Custom', count: tableData[0].custom },
        { platform: 'Checkout1 & KwikCheckout', count: tableData[0].checkout_1_and_kwik_checkout },
        { platform: 'Checkout1 & Custom', count: tableData[0].checkout_1_and_custom },
        { platform: 'Checkout1 & Custom & KwikCheckout', count: tableData[0].checkout_1_and_custom_and_kwik_checkout },
        { platform: 'Custom & KwikCheckout', count: tableData[0].custom_and_kwik_checkout },
    ]?.filter((item) => item.count > 0)

    const columns: ColumnsType<any> = [
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
            width: 200,
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
            sorter: (a, b) => a.count - b.count,
            defaultSortOrder: 'descend',
            width: 100,
        },
    ]

    return (
        <Card className='overflow-x-auto h-[327px]' title='Platform-wise merchants count'>
            <Table
                scroll={{
                    x: 300,
                    y: 250,
                }}
                columns={columns}
                dataSource={transformedData}
                pagination={false}
            />
        </Card>
    )
}

export default memo(PlatformWiseChart, (prevProps, nextProps) => {
    return JSON.stringify(prevProps?.platformWiseCount) === JSON.stringify(nextProps?.platformWiseCount)
})
