import { makeAPICall, filterDateFormatter } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import {
    Button,
    Col,
    Drawer,
    Input,
    Row,
    Select,
    Space,
    TextArea,
    Tooltip,
    message,
    DatePicker,
    TimePicker,
    Modal,
    Form,
} from 'gokwik-ui-kit'
import { ReactNode, useEffect, useState } from 'react'
import dayjs from 'dayjs'
interface Props {
    open: boolean
    title: string
    onClose: (text: string) => void
    recordData: any
    type: string
    isNew: boolean
    roleList: any
    action: String
}

interface NotificationsDetails {
    name: string | null
    email: string
    id: string | null
    status: string | null
    role_id: string | null
    phone: string | null
    updated_at: string | null
}
const today = dayjs()
const formattedDate = filterDateFormatter(today)
const EditNotificationsDrawer = ({ open, title, onClose, type, recordData, isNew, roleList, action }: Props) => {
    const [data, setData] = useState<NotificationsDetails>({
        name: recordData?.name || '',
        email: recordData?.email || '',
        phone: recordData?.phone || '',
        id: recordData?.id || '',
        status: recordData?.status,
        role_id: recordData?.role_id || '',
        updated_at: recordData?.updated_at || '',
    })
    const [proceed, setProceed] = useState(false)

    function validateEmail(mail) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return true
        }

        return false
    }

    function isValidPhoneNumber(phoneNumber) {
        const phonePattern = /^\d{10}$/
        return phonePattern.test(phoneNumber)
    }
    function isValidRole(role_id) {
        let permissionType = roleList.find((role) => role.id === role_id)
        if (permissionType === 'web') return true
        return false
    }

    const conditionsCheck = async () => {
        if (action !== 'user_update') {
            if (!data?.name) {
                message.warning('Name is Required')
                return
            }
            if (!data?.phone) {
                message.warning('Phone No. is Required')
                return
            }
            if (!data?.email) {
                message.warning('Email is Required')
                return
            }
            if (!data?.role_id) {
                message.warning('Role is Required')
                return
            }
            if (!validateEmail(data.email)) {
                message.warning('Email is Invalid')
                return
            }
            if (!isValidPhoneNumber(data.phone)) {
                message.warning('Phone Number is Invalid')
                return
            }
        }

        setProceed(true)
        return
    }
    const onEditSubmit = async () => {
        setProceed(false)

        const payload = {
            name: data?.name, // optional
            email: data?.email, // optional
            phone: data?.phone,
            id: data?.id,
            status: data?.status,
            role_id: data?.role_id,
            ...(isNew && { created_at: formattedDate }),
            updated_at: isNew ? formattedDate : data?.updated_at,
        }

        const url = isNew
            ? `${process.env.REACT_APP_BASE_URL}${APIEndPoints.addUsers}`
            : `${process.env.REACT_APP_BASE_URL}${APIEndPoints.updateUsers}`
        const response = await makeAPICall({
            method: 'post',
            url: url,
            payload: payload,
            params: { user_type: 'admin' },
        })
        if (response.success) {
            message.success('Successfully Added')
            onClose('Added')
        } else {
            let error =
                response?.data?.message ||
                response?.data?.data?.message ||
                response?.response?.data?.data ||
                response?.response?.data?.data?.message ||
                response?.response?.data?.message
            if (error.includes('UserCreateData.Email')) {
                message.warning('Email is Invalid')
                return
            }
            message.warning(error)
        }
    }

    return (
        <>
            <Modal
                title={<p> Are your sure, you want to {isNew ? 'add' : 'update'} this user ?</p>}
                open={proceed ? true : false}
                onCancel={() => {
                    setProceed(false)
                }}
                centered
                width={'500px'}
                footer={() => (
                    <Row className='flex justify-end gap-2'>
                        <Button onClick={() => setProceed(null)} variant='default'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                onEditSubmit()
                            }}
                            variant='primary'
                        >
                            Confirm
                        </Button>
                    </Row>
                )}
            ></Modal>
            <Drawer title={title} className='pt-0' placement='right' onClose={() => onClose('Cancelled')} open={open}>
                <Col className='w-full'>
                    <Space direction='vertical' className='w-full'>
                        <Input
                            // type='text'
                            label={'Full Name'}
                            value={data?.name}
                            placeholder='Name'
                            onChange={(e) => {
                                setData((prev) => ({ ...prev, name: e.target.value }))
                            }}
                        />
                        <Input
                            type='tel'
                            label='Phone Number'
                            maxLength={10}
                            placeholder='Phone no.'
                            value={data?.phone}
                            onChange={(e) => {
                                let ph = e.target.value
                                if (/^\d+$/.test(ph) || ph === '') {
                                    setData((prev) => ({ ...prev, phone: e.target.value }))
                                }
                            }}
                            prefix={'+91'}
                        />
                        {action !== 'user_update' && (
                            <Input
                                label={'Email ID'}
                                value={!isNew ? recordData?.email_mask : data?.email}
                                placeholder='Email'
                                onChange={(e) => {
                                    setData((prev) => ({ ...prev, email: e.target.value }))
                                }}
                                disabled={!isNew}
                            />
                        )}
                        <Select
                            className='w-full'
                            label={'User Role'}
                            placeholder={'Select'}
                            value={data?.role_id}
                            onChange={(e) => {
                                setData((prev) => ({ ...prev, role_id: e }))
                            }}
                            options={roleList.map((role) => ({ value: role.id, label: role.name }))}
                        />
                        <Select
                            className='w-full'
                            label={'User Status'}
                            placeholder={'Select'}
                            value={data?.status}
                            onChange={(e) => {
                                setData((prev) => ({ ...prev, status: e }))
                            }}
                            options={[
                                { value: 'active', label: 'Active' },
                                { value: 'suspended', label: 'Suspended' },
                            ]}
                        />
                    </Space>
                </Col>
                <Row className='mt-4'>
                    <Col span={12}>
                        <Button onClick={() => conditionsCheck()} variant='primary' className='w-[99%]'>
                            Submit
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button onClick={() => onClose('Cancelled')} variant='default' className='w-[99%] ml-1'>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Drawer>
        </>
    )
}

export default EditNotificationsDrawer
