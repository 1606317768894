import WhatsNewImg from '@library/images/common/executive-summary-images/WhatsNewImg'
import { useEffect, useState } from 'react'
import FilterTabs from './components/FilterTabs'
import Image1 from '@library/images/common/executive-summary-images/TempImage1.png'
import Image2 from '@library/images/common/executive-summary-images/TempImage2.png'
import Image3 from '@library/images/common/executive-summary-images/TempImage3.png'
import Image4 from '@library/images/common/executive-summary-images/TempImage4.png'
import Image5 from '@library/images/common/executive-summary-images/KwikPassImg.png'

import { Button, Divider, ShoppingFilled } from 'gokwik-ui-kit'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getUserData } from '@store/user/selectors'
import { useSelector } from 'react-redux'

const WhatsNew = ({ chartComponentHieght, recommendedCampaigns, recordInteraction }) => {
    const [selected, setSelected] = useState('All')
    const [newsFeed, setNewsFeed] = useState([])
    const userData = useSelector(getUserData)

    useEffect(() => {
        const filteredNewsFeed =
            selected === 'All'
                ? recommendedCampaigns
                : recommendedCampaigns?.filter((item) => item.type.includes(selected))
        setNewsFeed(filteredNewsFeed)
    }, [selected, recommendedCampaigns])

    return (
        <div
            className={`bg-white border border-solid border-[#EAECF0]`}
            style={{ height: `${chartComponentHieght}px` }}
        >
            <div className='flex justify-between items-center pb-5 pt-3 px-3 bg-gradient-to-b from-blue-100 to-white'>
                <div className='font-semibold text-sm'>Recommended For You</div>
                <WhatsNewImg />
            </div>
            <div className='m-auto w-11/12'>
                <div className='-ml-3'>
                    {/* <FilterTabs selected={selected} setSelected={setSelected} newLaunchesArray={newLaunchesArray} /> */}
                </div>

                <div
                    className='overflow-y-auto'
                    style={{
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none',
                        maxHeight: `${Number(chartComponentHieght) - 65}px`,
                    }}
                >
                    <style>
                        {`
                            .overflow-y-auto::-webkit-scrollbar {
                                display: none;
                            }
                        `}
                    </style>
                    {newsFeed?.map((item, index) => {
                        return (
                            <div key={index}>
                                <div className='bg-white rounded-md shadow-sm flex flex-col gap-3 p-2 mb-3 pb-0'>
                                    {item?.media_url && (
                                        <div className='w-full aspect-video'>
                                            <img src={item?.media_url} className='w-full h-full object-cover' />
                                        </div>
                                    )}
                                    <div className='text-base font-medium text-[#000000E0] text-left w-full'>
                                        {item?.title}
                                    </div>
                                    <div className='text-xs font-normal text-[#000000E0] text-justify w-[92%]'>
                                        <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                                    </div>
                                    <div className='w-full'>
                                        <Button
                                            className='w-full'
                                            variant='primary'
                                            onClick={() => {
                                                logEvent(
                                                    'es_reco_cta_clicked',
                                                    'click',
                                                    'executive_summary',
                                                    userData?.userDetails?.email,
                                                    userData?.merchantDetails?.m_id,
                                                    userData?.merchantDetails?.short_name,
                                                    userData?.userDetails?.name,
                                                    { ctaURL: item?.cta_url },
                                                )
                                                recordInteraction('click', item?.id)
                                                window.open(item.cta_url, '_blank')
                                            }}
                                        >
                                            {item?.cta_text}
                                        </Button>
                                    </div>
                                    {/* <Divider /> */}
                                </div>
                                <Divider className='mt-5 mb-1 bg-gray-200' />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default WhatsNew
