import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import { Row, Col } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import Editor from './editor'
import { configs } from './utils'
import Preview from './preview-campaign'

const MarketingCampaign = () => {
    const [campaignType, setCampaignType] = useState('small')
    const [campaignConfigs, setCampaignConfigs] = useState(configs)
    const [previewImage, setPreviewImage] = useState(null)
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        setCampaignConfigs(configs)
    }, [campaignType])

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'all-campaigns',
                href: '/general/all-campaigns',
                text: 'All Campaigns',
            },
            {
                key: 'create-campaign',
                href: '/general/create-campaign',
                text: 'Create Campaign',
            },
        ])
    }, [])

    return (
        <div className='w-full'>
            <Row>
                <Col span={24}>
                    <header>
                        <div className='text-xl font-semibold'>In App Marketing Campaign</div>

                        <div className='text-[#00000073] font-normal text-sm'>
                            Create and customise in app marketing campaign
                        </div>
                    </header>
                </Col>
            </Row>
            <br />
            <Row gutter={12}>
                <Col span={12}>
                    <Editor
                        campaignType={campaignType}
                        setCampaignType={setCampaignType}
                        campaignConfigs={campaignConfigs}
                        setCampaignConfigs={setCampaignConfigs}
                        setPreviewImage={setPreviewImage}
                    />
                </Col>

                <Col span={12}>
                    <div className='rounded-md border-t-[12px] border-[24px] border-white border-solid'>
                        <div className='bg-white p-1 pb-4 text-[#000000] text-sm font-semibold'>Preview</div>

                        <div
                            className={`bg-[#CECECE] p-7 rounded-md ${
                                campaignType === 'modal' && 'flex justify-center items-center'
                            }`}
                        >
                            <Preview
                                previewImage={previewImage}
                                campaignType={campaignType}
                                campaignConfigs={campaignConfigs}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default MarketingCampaign
