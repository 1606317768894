import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { Button, Col, Drawer, Input, Row, Select, Space, TextArea, Tooltip, message, DatePicker, TimePicker, Tag, Modal } from 'gokwik-ui-kit'
import { ReactNode, useEffect, useState } from 'react'
import dayjs from 'dayjs';
// import TimePicker from 'react-time-picker';
const {Option} = Select
interface Props {
  open: boolean
  title: string
  onClose: (refetch?: boolean) => void
  recordData: any
}

interface NotificationsDetails {
  name: string | null
  description: string | null
  permissions: any | null
 
}

const addDrawerPermissions = ({ open, title, onClose, recordData }: Props) => {


  const [data, setData] = useState<NotificationsDetails>({
    name: recordData?.name || '',
    description: recordData?.description || '',
    permissions: recordData?.permission_type || [],
    // description: recordData?.description || '',
  })
  const [permissionsOption, setPermissionOptions] = useState([]);
  const [permissionArray, setPermissionArray] = useState(recordData?.permissions || []);
  async function makePermissionsApiCall() { 
    try {
        let params = { page: 1, pageSize: 1000 };
        let response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + '/api/dashboard/permissions/all',
            params: {
                ...params,
            },
        })
        if (response.data?.data?.permissions?.length > 0) {
          let permissions = response.data.data.permissions;
          let arr = [];
  
          let obj = {
            value: 'SelectAll',
            label: 'SelectAll',
          };
          arr.push(obj);
          for (let i = 0; i < permissions.length; i++) {
            let obj = {
              value: permissions[i].name,
              label: permissions[i].name + ' --> ' + permissions[i]?.description || 'Description not added',
            };
            arr.push(obj);
          }
           setPermissionOptions([...arr]);
        } 
    } catch (err) {
        console.warn(err)
    }
}
  useEffect(() => {
    makePermissionsApiCall();
  }, []);
  const [proceed, setProceed] = useState(false)
  
  const conditionsCheck = async() => {
    if (!data?.name?.trim()) {
      message.warning('Name is Required')
      return
    }
    if(!data?.description?.trim()){
      message.warning('Description is Required')
      return
    }
    if(!permissionArray.length) {
      message.warning('Permissions are Required')
      return
    }
    setProceed(true);
    return;
  }
  const onEditSubmit = async () => {
    setProceed(false)
    const payload = {
      name: data?.name?.trim(), // optional
      description: data?.description?.trim(),
      permissions: permissionArray,
      id: recordData?.id,
    }
    const url = `${process.env.REACT_APP_BASE_URL}${APIEndPoints.updateRoles}`
    const response = await makeAPICall({
      method: 'post',
      url: url,
      payload: payload 
    })
    if (response.success) {
      message.success("Updated Successfully")
      onClose(true)
    } else {
      message.warning(response?.data?.message || response?.data?.data?.message || response?.response?.data?.message || response?.response?.data?.data?.message)
    }
  }

  return (
    <>
    <Modal
          title={
              <p> Are your sure, you want to update this role ?</p>
          }
          open={proceed ? true : false}
          onCancel={() => {
              setProceed(false)
          }}
          centered
          width={'500px'}
          footer={() => (
              <Row className='flex justify-end gap-2'>
                  <Button onClick={() => setProceed(null)} variant='default'>
                      Cancel
                  </Button>
                  <Button
                        onClick={() => {
                          onEditSubmit()
                        }}
                        variant='primary'
                    >
                        Confirm
                    </Button>
              </Row>
          )}
      >
    </Modal>
    <Drawer title={title} className='pt-0' placement='right' onClose={() => onClose()} open={open} width = {555}>
      <Col className='w-full'>
        <Space direction='vertical' className='w-full'>
          <p className='mt-2'>Name* </p>
          <Input
            value={data?.name}
            placeholder='Name'
            onChange={(e) => {
              setData((prev) => ({ ...prev, name: e.target.value }))
            }}
          />
           < Select
              label = {'Permissions*'}
              className='w-full'
              mode='multiple'
              placeholder='Select items'
              value={permissionArray}
              onChange={(value) => {setPermissionArray(value);}}
              tagRender={({ label, value, closable, onClose }) => (
                  <Tag
                      color='green'
                      closable={closable}
                      onClose={onClose}
                  >
                      {value}
                  </Tag>
              )}
            >
            {permissionsOption.map((permission,index) => (
              <Option key={index} value={permission?.value}>
                {permission?.label}
              </Option>
            ))}
          </Select> 
          <p className='mt-2'>Description* </p>
          <TextArea
            placeholder='Description'
            value = {data?.description}
            onChange={(e) => {
              setData((prev) => ({ ...prev, description: e.target.value }))
            }}
          />
        </Space>
      </Col>
      <Row className='mt-4'>
        <Col span={12}>
          <Button onClick={conditionsCheck} variant='primary' className='w-[99%]'>
            Submit
          </Button>
        </Col>
        <Col span={12}>
          <Button onClick={() => onClose()} variant='default' className='w-[99%] ml-1'>
            Cancel
          </Button>
        </Col>
      </Row>
    </Drawer>
   </>
  )
}

export default addDrawerPermissions
