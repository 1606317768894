import React, { useState } from 'react';
import { Button, Drawer } from 'gokwik-ui-kit'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents';
import { getMerchantDetails, getUserDetails } from '@store/user/selectors';
import { useSelector } from 'react-redux';

const CodSuiteDetails = ({codDetailsVisible, setCodDetailsVisible}) => {
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)

    const closeDetailsDrawer = () => {
        logEvent(
            'know_more_closed',
            'click',
            'get_started',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { plan: 'cod' }
        )
        setCodDetailsVisible(false);
    }

    const codDetailsSections = [
        {
            title: "What is COD?",
            description:
                "GoKwik COD is a powerful app that acts as a COD gateway, offering a suite of COD features to help you intelligently manage COD, such as viewing risky customers and applying interventions where necessary.",
        },
        {
            title: "Why COD?",
            description:
                "By using GoKwik COD, you can leverage advanced features to increase sales, reduce risk, and optimize your payment methods. It’s not just about enabling COD—it’s about enabling it smartly.",
        },
        {
            title: "Impact of COD?",
            points: [
                "Boost Prepaid Conversions: Increase prepaid orders by 20-25%.",
                "Reduce Risk: Apply risk interventions to risky customers and protect margins.",
                "Ensures 100% order success on COD.",
            ],
        },
        {
            title: "COD Pricing",
            points: [
                "Free for the first 3 months.",
                "After the free period, you get 1000 free COD orders per month.",
                "Post 1000 orders, we charge 0.5% per order.",
            ],
        },
    ];

    return (
        <Drawer
            open={codDetailsVisible}
            footer={
                <div style={{ padding: '0.5rem 0rem' }}>
                    <Button onClick={closeDetailsDrawer} variant="primary">
                        Got It
                    </Button>
                </div>
            }
            width={550}
            closable={false}
            onClose={closeDetailsDrawer}
            style={{
                borderTopLeftRadius: '16px',
                borderBottomLeftRadius: '16px',
            }}
        >
            <div className='mt-5'>
            {codDetailsSections?.map((section, index) => (
                <div key={index} className="mb-8">
                    <div className="text-[16px] mb-3 font-semibold">{section?.title}</div>
                    {section?.description && (
                        <p className="text-[14px] font-normal" style={{ color: 'rgba(0, 0, 0, 0.65)', lineHeight: '22px' }} >{section.description}</p>
                    )}
                    {section?.points && (
                        <ul className="text-[14px] font-normal w-full flex flex-col gap-1" style={{ paddingLeft: '1.2rem', color: 'rgba(0, 0, 0, 0.65)' }} >
                            {section.points.map((point, i) => (
                                <li key={i}>{point}</li>
                            ))}
                        </ul>
                    )}
                </div>
            ))}
            </div>
        </Drawer>
    )
};

export default CodSuiteDetails;
