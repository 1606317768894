import './kyc.css'
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import {
    Form,
    Input,
    Select,
    Checkbox,
    Row,
    Col,
    Button,
    Alert,
    CopyOutlined,
    ExportOutlined,
    QuestionCircleOutlined,
    Steps,
    StepProps,
    Tag,
    Tooltip,
    Upload,
    UploadOutlined,
    Switch,
    DatePicker,
    CloseCircleOutlined,
    Spin,
    Modal,
    message,
    InfoCircleFilled,
    InputNumber,
    DownloadOutlined,
} from 'gokwik-ui-kit'
import { fileInput } from '../inputJSON'
import { logUserEvent, makeAPICall } from '@gokwik/utilities'
import { getMerchantDetails, getUserData, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import Terms from '@library/images/terms.html'
import FileIcon from '@library/images/icons/fileIcon'
import QuestionMarkIcon from '@library/images/icons/questionMarkIcon'
import { navigateToUrl } from 'single-spa'
import dayjs from 'dayjs'
import FileIcon2 from '@library/images/icons/fileIcon2'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { handleError } from '@library/utilities/helpers/handleError'
import { fetchUserDetails } from '@store/user'
import { useAppDispatch, useInterval } from '@library/utilities/hooks'
import { stepConfiguration } from '@pages/onboarding/stepsConfig'

const { Option } = Select
const formJson = fileInput

const KYCForm = () => {
    const [currentSteps, setCurrent] = useState(0)
    const [form] = Form.useForm()
    const [completed, setCompleted] = useState({})
    const [documentList, setDocumentList] = useState({})
    const merchant_details = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const userData = useSelector(getUserData)
    const [kycData, setKycData] = useState(null)
    const [disabledInput, setEnableInput] = useState({})
    const [bankProofUpload, setBankProofUploaded] = useState(false)
    const [VBCLink, setVBClink] = useState(null)
    const [pincode, setPincode] = useState('')
    const [termsCondition, setTermsCondition] = useState(false)
    const [showTNCModal, setShowTNCModal] = useState(false)
    const [stateCity, setStateCity] = useState(true)
    const [reSubmitState, setReSubmitState] = useState(false)
    const dispatch = useAppDispatch()
    const [fassai_ayurvedic_status, set_fassai_ayurvedic_status] = useState({})
    const [isCopied, setIsCopied] = useState(false)
    const [isCpvMandatory, setIsCpvMandatory] = useState(false)
    const [showKYCErrorMessage, setShowKYCErrorMessage] = useState(false)
    const [interval, initiateInterval, removeInterval] = useInterval(
        () => fetchLatestOnboardingStatusWithPollingHandling(),
        1000,
    )
    const intervalHandling = useCallback(
        (result) => {
            const current_onboarding_status = result

            if (
                current_onboarding_status === 'kyc_verification_failed' &&
                userData?.kycData?.kyc_failure_reasons &&
                !userData?.kycData?.kyc_failure_reasons?.hasOwnProperty('incurable_kyc_error')
            ) {
                let timeout
                if (!timeout) {
                    // Automatically stop polling after the 1min
                    window.setTimeout(() => {
                        removeInterval()
                        setShowKYCErrorMessage(false)
                        window.location.reload()
                    }, 60000)
                }
                if (!interval) {
                    initiateInterval()
                }
            } else {
                window.location.reload()
                setShowKYCErrorMessage(false)
                removeInterval()
                dispatch(fetchUserDetails())
            }
        },
        [merchant_details?.onboarding_status?.kwik_checkout_app],
    )

    useEffect(() => {
        return () => {
            if (copyTimerRef.current) {
                clearTimeout(copyTimerRef.current)
            }
        }
    }, [])

    const kycFailureReason = userData?.kycData?.kyc_rejection_reason

    const undeterminedKycErrorState =
        merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_verification_failed' &&
        kycFailureReason.length > 0

    const fetchLatestOnboardingStatusWithPollingHandling = async () => {
        const res = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.userDetails,
            method: 'get',
            skipLoader: true,
        })
            .then((result) => {
                intervalHandling(result?.data?.data?.merchant_details?.onboarding_status?.kwik_checkout_app)
            })
            .catch((err) => {
                console.warn(err)
            })
    }

    useEffect(() => {
        setTermsCondition(userData?.kycData?.accepted_terms_and_conditions)
    }, [userData?.kycData?.accepted_terms_and_conditions])

    const openNextErrorStep = () => {
        const steps = Object.keys(errorMap)
        const startIndex = currentSteps + 1
        // Search from the current step to the end
        for (let i = startIndex; i < steps.length; i++) {
            if (errorMap[i]) {
                setCurrent(i)
                return
            }
        }
        // If not found, wrap around and search from the beginning
        for (let i = 0; i < startIndex; i++) {
            if (errorMap[i]) {
                setCurrent(i)
                return
            }
        }
    }

    const isOnlyOneError = () => {
        let trueCount = 0
        for (const key in errorMap) {
            if (errorMap[key]) {
                trueCount++
            }
            // If more than one true value is found, return false early
            if (trueCount > 1) {
                return false
            }
        }
        // Return true if exactly one true value is found
        return trueCount === 1
    }

    const onFinish = async (values, step) => {
        const finalValue = {
            ...values,
            ...(step === 1 && { pincode: Number(values.pincode) }),
            ...(step === 0 && {
                average_order_value: Number(values.average_order_value),
                accepted_terms_and_conditions: true,
                entity_type: Number(values.entity_type),
            }),
            ...(step === 2 && {
                ifsc_code: values?.ifsc_code?.toUpperCase(),
            }),
            ...(step === 3 && {
                business_pan: values?.business_pan?.toUpperCase(),
                aus_pan: values?.aus_pan?.toUpperCase(),
                gstin: values?.gstin?.toUpperCase(),
            }),
            ...(step === 4 && {
                address_type: Number(values.address_type),
            }),
            ...(merchant_details?.onboarding_status?.kwik_checkout_app !== 'kyc_verification_failed' && {
                current_onboarding_status: checkCurrentStatus(step),
            }),
        }
        try {
            await makeAPICall({
                method: 'post',
                url:
                    process.env.REACT_APP_BASE_URL +
                    APIEndPoints.updateMerchantOnboardingStatus +
                    merchant_details.m_id,
                payload: finalValue,
            }).then((res) => {
                if (!res.data.success) {
                    message.error('Updation Failed')
                }

                if (merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_verification_failed') {
                    if (termsCondition && step === 4) {
                        sendPriceAgreed()
                    }
                    if (isOnlyOneError()) {
                        //if only 1 error left then submit and close that step
                        let stepGreaterThanAnyOfTheStepInThisOnboardingFlow = 100
                        setCurrent(stepGreaterThanAnyOfTheStepInThisOnboardingFlow)
                    } else {
                        //open the next step which has error
                        openNextErrorStep()
                    }
                    // sendErrorDocs()
                } else {
                    onStepChange(step)
                    setCompleted((prev) => ({
                        ...prev,
                        [step]: true,
                        ...{ [step + 1]: completed[step + 1] ? true : false },
                    }))

                    if (step === 4) {
                        if (termsCondition) {
                            sendPriceAgreed()
                        }
                        skipVPC()

                        //stopped VPC link fetching and therby also stopped polling

                        //starting VPC link fetching
                        if (isCpvMandatory) {
                            getVPCLink()
                        }

                        fetchLatestOnboardingStatusWithPollingHandling()
                    }
                }

                if (step === 0) {
                    getDocumentList()
                }
            })
        } catch (error) {
            handleError(error)
        }
    }

    const skipVPC = () => {
        sendErrorDocs()
        navigateToUrl('/shopify-app/settings')
    }

    const getVPCLink = async () => {
        await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.generateKYCLinks,
            payload: {
                send_notification: 1,
            },
        }).then((res) => {
            if (!res.data.data.video_kyc_url) {
                getVPCLink()
            }
            setVBClink(res?.data?.data?.video_kyc_url)
        })
    }

    const checkCurrentStatus = (step) => {
        switch (step) {
            case 0:
                return 'kyc_basic_info_added'
            case 1:
                return 'kyc_address_details'
            case 2:
                return 'kyc_bank_details'
            case 3:
                return 'kyc_business_details'
            case 4:
                return 'kyc_document_upload'
            case 5:
                return 'kyc_vpc'
            case 6:
                return 'kyc_verification_pending'
            case 7:
                return 'kyc_verification_failed'
            case 8:
                return 'kyc_completed'
            default:
                return 'Unknown Status' // Default case if none of the above matches
        }
    }

    const formItemLayout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    }

    const onStepChange = (e) => {
        setCurrent(e + 1)
        setCompleted((prev) => ({ ...prev, [e]: true }))
    }

    const renderCurrentStatus = (stepIndex) => {
        return stepIndex === currentSteps && !completed[stepIndex] ? (
            <></>
        ) : stepIndex === 5 &&
          isCpvMandatory &&
          merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_verification_failed' ? (
            <span
                className={`px-2 py-[1px] mr-2 ml-[-8px] rounded border border-solid text-xs font-normal text-center bg-[#F8920D] border-[#F8920D] text-white `}
            >
                Pending
            </span>
        ) : completed[stepIndex] ? (
            <span
                className={`px-2 py-[1px] mr-2 ml-[-8px] rounded border border-solid text-xs font-normal text-center bg-[#E5FFF7] border-[#25B59B] text-[#25B59B] `}
            >
                Completed
            </span>
        ) : (
            <span
                className={`px-2 py-[1px] mr-2 ml-[-8px] rounded border border-solid text-xs font-normal text-center bg-[#F8920D] border-[#F8920D] text-white `}
            >
                Pending
            </span>
        )
    }

    const onCopy = () => {
        setIsCopied(true)
        logEvent('background_verification_copy_link', 'click', 'kyc', user_details.email, merchant_details.m_id)

        navigator.clipboard.writeText(form.getFieldValue('vbc_link')).then(
            () => {
                console.log('Link copied to clipboard!')
            },
            (err) => {
                console.error('Could not copy text: ', err)
            },
        )

        copyTimerRef.current = setTimeout(() => {
            setIsCopied(false)
        }, 4000)
    }

    const copyTimerRef = useRef(null)

    const onOpenInNewTab = () => {
        logEvent('background_verification_new_tab_clicked', 'click', 'kyc', user_details.email, merchant_details.m_id)
        // Logic to open the link in a new tab
        const url = form.getFieldValue('vbc_link')
        if (url) window.open(url, '_blank').focus()
    }

    useEffect(() => {
        getKYCDetails()
        getDocumentList()
    }, [])

    const onCurrentStepChange = (status) => {
        switch (status) {
            case 'kyc_basic_info_added':
                return 0
            case 'kyc_address_details':
                return 1
            case 'kyc_bank_details':
                return 2
            case 'kyc_business_details':
                return 3
            case 'kyc_document_upload':
                return 4
            case 'kyc_vpc':
                return 5
            case 'kyc_verification_pending':
                return 6
            case 'kyc_verification_failed':
                return 7
            case 'kyc_completed':
                return 8
            default:
                return -1 // A default value indicating 'unknown' or 'not found'
        }
    }

    function setCurrentCompletedSteps(number) {
        let resultArray = {}
        for (let i = 0; i <= number; i++) {
            resultArray[i] = true
        }
        setCompleted((prev) => ({ ...prev, ...resultArray }))
        setCurrent(number + 1)
    }

    const getKYCDetails = async (skiploader?) => {
        let response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.userDetails,
            skipLoader: skiploader,
        })
        if (response.success) {
            setKycData({
                ...response.data.data.kyc_details,
                ...(response.data.data?.kyc_details?.business_pan_registration_date && {
                    business_pan_registration_date: dayjs(
                        response.data.data?.kyc_details?.business_pan_registration_date,
                    ),
                }),
            })

            setCurrentCompletedSteps(
                onCurrentStepChange(response.data.data.merchant_details?.onboarding_status?.kwik_checkout_app),
            )

            if (response.data.data?.kyc_details?.is_cpv_mandatory) {
                setIsCpvMandatory(true)

                setCurrent(5)
            } else {
                setIsCpvMandatory(false)
            }

            // if (response.data.data.merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_verification_failed') {
            //     // window.location.reload()
            // } else if (response.data.data.merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_verification_pending') {
            //     navigateToUrl('/checkout/settings')
            // }
            if (
                response.data.data.merchant_details?.onboarding_status?.kwik_checkout_app ===
                    'kyc_verification_failed' &&
                response.data.data?.kyc_details?.is_cpv_mandatory
            ) {
                getVPCLink()
            } else if (
                ['setup_completed'].includes(response.data.data.merchant_details?.onboarding_status?.kwik_checkout_app)
            ) {
                removeInterval()
                navigateToUrl('/shopify-app/settings')
            }
            if (response.data.data?.kyc_details?.state || response.data.data.kyc_details?.city) {
                setStateCity(false)
            }
            setBankProofUploaded(response.data.data.kyc_details.bank_proof_uploaded)
        } else if (response.error) {
            if (response.response.data.status_code !== 401) {
                let errorMsg =
                    response.response && response.response.data ? response.response.data.message : 'Something failed'
                message.error(errorMsg)
            }
        }
    }
    const getDocumentList = async () => {
        let res = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.documentList,
        })
        if (res.data.data[0].other_details?.allowed_options.length === 1) {
            form.setFieldValue('address_type', Object.keys(res.data.data[0].other_details?.allowed_options[0])[0])
        }
        groupByTitle(res.data.data)
    }

    function groupByTitle(dataArray) {
        const fassai_ayurvedic_map = dataArray.reduce((acc, doc) => {
            if (['fssai_license', 'ayurvedic_license'].includes(doc.name)) {
                acc[`${doc.name}`] = doc.uploaded
            }
            return acc
        }, {})
        set_fassai_ayurvedic_status({ ...fassai_ayurvedic_map })

        const groupedData = {}

        dataArray.forEach((item) => {
            const title = item.title || 'Address Proof' // 'Others' is used for items without a title

            if (!groupedData[title]) {
                groupedData[title] = [] // Initialize the array if not exist
            }

            groupedData[title].push(item) // Append the item to the array for this title
        })

        setDocumentList(groupedData)
    }

    const customRequest = async ({ file, onSuccess, onError }, item) => {
        // onSuccess('ok')

        if (!beforeUpload(item, file)) {
            onError('ok')
            form.resetFields([item.field])
            return
        }
        // Upload file
        await fetch(item.upload_url, {
            method: 'PUT',
            body: file,
        }).then((res) => {
            if (res?.status === 200) {
                onSuccess('ok')
                updateKycDoc(item.name)
            } else {
                message.error('Upload failed ! Please retry.')
                onError('ok')
                form.resetFields([item.field])
                return
            }
        })
    }

    const updateKycDoc = async (name) => {
        try {
            await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateKycDocument,
                payload: {
                    file_name: name,
                },
            }).then((res) => {
                if (res.data.status_code === 200) {
                    message.success(res.data?.data || 'Document uploaded successfully')
                    if (name === 'bank_proof') {
                        setBankProofUploaded(true)
                    }
                    getDocumentList()
                }
            })
        } catch (err) {
            handleError(err)
        }
    }
    const getKycDoc = async (name) => {
        await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.getKycDocument,
            payload: {
                file_name: name,
            },
        }).then((res) => {
            // window.location.href = res.data.data.download_url
            const link = document.createElement('a')
            link.href = res.data.data.download_url
            link.download = name
            document.body.appendChild(link)
            link.setAttribute('target', '_blank') // Append to body
            link.click() // Simulate click
            document.body.removeChild(link) // Remove from body
        })
    }

    const deleteFile = async (name) => {
        await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.deleteKycDocument,
            payload: {
                file_name: name,
            },
        }).then((res) => {
            setBankProofUploaded(false)
            getDocumentList()
        })
    }

    const isInputDisabled = (name) => {
        return !disabledInput[name]
    }

    const beforeUpload = (item, file) => {
        const isLt5M = file.size / 1024 / 1024 < 5
        const uploadedMimeType = file?.type
        const uploadFormat = uploadedMimeType.split('/')[1]
        const allowedFileTypes = item?.other_details?.allowed_format?.split(', ')
        const isAllowedFormat = allowedFileTypes?.includes(uploadFormat)

        if (!isAllowedFormat && allowedFileTypes !== undefined) {
            form.resetFields([item.field])
            message.error(`You can only upload files of types: ${allowedFileTypes}`)
            return false
        } else if (!isLt5M) {
            message.error('Image must smaller than 5MB!')
            return false
        }
        return true
    }

    useEffect(() => {
        let pin = pincode + ''
        if (pin.length === 6) {
            getStateCity()
        }
    }, [pincode])

    const getStateCity = async () => {
        await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.getPincodeState + '?pincode=' + pincode,
        }).then((res) => {
            if (res?.status === 200) {
                setStateCity(false)
                form.setFieldValue('state', res.data.data.state)
                form.setFieldValue('city', res.data.data.city)
            } else {
                form.setFields([
                    {
                        name: 'pincode',
                        errors: ['Invalid Pincode'],
                    },
                ])
                form.setFieldValue('state', '')
                form.setFieldValue('city', '')
            }
        })
    }

    const checkCompletedSteps = (step) => {
        return Object.keys(completed).includes(step.toString())
    }

    const renderToolTip = (tooltips) => {
        let tips = ''
        tooltips.map((itm) => {
            itm?.tooltip
            tips = itm?.tooltip ? itm?.tooltip : tips
        })
        return tips
    }
    const allowedFileFormats = (allowedFormatsString) => {
        const allowedFormats = allowedFormatsString?.split(', ')
        const formattedString = allowedFormats.map((format) => `.${format}`).join(', ')
        return formattedString
    }

    const getErrorList = (sectionName) => {
        const kycErrors = userData?.kycData?.kyc_failure_reasons
        const document_upload_mapped_to_entity_type = {
            '5': {
                address_type: 'Address Type',
                pan_card: 'PAN card of HUF',
                address_proof_front_page: 'GST Certificate of the Entity(Front)',
                address_proof_back_page: 'GST Certificate of the Entity(Back)',
                business_registration_proof: 'HUF Deed',
                pan_of_karta: 'PAN of Karta',
                aus_aadhar_card_front_page: 'Front Side of Document',
                aus_aadhar_card_back_page: 'Back Side of Document',
                sell_edible_food_products: 'Do You Sell Edible Food Products?',
                sell_ayurvedic_products: 'Do You Sell Ayurvedic Products?',
                fssai_license: 'FSSAI License',
                ayurvedic_license: 'Ayurvedic License',
                kyc: 'KYC Error',
            },
            '7': {
                address_type: 'Address Type',
                pan_card: 'Business PAN',
                address_proof_front_page: 'GST Certificate of the Entity(Front)',
                address_proof_back_page: 'GST Certificate of the Entity(Back)',
                business_registration_proof: 'Certificate of Incorporation',
                aus_board_resolution_authorizing: 'Board Resolution',
                aus_pan_card: 'PAN (Authorised Signatory)',
                aus_aadhar_card_front_page: 'Front Side of Document',
                aus_aadhar_card_back_page: 'Back Side of Document',
                sell_edible_food_products: 'Do You Sell Edible Food Products?',
                sell_ayurvedic_products: 'Do You Sell Ayurvedic Products?',
                fssai_license: 'FSSAI License',
                ayurvedic_license: 'Ayurvedic License',
            },
            '9': {
                address_type: 'Address Type',
                pan_card: 'Business PAN',
                address_proof_front_page: 'Front Side of Document',
                address_proof_back_page: 'Back Side of Document',
                business_registration_proof: 'Business Deed',
                aus_pan_card: 'PAN (Authorised Signatory)',
                aus_aadhar_card_front_page: 'Front Side of Document',
                aus_aadhar_card_back_page: 'Back Side of Document',
                sell_edible_food_products: 'Do You Sell Edible Food Products?',
                sell_ayurvedic_products: 'Do You Sell Ayurvedic Products?',
                fssai_license: 'FSSAI License',
                ayurvedic_license: 'Ayurvedic License',
            },
            '10': {
                address_type: 'Address Type',
                pan_card: 'PAN',
                address_proof_front_page: 'Front Side of Document',
                address_proof_back_page: 'Back Side of Document',
                business_registration_proof: 'GST Certificate of the Entity',
                aus_aadhar_card_front_page: 'Front Side of Document',
                aus_aadhar_card_back_page: 'Back Side of Document',
                sell_edible_food_products: 'Do You Sell Edible Food Products?',
                sell_ayurvedic_products: 'Do You Sell Ayurvedic Products?',
                fssai_license: 'FSSAI License',
                ayurvedic_license: 'Ayurvedic License',
            },
            '11': {
                address_type: 'Address Type',
                pan_card: 'Business PAN',
                address_proof_front_page: 'GST Certificate of the Entity(Front)',
                address_proof_back_page: 'GST Certificate of the Entity(Back)',
                aus_board_resolution_authorizing: 'Board Resolution',
                business_registration_proof: 'Certificate of Incorporation',
                aus_pan_card: 'PAN (Authorised Signatory)',
                aus_aadhar_card_front_page: 'Front Side of Document',
                aus_aadhar_card_back_page: 'Back Side of Document',
                sell_edible_food_products: 'Do You Sell Edible Food Products?',
                sell_ayurvedic_products: 'Do You Sell Ayurvedic Products?',
                fssai_license: 'FSSAI License',
                ayurvedic_license: 'Ayurvedic License',
            },
        }

        const kycFieldsMap = {
            basic_info: {
                websiteurl: 'Website URL',
                business_name: 'Brand Name',
                business_category: 'Business Category',
                entity_type: 'Registration Type',
            },
            address_details: {
                address: 'Business Address',
                pincode: 'Pincode',
                city: 'City',
                state: 'State',
            },
            bank_details: {
                account_holder_name: 'Account Holder Name',
                account_number: 'Account Number',
                bank_name: 'Bank Name',
                branch_name: 'Branch Name',
                ifsc_code: 'IFSC Code',
                bank_proof: 'Bank Proof',
            },
            business_details: {
                business_name: 'Business Name',
                business_pan: 'Business PAN',
                pan_registration_date: 'Business PAN Registration Date',
                aus_pan: 'PAN (Authorised Signatory)',
                authorized_signatory_name: 'Authorized Signatory Name',
                gstin: 'GSTIN',
            },
            document_upload: {
                ...document_upload_mapped_to_entity_type[userData?.kycData?.entity_type],
            },
        }

        const errorList = []

        for (let key in kycErrors) {
            for (let section in kycFieldsMap) {
                if (key in kycFieldsMap[sectionName]) {
                    errorList.push(
                        `${kycFieldsMap[sectionName][key]} (${
                            (Array.isArray(kycErrors[key]) && kycErrors[key][0]) ||
                            (typeof kycErrors[key] === 'string' && kycErrors[key])
                        })`,
                    )
                    break
                }
            }
        }

        const erroListToolTip = () => {
            return (
                <>
                    <ul>
                        {errorList?.map((item, index) => {
                            return <div key={index}>{item}</div>
                        })}
                    </ul>
                </>
            )
        }

        return {
            errorList,
            erroListToolTip,
        }
    }

    const sendErrorDocs = async (isResubmit?: any) => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.sendKYCDocumentPostError,
                skipLoader: false,
                payload: { test: 1 },
            })

            if (response.status === 200) {
                if (isResubmit) {
                    logEvent(
                        'kyc_error_resubmit',
                        'click',
                        'kyc',
                        user_details?.email,
                        merchant_details?.m_id,
                        merchant_details?.short_name,
                        user_details?.name,
                        { errors: userData?.kycData?.kyc_failure_reasons },
                    )
                }
                setShowKYCErrorMessage(true)
                fetchLatestOnboardingStatusWithPollingHandling()
            } else {
                message.error('Failed')
            }
        } catch (err) {
            console.log(err)
        }
    }

    const sendPriceAgreed = async () => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.createBasicPlanForCODFirst,
            })
        } catch (err) {
            console.log(err)
        }
    }

    const isStepClickable = useMemo(
        () =>
            merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_verification_pending' ||
            merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_completed' ||
            (merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_verification_failed' &&
                userData?.kycData?.kyc_failure_reasons?.hasOwnProperty('incurable_kyc_error'))
                ? 'pointer-events-none'
                : '',
        [merchant_details?.onboarding_status?.kwik_checkout_app],
    )

    const errorMap = useMemo(() => {
        return {
            0: getErrorList('basic_info')?.errorList?.length > 0,
            1: getErrorList('address_details')?.errorList?.length > 0,
            2: getErrorList('bank_details')?.errorList?.length > 0,
            3: getErrorList('business_details')?.errorList?.length > 0,
            4: getErrorList('document_upload')?.errorList?.length > 0,
        }
    }, [userData])

    const getKYCStatusAlert = () => {
        if (undeterminedKycErrorState) {
            return (
                <Alert
                    className='p-4'
                    message={<span className='text-[#FF2618] text-sm font-semibold'>{'Attention Required'}</span>}
                    description={
                        <span className='text-red-400'>
                            <span>
                                <p>{kycFailureReason}</p>
                            </span>
                        </span>
                    }
                    type='error'
                    showIcon
                />
            )
        }

        const stepId = stepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.id
        if (
            stepId >= stepConfiguration['kyc_completed']?.id &&
            stepId !== stepConfiguration['kyc_verification_pending']?.id &&
            stepId !== stepConfiguration['kyc_verification_failed']?.id
        ) {
            return (
                <Alert
                    className='p-4'
                    message={<span className='text-[#084426] text-sm font-semibold'>KYC Approved</span>}
                    description={
                        <span className='text-[#084426] text-sm font-normal'>
                            You're all set. Thank you for the sign-up and document submissions. Our team will reach out
                            to you in next 5 working days to activate checkout. &nbsp;
                            <Button variant='primary' onClick={() => navigateToUrl('/shopify-app/settings')}>
                                Go to homepage
                            </Button>
                        </span>
                    }
                    type='success'
                    showIcon
                />
            )
        } else if (stepId === stepConfiguration['kyc_verification_failed']?.id) {
            const isIncurable =
                merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_verification_failed' &&
                userData?.kycData?.kyc_failure_reasons?.hasOwnProperty('incurable_kyc_error')
            let header, content
            if (isIncurable) {
                header = 'KYC Failed'
                content = (
                    <>
                        Your KYC has been rejected by our payment partners. Please reach out to{' '}
                        <a
                            href='mailto:support@gokwik.co?subject=GoKwik KYC verification Failed&body=I have encountered an incurable error in my KYC. Please help.'
                            target='_blank'
                        >
                            support@gokwik.co
                        </a>
                    </>
                )
            } else {
                header = 'Attention Required'
                content = (
                    <span>
                        Your KYC verification has failed due to the following reasons. Hover on the error(s) to know
                        more.{' '}
                        <p>Once reviewed please re-submit your KYC for verification by clicking on Re-Submit button.</p>
                    </span>
                )
            }
            return (
                <Alert
                    className='p-4'
                    message={<span className='text-[#FF2618] text-sm font-semibold'>{header}</span>}
                    description={<span className='text-red-400'>{content}</span>}
                    type='error'
                    showIcon
                />
            )
        } else if (stepId === stepConfiguration['kyc_verification_pending']?.id) {
            return (
                <Alert
                    className='p-4'
                    message={<span className='text-[#004B8D] text-sm font-semibold'>Application in Progress</span>}
                    description={
                        <span className='text-[#004B8D] text-[13px] font-normal'>
                            You’re all set! Thank you for the sign-up and document submissions. You will receive timely
                            updates on the approval of your application via email.{' '}
                            <span className='font-semibold'>It can take 2 business days to verify KYC</span>
                        </span>
                    }
                    type='info'
                    showIcon
                />
            )
        } else {
            return ''
        }
    }

    const getEntityTypeValue = (item) => {
        let value
        for (let obj of item) {
            if (obj[kycData?.address_type]) {
                value = obj[kycData?.address_type]
                break
            }
        }

        return value
    }

    return (
        <>
            {kycData && (
                <div className=' bg-blue-50 p-6'>
                    {getKYCStatusAlert() && <div className='mb-6'>{getKYCStatusAlert()}</div>}
                    <Steps
                        className={`${
                            Object.keys(completed).filter((itm) => Number(itm) === currentSteps)
                                ? 'customStepsClass'
                                : ''
                        } ${isStepClickable}`}
                        direction='vertical'
                        current={currentSteps}
                        onChange={(e) => {
                            if (!checkCompletedSteps(e)) {
                                return
                            }
                            if (Object.keys(completed).filter((itm) => Number(itm) === e)) {
                                setCurrent(e)
                            }
                        }}
                        items={[
                            {
                                title: (
                                    <div className='flex flex-row gap-2'>
                                        <div>Basic Information</div>
                                        {!undeterminedKycErrorState && (
                                            <div>
                                                {getErrorList('basic_info')?.errorList?.length > 0 &&
                                                    merchant_details?.onboarding_status?.kwik_checkout_app ===
                                                        'kyc_verification_failed' && (
                                                        <Tooltip
                                                            title={getErrorList('basic_info')?.erroListToolTip}
                                                            className=' cursor-pointer'
                                                        >
                                                            <span className='text-red-500 text-lg font-semibold'>
                                                                KYC Error <InfoCircleFilled />
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                ),
                                subTitle: <>{renderCurrentStatus(0)}</>,
                                description: (
                                    <>
                                        {currentSteps === 0 && (
                                            <Form
                                                scrollToFirstError
                                                disabled={
                                                    undeterminedKycErrorState
                                                        ? false
                                                        : merchant_details?.onboarding_status?.kwik_checkout_app ===
                                                              'kyc_verification_failed' &&
                                                          (getErrorList('basic_info')?.errorList?.length === 0 ||
                                                              userData?.kycData?.kyc_failure_reasons?.hasOwnProperty(
                                                                  'incurable_kyc_error',
                                                              ))
                                                }
                                                form={form}
                                                name='dynamic_form'
                                                initialValues={kycData || {}}
                                                onFinish={(v) => {
                                                    logEvent(
                                                        'basic_info_save_continue_clicked',
                                                        'click',
                                                        'kyc',
                                                        user_details.email,
                                                        merchant_details.m_id,
                                                    )
                                                    onFinish(v, 0)
                                                }}
                                                // autoComplete='off'
                                            >
                                                <Row gutter={16} className='gap-x-[2.5rem]'>
                                                    {formJson.basicDetails.map((item, index) => {
                                                        // Calculate column span, adjust as needed for responsiveness
                                                        const colSpan = item.type === 'checkbox' ? 24 : 11
                                                        const formItem = (
                                                            <Col
                                                                span={colSpan}
                                                                key={item.name}
                                                                className={
                                                                    item.type === 'checkbox' &&
                                                                    'accept_terms_and_conditions'
                                                                }
                                                            >
                                                                <Form.Item
                                                                    {...formItemLayout}
                                                                    name={item.name}
                                                                    label={item.label}
                                                                    rules={item.rules}
                                                                >
                                                                    {item.type === 'input' && (
                                                                        <Input
                                                                            disabled={item.disabled}
                                                                            placeholder={item.placeholder}
                                                                        />
                                                                    )}
                                                                    {item.type === 'select' && (
                                                                        <Select
                                                                            filterSort={null}
                                                                            placeholder={item.placeholder}
                                                                        >
                                                                            {item.name === 'business_category'
                                                                                ? kycData?.allowed_business_categories.map(
                                                                                      (option) => (
                                                                                          <Option
                                                                                              key={option}
                                                                                              value={option}
                                                                                          >
                                                                                              {option}
                                                                                          </Option>
                                                                                      ),
                                                                                  )
                                                                                : []}
                                                                            {item.name === 'entity_type'
                                                                                ? item.options.map((option, index) => (
                                                                                      <Option
                                                                                          key={index}
                                                                                          value={Number(
                                                                                              Object.values(option),
                                                                                          )}
                                                                                      >
                                                                                          {Object.keys(option)[0]}
                                                                                      </Option>
                                                                                  ))
                                                                                : []}
                                                                        </Select>
                                                                    )}
                                                                    {item.type === 'inputNumber' && (
                                                                        <InputNumber
                                                                            className='w-full ant-input-number'
                                                                            min={1}
                                                                            onChange={(e) => {
                                                                                const newValue = Number(e)
                                                                                if (newValue < 0) {
                                                                                    return
                                                                                } else {
                                                                                    form.setFieldValue(
                                                                                        item.name,
                                                                                        newValue,
                                                                                    )
                                                                                }
                                                                            }}
                                                                            placeholder={item.placeholder}
                                                                        />
                                                                    )}
                                                                    {/* {item.type === 'checkbox' && (
                                                                        <div className='h-[35px] p-2 mt-[2.5rem] rounded'>
                                                                            <Checkbox
                                                                                // checked={true}
                                                                                checked={form.getFieldValue(
                                                                                    'accepted_terms_and_conditions',
                                                                                )}
                                                                                onChange={(e) =>
                                                                                    setTermsCondition(true)
                                                                                }
                                                                                className='w-full  text-blue-600'
                                                                            >
                                                                                {item.text}
                                                                            </Checkbox>
                                                                        </div>
                                                                    )} */}
                                                                </Form.Item>
                                                            </Col>
                                                        )

                                                        // For a checkbox, we want it to span the full width
                                                        if (item.type === 'checkbox') {
                                                            return (
                                                                <Row className='w-[45%]' key={`row-${index}`}>
                                                                    {formItem}
                                                                </Row>
                                                            )
                                                        }

                                                        return formItem
                                                    })}
                                                </Row>
                                                <Row className='gap-2'>
                                                    <Form.Item>
                                                        <Button variant='primary' htmlType='submit'>
                                                            Save & Continue
                                                        </Button>
                                                    </Form.Item>
                                                </Row>
                                            </Form>
                                        )}
                                    </>
                                ),
                            },
                            {
                                title: (
                                    <div className='flex flex-row gap-2'>
                                        <div>Address Details</div>
                                        {!undeterminedKycErrorState && (
                                            <div>
                                                {getErrorList('address_details')?.errorList?.length > 0 &&
                                                    merchant_details?.onboarding_status?.kwik_checkout_app ===
                                                        'kyc_verification_failed' && (
                                                        <Tooltip
                                                            title={getErrorList('address_details')?.erroListToolTip}
                                                            className=' cursor-pointer'
                                                        >
                                                            <span className='text-red-500 text-lg font-semibold'>
                                                                KYC Error <InfoCircleFilled />
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                ),
                                subTitle: <>{renderCurrentStatus(1)}</>,
                                description: (
                                    <>
                                        {currentSteps === 1 && (
                                            <Form
                                                scrollToFirstError
                                                disabled={
                                                    undeterminedKycErrorState
                                                        ? false
                                                        : merchant_details?.onboarding_status?.kwik_checkout_app ===
                                                              'kyc_verification_failed' &&
                                                          (getErrorList('address_details')?.errorList?.length === 0 ||
                                                              userData?.kycData?.kyc_failure_reasons?.hasOwnProperty(
                                                                  'incurable_kyc_error',
                                                              ))
                                                }
                                                form={form}
                                                initialValues={kycData}
                                                name='dynamic_form'
                                                onFinish={(v) => {
                                                    logEvent(
                                                        'address_save_continue_clicked',
                                                        'click',
                                                        'kyc',
                                                        user_details.email,
                                                        merchant_details.m_id,
                                                    )
                                                    onFinish(v, 1)
                                                }}
                                                // autoComplete='off'
                                            >
                                                <Row gutter={16} className=''>
                                                    {formJson.addressDetails.map((item, index) => {
                                                        const formItem = (
                                                            <Col span={item.colSpan} key={item.name}>
                                                                <Form.Item
                                                                    {...formItemLayout}
                                                                    name={item.name}
                                                                    label={item.label}
                                                                    rules={item.rules}
                                                                >
                                                                    {item.type === 'input' && (
                                                                        <Input
                                                                            onBlur={(e) => {
                                                                                const value = form.getFieldValue(
                                                                                    item.name,
                                                                                )
                                                                                const trimmedValue = value.trim() // Remove all whitespaces
                                                                                form.setFieldsValue({
                                                                                    [item.name]: trimmedValue,
                                                                                })
                                                                            }}
                                                                            placeholder={item.placeholder}
                                                                            className={item?.class}
                                                                        />
                                                                    )}
                                                                    {item.type === 'inputNumber' && (
                                                                        <InputNumber
                                                                            {...(item.name === 'pincode'
                                                                                ? {
                                                                                      onChange: (e) => {
                                                                                          let pin = String(e) + ''
                                                                                          if (pin?.length <= 6) {
                                                                                              setPincode(String(e))
                                                                                              form?.setFieldValue(
                                                                                                  item.name,
                                                                                                  e,
                                                                                              )
                                                                                          }
                                                                                      },
                                                                                  }
                                                                                : {})}
                                                                            value={pincode}
                                                                            maxLength={6}
                                                                            minLength={6}
                                                                            className='w-full'
                                                                            placeholder={item.placeholder}
                                                                        />
                                                                    )}
                                                                    {item.type === 'checkbox' && (
                                                                        <div className='h-[35px] bg-white p-2 mt-[2.5rem] rounded'>
                                                                            <Checkbox className='w-full bg-white text-blue-600'>
                                                                                {/* {item} */}
                                                                            </Checkbox>
                                                                        </div>
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                        )

                                                        // For a checkbox, we want it to span the full width
                                                        if (item.type === 'checkbox') {
                                                            return (
                                                                <Row className='w-[45%]' key={`row-${index}`}>
                                                                    {formItem}
                                                                </Row>
                                                            )
                                                        }

                                                        return formItem
                                                    })}
                                                </Row>
                                                <Row className='gap-2'>
                                                    <Form.Item>
                                                        <Button variant='primary' htmlType='submit'>
                                                            Save & Continue
                                                        </Button>
                                                    </Form.Item>
                                                </Row>
                                            </Form>
                                        )}
                                    </>
                                ),
                            },
                            {
                                title: (
                                    <div className='flex flex-row gap-2'>
                                        <div>Bank Details</div>
                                        {!undeterminedKycErrorState && (
                                            <div>
                                                {getErrorList('bank_details')?.errorList?.length > 0 &&
                                                    merchant_details?.onboarding_status?.kwik_checkout_app ===
                                                        'kyc_verification_failed' && (
                                                        <Tooltip
                                                            title={getErrorList('bank_details')?.erroListToolTip}
                                                            className=' cursor-pointer'
                                                        >
                                                            <span className='text-red-500 text-lg font-semibold'>
                                                                KYC Error <InfoCircleFilled />
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                ),
                                subTitle: <>{renderCurrentStatus(2)}</>,
                                description: (
                                    <>
                                        {currentSteps === 2 && (
                                            <Form
                                                scrollToFirstError
                                                disabled={
                                                    undeterminedKycErrorState
                                                        ? false
                                                        : merchant_details?.onboarding_status?.kwik_checkout_app ===
                                                              'kyc_verification_failed' &&
                                                          (getErrorList('bank_details')?.errorList?.length === 0 ||
                                                              userData?.kycData?.kyc_failure_reasons?.hasOwnProperty(
                                                                  'incurable_kyc_error',
                                                              ))
                                                }
                                                form={form}
                                                name='dynamic_form'
                                                onFinish={(v) => {
                                                    logEvent(
                                                        'bank_details_save_continue_clicked',
                                                        'click',
                                                        'kyc',
                                                        user_details.email,
                                                        merchant_details.m_id,
                                                    )
                                                    onFinish(v, 2)
                                                }}
                                                autoComplete='off'
                                            >
                                                <Row gutter={16} className=''>
                                                    {formJson.bankDetails.map((item, index) => {
                                                        // Calculate column span, adjust as needed for responsiveness
                                                        // const colSpan = item.type === 'checkbox' ? 24 : 11
                                                        const formItem = (
                                                            <Col span={item.colSpan} key={item.name}>
                                                                <Form.Item
                                                                    {...formItemLayout}
                                                                    name={item.name}
                                                                    label={item.label}
                                                                    rules={
                                                                        item.type === 'upload' && bankProofUpload
                                                                            ? []
                                                                            : item.rules
                                                                    }
                                                                >
                                                                    {item.type === 'input' && (
                                                                        <Input
                                                                            onBlur={(e) => {
                                                                                const value = form.getFieldValue(
                                                                                    item.name,
                                                                                )
                                                                                const trimmedValue = value.trim() // Remove all whitespaces
                                                                                form.setFieldsValue({
                                                                                    [item.name]: trimmedValue,
                                                                                })
                                                                            }}
                                                                            placeholder={item.placeholder}
                                                                            className={item?.class}
                                                                        />
                                                                    )}

                                                                    {item.type === 'upload' &&
                                                                        (bankProofUpload ? (
                                                                            <div
                                                                                className={`w-fit flex items-center  bg-blue-50   p-[0.5px] px-1 py-1 rounded-lg border-solid border-blue-400 border-[1px] ${
                                                                                    merchant_details?.onboarding_status
                                                                                        ?.kwik_checkout_app ===
                                                                                        'kyc_verification_failed' &&
                                                                                    getErrorList('bank_details')
                                                                                        ?.errorList?.length === 0
                                                                                        ? 'pointer-events-none cursor-no-drop'
                                                                                        : ''
                                                                                }`}
                                                                            >
                                                                                <FileIcon2 />
                                                                                <a type='link' className='ml-1'>
                                                                                    <span
                                                                                        onClick={() =>
                                                                                            getKycDoc('bank_proof')
                                                                                        }
                                                                                    >
                                                                                        {'File Uploaded'}
                                                                                    </span>
                                                                                    <CloseCircleOutlined
                                                                                        onClick={() => {
                                                                                            deleteFile('bank_proof')
                                                                                            // Clear the validation error
                                                                                            form.setFields([
                                                                                                {
                                                                                                    name: item.name,
                                                                                                    errors: [],
                                                                                                },
                                                                                            ])
                                                                                        }}
                                                                                        className='ml-2'
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                        ) : (
                                                                            <>
                                                                                <Upload
                                                                                    accept='.jpg, .jpeg, .png, .pdf'
                                                                                    customRequest={(v) => {
                                                                                        // @ts-ignore
                                                                                        customRequest(
                                                                                            // @ts-ignore
                                                                                            v,
                                                                                            {
                                                                                                ...item,
                                                                                                upload_url:
                                                                                                    kycData.bank_proof_upload_url,
                                                                                                field: item.name,
                                                                                            },
                                                                                        ).then(() => {
                                                                                            form.setFields([
                                                                                                {
                                                                                                    name: item.name,
                                                                                                    errors: [],
                                                                                                },
                                                                                            ])
                                                                                        })
                                                                                    }}
                                                                                    method='PUT'
                                                                                    name={item.name}
                                                                                    listType='text'
                                                                                    maxCount={1}
                                                                                >
                                                                                    <Button
                                                                                        className='flex items-center  justify-center text-[#004B8D]'
                                                                                        variant='default'
                                                                                    >
                                                                                        <FileIcon />
                                                                                        <span className='ml-2'>
                                                                                            Upload
                                                                                        </span>
                                                                                    </Button>
                                                                                </Upload>
                                                                                <div className='w-full  my-2 '>
                                                                                    <span
                                                                                        className={` text-xs font-normal  text-gray-500 `}
                                                                                    >
                                                                                        Max 5 MB. Accepted formats:
                                                                                        .jpg, .jpeg, .png, .pdf
                                                                                    </span>
                                                                                </div>
                                                                            </>
                                                                        ))}

                                                                    {item.type === 'inputNumber' && (
                                                                        <Input
                                                                            type='number'
                                                                            className='w-full'
                                                                            placeholder={item.placeholder}
                                                                        />
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                        )

                                                        return formItem
                                                    })}
                                                </Row>
                                                <Row className='gap-2'>
                                                    <Form.Item>
                                                        <Button variant='primary' htmlType='submit'>
                                                            Save & Continue
                                                        </Button>
                                                    </Form.Item>
                                                </Row>
                                            </Form>
                                        )}
                                    </>
                                ),
                            },
                            {
                                title: (
                                    <div className='flex flex-row gap-2'>
                                        <div>Business Details</div>
                                        {!undeterminedKycErrorState && (
                                            <div>
                                                {getErrorList('business_details')?.errorList?.length > 0 &&
                                                    merchant_details?.onboarding_status?.kwik_checkout_app ===
                                                        'kyc_verification_failed' && (
                                                        <Tooltip
                                                            title={getErrorList('business_details')?.erroListToolTip}
                                                            className=' cursor-pointer'
                                                        >
                                                            <span className='text-red-500 text-lg font-semibold'>
                                                                KYC Error <InfoCircleFilled />
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                ),
                                subTitle: <>{renderCurrentStatus(3)}</>,
                                description: (
                                    <>
                                        {currentSteps === 3 && (
                                            <Form
                                                scrollToFirstError
                                                disabled={
                                                    undeterminedKycErrorState
                                                        ? false
                                                        : merchant_details?.onboarding_status?.kwik_checkout_app ===
                                                              'kyc_verification_failed' &&
                                                          (getErrorList('business_details')?.errorList?.length === 0 ||
                                                              userData?.kycData?.kyc_failure_reasons?.hasOwnProperty(
                                                                  'incurable_kyc_error',
                                                              ))
                                                }
                                                className='mt-2'
                                                form={form}
                                                onFinish={(v) => {
                                                    logEvent(
                                                        'business_details_save_continue_clicked',
                                                        'click',
                                                        'kyc',
                                                        user_details.email,
                                                        merchant_details.m_id,
                                                    )
                                                    onFinish(v, 3)
                                                }}
                                                layout='vertical'
                                                initialValues={{ ...kycData }}
                                            >
                                                <Row gutter={24}>
                                                    {formJson.buisnessDetails.map((item, index) => {
                                                        // Calculate column span, adjust as needed for responsiveness
                                                        // const colSpan = item.type === 'checkbox' ? 24 : 11
                                                        const formItem = (
                                                            <Col span={item.colSpan} key={item.name}>
                                                                <Form.Item
                                                                    {...formItemLayout}
                                                                    name={item.name}
                                                                    label={item.label}
                                                                    rules={item.rules}
                                                                >
                                                                    {item.type === 'input' && (
                                                                        <Input
                                                                            onBlur={(e) => {
                                                                                const value = form.getFieldValue(
                                                                                    item.name,
                                                                                )
                                                                                const trimmedValue = value.trim() // Remove all whitespaces
                                                                                form.setFieldsValue({
                                                                                    [item.name]: trimmedValue,
                                                                                })
                                                                            }}
                                                                            placeholder={item.placeholder}
                                                                            className={item?.class}
                                                                        />
                                                                    )}
                                                                    {item.type === 'date' && (
                                                                        <DatePicker
                                                                            onChange={(e) => {
                                                                                form.setFieldValue(item.name, dayjs(e))
                                                                            }}
                                                                            placeholder={item.placeholder}
                                                                            className={item?.class}
                                                                            disabledDate={(current) => {
                                                                                // Can not select days after today
                                                                                return current > dayjs().endOf('day')
                                                                            }}
                                                                            allowClear={false}
                                                                        />
                                                                    )}

                                                                    {item.type === 'inputNumber' && (
                                                                        <Input
                                                                            type='number'
                                                                            className='w-full'
                                                                            placeholder={item.placeholder}
                                                                        />
                                                                    )}
                                                                </Form.Item>
                                                            </Col>
                                                        )

                                                        return formItem
                                                    })}
                                                </Row>
                                                <Row className='gap-2'>
                                                    <Form.Item>
                                                        <Button variant='primary' htmlType='submit'>
                                                            Save & Continue
                                                        </Button>
                                                    </Form.Item>
                                                </Row>
                                            </Form>
                                        )}
                                    </>
                                ),
                            },
                            {
                                title: (
                                    <div className='flex flex-row gap-2'>
                                        <div>Document Upload</div>
                                        {!undeterminedKycErrorState && (
                                            <div>
                                                {getErrorList('document_upload')?.errorList?.length > 0 &&
                                                    merchant_details?.onboarding_status?.kwik_checkout_app ===
                                                        'kyc_verification_failed' && (
                                                        <Tooltip
                                                            title={getErrorList('document_upload')?.erroListToolTip}
                                                            className=' cursor-pointer'
                                                        >
                                                            <span className='text-red-500 text-lg font-semibold'>
                                                                KYC Error <InfoCircleFilled />
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                            </div>
                                        )}
                                    </div>
                                ),
                                subTitle: <>{renderCurrentStatus(4)}</>,
                                description: (
                                    <>
                                        {currentSteps === 4 && (
                                            <Form
                                                scrollToFirstError
                                                disabled={
                                                    undeterminedKycErrorState
                                                        ? false
                                                        : merchant_details?.onboarding_status?.kwik_checkout_app ===
                                                              'kyc_verification_failed' &&
                                                          (getErrorList('document_upload')?.errorList?.length === 0 ||
                                                              userData?.kycData?.kyc_failure_reasons?.hasOwnProperty(
                                                                  'incurable_kyc_error',
                                                              ))
                                                }
                                                className='mt-2 inter'
                                                form={form}
                                                onFinish={(v) => {
                                                    logEvent(
                                                        'document_upload_save_continue_clicked',
                                                        'click',
                                                        'kyc',
                                                        user_details.email,
                                                        merchant_details.m_id,
                                                    )
                                                    onFinish(v, 4)
                                                }}
                                                layout='vertical'
                                                initialValues={{ ...kycData }}
                                            >
                                                <Row gutter={24}>
                                                    <div className='w-full pl-3 my-4 '>
                                                        {/* <p className='mb-2'>Documents Upload</p> */}
                                                        <span
                                                            className={`px-2 py-[1px] mr-2 rounded border border-solid text-xs font-normal text-center  bg-orange-50 border-orange-400 text-orange-400 `}
                                                        >
                                                            Max 5 MB. Accepted formats: .jpg, .png, .pdf
                                                        </span>
                                                    </div>

                                                    {Object.entries(documentList).map(([title, items], i) => (
                                                        <Col span={24} key={title}>
                                                            <div className='w-full my-2'>
                                                                <p className='text-sm font-semibold flex items-center'>
                                                                    <span className='text-lg text-[#002547]'>
                                                                        {title}
                                                                    </span>
                                                                    <Tooltip title={renderToolTip(documentList[title])}>
                                                                        <>
                                                                            <QuestionMarkIcon />
                                                                        </>
                                                                    </Tooltip>
                                                                </p>
                                                            </div>
                                                            <Row>
                                                                {
                                                                    // @ts-ignore
                                                                    items?.map((item, index) => {
                                                                        return (
                                                                            <Col
                                                                                span={
                                                                                    item.name == 'address_type'
                                                                                        ? 24
                                                                                        : 12
                                                                                }
                                                                                key={index}
                                                                            >
                                                                                <>
                                                                                    <Form.Item
                                                                                        label={
                                                                                            item.name ===
                                                                                            'aus_board_resolution_authorizing' ? (
                                                                                                <div className=' flex items-center'>
                                                                                                    {item.label}
                                                                                                    <a
                                                                                                        target='_blank'
                                                                                                        href='https://s3.ap-south-1.amazonaws.com/devcdngokwik.co/shopify_documents/generic_br.pdf'
                                                                                                        className='ml-4 flex items-center '
                                                                                                    >
                                                                                                        <span className='mt-1'>
                                                                                                            <FileIcon />
                                                                                                        </span>
                                                                                                        <span className='ml-1 text-blue-500 cursor-pointer'>
                                                                                                            Download
                                                                                                            Sample
                                                                                                        </span>
                                                                                                    </a>
                                                                                                </div>
                                                                                            ) : item.name ===
                                                                                                  'sell_edible_food_products' ||
                                                                                              item.name ===
                                                                                                  'sell_ayurvedic_products' ? null : (
                                                                                                <p className='text-[#002547]'>
                                                                                                    {item.label}
                                                                                                </p>
                                                                                            )
                                                                                        }
                                                                                        name={item.name}
                                                                                        rules={[
                                                                                            {
                                                                                                required: item.uploaded
                                                                                                    ? false
                                                                                                    : item.is_required,
                                                                                                message: `${item.label} is required`,
                                                                                            },
                                                                                        ]}
                                                                                        valuePropName={
                                                                                            item.name ===
                                                                                                'sell_edible_food_products' ||
                                                                                            item.name ===
                                                                                                'sell_ayurvedic_products'
                                                                                                ? 'checked'
                                                                                                : 'file'
                                                                                        }
                                                                                        className={
                                                                                            item.name == 'address_type'
                                                                                                ? 'w-[300px]'
                                                                                                : ''
                                                                                        }
                                                                                        required={!item.uploaded}
                                                                                    >
                                                                                        {item.name.includes(
                                                                                            'address_type',
                                                                                        ) ? (
                                                                                            <Select
                                                                                                {...(item.other_details
                                                                                                    .allowed_options
                                                                                                    .length && {
                                                                                                    value: getEntityTypeValue(
                                                                                                        item
                                                                                                            .other_details
                                                                                                            .allowed_options,
                                                                                                    ),
                                                                                                })}
                                                                                                placeholder={`Select ${item.label.toLowerCase()}`}
                                                                                            >
                                                                                                {item.other_details.allowed_options.map(
                                                                                                    (option, index) => {
                                                                                                        return (
                                                                                                            <Option
                                                                                                                key={
                                                                                                                    index
                                                                                                                }
                                                                                                                value={Number(
                                                                                                                    Object.keys(
                                                                                                                        option,
                                                                                                                    )[0],
                                                                                                                )}
                                                                                                            >
                                                                                                                {
                                                                                                                    option[
                                                                                                                        Object.keys(
                                                                                                                            option,
                                                                                                                        )[0]
                                                                                                                    ]
                                                                                                                }
                                                                                                            </Option>
                                                                                                        )
                                                                                                    },
                                                                                                )}
                                                                                            </Select>
                                                                                        ) : item.name ===
                                                                                              'sell_edible_food_products' ||
                                                                                          item.name ===
                                                                                              'sell_ayurvedic_products' ? (
                                                                                            <Row>
                                                                                                <Switch
                                                                                                    id={item.name}
                                                                                                    onChange={(e) => {
                                                                                                        form.setFieldValue(
                                                                                                            item?.name,
                                                                                                            e,
                                                                                                        )
                                                                                                        setEnableInput(
                                                                                                            (prev) => ({
                                                                                                                ...prev,
                                                                                                                [item.for]:
                                                                                                                    e,
                                                                                                            }),
                                                                                                        )

                                                                                                        set_fassai_ayurvedic_status(
                                                                                                            (prev) => ({
                                                                                                                ...prev,
                                                                                                                [item.for]:
                                                                                                                    e,
                                                                                                            }),
                                                                                                        )
                                                                                                    }}
                                                                                                    checked={
                                                                                                        fassai_ayurvedic_status[
                                                                                                            item.for
                                                                                                        ]
                                                                                                    }
                                                                                                />

                                                                                                <label
                                                                                                    className='ml-2'
                                                                                                    htmlFor={item.name}
                                                                                                >
                                                                                                    {item.label}
                                                                                                </label>
                                                                                            </Row>
                                                                                        ) : (
                                                                                            <>
                                                                                                {item.uploaded ? (
                                                                                                    <Form.Item>
                                                                                                        <div
                                                                                                            className={`w-fit flex items-center bg-blue-50  p-[0.5px] px-1 py-1 rounded-lg border-solid border-blue-400 border-[1px] ${
                                                                                                                undeterminedKycErrorState
                                                                                                                    ? ''
                                                                                                                    : merchant_details
                                                                                                                          ?.onboarding_status
                                                                                                                          ?.kwik_checkout_app ===
                                                                                                                          'kyc_verification_failed' &&
                                                                                                                      getErrorList(
                                                                                                                          'document_upload',
                                                                                                                      )
                                                                                                                          ?.errorList
                                                                                                                          ?.length ===
                                                                                                                          0
                                                                                                                    ? 'pointer-events-none cursor-no-drop'
                                                                                                                    : ''
                                                                                                            }`}
                                                                                                        >
                                                                                                            <FileIcon2 />
                                                                                                            <a
                                                                                                                type='link'
                                                                                                                className='ml-1 text-blue-400 hover:text-blue-400'
                                                                                                            >
                                                                                                                <span
                                                                                                                    onClick={() =>
                                                                                                                        getKycDoc(
                                                                                                                            item.name,
                                                                                                                        )
                                                                                                                    }
                                                                                                                >
                                                                                                                    File
                                                                                                                    Uploaded
                                                                                                                </span>
                                                                                                                <CloseCircleOutlined
                                                                                                                    onClick={() =>
                                                                                                                        deleteFile(
                                                                                                                            item.name,
                                                                                                                        )
                                                                                                                    }
                                                                                                                    className='ml-2 cursor-pointer'
                                                                                                                />
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    </Form.Item>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <Upload
                                                                                                            accept={allowedFileFormats(
                                                                                                                item
                                                                                                                    ?.other_details
                                                                                                                    ?.allowed_format,
                                                                                                            )}
                                                                                                            onChange={() => {
                                                                                                                logEvent(
                                                                                                                    item.name +
                                                                                                                        '_' +
                                                                                                                        'upload_clicked',
                                                                                                                    'click',
                                                                                                                    'kyc',
                                                                                                                    user_details.email,
                                                                                                                    merchant_details.m_id,
                                                                                                                )
                                                                                                            }}
                                                                                                            disabled={
                                                                                                                item.name ===
                                                                                                                    'fssai_license' ||
                                                                                                                item.name ===
                                                                                                                    'ayurvedic_license'
                                                                                                                    ? isInputDisabled(
                                                                                                                          item.name,
                                                                                                                      )
                                                                                                                    : false
                                                                                                            }
                                                                                                            action={
                                                                                                                item.upload_url
                                                                                                            }
                                                                                                            customRequest={(
                                                                                                                v,
                                                                                                            ) => {
                                                                                                                // @ts-ignore
                                                                                                                customRequest(
                                                                                                                    // @ts-ignore
                                                                                                                    v,
                                                                                                                    {
                                                                                                                        ...item,
                                                                                                                        field: item.name,
                                                                                                                    },
                                                                                                                )
                                                                                                            }}
                                                                                                            method='PUT'
                                                                                                            listType='text'
                                                                                                            maxCount={1}
                                                                                                        >
                                                                                                            <Button
                                                                                                                disabled={
                                                                                                                    item.name ===
                                                                                                                        'fssai_license' ||
                                                                                                                    item.name ===
                                                                                                                        'ayurvedic_license'
                                                                                                                        ? isInputDisabled(
                                                                                                                              item.name,
                                                                                                                          )
                                                                                                                        : false
                                                                                                                }
                                                                                                                className={`flex items-center  justify-center text-[#004B8D] border-blue-500 py-1 px-2 disabled:opacity-[0.6]`}
                                                                                                                variant='default'
                                                                                                            >
                                                                                                                <FileIcon />
                                                                                                                <span className='ml-2 text-blue-500'>
                                                                                                                    Upload
                                                                                                                </span>
                                                                                                            </Button>
                                                                                                        </Upload>
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </Form.Item>
                                                                                    {item.name.includes('aadhar') && (
                                                                                        <div className='w-full mb-2 mt-0'>
                                                                                            <span
                                                                                                className={` text-xs font-normal  text-gray-500 `}
                                                                                            >
                                                                                                Max 5 MB. Accepted
                                                                                                formats: .jpg, .png,
                                                                                                .jpeg
                                                                                            </span>
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            </Col>
                                                                        )
                                                                    })
                                                                }
                                                            </Row>
                                                        </Col>
                                                    ))}

                                                    <div className='h-[35px] ml-4 mt-[2.5rem] rounded'>
                                                        <Checkbox
                                                            disabled={
                                                                undeterminedKycErrorState
                                                                    ? false
                                                                    : merchant_details?.onboarding_status
                                                                          ?.kwik_checkout_app ===
                                                                          'kyc_verification_failed' &&
                                                                      getErrorList('document_upload')?.errorList
                                                                          ?.length === 0
                                                            }
                                                            // checked={true}
                                                            checked={form.getFieldValue(
                                                                'accepted_terms_and_conditions',
                                                            )}
                                                            onChange={() => {
                                                                setShowTNCModal(!showTNCModal)
                                                            }}
                                                            className='w-full  text-blue-600'
                                                        >
                                                            Accept Terms & Conditions
                                                        </Checkbox>
                                                    </div>
                                                </Row>
                                                <Row className='gap-2'>
                                                    <Form.Item>
                                                        <Button
                                                            variant='primary'
                                                            htmlType='submit'
                                                            disabled={
                                                                undeterminedKycErrorState
                                                                    ? false
                                                                    : !termsCondition ||
                                                                      (merchant_details?.onboarding_status
                                                                          ?.kwik_checkout_app ===
                                                                          'kyc_verification_failed' &&
                                                                          getErrorList('document_upload')?.errorList
                                                                              ?.length === 0)
                                                            }
                                                        >
                                                            Save & Continue
                                                        </Button>
                                                    </Form.Item>
                                                </Row>
                                            </Form>
                                        )}
                                    </>
                                ),
                            },
                            isCpvMandatory && {
                                title: (
                                    <div className='flex flex-row gap-2'>
                                        <div>Virtual Background Check</div>
                                    </div>
                                ),
                                subTitle: <>{renderCurrentStatus(5)}</>,
                                description: (
                                    <>
                                        {currentSteps === 5 &&
                                            (VBCLink ? (
                                                <>
                                                    <div className='flex justify-between mt-2 w-full'>
                                                        <Alert
                                                            type='info'
                                                            showIcon
                                                            icon={<InfoCircleFilled />}
                                                            className='w-full py-1'
                                                            message={
                                                                <p className='text-sm font-normal leading-6 text-[#002C8C]'>
                                                                    We recommend finishing this on mobile, please read
                                                                    guide for instructions.
                                                                </p>
                                                            }
                                                        />
                                                    </div>

                                                    <p className='text-sm font-normal leading-custom-22 text-gray-600 mt-6'>
                                                        Please ensure the below mentioned pre-requisite to start the
                                                        process :
                                                    </p>

                                                    <ol>
                                                        <li className='text-sm font-normal leading-custom-22 text-gray-600'>
                                                            Please ensure you are doing CPV from your{' '}
                                                            <span className='text-black font-semibold'>
                                                                business location (registered office address)
                                                            </span>
                                                        </li>
                                                        <li className='text-sm font-normal leading-custom-22 text-gray-600'>
                                                            Provide{' '}
                                                            <span className='text-black font-semibold'>
                                                                Camera, location & microphone access{' '}
                                                            </span>
                                                            whenever prompted after starting the Virtual background
                                                            check.
                                                        </li>
                                                        <li className='text-sm font-normal leading-custom-22 text-gray-600'>
                                                            If you need to send the background check link to other
                                                            device , you can copy the link using{' '}
                                                            <span
                                                                className='text-[#004B8D] cursor-pointer'
                                                                onClick={onCopy}
                                                            >
                                                                {isCopied ? (
                                                                    <>Copied!</>
                                                                ) : (
                                                                    <>
                                                                        <CopyOutlined /> Copy link
                                                                    </>
                                                                )}
                                                            </span>
                                                        </li>
                                                    </ol>

                                                    {VBCLink && (
                                                        <Form
                                                            layout='vertical'
                                                            form={form}
                                                            onFinish={(v) => onFinish(v, 5)}
                                                            className='mt-6'
                                                            initialValues={{ vbc_link: VBCLink }}
                                                        >
                                                            <Row>
                                                                <Row className='ml-2 items-center'>
                                                                    <Tooltip title='Open In New Tab'>
                                                                        <Button
                                                                            // type='link'
                                                                            target='_blank'
                                                                            className='ml-2'
                                                                            variant='primary'
                                                                            onClick={onOpenInNewTab}
                                                                        >
                                                                            Start Virtual Background Check
                                                                        </Button>
                                                                    </Tooltip>
                                                                </Row>
                                                            </Row>
                                                        </Form>
                                                    )}
                                                </>
                                            ) : (
                                                <div className='flex justify-center items-center'>
                                                    <div className='flex flex-col justify-center items-center'>
                                                        <Spin />
                                                        <p>Fetching Video KYC Link</p>
                                                    </div>
                                                </div>
                                            ))}
                                    </>
                                ),
                            },
                        ]}
                    />

                    {merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_verification_failed' &&
                        (getErrorList('document_upload')?.errorList?.length > 0 ||
                            getErrorList('bank_details')?.errorList?.length > 0 ||
                            getErrorList('address_details')?.errorList?.length > 0 ||
                            getErrorList('business_details')?.errorList?.length > 0 ||
                            getErrorList('basic_info')?.errorList?.length > 0 ||
                            kycFailureReason.length > 0) && (
                            <>
                                <Row justify={'end'}>
                                    {!(
                                        merchant_details?.onboarding_status?.kwik_checkout_app ===
                                            'kyc_verification_failed' && isCpvMandatory
                                    ) && (
                                        <Col span={24}>
                                            <div className='w-full text-start p-4 pr-0'>
                                                <Checkbox
                                                    onChange={() => setReSubmitState(!reSubmitState)}
                                                    value={reSubmitState}
                                                >
                                                    I have completed the review and corrected all KYC errors and I'm
                                                    ready to re-submit for KYC review.
                                                </Checkbox>
                                                <Button
                                                    disabled={undeterminedKycErrorState ? false : !reSubmitState}
                                                    onClick={sendErrorDocs}
                                                    variant='primary'
                                                    className='mt-3'
                                                >
                                                    Re-Submit
                                                </Button>
                                            </div>
                                        </Col>
                                    )}

                                    <Col span={24}>
                                        {showKYCErrorMessage && (
                                            <Alert
                                                className='w-full p-2'
                                                message={
                                                    <span className='text-yellow-500 text-sm font-semibold'>
                                                        Please Wait !
                                                    </span>
                                                }
                                                description={
                                                    <div className='text-yellow-500 '>
                                                        It will take some time to submit your details.
                                                    </div>
                                                }
                                                type='warning'
                                                showIcon
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </>
                        )}
                </div>
            )}
            <Modal
                cancelText={'Decline'}
                okText={'I Agree'}
                centered
                // className='w-1/2'
                width={'45vw'}
                title={
                    <Row className='gap-2'>
                        <CloseCircleOutlined
                            size={50}
                            onClick={() => {
                                form.setFieldValue('accept_terms_and_conditions', false)
                                setTermsCondition(false)
                                setShowTNCModal(false)
                            }}
                        />
                        <p>Terms and Condtions</p>
                    </Row>
                }
                open={showTNCModal}
                closeIcon={null}
                onOk={() => {
                    logEvent('basic_info_tc_agree_clicked', 'click', 'kyc', user_details.email, merchant_details.m_id)
                    form.setFieldValue('accepted_terms_and_conditions', true)
                    setTermsCondition(true)
                    setShowTNCModal(false)
                }}
                onCancel={() => {
                    logEvent('basic_info_tc_decline_clicked', 'click', 'kyc', user_details.email, merchant_details.m_id)
                    form.setFieldValue('accepted_terms_and_conditions', false)
                    setTermsCondition(false)
                    setShowTNCModal(false)
                }}
            >
                <div className='max-h-[70vh] overflow-auto'>
                    <div className='terms' id='terms' dangerouslySetInnerHTML={{ __html: Terms }}></div>
                </div>
            </Modal>
        </>
    )
}

export default KYCForm
