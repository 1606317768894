import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

export const getSelectedPlan = async (mid: string = null, isSkiploader = false) => {
    try {
        const resp = await makeAPICall({
            method: 'get',
            url: process.env.KWIKPASS_DASHBOARD_BASE_URL + APIEndPoints.getKpMerchantConfigs,
            skipMode: true,
            headers: {
                'kp-merchant-id': mid,
            },
            skipLoader: isSkiploader,
        })
        return resp?.data?.data?.shopifyAppPlan
    } catch (error) {
        console.error('kwikpassDashboardService -> inside getMerchantConfigs() -> Error: ', error)
    }
}

export const getAllPricingPlans = async () => {
    try {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.KWIKPASS_DASHBOARD_BASE_URL + APIEndPoints.getAllPlan,
        })
        return response
    } catch (error) {
        console.log(error)
    }
}

export const shopifyAppNotification = async (mid) => {
    try {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.KWIKPASS_DASHBOARD_BASE_URL + APIEndPoints.shopifyAppNotification,
            headers: {
                'kp-merchant-id': mid,
            },
        })
        return response.data
    } catch (error) {
        console.log(error)
    }
}

export const getPriceDetails = (plan) => {
    if (+plan?.price === 0) {
        return 'Free'
    }
    if (plan?.planName === 'Custom') {
        return 'Contact sales'
    }
    return `$${plan?.price}`
}
