// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phone-input-container {
    width: 100%;
}

.phone-input-container .phone-input {
    width: 100%;
    height: 32px;
    padding: 4px 12px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    outline: none;
    box-shadow: none;
    transition: border-color 0.2s ease-in-out;
}

.react-international-phone-input-container .react-international-phone-input {
    width: -webkit-fill-available;
    max-height: 32px;
}

.react-international-phone-country-selector {
    max-height: 32px;
}

.react-international-phone-input-container .react-international-phone-country-selector-button {
    height: 32px;
}

.phone-input-container .phone-input:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.phone-input-container .country-dropdown-button {
    background-color: transparent;
    border: none;
    color: #000;
    padding: 0;
    outline: none;
    cursor: pointer;
}

.phone-input-container .flag-dropdown {
    border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/passwordSignup/password-signup.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,yCAAyC;AAC7C;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,6CAA6C;AACjD;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,WAAW;IACX,UAAU;IACV,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".phone-input-container {\n    width: 100%;\n}\n\n.phone-input-container .phone-input {\n    width: 100%;\n    height: 32px;\n    padding: 4px 12px;\n    border: 1px solid #d9d9d9;\n    border-radius: 4px;\n    outline: none;\n    box-shadow: none;\n    transition: border-color 0.2s ease-in-out;\n}\n\n.react-international-phone-input-container .react-international-phone-input {\n    width: -webkit-fill-available;\n    max-height: 32px;\n}\n\n.react-international-phone-country-selector {\n    max-height: 32px;\n}\n\n.react-international-phone-input-container .react-international-phone-country-selector-button {\n    height: 32px;\n}\n\n.phone-input-container .phone-input:focus {\n    border-color: #40a9ff;\n    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);\n}\n\n.phone-input-container .country-dropdown-button {\n    background-color: transparent;\n    border: none;\n    color: #000;\n    padding: 0;\n    outline: none;\n    cursor: pointer;\n}\n\n.phone-input-container .flag-dropdown {\n    border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
