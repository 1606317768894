import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useAppDispatch } from '@library/utilities/hooks'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { fetchUserDetails } from '@store/user'
import { message } from 'gokwik-ui-kit'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { navigateToUrl } from 'single-spa'
const OAuthCallback = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const code = params.get('code')
        const page = params.get('state')

        const handleCredentialResponse = async (code) => {
            if (sessionStorage.getItem('google-sso')) {
                return
            }

            try {
                const response = await makeAPICall({
                    method: 'get',
                    url: process.env.REACT_APP_BASE_URL + APIEndPoints.googleSignin,
                    params: { code },
                })

                if (response.status === 401) {
                    message.error('Authentication failed! Please sign in using valid credentials')
                    logEvent('google_auth_invalid_creds', 'click', 'login', localStorage.getItem('email'), '', '', '', {
                        reason: response?.response?.data?.message,
                    })
                    navigateToUrl('/login')
                    return
                }

                if (response.success) {
                    logEvent('google_auth_signin_success', 'click', 'login', localStorage.getItem('email'))
                    message.success('Signed In Successfully')
                    sessionStorage.setItem('merchant-mid', process.env.DEFAULT_MERCHANT_ID)

                    if (response.data.data?.current_dashboard === 'old') {
                        window.location.replace(
                            process.env.REACT_APP_OLD_DASHBOARD_URL + '/login' + `?t=${response.data.data.token}`,
                        )
                        return
                    }

                    localStorage.setItem('currentPage', 'dashboard')
                    sessionStorage.setItem('google-sso', 'true')
                    localStorage.setItem('email', response.data.data.email)

                    if (!response.data.data.is_internal_user) {
                        localStorage.setItem(
                            'mode',
                            response.data.data?.role?.permissions?.includes?.('live_mode') ? 'live' : 'test',
                        )
                    }

                    const userPath = await dispatch(fetchUserDetails(null, true))
                    const redirectTo = sessionStorage.getItem('redirectTo') || userPath || '/checkout/orders'
                    navigateToUrl(redirectTo)
                    sessionStorage.removeItem('redirectTo')
                    sessionStorage.removeItem('landed_otp_page')
                    logEvent('dashboard_landed_success_via_login_page', 'click', 'login', localStorage.getItem('email'))
                } else {
                    navigateToUrl('/login')
                    message.error('Something went wrong! Please sign in using valid credentials')
                    logEvent('google_auth_signin_failed', 'click', 'login', localStorage.getItem('email'), '', '', '', {
                        reason: response?.response?.data?.message,
                    })
                }
            } catch (error) {
                console.log('ERR:', error)
            }
        }

        console.log(page, 'page')

        if (code) {
            if (page === 'login') {
                handleCredentialResponse(code)
            } else {
                setTimeout(() => {
                    navigate(`/executive-summary`, {
                        state: {
                            code: code,
                        },
                    })
                }, 0)
            }
        } else {
            setTimeout(() => {
                navigate(`/${page}`)
            }, 0)
        }

        return () => {
            if (page !== 'login') {
                dispatch(fetchUserDetails())
            }
        }
    }, [])

    return <div> Loading...</div>
}

export default OAuthCallback
