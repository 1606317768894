import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Divider,
    Input,
    Button,
    message,
    FileSearchOutlined,
    FileWordOutlined,
    BankOutlined,
    GlobalOutlined,
    ShopOutlined,
    EnvironmentOutlined,
    EyeOutlined,
} from 'gokwik-ui-kit'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserData, getUserDetails, isMerchantSelector } from '@store/user/selectors'
import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import dayjs from 'dayjs'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { Link } from 'react-router-dom'

const Account = () => {
    const userDetails = useSelector(getUserData)
    const kycData = userDetails.kycData
    const isMerchantUser = useSelector(isMerchantSelector)
    const merchantData = useSelector(getMerchantDetails)
    const [paymentNotificationURL, setPaymentNotificationURL] = useState(merchantData?.payment_notification_url || '')
    const [webhookNotifyEmail, setWebhookNotifyEmail] = useState(merchantData?.webhook_notify_emails || '')
    const [showAppId, setShowAppId] = useState(false)
    const [showAppSecret, setShowAppSecret] = useState(false)

    const overview = [
        { key: 'business_name', name: 'Business Name' },
        { key: 'short_name', name: 'Short Name' },
        { key: 'is_kyc_clarification', name: 'KYC Clarification' },
    ]
    // Address Details
    const addressDetails = [
        { key: 'address1', name: 'Address Line 1' },
        { key: 'address2', name: 'Address Line 2' },
        { key: 'city', name: 'City' },
        { key: 'pincode', name: 'Pincode' },
        { key: 'state', name: 'State' },
        { key: 'country', name: 'Country' },
    ]

    // Bank Details
    const bankDetails = [
        { key: 'bank_account_name', name: 'Account Holder Name' },
        { key: 'bank_account_number', name: 'Account Number', render: (e) => hideAccountNumber(e, 5) },
        { key: 'bank_account_type', name: 'Account Type' },
        { key: 'bank_branch', name: 'Bank Branch' },
        { key: 'bank_branch_ifsc', name: 'IFSC Code' },
    ]

    // Business Details
    const businessDetails = [
        { key: 'business_name', name: 'Business Name' },
        { key: 'business_category', name: 'Business Category' },
        { key: 'business_subcategory', name: 'Business Subcategory' },
        { key: 'business_description', name: 'Business Description' },
        { key: 'business_phone', name: 'Business Phone' },
        { key: 'business_phone_verified', name: 'Business Phone Verified' },
    ]

    // Company Details
    const companyDetails = [
        { key: 'company_cin', name: 'Company CIN' },
        { key: 'company_cin_verification_status', name: 'Company CIN Verification Status' },
        { key: 'company_gstin', name: 'Company GSTIN' },
        { key: 'company_gstin_verification_status', name: 'Company GSTIN Verification Status' },
        { key: 'company_pan', name: 'Company PAN' },
        { key: 'company_pan_name', name: 'Company PAN Name' },
        { key: 'company_pan_verification_status', name: 'Company PAN Verification Status' },
    ]

    // Payment Details
    const paymentDetails = [
        { key: 'payment_notification_url', name: 'Payment Notification URL' },
        // Add more payment-related details if needed
    ]

    // Other Details
    const otherDetails = [
        { key: 'm_id', name: 'Merchant ID' },
        {
            key: 'app_id',
            name: 'App ID',
            render: (e) => (
                <p>
                    {showAppId ? e : hideAccountNumber(e, 32)}
                    <EyeOutlined onClick={() => setShowAppId(!showAppId)} className='ml-2' />
                </p>
            ),
        },
        {
            key: 'app_secret',
            name: 'App Secret',
            render: (e) => (
                <p>
                    {showAppSecret ? e : hideAccountNumber(e, 32)}
                    <EyeOutlined onClick={() => setShowAppSecret(!showAppSecret)} className='ml-2' />
                </p>
            ),
        },
        { key: 'id', name: 'ID' },
    ]

    const saveChange = async () => {
        if (!paymentNotificationURL || !paymentNotificationURL) {
            message.warning('Please fill all the fields')
            return
        }
        const res = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.updatePaymentURL,
            method: 'post',
            payload: {
                payment_notification_url: paymentNotificationURL?.replace(/\s/g, '') || '',
                webhook_notify_emails: webhookNotifyEmail?.replace(/\s/g, '') || '',
            },
        })
        if (res.success) {
            message.success('Updated Successfully')
        } else {
            message.error('Updated failed')
        }
    }

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'account',
                href: '/general/account',
                text: 'Account',
            },
        ])
        logEvent(
            'admin_account_clicked',
            'click',
            'Admin Account',
            userDetails?.userDetails?.email,
            merchantData?.m_id,
            merchantData?.short_name,
            userDetails?.userDetails?.name,
        )
    }, [])

    function hideAccountNumber(accountNumber, num) {
        if (accountNumber?.length < num) {
            return accountNumber
        }
        const hiddenPart = 'X'.repeat(num)
        const visiblePart = accountNumber?.slice(num)
        return hiddenPart + visiblePart
    }

    const itemRender = (name, render, key) => {
        return (
            <>
                <p className='text-xs text-stone-500 '>{name}</p>
                <p className='text-sm mt-1'>{render ? render : merchantData[key]}</p>
            </>
        )
    }

    return (
        <div className='inter w-full mx-auto'>
            <Row className='gap-4'>
                <Col className='bg-white rounded-md overflow-hidden' span={8}>
                    <div className='p-2 bg-white'>
                        <p className='text-base '>
                            <FileSearchOutlined /> Overview
                        </p>
                    </div>
                    <Divider className='my-1 ' />
                    <div className='flex justify-between p-2'>
                        <div>
                            {overview.map((itm) => (
                                <Col key={itm.key} className='my-2'>
                                    <p className='text-xs text-stone-500 '>{itm.name}</p>
                                    <p className='text-sm mt-1'>{merchantData[itm.key]}</p>
                                </Col>
                            ))}
                        </div>
                        {merchantData?.onboarding_status?.kwik_checkout_app !== 'setup_completed' &&
                            merchantData?.onboarding_status?.kwik_checkout_app !== 'kwik_checkout_button_activated' && (
                                <div>
                                    <Link to='/onboarding/kyc'>
                                        <Button variant='primary'>Click Here To Complete KYC</Button>
                                    </Link>
                                </div>
                            )}
                    </div>
                </Col>
                <Col className='bg-white rounded-md overflow-hidden' span={7}>
                    <div className='p-2 bg-white'>
                        <p className='text-base '>
                            <FileWordOutlined /> Business Details
                        </p>
                    </div>
                    <Divider className='my-1 ' />
                    <div className='p-2'>
                        {businessDetails.map((itm) => (
                            <Col key={itm.key} className='my-2'>
                                <p className='text-xs text-stone-500 '>{itm.name}</p>
                                <p className='text-sm mt-1'>{merchantData[itm.key]}</p>
                            </Col>
                        ))}
                    </div>
                </Col>
                <Col className='bg-white rounded-md overflow-hidden' span={8}>
                    <div className='p-2 bg-white'>
                        <p className='text-base '>
                            <BankOutlined /> Bank Details
                        </p>
                    </div>
                    <Divider className='my-1 ' />
                    <div className='p-2'>
                        <Row justify='space-between' className='h-1/2'>
                            <Col>
                                {bankDetails.map((itm) => (
                                    <Col key={itm.key} className='my-2'>
                                        {itemRender(
                                            itm.name,
                                            itm?.render && itm?.render(merchantData[itm.key]),
                                            itm.key,
                                        )}
                                    </Col>
                                ))}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row className='w-full gap-4 my-2'>
                <Col className='bg-white rounded-md overflow-hidden' span={8}>
                    <div className='p-2 bg-white'>
                        <p className='text-base '>
                            <GlobalOutlined /> Advanced Details
                        </p>
                    </div>
                    <Divider className='my-1 ' />
                    <div className='p-2'>
                        {otherDetails.map((itm) => (
                            <Col key={itm.key} className='my-2'>
                                {itemRender(itm.name, itm?.render && itm?.render(merchantData[itm.key]), itm.key)}
                            </Col>
                        ))}
                    </div>
                </Col>
                <Col className='bg-white rounded-md overflow-hidden' span={7}>
                    <div className='p-2 bg-white'>
                        <p className='text-base '>
                            <ShopOutlined /> Company Details
                        </p>
                    </div>
                    <Divider className='my-1 ' />
                    <div className='p-2'>
                        {companyDetails.map((itm) => (
                            <Col key={itm.key} className='my-2'>
                                <p className='text-xs text-stone-500 '>{itm.name}</p>
                                <p className='text-sm mt-1'>
                                    {itm.key == 'business_pan_registration_date'
                                        ? dayjs(merchantData[itm.key]).format('DD, MMM, YYYY')
                                        : merchantData[itm.key]}
                                </p>
                            </Col>
                        ))}
                    </div>
                </Col>
                <Col className='bg-white rounded-md overflow-hidden' span={8}>
                    <div className='p-2 bg-white'>
                        <p className='text-base '>
                            <EnvironmentOutlined /> Address Details
                        </p>
                    </div>
                    <Divider className='my-1 ' />
                    <div className='p-2'>
                        {addressDetails.map((itm) => (
                            <Col key={itm.key} className='my-2'>
                                <p className='text-xs text-stone-500 '>{itm.name}</p>
                                <p className='text-sm mt-1'>{merchantData[itm.key]}</p>
                            </Col>
                        ))}
                    </div>
                </Col>
            </Row>

            <Row className='gap-4  rounded-md my-4'>
                <Col className='bg-white rounded-md overflow-hidden p-2 ' span={11}>
                    <div className='p-2 bg-white'>
                        <p className='text-lg '>Payment Notification URL</p>
                    </div>
                    <Input
                        value={paymentNotificationURL}
                        onChange={(e) => setPaymentNotificationURL(e.target.value)}
                        placeholder='Enter notification url'
                        className='w-full '
                    />
                </Col>
                <Col className='bg-white rounded-md overflow-hidden p-2 ml-3' span={12}>
                    <div className='p-2 bg-white'>
                        <p className='text-lg '>Webhook Notify Email Hint</p>
                    </div>
                    <Input
                        value={webhookNotifyEmail}
                        onChange={(e) => setWebhookNotifyEmail(e.target.value)}
                        placeholder='Webhook notify email'
                        className='w-full'
                    />
                </Col>
            </Row>
            <div className='flex justify-end items-end w-full p-2 px-4'>
                <Button onClick={saveChange} variant='primary'>
                    {' '}
                    SAVE
                </Button>
            </div>
        </div>
    )
}

export default Account
