const PasswordIcon = () => {
    return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='vuesax/linear/lock'>
                <g id='lock'>
                    <path
                        id='Vector'
                        d='M4 6.66536V5.33203C4 3.12536 4.66667 1.33203 8 1.33203C11.3333 1.33203 12 3.12536 12 5.33203V6.66536'
                        stroke='#002D55'
                        stroke-width='1.6'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <path
                        id='Vector_2'
                        d='M8.00016 12.3333C8.92064 12.3333 9.66683 11.5871 9.66683 10.6667C9.66683 9.74619 8.92064 9 8.00016 9C7.07969 9 6.3335 9.74619 6.3335 10.6667C6.3335 11.5871 7.07969 12.3333 8.00016 12.3333Z'
                        stroke='#002D55'
                        stroke-width='1.6'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <path
                        id='Vector_3'
                        d='M11.3335 14.668H4.66683C2.00016 14.668 1.3335 14.0013 1.3335 11.3346V10.0013C1.3335 7.33464 2.00016 6.66797 4.66683 6.66797H11.3335C14.0002 6.66797 14.6668 7.33464 14.6668 10.0013V11.3346C14.6668 14.0013 14.0002 14.668 11.3335 14.668Z'
                        stroke='#002D55'
                        stroke-width='1.6'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                </g>
            </g>
        </svg>
    )
}

export default PasswordIcon
