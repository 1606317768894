const EmailIcon = () => {
    return (
        <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g id='vuesax/linear/sms'>
                <g id='sms'>
                    <path
                        id='Vector'
                        d='M11.3335 13.6654H4.66683C2.66683 13.6654 1.3335 12.6654 1.3335 10.332V5.66536C1.3335 3.33203 2.66683 2.33203 4.66683 2.33203H11.3335C13.3335 2.33203 14.6668 3.33203 14.6668 5.66536V10.332C14.6668 12.6654 13.3335 13.6654 11.3335 13.6654Z'
                        stroke='#002D55'
                        stroke-width='1.6'
                        stroke-miterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                    <path
                        id='Vector_2'
                        d='M11.3332 6L9.2465 7.66667C8.55983 8.21333 7.43317 8.21333 6.7465 7.66667L4.6665 6'
                        stroke='#002D55'
                        stroke-width='1.6'
                        stroke-miterlimit='10'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                    />
                </g>
            </g>
        </svg>
    )
}

export default EmailIcon
