import RenderSearchFilters from '@library/components/search-filter'
import { Checkbox, Input, LinkOutlined, Modal, Row, Switch, Table, Tooltip } from 'gokwik-ui-kit'
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, ColumnProps, Tag, message } from 'gokwik-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { getMerchantConfigs, getMerchantPlatform, getUserDetails, isMerchantSelector } from '@store/user/selectors'
import { filterDateFormatter, makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import dayjs from 'dayjs'
import { previousMerchantFilters } from '@store/filters/selectors'
import { saveFiltersData } from '@store/filters'
import { handleError } from '@library/utilities/helpers/handleError'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

const today = dayjs()
const formattedDate = filterDateFormatter(today)


const checkCodCharge = (rto_config) => {
    let cod_charge = false;
    rto_config?.every((itm) => {
      if (itm.cod_charge != 0) {
        cod_charge = true;
        return true;
      }
    });
    return cod_charge;
  };
  const checkUpiPercent = (rto_config) => {
    let upi_percent = false;
    rto_config?.every((itm) => {
      if (itm.upi_percent != 0) {
        upi_percent = true;
        return true;
      }
    });
    return upi_percent;
  };

  const formatStatus = (itm) =>{
    return  itm ? "Yes" :"No"
  }
  
  const  formatData = (itm,key) =>{
    return <>
                {itm['merchant_tracker'] &&
                itm['merchant_tracker'][key] &&
                Array.isArray(itm['merchant_tracker'][key]) &&
                itm['merchant_tracker'][key].length
                ? itm['merchant_tracker'][key].join(', ')
                : ''}
            </>
  }
export default function Merchants() {
    const prevFilters = useSelector(previousMerchantFilters)
    const [total, setTotal] = useState(0)
    const [merchants, setMerchants] = useState([])
    const dispatch = useDispatch()
    const [openActivate,setopenActivate] = useState(null)
    const [reason,setReason] = useState("")
    const [openDeactivate,setDeactivateModal] = useState(null)
    const [activateText,setActivateText] = useState('')
    const [deActivateText,setDeactivateText] = useState('')
    const [whitelistCheckout,setWhitelistCheckout] = useState(false)
    const [whitelistExperiment,setWhitelistExperiment] = useState(false)
    const [parameters, setParameters] = useState({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        searchMerchantId: '',
        searchWebsite: '',
        domain: '',
        platform: '',
        merchant_status: '',
        kyc_completion_status: '',
        rto_enabled: false,
        app_onboarding_status: '',
        ...prevFilters,
    })

    const columns: ColumnProps<any>[] = useMemo(
        () => [
            {
                title: <span>S No.</span>,
                dataIndex: 'index',
                fixed: 'left',
                width: 20,
            },
            {
                title: <span>Merchant Name</span>,
                dataIndex: 'business_name',
                fixed: 'left',
                width: 50,
                render: (t,itm) => <div className=' text-blue-500 cursor-pointer '><Tooltip title={<><span>Merchant ID : {itm.m_id}  </span><br/>ID : {itm.id}</>}>{t}</Tooltip></div>,
            },
            {
                title: <span>Active Status</span>,
                dataIndex: 'is_active',
                width: 30,
                fixed: 'left',
                render: (t,itm) => { 
                    return  itm?.platform === "shopify" ? (
                    <div>
                        <Switch onClick={() =>{ t ? setDeactivateModal(itm) : setopenActivate(itm) }}  value={t} />
                    </div>
                ) : "N/A"},
            },
            {
                title: <span>Merchant ID</span>,
                dataIndex: 'm_id',
                width: 50,
                render: (t) => <p className='truncate w-48'>{t || "N/A"}</p>,
                // align:"center"
            },
            {
                title: <span>Status</span>,
                dataIndex: 'merchant_status',
                width: 40,
                render:(e,itm) => <span>{itm['merchant_tracker'] && itm['merchant_tracker']["merchant_status"] || 'N/A'}</span>,
                align: 'center',
            },
            {
                title: <span>Product</span>,
                dataIndex: 'product_type',
                width: 80,
                render: (t,itm) => <p className='truncate w-full text-center '>{formatData(itm,"product_type") || "N/A"} </p>,
                align: 'center',
            },
            {
                title: <span>Platform tags</span>,
                dataIndex: 'platform_tags',
                width: 80,
                render: (t,itm) => <p className='truncate w-full text-center'>{formatData(itm,"platform_tags") || "N/A"}</p>,
                align:"center"
            },
            {
                title: <span>Device</span>,
                dataIndex: 'merchant_device',
                width: 80,
                render: (t,itm) => <p className='truncate w-full text-center'>{formatData(itm,"merchant_device") || "N/A"}</p>,
                align: 'center',
            },
            {
                title: <span>Website</span>,
                align: 'center',
                width: 50,
                dataIndex: 'website',
                render: (e, itm) => e ? <Tooltip title={e}><a href={e} target='_blank'><LinkOutlined className='text-blue-400' /></a></Tooltip>  : <span>N/A</span>,
            },
            {
                title: <span>Go Live Date</span>,
                dataIndex: 'rto_go_live_date',
                align: 'center',
                width: 40,
                render: (e, itm) => <div className='flex justify-center'>{itm?.Merchant_config?.rto_go_live_date ? dayjs(itm?.Merchant_config?.rto_go_live_date).format("DD/MMM/YYYY") : "N/A"}</div>,
            },
            {
                title: <span>RTO Sol Live</span>,
                dataIndex: 'type_of_rto',
                align: 'center',
                width: 40,
                render: (e, itm) => <div className='flex justify-center'>{e || "N/A"}</div>,
            },
            {
                title: <span>COD Blocking</span>,
                dataIndex: 'threshhold_rto_flag',
                align: 'center',
                width: 40,
                render: (e, itm) => <div className='flex justify-center'>{itm?.Merchant_config && itm?.Merchant_config['threshhold_rto_flag'] ? 'Yes' : 'No' }</div>,
            },
            {
                title: <span>A/B</span>,
                dataIndex: 'ab_control_enable',
                align: 'center',
                width: 40,
                render: (e, itm) => <div className='flex justify-center'>{formatStatus(itm?.ab_control_enable)}</div>,
            },
            {
                title: <span>Models in Shadow</span>,
                dataIndex: 'is_shadow_deployed',
                align: 'center',
                width: 40,
                render: (e, itm) => <div className='flex justify-center'>{formatStatus(itm?.is_shadow_deployed)}</div>,
            },
            {
                title: <span>RTO COD Fee</span>,
                dataIndex: 'cod_charge',
                align: 'center',
                width: 40,
                render: (e, itm) => <div className='flex justify-center'>{formatStatus(checkCodCharge(itm?.Merchant_config?.rto_config))}</div>,
            },
            {
                title: <span>UPI Percentage</span>,
                dataIndex: 'upi_percent',
                align: 'center',
                width: 40,
                render: (e, itm) => <div className='flex justify-center'>{formatStatus(checkUpiPercent(itm?.Merchant_config?.rto_config))}</div>,
            },
            {
                title: <span>Product ID blocking</span>,
                dataIndex: 'prod_id_block',
                align: 'center',
                width: 40,
                render: (e, itm) => <div className='flex justify-center'>{e || "N/A"}</div>,
            },
            {
                title: <span>Pincode blocking</span>,
                dataIndex: 'pincode_block',
                align: 'center',
                width: 40,
                render: (e, itm) => <div className='flex justify-center'>{e || "N/A"}</div>,
            },
            {
                title: <span>UTM blocking</span>,
                dataIndex: 'utm_block',
                align: 'center',
                width: 40,
                render: (e, itm) => <div className='flex justify-center'>{e || "N/A"}</div>,
            },
            {
                title: <span>COD Confirmation Prompt</span>,
                dataIndex: 'cod_confirmation',
                align: 'center',
                width: 40,
                render: (e, itm) => <div className='flex justify-center'>{e || "N/A"}</div>,
            },
        ],
        [],
    )
    const displayedColumns = parameters.rto_enabled ? columns : columns.slice(0, 9);
 

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'Merchants',
                href: '/general/merchants',
                text: 'Merchants',
            },
        ])
    }, [])
    useEffect(() => {
        if (Object.keys(prevFilters).length && JSON.stringify(prevFilters) !== JSON.stringify(parameters))
            setParameters({ ...prevFilters })
    }, [prevFilters])

    useEffect(() => {
        getMerchantList(parameters)
    }, [parameters.page,parameters.rto_enabled])

    const getMerchantList = async (parameters:any) => {
        if (!parameters.page) return
        const res = await makeAPICall({
            url:
                process.env.REACT_APP_BASE_URL +
                APIEndPoints.getAllMerchants, 
            method: 'get',
            params:{...parameters}
        })
        if (res.success) {
            setMerchants(res?.data?.data?.merchants?.map((itm,idx) =>({...itm,index:idx+1})) || [])
            setTotal(res.data.data?.totalCount)
        }
        dispatch<any>(saveFiltersData('merchants', { ...parameters }))
    }

    const reset = (e) => {
        e.preventDefault()
        const newParams = {
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
            start_dt: formattedDate,
            end_dt: formattedDate,
        }
        setParameters({ ...newParams })
        getMerchantList({...newParams})
    }
    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
        // getMerchantList(parameters)
    }

    const handleSearchClick = (e) => {
        console.log(parameters)
        e?.preventDefault()
        if (parameters) {
           getMerchantList({...parameters ,page:1})
        }
    }

    const changeMerchantStatus = async (statusData) =>{
        try{
        const res = await makeAPICall({
            method:'post',
            url:process.env.REACT_APP_BASE_URL + APIEndPoints.merchantAppStateUpdate,
            payload:{
                mid:statusData.id ,
                state:statusData.is_active ? "disable" :"enable",
                "whitelist_domain_checkout": whitelistCheckout,
                "whitelist_domain_experiment": whitelistExperiment
            }
        })
        if(res?.success){
            message.success(res?.data.data)
        }else{
            message.error(res.response?.data?.message || "Somethign went wrong")
        }
        getMerchantList(parameters)
        setActivateText("")
        setDeactivateText("")
        setDeactivateModal(null)
        setopenActivate(null)
        setWhitelistCheckout(false)
        setWhitelistExperiment(false)
        setReason("")
    }
        catch(err) {
            handleError(err)
        }
    }

    return (
        <div className='w-full'>
                    <div className='bg-white rounded overflow-auto'>
                        <RenderSearchFilters
                            values={parameters}
                            setValues={(data, reset) => {
                                console.log(data)
                                setParameters((prev) => (reset ? { ...data } : { ...prev, ...data }))
                            }}
                            page='merchants'
                            onSearch={handleSearchClick}
                            onReset={reset}
                        />
                    </div>
                    <div className='bg-white p-2 rounded-md'>
                        <Table
                            columns={displayedColumns  }
                            dataSource={merchants || []}
                            pagination={{
                                current: parameters.page, // Current page number
                                pageSize: parameters.pageSize, // Number of items to display per page
                                total: total, // Total number of items in the data array
                                showSizeChanger: false,
                                onChange: handlePaginationChange,
                                position: ['topLeft', 'bottomLeft'],
                                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                            }}
                            scroll={{ x: !parameters.rto_enabled ?  2500 : 3500 }}
                        />
                    </div>

                    <Modal
                        title="To confirm, type Deactivate in the text input field"
                        open={openDeactivate ? true :false }
                        footer={null}
                    >
                            <Input value={deActivateText} onChange={(e) => setDeactivateText(e.target.value)}  type='text' className='' placeholder='Deactivate' />
                            <Input value={reason}  onChange={(e) => setReason(e.target.value)} type='textarea' className='h-20 mt-2' multiple placeholder='Reason for Deactivation' />
                            <Row justify={"end"} className='gap-2 mt-2'>
                                <Button onClick={()=>{setDeactivateModal(null)}} variant='default'>Cancel</Button>
                                <Button disabled={deActivateText !== "Deactivate" } onClick={() =>{changeMerchantStatus(openDeactivate) }} variant='primary'>Submit</Button>
                            </Row>
                    </Modal>
                    <Modal
                        title="To confirm, type Activate in the text input field"
                        open={openActivate ? true :false }
                        footer={null}
                        onCancel={() =>{
                            setopenActivate(null)
                            setDeactivateModal(null)
                        }}
                    >       
                            <div className='pt-3'>
                                <Checkbox checked={whitelistCheckout} onChange={(v) =>setWhitelistCheckout(v.target.checked)}  /><span className='text-sm pl-2'>Whitelist Domain Checkout</span>
                            </div>
                            <div className='py-3'>
                                <Checkbox checked={whitelistExperiment}  onChange={(v) =>setWhitelistExperiment(v.target.checked)}  /><span className='text-sm pl-2'>Whitelist Domain Experiment</span>
                            </div>
                            <Input  value={activateText} onChange={(e) => setActivateText(e.target.value)} type='text' className='' placeholder='Activate' />
                            <Row justify={"end"} className='gap-2 mt-2'>
                                <Button onClick={()=>{setopenActivate(null)}} variant='default'>Cancel</Button>
                                <Button disabled={activateText !== "Activate" } onClick={() =>{changeMerchantStatus(openActivate) }} variant='primary'>Submit</Button>
                            </Row>
                    </Modal>
                </div>
            
    )
};