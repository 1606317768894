import { makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { handleError } from '@library/utilities/helpers/handleError'
import { saveFiltersData } from '@store/filters'
import {
    Button,
    Col,
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    InfoCircleOutlined,
    message,
    PlusCircleOutlined,
    Popconfirm,
    Row,
    Table,
    Tag,
    Tooltip,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { render } from 'react-dom'
import { navigateToUrl } from 'single-spa'
import dayjs from 'dayjs'

const CampaignList = () => {
    const [parameters, setParameters] = useState({
        page: 1,
        pageSize: 25,
    })
    const [allCampaignsList, setAllCampaignsList] = useState([])
    const [confirmLoading, setConfirmLoading] = useState(false)

    const handleActionButton = (record, action) => {
        if (action === 'Edit') {
            navigateToUrl(`/general/edit-campaign/${record?.id}`)
        } else if (action === 'View') {
            navigateToUrl(`/general/view-campaign/${record?.id}`)
        }
    }

    const productMap = {
        checkout: { label: 'KwikCheckout', color: '#1E90FF' }, // Blue
        kwik_pass: { label: 'KwikPass', color: '#32CD32' }, // Green
        payments: { label: 'KwikPayment', color: '#9370DB' }, // Purple
        rto: { label: 'RTO', color: '#00CED1' }, // Cyan
        kwikship: { label: 'KwikShip', color: '#FFA500' }, // Orange
        kwikship_shopify_o_m_s: { label: 'KwikShip Shopify OMS', color: '#FFD700' }, // Yellow
        kwik_cart: { label: 'KwikCart', color: '#008080' }, // Teal
    }

    const getLabelsFromTypes = (typesString: string) => {
        const typesArray = typesString?.split(', ')
        const labels = typesArray?.map((type) => productMap[type]?.label).filter(Boolean)
        return labels?.join(', ')
    }
    const confirm = async (e, record) => {
        record.is_active = false
        record.id = Number(record?.id)
        try {
            setConfirmLoading(true)
            let response = await makeAPICall({
                method: 'patch',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.marketingCampaigns.updateCampaign(record?.id),
                payload: record,
                skipLoader: true,
            })

            if (response?.status === 200) {
                getAllCampaigns(parameters)
            }

            setConfirmLoading(false)
        } catch (error) {
            setConfirmLoading(false)
            handleError(error)
        }
    }

    const cancel = (e) => {}
    const columns = [
        {
            title: 'Update Name',
            dataIndex: 'name',
            key: 'name',
            width: 90,
        },
        {
            title: 'Banner Title',
            dataIndex: 'title',
            key: 'title',
            width: 150,
            render: (text) => (
                <Tooltip title={text}>
                    <span className='truncate text-ellipsis max-w-32 overflow-hidden whitespace-nowrap inline-block'>
                        {text}
                    </span>
                </Tooltip>
            ),
        },

        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            width: 50,
            render: (text) => <>{dayjs(text).format('DD/MM/YYYY HH:mm')}</>,
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
            width: 50,
            render: (text) => <>{dayjs(text).format('DD/MM/YYYY HH:mm')}</>,
        },
        {
            title: 'Product Type',
            dataIndex: 'product_name',
            key: 'product_name',
            width: 30,
            render: (text) => {
                const item = text?.split(', ')
                let product
                if (item?.length === 1) {
                    product = productMap[item[0]]
                } else {
                    product = null
                }

                return product ? (
                    <span
                        style={{
                            color: product.color,
                            border: `1px solid ${product.color}`,
                            display: 'inline-block',
                            padding: '1px 2px',
                            borderRadius: '4px',
                        }}
                    >
                        {product.label}
                    </span>
                ) : (
                    <>
                        <Tooltip title={getLabelsFromTypes(item?.join(', '))}>
                            <Tag variant='default'>{productMap[text?.split(', ')[0]]['label']} ...</Tag>
                        </Tooltip>
                    </>
                )
            },
        },
        // {
        //     title: 'Preview',
        //     dataIndex: 'description',
        //     key: 'description',
        //     render: (id, record) => (
        //         <Row className='flex gap-3 m-0' justify={'center'}>
        //             <Tooltip className=' cursor-pointer' placement='topLeft' title={'Preview Campaign'}>
        //                 <EyeOutlined onClick={() => handleActionButton(record, 'View')} className='text-blue-500' />
        //             </Tooltip>
        //         </Row>
        //     ),
        //     width: 30,
        // },
        {
            title: 'Action',
            dataIndex: 'cta_text',
            key: 'cta_text',
            render: (id, record) => (
                <Row justify={'center'}>
                    <Col span={12}>
                        {' '}
                        <Tooltip className=' cursor-pointer' placement='topLeft' title={'Edit Campaign'}>
                            <EditOutlined
                                onClick={() => handleActionButton(record, 'Edit')}
                                className='text-blue-500'
                            />
                        </Tooltip>
                    </Col>
                    <Col span={12}>
                        {' '}
                        <Popconfirm
                            title='Delete Campaign'
                            description='Are you sure to delete this campaign?'
                            onConfirm={(e) => confirm(e, record)}
                            onCancel={cancel}
                            okButtonProps={{ loading: confirmLoading }}
                            okText='Yes'
                            cancelText='No'
                        >
                            <DeleteOutlined className='text-red-500' />
                        </Popconfirm>
                    </Col>
                </Row>
            ),
            width: 90,
        },
    ]
    const takeAction = () => {
        navigateToUrl('/general/create-campaign')
    }

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'all-campaigns',
                href: '/general/all-campaigns',
                text: 'All Campaigns',
            },
        ])

        getAllCampaigns(parameters)
    }, [])

    async function getAllCampaigns(parameters: any) {
        if (!parameters.page) return
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.marketingCampaigns.allCampaigns,
                params: {
                    ...parameters,
                },
            })
            if (response?.status === 200) {
                setAllCampaignsList(response?.data?.data?.campaign?.length >= 1 ? response?.data?.data?.campaign : [])
            }
        } catch (error) {
            handleError(error)
        }
    }

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
        getAllCampaigns({ ...parameters, page: current, pageSize })
    }

    return (
        <div className='w-full h-full'>
            <div className='!mt-0 mb-2'>
                <h4>Campaign List</h4>
                <p className='text-[#00000073]'>All the campaigns are listed here</p>
            </div>
            <Row className='p-2 rounded bg-neutrals-15' align={'middle'} justify={'space-between'}>
                <Col sm={12} className='flex items-center gap-x-2'>
                    <Row className='text-black inter m-0 tracking-wide items-center'>
                        <Tooltip title={''}>
                            <InfoCircleOutlined className='text-blue fs20' />
                        </Tooltip>

                        <span className='ml-2'>Create different types of campaigns from here.</span>
                    </Row>
                </Col>
                <Col className='flex justify-end' sm={2}>
                    <Button onClick={() => takeAction()} variant='primary'>
                        <PlusCircleOutlined />
                        Create New Campaign
                    </Button>
                </Col>
            </Row>
            <div className='mt-5 bg-white rounded overflow-clip'>
                <Table
                    columns={columns}
                    dataSource={allCampaignsList || []}
                    pagination={{
                        current: parameters.page, // Current page number
                        pageSize: parameters.pageSize, // Number of items to display per page
                        showSizeChanger: false,
                        onChange: handlePaginationChange,
                        position: ['topLeft', 'bottomLeft'],
                        showTotal: (total, range) => (
                            <p className='ml-4'>{`Showing ${range[0]}-${range[1]} of ${total} Records`}</p>
                        ),
                    }}
                    scroll={{ x: 'max-content' }}
                />
            </div>
        </div>
    )
}

export default CampaignList
