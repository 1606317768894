// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container .next,
.login-container .email-input {
  padding: 0.75rem 1rem;
  height: fit-content;
}
.login-container .email-input input {
  margin-left: 0.5rem;
}
.login-container .email-input:focus-within .ant-input-prefix svg path {
  stroke: var(--primary-blue-400);
}

.login-container .email-input.ant-input-affix-wrapper-status-error .ant-input-prefix svg path {
  stroke: var(--error-700);
}
.login-container .email-input.ant-input-affix-wrapper-status-error input {
  color: var(--error-700);
}
.login-container .email-input.gk-text-input.ant-input,
.login-container .email-input.gk-text-input .ant-input,
.login-container .email-input.gk-text-input.ant-input-affix-wrapper {
  border-color: var(--primary-blue-750);
  color: var(--primary-blue-750);
}
.login-container .email-input.gk-text-input.ant-input:focus,
.login-container .email-input.gk-text-input .ant-input:focus,
.login-container .email-input.gk-text-input.ant-input-affix-wrapper-focused,
.login-container .email-input.gk-text-input.ant-input-affix-wrapper-focused:hover {
  border-color: var(--primary-blue-400);
  color: var(--primary-blue-400);
  box-shadow: var(--shadow-primary);
}
.inline {
  display: inline;
}

.no-wrap {
  white-space: nowrap;
}

#webpack-dev-server-client-overlay {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/library/styles/global.css"],"names":[],"mappings":"AAAA;;EAEE,qBAAqB;EACrB,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,+BAA+B;AACjC;;AAEA;EACE,wBAAwB;AAC1B;AACA;EACE,uBAAuB;AACzB;AACA;;;EAGE,qCAAqC;EACrC,8BAA8B;AAChC;AACA;;;;EAIE,qCAAqC;EACrC,8BAA8B;EAC9B,iCAAiC;AACnC;AACA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".login-container .next,\n.login-container .email-input {\n  padding: 0.75rem 1rem;\n  height: fit-content;\n}\n.login-container .email-input input {\n  margin-left: 0.5rem;\n}\n.login-container .email-input:focus-within .ant-input-prefix svg path {\n  stroke: var(--primary-blue-400);\n}\n\n.login-container .email-input.ant-input-affix-wrapper-status-error .ant-input-prefix svg path {\n  stroke: var(--error-700);\n}\n.login-container .email-input.ant-input-affix-wrapper-status-error input {\n  color: var(--error-700);\n}\n.login-container .email-input.gk-text-input.ant-input,\n.login-container .email-input.gk-text-input .ant-input,\n.login-container .email-input.gk-text-input.ant-input-affix-wrapper {\n  border-color: var(--primary-blue-750);\n  color: var(--primary-blue-750);\n}\n.login-container .email-input.gk-text-input.ant-input:focus,\n.login-container .email-input.gk-text-input .ant-input:focus,\n.login-container .email-input.gk-text-input.ant-input-affix-wrapper-focused,\n.login-container .email-input.gk-text-input.ant-input-affix-wrapper-focused:hover {\n  border-color: var(--primary-blue-400);\n  color: var(--primary-blue-400);\n  box-shadow: var(--shadow-primary);\n}\n.inline {\n  display: inline;\n}\n\n.no-wrap {\n  white-space: nowrap;\n}\n\n#webpack-dev-server-client-overlay {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
