import { Tooltip, QuestionCircleOutlined } from 'gokwik-ui-kit'

const validateCityState = (value) => {
    if (!value || value.trim() === '') {
        return Promise.reject(new Error(''))
    }
    // Trim leading and trailing spaces and check for only alphabetic characters and spaces, also no HTML tags
    const trimmedValue = value.trim()
    if (!/^[a-zA-Z\s]+$/.test(trimmedValue) || /<[^>]*>/g.test(trimmedValue)) {
        return Promise.reject(new Error('Only alphabetic characters and spaces are allowed!'))
    }
    return Promise.resolve()
}

export const businessDetailsFormConfig = {
    label: 'Business Details',
    step: 'Step 4',
    status: 'Completed',
    items: [
        {
            label: (
                <>
                    Business Name
                    <Tooltip
                        title={
                            'Enter the full legal name of your business as registered in official documents and records'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'business_name',
            type: 'input',
            placeholder: 'Business name',
            rules: [
                {
                    required: true,
                    message: 'Please add your business name',
                },
                {
                    validator: (_, value) => {
                        if (!value || value.trim() === '') {
                            return Promise.reject(new Error('Name cannot be empty or contain only spaces'))
                        }
                        if (/<[^>]*>/g.test(value)) {
                            return Promise.reject(new Error('HTML tags are not allowed!'))
                        }
                        return Promise.resolve()
                    },
                },
            ],
            span: 8,
        },
        {
            label: (
                <>
                    Business PAN
                    <Tooltip
                        title={
                            'Provide the Permanent Account Number (PAN) associated with your business for tax purposes in India'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'business_pan',
            type: 'input',
            placeholder: 'Business PAN',
            class: 'uppercase',
            rules: [
                {
                    required: true,
                    message: 'Please add your business pan',
                },
                {
                    pattern: RegExp(`^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$`),
                    message: 'Invalid PAN. Please enter a valid PAN number',
                },
            ],
            span: 8,
        },
        {
            label: (
                <>
                    Business PAN Registration Date
                    <Tooltip
                        title={
                            'Enter the date when your business PAN was officially registered with the Indian tax authorities'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'business_pan_registration_date',
            type: 'date',
            placeholder: 'Business PAN Registration Date',
            class: 'w-full',
            rules: [
                {
                    required: true,
                    message: 'Please add your business PAN registration date',
                },
                {
                    validator: (_, value) => {
                        const today = new Date()
                        today.setHours(0, 0, 0, 0) // Set to the start of today

                        if (!value) {
                            return Promise.resolve()
                        }

                        const selectedDate = new Date(value)
                        if (selectedDate >= today) {
                            return Promise.reject(new Error('Date cannot be today or in the future'))
                        }

                        return Promise.resolve()
                    },
                },
            ],
            span: 8,
        },
        {
            label: (
                <>
                    PAN (Authorised Signatory)
                    <Tooltip
                        title={
                            'Provide the PAN of the individual who is authorized to sign documents on behalf of the business'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'aus_pan',
            type: 'input',
            placeholder: 'PAN (Authorised Signatory)',
            class: 'uppercase',
            rules: [
                {
                    required: true,
                    message: 'Please add your PAN (Authorised Signatory)',
                },
                {
                    pattern: RegExp(`^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$`),
                    message: 'Invalid PAN. Please enter a valid PAN number',
                },
            ],
            span: 8,
        },
        {
            label: (
                <>
                    Authorised Signatory Name
                    <Tooltip
                        title={
                            'Provide the PAN of the individual who is authorized to sign documents on behalf of the business'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'aus_name',
            type: 'input',
            placeholder: 'Authorised Signatory Name',
            rules: [
                {
                    required: true,
                    message: 'Required',
                },
                {
                    validator: (_, value) => {
                        if (!value || value.trim() === '') {
                            return Promise.reject(new Error('Cannot be empty'))
                        }
                        const trimmedValue = value.trim()
                        if (!/^[a-zA-Z\s]+$/.test(trimmedValue) || /<[^>]*>/g.test(trimmedValue)) {
                            return Promise.reject(new Error('Only alphabetic characters and spaces are allowed!'))
                        }
                        return Promise.resolve()
                    },
                },
            ],
            span: 8,
        },
        {
            label: (
                <>
                    Authorised Signatory Email
                    <Tooltip title={'The Agreement will be shared on this email ID for signing'}>
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'aus_email',
            type: 'input',
            placeholder: 'Authorised Signatory Email',
            rules: [
                {
                    required: true,
                    message: '',
                },
                {
                    validator: (_, value) => {
                        if (!value || value.trim() === '') {
                            return Promise.reject(new Error('Email cannot be empty'))
                        }
                        const trimmedValue = value.trim()
                        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                        if (!emailPattern.test(trimmedValue)) {
                            return Promise.reject(new Error('Please enter a valid email address!'))
                        }
                        return Promise.resolve()
                    },
                },
            ],
            span: 8,
        },
        {
            label: (
                <>
                    GSTIN
                    <Tooltip
                        title={
                            'Enter your Goods and Services Tax Identification Number, which is used for GST compliance and filings'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'gstin',
            type: 'input',
            placeholder: 'GSTIN',
            class: 'uppercase',
            rules: [
                {
                    required: true,
                    message: 'Please add your gstin',
                },
                // {
                //     pattern: RegExp('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$'),
                //     message: 'Invalid GSTIN, Please enter a valid gstin.',
                // },
            ],
            span: 8,
        },
    ],
}
