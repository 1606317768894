import { makeAPICall } from '@gokwik/utilities'
import { useAppDispatch } from '@library/utilities/hooks'
import { fetchUserDetails } from '@store/user'
import { Alert, Button, Checkbox, Col, Divider, Radio, Row, Space } from 'gokwik-ui-kit'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { navigateToUrl } from 'single-spa'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

export default function OnboardStep3({ updateStatus, step, website, startPolling }) {
    const [disableButton, setDisableButton] = useState(true)
    const [limitCOD, setLimitCOD] = useState(false)
    const [shippingRate, setShippingRate] = useState(false)
    const merchant_details = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)

    useEffect(() => {
        logEvent(
            'setup_shipping_page_landed',
            'event',
            'onboarding',
            userDetails?.email,
            merchant_details?.m_id,
            merchant_details?.short_name,
            userDetails?.name,
        )
    }, [])
    const onSubmit = async () => {
        logEvent(
            'cod_shipping_rates_selected',
            'event',
            'onboarding',
            userDetails?.email,
            merchant_details?.m_id,
            merchant_details?.short_name,
            userDetails?.name,
        )
        if (!limitCOD) {
            const response = await makeAPICall({
                url: process.env.REACT_APP_BASE_URL + '/api/dashboard/pincode/enable-pin',
                method: 'post',
                payload: {
                    enable: false,
                    checkout_platform: 'kwik_checkout',
                },
            })
        }

        if (shippingRate && limitCOD) {
            navigateToUrl(
                '/shopify-app/settings/pincode?' +
                    'limit-cod-availability=' +
                    limitCOD +
                    '&' +
                    'cod-shipping-rate=' +
                    shippingRate,
            )
        } else if (shippingRate) {
            navigateToUrl('/shopify-app/settings/shipping?' + 'cod-shipping-rate=' + shippingRate)
        } else if (limitCOD) {
            navigateToUrl('/shopify-app/settings/pincode?' + 'limit-cod-availability=' + limitCOD)
        }
        setDisableButton(false)
    }

    useEffect(() => {
        startPolling()
    }, [])

    return (
        <Row className=' p-4 py-6 border-solid border-gray-100 rounded-md relative overflow-auto '>
            <Col span={12}>
                {/* <p className='text-[20px] font-semibold '>Step-up Shipping Pincode Serviceability and COD Charges</p> */}
                <p className='text-[20px] font-semibold '>Step-up Shipping payment mapping for Shipping rates</p>

                {/* <p className='text-gray-400 mt-4'>
                    Explain the need to install the app and what permissions are required
                </p> */}

                <div className='text-slate-800 mt-4 p-6 rounded-md'>
                    <h2 className='text-base font-semibold mb-4'>
                        If you want to offer different shipping rates for cash on delivery:
                    </h2>
                    <ol className='list-decimal pl-4 text-sm'>
                        <li>
                            <h3 className='font-semibold'>Create Shipping Methods on Shopify:</h3>
                            <ul className='list-disc pl-4 mt-2 space-y-2'>
                                <li>Set up different rates for cash on delivery and prepaid shipping.</li>
                            </ul>
                        </li>
                        <li>
                            <h3 className='font-semibold'>Map Shipping Methods on Our Portal:</h3>
                            <ul className='list-disc pl-4 mt-2 space-y-2'>
                                <li>
                                    Create the same shipping methods (cash on delivery and prepaid) that you set up on
                                    Shopify.
                                </li>
                                <li>Match the shipping costs you defined on Shopify.</li>
                                <li>
                                    Connect each shipping method to its corresponding payment method (e.g., cash on
                                    delivery with cash payments, prepaid with online payments).
                                </li>
                            </ul>
                        </li>
                    </ol>
                </div>
            </Col>

            <Col span={12} className='bg-white'>
                {/* <div className='bg-white w-full p-4 '>
                    <p className='text-gray-900 font-inter text-xl font-semibold leading-7'>Postal Codes</p>
                    <p className='text-gray-400 mt-2'>
                        Use this area to limit the postal codes that you service in India.{' '}
                    </p>

                    <Alert className='my-6' message='Informational Notes' type='info' showIcon />
                    <div className='mb-6'>
                        <Radio.Group>
                            <Space direction='vertical'>
                                <Checkbox onChange={(e) => setLimitCOD(e.target.checked)} value={limitCOD}>
                                    Limit where Cash on Delivery is available
                                </Checkbox>
                                <Radio value={2}>Limit where shipping on prepaid payment method is available</Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                </div> */}
                {/* <Divider className='my-2' /> */}
                <div className='bg-white w-full p-4'>
                    <p className='text-gray-900 font-inter text-xl font-semibold leading-7'>
                        {/* Cash on Delivery shipping rates */}
                        Setting Up Your Shipping Methods
                    </p>
                    <p className='text-gray-400 mt-2'>
                        {/* Use this area to limit the postal codes that you service in India. */}
                        Create a shipping on Shopify and map it to COD/Prepaid
                    </p>

                    <Alert className='my-6' message='Informational Notes' type='info' showIcon />
                    <div className='mb-6'>
                        <Radio.Group onChange={(e) => setShippingRate(e.target.value)} value={shippingRate}>
                            <Space direction='vertical'>
                                <Radio checked value={false}>
                                    No extra free associated with Cash on Delivery
                                </Radio>
                                <Radio value={true}>Create new Cash on Delivery shipping rates</Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                </div>

                {shippingRate ? (
                    <Row>
                        <Button
                            onClick={onSubmit}
                            variant='primary'
                            className=' flex justify-center items-center m-4 w-fit'
                        >
                            <span className='pl-2'>Setup Shipping</span>
                        </Button>

                        <Button
                            disabled={disableButton}
                            onClick={() => updateStatus('gokwik_shipping_setup_done')}
                            variant='primary'
                            className=' flex justify-center items-center m-4 w-fit'
                        >
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='20'
                                height='20'
                                viewBox='0 0 21 20'
                                fill='none'
                            >
                                <g clip-path='url(#clip0_6870_23394)'>
                                    <path
                                        d='M10.3132 5H5.31315C4.87112 5 4.4472 5.17559 4.13464 5.48816C3.82208 5.80072 3.64648 6.22464 3.64648 6.66667V15C3.64648 15.442 3.82208 15.866 4.13464 16.1785C4.4472 16.4911 4.87112 16.6667 5.31315 16.6667H13.6465C14.0885 16.6667 14.5124 16.4911 14.825 16.1785C15.1376 15.866 15.3132 15.442 15.3132 15V10'
                                        stroke='white'
                                        stroke-width='1.6'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                    />
                                    <path
                                        d='M9.47852 10.832L16.9785 3.33203'
                                        stroke='white'
                                        stroke-width='1.6'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                    />
                                    <path
                                        d='M12.8125 3.33203H16.9792V7.4987'
                                        stroke='white'
                                        stroke-width='1.6'
                                        stroke-linecap='round'
                                        stroke-linejoin='round'
                                    />
                                </g>
                                <defs>
                                    <clipPath id='clip0_6870_23394'>
                                        <rect width='20' height='20' fill='white' transform='translate(0.3125)' />
                                    </clipPath>
                                </defs>
                            </svg>
                            <span className='pl-2'>I Did It, What Next?</span>
                        </Button>
                    </Row>
                ) : (
                    <Button
                        onClick={() => updateStatus('gokwik_shipping_setup_done')}
                        variant='primary'
                        className=' flex justify-center items-center m-4 w-fit'
                    >
                        Proceed to next step
                    </Button>
                )}
            </Col>
        </Row>
    )
}
