import { navigateToUrl } from 'single-spa';
import PaymentActivationStep1 from './PaymentActivationStep1';
import PaymentActivationStep2 from './PaymentActivationStep2';
import { useEffect, useState } from 'react';
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents';
import { updateMerchantOnboardingCheckoutConfigsStatus } from '../CommonComponents/Utils';
import { useSelector } from 'react-redux';
import { getMerchantDetails, getUserData, getUserDetails } from '@store/user/selectors';
import { useAppDispatch } from '@library/utilities/hooks';
import { fetchUserDetails } from '@store/user';

const PaymentStepsWrapper = ({currentStep, setCurrentStep }) => {
    const [expandedStep, setExpandedStep] = useState(currentStep);
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)
    const userData = useSelector(getUserData);
    const dispatch = useAppDispatch();

    const handleNextStep = () => {
        setExpandedStep(currentStep);
    };
    const toggleStep = (step: number) => {
        setExpandedStep(step);
    }

    useEffect(() => {
        const agreementStatus = userData?.kycData?.sign_agreement_status
        if ((agreementStatus === 'Signed' || agreementStatus === "") && ['setup_completed', 'kyc_completed', 'kwik_checkout_button_activated'].includes(merchantDetails?.onboarding_status?.kwik_checkout_app)) {
            updateMerchantOnboardingCheckoutConfigsStatus('gk_payments_agreement_signed', () => {
                dispatch(fetchUserDetails());
                handleNextStep();
            }, merchantDetails)
        }
        const params = new URLSearchParams(window.location.search)
        const gokwikkAgreementSigned = params.get('gokwikk-agreement-signed') === 'true';
        if (gokwikkAgreementSigned) {
            agreementSignedClicked();
            navigateToUrl('/onboarding/payment-setup')
        }
    }, [])

    const agreementSignedClicked = () => {
        logEvent(
            'agreement_signed',
            'click',
            'payment-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            {Step: 'payments 1'}
        )        
        updateMerchantOnboardingCheckoutConfigsStatus('gk_payments_agreement_signed', () => {
            dispatch(fetchUserDetails());
            handleNextStep();
        }, merchantDetails)   
    }

    return (
        <div className="flex flex-col gap-4 h-[73vh] overflow-y-auto pb-2" >
            <PaymentActivationStep1 onStepComplete={handleNextStep} currentStep={currentStep} expandedStep={expandedStep} toggleStep={toggleStep} />
            <PaymentActivationStep2 onStepComplete={handleNextStep} currentStep={currentStep} expandedStep={expandedStep} toggleStep={toggleStep} />
        </div>
    );
};

export default PaymentStepsWrapper;
