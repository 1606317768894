import { useCallback, useEffect, useRef, useState } from 'react'
import BasicDetails from './kyc-steps/basic-details/BasicDetails'
import BankDetails from './kyc-steps/bank-details/BankDetails'
import BusinessAddress from './kyc-steps/business-address/BusinessAddress'
import BusinessDetails from './kyc-steps/business-details/BusinessDetails'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import DocumentsUpload from './kyc-steps/documents-upload/DocumentsUpload'
import dayjs from 'dayjs'
import { getMerchantDetails, getUserData, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { checkCurrentStatus, getStepFromStatus, scrollToText } from '../utils'
import { Alert, Button, Checkbox, Col, message, Row } from 'gokwik-ui-kit'
import { navigateToUrl } from 'single-spa'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { useAppDispatch, useInterval } from '@library/utilities/hooks'
import { fetchUserDetails } from '@store/user'
import VBC from './kyc-steps/virtual-background-check/VBC'
import { stepConfiguration } from '@pages/onboarding/stepsConfig'
import ComplienceModal from './components/ComplienceModal'

type Props = {}

const KYCFormComponent = ({
    kycData,
    currentStep,
    setCurrentStep,
    isCPVMandatory,
    isPanelClickable,
    undeterminedKycErrorState,
    setIsCpvMandatory,
    setKycData,
}) => {
    const copyTimerRef = useRef(null)
    const dispatch = useAppDispatch()
    const merchant_details = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const userData = useSelector(getUserData)
    const [showKYCErrorMessage, setShowKYCErrorMessage] = useState(false)
    const [VBCLink, setVBClink] = useState(null)
    const [reSubmitState, setReSubmitState] = useState(false)
    const [openComplienceModal, setOpenComplienceModal] = useState(false)
    const [complienceAgreed, setComplienceAgreed] = useState(false)
    const [isEnabled, setIsEnabled] = useState(false)
    const [interval, initiateInterval, removeInterval] = useInterval(
        () => fetchLatestOnboardingStatusWithPollingHandling(),
        1000,
    )
    const intervalHandling = useCallback(
        (result) => {
            const current_onboarding_status = result

            if (
                current_onboarding_status === 'kyc_verification_failed' &&
                userData?.kycData?.kyc_failure_reasons &&
                !userData?.kycData?.kyc_failure_reasons?.hasOwnProperty('incurable_kyc_error')
            ) {
                let timeout
                if (!timeout) {
                    // Automatically stop polling after the 1min
                    window.setTimeout(() => {
                        removeInterval()
                        setShowKYCErrorMessage(false)
                        window.location.reload()
                    }, 60000)
                }
                if (!interval) {
                    initiateInterval()
                }
            } else {
                window.location.reload()
                setShowKYCErrorMessage(false)
                removeInterval()
                dispatch(fetchUserDetails())
            }
        },
        [merchant_details?.onboarding_status?.kwik_checkout_app],
    )

    useEffect(() => {
        return () => {
            if (copyTimerRef.current) {
                clearTimeout(copyTimerRef.current)
            }
        }
    }, [])

    useEffect(() => {
        if (
            merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_verification_failed' &&
            userData?.kycData?.is_cpv_mandatory
        ) {
            getVPCLink()
        } else if (["kyc_completed", 'setup_completed', 'gk_payments_app_installed', 'gk_additional_payment_methods_configured', 'merchant_onboarded'].includes(merchant_details?.onboarding_status?.kwik_checkout_app)) {
            removeInterval()
            navigateToUrl('/shopify-app/settings')
        } else if (['gk_payments_agreement_signed'].includes(merchant_details?.onboarding_status?.kwik_checkout_app)) {
            removeInterval()
            navigateToUrl('/onboarding/payment-setup')
        } else if (!merchant_details?.onboarding_status?.kwik_checkout_app) {
            navigateToUrl('/shopify-app/settings');
        }
    }, [userData])

    const fetchLatestOnboardingStatusWithPollingHandling = async () => {
        const res = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.userDetails,
            method: 'get',
        })
            .then((result) => {
                intervalHandling(result?.data?.data?.merchant_details?.onboarding_status?.kwik_checkout_app)
            })
            .catch((err) => {
                console.warn(err)
            })
    }

    const submitKYCDocsToEaseBuzz = async (isResubmit?: any) => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.sendKYCDocumentPostError,
                skipLoader: false,
                payload: { test: 1 },
            })

            if (response.status === 200) {
                if (isResubmit) {
                    logEvent(
                        'kyc_error_resubmit',
                        'click',
                        'kyc',
                        user_details?.email,
                        merchant_details?.m_id,
                        merchant_details?.short_name,
                        user_details?.name,
                        { errors: userData?.kycData?.kyc_failure_reasons },
                    )
                }
                setShowKYCErrorMessage(true)
                fetchLatestOnboardingStatusWithPollingHandling()
            } else {
                message.error('Failed')
            }
        } catch (err) {
            console.log(err)
        }
    }

    const sendPriceAgreed = async () => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.createBasicPlanForCODFirst,
            })
        } catch (err) {
            console.log(err)
        }
    }

    const getVPCLink = async () => {
        await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.generateKYCLinks,
            payload: {
                send_notification: 1,
            },
        }).then((res) => {
            if (!res.data.data.video_kyc_url) {
                getVPCLink()
            }
            setVBClink(res?.data?.data?.video_kyc_url)
        })
    }

    const onFinish = async (formValue, step) => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateMerchantOnboardingStatus + merchant_details?.m_id,
            payload: {
                ...formValue,
                ...(merchant_details?.onboarding_status?.kwik_checkout_app !== 'kyc_verification_failed' && {
                    current_onboarding_status: checkCurrentStatus(step),
                }),
                ...(step === 2 && {
                    ifsc_code: formValue?.ifsc_code?.toUpperCase(),
                }),
                ...(step === 3 && {
                    business_pan: formValue?.business_pan?.toUpperCase(),
                    aus_pan: formValue?.aus_pan?.toUpperCase(),
                    gstin: formValue?.gstin?.toUpperCase(),
                }),
                ...(step === 4 && {
                    address_type: Number(formValue?.address_type),
                }),
            },

            skipLoader: true,
        })

        if (response?.status === 200) {
            if (step === 4) {
                sendPriceAgreed()

                if (isCPVMandatory) {
                    getVPCLink()
                }

                fetchLatestOnboardingStatusWithPollingHandling()
            }

            setCurrentStep(step + 1)
        } else {
            message.error('Something went wrong. Try again !')
        }
        dispatch(fetchUserDetails())
    }

    const onKycSubmit = () => {
        submitKYCDocsToEaseBuzz()
        setOpenComplienceModal(false)
        navigateToUrl('/shopify-app/settings')
    }

    return (
        <>
            {kycData && (
                <div className='flex flex-col gap-3'>
                    <BasicDetails
                        kycData={kycData}
                        onFinish={onFinish}
                        setCurrentStep={setCurrentStep}
                        currentStep={currentStep}
                        isPanelClickable={isPanelClickable}
                        undeterminedKycErrorState={undeterminedKycErrorState}
                    />
                    <BusinessAddress
                        kycData={kycData}
                        onFinish={onFinish}
                        setCurrentStep={setCurrentStep}
                        currentStep={currentStep}
                        isPanelClickable={isPanelClickable}
                        undeterminedKycErrorState={undeterminedKycErrorState}
                    />
                    <BankDetails
                        kycData={kycData}
                        onFinish={onFinish}
                        setCurrentStep={setCurrentStep}
                        currentStep={currentStep}
                        isPanelClickable={isPanelClickable}
                        undeterminedKycErrorState={undeterminedKycErrorState}
                    />
                    <BusinessDetails
                        kycData={kycData}
                        onFinish={onFinish}
                        setCurrentStep={setCurrentStep}
                        currentStep={currentStep}
                        isPanelClickable={isPanelClickable}
                        undeterminedKycErrorState={undeterminedKycErrorState}
                    />
                    <DocumentsUpload
                        kycData={kycData}
                        onFinish={onFinish}
                        setCurrentStep={setCurrentStep}
                        currentStep={currentStep}
                        isPanelClickable={isPanelClickable}
                        undeterminedKycErrorState={undeterminedKycErrorState}
                        setIsCpvMandatory={setIsCpvMandatory}
                        setKycData={setKycData}
                    />

                    {isCPVMandatory && (
                        <VBC
                            currentStep={currentStep}
                            setCurrentStep={setCurrentStep}
                            VBCLink={VBCLink}
                            onFinish={onFinish}
                            isPanelClickable={isPanelClickable}
                            undeterminedKycErrorState={undeterminedKycErrorState}
                        />
                    )}

                    <Row>
                        <Col span={24}>
                            {showKYCErrorMessage && (
                                <Alert
                                    className='w-full p-2'
                                    message={
                                        <span className='text-yellow-500 text-sm font-semibold'>Please Wait !</span>
                                    }
                                    description={
                                        <div className='text-yellow-500 '>
                                            It will take some time to submit your details.
                                        </div>
                                    }
                                    type='warning'
                                    showIcon
                                />
                            )}
                        </Col>
                        {merchant_details?.onboarding_status?.kwik_checkout_app !== 'kyc_verification_pending' &&
                            ((isCPVMandatory
                                ? merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_vpc'
                                : merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_document_upload') ||
                                merchant_details?.onboarding_status?.kwik_checkout_app ===
                                    'kyc_verification_failed') && (
                                <Col span={24}>
                                    <div className='w-full text-start p-4 bg-white rounded-md shadow-md flex justify-between items-center mb-4'>
                                        <Checkbox
                                            onChange={() => {
                                                if (
                                                    merchant_details?.onboarding_status?.kwik_checkout_app ===
                                                    'kyc_verification_failed'
                                                ) {
                                                    setReSubmitState(!reSubmitState)
                                                }
                                                setIsEnabled(!isEnabled)
                                            }}
                                            value={isEnabled}
                                        >
                                            {merchant_details?.onboarding_status?.kwik_checkout_app ===
                                            'kyc_verification_failed'
                                                ? `I have completed the review and corrected all KYC errors and I'm ready to re-submit
                                    for KYC review.`
                                                : 'I have carefully reviewed all KYC fields, documents and ready to submit for final review.'}
                                        </Checkbox>
                                        <Button
                                            disabled={
                                                merchant_details?.onboarding_status?.kwik_checkout_app ===
                                                    'kyc_verification_failed' &&
                                                userData?.kycData?.kyc_rejection_reason?.length > 0
                                                    ? false
                                                    : !isEnabled
                                            }
                                            onClick={() => {
                                                if (
                                                    merchant_details?.onboarding_status?.kwik_checkout_app ===
                                                    'kyc_verification_failed'
                                                ) {
                                                    submitKYCDocsToEaseBuzz(true)
                                                } else {
                                                    setOpenComplienceModal(true)
                                                }
                                            }}
                                            variant='primary'
                                        >
                                            {merchant_details?.onboarding_status?.kwik_checkout_app ===
                                            'kyc_verification_failed'
                                                ? 'Re-Submit'
                                                : 'Final KYC Submit'}
                                        </Button>
                                    </div>
                                </Col>
                            )}
                    </Row>
                    <ComplienceModal
                        isOpen={openComplienceModal}
                        setIsOpen={setOpenComplienceModal}
                        complienceAgreed={complienceAgreed}
                        setComplienceAgreed={setComplienceAgreed}
                        onKycSubmit={onKycSubmit}
                    />

                    {/* <div
                        className={`text-start p-4 backdrop-blur-sm bg-white/30 shadow-lg flex justify-between items-center sticky bottom-0 w-full left-0`}
                    >
                        <Checkbox onChange={() => setReSubmitState(!reSubmitState)} value={reSubmitState}>
                            I have completed the review and corrected all KYC errors and I'm ready to re-submit for KYC
                            review.
                        </Checkbox>
                        <Button
                            disabled={
                                merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_verification_failed' &&
                                userData?.kycData?.kyc_rejection_reason?.length > 0
                                    ? false
                                    : !reSubmitState
                            }
                            onClick={() => submitKYCDocsToEaseBuzz(true)}
                            variant='primary'
                        >
                            {merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_verification_failed'
                                ? 'Re-Submit'
                                : 'Final KYC Submit'}
                        </Button>
                    </div> */}
                </div>
            )}
        </>
    )
}

export default KYCFormComponent
