import { Header } from '@library/components'
import { Col, Flex, Layout, Row } from 'gokwik-ui-kit'
import Banner from './Banner'
import WhatsNew from './WhatsNew'
import Charts from './Charts'

const ExecutiveSummary = () => {
    return (
        <Row gutter={12} className='w-full'>
            <Col span={17}>
                <Row>
                    <Col span={24}>{/* <Banner /> */}</Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Charts />
                    </Col>
                </Row>
            </Col>
            <Col span={7}>
                <WhatsNew />
            </Col>
        </Row>
    )
}

export default ExecutiveSummary
