import { ArrowRightOutlined } from 'gokwik-ui-kit'
import React, { useState } from 'react'

const FilterTabs = ({ selected, setSelected, newLaunchesArray }) => {
    const [showLeftArrow, setShowLeftArrow] = useState(false)
    const [showRightArrow, setShowRightArrow] = useState(true)
    const tabsRef = React.useRef<HTMLDivElement>(null)

    const tabs = Array.from(new Set(newLaunchesArray.flatMap((item) => item.tag)))
    if (!tabs.includes('All')) {
        tabs.unshift('All')
    } else {
        tabs.splice(tabs.indexOf('All'), 1)
        tabs.unshift('All')
    }

    const handleScroll = (direction: 'left' | 'right') => {
        if (tabsRef.current) {
            const { scrollLeft, clientWidth, scrollWidth } = tabsRef.current
            const scrollAmount = clientWidth / 2

            let newScrollPosition = direction === 'right' ? scrollLeft + scrollAmount : scrollLeft - scrollAmount

            if (direction === 'right' && newScrollPosition + clientWidth >= scrollWidth) {
                newScrollPosition = scrollWidth - clientWidth
            }

            if (direction === 'left' && newScrollPosition < 0) {
                newScrollPosition = 0
            }

            tabsRef.current.scrollTo({
                left: newScrollPosition,
                behavior: 'smooth',
            })

            setTimeout(() => {
                const { scrollLeft, clientWidth, scrollWidth } = tabsRef.current
                setShowLeftArrow(scrollLeft > 0)
                setShowRightArrow(scrollLeft + clientWidth < scrollWidth - 1)
            }, 300)
        }
    }

    return (
        <div className='relative'>
            {showLeftArrow && (
                <span
                    className='absolute left-0 top-0 bottom-0 flex items-center justify-center w-8 h-12 rounded-md bg-gray-200 cursor-pointer'
                    onClick={() => handleScroll('left')}
                >
                    <ArrowRightOutlined size={18} className='text-gray-700 transform rotate-180' />
                </span>
            )}
            <div
                ref={tabsRef}
                className='flex gap-2 p-2 overflow-x-auto whitespace-nowrap'
                style={{ WebkitOverflowScrolling: 'touch', scrollbarWidth: 'none' }}
                onScroll={() => {
                    if (tabsRef.current) {
                        const { scrollLeft, clientWidth, scrollWidth } = tabsRef.current
                        setShowLeftArrow(scrollLeft > 0)
                        setShowRightArrow(scrollLeft + clientWidth < scrollWidth)
                    }
                }}
            >
                {tabs?.map((tab, index) => (
                    <div
                        key={index}
                        onClick={() => setSelected(tab)}
                        className={`flex items-center px-4 py-2 rounded-lg border cursor-pointer ${
                            selected === tab
                                ? 'bg-gray-200 text-black border-gray-300'
                                : 'bg-white text-black border-gray-300'
                        }`}
                    >
                        {typeof tab === 'string' ? tab : null}
                    </div>
                ))}
            </div>
            {showRightArrow && (
                <span
                    className='absolute right-0 top-0 bottom-0 flex items-center justify-center w-8 h-12 rounded-md bg-gray-200 cursor-pointer'
                    onClick={() => handleScroll('right')}
                >
                    <ArrowRightOutlined size={18} className='text-gray-700' />
                </span>
            )}
        </div>
    )
}

export default FilterTabs
