import { navigateToUrl } from 'single-spa'
import React, { useState, useEffect, useMemo, useCallback } from 'react'

// Import Styles
// import './signup.css';

// Import Utilities
import { dashboardStore, makeAPICall } from '@gokwik/utilities'
import { Button, Col, Row, Input, message } from 'gokwik-ui-kit'

import { useSelector } from 'react-redux'
import { getSignUpEmail } from '@store/user/selectors'
import LoginLayout from '@library/components/loginLayout'
import { useAppDispatch } from '@library/utilities/hooks'
import { sendVerifyEmailOTP, setUserData, verifyMerchantSignup } from '@store/user'
import { ArrowLeftIcon } from '@library/images/common/arrowLeft'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'

const VerifyMerchant = () => {
    const [formData, setFormData] = useState({
        otp: '',
    })
    const [timerOtp, setTimerOtp] = useState(60)
    const signupEmail = localStorage.getItem('email')
    const dispatch = useAppDispatch()

    const submit = useCallback(async () => {
        if (!formData.otp || formData.otp.length < 6) {
            message.error('Invalid OTP')
            return
        }
        // dispatch(verifyMerchantSignup({ ...formData, email: signupEmail }))
        let response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.verifyMerchant,
            payload: { ...formData, email: signupEmail },
        })
        if (response.success) {
            localStorage.setItem('email', signupEmail)
            navigateToUrl("/login")
            message.success("Successfully signed up")
        } else {
            message.error(response.response?.data?.message)
        }
    }, [formData])
    // useEffect(() => {
    //     if (!signupEmail) navigateToUrl('/login')
    // }, [])

    const callBack = (err?: any) => {
        if (!err) setTimerOtp(60)
        else {
            message.error(err.errorMessage)
        }
    }
    useEffect(() => {
        if (!timerOtp) return
        const timeout = setTimeout(() => setTimerOtp((prev) => prev - 1), 1000)
        return () => clearTimeout(timeout)
    }, [timerOtp])
    const resend = (e) => {
        e.preventDefault()
        dispatch(sendVerifyEmailOTP({ email: signupEmail }, callBack))
    }

    return (
        <LoginLayout>
            <Row className='bg-[#ffffff] w-auto  rounded-lg  min-w-[35vw] m-auto flex flex-col align-middle justify-center'>
                <div>
                    <div
                        className='flex items-center cursor-pointer absolute top-[12%] text-[#002547]'
                        onClick={() => navigateToUrl('/login')}
                        style={{ marginBottom: '1.75rem' }}
                    >
                        {/* <ArrowLeftIcon /> */}
                        {/* <h3 className='font-normal text-base inter ml-1'>Back</h3> */}
                    </div>
                    <div className='heading text-primary-750 text-left flex-start text-2xl font-semibold'>
                        OTP Verification
                    </div>
                    <div className='text-[#000000A6] subheading text-base font-normal'>
                        Enter the OTP sent to <span className='font-semibold'>{signupEmail}</span>
                    </div>
                    <div className='w-full flex flex-col' style={{ rowGap: '1.5rem', marginTop: '3rem' }}>
                        <div className='flex flex-col' style={{ rowGap: '0.75rem' }}>
                            <h4 className='body text-[#002547] text-sm !leading-[22px] font-medium'>Enter OTP</h4>
                            <Input
                                className='email-input gk-text-input'
                                value={formData.otp}
                                onChange={(e) =>
                                    setFormData((prev) => ({
                                        ...prev,
                                        otp: e.target.value.replace(/[^\d]/g, '').substring(0, 6),
                                    }))
                                }
                                placeholder='******'
                            />
                        </div>

                        <Button
                            variant='primary'
                            className='next'
                            onClick={submit}
                            disabled={formData?.otp ? false : true}
                        >
                            <h3 className='body-bold'>Next</h3>
                        </Button>
                    </div>
                    <div className='text-center inter mt-2'>
                        {timerOtp === 0 ? (
                            <Button onClick={resend} className='w-full h-11' variant='default'>
                                <span className='body py-2 text-primary-700'>Resend OTP</span>
                            </Button>
                        ) : (
                            // <span className="fs14 lh-14 text-blue metro-m">
                            <p className='text-primary-800 text-center text-sm'>
                                Resend OTP in <span className='text-primary-400'>{timerOtp} Sec</span>
                            </p>
                        )}
                    </div>
                </div>

            </Row>
        </LoginLayout>
    )
}

export default VerifyMerchant
