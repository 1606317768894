import { Modal, Button } from 'gokwik-ui-kit';
import { fetchBillingSubscription } from '../CommonComponents/Utils';

const ApproveChargesModal = ({ approveChargesModal, setApproveChargesModal, setSiteVisited }) => {

    const handleActivation = () => {
        setApproveChargesModal(false);
        fetchBillingSubscription((approveChergesUrl: string) => {
            setSiteVisited(true)
            window.open(approveChergesUrl, '_blank');
        });
    };

    return (
        <Modal
            onCancel={() => setApproveChargesModal(false)}
            title={<span className="text-[#820014]" >Charges Approval Unsuccessful</span>}
            open={approveChargesModal}
            footer={[
                <Button key="activate" type="primary" onClick={handleActivation}>
                    Approve Charges
                </Button>
            ]}
            centered
        >
            <div className='mt-4 text-[14px]'>
                <p>Please click the Approve Charges button again to approve the charges</p>
            </div>
        </Modal>
    )
}

export default ApproveChargesModal;
