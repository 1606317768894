// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* :where(.css-dev-only-do-not-override-17we33g).ant-steps
    .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title
    > .ant-steps-item-subtitle,
.ant-steps-item-description {
    display: none;
}

:where(.css-dev-only-do-not-override-17we33g).ant-steps
    .ant-steps-item-wait
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title
    > .ant-steps-item-subtitle,
.ant-steps-item-description {
    display: none;
}

.kp-onboarding-steps
    :where(.css-dev-only-do-not-override-17we33g).ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title
    > .ant-steps-item-subtitle,
.ant-steps-item-description {
    display: none;
}

.kp-onboarding-steps .ant-steps-item-title > .ant-steps-item-subtitle,
.ant-steps-item-description {
    display: none;
} */
`, "",{"version":3,"sources":["webpack://./src/pages/onboarding/kwikpass-onboarding/steps/kwikpass-onboarding.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAkCG","sourcesContent":["/* :where(.css-dev-only-do-not-override-17we33g).ant-steps\n    .ant-steps-item-finish\n    > .ant-steps-item-container\n    > .ant-steps-item-content\n    > .ant-steps-item-title\n    > .ant-steps-item-subtitle,\n.ant-steps-item-description {\n    display: none;\n}\n\n:where(.css-dev-only-do-not-override-17we33g).ant-steps\n    .ant-steps-item-wait\n    > .ant-steps-item-container\n    > .ant-steps-item-content\n    > .ant-steps-item-title\n    > .ant-steps-item-subtitle,\n.ant-steps-item-description {\n    display: none;\n}\n\n.kp-onboarding-steps\n    :where(.css-dev-only-do-not-override-17we33g).ant-steps\n    .ant-steps-item-process\n    > .ant-steps-item-container\n    > .ant-steps-item-content\n    > .ant-steps-item-title\n    > .ant-steps-item-subtitle,\n.ant-steps-item-description {\n    display: none;\n}\n\n.kp-onboarding-steps .ant-steps-item-title > .ant-steps-item-subtitle,\n.ant-steps-item-description {\n    display: none;\n} */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
