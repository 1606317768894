import { Table, Button, Col, ColumnProps, Tag, message, EditOutlined, Tooltip, Row, InfoCircleOutlined } from 'gokwik-ui-kit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { PermissionValues } from '@library/utilities/constants/constants'
import {
    convertIsoToLocaleDateString,
    convertIsoToLocaleString,
    filterDateFormatter,
    makeAPICall,
    updateBreadcrumbs,
    validatePermission,
    downloadFromURL
} from '@gokwik/utilities'
import ReportsIcon from '@library/images/icons/reportsIcon'
import RenderSearchFilters from '@library/components/search-filter'
import { useDispatch, useSelector } from 'react-redux'
import { previousReports } from '@store/filters/selectors'
import { saveFiltersData } from '@store/filters'
import { getMerchantDetails, getUserData, isMerchantSelector } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useAppDispatch, useInterval } from '@library/utilities/hooks'
import { handleError } from '@library/utilities/helpers/handleError'
import { useNavigate } from 'react-router-dom'
import EditNotificationsDrawer from './EditNotifications'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

const today = dayjs()
const formattedDate = filterDateFormatter(today)

export default function () {
    const userDetails = useSelector(getUserData)
    const merchant_details = useSelector(getMerchantDetails)
    const [notifications, setNotifications] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [total, setTotal] = useState(0)
    const [reports, setReports] = useState([])
    const [editOpen, setEditOpen] = useState<any>()
    const prevFilters = useSelector(previousReports)
    const isMerchantUser = useSelector(isMerchantSelector)
    const [isNew, setIsNew] = useState(false);
    const [title, setTitle] = useState('Edit Notification');
    const params = new URLSearchParams(location.search);
    const queryParams = {
        from: params.get('from'),
       start : params.get('start'),
       end : params.get('end')
    }
    const [parameters, setParameters] = useState({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        st_date: formattedDate,
        end_date: formattedDate,
        reportType: 'order',
        filterField: 'createdOn',
        merchantId: [],
        generatedOn: {
            from: new Date().getTime() - 2 * 24 * 60 * 60 * 1000,
            to: new Date().getTime(),
        },
        ...prevFilters,
    })
    console.log(dayjs(new Date().getTime() - 2 * 24 * 60 * 60 * 1000).format("DD,MMM,YY"),)
    // const
    const openEdit = (id, data) => {
        setEditOpen(data)
    }
    const formatToIST = (dateStr) => {
        const date = new Date(dateStr);
        return formatDateWithoutSeconds(date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', hour12: true }));
      };

    function formatDateWithoutSeconds(dateString) {
        const [datePart, timePart] = dateString.split(', ');
        const [day, month, year] = datePart.split('/');
        let [time, meridian] = timePart.split(' ');

        let [hours, minutes] = time.split(':');
        const formattedDate = `${day}/${month}/${year}, ${hours}:${minutes} ${meridian}`;
        return formattedDate;
    }
    const columns: ColumnProps<any>[] = useMemo(
        () => [
            {
                title: <span>Reason</span>,
                dataIndex: 'module',
                width: 25,
                render: (text) => (
                    <Tooltip title={text}>
                        <span className='truncate text-ellipsis max-w-60 overflow-hidden whitespace-nowrap inline-block'>
                            {text}
                        </span>
                    </Tooltip>
                ),
            },
            {
                title: <span>Message</span>,
                dataIndex: 'message',
                width: 25,
                render: (text) => (
                    <Tooltip title={text}>
                        <span className='truncate text-ellipsis max-w-60 overflow-hidden whitespace-nowrap inline-block'>
                            {text}
                        </span>
                    </Tooltip>
                ),
            },
            {
                title: <span>Start Time</span>,
                dataIndex: 'start_time',
                render: (text, record) => (
                    <Row className='flex gap-3 m-0'>
                        <>{formatToIST(record.start_time)}</>
                    </Row>
                ),
            },
            {
                title: <span>End Time</span>,
                dataIndex: 'end_time',
                render: (text, record) => (
                    <Row className='flex gap-3 m-0'>
                        <>{formatToIST(record.end_time)}</>
                    </Row>
                ),
            },
            {
                title: <span>Status</span>,
                dataIndex: 'status',
                render: (id,record) => (
                    <div>
                        <>
                            {' '}
                            {record?.is_enabled && new Date(record?.end_time) > new Date() && new Date(record?.start_time) <= new Date() && (
                                    <Tag
                                        variant={'success'}
                                        className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                                    >
                                        Active
                                    </Tag>
                                )}
                            {record?.is_enabled && new Date(record?.end_time) > new Date() && new Date(record?.start_time) > new Date() && (
                                    <Tag
                                        variant={'warning'}
                                        className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                                    >
                                        Pending
                                    </Tag>
                                )}
                            {((!record?.is_enabled) || (new Date(record?.end_time) <= new Date() && new Date(record?.start_time) < new Date())) && (
                                <Tag
                                    variant={'error'}
                                    className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                                >
                                    Suspend
                                </Tag>
                            )}
                        </>
                    </div>
                ),
            },
            {
                title: <span>Actions</span>,
                dataIndex: 'actions',
                render: (id,record) => (
                    <Row className='flex gap-3 m-0' justify={'center'}>
                        <>
                            <Tooltip className=' cursor-pointer' placement='topLeft' title={'Edit'}>
                                <EditOutlined
                                    onClick={() => {
                                            openEdit(id,record)
                                    }}
                                    className=' text-red-500'
                                />
                            </Tooltip>
                        </>
                    </Row>
                ),
            },
        ],
        [isMerchantUser],
    )

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'scheduleNotifications',
                href: '/schedule-notifications',
                text: 'Schedule Notifications',
            },
        ])
        logEvent(
            'admin_notifications_clicked',
            'click',
            'Admin Schedule Notifications',
            userDetails?.userDetails?.email,
            merchant_details?.m_id,
            merchant_details?.short_name,
            userDetails?.userDetails?.name,
        )
    }, [])

    useEffect(() =>{
        if(queryParams.from && queryParams.start && queryParams.end){
            setParameters(prev =>(
                {
                ...prev,
                st_date: filterDateFormatter(new Date(Number(queryParams.start))),
                end_date: filterDateFormatter(new Date(Number(queryParams.end))),
                    generatedOn:{
                        from:queryParams.start,
                        to:queryParams.end
                },
                    reportType:queryParams.from
                }
            ))
        }
    },[])

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
    }


    const getNotificationsData = async () => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getScheduleNotifications,
            })

            if (response?.data?.status_code === 200) {
                setNotifications(response?.data?.data?.noti);
            }
        } catch (error) {
            handleError(error)
        }
    }

    useEffect(() => {
        getNotificationsData()
    }, [])
    return (
        <>
            <div className='overflow-auto w-full dashboard-reports'>
                <div className='bg-white'>
                    <>
                        <Row className='p-2'>
                            <Col sm={12} className='flex items-center gap-x-2 '>
                                <Row className='text-black inter m-0 tracking-wide items-center'>
                                    <Tooltip
                                        title={
                                            'Schedule notifications for overall dashboard users, displayed at the top until specified expiration.'
                                        }
                                    >
                                        <InfoCircleOutlined className='text-blue fs20' />
                                    </Tooltip>

                                <span className='ml-2'>Schedule a notification to keep your merchants informed and engaged.</span>
                                </Row>
                            </Col>
                            <Col className='flex flex-grow justify-end gap-x-2'>
                            <Button onClick={()=>{setEditOpen(true); setIsNew(true); setTitle('Add New Notification')}} variant='primary' className='flex align-center items-center'>
                                    <ReportsIcon width={16} color='white' />
                                    <span className='ml-2 text-white'>Add Schedule Notifications</span>
                                </Button>
                            </Col>
                        </Row>
                    </>
                </div>

                <div className='mt-5 bg-white rounded overflow-clip'>
                    <Table
                        className={'ordersTable'}
                        columns={columns}
                        dataSource={notifications || []}
                        pagination={{
                            current: parameters.page, // Current page number
                            pageSize: parameters.pageSize, // Number of items to display per page
                            total: total, // Total number of items in the data array
                            showSizeChanger: false,
                            onChange: handlePaginationChange,
                            position: ['topRight', 'bottomRight'],
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                        }}
                        rowClassName={(record, index) =>
                            (new Date().getTime() - new Date(record.generatedOn).getTime()) / 1000 < 30 &&
                            `animate-bg-${record.status.toLowerCase()}`
                        }
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            </div>
            {editOpen && (
                <EditNotificationsDrawer
                    title = {title}
                    open={!!editOpen}
                    onClose = {(text: string) => {
                        if(text === 'Added') getNotificationsData()
                        setEditOpen(false)
                        setIsNew(false)
                        setTitle('Edit Notification')
                    }}
                    recordData={editOpen}
                    type='transaction'
                    isNew = {isNew}
                />
            )}
        </>
    )
}
