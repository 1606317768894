import { Button, Col, Collapse, Form, Row, Steps, Tabs, DeleteOutlined, message } from 'gokwik-ui-kit'
import { merchantDetailFields } from './agreementInputData'
import RenderInput from './formInputs'
import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { getCurrencySymbol } from '@library/utilities/helpers/helper'

const formatDate = (val) => new Date(dayjs(val).toISOString()).toLocaleDateString('en-CA') + 'T00:00:00.000Z'

const PricingInputs = [
    {
        key: 'start_date',
        label: 'Pricing start date',
        input_details: {
            type: 'date',
            span: 6,
        },
    },
    {
        key: 'end_date',
        label: 'Pricing end date',
        input_details: {
            type: 'date',
            span: 6,
        },
    },
]

const deletePricing = async (field, callbackStates, i) => {
    if (field.pricing_strategy_id) {
        const data = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.rateCapture.pricingStrategy,
            payload: {
                is_deleted: true,
                id: field.pricing_strategy_id,
            },
        })
    }
    callbackStates.forEach((func) =>
        func((prev) => {
            const tempData = JSON.parse(JSON.stringify(prev))
            return tempData.filter((itm, idx) => idx !== i)
        }),
    )
}

const scaffolding: any = {}

const RenderSection = ({
    children,
    handleDelete,
    inputDisabled,
    inputData,
    data,
    id,
    hideDelete,
    index,
    setSubmitData,
    isCheckout,
}) => {
    const feeInputConfigs = [
        {
            label: `Minimum guarantee (${getCurrencySymbol()})`,
            input_details: {
                span: 12,
                type: 'spacer',
                spacerDetails: [
                    {
                        key: 'mg_fee_duration',
                        input_details: {
                            type: 'select',
                            options: [
                                {
                                    value: 'monthly',
                                    label: 'Monthly',
                                },
                                {
                                    value: 'quarterly',
                                    label: 'Quarterly',
                                },

                                {
                                    value: 'yearly',
                                    label: 'Yearly',
                                },
                            ],
                            defaultValue: 'monthly',
                            span: 3,
                        },

                        getValue: (input) =>
                            input.input_details.type === 'select'
                                ? data[input.key] || inputData[input.key] || '0'
                                : data[input.key] || inputData[input.key]
                                ? dayjs(data[input.key] || inputData[input.key])
                                : null,
                    },
                    {
                        key: 'minimum_guarantee_price',
                        input_details: {
                            type: 'number',
                            span: 3,
                        },
                        getValue: (input) =>
                            input.input_details.type === 'number'
                                ? data[input.key] || inputData[input.key] || '0'
                                : data[input.key] || inputData[input.key]
                                ? dayjs(data[input.key] || inputData[input.key])
                                : null,
                    },
                ],
            },
        },

        {
            label: `Platform fee (${getCurrencySymbol()})`,
            input_details: {
                span: 12,
                type: 'spacer',
                spacerDetails: [
                    {
                        key: 'platform_fee_duration',
                        input_details: {
                            type: 'select',
                            options: [
                                {
                                    value: 'monthly',
                                    label: 'Monthly',
                                },
                                {
                                    value: 'quarterly',
                                    label: 'Quarterly',
                                },

                                {
                                    value: 'yearly',
                                    label: 'Yearly',
                                },
                            ],
                            defaultValue: 'quarterly',
                            span: 3,
                        },

                        getValue: (input) =>
                            input.input_details.type === 'select'
                                ? data[input.key] || inputData[input.key] || '0'
                                : data[input.key] || inputData[input.key]
                                ? dayjs(data[input.key] || inputData[input.key])
                                : null,
                    },
                    {
                        key: 'platform_fee',
                        input_details: {
                            type: 'number',
                            span: 3,
                        },
                        getValue: (input) =>
                            input.input_details.type === 'number'
                                ? data[input.key] || inputData[input.key] || '0'
                                : data[input.key] || inputData[input.key]
                                ? dayjs(data[input.key] || inputData[input.key])
                                : null,
                    },
                ],
            },
        },
    ]
    return (
        <div className={`border-solid border-[1px] border-gray-200 p-3 ${index ? 'mt-2' : ''}`}>
            <Form layout='horizontal'>
                <Row gutter={16}>
                    {/* @ts-ignore */}
                    <RenderInput
                        data={[...PricingInputs, ...(isCheckout ? feeInputConfigs : [])]}
                        onChange={setSubmitData}
                        getValue={(input) =>
                            input.input_details.type === 'number'
                                ? data[input.key] || inputData[input.key] || '0'
                                : data[input.key] || inputData[input.key]
                                ? dayjs(data[input.key] || inputData[input.key])
                                : null
                        }
                    />
                    {!inputDisabled && (
                        <>
                            {!hideDelete && (
                                <Col className='flex justify-end pt-2' offset={isCheckout ? 3 : 10} span={2}>
                                    <span className='cursor-pointer' onClick={handleDelete}>
                                        <DeleteOutlined className='text-black text-xl hover:text-red-300' />
                                    </span>
                                </Col>
                            )}
                        </>
                    )}
                </Row>
            </Form>
            {children}
        </div>
    )
}

const AgreementForm = ({
    mode,
    id,
    merchantId,
    setPageAgreementId,
    pageAddendumId,
    type,
    setPageAddendumId,
    setLatestConfirmed,
    latestConfirmed,
    setLatestConfirmedType,
}) => {
    const [merchantDetails, setMerchantDetails]: any = useState({})
    const [checkoutData, setCheckoutData]: any = useState([{}])
    const [rtoData, setRtoData]: any = useState([{}])
    const [checkoutFields, setCheckoutFields] = useState([])
    const [rtoFields, setRtoFields] = useState([])
    const [agreementId, setAgreementId] = useState(id)
    const [addendumId, setAddendumId] = useState(pageAddendumId)
    const [file, setFile] = useState({ file: '', fileName: '' })
    const isAddendum = type === 'addendum'
    const [refetchValues, setRefetchValues] = useState(false)
    const inputDisabled = useMemo(() => {
        return mode === 'view'
    }, [mode])

    const populateData = async (data) => {
        const agreementDetails = Object.keys(merchantDetails).length ? merchantDetails : await getAgreement()

        if (data?.checkout) {
            if (agreementDetails.products_enabled?.some((itm) => itm.toLowerCase() === 'checkout')) {
                const [template, ...rest] = data.checkout
                setCheckoutFields(rest.length ? rest : [template])
                scaffolding.checkout = template

                if (rest.length) {
                    setCheckoutData(
                        rest.map((data) => ({
                            pricing_strategy: {
                                start_date: data.start_date,
                                end_date: data.end_date,
                                minimum_guarantee_price: data.minimum_guarantee_price || 0,
                                platform_fee: data.platform_fee || 0,
                                mg_fee_duration: data.mg_fee_duration || 'monthly',
                                platform_fee_duration: data.platform_fee_duration || 'quarterly',
                                product: 'checkout',
                                id: data.pricing_strategy_id,
                            },
                        })),
                    )
                } else {
                    setCheckoutData([
                        {
                            pricing_strategy: {
                                start_date: template.start_date,
                                end_date: template.end_date,
                                product: 'checkout',
                                minimum_guarantee_price: template.minimum_guarantee_price || 0,
                                platform_fee: template.platform_fee || 0,
                                mg_fee_duration: template.mg_fee_duration || 'monthly',
                                platform_fee_duration: template.platform_fee_duration || 'quarterly',
                                id: null,
                            },
                        },
                    ])
                }
            } else {
                setCheckoutData([])
                setCheckoutFields([])
            }
        }

        if (data?.rto) {
            if (agreementDetails.products_enabled?.some((itm) => itm.toLowerCase() === 'rto')) {
                const [template, ...rest] = data.rto
                setRtoFields(rest.length ? rest : [template])
                scaffolding.rto = template

                if (rest.length) {
                    setRtoData(
                        rest.map((data) => ({
                            pricing_strategy: {
                                start_date: data.start_date,
                                end_date: data.end_date,
                                product: 'rto',
                                id: data.pricing_strategy_id,
                            },
                        })),
                    )
                } else {
                    setRtoData([
                        {
                            pricing_strategy: {
                                start_date: template.start_date,
                                end_date: template.end_date,
                                product: 'rto',
                                id: null,
                            },
                        },
                    ])
                }
            } else {
                setRtoData([])
                setRtoFields([])
            }
        }
    }

    const updateData = async (data, product) => {
        const body = data.reduce((result, curr) => {
            const { isChanged, ...rest } = curr
            return [...result, ...(isChanged ? [rest] : [])]
        }, [])
        const response = await makeAPICall({
            method: 'post',
            url:
                process.env.REACT_APP_BASE_URL +
                `/api/dashboard/onboarding/agreement/${addendumId || agreementId}/agreement_values/update`,
            payload: {
                data: body,
            },
        })
        if (response.success) {
            message.success('Agreement updated successfully')
            const data = await fetchValues(product)
            if (data) populateData(data)
        } else {
            message.error(response?.response?.data?.data || 'Agreement updated failed')
        }
    }

    const saveAgreement = async () => {
        if (!merchantDetails.start_date) return message.error('Agreement start date required')
        if (!merchantDetails.end_date) return message.error('Agreement end date required')
        if (dayjs(merchantDetails.start_date).isAfter(merchantDetails.end_date, 'D'))
            return message.error(`Agreement start date can't be after agreement end date`)
        if (!merchantDetails.file_name && !file.file) return message.error('Agreement document required')
        if (isAddendum) {
            if (!merchantDetails.add_start_date) return message.error('Addendum start date required')
            if (!merchantDetails.add_end_date) return message.error('Addendum end date required')
            if (!merchantDetails.add_file_name && !file.file) return message.error('Addendum document required')
            if (dayjs(merchantDetails.add_start_date).isAfter(merchantDetails.add_end_date, 'D'))
                return message.error(`Addendum start date can't be after addendum end date`)
            if (dayjs(merchantDetails.start_date).isAfter(merchantDetails.add_start_date, 'D'))
                return message.error(`Addendum start date can't be earlier than agreement start date`)
            if (dayjs(merchantDetails.add_end_date).isAfter(merchantDetails.end_date, 'D'))
                return message.error(`Addendum end date can't be after agreement end date`)
        }
        if (!merchantDetails.products_enabled?.length) return message.error(`Purchased products can't be empty`)

        const body = isAddendum
            ? Object.entries(merchantDetails).reduce(
                  (result, curr) => ({
                      ...result,
                      ...(curr[0].includes('add') && {
                          [curr[0].replace('add_', '')]: curr[1],
                      }),
                  }),
                  { products_enabled: merchantDetails.products_enabled },
              )
            : merchantDetails
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.rateCapture.agreement,
            payload: {
                ...body,
                id: (isAddendum ? addendumId : agreementId) || null,
                merchant_id: merchantId,
                ...(isAddendum && { parent_id: agreementId }),
                type: isAddendum ? 'addendum' : 'agreement',
            },
        })
        if (response.success) {
            message.success('Agreement saved successfully')
            const newId = response.data.data.id
            isAddendum ? setAddendumId(newId) : setAgreementId(newId)
            isAddendum
                ? newId !== pageAddendumId && setPageAddendumId(newId)
                : newId !== id && setPageAgreementId(newId)
            setLatestConfirmed(newId)
            setLatestConfirmedType(response.data.data.type)
            setMerchantDetails(
                isAddendum
                    ? (prev) => ({
                          ...prev,
                          ...Object.entries(merchantDetails).reduce(
                              (result, curr) => ({
                                  ...result,
                                  ...(curr[0].includes('add') && {
                                      [curr[0]]: response.data.data[curr[0].replace('add_', '')],
                                  }),
                              }),
                              {},
                          ),
                      })
                    : response.data.data,
            )
            setRefetchValues((prev) => !prev)
            if (file.file) {
                const url = await getSignedUrl(response.data.data.id)
                fetch(url, {
                    method: 'PUT',
                    body: file.file,
                })
            }
        } else {
            message.error(response?.response?.data?.data || 'Failed')
        }
    }

    const getSignedUrl = async (agreementId) => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + `/api/dashboard/onboarding/agreement/presigned-url`,
            params: { agreementId, merchant_id: merchantId, file_name: file.fileName },
        })
        if (response.success) {
            return response.data.data
        }
    }

    const getAgreement = async () => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.rateCapture.agreement,
            params: { id: agreementId },
        })
        const addResponse = addendumId
            ? await makeAPICall({
                  method: 'get',
                  url: process.env.REACT_APP_BASE_URL + APIEndPoints.rateCapture.agreement,
                  params: { id: latestConfirmed },
              })
            : {}
        if (response.success) {
            const data = {
                ...response.data.data,
                ...(!!Object.keys(addResponse?.data?.data || {}).length && {
                    add_file_name: addResponse.data?.data.file_name,
                    add_start_date: addResponse.data?.data.start_date,
                    add_end_date: addResponse.data?.data.end_date,
                    products_enabled: addResponse.data?.data.products_enabled,
                }),
            }
            setMerchantDetails(data)
            return data
        }
    }

    const fetchValues = async (product) => {
        if (!latestConfirmed) {
            return
        }

        const data = await makeAPICall({
            method: 'get',
            url:
                process.env.REACT_APP_BASE_URL +
                `/api/dashboard/onboarding/agreement/${latestConfirmed}/agreement_values`,
        })
        if (data.success) return product ? { [product]: data.data.data?.[product] } : data.data.data
    }

    useEffect(() => {
        if (!agreementId) return
        ;(async () => {
            const data = await fetchValues(null)
            if (data) populateData(data)
        })()
    }, [agreementId, refetchValues])

    const items = [
        {
            key: '1',
            label: 'Agreement Details',
            children: (
                <Form wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                    <Row gutter={24}>
                        <RenderInput
                            data={
                                !isAddendum
                                    ? [
                                          ...merchantDetailFields,
                                          {
                                              key: 'products_enabled',
                                              label: 'Purchased products',
                                              input_details: {
                                                  type: 'select',
                                                  mode: 'multiple',

                                                  options: [
                                                      {
                                                          label: 'Checkout',
                                                          value: 'checkout',
                                                      },
                                                      {
                                                          label: 'RTO',
                                                          value: 'rto',
                                                      },
                                                  ],
                                              },
                                          },
                                      ]
                                    : [
                                          ...merchantDetailFields.map((itm) => ({
                                              ...itm,
                                              disabled: true,
                                          })),
                                          {
                                              key: 'products_enabled',
                                              label: 'Purchased products',
                                              input_details: {
                                                  type: 'select',
                                                  mode: 'multiple',

                                                  options: [
                                                      {
                                                          label: 'Checkout',
                                                          value: 'checkout',
                                                      },
                                                      {
                                                          label: 'RTO',
                                                          value: 'rto',
                                                      },
                                                  ],
                                              },
                                          },
                                          {
                                              key: 'add_file_name',
                                              label: 'Merchant addendum',
                                              url: 'add_signed_url',
                                              input_details: {
                                                  type: 'upload',
                                              },
                                          },
                                          {
                                              key: 'add_start_date',
                                              label: 'Addendum start date',
                                              input_details: {
                                                  type: 'date',
                                              },
                                          },
                                          {
                                              key: 'add_end_date',
                                              label: 'Addendum end date',
                                              input_details: {
                                                  type: 'date',
                                              },
                                          },
                                      ]
                            }
                            onChange={(item, val) =>
                                item.input_details.type === 'upload'
                                    ? setFile(val)
                                    : setMerchantDetails((prev) => ({
                                          ...prev,
                                          [item.key]:
                                              item.input_details?.type === 'number'
                                                  ? +val
                                                  : item.input_details.type === 'date'
                                                  ? val
                                                      ? formatDate(val)
                                                      : null
                                                  : val,
                                      }))
                            }
                            getValue={(item) =>
                                item.input_details.type === 'date'
                                    ? merchantDetails[item.key]
                                        ? dayjs(merchantDetails[item.key])
                                        : null
                                    : item.input_details.type === 'number'
                                    ? `${merchantDetails[item.key] || ''}`
                                    : item.input_details.type === 'upload'
                                    ? {
                                          name: merchantDetails[item.key],
                                          url: merchantDetails[item.url],
                                      }
                                    : merchantDetails[item.key]
                            }
                            disableAll={inputDisabled}
                        />
                        {!inputDisabled && (
                            <Col className='flex justify-center pt-8' span={2} offset={isAddendum ? 2 : 14}>
                                <Button type='primary' onClick={() => saveAgreement()}>
                                    Save
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Form>
            ),
        },
        ...(checkoutFields?.length && (isAddendum ? addendumId : true)
            ? [
                  {
                      key: '2',
                      label: 'Checkout',
                      children: (
                          <div className='flex flex-col'>
                              {checkoutFields?.map((field, i) => (
                                  // @ts-ignore
                                  <RenderSection
                                      key={field?.id || i}
                                      index={i}
                                      inputData={field}
                                      data={checkoutData[i]?.pricing_strategy || {}}
                                      inputDisabled={inputDisabled}
                                      hideDelete={checkoutFields?.length === 1}
                                      isCheckout={true}
                                      handleDelete={() => deletePricing(field, [setCheckoutData, setCheckoutFields], i)}
                                      setSubmitData={(item, val) => {
                                          setCheckoutData((prev) => {
                                              const tempData = JSON.parse(JSON.stringify(prev))
                                              tempData[i] = {
                                                  ...tempData[i],
                                                  isChanged: true,
                                                  pricing_strategy: {
                                                      ...(tempData[i]?.pricing_strategy || {}),
                                                      [item.key]:
                                                          item.input_details.type === 'select'
                                                              ? val
                                                              : item.input_details.type === 'number'
                                                              ? +val
                                                              : val
                                                              ? formatDate(val)
                                                              : null,
                                                  },
                                              }
                                              return tempData
                                          })
                                      }}
                                  >
                                      <Tabs
                                          items={field?.data
                                              ?.filter((item) => item.level === 0)
                                              .map((curr) => ({
                                                  key: curr.key,
                                                  label: curr.label,
                                                  children: (
                                                      <Form
                                                          key={curr.key}
                                                          wrapperCol={{ span: 24 }}
                                                          labelCol={{ span: 24 }}
                                                      >
                                                          <Row gutter={24}>
                                                              {/* @ts-ignore */}
                                                              <RenderInput
                                                                  data={curr.children}
                                                                  onChange={(data) =>
                                                                      setCheckoutData((prev) => {
                                                                          const { tierId, ...rest } = data
                                                                          const tempData = JSON.parse(
                                                                              JSON.stringify(prev),
                                                                          )
                                                                          tempData[i] = {
                                                                              ...tempData[i],
                                                                              isChanged: true,
                                                                              agreement_value: [
                                                                                  ...(tempData[
                                                                                      i
                                                                                  ]?.agreement_value?.filter(
                                                                                      (itm) =>
                                                                                          tierId !== itm.tierId ||
                                                                                          itm.agreement_key_id !==
                                                                                              data.agreement_key_id ||
                                                                                          itm.id !== data.id,
                                                                                  ) || []),
                                                                                  { ...data },
                                                                              ],
                                                                          }
                                                                          return tempData
                                                                      })
                                                                  }
                                                                  disableAll={inputDisabled}
                                                              />
                                                          </Row>
                                                      </Form>
                                                  ),
                                              }))}
                                          defaultActiveKey={field.data?.[0]?.key}
                                      />
                                  </RenderSection>
                              ))}
                              {!inputDisabled && (
                                  <div className='w-full flex justify-between mt-2'>
                                      <Button
                                          onClick={() => {
                                              setCheckoutFields((prev) => [...prev, scaffolding.checkout])
                                              setCheckoutData((prev) => [
                                                  ...prev,
                                                  {
                                                      pricing_strategy: {
                                                          product: 'checkout',
                                                          start_date: scaffolding.checkout.start_date,
                                                          end_date: scaffolding.checkout.end_date,
                                                          minimum_guarantee_price:
                                                              scaffolding.checkout.minimum_guarantee_price || 0,
                                                      },
                                                  },
                                              ])
                                          }}
                                          type='primary'
                                          className='self-start'
                                      >
                                          {' '}
                                          + Add more
                                      </Button>

                                      <Button
                                          type='primary'
                                          onClick={() =>
                                              updateData(
                                                  checkoutData.map((itm) => {
                                                      const temp = { ...itm }
                                                      delete temp?.agreement_value?.tierId
                                                      return temp
                                                  }),
                                                  'checkout',
                                              )
                                          }
                                      >
                                          Save
                                      </Button>
                                  </div>
                              )}
                          </div>
                      ),
                  },
              ]
            : []),
        ...(rtoFields?.length && (isAddendum ? addendumId : true)
            ? [
                  {
                      key: '3',
                      label: 'RTO',
                      children: (
                          <div className='flex flex-col'>
                              {rtoFields?.map((field, index, array) => (
                                  // @ts-ignore
                                  <RenderSection
                                      key={field.id || index}
                                      index={index}
                                      hideDelete={array.length === 1}
                                      inputData={field}
                                      inputDisabled={inputDisabled}
                                      data={rtoData[index]?.pricing_strategy || {}}
                                      setSubmitData={(item, val) => {
                                          setRtoData((prev) => {
                                              const tempData = JSON.parse(JSON.stringify(prev))
                                              tempData[index] = {
                                                  isChanged: true,
                                                  ...tempData[index],
                                                  pricing_strategy: {
                                                      ...(tempData[index]?.pricing_strategy || {}),
                                                      [item.key]: val ? formatDate(val) : null,
                                                  },
                                              }
                                              return tempData
                                          })
                                      }}
                                      handleDelete={() => deletePricing(field, [setRtoData, setRtoFields], index)}
                                  >
                                      <Form wrapperCol={{ span: 24 }} labelCol={{ span: 24 }}>
                                          <Row gutter={16}>
                                              <RenderInput
                                                  data={field?.data?.[0]?.children}
                                                  onChange={(item, val) => {
                                                      setRtoData((prev) => {
                                                          const tempData = JSON.parse(JSON.stringify(prev))
                                                          const value =
                                                              item.input_details.type === 'date'
                                                                  ? val
                                                                      ? formatDate(val)
                                                                      : null
                                                                  : val
                                                          tempData[index] = {
                                                              isChanged: true,
                                                              ...tempData[index],
                                                              agreement_value: [
                                                                  ...(tempData[index]?.agreement_value?.filter(
                                                                      (prev) =>
                                                                          prev.agreement_key_id !== item.id ||
                                                                          prev.id !== item.value_id,
                                                                  ) || []),
                                                                  {
                                                                      agreement_key_id: item.id,
                                                                      id: item.value_id,
                                                                      value: [{ value }],
                                                                  },
                                                              ],
                                                          }
                                                          return tempData
                                                      })
                                                  }}
                                                  getValue={(item) => {
                                                      const val = rtoData[index]?.agreement_value?.find(
                                                          (itm) => itm?.agreement_key_id === item.id,
                                                      )?.value?.[0]?.value
                                                      if (typeof val === 'boolean') {
                                                          return val ? 'Enabled' : 'Disabled'
                                                      }
                                                      return val || item.value?.[0]?.value
                                                  }}
                                                  disableAll={inputDisabled}
                                              />
                                          </Row>
                                      </Form>
                                  </RenderSection>
                              ))}
                              {!inputDisabled && (
                                  <div className='w-full flex justify-between mt-2'>
                                      <Button
                                          onClick={() => {
                                              setRtoFields((prev) => [...prev, scaffolding.rto])
                                              setRtoData((prev) => [
                                                  ...prev,
                                                  {
                                                      pricing_strategy: {
                                                          product: 'rto',
                                                          start_date: scaffolding.rto.start_date,
                                                          end_date: scaffolding.rto.end_date,
                                                      },
                                                  },
                                              ])
                                          }}
                                          type='primary'
                                          className='align-self-start'
                                      >
                                          {' '}
                                          + Add more
                                      </Button>

                                      <Button type='primary' onClick={() => updateData(rtoData, 'rto')}>
                                          Save
                                      </Button>
                                  </div>
                              )}
                          </div>
                      ),
                  },
              ]
            : []),
    ]

    return (
        <div className='mt-2'>
            <Collapse items={items} defaultActiveKey={['1']} />
        </div>
    )
}

export default AgreementForm
