import React, { useEffect, useState } from 'react';
import { Button, Divider } from "gokwik-ui-kit";
import previewPlaceholder1 from '@library/images/icons/codStep11.png'; 
import darkExternalLink from '@library/images/icons/dark-external-link.svg';
import lightExternalLink from '@library/images/icons/light-external-link.svg';
import HowToStepsWithPreview from '../CommonComponents/howToStepsAndPreview';
import { logEvent } from "@library/utilities/userLogsEvents/userLogEvents";
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux';
import { updateMerchantOnboardingCheckoutConfigsStatus } from '../CommonComponents/Utils';
import CheckoutStepsHeader from '../CommonComponents/checkoutStepsHeader';
import { fetchUserDetails } from '@store/user';
import { useAppDispatch } from '@library/utilities/hooks'

const CodOnboardingStep1 = ({ onStepComplete, setSiteVisited, handleInstallAppClick, siteVisited, getCodAppInstalledStatus, currentStep, expandedStep, toggleStep }) => {
  const userDetails = useSelector(getUserDetails)
  const merchantDetails = useSelector(getMerchantDetails)
  const [showContent, setShowContent] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
      if (expandedStep == 1 && currentStep !== 1) {
        setSiteVisited(true);
      }
      if (expandedStep == 1) {
          setShowContent(false);
          setTimeout(() => setShowContent(true), 100);
      }
  }, [expandedStep]);

  const steps = [
    {
      description: (<>Click on <b>“Install COD module”</b> button.</>) ,
      step: 1,
    },
    {
      description: (<>Click <b>“Install”</b> button on Shopify store.</>) 
    },
    {
      description: (<>You'll be redirected to the <b>Shopify dashboard</b>. Refresh the page and click <b>'Activate'</b> to enable GoKwik COD.</>)
    },
    {
      description: (<>After installing the app, <b>come back to this page.</b></>) 
    },
    {
      description: (<>Click on <b>“Finish Installation”</b> Button.</>) 
    }
  ]

    const finishInstallationClicked = async () => {
        logEvent(
            "cod_finish_installation_clicked",
            'click',
            'cod-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { Step: 'cod 1' }
        )
        const res = await getCodAppInstalledStatus()
        if (res) {
            updateMerchantOnboardingCheckoutConfigsStatus(
                'smart_cod_app_activated',
                () => {
                    dispatch(fetchUserDetails())
                    onStepComplete();
                },
                merchantDetails,
            )
        }
    }

    return (
        <div className='border border-gray-300 rounded-lg bg-white w-[74vw]'>
            <CheckoutStepsHeader
                step={'Step 1'}
                heading={'Install COD Module'}
                toggleStep={toggleStep}
                stepId={1}
                currentStep={currentStep}
            />

            {expandedStep == 1 ? (
                <div
                    style={{
                        opacity: showContent ? 1 : 0,
                        transform: showContent ? 'translateY(0)' : 'translateY(20px)',
                        transition: 'opacity 0.5s ease, transform 0.5s ease',
                    }}
                >
                    <Divider className='m-0' />
                    <HowToStepsWithPreview
                        steps={steps}
                        previewImage={previewPlaceholder1}
                        videoUrl={'https://pdp.gokwik.co/sa-login-ui/assets/InstallCODModule.mp4'}
                        page={'cod'}
                    />
                    <Divider  className='m-0' />

                    <div className={`flex ${siteVisited ? "" : "flex-row-reverse"} justify-between items-center p-3`}>
                        <Button
                            onClick={handleInstallAppClick}
                            variant={siteVisited ? 'default' : 'primary'}
                            className="flex items-center"
                            size="large"
                        >
                            Install COD module <img style={{ marginLeft: '0.4rem' }} src={siteVisited ? darkExternalLink : lightExternalLink} />
                        </Button>
                        {siteVisited && ( 
                          <Button disabled={expandedStep == 1 && currentStep !== 1} onClick={finishInstallationClicked} type="primary" size="large">
                                Finish Installation
                            </Button>
                        )}
                    </div>
                </div>
            ) : "" }
        </div>
    );
};

export default CodOnboardingStep1;
