import { Chart } from 'gokwik-ui-kit'

const BarChart = ({ data }) => {
    const { merchant_onboarding_stats } = data

    const finalData = {
        merchants_signed_up: merchant_onboarding_stats?.merchants_signup_count || 0,
        onboarding_completed: merchant_onboarding_stats?.merchants_go_live_count || 0,
        orders_started_on_gokwik: merchant_onboarding_stats?.merchants_order_started_count || 0,
    }

    const chartOptions = {
        chart: {
            type: 'bar',
            toolbar: { show: false },
        },
        xaxis: {
            categories: ['Merchants Signed Up', 'Onboarding Completed', 'Order Started on GoKwik'],
            title: {
                text: '',
            },
        },
        dataLabels: { enabled: false },
        title: {
            text: '',
            align: 'left',
        },
        yaxis: {
            title: {
                text: 'Count',
            },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
        },
    }

    const chartSeries = [
        {
            name: 'Count',
            data: [
                finalData?.merchants_signed_up,
                finalData?.onboarding_completed,
                finalData?.orders_started_on_gokwik,
            ],
        },
    ]

    return (
        <Chart
            //@ts-ignore
            options={chartOptions}
            series={chartSeries}
            type='bar'
            height={200}
        />
    )
}

export default BarChart
