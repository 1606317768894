import { getMerchantDetails, getUserData } from '@store/user/selectors'
import { Timeline } from 'gokwik-ui-kit'
import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getStepFromStatus, stepperStatusChipDot } from './utils'
import { stepConfiguration } from '../stepsConfig'

const KycStepper = ({ currentStep, setCurrentStep, isPanelClickable, isCPVMandatory }) => {
    const merchant_details = useSelector(getMerchantDetails)
    const hasMounted = useRef(false)
    const userData = useSelector(getUserData)

    const initialSteps = [
        { stepNumber: 0, title: 'Basic Information', status: null, isActive: false },
        { stepNumber: 1, title: 'Address Details', status: null, isActive: false },
        { stepNumber: 2, title: 'Bank Details', status: null, isActive: false },
        { stepNumber: 3, title: 'Business Details', status: null, isActive: false },
        { stepNumber: 4, title: 'Document Upload', status: null, isActive: false },
        { stepNumber: 5, title: 'Contact Point Verification', status: null, isActive: false },
    ]
    const [steps, setSteps] = useState(initialSteps)

    useEffect(() => {
        let updated = initialSteps.map((step) => {
            let currentStatusWiseStepNumber = getStepFromStatus(merchant_details?.onboarding_status?.kwik_checkout_app)
            if (step.stepNumber <= currentStatusWiseStepNumber) {
                return { ...step, status: 'Completed', isActive: false }
            } else if (step.stepNumber === currentStatusWiseStepNumber + 1) {
                return { ...step, status: 'Pending', isActive: true }
            } else {
                return { ...step, status: 'Pending', isActive: false }
            }
        })

        setSteps(updated)
    }, [merchant_details?.onboarding_status?.kwik_checkout_app])

    useEffect(() => {
        if (hasMounted.current) {
            let updated = steps?.map((step) => {
                if (step.stepNumber === currentStep) {
                    return { ...step, isActive: true }
                } else {
                    return { ...step, isActive: false }
                }
            })

            setSteps(updated)
        } else {
            hasMounted.current = true
        }
    }, [currentStep])

    const createStep = ({ stepNumber, title, status, isActive }) => {
        const sectionName = () => {
            if (stepNumber === 0) {
                return 'basic_info'
            } else if (stepNumber === 1) {
                return 'address_details'
            } else if (stepNumber === 2) {
                return 'bank_details'
            } else if (stepNumber === 3) {
                return 'business_details'
            } else if (stepNumber === 4) {
                return 'document_upload'
            } else if (stepNumber === 5) {
                return 'kyc_vpc'
            }
        }

        const customStepperChipDot = stepperStatusChipDot(
            userData,
            sectionName(),
            stepConfiguration,
            merchant_details?.onboarding_status?.kwik_checkout_app,
            isCPVMandatory,
        )
        const activeStyle = isActive
            ? {
                  padding: '6px 14px',
                  borderLeft: '1px solid #004B8D',
                  background: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFF 100%), #E6EDF4',
                  color: '#004B8D',
              }
            : {}

        return {
            dot:
                status === 'Completed' ? (
                    customStepperChipDot.dot
                ) : (
                    <div className='w-2 h-2 bg-[#004B8D] rounded-full'></div>
                ),
            children: (
                <div
                    className='flex flex-col gap-3 items-start cursor-pointer py-1.5 px-3.5'
                    style={activeStyle}
                    onClick={isPanelClickable(stepNumber) !== 'disabled' ? () => handleClick(stepNumber) : () => {}}
                >
                    <div className='text-xs text-gray-400'>Step {stepNumber + 1}</div>
                    <div className='flex flex-col items-start'>
                        <div className='text-sm text-gray-700 font-normal'>{title}</div>
                        <div>{customStepperChipDot?.chip}</div>
                    </div>
                </div>
            ),
        }
    }

    const handleClick = (stepNumber) => {
        let updated = steps?.map((step) => {
            if (step.stepNumber === stepNumber) {
                return { ...step, isActive: true }
            } else {
                return { ...step, isActive: false }
            }
        })

        setSteps(updated)
        setCurrentStep(stepNumber)
    }

    return (
        <div className='bg-white rounded-lg px-4 pt-6 pb-0'>
            <Timeline items={steps.map((step) => createStep(step))} />
        </div>
    )
}

export default KycStepper
