import payment_method from '@library/images/common/payment_method.png'
import order_cancel from '@library/images/common/order_cancel.png'
import cod_sync_check from '@library/images/common/cod_sync_check.png'

export default function OnBoardingTips() {
    const onboardingTipsData = [
        {
            image: <img src={payment_method} />,
            title: 'Payment Methods',
            content:
                'We have hidden other payment methods. Ensure you deactivate them from Payments to avoid any unnecessary charges.',
        },

        {
            image: <img src={cod_sync_check} className='w-[310px] h-[231px] object-cover' />,
            title: 'Ensure You Create COD Sync Check',
            content:
                'Create a test order using the cash on delivery (COD) option. Then, verify that the order is accurately synced as COD in both your order management system (OMS) and with your third-party logistic partners.',
        },

        {
            image: <img src={order_cancel} className='w-[310px] h-[231px] object-cover' />,
            title: 'Activating Order Cancellation',
            content:
                'Before canceling any order, ensure you click on "Activate Order Cancellation" for that specific order. Happy managing!',
        },
    ]

    return (
        <>
            <div className='pt-8 pb-16 px-12 grid grid-cols-3 gap-4'>
                {onboardingTipsData?.map((item, i) => {
                    return (
                        <div
                            key={i}
                            className='flex flex-col gap-6 h-auto bg-white border shadow-[0px_4px_4px_0px_#F3F5F6] rounded-lg border-solid border-[#EFF8FF] p-5'
                        >
                            <div className='bg-[#F1F1F1]'>{item.image}</div>

                            <div>
                                <div className=' text-sm font-semibold'>{item.title}</div>
                                <p className='text-[#000000A6] text-sm font-normal line-clamp-3'>{item.content}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}
