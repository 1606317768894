import { Button, Col, Collapse, Divider, DownOutlined, Form, Input, InputNumber, Row, Select } from 'gokwik-ui-kit'
import { basicDetailsFormConfig } from './config'
import { useEffect, useState } from 'react'
import { CollapsePanelStatusChips, getFormStatusConfigs, handleFieldChange } from '@pages/onboarding/kyc/utils'
import { getUserData } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { stepConfiguration } from '@pages/onboarding/stepsConfig'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

const BasicDetails = ({
    kycData,
    onFinish,
    setCurrentStep,
    currentStep,
    isPanelClickable,
    undeterminedKycErrorState,
}) => {
    const [form] = Form.useForm()
    const userData = useSelector(getUserData)
    const [basic_details_fields, set_basic_details_fields] = useState(basicDetailsFormConfig?.items)
    const [errors, setError] = useState([])
    const current_onboarding_status = userData?.merchantDetails?.onboarding_status?.kwik_checkout_app

    useEffect(() => {
        const getStatus = getFormStatusConfigs(userData, basic_details_fields, 'basic_info')
        setError(getStatus?.generateErrorChips)
        set_basic_details_fields(getStatus?.updateConfigWithErrors)
    }, [userData])

    const isFieldEnabled = (fieldName) => {
        if (current_onboarding_status === 'kyc_verification_failed') {
            if (Object.keys(userData?.kycData?.kyc_failure_reasons)?.includes(fieldName) || undeterminedKycErrorState) {
                return false //dont-disable-the-field
            } else {
                return true //yes-disable-the-field
            }
        } else if (
            current_onboarding_status === 'kyc_verification_pending' ||
            current_onboarding_status === 'kyc_completed'
        ) {
            return true //yes-disable-the-field
        }
    }

    const items = [
        {
            key: '1',
            label: (
                <>
                    <div className='flex flex-col gap-1'>
                        <div className='text-[#004B8D] text-xs font-semibold'>Step 1</div>

                        <div className='flex gap-4 items-center'>
                            <div className={`text-xl font-semibold ${errors?.length && 'text-red-700'}`}>
                                Basic Information
                            </div>{' '}
                            <CollapsePanelStatusChips
                                errors={errors}
                                stepConfiguration={stepConfiguration}
                                current_onboarding_status={
                                    userData?.merchantDetails?.onboarding_status?.kwik_checkout_app
                                }
                                sectionName={'basic_info'}
                            />
                        </div>
                    </div>
                </>
            ),
            children: (
                <Form
                    initialValues={kycData || {}}
                    form={form}
                    scrollToFirstError={{ behavior: 'auto', block: 'center' }}
                    onFinish={(v) => {
                        logEvent(
                            'basic_info_save_continue_clicked',
                            'click',
                            'kyc',
                            userData?.userDetails?.email,
                            userData?.merchantDetails?.m_id,
                        )
                        onFinish(v, 0)
                    }}
                    layout='vertical'
                    onFieldsChange={(changedField, allFields) =>
                        handleFieldChange(changedField, basic_details_fields, set_basic_details_fields)
                    }
                >
                    <Row gutter={16}>
                        {basic_details_fields?.map((item, index) => (
                            <Col span={12} key={index}>
                                <Form.Item
                                    extra={
                                        item.name === 'entity_type' && !isFieldEnabled(item?.name) ? (
                                            <span className='text-orange-400'>{item?.warning}</span>
                                        ) : (
                                            <></>
                                        )
                                    }
                                    label={item.label}
                                    name={item.name}
                                    rules={item.rules}
                                    {...((item as any)?.validateStatus &&
                                        (item?.name !== 'business_name' ||
                                            //@ts-ignore
                                            (item?.name === 'website' && item?.help?.includes('URL'))) && {
                                            validateStatus: (item as any)?.validateStatus,
                                        })}
                                    {...((item as any)?.help &&
                                        (item?.name !== 'business_name' ||
                                            //@ts-ignore
                                            (item?.name === 'website' && item?.help?.includes('URL'))) && {
                                            help: (item as any)?.help,
                                        })}
                                >
                                    {item.type === 'input' && (
                                        <Input
                                            placeholder={item.placeholder}
                                            disabled={item.disabled || isFieldEnabled(item?.name)}
                                        />
                                    )}
                                    {item.type === 'inputNumber' && (
                                        <InputNumber
                                            placeholder={item.placeholder}
                                            className='w-full'
                                            disabled={isFieldEnabled(item?.name)}
                                        />
                                    )}
                                    {item.type === 'select' && (
                                        <Select
                                            filterSort={null}
                                            placeholder={item.placeholder}
                                            disabled={isFieldEnabled(item?.name)}
                                        >
                                            {item.name === 'business_category'
                                                ? userData?.kycData?.allowed_business_categories.map((option) => (
                                                      <Select.Option key={option} value={option}>
                                                          {option}
                                                      </Select.Option>
                                                  ))
                                                : []}
                                            {item.name === 'entity_type'
                                                ? item.options.map((option, index) => (
                                                      <Select.Option key={index} value={Number(Object.values(option))}>
                                                          {Object.keys(option)[0]}
                                                      </Select.Option>
                                                  ))
                                                : []}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>

                    <Divider className='my-4' />
                    <Form.Item className='flex justify-end mb-0'>
                        <Button
                            variant='primary'
                            htmlType='submit'
                            disabled={
                                (errors?.length === 0 &&
                                    current_onboarding_status === 'kyc_verification_failed' &&
                                    !userData?.kycData?.kyc_rejection_reason) ||
                                userData?.merchantDetails?.onboarding_status?.kwik_checkout_app ===
                                    'kyc_verification_pending' ||
                                current_onboarding_status === 'kyc_completed'
                            }
                        >
                            Save & Continue
                        </Button>
                    </Form.Item>
                </Form>
            ),
        },
    ]

    const onChange = (e) => {
        setCurrentStep(0)
    }

    return (
        <div className=''>
            <Collapse
                collapsible={isPanelClickable(0)}
                className='bg-white'
                activeKey={currentStep === 0 && ['1']}
                onChange={onChange}
                items={items}
                accordion
                expandIconPosition='right'
                expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
            />
        </div>
    )
}

export default BasicDetails
