import React, { useEffect, useRef, useState } from 'react'
import { Button, Divider, message, Modal, PlayCircleFilled, Form } from 'gokwik-ui-kit'
import extraFreeCod from '@library/images/icons/extraFreeCod.svg'
import noExtraFeeCod from '@library/images/icons/noExtraFeeCod.svg'
import step1 from '@library/images/icons/circle-number-1.svg'
import step2 from '@library/images/icons/circle-number-2.svg'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { updateMerchantOnboardingCheckoutConfigsStatus } from '../CommonComponents/Utils'
import CheckoutStepsHeader from '../CommonComponents/checkoutStepsHeader'
import { fetchUserDetails } from '@store/user'
import { useAppDispatch } from '@library/utilities/hooks'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import AddShippingRateForm from './ShippingServicebility/ShippingForm'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import AddedShipping from './ShippingServicebility/AddedShipping'

const CodOnboardingStep2 = ({ onStepComplete, currentStep, expandedStep, toggleStep }) => {
    const [noExtraCharges, setNoExtraCharges] = useState(true)
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)
    const [showContent, setShowContent] = useState(false)
    const dispatch = useAppDispatch()
    const [configJSON, setConfigJSON] = useState(null)
    const [listItems, setListItems] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showShippingForm, setShowShippingForm] = useState(false)
    const [action, setAction] = useState({ name: null, id: null })
    const [modalTitle, setModalTitle] = useState('Add shipping method')
    const [onBoardingStates, setOnBoardingStates] = useState({
        addCodShippingRates: false,
    })
    const [showNewShipping, setShowNewShipping] = useState(false)
    const [playTutorial, setPlayTutorial] = useState(false)

    useEffect(() => {
        if (expandedStep == 2) {
            setShowContent(false)
            setTimeout(() => setShowContent(true), 100)
        }
    }, [expandedStep])

    const logEventFunc = (msg, type = 'success') => {
        logEvent(
            msg,
            type,
            'cod-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { Step: 'cod 2' },
        )
    }

    const handleExtraCharges = async () => {
        logEventFunc('setup_cod_fee_clicked', 'click')
        await getShippingConfig()
        setShowNewShipping(false)
        setShowShippingForm(false)
        if (listItems?.length === 0) {
            setAction({ name: 'add', id: null })
        }
        setShowModal(true)
    }

    const handleShippingSetupDone = () => {
        if (noExtraCharges) {
            logEventFunc('continue_with_no_extra_charge_clicked', 'click')
        }
        updateMerchantOnboardingCheckoutConfigsStatus(
            'gokwik_shipping_setup_done',
            () => {
                dispatch(fetchUserDetails())
                onStepComplete()
            },
            merchantDetails,
        )
        setShowModal(false)
    }

    const getShippingConfig = async () => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyMerchantConfigs,
        })

        if (response?.data?.status_code === 200) {
            setConfigJSON({ shippingConfig: response?.data?.data?.shippingConfig || [] })
            setListItems(response?.data?.data?.shippingConfig || [])
        }
    }

    const addShippingMethod = async (values) => {
        const tempShippingConfig = configJSON.shippingConfig
        const shippingConfig = {
            shipping_name: values.shipping_name?.trim(),
            shipping_charge: values.shipping_charge,
            paymentOption: values.paymentOption,
            minOrderPrice: values.minOrderPrice,
            maxOrderPrice: values.maxOrderPrice,
            minWeight: values.minWeight,
            maxWeight: values.maxWeight,
        }
        tempShippingConfig.push(shippingConfig)
        setShowModal(false)
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyMerchantConfigs,
                payload: {
                    shippingConfig: tempShippingConfig,
                },
            })

            if (response.data.status_code === 200) {
                setShowShippingForm(false)
                setShowNewShipping(true)
                message.success('Shipping rate added successfully')
                getShippingConfig()
                setShowModal(true)
                setAction({ id: null, name: null })
                logEventFunc('shipping_serviceability_add_success')
            } else {
                setAction({ id: null, name: null })
                setShowShippingForm(false)
                logEventFunc('shipping_serviceability_add_failure', 'failure')
            }
        } catch (error) {
            setShowShippingForm(false)
            setAction({ id: null, name: null })
            logEventFunc('shipping_serviceability_add_failure', 'failure')
            console.error('Error:', error)
            message.error('Failed to add shipping rate')
        }
    }

    const editShippingMethod = async (values) => {
        const temp = { ...configJSON }
        const index = temp.shippingConfig.findIndex((item) => item.id === action.id)

        if (index !== -1) {
            temp.shippingConfig[index] = {
                ...temp.shippingConfig[index], // Keep existing properties
                ...values, // Update with new values
            }
        }
        setShowModal(false)

        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyMerchantConfigs,
                payload: temp,
            })

            if (response.data.status_code === 200) {
                message.success('Shipping method edited successfully')
                getShippingConfig()
                setShowModal(true)
                setShowNewShipping(false)
                setShowShippingForm(false)
                setAction({ id: null, name: null })
                logEventFunc('shipping_serviceability_edit_success')
            } else {
                setAction({ id: null, name: null })
                logEventFunc('shipping_serviceability_edit_failure', 'failure')
            }
        } catch (error) {
            setAction({ id: null, name: null })
            logEventFunc('shipping_serviceability_edit_failure', 'failure')
            console.error('Error:', error)
            message.error('Failed to edit shipping rate')
        }
    }

    useEffect(() => {
        getShippingConfig()
    }, [])

    const handleActionButtonClick = (actionName, shippingMethodId) => {
        if (actionName === 'edit') {
            logEventFunc('shipping_serviceability_edit_clicked', 'click')
            const shippingMethod = listItems?.find((item) => item.id === shippingMethodId)
            setModalTitle(`Edit ${shippingMethod?.shipping_name} shipping method`)
            setAction({ name: actionName, id: shippingMethodId })
            setShowShippingForm(true)
        } else {
            logEventFunc('shipping_serviceability_delete_clicked', 'click')
            handleDelete(shippingMethodId)
        }
    }

    const handleDelete = async (id: string) => {
        const temShipping = listItems.filter((item) => item.id !== id)
        setShowModal(false)
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyMerchantConfigs,
                payload: {
                    shippingConfig: temShipping,
                },
            })

            if (response.data.status_code === 200) {
                setShowShippingForm(false)
                setShowModal(true)
                message.success('Shipping rate deleted successfully')
                getShippingConfig()
                setShowNewShipping(false)
                setAction({ id: null, name: null })
                logEventFunc('shipping_serviceability_delete_success')
            } else {
                logEventFunc('shipping_serviceability_delete_failure', 'failure')
            }
        } catch (error) {
            logEventFunc('shipping_serviceability_delete_failure', 'failure')
            console.error('Error:', error)
            message.error('Failed to add shipping rate')
        }
    }

    const handleCloseModal = () => {
        setShowModal(false)
        setAction({ id: null, name: null })
    }

    const handleNewShipping = () => {
        setShowShippingForm(true)
        setAction({ id: null, name: 'add' })
    }

    return (
        <div>
            <div className='border border-gray-300 rounded-lg bg-white w-[74vw]'>
                <CheckoutStepsHeader
                    step={'Step 2'}
                    heading={'Setup Shipping Method'}
                    toggleStep={toggleStep}
                    stepId={2}
                    currentStep={currentStep}
                />

                {expandedStep === 2 ? (
                    <div
                        style={{
                            opacity: showContent ? 1 : 0,
                            transform: showContent ? 'translateY(0)' : 'translateY(20px)',
                            transition: 'opacity 0.5s ease, transform 0.5s ease',
                        }}
                    >
                        <Divider className='m-0' />
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button
                                style={{
                                    border: 'none',
                                    outline: 'none',
                                    background: 'none',
                                    padding: '0',
                                    margin: '0',
                                    cursor: 'pointer',
                                    display: 'block',
                                }}
                                onClick={() => setNoExtraCharges(true)}
                            >
                                <div
                                    style={{
                                        borderRadius: '8px',
                                        border: noExtraCharges
                                            ? '2px solid rgba(0, 75, 141, 1)'
                                            : '1px solid rgba(0, 0, 0, 0.15)',
                                        padding: '0.5rem',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        height: '19rem',
                                        width: '21rem',
                                        margin: '1rem 0.5rem',
                                    }}
                                >
                                    <img className='mb-4' src={noExtraFeeCod} />
                                    <p
                                        className='mb-2'
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: '700',
                                            textAlign: 'center',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        I don’t charge extra fee on COD orders
                                    </p>
                                    {/* <p
                                        className='mb-2'
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        Select this option if your COD orders are free of additional charges
                                    </p> */}
                                    <div style={{ height: '6.2rem' }}></div>
                                </div>
                            </button>
                            <button
                                style={{
                                    border: 'none',
                                    background: 'none',
                                    outline: 'none',
                                    padding: '0',
                                    margin: '0',
                                    cursor: 'pointer',
                                    display: 'flex',
                                }}
                                onClick={() => setNoExtraCharges(false)}
                            >
                                <div
                                    style={{
                                        border: noExtraCharges
                                            ? '1px solid rgba(0, 0, 0, 0.15)'
                                            : '2px solid rgba(0, 75, 141, 1)',
                                        borderRadius: '8px',
                                        padding: '0.5rem',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        height: '19rem',
                                        width: '21rem',
                                        margin: '1rem 0.5rem',
                                    }}
                                >
                                    <img className='mb-4' src={extraFreeCod} />
                                    <p
                                        className='mb-2'
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: '700',
                                            textAlign: 'center',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        I charge extra fee on COD orders
                                    </p>
                                    {/* <p
                                        className='mb-2'
                                        style={{
                                            fontSize: '12px',
                                            fontWeight: '400',
                                            textAlign: 'center',
                                            lineHeight: '20px',
                                        }}
                                    >
                                        Select this option if you charge extra fee on your COD orders
                                    </p> */}
                                    <div className='p-2 flex flex-col gap-3'>
                                        <div className='flex items-start gap-1'>
                                            <img src={step1} alt='Step 1' />
                                            <span style={{ lineHeight: '22px' }} className='block text-left'>
                                                Create the same shipping methods as on Shopify here.
                                            </span>
                                        </div>
                                        <div className='flex items-start gap-1'>
                                            <img src={step2} alt='Step 1' />
                                            <span style={{ lineHeight: '22px' }} className='block text-left'>
                                                Map them to COD or Prepaid.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <Divider className='m-0' />

                        <div className={`flex flex-row-reverse justify-between items-center p-3`}>
                            {noExtraCharges ? (
                                <Button
                                    onClick={handleShippingSetupDone}
                                    variant='primary'
                                    className='flex items-center'
                                    size='large'
                                    disabled={expandedStep !== 2} 
                                >
                                    Continue with No Extra Charges on COD
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleExtraCharges}
                                    variant='primary'
                                    className='flex items-center'
                                    size='large'
                                    disabled={expandedStep !== 2} 
                                >
                                    Set COD Fee
                                </Button>
                            )}
                        </div>
                    </div>
                ) : (
                    ''
                )}
                {showModal && (
                    <Modal
                        title={
                            showShippingForm && (
                                <div className='flex items-center justify-between'>
                                    <span>{modalTitle}</span>
                                    {onBoardingStates?.addCodShippingRates && (
                                        <Button
                                            onClick={() => setPlayTutorial((prev) => !prev)}
                                            type='link'
                                            variant='default'
                                        >
                                            <PlayCircleFilled className='text-black' />
                                            <span className='text-black'>
                                                {playTutorial ? 'Close' : 'Play'} Tutorial
                                            </span>
                                        </Button>
                                    )}
                                </div>
                            )
                        }
                        footer={null}
                        onCancel={() => handleCloseModal()}
                        open={showModal}
                        centered
                        width={'600px'}
                        closable={!onBoardingStates?.addCodShippingRates}
                        maskClosable={false}
                    >
                        {playTutorial && (
                            <iframe
                                width='100%'
                                height='300px'
                                src='https://www.youtube.com/embed/BxJ1F4Obwtc?autoplay=1&mute=1'
                                allow='autoplay; encrypted-media'
                                className='rounded-xl'
                                allowFullScreen
                                title='video'
                            ></iframe>
                        )}
                        {!showShippingForm && listItems?.length > 0 && (
                            <AddedShipping
                                onDelete={handleActionButtonClick}
                                onEdit={handleActionButtonClick}
                                showNewShipping={showNewShipping}
                                handleNewShipping={handleNewShipping}
                                handleShippingSetupDone={handleShippingSetupDone}
                                listItems={listItems}
                            />
                        )}
                        {(action.name === 'edit' || action.name === 'add') && (
                            <AddShippingRateForm
                                handleCloseModal={handleCloseModal}
                                addShippingMethod={addShippingMethod}
                                editShippingMethod={editShippingMethod}
                                action={action}
                                configJSON={configJSON}
                                isOnBoardingFlow={true}
                            />
                        )}
                    </Modal>
                )}
            </div>
        </div>
    )
}

export default CodOnboardingStep2
