import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { ArrowDownOutlined, ArrowUpOutlined, Card, EllipsisOutlined, ReactApexChart, Skeleton } from 'gokwik-ui-kit'
import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'

const RTOAnalytics = ({ timeFrame }) => {
    const [data, setData] = useState({
        awb_fillRate: 99.56,
        awb_tillDate: '2025-02-10',
        metric1: {
            end_date: '2025-01-27',
            rtoPercentageRange: '11.55',
            start_date: '2025-01-20',
            total_orders: 7000,
            total_rto_orders: 771,
        },
        metric2: {
            end_date: '2025-02-03',
            rtoPercentageRange: '10.40',
            start_date: '2025-01-27',
            total_orders: 5692,
            total_rto_orders: 569,
        },
        metric3: {
            end_date: '2025-02-10',
            rtoPercentageRange: '11.93',
            start_date: '2025-02-03',
            total_orders: 8078,
            total_rto_orders: 932,
        },
    })

    const getYAxisScale = (minPercentage, maxPercentage) => {
        let min = minPercentage
        let max = maxPercentage

        if (min - 10 <= 0) {
            min = 0
        } else {
            min = min - 10
        }

        if (max + 10 >= 100) {
            max = 100
        } else {
            max = max + 10
        }
        return { min, max }
    }
    const formatValue = (value) => {
        if (value < 1000) return value.toFixed(2)
        if (value < 100000) return `${(value / 1000).toFixed(0)}K`
        if (value < 10000000) return `${(value / 100000).toFixed(1)}L`
        return `${(value / 10000000).toFixed(1)}Cr`
    }

    const getMaxAxis = () => {
        const maxValue = Math.max(
            data.metric1.total_orders,
            data.metric2.total_orders,
            data.metric3.total_orders,
            data.metric1.total_rto_orders,
            data.metric2.total_rto_orders,
            data.metric3.total_rto_orders,
        )
        const magnitude = Math.pow(10, Math.floor(Math.log10(maxValue)))
        return Math.ceil(maxValue / magnitude) * magnitude
    }
    const chartConfigs = {
        series: [
            {
                name: 'Total Orders',
                type: 'bar',
                data: [data.metric1?.total_orders, data.metric2?.total_orders, data.metric3?.total_orders],
            },
            {
                name: 'RTO Orders',
                type: 'bar',
                data: [data.metric1?.total_rto_orders, data.metric2?.total_rto_orders, data.metric3?.total_rto_orders],
            },
            {
                name: 'RTO %',
                type: 'line',
                data: [
                    parseFloat(data.metric1?.rtoPercentageRange),
                    parseFloat(data.metric2?.rtoPercentageRange),
                    parseFloat(data.metric3?.rtoPercentageRange),
                ],
            },
        ],
        options: {
            chart: {
                type: 'bar',
                stacked: true, // Enable stacking for bar charts
                height: 350,
                toolbar: { show: false },
                zoom: {
                    enabled: false,
                },
            },
            stroke: {
                width: [1, 0.3, 1], // Ensure the line has a visible stroke width
                curve: 'smooth', // Make the line smooth
            },

            xaxis: {
                categories: [
                    `${dayjs(data.metric1.start_date).format("D MMM'YY")} - ${dayjs(data.metric1.end_date).format(
                        "D MMM'YY",
                    )}`,
                    `${dayjs(data.metric2.start_date).format("D MMM'YY")} - ${dayjs(data.metric2.end_date).format(
                        "D MMM'YY",
                    )}`,
                    `${dayjs(data.metric3.start_date).format("D MMM'YY")} - ${dayjs(data.metric3.end_date).format(
                        "D MMM'YY",
                    )}`,
                ],
            },
            yaxis: [
                {
                    show: true,
                    title: {
                        text: '',
                    },
                    min: 0,
                    max: getMaxAxis(),
                    ...(getMaxAxis() <= 100 ? { tickAmount: 6 } : {}),
                    labels: {
                        formatter: (val) => formatValue(val),
                    },
                },
                {
                    show: false,
                    title: {
                        text: '',
                    },
                    min: 0,
                    max: getMaxAxis(),

                    ...(getMaxAxis() <= 100 ? { tickAmount: 6 } : {}),
                    labels: {
                        formatter: (val) => formatValue(val),
                    },
                },

                // {
                //     show: false,
                //     title: {
                //         text: '',
                //     },
                //     labels: {
                //         formatter: (val) => {
                //             if (val < 999) return val
                //             if (val >= 1e9) return `${(val / 1e9).toFixed(1)}B`
                //             if (val >= 1e6) return `${(val / 1e6).toFixed(1)}M`
                //             if (val >= 1e3) return `${(val / 1e3).toFixed(1)}K`
                //             return val.toString()
                //         },
                //     },
                //     tickAmount: 6, // Adjust this to control the number of ticks
                //     min: 0,
                //     max: (() => {
                //         const maxValue = Math.max(
                //             data.metric1.total_orders,
                //             data.metric2.total_orders,
                //             data.metric3.total_orders,
                //             data.metric1.total_rto_orders,
                //             data.metric2.total_rto_orders,
                //             data.metric3.total_rto_orders,
                //         )
                //         const magnitude = Math.pow(10, Math.floor(Math.log10(maxValue)))
                //         return Math.ceil(maxValue / magnitude) * magnitude
                //     })(), // Calculate max and round up to the nearest significant figure
                // },

                {
                    show: false,
                    opposite: true,
                    title: {
                        text: 'RTO %',
                    },
                    ...getYAxisScale(
                        Math.min(
                            parseFloat(data.metric1.rtoPercentageRange),
                            parseFloat(data.metric2.rtoPercentageRange),
                            parseFloat(data.metric3.rtoPercentageRange),
                        ),
                        Math.max(
                            parseFloat(data.metric1.rtoPercentageRange),
                            parseFloat(data.metric2.rtoPercentageRange),
                            parseFloat(data.metric3.rtoPercentageRange),
                        ),
                    ),
                    labels: {
                        formatter: (val) => `${val.toFixed(2)}%`,
                    },
                },
            ],
            tooltip: {
                shared: true,
                intersect: false,
            },
            legend: {
                position: 'bottom',
            },
            colors: ['#2DA8FF', '#FF4560', '#0A2D59'], // Colors for Total Orders, RTO Orders, RTO %
        },
    }
    const [loading, setLoading] = useState(false)

    const getRTOData = async () => {
        try {
            setLoading(true)
            const response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints?.executiveSummary?.RTOAnalytics,
                params: { type: timeFrame },
                skipLoader: true,
            })

            setLoading(false)
            if (response?.status === 200) {
                setData(response?.data?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getRTOData()
    }, [timeFrame])

    function percentageChange(a, b) {
        if (a === 0.0 || b === 0.0) {
            return 0.0
        }
        return Math.round(((a - b) / b) * 100.0 * 100) / 100
    }

    const getTopData = useMemo(() => {
        return {
            lastRTOPc: data?.metric3?.rtoPercentageRange,
            percentChange: percentageChange(
                Number(data?.metric3?.rtoPercentageRange),
                Number(data?.metric2?.rtoPercentageRange),
            ),
        }
    }, [data])

    return (
        <div>
            <Card title={<>RTO Performance</>} extra={<EllipsisOutlined className='rotate-90' />}>
                {loading ? (
                    <Skeleton loading={loading} active avatar />
                ) : (
                    <div>
                        <div>
                            <span className='text-2xl font-semibold'>
                                {Number(getTopData?.lastRTOPc) === 0
                                    ? Number(getTopData?.lastRTOPc).toFixed(0)
                                    : getTopData?.lastRTOPc}
                                %
                            </span>
                            {'   '}
                            {getTopData?.percentChange > 0 ? (
                                <span className='text-red-600 text-base font-semibold'>
                                    {getTopData?.percentChange.toFixed(2)}% <ArrowUpOutlined />
                                </span>
                            ) : getTopData?.percentChange < 0 ? (
                                <span className='text-green-600 text-base font-semibold'>
                                    {getTopData?.percentChange}% <ArrowDownOutlined />
                                </span>
                            ) : (
                                <>{getTopData?.percentChange.toFixed(0)}%</>
                            )}
                        </div>
                        <div>
                            <div id='chart'>
                                <ReactApexChart
                                    //@ts-ignore
                                    options={chartConfigs.options}
                                    series={chartConfigs.series}
                                    type='line'
                                    height={350}
                                />
                            </div>
                            <div id='html-dist'></div>
                        </div>

                        <div className='text-[#98A2B3] text-xs float-end'>Last 3 {timeFrame}s</div>
                        <div className='text-[#98A2B3] text-xs'>
                            AWB fill rate is {data?.awb_fillRate}%, till {data?.awb_tillDate}, and RTO data is prior to
                            this date.
                        </div>
                    </div>
                )}
            </Card>
        </div>
    )
}
export default RTOAnalytics
