import {
    ApiOutlined,
    Button,
    Col,
    Divider,
    GoogleOutlined,
    message,
    Modal,
    NodeIndexOutlined,
    Row,
    Select,
    SyncOutlined,
    Tag,
    Tooltip,
    UsbOutlined,
} from 'gokwik-ui-kit'
import { useEffect, useRef, useState } from 'react'
import logo from '@library/images/common/GoKwik_Symbol.svg'
import GA4Logo from '@library/images/common/GoogleAnalyticsIcons.svg'
import { loaderEvent, makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { handleError } from '@library/utilities/helpers/handleError'
import { useLocation, useNavigate } from 'react-router-dom'
import { Props } from 'gokwik-ui-kit/dist/esm/components/Button/Button'
const { Option } = Select

interface TokenData {
    access_token?: string
    token_type?: string
    expires_in?: string
    scope?: string
    [key: string]: string | undefined // Allow any other string keys with string values
}

type AccountData = {
    [key: string]: any
}

const credentials = process.env.GOOLE_OAUTH_CREDS

export default function GA4DataFetch() {
    const location = useLocation()
    const [tokenData, setTokenData] = useState<TokenData | null>(null)
    const [finalData, setFinalData] = useState({
        accountSummaries: [],
    })
    const [accountData, setAccountData] = useState<AccountData>({
        account_name: '',
        account_email: '',
    })
    const [modalOpen, setModalOpen] = useState(false)
    const [isAccountConnected, setIsAccountConnected] = useState(false)
    const [userEmail, setUserEmail] = useState('')
    const [accountSelected, setAccountSelected] = useState('')
    const navigate = useNavigate()
    const setValues = async (data) => {
        setFinalData({ ...data, ...tokenData })
        setModalOpen(true)
        loaderEvent({ count: -1 })
        // setTokenData(data)
    }

    const getAllAccountsRelatedToGA = async (tokenData) => {
        const accessToken = tokenData?.access_token
        const apiUrl = 'https://analyticsadmin.googleapis.com/v1beta/accountSummaries'

        try {
            const response = await fetch(apiUrl, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })

            if (response?.status === 200) {
                const data = await response.json()
                await setValues(data)
            } else {
                message.warning('Error in fetching account data')
                return
            }
        } catch (error) {
            console.error('Error fetching account summaries:', error)
        }
    }
    const getUserEmail = async (tokenData) => {
        const accessToken = tokenData?.access_token
        const apiUrl = 'https://www.googleapis.com/oauth2/v3/userinfo'
        try {
            const response = await fetch(apiUrl, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })

            if (response?.status === 200) {
                const data = await response.json()
                setUserEmail(data?.email)
                // await setValues(data)
            } else {
                message.warning('Error in fetching user email')
                return
            }
        } catch (error) {
            console.error('Error fetching account summaries:', error)
        }
    }

    const removeCode = () => {
        const params = new URLSearchParams(window.location.search)
        const code = params.get('code')

        // we are using setTimeout here because - 'globalStoreChanged' in routes.tsx file forces useEffect to render twice
        setTimeout(() => {
            navigate(`/data-integration`, {
                state: {
                    code: '',
                },
            })
        }, 0)
    }

    useEffect(() => {
        if (location?.state?.code) {
            exchangeCodeForTokens(location.state.code)
            removeCode()
        }
        getAccount()
    }, [])

    const handleAuthorize = () => {
        const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth'
        const clientId = credentials
        //oauth-redirect
        const redirectUri = `${window.location.origin}/oauth-redirect`
        const scope =
            'https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/userinfo.email'
        const state = 'random_state_string' // Generate a random string to prevent CSRF attacks
        const authUrl = `${oauth2Endpoint}?client_id=${encodeURIComponent(clientId)}&redirect_uri=${encodeURIComponent(
            redirectUri,
        )}&access_type=offline&response_type=code&scope=${encodeURIComponent(
            scope,
        )}&include_granted_scopes=true&state=${encodeURIComponent(state)}&prompt=consent`
        window.location.href = authUrl
    }

    const exchangeCodeForTokens = async (code: string) => {
        // const clientId = credentials?.client_id
        // const clientSecret = credentials?.client_secret
        // const redirectUri = `${window.location.origin}/oauth-redirect`
        // const tokenEndpoint = 'https://oauth2.googleapis.com/token'

        // try {
        //     let response = await makeAPICall({
        //         method: 'post',
        //         // url:'https://www.googleapis.com/oauth2/v3/token',
        //         url: tokenEndpoint,
        //         skipMode: true,
        //         params: {
        //             client_id: clientId,
        //             client_secret: clientSecret,
        //             redirect_uri: redirectUri,
        //             code: code,
        //             grant_type: 'authorization_code',
        //         },
        //     })

        //     if (response?.status === 200) {
        //         const data = response?.data
        //         loaderEvent({ count: 1 })
        //         getAllAccountsRelatedToGA(data)
        //         getUserEmail(data)
        //         setTokenData(data)
        //     } else {
        //         // message.error('Failed to retrieve token data')
        //     }
        // } catch (error) {
        //     console.error('Error exchanging code for tokens:', error)
        // }

        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getAccoutData,
                params: { code },
                skipMode: true,
            })

            if (response?.status === 200) {
                const result = response?.data?.data

                getUserEmail(result?.access_auth)
                setTokenData(result?.access_auth)
                await setValues({ accountSummaries: result?.ga_summaries?.accountSummaries })
            } else {
                message.error('Failed to retrieve token: : API error')
            }
        } catch (error) {
            message.error('Failed to retrieve token')
        }
    }

    const updateAccount = async () => {
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.addAccount,
                payload: {
                    accountSummaries: { ...accountData },
                    ...tokenData,
                    account_email: userEmail,
                },
            })

            if (response?.data?.status_code === 200) {
                message.success('Account Updated')
                if (response?.data?.is_connected === true) {
                    setIsAccountConnected(true)
                }
            } else {
                message.error(`Error connecting account: ${accountData?.account_name}`)
            }
            setModalOpen(false)
            getAccount()
        } catch (error) {
            handleError(error)
            message.error('Error adding account data.')
        }
    }

    const getAccount = async () => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getAccount,
            })
            if (response?.data?.status_code === 200 && response?.data?.data?.is_connected) {
                setIsAccountConnected(true)
                setAccountData({ ...response?.data?.data })
            }
        } catch (error) {
            handleError(error)
            setIsAccountConnected(false)
            message.error('Error adding account data.')
        }
    }
    const handleAccountChange = (account: string) => {
        const selectedAccount = finalData?.accountSummaries?.find((acc) => acc?.account === account)
        setAccountData(selectedAccount || {})
        setAccountSelected(account)
    }

    console.log(accountSelected?.length, 'as')

    return (
        <>
            <Row className={`bg-white p-5 rounded-lg shadow-md h-1/4 w-1/3`}>
                <Col span={24}>
                    <Row justify={'space-between'}>
                        <Col>
                            <div className='flex items-start flex-col gap-1'>
                                <img
                                    className='w-10 rounded-lg border border-solid border-gray-300 p-2'
                                    src={GA4Logo}
                                    alt='Google Analytics Logo'
                                />
                                <span className='font-semibold text-base'>Google Analytics</span>
                            </div>
                        </Col>
                        <Col>
                            <Button type={'default'} onClick={handleAuthorize}>
                                {!isAccountConnected ? (
                                    <span>
                                        <NodeIndexOutlined className='text-base' />
                                        &nbsp;Connect
                                    </span>
                                ) : (
                                    <span>
                                        <SyncOutlined className='text-base' />
                                        &nbsp;Switch Account
                                    </span>
                                )}
                            </Button>
                        </Col>
                    </Row>
                    <div className='my-1'></div>
                    <Row>
                        <Col span={22}>
                            <span className='text-xs font-light text-balance'>
                                Enable deeper insights and better results with GoKwik by integrating your Google
                                Analytics Account
                            </span>
                        </Col>
                    </Row>

                    {accountData?.account_email && accountData?.account_name ? (
                        <Row className='mt-2'>
                            <Col span={24}>
                                <Tag variant={'success'} className={`text-xs w-full`}>
                                    <div className='flex items-center'>
                                        {/* <div className='bg-green-400 rounded-full w-2.5 h-2.5' /> */}
                                        <span className='flex items-center w-full'>
                                            Connected&nbsp;
                                            <Tooltip title={accountData?.account_name}>
                                                <span className='font-semibold max-w-[50%] truncate overflow-hidden whitespace-nowrap'>
                                                    {accountData?.account_name}&nbsp;
                                                </span>
                                            </Tooltip>
                                            for {accountData?.account_email}
                                        </span>
                                    </div>
                                </Tag>
                            </Col>
                        </Row>
                    ) : (
                        <Row className='mt-2'>
                            <Col span={24}>
                                <Tag variant={'error'} className={`text-xs w-full`}>
                                    <div className='flex gap-1 items-center'>
                                        <div className='bg-red-400 rounded-full w-2.5 h-2.5' />{' '}
                                        <span>Not Connected</span>
                                    </div>
                                </Tag>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>

            <Modal
                okButtonProps={{ disabled: !!accountSelected?.length ? false : true }}
                cancelText={'Cancel'}
                okText={'Submit'}
                centered
                width={'400px'}
                open={modalOpen}
                onCancel={() => {
                    getAccount()
                    setModalOpen(false)
                }}
                onOk={() => {
                    if (accountSelected?.length) {
                        updateAccount()
                    } else {
                        message.error('Please select an account')
                    }
                }}
                {...(!finalData?.accountSummaries?.length && { footer: <></> })}
            >
                <Row gutter={[16, 16]} className='mt-5'>
                    {!!finalData?.accountSummaries?.length ? (
                        <Col span={24}>
                            <p className='font-semibold '>Choose an account to proceed with?</p>
                            <Select
                                className='w-full mb-2'
                                placeholder='Select Account'
                                onChange={handleAccountChange}
                                defaultValue={''}
                                showSearch
                                optionFilterProp='label'
                            >
                                {finalData?.accountSummaries?.map((account) => (
                                    <Option key={account.account} value={account.account}>
                                        {account.displayName}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    ) : (
                        <Col span={24}>
                            No accounts found on Google Analytics for email{' '}
                            <span className='font-semibold'>{accountData?.account_email || userEmail}</span>
                        </Col>
                    )}
                </Row>
            </Modal>
        </>
    )
}
