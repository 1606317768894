import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import {
    Button,
    Col,
    Drawer,
    Input,
    Row,
    Select,
    Space,
    TextArea,
    Tooltip,
    message,
    DatePicker,
    TimePicker,
    Modal,
} from 'gokwik-ui-kit'
import { ReactNode, useEffect, useState } from 'react'
import dayjs from 'dayjs'
// import TimePicker from 'react-time-picker';
interface Props {
    open: boolean
    title: string
    onClose: (text: string) => void
    recordData: any
    type: string
    isNew: boolean
}

interface NotificationsDetails {
    module: string | null
    message: string
    start_time: string | null
    end_time: string | null
    id: string | null
    is_enabled: boolean | null
}

const EditNotificationsDrawer = ({ open, title, onClose, type, recordData, isNew }: Props) => {
    const parseTime = (dateTimeStr) => {
        const date = dayjs(dateTimeStr)
        const hours = date.hour()
        const minutes = date.minute()
        const seconds = date.second()
        const newDate = new Date()
        newDate.setHours(hours)
        newDate.setMinutes(minutes)
        newDate.setSeconds(seconds)
        return dayjs(newDate)
    }

    const parseDate = (dateStr) => {
        return dayjs(dateStr)
    }

    const convertISTToGMT = (istTimeStr) => {
        const istDate = new Date(istTimeStr)
        const istTimeInMillis = istDate.getTime()
        const gmtTimeInMillis = istTimeInMillis - (5 * 60 * 60 * 1000 + 30 * 60 * 1000)
        const gmtDate = new Date(gmtTimeInMillis)
        return gmtDate.toISOString()
    }

    const [data, setData] = useState<NotificationsDetails>({
        module: recordData?.module || '',
        message: recordData?.message || '',
        start_time: recordData?.start_time || '',
        end_time: recordData?.end_time || '',
        id: recordData?.id || '',
        is_enabled: recordData?.is_enabled === true ? true : false,
    })
    const [proceed, setProceed] = useState(false)

    const conditionsCheck = async () => {
        if (!data?.message) {
            message.warning('Message is Required')
            return
        }
        if (!data?.start_time) {
            message.warning('Please add Start Date and Time')
            return
        }
        if (!data.end_time) {
            message.warning('Please add End date and Time')
            return
        }

        if (!data?.module) {
            message.warning('Reason is Required')
            return
        }
        let dateStr1 = data?.start_time
        let dateStr2 = data?.end_time

        let date1 = new Date(dateStr1)
        let date2 = new Date(dateStr2)
        if (!(date1 < date2)) {
            message.warning('Start Date should be less than End date')
            return
        }
        setProceed(true)
        return
    }
    const onEditSubmit = async () => {
        setProceed(false)

        let dateStr1 = data?.start_time
        let dateStr2 = data?.end_time

        let date1 = new Date(dateStr1)
        let date2 = new Date(dateStr2)

        const payload = {
            module: data?.module, // optional
            message: data?.message, // optional
            start_time: date1,
            end_time: date2,
            ...(!isNew && { id: data?.id, is_enabled: data?.is_enabled.toString() === 'true' ? true : false }),
        }

        const url = isNew
            ? `${process.env.REACT_APP_BASE_URL}${APIEndPoints.addScheduleNotifications}`
            : `${process.env.REACT_APP_BASE_URL}${APIEndPoints.updateScheduleNotifications}`
        const response = await makeAPICall({
            method: 'post',
            url: url,
            payload: payload,
        })
        if (response.success) {
            message.success('Successfully Added')
            onClose('Added')
        } else {
            message.warning(
                response?.data?.message ||
                    response?.data?.data?.message ||
                    response?.response?.data?.data?.message ||
                    response?.response?.data?.message,
            )
        }
    }

    return (
        <>
            <Modal
                title={<p> Are your sure, you want to {isNew ? 'add' : 'update'} this notification ?</p>}
                open={proceed ? true : false}
                onCancel={() => {
                    setProceed(false)
                }}
                centered
                width={'500px'}
                footer={() => (
                    <Row className='flex justify-end gap-2'>
                        <Button onClick={() => setProceed(null)} variant='default'>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                onEditSubmit()
                            }}
                            variant='primary'
                        >
                            Confirm
                        </Button>
                    </Row>
                )}
            ></Modal>
            <Drawer title={title} className='pt-0' placement='right' onClose={() => onClose('Cancelled')} open={open}>
                <Col className='w-full'>
                    <Space direction='vertical' className='w-full'>
                        <p className='mt-2'>Message </p>
                        <Input
                            // type='text'
                            value={data?.message}
                            placeholder='Message'
                            onChange={(e) => {
                                setData((prev) => ({ ...prev, message: e.target.value }))
                            }}
                        />
                        <Row className='gap-10'>
                            <Col span={24}>
                                <p className='mt-2 margin-b10 margin-t10'>Start DateTime </p>
                                <DatePicker
                                    placeholder='Select start date & time'
                                    className='w-full'
                                    size='middle'
                                    // use12Hours
                                    value={data?.start_time ? parseDate(data.start_time) : null}
                                    onChange={(value) => {
                                        setData((prev) => ({ ...prev, start_time: value ? value.toString() : '' }))
                                    }}
                                    format={'YYYY-MM-DD : hh:mm A'}
                                    allowClear
                                    showTime
                                    showNow={false}
                                />
                            </Col>
                        </Row>
                        <Row className='gap-10'>
                            <Col span={24}>
                                <p className='mt-2 margin-b10 margin-t10'>End DateTime </p>
                                <DatePicker
                                    placeholder='Select end date & time'
                                    className='w-full'
                                    size='middle'
                                    value={data?.end_time ? parseDate(data.end_time) : null}
                                    onChange={(value) => {
                                        setData((prev) => ({ ...prev, end_time: value ? value.toString() : '' }))
                                    }}
                                    // use12Hours
                                    format={'YYYY-MM-DD : hh:mm A'}
                                    allowClear
                                    showTime
                                    showNow={false}
                                />
                            </Col>
                        </Row>
                        <p className='mt-2'>Reason</p>
                        <TextArea
                            // type='text'
                            value={data?.module}
                            placeholder='Reason for Notification'
                            onChange={(e) => {
                                setData((prev) => ({ ...prev, module: e.target.value }))
                            }}
                        />
                        {!isNew && (
                            <Select
                                className='w-full'
                                label={'Notification Status'}
                                placeholder={'Select'}
                                value={data?.is_enabled.toString()}
                                onChange={(e) => {
                                    setData((prev) => ({ ...prev, is_enabled: e }))
                                }}
                                options={[
                                    { value: 'true', label: 'Activate' },
                                    { value: 'false', label: 'Deactivate' },
                                ]}
                            />
                        )}
                    </Space>
                </Col>
                <Row className='mt-4'>
                    <Col span={12}>
                        <Button onClick={() => conditionsCheck()} variant='primary' className='w-[99%]'>
                            Submit
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button onClick={() => onClose('Cancelled')} variant='default' className='w-[99%] ml-1'>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Drawer>
        </>
    )
}

export default EditNotificationsDrawer
