import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Modal, Button, TextArea, Input } from 'gokwik-ui-kit'
import errorAlert from '@library/images/icons/errorAlert.svg'
import lightExternalLink from '@library/images/icons/light-external-link.svg'
import colabratorCodeVideo from '@library/video/Collaborator-Code.mp4'
import colabratorCode from '@library/images/icons/colabratorCode.png'
import { createJiraTicketForbuttonNotWorking, sendMerchantOnboardingDetails } from '../CommonComponents/Utils'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import TutorialVideoModal from '../CommonComponents/stepTutorial'

const CollaboratorCodePopup = ({ merchant_store_name, popupref }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const handleCloseModal = () => {
        setIsModalOpen(false)
    }
    return (
        <div
            ref={popupref}
            className='absolute bg-black rounded-lg text-left p-3 text-white leading-[21px] z-50 top-[42%] left-1/2 w-[342px] transform -translate-x-[8%]'
        >
            <h2 className='text-white font-bold text-lg'>How to find collaborator code?</h2>
            <div className='absolute bottom-full right-[45%] translate-x-[-50%] border-[8px] border-solid border-transparent border-b-black' />
            <div className='mt-2 text-white text-sm'>
                <p>
                    Step 1 - Open{' '}
                    <a
                        href={`https://admin.shopify.com/store/${merchant_store_name}/settings/account`}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='underline text-[#0066cc] cursor-pointer'
                    >
                        Users & Permissions
                    </a>{' '}
                    in Shopify.
                </p>
                <p>
                    Step 2 - Go to the <strong>Collaborators section.</strong>
                </p>
                <p>
                    Step 3 - Copy your <strong>Collaborator Request Code.</strong>
                </p>
                <p>
                    Step 4 - Return here and <strong>paste the code.</strong>
                </p>
            </div>
            <div className='mt-2 bg-gray-800 p-3 rounded-lg relative'>
                <img src={colabratorCode} alt='Watch Video' className='w-full rounded-lg' />
                <button
                    className='bg-white text-black absolute bottom-[8%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-4 py-2 rounded-md flex items-center shadow-lg'
                    onClick={() => setIsModalOpen(true)}
                >
                    ▶ Watch Video
                </button>
            </div>
            <TutorialVideoModal
                isModalOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
                videoUrl={colabratorCodeVideo}
            />
            <div className='mt-2 bg-gray-900 text-gray-300 p-2 rounded-lg italic text-sm'>
                <p>
                    <strong>Why we need this code?</strong> : We use this code to request store access and manually add
                    buttons for you.
                </p>
            </div>
        </div>
    )
}

const ContactSupportModal = ({ showContactSupportModal, onClose, setShowContactSupportModal }) => {
    const [issueDescription, setIssueDescription] = useState('')
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [collaboratorCode, setCollaboratorCode] = useState('')
    const popupRef = useRef(null)

    const handleSubmit = async () => {
        logEvent(
            'contact_support_clicked',
            'click',
            'checkout-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { Step: 'checkout 3' },
        )
        const payload = {
            error_description: issueDescription,
            collaborator_code: collaboratorCode,
            event_name: 'KWIK_CHECKOUT_MANUAL_BUTTON_IMPLEMENTATION_REQUEST',
        }
        try {
            await Promise.all([
                sendMerchantOnboardingDetails(payload, '', 'Failed to submit issue description', {
                    setIssueDescription,
                    onClose,
                }),
                createJiraTicketForbuttonNotWorking(payload, merchantDetails?.m_id),
            ])
        } catch (error) {
            console.error('Error requesting payment methods activation:', error)
        }
    }

    const handleClickOutside = useCallback(
        (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsPopupOpen(false)
            }
        },
        [setIsPopupOpen],
    )

    useEffect(() => {
        if (isPopupOpen) {
            document.addEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isPopupOpen, handleClickOutside])

    return (
        <Modal
            open={showContactSupportModal}
            onCancel={() => setShowContactSupportModal(false)}
            footer={null}
            centered
            width={800}
            className='rounded-lg p-0 [&_.ant-modal-body]:bg-[#FFF1F0] [&_.ant-modal-body]:text-center'
        >
            <div className='flex flex-col justify-center items-center h-[60vh] rounded-lg'>
                <div className='mb-2'>
                    <img src={errorAlert} alt='error' />
                </div>

                <div className='text-2xl font-bold leading-8 text-center'>
                    <div>Sorry! it didn’t work.</div>
                    <div>Do not worry, we’ll help you out.</div>
                </div>

                <p className='text-base leading-6 text-[#555] mt-2 mb-4 text-center'>
                    Contact our support team and we’ll assist you the best way possible.
                </p>

                <div className='flex flex-col w-[69%] mb-3'>
                    <div className='flex justify-between items-center mb-1'>
                        <label className='text-sm font-normal text-[#555]'>
                            <span className='text-red-500 mr-1'>*</span>Collaborator code
                        </label>
                        <div
                            ref={popupRef}
                            className='text-[#0066cc] text-sm underline cursor-pointer'
                            onMouseEnter={() => setIsPopupOpen(true)}
                        >
                            Find Shopify collaborator code
                        </div>
                        {isPopupOpen && (
                            <CollaboratorCodePopup
                                merchant_store_name={merchantDetails?.website?.replace('.myshopify.com', '') || ''}
                                popupref={popupRef}
                            />
                        )}
                    </div>

                    <Input
                        type='text'
                        value={collaboratorCode}
                        onChange={(e) => setCollaboratorCode(e.target.value)}
                        className='mt-1'
                        placeholder='Collaborator Code'
                        size='large'
                    />
                </div>

                <TextArea
                    placeholder='Describe your issue. (Optional)'
                    value={issueDescription}
                    onChange={(e) => setIssueDescription(e.target.value)}
                    className='mb-6 text-base'
                    rows={6}
                    cols={55}
                />

                <Button
                    type='primary'
                    size='large'
                    onClick={handleSubmit}
                    disabled={!collaboratorCode}
                    className='flex items-center justify-center'
                >
                    Submit Report
                    <img src={lightExternalLink} alt='external link' className='ml-2 mb-[2px]' />
                </Button>
            </div>
        </Modal>
    )
}

export default ContactSupportModal
