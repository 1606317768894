import { Alert, Button, Col, ExportOutlined, Row, Tooltip } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import '../congratulations.css'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useSelector } from 'react-redux'
import { getMerchantDetails } from '@store/user/selectors'
import { DisountSVG, RTOSVG } from '@library/images/common/onboarding-images/congratulationsPageIcon'
import { useAppDispatch } from '@library/utilities/hooks'
import { fetchUserDetails } from '@store/user'
import { useNavigate } from 'react-router-dom'
const today = dayjs()
export default function FeatureSpotlight() {
    const [isRTOActivated, setIsRTOActivated] = useState(false)
    const [showOverride, setShowOverride] = useState(true)
    const merchant_details = useSelector(getMerchantDetails)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const renderTooltipContent = (text) => <div style={{ maxWidth: '200px' }}>{text}</div>
    const featureSpotData = [
        {
            title: 'Exclusive Discounts',
            content:
                'Boost profits and customer retention with unique, cost-effective GoKwik discounts, enhancing conversions by X% at no extra cost compared to Shopify-specific discounts.',
            sticker: <DisountSVG />,
            buttonText: 'Setup',
        },

        {
            title: 'RTO - Return to Origin',
            content:
                'Lower RTO rates by up to X% with our RTO Module, improving COD health. Safeguard against high-risk users and enable COD for low-risk ones using intelligent interventions—Risk-based COD Blocking, Confirmation Prompt, and Captcha—backed by our vast 100Mn+ shopper data.',
            sticker: <RTOSVG />,
            buttonText: 'Activate',
        },
    ]

    useEffect(() => {
        if (merchant_details?.rto_enabled) {
            setIsRTOActivated(true)

            if (getDaysLeft() <= 0) {
                setShowOverride(false)
            }
        }
    }, [merchant_details?.rto_enabled])

    const activateOrDeactivateRTO = async (action) => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.activateOrDeactivateRTO,
                payload: {
                    action,
                },
            })
            dispatch(fetchUserDetails())
            if (response?.data?.status_code === 200) {
                if (action === 'activate') {
                    setIsRTOActivated(true)
                } else if (action === 'override') {
                    setShowOverride(false)
                    setIsRTOActivated(true)
                } else {
                    setIsRTOActivated(false)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleClick = (title, action) => {
        if (title === 'RTO - Return to Origin') {
            if (action) {
                activateOrDeactivateRTO(action)
            }
        }
    }

    const getDaysLeft = () => {
        const rto_go_live_date = dayjs(merchant_details?.rto_go_live_date)
        const daysLeft = rto_go_live_date.diff(today, 'day')
        return daysLeft
    }

    const getDaysLeftMessage = () => {
        if (getDaysLeft() <= 0) {
            return 'RTO Activated'
        } else {
            return `RTO Activated, Please Wait ${getDaysLeft()} Days`
        }
    }

    console.log()

    return (
        <>
            <div className='flex justify-around pt-8 pb-16 px-12 gap-6'>
                {featureSpotData?.map((item, i) => {
                    return (
                        <div
                            key={i}
                            className='w-1/2 h-auto bg-white border shadow-[0px_4px_4px_0px_#F3F5F6] rounded-lg border-solid border-[#EFF8FF] p-5'
                        >
                            <Row justify={'space-around'}>
                                <Col span={5}>{item.sticker}</Col>
                                <Col span={18}>
                                    <div className='flex flex-col gap-2'>
                                        <span className='text-[#000000E0] text-sm font-semibold'>{item.title}</span>
                                        <Tooltip title={renderTooltipContent(item.content)}>
                                            <span className='line-clamp-3 text-[#000000BF] text-sm font-normal'>
                                                {item.content}
                                            </span>
                                        </Tooltip>

                                        {i === 1 ? (
                                            <>
                                                {isRTOActivated || merchant_details?.rto_enabled ? (
                                                    <>
                                                        <Alert
                                                            className='w-full my-3'
                                                            banner
                                                            type='success'
                                                            showIcon
                                                            message={
                                                                <span className=' text-[#084426] text-sm font-semibold'>
                                                                    {getDaysLeftMessage()}
                                                                </span>
                                                            }
                                                        />
                                                        <span className='flex justify-between gap-5'>
                                                            <Button
                                                                className='w-full'
                                                                variant='default'
                                                                onClick={() => handleClick(item?.title, 'deactivate')}
                                                            >
                                                                Deactivate
                                                            </Button>

                                                            {showOverride && (
                                                                <Button
                                                                    className='w-full'
                                                                    variant='default'
                                                                    onClick={() => handleClick(item?.title, 'override')}
                                                                >
                                                                    Override & Activate Now
                                                                </Button>
                                                            )}
                                                        </span>
                                                        <div className=''>
                                                            <p className='text-xs text-gray-400 my-4'>
                                                                Please provide your shipping credentials using the form
                                                                below. Providing requested credentials will unlock
                                                                granular data analysis and RTO prediction model
                                                                enhancements, leading to a potential 10-20% RTO
                                                                reduction for your business
                                                            </p>
                                                            <Button
                                                                href='https://forms.gle/F5SjwhXffnGWuJ5t9'
                                                                target='_blank'
                                                                variant='primary'
                                                                className=''
                                                            >
                                                                Form Link <ExportOutlined />
                                                            </Button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {' '}
                                                        <Alert
                                                            className='w-full'
                                                            banner
                                                            type='info'
                                                            showIcon
                                                            message={
                                                                <span className=' text-[#002C8C] text-sm font-semibold'>
                                                                    Note
                                                                </span>
                                                            }
                                                            description={
                                                                <span className='text-[#002C8C] text-[13px] font-normal'>
                                                                    On clicking Activate, RTO Interventions will be
                                                                    activated only ~15 days post usage. Our model takes
                                                                    15-30 days to learn, which will help in better
                                                                    reducing RTO for you. *There may also be a
                                                                    possibility of a Checkout Conversion drop in order
                                                                    to bring the RTO rates to optimal levels.
                                                                </span>
                                                            }
                                                        />
                                                        <span className='flex justify-center'>
                                                            <Button
                                                                className='w-full'
                                                                variant='primary'
                                                                onClick={() => handleClick(item?.title, 'activate')}
                                                            >
                                                                {item.buttonText}
                                                            </Button>
                                                        </span>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <span className='flex justify-center'>
                                                    <Button
                                                        className='w-full'
                                                        variant='primary'
                                                        onClick={() => navigate('/checkout/settings/discount')}
                                                    >
                                                        {item.buttonText}
                                                    </Button>
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )
                })}
            </div>
        </>
    )
}
