import { Col, Layout, Row, Button, Tooltip } from 'gokwik-ui-kit'
import leftTops from '@library/images/icons/leftTops.svg'
import cash from '@library/images/icons/cash.svg'
import cart from '@library/images/icons/cart.svg'
import fingerprintPassword from '@library/images/icons/password-fingerprint.svg'
import cards from '@library/images/icons/cards.svg'
import number1 from '@library/images/icons/circle-1.svg'
import number2 from '@library/images/icons/circle-2.svg'
import number3 from '@library/images/icons/circle-3.svg'
import number4 from '@library/images/icons/circle-4.svg'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { newStepConfiguration } from '../stepsConfig'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'

const { Content } = Layout

const Steps = [
    {
        icon: cash,
        text: 'Install and activate COD module',
        stepIcon: number1,
        isInstant: true,
    },
    {
        icon: cart,
        text: 'Enable GoKwik Checkout',
        stepIcon: number2,
        isInstant: true,
    },
    {
        icon: fingerprintPassword,
        text: 'Verify Your Business(KYC)',
        stepIcon: number3,
        isInstant: false,
    },
    {
        icon: cards,
        text: 'Enable Payment Options',
        stepIcon: number4,
        isInstant: false,
    },
]

const Step = ({ icon, actionIcon, title, isInstant }) => {
    return (
        <div
            className='rounded-md flex flex-col items-center gap-4 min-w-[175px] max-w-[250px] flex-grow h-auto p-4 text-center'
            style={{ border: '1px solid #0000000F' }}
        >
            <div className='flex items-center justify-center'>
                <img src={icon} alt='Icon' className='w-10 h-10' />
            </div>
            <div className='flex gap-2 w-full'>
                <div className='ml-auto flex items-start'>
                    <img src={actionIcon} alt='Action Icon' className='w-6 h-6' />
                </div>
                <div className='flex flex-col w-full'>
                    <p className='font-medium text-[14px] leading-[22px]'>{title}</p>
                    {isInstant && (
                        <p className='bg-[#DAE9F7] rounded-md px-2 py-0.5 inline-block w-auto mx-auto text-[12px] text-[#004B8D] font-normal'>
                            Instant Activation
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default function OnboardingHome() {
    const navigate = useNavigate()
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)

    useEffect(() => {
        if (
            newStepConfiguration[merchantDetails?.onboarding_status?.kwik_checkout_app]?.id >
                newStepConfiguration['password_setup']?.id ||
            merchantDetails?.country?.toLowerCase() !== 'india'
        ) {
            navigate('/onboarding/cod-setup')
        } else if (!merchantDetails?.onboarding_status?.kwik_checkout_app) {
            navigate('/shopify-app/settings')
        }
    }, [])

    return (
        <Layout
            style={{ margin: '5rem auto', maxWidth: '80%', boxShadow: '4px 6px 16px 4px rgba(0, 0, 0, 0.2)' }}
            className='rounded-md'
        >
            <Content>
                <div className='flex flex-col justify-around h-full px-10'>
                    <div className='flex items-center justify-between w-full gap-5'>
                        <h2 className='font-bold text-5xl text-gray-900 sm:text-3xl flex-1 min-w-0'>
                            Reduced RTO Higher Conversions & Faster Checkout
                        </h2>
                        <div
                            className='flex items-center rounded-lg mt-6'
                            style={{ backgroundColor: '#FFFAEB', borderRadius: '13px' ,  width: 'fit-content', }}
                        >
                             <img
                                src={leftTops}
                                alt='left top'
                                className='sm:w-24 sm:h-24 object-cover'
                                style={{ borderRadius: '13px', width : "8rem", height : "9rem"}}
                            />

                            <div className='flex flex-col space-y-3 p-4'>
                                <div className='flex flex-col'>
                                    <p
                                        className='text-[#94380C] font-bold text-xs flex items-center justify-center rounded-md mb-2'
                                        style={{
                                            backgroundColor: '#FEE089',
                                            width: '41px',
                                            height: '23px',
                                        }}
                                    >
                                        FREE
                                    </p>

                                    <p className='text-md font-semibold text-yellow-900'>
                                        1000 COD orders every month for lifetime
                                    </p>
                                </div>

                                <div className='flex flex-col'>
                                    <p
                                        className='text-[#94380C] font-bold text-xs flex items-center justify-center rounded-md mb-2'
                                        style={{
                                            backgroundColor: '#FEE089',
                                            width: '41px',
                                            height: '23px',
                                        }}
                                    >
                                        FREE
                                    </p>
                                    <p className='text-md font-semibold text-yellow-900'>
                                        30 days trial for checkout
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-full flex flex-col gap-8'>
                        <p className='font-semibold text-[20px] leading-[28px]'>In just 4 simple steps</p>
                        <div className='flex justify-between gap-4'>
                            {Steps.map((step, index) => (
                                <Step
                                    key={index}
                                    icon={step.icon}
                                    actionIcon={step.stepIcon}
                                    title={step.text}
                                    isInstant={step.isInstant}
                                />
                            ))}
                        </div>
                    </div>

                    <div className='flex flex-col items-center text-center gap-4'>
                        <Button
                            variant='primary'
                            className='p-6 flex items-center justify-center'
                            style={{ width: '30%', textAlign: 'center' }}
                            onClick={() => {
                                navigate('/onboarding/cod-setup')
                                logEvent(
                                    'get_started_clicked',
                                    'click',
                                    'get-started',
                                    userDetails?.email,
                                    merchantDetails?.m_id,
                                    merchantDetails?.short_name,
                                    userDetails?.name,
                                )
                            }}
                        >
                            <p className='font-bold text-[16px]'>Let's Get Started</p>
                        </Button>
                        <div className='text-center'>
                            <b className='block'>After 30 days free trial</b>
                            <ul className='space-y-1 text-left'>
                                <li className='font-normal text-[12px] text-[#004B8D]'>
                                    <b>Completed KYC:</b> 2.5% charges (including payment gateway charges) apply to all
                                    prepaid orders.
                                </li>
                                <li className='font-normal text-[12px] text-[#004B8D]'>
                                    <b>Incomplete KYC:</b> 2.5% charges apply to all prepaid orders + your payment
                                    gateway charges.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    )
}
