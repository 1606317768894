// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(.css-dev-only-do-not-override-1urq5c6).ant-timeline .ant-timeline-item-head-custom{
    padding:5px ;
    border-radius:100px;
}

`, "",{"version":3,"sources":["webpack://./src/pages/timeline/timeline.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[":where(.css-dev-only-do-not-override-1urq5c6).ant-timeline .ant-timeline-item-head-custom{\n    padding:5px ;\n    border-radius:100px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
