import { Card, ChromeOutlined, ClockCircleOutlined, CodeOutlined, Col,  DatabaseOutlined, Divider, GlobalOutlined, HddOutlined, Row, Tag, ToolTwoTone, Tooltip, UserOutlined,Collapse } from "gokwik-ui-kit"
import dayjs from "dayjs"
import "./timeline.css"
const { Panel } = Collapse;


function renderOldData(oldData) {
    // Check if oldData is a string
    if (typeof oldData === 'string' && oldData.length !== 0) {
        return oldData // Return the string as is
    } else if (typeof oldData === 'boolean') {
        return oldData?.toString() // Return the string as is
    } else if (!oldData) {
        return 'N/A' // Return the string as is
    }
    // Check if oldData is an object
    else if (typeof oldData === 'object' && oldData !== null) {
        // Return the object using JSON.stringify
        return   <pre className="p-3 rounded">{JSON.stringify(oldData, null, 2)}</pre> // Add indentation for readability
    } else {
        return '' // Return an empty string if oldData is neither a string nor an object
    }
}

const truncateLength = (text) =>{
    if(text.length > 60){
        return <Tooltip title={text}>{`${text.slice(0,30)}...`}</Tooltip>
    }
    return text
}

const groupByDate = (data) => {
const grouped = data.reduce((acc, item) => {
  const date = dayjs(item.updated_at).format('YYYY-MM-DD');
  if (!acc[date]) acc[date] = [];
  acc[date].push(item);
  return acc;
}, {});
return Object.entries(grouped).map(([date, items]) => ({ date, items }));
};


export const   timeLineData = (datat) => {
    const groupedData = groupByDate(datat);
    const timelineItems = [];
  
    groupedData.forEach(({ date, items }:{date:string,items:any}) => {
      timelineItems.push({
        dot: <ClockCircleOutlined className="text-lg" />,
        children: (
          <Row  align={"middle"} className="text-center">
          <p className="text-xl font-bold text-gray-800 mb-4">{dayjs(date).format('DD MMMM YYYY')}</p>
          <p className="text-gray-700 ml-3 mb-4 border-solid border-gray-300 rounded bg-white border-1 px-2"><span className="font-semibold">{items?.length}</span> total changes on this day.</p>
          </Row>
        ),
      });
    //   @ts-ignore
      items.forEach((itm) => {
        timelineItems.push({
          dot: <ToolTwoTone className="text-xl" />,
          children: (
            <div className="timeline-item-content">
              <Card className="rounded-md p-4 shadow-lg border border-gray-300 mb-6">
                <Row justify="space-between" align="middle" className="mb-2">
                  <Tooltip title={`Modified by ${itm.modified_by}`}>
                    <p className="text-sm text-gray-800 font-semibold flex items-center">
                      <UserOutlined className="text-blue-500 mr-2" /> {itm.modified_by}
                    </p>
                  </Tooltip>
                  <Tooltip title={`Updated at ${dayjs(itm.updated_at).format('DD/MMM/YYYY, hh:mm:ss a')}`}>
                    <p className="text-sm text-gray-500 flex items-center">
                      <ClockCircleOutlined className="text-gray-400 mr-2" /> {dayjs(itm.updated_at).format('DD/MMM/YYYY, hh:mm:ss a')}
                    </p>
                  </Tooltip>
                </Row>
                <Divider className="my-2" />
                <Row className="mb-2">
                  <Col span={12} className="p-2">
                    <div>
                      <p className="text-sm text-gray-500 font-semibold flex items-center">
                        <DatabaseOutlined className="mr-2" /> Table Effected:
                        <span className="text-gray-600 font-bold uppercase ml-2">{itm.table_name}</span>
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 font-semibold flex items-center">
                      <CodeOutlined className="mr-2" /> Property:
                        <Tag color="blue" className="ml-2">{itm.property_name}</Tag>
                      </p>
                    </div>
                  </Col>
                  <Col span={12} className="p-2">
                    <div>
                      <p className="text-sm text-gray-500 font-semibold">
                      <GlobalOutlined /> IP Address:
                        <span className="text-gray-600 font-bold ml-2">{itm.ip_address || "N/A"}</span>
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500 font-semibold">
                      <ChromeOutlined /> User Agent:
                        <span className="text-gray-600 font-bold ml-2">{itm.user_agent ? truncateLength(itm.user_agent) : "N/A"}</span>
                      </p>
                    </div>
                  </Col>
                </Row>
                <Collapse>
                  <Panel header="Old Data" key="1">
                    <div className="max-h-80 overflow-auto bg-blue-50 rounded p-2 border-l-4 border-red-400">
                      {renderOldData(itm.old_value)}
                    </div>
                  </Panel>
                  <Panel header="New Data" key="2">
                    <div className="max-h-80 overflow-auto bg-yellow-50 rounded p-2 border-l-4 border-green-400">
                      {renderOldData(itm.new_value)}
                    </div>
                  </Panel>
                </Collapse>
              </Card>
            </div>
          ),
        });
      });
    });
  
    return timelineItems;
  };