import React, { useEffect, useState } from 'react';
import { Button, Divider } from "gokwik-ui-kit";
import previewPlaceholder3 from '@library/images/icons/codStep3.png'; 
import HowToStepsWithPreview from '../CommonComponents/howToStepsAndPreview';
import darkExternalLink from '@library/images/icons/dark-external-link.svg';
import lightExternalLink from '@library/images/icons/light-external-link.svg';
import { useSelector } from 'react-redux';
import { getMerchantDetails, getUserDetails } from '@store/user/selectors';
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents';
import { updateMerchantOnboardingCheckoutConfigsStatus } from '../CommonComponents/Utils';
import CheckoutStepsHeader from '../CommonComponents/checkoutStepsHeader';
import { navigateToUrl } from 'single-spa';
import { fetchUserDetails } from '@store/user';
import { useAppDispatch } from '@library/utilities/hooks'

const CodOnboardingStep3 = ({ onStepComplete, currentStep, expandedStep, toggleStep }) => {
    const [siteVisited, setSiteVisited] = useState(false);
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)
    const [showContent, setShowContent] = useState(false);
    const gkCheckoutStatus = merchantDetails?.onboarding_status?.gk_checkout_status;
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (expandedStep === 3 && currentStep !== 3) {
            setSiteVisited(true);
        }
        if (expandedStep == 3) {
            setShowContent(false);
            setTimeout(() => setShowContent(true), 100);
        }
    }, [expandedStep]);

    const skipImpChecksPassedScreen = () => {
        return merchantDetails?.country?.toLowerCase() !== 'india' || ['gk_checkout_buttons_working', 'gk_checkout_buttons_not_working'].includes(gkCheckoutStatus)
    }

    const finishSetupClicked = () => {
        logEvent(
            "cod_complete_setup_clicked",
            'click',
            'cod-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            {Step : 'cod 3'},
        )
        updateMerchantOnboardingCheckoutConfigsStatus(skipImpChecksPassedScreen() ? 'imp_checks_passed' : 'smart_cod_app_disabled', () => {
            dispatch(fetchUserDetails())
            onStepComplete();
            if (skipImpChecksPassedScreen()) {
                navigateToUrl('/shopify-app/settings')
            }
        }, merchantDetails)
    }

    const steps = [
        {   
            description: (<>Click on <b>“Disable Shopify COD”</b> button.</>),
            step: 3,
        },
        { 
            description: (<>You will be redirected to the <b>“payments”</b> section on Shopify, locate <b>"Manual Payment method"</b>.</>) 
        },
        {
            description: (<>Click on <b>“Edit”</b> button and <b>“Deactivate existing Payment Method”</b>.</>)
        },
        {
            description: (<>After deactivating, <b>come back to this page</b>.</>) 
        },
        { 
            description: (<>Click on <b>“Finish Setup”</b> Button.</>) 
        }
    ]

    const openShpAdminPaymentsPage = () => {
        logEvent(
            siteVisited ? "disable_app_clicked_again" : "disable_app_clicked",
            'click',
            'cod-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            {Step : 'cod 3'},
        )
        setSiteVisited(true)
        const storeName = merchantDetails?.website?.replace('.myshopify.com', '') || '';
        window.open(`https://admin.shopify.com/store/${storeName}/settings/payments`, '_blank');
    }

    return (
        <div className="border border-[#D0D5DA] rounded-lg bg-white w-[74vw]">
            <CheckoutStepsHeader step={'Step 3'} heading={'Disable Shopify COD'} toggleStep={toggleStep} stepId={3} currentStep={currentStep} />

            {expandedStep === 3 ? (
                <div 
                    style={{
                        opacity: showContent ? 1 : 0,
                        transform: showContent ? 'translateY(0)' : 'translateY(20px)',
                        transition: 'opacity 0.5s ease, transform 0.5s ease',
                    }}
                >
                    <Divider className='m-0'/>
                    <HowToStepsWithPreview steps={steps} previewImage={previewPlaceholder3} videoUrl={'https://pdp.gokwik.co/sa-login-ui/assets/DisableManualCODApp.mp4'} page={'cod'}/>
                    <Divider  className='m-0' />

                    <div className={`flex  ${siteVisited ? "" : "flex-row-reverse"} justify-between items-center p-[0.8rem_1rem]`}>
                        <div className="flex items-center gap-3">
                            <Button
                                onClick={openShpAdminPaymentsPage}
                                variant={siteVisited ? 'default' : 'primary'}
                                className="flex items-center"
                                size="large"
                            >
                                Disable Shopify COD <img style={{ marginLeft: '0.4rem' }} src={siteVisited ? darkExternalLink : lightExternalLink} />
                            </Button>
                        </div>
                        {siteVisited && (
                            <Button
                                onClick={finishSetupClicked}
                                variant='primary'
                                className="flex items-center"
                                size="large"
                                disabled={(expandedStep === 3 && currentStep !== 3)}
                            >
                                Finish Setup
                            </Button>
                        )}
                    </div>
                </div>
            ) : "" }
        </div>
    )
};

export default CodOnboardingStep3;
