import { Button, Col, DatePicker, Form, InputNumber, Row, Select, Upload, Space, Input, Tooltip } from 'gokwik-ui-kit';
import TieredControls from './tieredControls';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { CloseCircleFilled } from '@ant-design/icons';

const changeHandler = (input, val, callback) => callback(input, val);
const truncateString = (text, maxLength) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + '...';
};
const getBooleanvalue = (val) =>{
  return  val ?"Enabled":"Disbaled"
}

export const inputSelector = (input, onChange, getValue,index) => {
  const details = input.input_details || input;
  switch (details.type) {
    case 'upload': {
      const val = getValue(input);
      const [showUpload, setShowUpload] = useState(false);
      const [uploadingStaus, setUploadingStaus] = useState('');
      return val.name && !showUpload ? (
        <Tooltip title={val.name}>
        <Button className='w-full flex items-center justify-between  overflow-hidden'>
          <a className='file-name' href={val.url} target='_blank'>
            {truncateString(val.name,15)}
          </a>
          {!input.disabled && (
            <CloseCircleFilled
              onClick={() => {
                onChange(input, { file: null, fileName: null });
                setShowUpload(true);
                setUploadingStaus('');
              }}
              className='text-black hover:text-red-500'
            />
          )}
        </Button>
        </Tooltip>
      ) : (
        <Upload
          className='w-full '
          onChange={(info) => {
            setUploadingStaus(info.file.status);
          }}
          accept='.pdf, .doc'
          disabled={input.disabled}
          customRequest={async (options) => {
            const { onSuccess, onError, file, onProgress } = options;
            var data = new FormData();
            data.append('file', file);
            // @ts-ignore
            onChange(input, { file: data, fileName: file?.name });
            setUploadingStaus('done');
            // @ts-ignore
            onSuccess();
          }}
        >
          {uploadingStaus !== 'done' && (
            <Button className='w-full' disabled={input.disabled}>
              Click here to upload
            </Button>
          )}
        </Upload>
      );
    }
    case 'date':
      return (
        <DatePicker
          key={index}
          className='w-full '
          disabled={input.disabled}
          value={getValue?.(input) || (input.value ? dayjs(input.value) : null)}
          onChange={(val, str) => changeHandler(input, val, onChange)}
          format={'DD/MM/YYYY'}
          allowClear={false}
        />
      );
    case 'select':
      return (
        <Select
          key={index}
          mode={details.mode}
          options={details?.options || []}
          placeholder='Select value'
          value={details.mode === "multiple" ? (getValue?.(input) || [])  :  ((typeof getValue?.(input) === 'boolean' ? getBooleanvalue(getValue?.(input).toString()) : getValue?.(input) || input.value) || <span className='text-gray-500'>Select Value</span>)}
          onChange={(val) => changeHandler(input, val, onChange)}
          disabled={input.disabled}
          defaultValue={details.defaultValue }
          className='min-w-fit'
        />
      );
    case 'number':
      return (
        <InputNumber
          key={index}
          min={details.range?.[0] || 0}
          max={details.range?.[1] || Number.MAX_SAFE_INTEGER}
          stringMode={details.allowDecimals}
          className='w-full'
          placeholder='Enter value'
          value={getValue?.(input) || input.value}
          onChange={(val) => changeHandler(input, val, onChange)}
          disabled={input.disabled}
        />
      );

    case 'spacer':
      return (
        <Space.Compact key={index} className='min-w-[300px]'>
          {details?.spacerDetails?.map((detail,index) => {
            return inputSelector(detail, onChange, detail?.getValue,index);
          })}
        </Space.Compact>
      );

    default:
      return null;
  }
};

const RenderInput = ({ data, onChange, getValue, disableAll }) => {
  return data.map((item, i) => (
    <>
      <Col key={item.name + i} span={item.input_details?.span || 4}>
        {item.input_details?.type === 'button' ? (
          <Button type='primary'>Save</Button>
        ) : item.input_details?.type === 'limit' ? (
          <TieredControls details={item} disableAll={disableAll} onChange={onChange} />
        ) : (
          <>
            <Form.Item
              className='mb-3'
              name={item.name}
              label={item.label ? <span className='capitalize pb-0'>{item.label}</span> : ''}
            >
              <Col>{inputSelector({ ...item, disabled: disableAll || item.disabled }, onChange, getValue,i)}</Col>
            </Form.Item>
            {!!item.children?.length && 
            // @ts-ignore
            <RenderInput data={item.children} onChange={onChange} />
            }
          </>
        )}
      </Col>
    </>
  ));
};

export default RenderInput;
