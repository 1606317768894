// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.congratulation-nav .ant-tabs-nav-list {
    margin: auto;
}

.truncate-lines-3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3; /* Number of lines to show */
}
`, "",{"version":3,"sources":["webpack://./src/pages/onboarding/congratulations/congratulations.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,oBAAoB;IACpB,4BAA4B;IAC5B,gBAAgB;IAChB,qBAAqB,EAAE,4BAA4B;AACvD","sourcesContent":[".congratulation-nav .ant-tabs-nav-list {\n    margin: auto;\n}\n\n.truncate-lines-3 {\n    display: -webkit-box;\n    -webkit-box-orient: vertical;\n    overflow: hidden;\n    -webkit-line-clamp: 3; /* Number of lines to show */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
