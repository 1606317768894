import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { Button, Col, Drawer, Input, Row, Select, Space, TextArea, Tooltip, message, DatePicker, TimePicker,Modal } from 'gokwik-ui-kit'
import { ReactNode, useEffect, useState } from 'react'
import dayjs from 'dayjs';
// import TimePicker from 'react-time-picker';
interface Props {
  open: boolean
  title: string
  onClose: (refetch?: boolean) => void
}

interface NotificationsDetails {
  name: string | null
  description: string | null
}

const addDrawerPermissions = ({ open, title, onClose }: Props) => {


  const [data, setData] = useState<NotificationsDetails>({
    name: '',
    description: '',
  })
  const [proceed, setProceed] = useState(false)
  
  const conditionsCheck = async() => {
    if (!data?.name.trim()) {
      message.warning('Name is Required')
      return
    }
    if(!data?.description?.trim()){
      message.warning('Description is Required')
      return
    }
    if(data.description.split(' ').length > 20) {
      message.warning('description should be less than 20 words')
      return
    }
    setProceed(true);
    return;
  }
  const onEditSubmit = async () => {
    const payload = {
      name: data?.name.trim().replace(/,/g, ' '), // optional
      description: data.description.trim(),
    }
    // have to change url
    setProceed(false);
    const url = `${process.env.REACT_APP_BASE_URL}${APIEndPoints.addPermissions}`
    const response = await makeAPICall({
      method: 'post',
      url: url,
      payload: payload 
    })
    if (response.success) {
      message.success(response.data.message)
      onClose(true)
    } else {
      message.warning(response?.data?.message || response?.data?.data?.message || response?.response?.data?.message || response?.response?.data?.data?.message)

    }
  }
  


  return (
    <>
    <Modal
          title={
              <p> Are your sure, you want to add this permission ?</p>
          }
          open={proceed ? true : false}
          onCancel={() => {
              setProceed(false)
          }}
          centered
          width={'500px'}
          footer={() => (
              <Row className='flex justify-end gap-2'>
                  <Button onClick={() => setProceed(null)} variant='default'>
                      Cancel
                  </Button>
                  <Button
                        onClick={() => {
                          onEditSubmit()
                        }}
                        variant='primary'
                    >
                        Confirm
                    </Button>
              </Row>
          )}
      >
    </Modal>
    <Drawer title={title} className='pt-0' placement='right' onClose={() => onClose()} open={open}>
      <Col className='w-full'>
        <Space direction='vertical' className='w-full'>
          <p className='mt-2'>Name* </p>
          <Input
            value={data?.name}
            placeholder='Name'
            onChange={(e) => {
              setData((prev) => ({ ...prev, name: e.target.value }))
            }}
          />
          <p className='mt-2'>Description* </p>
          <TextArea
            placeholder='Description'
            value = {data?.description}
            onChange={(e) => {
              setData((prev) => ({ ...prev, description: e.target.value }))
            }}
          />
        </Space>
      </Col>
      <Row className='mt-4'>
        <Col span={12}>
          <Button onClick={conditionsCheck} variant='primary' className='w-[99%]'>
            Submit
          </Button>
        </Col>
        <Col span={12}>
          <Button onClick={() => onClose(false)} variant='default' className='w-[99%] ml-1'>
            Cancel
          </Button>
        </Col>
      </Row>
    </Drawer>
  </>
  )
}

export default addDrawerPermissions
