import { Select } from 'gokwik-ui-kit'
import React, { useState } from 'react'
import RTOAnalytics from './charts/RTOAnalytics'
import CheckoutAnalytics from './charts/CheckoutAnalytics'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getUserData } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import SessionAnalytics from './charts/SessionAnalytics'

const Charts = () => {
    const [timeFrame, setTimeFrame] = useState('week')
    const userData = useSelector(getUserData)

    const handleTimeFrameChange = (value) => {
        setTimeFrame(value)
        logEvent(
            'es_analytics_date_filter_applied',
            'click',
            'executive_summary',
            userData?.userDetails?.email,
            userData?.merchantDetails?.m_id,
            userData?.merchantDetails?.short_name,
            userData?.userDetails?.name,
            { filterApplied: value },
        )
    }

    const featureFlag = () => {
        const executiveSummaryFeatureFlags = [
            'sherali.hussain@gokwik.co',
            'anuj.agrawal@gokwik.co',
            'mahima.garg@gokwik.co',
            'aditya@gokwik.co',
            'lasya.priya@gokwik.co',
            'prashant.sharma@gokwik.co',
            'mandeep@gokwik.co',
            'srikant@gokwik.co',
            'suhag.vamja@gokwik.co',
            'tripti.agarwal@gokwik.co',
            'rakesh.thakur@gokwik.co',
            'akash.kumar@gokwik.co',
            'sherali2910.sa@gmail.com',
            'sher.ali@gmail.com',
        ]
        return executiveSummaryFeatureFlags?.includes(userData?.userDetails?.email)
    }

    return (
        <div className='p-2'>
            <div className='flex justify-between'>
                <span className='text-xl font-semibold text-[#000000E0]'>Overview</span>

                <Select defaultValue={timeFrame} onChange={handleTimeFrameChange}>
                    <Select.Option value={'week'}>3 Weeks</Select.Option>
                    <Select.Option value={'month'}>3 Months</Select.Option>
                    <Select.Option value={'day'}>3 Days</Select.Option>
                </Select>
            </div>

            <br />
            <div>
                <CheckoutAnalytics timeFrame={timeFrame} />
                <br />
                <RTOAnalytics timeFrame={timeFrame} />
                {featureFlag() && (
                    <>
                        <br />
                        <SessionAnalytics timeFrame={timeFrame} />
                    </>
                )}
            </div>
        </div>
    )
}

export default Charts
