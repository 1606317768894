const BannerTopImg = () => {
    return (
        <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <mask
                id='mask0_315_12321'
                style={{ maskType: 'alpha' }}
                maskUnits='userSpaceOnUse'
                x='0'
                y='0'
                width='40'
                height='40'
            >
                <mask id='path-1-inside-1_315_12321' fill='white'>
                    <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M20 0C14.4772 0 10 4.47715 10 10C4.47715 10 0 14.4772 0 20C0 25.5228 4.47715 30 10 30C10 35.5228 14.4772 40 20 40C25.5228 40 30 35.5228 30 30C35.5228 30 40 25.5228 40 20C40 14.4772 35.5228 10 30 10C30 4.47715 25.5228 0 20 0Z'
                    />
                </mask>
                <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M20 0C14.4772 0 10 4.47715 10 10C4.47715 10 0 14.4772 0 20C0 25.5228 4.47715 30 10 30C10 35.5228 14.4772 40 20 40C25.5228 40 30 35.5228 30 30C35.5228 30 40 25.5228 40 20C40 14.4772 35.5228 10 30 10C30 4.47715 25.5228 0 20 0Z'
                    fill='url(#paint0_linear_315_12321)'
                />
                <path
                    d='M10 10V11H11V10H10ZM10 30H11V29H10V30ZM30 30V29H29V30H30ZM30 10H29V11H30V10ZM11 10C11 5.02944 15.0294 1 20 1V-1C13.9249 -1 9 3.92487 9 10H11ZM1 20C1 15.0294 5.02944 11 10 11V9C3.92487 9 -1 13.9249 -1 20H1ZM10 29C5.02944 29 1 24.9706 1 20H-1C-1 26.0751 3.92487 31 10 31V29ZM20 39C15.0294 39 11 34.9706 11 30H9C9 36.0751 13.9249 41 20 41V39ZM29 30C29 34.9706 24.9706 39 20 39V41C26.0751 41 31 36.0751 31 30H29ZM39 20C39 24.9706 34.9706 29 30 29V31C36.0751 31 41 26.0751 41 20H39ZM30 11C34.9706 11 39 15.0294 39 20H41C41 13.9249 36.0751 9 30 9V11ZM20 1C24.9706 1 29 5.02944 29 10H31C31 3.92487 26.0751 -1 20 -1V1Z'
                    fill='url(#paint1_linear_315_12321)'
                    mask='url(#path-1-inside-1_315_12321)'
                />
            </mask>
            <g mask='url(#mask0_315_12321)'>
                <g filter='url(#filter0_f_315_12321)'>
                    <circle cx='17.7706' cy='4.42662' r='10.6' fill='#0086FC' />
                    <circle cx='18.5121' cy='40.495' r='12.3446' fill='#90CBFF' />
                    <circle cx='12.491' cy='31.0452' r='15.0735' fill='#2297FF' />
                    <circle cx='12.9526' cy='30.8395' r='7.25214' fill='#DAEEFF' />
                    <circle cx='26.9559' cy='27.6978' r='12.4029' fill='#FEC84B' />
                </g>
            </g>
            <path
                d='M10 20C15.4432 19.8123 19.8123 15.4432 20 10C20.1877 15.4432 24.5568 19.8123 30 20C24.5568 20.1877 20.1877 24.5568 20 30C19.8123 24.5568 15.4432 20.1877 10 20Z'
                fill='#004B8D'
            />
            <defs>
                <filter
                    id='filter0_f_315_12321'
                    x='-26.5825'
                    y='-30.1733'
                    width='89.9412'
                    height='107.013'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
                    <feGaussianBlur stdDeviation='12' result='effect1_foregroundBlur_315_12321' />
                </filter>
                <linearGradient
                    id='paint0_linear_315_12321'
                    x1='20'
                    y1='0'
                    x2='20'
                    y2='40'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#D9D9D9' />
                    <stop offset='1' stop-color='#D9D9D9' stop-opacity='0' />
                </linearGradient>
                <linearGradient
                    id='paint1_linear_315_12321'
                    x1='20'
                    y1='0'
                    x2='20'
                    y2='40'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-opacity='0.2' />
                    <stop offset='1' stop-color='white' stop-opacity='0.2' />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default BannerTopImg
