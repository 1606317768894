import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import user from './user'
import filters from './filters'

const rootReducer = combineReducers({
    user,
    filters,
})

const store = configureStore({
    reducer: rootReducer,
    devTools: true,
})

export default store
export type RootStoreType = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
