import {
    Table,
    Button,
    Col,
    ColumnProps,
    Tag,
    message,
    EditOutlined,
    Tooltip,
    Row,
    InfoCircleOutlined,
} from 'gokwik-ui-kit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DownloadOutlined, PlusCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { PermissionValues } from '@library/utilities/constants/constants'
import {
    convertIsoToLocaleDateString,
    convertIsoToLocaleString,
    filterDateFormatter,
    makeAPICall,
    updateBreadcrumbs,
    validatePermission,
    downloadFromURL,
    titleCase,
} from '@gokwik/utilities'
import ReportsIcon from '@library/images/icons/reportsIcon'
import RenderSearchFilters from '@library/components/search-filter'
import { useDispatch, useSelector } from 'react-redux'
import { previousReports } from '@store/filters/selectors'
import { saveFiltersData } from '@store/filters'
import { isMerchantSelector } from '@store/user/selectors'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useAppDispatch, useInterval } from '@library/utilities/hooks'
import { handleError } from '@library/utilities/helpers/handleError'
import { useNavigate } from 'react-router-dom'
import EditUsersDrawer from './editDrawer'

const today = dayjs()
const formattedDate = filterDateFormatter(today)

export default function () {
    const [notifications, setNotifications] = useState([])
    const [total, setTotal] = useState(0)
    const [action, setAction] = useState('')
    const [editOpen, setEditOpen] = useState<any>()
    const prevFilters = useSelector(previousReports)
    const isMerchantUser = useSelector(isMerchantSelector)
    const [isNew, setIsNew] = useState(false)
    const [title, setTitle] = useState('Edit Notification')
    const [roleList, setRoleList] = useState([])
    const params = new URLSearchParams(location.search)
    const queryParams = {
        from: params.get('from'),
        start: params.get('start'),
        end: params.get('end'),
    }
    const [parameters, setParameters] = useState({
        page: 1,
        pageSize: 25,
        sortKey: 'created_at',
        sortOrder: -1,
        ...prevFilters,
    })
    const openEdit = (id, data) => {
        setAction('user_update')
        setEditOpen(data)
    }
    const formatToIST = (dateStr) => {
        const date = new Date(dateStr)
        return formatDateWithoutSeconds(date.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata', hour12: true }))
    }

    function formatDateWithoutSeconds(dateString) {
        const [datePart, timePart] = dateString.split(', ')
        const [day, month, year] = datePart.split('/')
        let [time, meridian] = timePart.split(' ')

        let [hours, minutes] = time.split(':')
        const formattedDate = `${day}/${month}/${year}, ${hours}:${minutes} ${meridian}`
        return formattedDate
    }

    const getExpirationDate = (login_date) => {
        let date_1 = new Date()
        let date_2 = new Date(login_date)

        const days = (date_1, date_2) => {
            let difference = date_1.getTime() - date_2.getTime()
            let TotalDays = 90 - Math.ceil(difference / (1000 * 3600 * 24))
            return TotalDays <= 0 ? 0 : TotalDays
        }
        return days(date_1, date_2)
    }

    const columns: ColumnProps<any>[] = [
        {
            title: <span>Full Name</span>,
            dataIndex: 'name',
            width: 35,
            ellipsis: true,
            render: (text) => <span>{`${titleCase(text)}`}</span>,
        },
        {
            title: <span>Email</span>,
            dataIndex: 'email_mask',
            width: 40,
            ellipsis: true,
            render: (text: string) => (
                <Tooltip title={text} placement='topLeft'>
                    {text}
                </Tooltip>
            ),
        },

        {
            title: <span>Phone</span>,
            dataIndex: 'phone_mask',
            width: 25,
            render: (text: string) => <span>{text}</span>,
        },

        {
            title: <span>Role</span>,
            dataIndex: 'role',
            width: 20,
            render: (text: string) => <span>{text}</span>,
            align: 'left',
        },
        {
            title: <span>Last Login</span>,
            dataIndex: 'last_login',
            width: 25,
            render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
            align: 'left',
        },
        {
            title: <span>Password Expiry</span>,
            dataIndex: 'last_password_change',
            width: 20,
            render: (text: string) => <span>{getExpirationDate(text)}</span>,
            align: 'left',
        },
        {
            title: <span>Status</span>,
            dataIndex: 'status',
            width: 20,
            render: (text: string) => (
                <span>
                    {text === 'suspended' || text === 'blocked' || text === 'deleted' ? (
                        <Tag
                            variant={'error'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Suspended
                        </Tag>
                    ) : (
                        <Tag
                            variant={'success'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Active
                        </Tag>
                    )}
                </span>
            ),
            align: 'center',
        },
        {
            title: <span>Created At</span>,
            dataIndex: 'created_at',
            width: 25,
            ellipsis: true,
            render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
            sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: <span>Updated At</span>,
            dataIndex: 'updated_at',
            render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
            sorter: (a, b) => (dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? -1 : 1),
            sortDirections: ['ascend', 'descend'],
            width: 25,
        },
        ...(validatePermission(PermissionValues.users.addUser)
            ? [
                  {
                      title: <span>Actions</span>,
                      dataIndex: 'id',
                      width: 20,
                      render: (id, record) => {
                          return (
                              <Row className={`m-0 `}>
                                  <Tooltip title='Edit User'>
                                      <EditOutlined
                                          onClick={() => openEdit(id, record)}
                                          className='ml-4 text-error-700'
                                      />
                                  </Tooltip>
                              </Row>
                          )
                      },
                  },
              ]
            : []),
    ]

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'users',
                href: '/users',
                text: 'Users',
            },
        ])
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                let res = await makeAPICall({
                    method: 'get',
                    url: process.env.REACT_APP_BASE_URL + '/api/dashboard/roles?page=1&pageSize=100',
                    params: parameters,
                })

                if (res.data.data.roles.length > 0) {
                    setRoleList(res.data.data.roles?.filter((role) => role.permission_type === 'web'))
                } else {
                    message.warning('No Roles Found')
                }
            } catch (error) {
                message.error('Failed to fetch roles')
            }
        }

        fetchData()
    }, [])

    useEffect(() => {
        if (queryParams.from && queryParams.start && queryParams.end) {
            setParameters((prev) => ({
                ...prev,
            }))
        }
    }, [])

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
        makeUsersApiCall({ ...parameters, page: current, pageSize })
    }

    const handleSearchClick = (e) => {
        e?.preventDefault()
        makeUsersApiCall({ ...parameters, page: 1 })
    }

    const reset = (e) => {
        e.preventDefault()
        const newParams = {
            page: 1,
            pageSize: 25,
            sortKey: 'created_at',
            sortOrder: -1,
        }
        setParameters({ ...newParams })
        makeUsersApiCall(newParams)
    }

    const makeUsersApiCall = async (parameters: any) => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getUsers,
                params: { ...parameters, user_type: 'admin' },
            })
            if (response?.data?.status_code === 200) {
                setNotifications(response?.data?.data?.users)
                setTotal(response.data?.data?.totalCount)
            } else if (response?.status === 204) {
                message.warning('No Such User found')
                setNotifications([])
            } else {
                message.error(
                    response?.response?.data?.data ||
                        response?.data?.data?.message ||
                        response?.response?.data?.message ||
                        response?.response?.data?.data?.message ||
                        'Roles are not present',
                )
            }
        } catch (error) {
            handleError(error)
        }
    }

    useEffect(() => {
        makeUsersApiCall({ ...parameters })
    }, [])
    return (
        <>
            <div className='overflow-auto w-full dashboard-reports'>
                <div className='bg-white'>
                    <>
                        <Row className='p-2'>
                            <Col sm={12} className='flex items-center gap-x-2 '>
                                <div className='text-black inter m-0 tracking-wide items-center flex gap-2'>
                                    <Tooltip
                                        title={'View and manage user information and activities efficiently here.'}
                                    >
                                        <InfoCircleOutlined className='text-blue fs20' />
                                    </Tooltip>

                                    <span>View and manage user information and activities efficiently</span>
                                </div>
                            </Col>
                            <Col className='flex flex-grow justify-end gap-x-2'>
                                <Button
                                    onClick={() => {
                                        setAction('create_user')
                                        setEditOpen(true)
                                        setIsNew(true)
                                        setTitle('Add New User')
                                    }}
                                    variant='primary'
                                    className='flex align-center items-center'
                                >
                                    <PlusCircleOutlined width={16} color='white' />
                                    <span className='ml-2 text-white'>Add Users</span>
                                </Button>
                            </Col>
                        </Row>
                    </>
                </div>

                <div className='mt-5 bg-white rounded overflow-clip'>
                    <>
                        <Row className='bg-white rounded'>
                            <RenderSearchFilters
                                values={parameters}
                                setValues={(data, reset) =>
                                    setParameters((prev: any) => (reset ? { ...data } : { ...prev, ...data }))
                                }
                                page='users'
                                onSearch={handleSearchClick}
                                onReset={reset}
                            />
                        </Row>
                    </>
                    <Table
                        className={'ordersTable'}
                        columns={columns}
                        dataSource={notifications || []}
                        pagination={{
                            current: parameters.page, // Current page number
                            pageSize: parameters.pageSize, // Number of items to display per page
                            total: total, // Total number of items in the data array
                            showSizeChanger: false,
                            onChange: handlePaginationChange,
                            position: ['topRight', 'bottomRight'],
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                        }}
                        rowClassName={(record, index) =>
                            (new Date().getTime() - new Date(record.generatedOn).getTime()) / 1000 < 30 &&
                            `animate-bg-${record.status.toLowerCase()}`
                        }
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            </div>
            {editOpen && (
                <EditUsersDrawer
                    title={title}
                    open={!!editOpen}
                    onClose={(text: string) => {
                        if (text === 'Added') makeUsersApiCall({ ...parameters })
                        setEditOpen(false)
                        setIsNew(false)
                        setTitle('Edit User')
                    }}
                    recordData={editOpen}
                    type='transaction'
                    isNew={isNew}
                    roleList={roleList}
                    action={action}
                />
            )}
        </>
    )
}
