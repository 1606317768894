import LoginLayout from '@library/components/loginLayout'
import { passwordValidation, passwordValidationMessages } from '@library/utilities/helpers/validations'
import { Button, Col, Input, Row, Form, Popover, message, Alert } from 'gokwik-ui-kit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import shieldGreen from '@library/images/icons/shield.svg'
import shieldCross from '@library/images/icons/shield-cross.svg'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useLocation } from 'react-router-dom'
import useAESEncryption from '@library/utilities/authorization/encryption'
import { useAppDispatch } from '@library/utilities/hooks'
import { fetchUserDetails } from '@store/user'
import { navigateToUrl } from 'single-spa'
const InvitationForm = () => {
    const dispatch = useAppDispatch()
    const location = useLocation()
    const [form] = Form.useForm()
    const [showPasswordAssistance, setShowPasswordAssistance] = useState(false)
    const [roles, setRoles] = useState([])
    const [initialValues, setInitialValues] = useState({})
    const [isExpired, setIsExpired] = useState(false)
    const password = Form.useWatch('password', form)
    const PopoverContent = useCallback(() => {
        const passwordStrength = passwordValidation(password || '')
        return (
            <>
                {Object.entries(passwordValidationMessages).map(([key, value]) => (
                    <div key={key} className={`text-xs flex justify-between p-1`}>
                        <p className='mr-2'>{value}</p>
                        {passwordStrength[key] ? (
                            <img src={shieldGreen} className='tick-icon' />
                        ) : (
                            <img src={shieldCross} className='tick-icon' />
                        )}
                    </div>
                ))}
            </>
        )
    }, [password])

    useEffect(() => {
        // getUserRoles()
    }, [])

    const getUserRoles = async () => {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getUserRoles,
            })
            console.log(response)

            if (response?.data?.data?.roles.length) {
                setRoles(response?.data?.data?.roles)
            }
        } catch (err) {
            console.warn(err)
        }
    }

    useEffect(() => {
        getPrefilledDetails()
    }, [])

    function findRoleNameById(id) {
        // const foundItem = roles?.find((item) => item.id === id)
        // return foundItem ? foundItem.name : null
    }

    useEffect(() => {
        form.setFieldsValue(initialValues)
    }, [initialValues, form])

    const getUUIDFromURL = () => {
        const urlString = location.pathname
        const pathSegments = urlString.split('/')
        const uuid = pathSegments[pathSegments.length - 1]
        return uuid
    }

    const getPrefilledDetails = async () => {
        if (getUUIDFromURL()) {
            const response = await makeAPICall({
                method: 'get',
                url:
                    process.env.REACT_APP_BASE_URL +
                    APIEndPoints.getPrefilledDetailsOfInvitedUser +
                    '/' +
                    getUUIDFromURL(),
            })

            if (response?.data?.status_code === 200) {
                const data = response?.data?.data
                setInitialValues({
                    name: data?.name,
                    email: data?.email,
                    role: data?.role_name,
                    companyName: data?.short_name,
                })
            } else {
                setIsExpired(true)
            }
        } else {
            message.error('User Not Found')
        }
    }
    const signInInvitedUser = async (password, phone, user_type) => {
        if (getUUIDFromURL()) {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.signInInvitedUser + '/' + getUUIDFromURL(),
                payload: { password, phone, user_type },
            })

            if (response?.data?.status_code === 200) {
                message.success(response?.data?.data + ' ' + '! Please Login')
                navigateToUrl('/login')
            }
        }
    }
    const onFinish = async (values: any) => {
        const password = await useAESEncryption(values?.password, process.env.REACT_APP_PUBLIC_CRYPTO_KEY)
        const phone = values?.phoneNumber
        const user_type = 'merchant'
        signInInvitedUser(password, phone, user_type)
    }

    return (
        <LoginLayout
            titleContent={
                <>
                    {' '}
                    <p className='text-3xl text-[#060322] font-normal leading-normal inter tracking-tight'>
                        Welcome Back
                    </p>
                    <svg xmlns='http://www.w3.org/2000/svg' width='398' height='2' viewBox='0 0 398 2' fill='none'>
                        <path d='M1 1L397 0.999965' stroke='#828282' stroke-linecap='round' />
                    </svg>
                    <p className='font-normal text-normal'>For secure access, please enter your details below.</p>
                </>
            }
            classNames='!w-[40vw] '
        >
            {!isExpired ? (
                <Row gutter={[0, 24]} className='invitation-form '>
                    <Col span={24}>
                        <Form
                            layout='vertical'
                            form={form}
                            className=''
                            onFinish={onFinish}
                            initialValues={initialValues}
                        >
                            <Row gutter={[12, 12]} wrap>
                                <Col span={12}>
                                    <Form.Item className='mb-0' name='name' label='Name'>
                                        <Input placeholder='' disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item className='mb-0' name='email' label='Email Address'>
                                        <Input placeholder='' disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item className='mb-0' name='role' label='Role'>
                                        <Input placeholder='' disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item className='mb-0' name='companyName' label='Company Name'>
                                        <Input placeholder='' disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Popover open={showPasswordAssistance} content={<PopoverContent />}>
                                        <Form.Item
                                            className='mb-0'
                                            name='password'
                                            label='Password'
                                            labelAlign='left'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your password!',
                                                },
                                                () => ({
                                                    validator(_, value) {
                                                        const isPasswordValid = Object.values(
                                                            passwordValidation(value || ''),
                                                        ).reduce((result, current) => current && result, true)
                                                        console.log({ isPasswordValid })

                                                        if (!value || isPasswordValid) {
                                                            return Promise.resolve()
                                                        }
                                                        return Promise.reject(
                                                            new Error('Please enter a strong password!'),
                                                        )
                                                    },
                                                }),
                                            ]}
                                            hasFeedback
                                            required
                                        >
                                            <Input.Password
                                                onFocus={() => setShowPasswordAssistance(true)}
                                                onBlur={() => setShowPasswordAssistance(false)}
                                                placeholder=''
                                            />
                                        </Form.Item>
                                    </Popover>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        className='mb-0'
                                        name='confirmPassword'
                                        label='Confirm Password'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your password!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve()
                                                    }
                                                    return Promise.reject(
                                                        new Error('The new password that you entered do not match!'),
                                                    )
                                                },
                                            }),
                                        ]}
                                        required
                                        hasFeedback
                                    >
                                        <Input.Password placeholder='' />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        className='mb-0'
                                        name='phoneNumber'
                                        label='Phone Number'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your phone number!',
                                            },
                                            {
                                                min: 10,
                                                message: 'Please enter a valid phone number!',
                                            },
                                        ]}
                                        required
                                    >
                                        <Input
                                            placeholder=''
                                            onChange={(e) =>
                                                form.setFieldValue(
                                                    'phoneNumber',
                                                    e.target.value.replace(/[^0-9]/g, '').slice(0, 10),
                                                )
                                            }
                                            prefix={'+91'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className='mt-2' span={24}>
                                    <Form.Item className='mb-0'>
                                        <Button className='w-full' variant='primary' htmlType='submit'>
                                            Signup
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            ) : (
                <Alert
                    className=' w-3/4 m-auto'
                    banner
                    type='error'
                    showIcon
                    message={
                        <span className=' text-red-500 text-sm font-semibold text-center'>
                            Link expired, please request another invite.
                        </span>
                    }
                />
            )}
        </LoginLayout>
    )
}

export default InvitationForm
