import { Layout, Row, Col, message } from 'gokwik-ui-kit'

import BasicPlanCardCod from './upsell-flow/basicPlanCod';
import AdvancePlanCard from './upsell-flow/advancePlan';
import whiteStars from '@library/images/icons/whiteStars.svg';
import { makeAPICall } from '@gokwik/utilities';
import APIEndPoints from '@library/utilities/constants/apiEndpoints';
import { useSelector } from 'react-redux';
import { getMerchantDetails } from '@store/user/selectors'
import { useAppDispatch } from '@library/utilities/hooks';
import { navigateToUrl } from 'single-spa';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchUserDetails } from '@store/user';
const { Content } = Layout;


export default function UpgradePlan() {
    const merchantDetails = useSelector(getMerchantDetails)
     const [messageApi, contextHolder] = message.useMessage()
    const [showActivationModal, setShowActivationModal] = useState(false);
    const location = useLocation()
    const dispatch = useAppDispatch()

    const updateAndNavigateTokyc = async (status, selectedPlans = []) => {
        try {
            let payload =  { current_onboarding_status: status, gokwik_products: selectedPlans } 
            const response = await makeAPICall({
                method: 'post',
                url:
                    process.env.REACT_APP_BASE_URL +
                    APIEndPoints.updateMerchantOnboardingStatus +
                    merchantDetails?.m_id,
                payload
            })
            if (response?.data?.status_code === 200) {
                if (location?.state?.merchantType === 'kwik_cod') {
                    window.location.pathname = '/onboarding/kyc'
                }
            } else {
                messageApi.open({
                    type: 'error',
                    content: 'Something went wrong. Try Later !',
                })
            }
        } catch (error) {
            console.log(error)
        }
        dispatch(fetchUserDetails())
        window.location.pathname = '/onboarding/kyc'
    }

    return (
        <Layout className='pt-16'>
            <Content>
                <Row gutter={16} style={{ border: '4px solid #0B1B2B', margin: "0px" }}>
                    <Col xs={24} sm={24} md={24} lg={24} >
                        <Row style={{ margin: "0px" }} gutter={16}>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <BasicPlanCardCod />
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', height: "3.5rem", background: "rgba(0, 75, 141, 1)", padding: '8px 16px', borderRadius: '10px 10px 0px 0px', margin: '8px' }}>
                                    <img src={whiteStars} style={{ marginBottom: "1rem", marginRight: "0.5rem" }} />
                                    <span style={{ fontWeight: 500, color: '#fff', marginBottom: "1rem" }}>Recommended</span>
                                </div>
                                <AdvancePlanCard updateMerchantOnboardingStatus={updateAndNavigateTokyc} showActivationModal={showActivationModal} setShowActivationModal={setShowActivationModal} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}
