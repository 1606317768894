import { message } from 'gokwik-ui-kit';
import { makeAPICall } from '@gokwik/utilities';
import APIEndPoints from '@library/utilities/constants/apiEndpoints';

export const sendMerchantOnboardingDetails = async (
        payload: any, 
        successMsg: string, 
        errMsg: string, 
        componentData: { setIssueDescription?: (desc: string) => void; onClose?: () => void; updateStatus?: () => void },
    ) => {
    const {setIssueDescription, onClose, updateStatus} = componentData
    try {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.sendOnboardingEmails,
            payload,
        })
        if (response.data.status_code === 200) {
            if (typeof updateStatus === "function") {
                updateStatus();
            } else if (!successMsg) {
                if (typeof setIssueDescription === "function") {
                    setIssueDescription("");
                }
                if (typeof onClose === "function") {
                    onClose();
                }
            } else {
                message.success(successMsg);
            }
        } else {
            message.error(errMsg)
        }
    } catch (error) {
        console.error('Error:', error)
        message.error(errMsg)
    }
}

export const updateMerchantOnboardingCheckoutConfigsStatus = async (status: string, callback: Function, merchantDetails: any) => {
    try {
        let payload = { current_onboarding_status: status }
        const response = await makeAPICall({
            method: 'post',
            url:
                process.env.REACT_APP_BASE_URL +
                APIEndPoints.updateMerchantOnboardingStatus +
                merchantDetails?.m_id,
            payload
        })
        if (response?.data?.status_code === 200 && status) {
            if (typeof callback === "function") {
                callback();
            }
        } else {
            message.error('Error occured updating onboarding status')
        }
    } catch (error) {
        console.log(error)
    }
}

export const fetchBillingSubscription = async (callback: any) => {
    try {
        let payload = { app: "kwik_checkout" }
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.fetchBillingSubscription,
            payload
        })
        if (response?.data?.status_code === 200 && response?.data?.data?.confirmationUrl) {
            callback(response?.data?.data?.confirmationUrl);
        } else {
            message.error('Error occured while Approving Charges')
        }
    } catch (error) {
        console.log(error)
    }
}

export const fetchBillingStatus = async (callback: any, errorMessage?: string) => {
    try {
        const response = await makeAPICall({
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}${APIEndPoints.fetchBillingStatus}?app=kwik_checkout`,
        })

        if (response?.data?.status_code === 200 && response?.data?.data?.status) {
            callback(response?.data?.data?.status);
        } else {
            if (errorMessage) {
                message.error(errorMessage)
            }
        }
    } catch (error) {
        console.log(error)
    }
}

export const formatDateString = (rawDate) => {
    if (!rawDate) {
        return ''
    }
    const date = new Date(rawDate)
    const formattedDate = date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
    })
    return formattedDate
}
