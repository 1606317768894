import IncorporationCertificateImage from '@library/images/common/kyc/incorporation-certificate.svg'
import PanCardImage from '@library/images/common/kyc/pan-card.svg'
import DocumentBack from '@library/images/common/kyc/back-side-doc.svg'
import AadharFront from '@library/images/common/kyc/aadhar-front.svg'
import AadharBack from '@library/images/common/kyc/aadhar-back.svg'
import BoardResolution from '@library/images/common/kyc/board-resolution.svg'
import FassaiLicence from '@library/images/common/kyc/fassai.svg'
import AyurvedicLicence from '@library/images/common/kyc/ayurvedic-licence.svg'
import CancelledCheque from '@library/images/common/kyc/cancelled-cheque.svg'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { handleError } from '@library/utilities/helpers/handleError'
import {
    Alert,
    AlertOutlined,
    Button,
    CheckCircleFilled,
    CheckOutlined,
    Col,
    EyeFilled,
    message,
    Modal,
    Row,
    Spin,
    Tag,
    Tooltip,
    VideoCameraOutlined,
} from 'gokwik-ui-kit'
import { navigateToUrl } from 'single-spa'
import { stepConfiguration } from '../stepsConfig'
import AadharFrontSample from '@library/images/common/kyc/aadhaar-front-sample.svg'
import AadharBackSample from '@library/images/common/kyc/aadhaar-back-sample.svg'
import PanSample from '@library/images/common/kyc/pan-front-sample.svg'
import FssaiSample from '@library/images/common/kyc/fssai-license-sample.svg'
import CinSample from '@library/images/common/kyc/certificate-of-incorporation-sample.svg'
import GstRegSample from '@library/images/common/kyc/gst-certificate-sample.svg'
import AyushLicenseSample from '@library/images/common/kyc/ayush-license-sample.svg'
import BrSample from '@library/images/common/kyc/board-resolution-certificate-sample.svg'
import CancelledChequeSample from '@library/images/common/kyc/cancelled-cheque-sample.svg'
import { useState } from 'react'
import DocumentAnalysis from './kyc-form/kyc-steps/documents-upload/OCRResult'
import dayjs from 'dayjs'

export const getPreviewImageForDoc = (doc) => {
    switch (doc) {
        case 'pan_card':
            return PanCardImage
        case 'aus_pan_card':
            return PanCardImage
        case 'aus_board_resolution_authorizing':
            return BoardResolution
        case 'business_registration_proof':
            return IncorporationCertificateImage
        case 'aus_aadhar_card_front_page':
            return AadharFront
        case 'aus_aadhar_card_back_page':
            return AadharBack
        case 'fssai_license':
            return FassaiLicence
        case 'ayurvedic_license':
            return AyurvedicLicence
        case 'bank_proof':
            return CancelledCheque
        default:
            return DocumentBack
    }
}

export const checkCurrentStatus = (step) => {
    switch (step) {
        case 0:
            return 'kyc_basic_info_added'
        case 1:
            return 'kyc_address_details'
        case 2:
            return 'kyc_bank_details'
        case 3:
            return 'kyc_business_details'
        case 4:
            return 'kyc_document_upload'
        case 5:
            return 'kyc_vpc'
        case 6:
            return 'kyc_verification_pending'
        case 7:
            return 'kyc_verification_failed'
        case 8:
            return 'kyc_completed'
        default:
            return 'Unknown Status' // Default case if none of the above matches
    }
}

export const getStepFromStatus = (status) => {
    switch (status) {
        case 'kyc_basic_info_added':
            return 0
        case 'kyc_address_details':
            return 1
        case 'kyc_bank_details':
            return 2
        case 'kyc_business_details':
            return 3
        case 'kyc_document_upload':
            return 4
        case 'kyc_vpc':
            return 5
        case 'kyc_verification_pending':
            return 6
        case 'kyc_verification_failed':
            return 7
        case 'kyc_completed':
            return 8
        default:
            return -1 // Default case if none of the above matches
    }
}

export const beforeUpload = (item, file, form) => {
    const isLt5M = file.size / 1024 / 1024 < 5
    const uploadedMimeType = file?.type
    const uploadFormat = uploadedMimeType.split('/')[1]
    const allowedFileTypes = item?.other_details?.allowed_format?.split(', ')
    const isAllowedFormat = allowedFileTypes?.includes(uploadFormat)

    if (!isAllowedFormat && allowedFileTypes !== undefined) {
        form.resetFields([item.field])
        message.error(`You can only upload files of types: ${allowedFileTypes}`)
        return false
    } else if (!isLt5M) {
        message.error('Image must smaller than 5MB!')
        return false
    }
    return true
}

export const updateKycDoc = async (name, setBankProofUploaded, getDocumentList) => {
    try {
        await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateKycDocument,
            payload: {
                file_name: name,
            },
        }).then((res) => {
            if (res.data.status_code === 200) {
                message.success(res.data?.data || 'Document uploaded successfully')
                if (name === 'bank_proof' && setBankProofUploaded) {
                    setBankProofUploaded(true)
                }

                if (getDocumentList) {
                    getDocumentList()
                }
            }
        })
    } catch (err) {
        handleError(err)
    }
}

export const customRequest = async (
    { file, onSuccess, onError },
    item,
    form,
    setBankProofUploaded,
    getDocumentList,
) => {
    // onSuccess('ok')

    if (!beforeUpload(item, file, form)) {
        onError('ok')
        form.resetFields([item.field])
        return
    }
    // Upload file
    await fetch(item.upload_url, {
        method: 'PUT',
        body: file,
    }).then((res) => {
        if (res?.status === 200) {
            onSuccess('ok')
            updateKycDoc(item.name, setBankProofUploaded, getDocumentList)
        } else {
            message.error('Upload failed ! Please retry.')
            onError('ok')
            form.resetFields([item.field])
            return
        }
    })
}

export const getKycDoc = async (name, setVisible, setDocumentUrl) => {
    await makeAPICall({
        method: 'post',
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.getKycDocument,
        payload: {
            file_name: name,
        },
    }).then((res) => {
        const downloadUrl = res.data.data.download_url
        setDocumentUrl(downloadUrl)
        setVisible(true)
    })
}

// Component to display the document in a modal
export const DocumentModal = ({ visible, onClose, documentUrl, documentName }) => {
    const handleDownload = () => {
        const link = document.createElement('a')
        link.href = documentUrl
        link.download = documentName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <Modal title='Document Preview' visible={visible} onCancel={onClose} footer={null} width={800}>
            <iframe
                src={documentUrl}
                style={{ width: '100%', height: '500px', border: 'none' }}
                title='Document Preview'
            />

            <div className='mt-2'>
                <Button variant='primary' onClick={handleDownload}>
                    Download
                </Button>
            </div>
        </Modal>
    )
}

export const UploadSuccessSVG = () => {
    return (
        //@ts-ignore
        <svg width='60' height='70' viewBox='0 0 60 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g filter='url(#filter0_d_10416_51803)'>
                <rect x='7' y='6' width='46' height='48' rx='6' fill='#F5F5F5' shape-rendering='crispEdges' />
                <rect
                    x='7.5'
                    y='6.5'
                    width='45'
                    height='47'
                    rx='5.5'
                    stroke='black'
                    stroke-opacity='0.15'
                    shape-rendering='crispEdges'
                />
                <g filter='url(#filter1_ddd_10416_51803)'>
                    <rect x='16' y='11' width='28' height='38' rx='4' fill='white' />
                    <rect x='20' y='15' width='6' height='6' rx='2' fill='#EBEBEB' />
                    <rect x='20' y='25' width='20' height='2' rx='1' fill='#EBEBEB' />
                    <rect x='20' y='29' width='20' height='2' rx='1' fill='#EBEBEB' />
                    <rect x='20' y='33' width='20' height='2' rx='1' fill='#EBEBEB' />
                    <rect x='20' y='39' width='14' height='2' rx='1' fill='#EBEBEB' />
                    <rect x='20' y='43' width='6' height='2' rx='1' fill='#EBEBEB' />
                </g>
            </g>
            <defs>
                <filter
                    id='filter0_d_10416_51803'
                    x='7'
                    y='6'
                    width='46'
                    height='50'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset dy='2' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0' />
                    <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_10416_51803' />
                    <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_10416_51803' result='shape' />
                </filter>
                <filter
                    id='filter1_ddd_10416_51803'
                    x='0'
                    y='0'
                    width='60'
                    height='70'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feMorphology
                        radius='4'
                        operator='dilate'
                        in='SourceAlpha'
                        result='effect1_dropShadow_10416_51803'
                    />
                    <feOffset dy='5' />
                    <feGaussianBlur stdDeviation='6' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0' />
                    <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_10416_51803' />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset dy='3' />
                    <feGaussianBlur stdDeviation='3' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0' />
                    <feBlend
                        mode='normal'
                        in2='effect1_dropShadow_10416_51803'
                        result='effect2_dropShadow_10416_51803'
                    />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feMorphology
                        radius='2'
                        operator='erode'
                        in='SourceAlpha'
                        result='effect3_dropShadow_10416_51803'
                    />
                    <feOffset dy='1' />
                    <feGaussianBlur stdDeviation='1' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0' />
                    <feBlend
                        mode='normal'
                        in2='effect2_dropShadow_10416_51803'
                        result='effect3_dropShadow_10416_51803'
                    />
                    <feBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_10416_51803' result='shape' />
                </filter>
            </defs>
        </svg>
    )
}

export const UploadedFileComponent = ({
    item,
    handleDelete,
    itemName,
    text,
    disableDeleteBtn,
    loadingAI,
    inputImageName,
    ocrResult,
}) => {
    const [visible, setVisible] = useState(false)
    const [documentUrl, setDocumentUrl] = useState('')

    const handlePreview = (name) => {
        getKycDoc(name, setVisible, setDocumentUrl)
    }

    return (
        <Row className='border border-[#00000026] border-solid rounded-md p-2 m-2 max-w-max'>
            <DocumentModal
                visible={visible}
                onClose={() => setVisible(false)}
                documentUrl={documentUrl}
                documentName='document_name'
            />
            <Col span={24}>
                <Row align={'middle'}>
                    <Col span={12}>
                        <div className='flex gap-1 items-center'>
                            <UploadSuccessSVG />

                            <Tooltip title={text}>
                                <div className='truncate'>{text === 'PAN' ? `PAN Card   (aus pan)` : text}</div>
                            </Tooltip>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className='flex gap-1 items-center'>
                            <Button variant='default' onClick={() => handlePreview(itemName)}>
                                <EyeFilled /> Preview
                            </Button>
                            <Button variant='default' onClick={() => handleDelete(item)} disabled={disableDeleteBtn}>
                                X
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row gutter={12} align={'middle'}>
                    <Col span={22}>
                        <div className='h-2 bg-[#52C41A] rounded-full shadow-md'></div>
                    </Col>
                    <Col span={2}>
                        <CheckCircleFilled className='text-[#52C41A]' />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <span className='text-[#52C41A]'>Uploaded Successfully</span>
                    </Col>
                    {inputImageName && ocrResult && (
                        <Col span={24}>
                            {loadingAI && inputImageName === item?.name ? (
                                <>Generating document acceptance score from AI: {<Spin />}</>
                            ) : (
                                <>
                                    {ocrResult?.map((i, idx) => {
                                        return (
                                            item?.name === i?.imageName &&
                                            i?.aiResponse && <DocumentAnalysis key={idx} response={i?.aiResponse} />
                                        )
                                    })}
                                </>
                            )}
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    )
}

const document_upload_mapped_to_entity_type = {
    '5': {
        address_type: 'Address Type',
        pan_card: 'PAN card of HUF',
        address_proof_front_page: 'GST Certificate of the Entity(Front)',
        address_proof_back_page: 'GST Certificate of the Entity(Back)',
        business_registration_proof: 'HUF Deed',
        pan_of_karta: 'PAN of Karta',
        aus_aadhar_card_front_page: 'Aadhaar Card (Front)',
        aus_aadhar_card_back_page: 'Aadhaar Card (Back)',
        sell_edible_food_products: 'Do You Sell Edible Food Products?',
        sell_ayurvedic_products: 'Do You Sell Ayurvedic Products?',
        fssai_license: 'FSSAI License',
        ayurvedic_license: 'Ayurvedic License',
        kyc: 'KYC Error',
        bis_license: 'BIS Certificate',
    },
    '7': {
        address_type: 'Address Type',
        pan_card: 'Business PAN',
        address_proof_front_page: 'GST Certificate of the Entity(Front)',
        address_proof_back_page: 'GST Certificate of the Entity(Back)',
        business_registration_proof: 'Certificate of Incorporation',
        aus_board_resolution_authorizing: 'Board Resolution',
        aus_pan_card: 'PAN (Authorised Signatory)',
        aus_aadhar_card_front_page: 'Aadhaar Card (Front)',
        aus_aadhar_card_back_page: 'Aadhaar Card (Back)',
        sell_edible_food_products: 'Do You Sell Edible Food Products?',
        sell_ayurvedic_products: 'Do You Sell Ayurvedic Products?',
        fssai_license: 'FSSAI License',
        ayurvedic_license: 'Ayurvedic License',
        bis_license: 'BIS Certificate',
    },
    '9': {
        address_type: 'Address Type',
        pan_card: 'Business PAN',
        address_proof_front_page: 'Address Proof (Front)',
        address_proof_back_page: 'Address Proof (Back)',
        business_registration_proof: 'Business Deed',
        aus_pan_card: 'PAN (Authorised Signatory)',
        aus_aadhar_card_front_page: 'Aadhaar Card (Front)',
        aus_aadhar_card_back_page: 'Aadhaar Card (Back)',
        sell_edible_food_products: 'Do You Sell Edible Food Products?',
        sell_ayurvedic_products: 'Do You Sell Ayurvedic Products?',
        fssai_license: 'FSSAI License',
        ayurvedic_license: 'Ayurvedic License',
        bis_license: 'BIS Certificate',
    },
    '10': {
        address_type: 'Address Type',
        pan_card: 'PAN',
        address_proof_front_page: 'Address Proof (Front)',
        address_proof_back_page: 'Address Proof (Back)',
        business_registration_proof: 'GST Certificate of the Entity',
        aus_aadhar_card_front_page: 'Aadhaar Card (Front)',
        aus_aadhar_card_back_page: 'Aadhaar Card (Back)',
        sell_edible_food_products: 'Do You Sell Edible Food Products?',
        sell_ayurvedic_products: 'Do You Sell Ayurvedic Products?',
        fssai_license: 'FSSAI License',
        ayurvedic_license: 'Ayurvedic License',
        bis_license: 'BIS Certificate',
    },
    '11': {
        address_type: 'Address Type',
        pan_card: 'Business PAN',
        address_proof_front_page: 'GST Certificate of the Entity(Front)',
        address_proof_back_page: 'GST Certificate of the Entity(Back)',
        aus_board_resolution_authorizing: 'Board Resolution',
        business_registration_proof: 'Certificate of Incorporation',
        aus_pan_card: 'PAN (Authorised Signatory)',
        aus_aadhar_card_front_page: 'Aadhaar Card (Front)',
        aus_aadhar_card_back_page: 'Aadhaar Card (Back)',
        sell_edible_food_products: 'Do You Sell Edible Food Products?',
        sell_ayurvedic_products: 'Do You Sell Ayurvedic Products?',
        fssai_license: 'FSSAI License',
        ayurvedic_license: 'Ayurvedic License',
        bis_license: 'BIS Certificate',
    },
}

export const kycFieldsMap = (userData) => {
    return {
        basic_info: {
            website: 'Website URL',
            // business_name: 'Brand Name',
            business_category: 'Business Category',
            entity_type: 'Registration Type',
        },
        address_details: {
            business_address: 'Business Address',
            pincode: 'Pincode',
            city: 'City',
            state: 'State',
        },
        bank_details: {
            account_holder_name: 'Account Holder Name',
            account_number: 'Account Number',
            bank_name: 'Bank Name',
            branch_name: 'Branch Name',
            ifsc_code: 'IFSC Code',
            bank_proof: 'Bank Proof',
        },
        business_details: {
            business_name: 'Business Name',
            business_pan: 'Business PAN',
            pan_registration_date: 'Business PAN Registration Date',
            aus_pan: 'PAN (Authorised Signatory)',
            authorized_signatory_name: 'Authorized Signatory Name',
            gstin: 'GSTIN',
        },
        document_upload: {
            ...document_upload_mapped_to_entity_type[userData?.kycData?.entity_type],
        },

        kyc_vpc: {
            cpv_error: 'Video KYC required',
        },
    }
}

export const handleErrorMapping = (userData) => {
    const kycErrors = userData?.kycData?.kyc_failure_reasons || {}
    const errorMap = {}

    // Iterate over each section in kycFieldsMap
    for (const [section, fields] of Object.entries(kycFieldsMap(userData))) {
        for (const [fieldKey, fieldName] of Object.entries(fields)) {
            if (kycErrors[fieldKey]) {
                // Map errors to the specific field
                errorMap[fieldKey] = kycErrors[fieldKey]
            }
        }
    }

    return errorMap
}

export const ErrorChips = ({ errors, sectionFields }) => {
    return (
        <div className='error-chips'>
            {Object.keys(errors)
                .filter((field) => sectionFields.includes(field))
                .map((field, index) => (
                    <Tag color='red' key={index} className='mr-2 mb-2'>
                        {field}
                    </Tag>
                ))}
        </div>
    )
}

export const getFormStatusConfigs = (userData, formFields, sectionName) => {
    const generateErrorChips = () => {
        const findExistingKeys = (keys, obj) => {
            return keys.filter((key) => key in obj).map((key) => kycFieldsMap(userData)?.[sectionName]?.[key])
        }
        const fields = Object.keys(kycFieldsMap(userData)[sectionName] || {})
        const errors = findExistingKeys(fields, userData?.kycData?.kyc_failure_reasons)

        return userData?.merchantDetails?.onboarding_status?.kwik_checkout_app === 'kyc_verification_failed'
            ? errors
            : []
    }

    const updateConfigWithErrors = () => {
        const kycFailureReasonsMap = userData?.kycData?.kyc_failure_reasons
        const updatedConfig = formFields?.map((config) => {
            if (
                kycFailureReasonsMap[config.name] &&
                userData?.merchantDetails?.onboarding_status?.kwik_checkout_app === 'kyc_verification_failed'
            ) {
                return {
                    ...config,
                    validateStatus: 'error',
                    help: Array.isArray(kycFailureReasonsMap[config.name])
                        ? kycFailureReasonsMap[config.name][0]
                        : kycFailureReasonsMap[config.name],
                }
            }
            return config
        })

        return updatedConfig
    }

    return {
        generateErrorChips,
        updateConfigWithErrors,
    }
}

export const handleFieldChange = (changedFields, formFields, setFields) => {
    const updatedConfig = formFields.map((config) => {
        const changedField = changedFields.find((field) => field.name[0] === config.name)
        if (changedField) {
            return {
                ...config,
                validateStatus: null,
                help: '',
            }
        }
        return config
    })
    setFields(updatedConfig)
}

export const sectionWiseNonErrorStatusChips = (
    stepConfiguration,
    current_onboarding_status,
    isCPVMandatory,
    dynamicErrors = {},
) => {
    return {
        basic_info: (
            <>
                {stepConfiguration[current_onboarding_status]?.id >= 8 ? (
                    <Tag className='p-0.5 w-auto justify-center items-center !border-[#079455]' variant='success'>
                        Completed
                    </Tag>
                ) : (
                    <Tag className='p-0.5 w-auto justify-center items-center !border-[#faad14]' variant='warning'>
                        Pending
                    </Tag>
                )}
            </>
        ),
        address_details: (
            <>
                {stepConfiguration[current_onboarding_status]?.id >= 9 ? (
                    <Tag variant='success'>Completed</Tag>
                ) : (
                    <Tag variant='warning'>Pending</Tag>
                )}
            </>
        ),
        bank_details: (
            <>
                {stepConfiguration[current_onboarding_status]?.id >= 10 ? (
                    <Tag variant='success'>Completed</Tag>
                ) : (
                    <Tag variant='warning'>Pending</Tag>
                )}
            </>
        ),
        business_details: (
            <>
                {stepConfiguration[current_onboarding_status]?.id >= 11 ? (
                    <Tag variant='success'>Completed</Tag>
                ) : (
                    <Tag variant='warning'>Pending</Tag>
                )}
            </>
        ),
        document_upload: (
            <>
                {stepConfiguration[current_onboarding_status]?.id >= 12 ? (
                    <Tag variant='success'>Completed</Tag>
                ) : (
                    <Tag variant='warning'>Pending</Tag>
                )}
            </>
        ),
        kyc_vpc: (
            <>
                {stepConfiguration[current_onboarding_status]?.id >= 13 && !isCPVMandatory ? (
                    <Tag variant='success'>Completed</Tag>
                ) : (
                    <Tag variant='warning'>Pending</Tag>
                )}
            </>
        ),
    }
}

export const CollapsePanelStatusChips = ({
    errors,
    stepConfiguration,
    current_onboarding_status,
    sectionName,
    isCPVMandatory = false,
    dynamicErrors = {},
}) => {
    return (
        <>
            {Array.isArray(errors) && errors?.length && current_onboarding_status === 'kyc_verification_failed' ? (
                <>
                    {errors?.map((item, i) => (
                        <Tag
                            key={i}
                            variant='error'
                            className='p-0.5 w-auto justify-center items-center !border-[#d92d20]'
                        >
                            <span className='capitalize'>{item}</span>
                        </Tag>
                    ))}
                </>
            ) : (
                <>
                    {
                        sectionWiseNonErrorStatusChips(
                            stepConfiguration,
                            current_onboarding_status,
                            isCPVMandatory,
                            dynamicErrors,
                        )[sectionName]
                    }
                </>
            )}
        </>
    )
}

export const stepperStatusChipDot = (
    userData,
    sectionName,
    stepConfiguration,
    current_onboarding_status,
    isCPVMandatory,
) => {
    const generateErrorChips = () => {
        const findExistingKeys = (keys, obj) => {
            return keys.filter((key) => key in obj)
        }
        const fields = Object.keys(kycFieldsMap(userData)[sectionName] || {})
        const errors = findExistingKeys(fields, userData?.kycData?.kyc_failure_reasons)

        return errors
    }

    return {
        chip: (
            <>
                {generateErrorChips()?.length > 0 && current_onboarding_status === 'kyc_verification_failed' ? (
                    <Tag variant='error'>Error</Tag>
                ) : (
                    <>
                        {
                            sectionWiseNonErrorStatusChips(
                                stepConfiguration,
                                current_onboarding_status,
                                isCPVMandatory,
                            )[sectionName]
                        }
                    </>
                )}
            </>
        ),
        dot:
            generateErrorChips()?.length > 0 && current_onboarding_status === 'kyc_verification_failed' ? (
                <div className='w-2 h-2 bg-red-400 rounded-full'></div>
            ) : (
                <CheckOutlined className='text-green-400 text-base' />
            ),
    }
}

export const nameMap = {
    basic_info: { name: 'Basic Information', step: 0 },
    address_details: { name: 'Business Address', step: 1 },
    bank_details: { name: 'Bank Details', step: 2 },
    business_details: { name: 'Business Details', step: 3 },
    document_upload: { name: 'Document Upload', step: 4 },
    kyc_vpc: { name: 'Contact Point Verification', step: 5 },
}

function findErrors(kycFailureReason, errorMap) {
    const result = []

    for (const section in errorMap) {
        const errors = []
        for (const key in kycFailureReason) {
            if (errorMap[section].hasOwnProperty(key)) {
                errors.push(key)
            }
        }
        if (errors.length > 0) {
            result.push({
                section: nameMap[section].name,
                error: errors,
                stepNumber: nameMap[section].step,
            })
        }
    }

    return result
}

const errorMeaningMap = {
    entity_type: 'Entity Type',
    business_name: 'Business Name',
    email: 'Email Address',
    contact_number: 'Contact Number',
    website: 'Website URL',
    business_address: 'Business Address',
    pincode: 'Pincode',
    city: 'City',
    state: 'State',
    account_holder_name: 'Account Holder Name',
    account_number: 'Account Number',
    ifsc_code: 'IFSC Code',
    bank_name: 'Bank Name',
    branch_name: 'Branch Name',
    bank_proof: 'Bank Proof Document',
    business_pan: 'Business PAN',
    aus_pan: 'PAN (Authorised Signatory)',
    gstin: 'GSTIN',
    address_proof_front_page: 'Address Proof (Front)',
    address_proof_back_page: 'Address Proof (Back)',
    aus_pan_card: 'PAN (Authorised Signatory)',
    business_registration_proof: 'Business Registration Proof',
    aus_aadhar_card_front_page: 'Aadhaar Card (Front)',
    aus_aadhar_card_back_page: 'Aadhaar Card (Back)',
    fssai_license: 'FSSAI License',
    ayurvedic_license: 'Ayurvedic License',
    additional_document_3: 'Additional Document 3',
    aus_board_resolution_authorizing: 'Board Resolution',
    incurable_kyc_error: 'KYC Error (Incurable)',
    bis_license: 'BIS Certificate',
}

export const getKYCStatusAlert = (
    undeterminedKycErrorState,
    kycFailureReason,
    current_onboarding_status,
    userData,
    errorMap,
    openStepFn,
    withdrawKYC,
) => {
    const openStepNumber = (step, openStepFn, section) => {
        openStepFn(step)
        scrollToText(section)
    }

    const stepId = stepConfiguration[current_onboarding_status]?.id
    if (
        stepId >= stepConfiguration['kyc_completed']?.id &&
        stepId !== stepConfiguration['kyc_verification_pending']?.id &&
        stepId !== stepConfiguration['kyc_verification_failed']?.id
    ) {
        return (
            <Alert
                className='p-4'
                message={<span className='text-[#084426] text-sm font-semibold'>KYC Approved</span>}
                description={
                    <span className='text-[#084426] text-sm font-normal'>
                        You're all set. Thank you for the sign-up and document submissions. Our team will reach out to
                        you in next 5 working days to activate checkout.&nbsp;
                        <Button variant='default' onClick={() => navigateToUrl('/shopify-app/settings')}>
                            Go to homepage
                        </Button>
                    </span>
                }
                type='success'
                icon={<CheckCircleFilled />}
                showIcon
            />
        )
    } else if (stepId === stepConfiguration['kyc_verification_failed']?.id) {
        const isIncurable =
            current_onboarding_status === 'kyc_verification_failed' &&
            userData?.kycData?.kyc_failure_reasons?.hasOwnProperty('incurable_kyc_error')
        const kycFailureReasonMap = findErrors(kycFailureReason, errorMap(userData))

        return (
            <>
                {isIncurable && (
                    <Alert
                        message={
                            <span className=''>
                                Incurable Error: <b>{userData?.kycData?.kyc_failure_reasons?.incurable_kyc_error}</b>.
                                Please reach out to{' '}
                                <a
                                    href='mailto:support@gokwik.co?subject=GoKwik KYC verification Failed&body=I have encountered an incurable error in my KYC. Please help.'
                                    target='_blank'
                                >
                                    support@gokwik.co
                                </a>{' '}
                                for further assistance.
                            </span>
                        }
                        icon={<AlertOutlined />}
                        type='error'
                        showIcon
                        className='w-full text-sm text-red-700 p-0.5 pl-2'
                    />
                )}
                <br />
                {undeterminedKycErrorState && (
                    <Alert
                        message={<span className='text-[#FF2618] text-sm font-semibold'>{'Attention Required'}</span>}
                        description={
                            <span className='text-red-400'>
                                <span>
                                    <p>{userData?.kycData?.kyc_rejection_reason}</p>
                                </span>
                            </span>
                        }
                        icon={<AlertOutlined />}
                        type='error'
                        showIcon
                        className='w-full text-sm text-red-700 p-0.5 pl-2'
                    />
                )}
                {kycFailureReasonMap?.map((item, i) => {
                    return (
                        <div key={i} className='my-2'>
                            <Alert
                                message={
                                    item?.error?.includes('cpv_error') ? (
                                        <>
                                            Your{' '}
                                            <span
                                                className='underline cursor-pointer font-bold'
                                                onClick={() =>
                                                    openStepNumber(item?.stepNumber, openStepFn, item?.section)
                                                }
                                            >
                                                CPV submission
                                            </span>{' '}
                                            was rejected. Please re-submit to proceed.
                                        </>
                                    ) : (
                                        <>
                                            Incorrect <b>{item?.error?.map((i) => errorMeaningMap?.[i])?.join(', ')}</b>{' '}
                                            in{' '}
                                            <span
                                                className='underline cursor-pointer'
                                                onClick={() =>
                                                    openStepNumber(item?.stepNumber, openStepFn, item?.section)
                                                }
                                            >
                                                {item?.section}
                                            </span>{' '}
                                            section
                                        </>
                                    )
                                }
                                icon={<AlertOutlined />}
                                type='error'
                                showIcon
                                className='w-full text-sm text-red-700 p-0.5 pl-2'
                            />
                        </div>
                    )
                })}
            </>
        )
    } else if (stepId === stepConfiguration['kyc_verification_pending']?.id && userData?.kycData?.is_cpv_mandatory) {
        return (
            <Alert
                className='w-full p-2'
                message={<span className='text-sm font-semibold text-[#004B8D] '>Your KYC has been submitted!</span>}
                type={'info'}
                showIcon
            />
        )
    } else if (stepId === stepConfiguration['kyc_verification_pending']?.id && !userData?.kycData?.is_cpv_mandatory) {
        return (
            <Alert
                className='p-4'
                message={<span className='text-[#004B8D] text-sm font-semibold'>Application in Progress</span>}
                description={
                    <div className='flex gap-2 items-center'>
                        <div className='text-[#004B8D] text-[13px] font-normal'>
                            You’re all set! Thank you for the sign-up and document submissions. You will receive timely
                            updates on the approval of your application via email.{' '}
                            <span className='font-semibold'>It can take upto 2 weeks to verify KYC</span>
                        </div>

                        {userData?.userDetails?.is_internal_user && (
                            <div>
                                <Button
                                    onClick={() => {
                                        withdrawKYC()
                                        openStepNumber(3, openStepFn, 'Business Details')
                                    }}
                                    variant='primary'
                                >
                                    Update Details
                                </Button>
                            </div>
                        )}
                    </div>
                }
                type='info'
                showIcon
            />
        )
    } else {
        return ''
    }
}

export const scrollToText = (textContent) => {
    const targetElement = Array.from(document.querySelectorAll('div')).find(
        (el) => el.textContent.trim() === textContent,
    )

    if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
}

export const getSampleDocument = (title) => {
    switch (title) {
        case 'aus_pan_card':
            return PanSample
        case 'business_registration_proof':
            return GstRegSample
        case 'aus_board_resolution_authorizing':
            return BrSample
        case 'aus_aadhar_card_front_page':
            return AadharFrontSample
        case 'aus_aadhar_card_back_page':
            return AadharBackSample
        case 'ayurvedic_license':
            return AyushLicenseSample
        case 'fssai_license':
            return FssaiSample
        case 'business_registration_proof':
            return CinSample
        case 'cancelled_cheque':
            return CancelledChequeSample
        default:
            return ''
    }
}

export const isIdCard = (src) =>
    ['aus_aadhar_card_front_page', 'aus_aadhar_card_back_page', 'aus_pan_card']?.includes(src)

export const getKYCDetails = async (setIsCpvMandatory, setKycData, setCurrentStep, setIsPending) => {
    try {
        let response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.userDetails,
            skipLoader: true,
        })

        if (response.success) {
            const userData = response.data.data

            if (userData?.kyc_details?.is_cpv_mandatory) {
                setIsCpvMandatory(true)
            } else {
                setIsCpvMandatory(false)
            }

            setKycData({
                ...userData?.kyc_details,
                ...(userData?.kyc_details?.business_pan_registration_date && {
                    business_pan_registration_date: dayjs(userData?.kyc_details?.business_pan_registration_date),
                }),
            })

            if (
                userData?.kyc_details?.kyc_rejection_reason &&
                userData?.merchant_details?.onboarding_status?.kwik_checkout_app === 'kyc_business_details'
            ) {
                setIsPending(true)
            }

            const stepNumberFromStatus = getStepFromStatus(
                userData?.merchant_details?.onboarding_status?.kwik_checkout_app,
            )

            setCurrentStep(stepNumberFromStatus + 1) // +1 because we want to open the next step on page load
        } else {
            console.error('Failed to fetch user details:', response.error)
        }
    } catch (error) {
        console.error('Error fetching KYC details:', error)
    }
}
