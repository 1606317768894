import { Button, message } from 'gokwik-ui-kit'
import CodOnboardingStep1 from './CodOnboardingStep1';
import CodOnboardingStep2 from './CodOnboardingStep2';
import CodOnboardingStep3 from './CodOnboardingStep3';
import { useState } from 'react';
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'

const CodStepsWrapper = ({ currentStep, setCurrentStep }) => {
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)
    const [expandedStep, setExpandedStep] = useState(currentStep)
    const [isCodAppInstall, setIsCodAppInstall] = useState(true)
    const [siteVisited, setSiteVisited] = useState(false)
    const handleNextStep = () => {
        setExpandedStep(currentStep);
    };
    const toggleStep = (step: number) => {
        setExpandedStep(step);
    }

    const getCodAppInstalledStatus = async () => {
        try {
            const response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.shopifyMerchantConfigs,
            })

            if (response?.data?.status_code === 200) {
                setIsCodAppInstall(response?.data?.data?.shopify_app?.gokwik_cash_on_delivery?.installed)
                return response?.data?.data?.shopify_app?.gokwik_cash_on_delivery?.installed;
            }
        } catch (error) {
            console.error('Error:', error)
            message.error('Error occured while fetching the cod app install')
        }
    }

    const handleInstallAppClick = () => {
        logEvent(
            siteVisited ? 'cod_module_clicked_again' : 'install_cod_module_clicked',
            'click',
            'cod-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { Step: 'cod 1' },
        )
        setSiteVisited(true)
        setIsCodAppInstall(true)
        window.open('https://apps.shopify.com/cash-on-delivery-by-gokwik', '_blank')
    }

    return (
        <div className='flex flex-col gap-4 h-[73vh] overflow-y-auto pb-2'>
            <CodOnboardingStep1
                onStepComplete={handleNextStep}
                setSiteVisited={setSiteVisited}
                siteVisited={siteVisited}
                handleInstallAppClick={handleInstallAppClick}
                getCodAppInstalledStatus={getCodAppInstalledStatus}
                currentStep={currentStep}
                expandedStep={expandedStep}
                toggleStep={toggleStep}
            />
            <CodOnboardingStep2
                onStepComplete={handleNextStep}
                currentStep={currentStep}
                expandedStep={expandedStep}
                toggleStep={toggleStep}
            />
            <CodOnboardingStep3
                onStepComplete={handleNextStep}
                currentStep={currentStep}
                expandedStep={expandedStep}
                toggleStep={toggleStep}
            />
            {!isCodAppInstall && (
                <div className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-50'>
                    <div className='bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center'>
                        <h2 className='text-lg font-bold text-red-600'>Installation Unsuccessful</h2>
                        <p className='text-gray-700 mt-2'>
                            Click on 'Install COD Module' to restart the installation process.
                        </p>
                        <Button
                            onClick={() => handleInstallAppClick()}
                            variant='primary'
                            className='mt-4 font-medium rounded-lg'
                        >
                            Install COD module
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default CodStepsWrapper
