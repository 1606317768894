import { makeAPICall } from '@gokwik/utilities'
import BannerTopImg from '@library/images/common/executive-summary-images/BannerTopImg'
import NewLaunchImg from '@library/images/common/executive-summary-images/NewLaunchImg'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getUserData } from '@store/user/selectors'
import { Button, CloseOutlined, message, Popconfirm } from 'gokwik-ui-kit'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const Banner = ({ bannerContent }) => {
    const userData = useSelector(getUserData)
    const [hideBanner, setHideBanner] = useState(
        false || sessionStorage?.getItem('smallBannerOpenState') === 'false' ? true : false,
    )
    const [open, setOpen] = useState(false)
    const onStay = (e, campaign_id) => {
        setOpen(false)
    }

    const recordInteraction = async (interaction_type, campaign_id) => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + '/api/dashboard/campaign/user-interaction',
            payload: {
                interaction_type,
                campaign_id: Number(campaign_id),
            },
        })

        if (response?.status === 200) {
            if (interaction_type === 'dismiss' || interaction_type === 'view') {
                sessionStorage?.setItem('smallBannerOpenState', 'false')
                setHideBanner(true)
            }
        }
    }

    const onDismiss = (e, campaign) => {
        const btnName = e?.target?.textContent

        if (campaign?.is_dismissed_permanently && btnName === 'Don’t show me again.') {
            recordInteraction('dismiss', campaign?.id)
        } else {
            recordInteraction('view', campaign?.id)
        }
        setOpen(false)
    }
    return (
        <div className={`p-3 bg-[#EBF6FF] rounded-lg flex gap-4 ${hideBanner && 'hidden'}`}>
            <div className='min-w-[7%]'>
                <BannerTopImg />
            </div>
            <div className='min-w-[86%]'>
                <div className='flex flex-col'>
                    <span className='mb-1'>
                        <NewLaunchImg />
                    </span>

                    <div>
                        <div className='font-semibold mb-1'>{bannerContent?.title}</div>
                        <div>{bannerContent?.description}</div>
                        <br />
                        <Button
                            variant='primary'
                            onClick={() => {
                                logEvent(
                                    'es_banner_cta_clicked',
                                    'click',
                                    'executive_summary',
                                    userData?.userDetails?.email,
                                    userData?.merchantDetails?.m_id,
                                    userData?.merchantDetails?.short_name,
                                    userData?.userDetails?.name,
                                    { ctaURL: bannerContent?.cta_url },
                                )
                                recordInteraction('click', bannerContent?.id)
                                window.open(bannerContent.cta_url, '_blank')
                            }}
                        >
                            {bannerContent?.cta_text}
                        </Button>
                    </div>
                </div>
            </div>

            <>
                <style>{`
                        .ant-popconfirm-buttons {
                            display: none;
                            pointer-events:none;
                        }
                `}</style>
                <Popconfirm
                    open={open}
                    icon={false}
                    className='custom-close-icon'
                    title={<span className='text-red-500'></span>}
                    description={
                        <div className='flex gap-1'>
                            {bannerContent?.is_dismissed_permanently && (
                                <Button onClick={(e) => onDismiss(e, bannerContent)}>Don’t show me again.</Button>
                            )}
                            <Button onClick={(e) => onDismiss(e, bannerContent)}>Dismiss</Button>

                            <Button variant='primary' onClick={(e) => onStay(e, bannerContent?.id)}>
                                Don’t Dismiss
                            </Button>
                        </div>
                    }
                >
                    <div className='min-w-[7%] cursor-pointer' onClick={() => setOpen(true)}>
                        <CloseOutlined className='bg-[#0000000D] rounded-full text-[#475467] p-2 font-semibold' />
                    </div>
                </Popconfirm>
            </>
        </div>
    )
}

export default Banner
