export default ({ width, color }) => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={width} height={width} viewBox='0 0 26 26' fill='none'>
            <path
                d='M13.0001 22.5335H5.20011C6.63878 22.5335 7.80011 21.3721 7.80011 19.9335V6.0668C7.80011 4.62813 8.96145 3.4668 10.4001 3.4668H18.0094C19.4481 3.4668 20.6094 4.62813 20.6094 6.0668V21.4935'
                stroke={color}
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M12.1336 17.3335H13.6762'
                stroke={color}
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M12.1336 8.66699H16.2763'
                stroke={color}
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M12.1336 13H16.2763'
                stroke={color}
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M5.19969 22.5333C3.76102 22.5333 2.59969 21.372 2.59969 19.9333V15.6C2.59969 14.1613 3.76102 13 5.19969 13H7.79969'
                stroke={color}
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M17.8097 19.7515L20.6091 22.5508L23.3997 19.7515'
                stroke={color}
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    )
}
