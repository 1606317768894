import { Button, Col, Collapse, Divider, DownOutlined, Form, Input, InputNumber, Row, Select, Tag } from 'gokwik-ui-kit'
import { businessAddressFormConfig } from './config'
import { useEffect, useState } from 'react'
import { getUserData } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { CollapsePanelStatusChips, getFormStatusConfigs, handleFieldChange, kycFieldsMap } from '../../../utils'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { stepConfiguration } from '@pages/onboarding/stepsConfig'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

const BusinessAddress = ({
    kycData,
    onFinish,
    setCurrentStep,
    currentStep,
    isPanelClickable,
    undeterminedKycErrorState,
}) => {
    const [form] = Form.useForm()
    const [business_address_fields, set_business_address_fields] = useState(businessAddressFormConfig?.items)
    const userData = useSelector(getUserData)
    const [pincode, setPincode] = useState('')
    const [errors, setError] = useState([])
    const current_onboarding_status = userData?.merchantDetails?.onboarding_status?.gokwik_cards_app

    useEffect(() => {
        const getStatus = getFormStatusConfigs(userData, business_address_fields, 'address_details')
        setError(getStatus?.generateErrorChips)
        set_business_address_fields(getStatus?.updateConfigWithErrors)
    }, [userData])

    useEffect(() => {
        let pin = pincode + ''
        if (pin?.length === 6) {
            getStateCity()
        }
    }, [pincode])

    const getStateCity = async () => {
        await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.getPincodeState + '?pincode=' + pincode,
        }).then((res) => {
            if (res?.status === 200) {
                form.setFieldValue('state', res.data.data.state)
                form.setFieldValue('city', res.data.data.city)
            } else {
                form.setFields([
                    {
                        name: 'pincode',
                        errors: ['Invalid Pincode'],
                    },
                ])
                form.setFieldValue('state', '')
                form.setFieldValue('city', '')
            }
        })
    }

    const isFieldEnabled = (fieldName) => {
        if (current_onboarding_status === 'kyc_verification_failed') {
            if (Object.keys(userData?.kycData?.kyc_failure_reasons)?.includes(fieldName) || undeterminedKycErrorState) {
                return false //dont-disable-the-field
            } else {
                return true //yes-disable-the-field
            }
        } else if (
            current_onboarding_status === 'kyc_verification_pending' ||
            current_onboarding_status === 'kyc_completed'
        ) {
            return true //yes-disable-the-field
        }
    }

    const items = [
        {
            key: '1',
            label: (
                <>
                    <div className='flex flex-col gap-1'>
                        <div className='text-[#004B8D] text-xs font-semibold'>Step 2</div>

                        <div className='flex gap-4 items-center'>
                            <div className={`text-xl font-semibold ${errors?.length && 'text-red-700'}`}>
                                Business Address
                            </div>{' '}
                            <CollapsePanelStatusChips
                                errors={errors}
                                stepConfiguration={stepConfiguration}
                                current_onboarding_status={
                                    userData?.merchantDetails?.onboarding_status?.gokwik_cards_app
                                }
                                sectionName={'address_details'}
                            />
                        </div>
                    </div>
                </>
            ),
            children: (
                <Form
                    initialValues={kycData || {}}
                    form={form}
                    scrollToFirstError={{ behavior: 'auto', block: 'center' }}
                    onFinish={(v) => {
                        logEvent(
                            'address_save_continue_clicked',
                            'click',
                            'kyc',
                            userData?.userDetails?.email,
                            userData?.merchantDetails?.m_id,
                        )
                        onFinish(v, 1)
                    }}
                    layout='vertical'
                    onFieldsChange={(changedField, allFields) =>
                        handleFieldChange(changedField, business_address_fields, set_business_address_fields)
                    }
                >
                    <style>
                        {`
                            .custom-label-styles label {
                                width: 100%
                            }
                        `}
                    </style>
                    <Row gutter={16}>
                        {business_address_fields?.map((item, index) => (
                            <Col span={item?.span} key={index} className={'custom-label-styles'}>
                                <Form.Item
                                    label={item.label}
                                    name={item.name}
                                    rules={item.rules}
                                    {...((item as any)?.validateStatus && {
                                        validateStatus: (item as any)?.validateStatus,
                                    })}
                                    {...((item as any)?.help && {
                                        help: (item as any)?.help,
                                    })}
                                >
                                    {item.type === 'input' && (
                                        <Input placeholder={item.placeholder} disabled={isFieldEnabled(item?.name)} />
                                    )}
                                    {item.type === 'inputNumber' && (
                                        <InputNumber
                                            {...(item.name === 'pincode'
                                                ? {
                                                      onChange: (e) => {
                                                          let pin = String(e) + ''
                                                          if (pin?.length <= 6) {
                                                              setPincode(String(e))
                                                              form?.setFieldValue(item.name, e)
                                                          }
                                                      },
                                                  }
                                                : {})}
                                            value={pincode}
                                            maxLength={6}
                                            minLength={6}
                                            className='w-full'
                                            placeholder={item.placeholder}
                                            disabled={isFieldEnabled(item?.name)}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>

                    <Divider className='my-4' />
                    <Form.Item className='flex justify-end mb-0'>
                        <Button
                            variant='primary'
                            htmlType='submit'
                            disabled={
                                (errors?.length === 0 &&
                                    current_onboarding_status === 'kyc_verification_failed' &&
                                    !userData?.kycData?.kyc_rejection_reason) ||
                                userData?.merchantDetails?.onboarding_status?.gokwik_cards_app ===
                                    'kyc_verification_pending' ||
                                current_onboarding_status === 'kyc_completed'
                            }
                        >
                            Save & Continue
                        </Button>
                    </Form.Item>
                </Form>
            ),
        },
    ]

    const onChange = (e) => {
        setCurrentStep(1)
    }

    return (
        <div className=''>
            <Collapse
                collapsible={isPanelClickable(1)}
                activeKey={currentStep === 1 && ['1']}
                className='bg-white'
                onChange={onChange}
                items={items}
                accordion
                expandIconPosition='right'
                expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
            />
        </div>
    )
}

export default BusinessAddress
