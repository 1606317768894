import React from 'react'

export default function StarsIcons() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clip-path="url(#clip0_6246_24184)">
          <path d="M13.3333 14.9987C13.7754 14.9987 14.1993 15.1743 14.5118 15.4869C14.8244 15.7994 15 16.2233 15 16.6654C15 16.2233 15.1756 15.7994 15.4882 15.4869C15.8007 15.1743 16.2246 14.9987 16.6667 14.9987C16.2246 14.9987 15.8007 14.8231 15.4882 14.5105C15.1756 14.198 15 13.7741 15 13.332C15 13.7741 14.8244 14.198 14.5118 14.5105C14.1993 14.8231 13.7754 14.9987 13.3333 14.9987ZM13.3333 4.9987C13.7754 4.9987 14.1993 5.17429 14.5118 5.48685C14.8244 5.79941 15 6.22334 15 6.66536C15 6.22334 15.1756 5.79941 15.4882 5.48685C15.8007 5.17429 16.2246 4.9987 16.6667 4.9987C16.2246 4.9987 15.8007 4.8231 15.4882 4.51054C15.1756 4.19798 15 3.77406 15 3.33203C15 3.77406 14.8244 4.19798 14.5118 4.51054C14.1993 4.8231 13.7754 4.9987 13.3333 4.9987ZM7.5 14.9987C7.5 13.6726 8.02678 12.4008 8.96447 11.4632C9.90215 10.5255 11.1739 9.9987 12.5 9.9987C11.1739 9.9987 9.90215 9.47191 8.96447 8.53423C8.02678 7.59655 7.5 6.32478 7.5 4.9987C7.5 6.32478 6.97322 7.59655 6.03553 8.53423C5.09785 9.47191 3.82608 9.9987 2.5 9.9987C3.82608 9.9987 5.09785 10.5255 6.03553 11.4632C6.97322 12.4008 7.5 13.6726 7.5 14.9987Z" fill="#4096FF" stroke="#4096FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <clipPath id="clip0_6246_24184">
            <rect width="20" height="20" fill="white"/>
          </clipPath>
        </defs>
      </svg>
        
    )
}
