import { message, QuestionCircleOutlined, Tooltip } from 'gokwik-ui-kit'

const validateCityState = (value) => {
    if (!value || value.trim() === '') {
        return Promise.reject(new Error(''))
    }
    // Trim leading and trailing spaces and check for only alphabetic characters and spaces, also no HTML tags
    const trimmedValue = value.trim()
    if (!/^[a-zA-Z\s]+$/.test(trimmedValue) || /<[^>]*>/g.test(trimmedValue)) {
        return Promise.reject(new Error('Only alphabetic characters and spaces are allowed!'))
    }
    return Promise.resolve()
}

export const fileInput = {
    basicDetails: [
        {
            label: (
                <>
                    Website/Store URL
                    <Tooltip
                        title={
                            'Enter the full web address of your online store or website where customers make purchases'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'website',
            type: 'input',
            disabled: true,
            placeholder: 'Enter Website URL',
            rules: [
                {
                    required: true,
                    message: 'Please input your website URL!',
                },
            ],
        },
        {
            label: (
                <>
                    Brand Name
                    <Tooltip title={'Provide the name of your brand as it is recognized by customers'}>
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'business_name',
            // disabled: true,
            type: 'input',
            placeholder: 'Enter Brand Name',
            rules: [
                {
                    required: true,
                    message: 'Please input your brand name!',
                },
                {
                    pattern: /^[a-zA-Z0-9 .&-]*$/,
                    message: 'Please enter a valid Brand Name!',
                },
            ],
        },
        {
            label: (
                <>
                    Business Category
                    <Tooltip
                        title={
                            'Select the primary category that best describes the nature of your business, such as apparel, electronics, etc.'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'business_category',
            type: 'select',
            disabled: false,
            options: ['Category 1', 'Category 2', 'Category 3'],
            placeholder: 'Enter Category',
            rules: [
                {
                    required: true,
                    message: 'Please select your business category!',
                },
            ],
        },
        {
            label: (
                <>
                    Average Order Value
                    <Tooltip
                        title={
                            'Enter the typical amount that customers spend on a single order in your store, in Indian Rupees. Estimate the average if exact numbers are not available.'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'average_order_value',
            type: 'inputNumber',
            disabled: false,
            placeholder: 'Enter value',
            rules: [
                {
                    required: true,
                    message: 'Please input the Average Order Value!',
                },
                {
                    min: 0,
                    validator: (_, value) => (value <= 0 ? Promise.reject(new Error('')) : Promise.resolve()),
                },
            ],
        },
        {
            label: (
                <>
                    Registration type
                    <Tooltip
                        title={
                            'Choose the legal structure of your business entity, such as sole proprietorship, partnership, private limited company, etc'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'entity_type',
            disabled: false,
            type: 'select',
            options: [
                { 'Private Ltd/Public Ltd/One Person Company': 11 },
                { 'Partnership Firm': 9 },
                { 'Sole Proprietor': 10 },
                { 'Limited liability partnership (LLP)': 7 },
                { 'Hindu Undivided Family (HUF)': 5 },
            ],
            placeholder: 'Enter type of the  Registration type',
            rules: [
                {
                    required: true,
                    message: 'Please select the  Registration type!',
                },
            ],
        },
        // {
        //     name: 'accepted_terms_and_conditions',
        //     valuePropName: 'checked',
        //     type: 'checkbox',
        //     disabled: false,
        //     text: 'Accept Terms & Conditions',
        //     rules: [
        //         {
        //             validator: (_, value) =>
        //                 value
        //                     ? Promise.resolve()
        //                     : Promise.reject(new Error('You must accept the Terms and Conditions!')),
        //         },
        //     ],
        // },
    ],
    addressDetails: [
        {
            label: (
                <>
                    Business Address
                    <Tooltip
                        title={
                            'Enter the official address of your business. This should match the location details provided in your business registration documents'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'business_address',
            type: 'input',
            colSpan: 21,
            class: '',
            placeholder: 'Business Address',
            rules: [
                {
                    required: true,
                    message: 'Please add your business address',
                },
                {
                    validator: (_, value) =>
                        value?.trim() !== ''
                            ? Promise.resolve()
                            : Promise.reject(new Error('Please enter a valid address!')),
                },
            ],
        },
        {
            label: (
                <>
                    Pincode
                    <Tooltip title={'Provide the postal code for your business location.'}>
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            initialValue: '',
            name: 'pincode',
            type: 'inputNumber',
            placeholder: 'Pincode',
            colSpan: 7,
            rules: [
                {
                    required: true,
                    message: '',
                },

                {
                    validator: (_, value) =>
                        value && value.toString().length === 6
                            ? Promise.resolve()
                            : Promise.reject(new Error('Pincode must be 6 digits')),
                },
            ],
        },

        {
            label: (
                <>
                    City
                    <Tooltip title={'Enter the city where your business is registered.'}>
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'city',
            type: 'input',
            placeholder: 'City',
            colSpan: 7,
            rules: [
                {
                    required: true,
                },
                {
                    validator: (_, value) => validateCityState(value),
                },
            ],
        },

        {
            label: (
                <>
                    State
                    <Tooltip
                        title={
                            'Select the state from the dropdown where your business is located. This should be the same state as mentioned in your official business documents.'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'state',
            type: 'input',
            placeholder: 'State',
            colSpan: 7,
            rules: [
                {
                    required: true,
                },
                {
                    validator: (_, value) => validateCityState(value),
                },
            ],
        },
    ],
    bankDetails: [
        {
            label: (
                <>
                    Account Holder Name
                    <Tooltip
                        title={
                            'Enter the name of the individual or entity that holds the bank account. This name must match the name on the bank account records.'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'account_holder_name',
            type: 'input',
            colSpan: 11,
            class: '',
            placeholder: 'Account holder name',
            rules: [
                {
                    required: true,
                    message: 'Please add your account holder name',
                },
                {
                    validator: (_, value) => {
                        if (!value || value.trim() === '') {
                            return Promise.reject(new Error('Cannot be empty'))
                        }
                        // Trim leading and trailing spaces and check for only alphabetic characters and spaces, also no HTML tags
                        const trimmedValue = value.trim()
                        if (!/^[A-Za-z0-9\s.]+$/.test(trimmedValue) || /<[^>]*>/g.test(trimmedValue)) {
                            return Promise.reject(new Error('Invalid Name'))
                        }
                        return Promise.resolve()
                    },
                },
            ],
        },
        {
            label: (
                <>
                    Account Number
                    <Tooltip title={'Provide the unique account number associated with your bank account.'}>
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'account_number',
            type: 'input',
            placeholder: 'Account Number',
            colSpan: 10,
            rules: [
                {
                    required: true,
                    message: 'Please add your account number',
                },
                {
                    pattern: new RegExp('^(?=.*\\d)[A-Za-z0-9]{9,18}$'),
                    message: 'Account number must be 9-18 alphanumeric characters, including at least one digit',
                },
            ],
        },

        {
            label: (
                <>
                    Bank Name
                    <Tooltip title={'Provide the name of the bank where the account is maintained.'}>
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'bank_name',
            type: 'input',
            placeholder: 'Bank Name',
            colSpan: 7,
            rules: [
                {
                    required: true,
                    message: 'Please add your bank name',
                },
                {
                    validator: (_, value) => {
                        if (!value || value.trim() === '') {
                            return Promise.reject(new Error('Cannot be empty'))
                        }
                        // Trim leading and trailing spaces and check for only alphabetic characters and spaces, also no HTML tags
                        const trimmedValue = value.trim()
                        if (!/^[A-Za-z0-9\s.]+$/.test(trimmedValue) || /<[^>]*>/g.test(trimmedValue)) {
                            return Promise.reject(new Error('Invalid Name'))
                        }
                        return Promise.resolve()
                    },
                },
            ],
        },

        {
            label: (
                <>
                    Branch Name
                    <Tooltip title={'Enter the specific branch name of the bank where your account is located.'}>
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'branch_name',
            type: 'input',
            placeholder: 'Branch Name',
            colSpan: 7,
            rules: [
                {
                    required: true,
                    message: 'Please add your bank branch name',
                },
                {
                    validator: (_, value) => {
                        if (!value || value.trim() === '') {
                            return Promise.reject(new Error('Cannot be empty'))
                        }
                        // Trim leading and trailing spaces and check for only alphabetic characters and spaces, also no HTML tags
                        const trimmedValue = value.trim()
                        if (!/^[A-Za-z0-9\s.]+$/.test(trimmedValue) || /<[^>]*>/g.test(trimmedValue)) {
                            return Promise.reject(new Error('Invalid branch name'))
                        }
                        return Promise.resolve()
                    },
                },
            ],
        },
        {
            label: (
                <>
                    IFSC Code
                    <Tooltip
                        title={
                            'Enter the Indian Financial System Code, which is an 11-character code assigned by the Reserve Bank of India to identify every bank branch uniquely.'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'ifsc_code',
            type: 'input',
            placeholder: 'IFSC Code',
            colSpan: 7,
            class: 'uppercase',
            rules: [
                {
                    required: true,
                    message: 'Please add your bank IFSC Code',
                },
                {
                    pattern: RegExp(`^[A-Za-z]{4}0[A-Za-z0-9]{6}$`),
                    message: 'Please add your correct ifsc code',
                },
            ],
        },
        {
            label: (
                <>
                    Bank Proof (Cheque Book)
                    <Tooltip
                        title={
                            'Upload a scanned copy or a clear photograph of your chequebook that shows your account details and IFSC code. Ensure that the image is legible'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'bank_proof',
            type: 'upload',
            colSpan: 11,
            class: 'w-[94%]',
            placeholder: 'Bank Proof (Cheque Book)',
            rules: [
                {
                    required: true,
                    message: 'Please add your bank proof',
                },
            ],
        },
    ],

    buisnessDetails: [
        {
            label: (
                <>
                    Business Name
                    <Tooltip
                        title={
                            'Enter the full legal name of your business as registered in official documents and records'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'business_name',
            type: 'input',
            colSpan: 7,
            class: '',
            placeholder: 'Business name',
            rules: [
                {
                    required: true,
                    message: 'Please add your business name',
                },
                {
                    validator: (_, value) => {
                        if (!value || value.trim() === '') {
                            return Promise.reject(new Error('Name cannot be empty or contain only spaces'))
                        }
                        if (/<[^>]*>/g.test(value)) {
                            return Promise.reject(new Error('HTML tags are not allowed!'))
                        }
                        return Promise.resolve()
                    },
                },
            ],
        },
        {
            label: (
                <>
                    Business PAN
                    <Tooltip
                        title={
                            'Provide the Permanent Account Number (PAN) associated with your business for tax purposes in India'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'business_pan',
            type: 'input',
            placeholder: 'Business PAN',
            colSpan: 7,
            class: 'uppercase',
            rules: [
                {
                    required: true,
                    message: 'Please add your business pan',
                },
                {
                    pattern: RegExp(`^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$`),
                    message: 'Invalid PAN. Please enter a valid PAN number',
                },
            ],
        },

        {
            label: (
                <>
                    Business PAN Registration Date
                    <Tooltip
                        title={
                            'Enter the date when your business PAN was officially registered with the Indian tax authorities'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'business_pan_registration_date',
            type: 'date',
            placeholder: 'Business PAN Registration Date',
            colSpan: 7,
            class: 'w-full',
            rules: [
                {
                    required: true,
                    message: 'Please add your business PAN registration date',
                },
                {
                    validator: (_, value) => {
                        const today = new Date()
                        today.setHours(0, 0, 0, 0) // Set to the start of today

                        if (!value) {
                            return Promise.resolve()
                        }

                        const selectedDate = new Date(value)
                        if (selectedDate >= today) {
                            return Promise.reject(new Error('Date cannot be today or in the future'))
                        }

                        return Promise.resolve()
                    },
                },
            ],
        },
        {
            label: (
                <>
                    PAN (Authorised Signatory)
                    <Tooltip
                        title={
                            'Provide the PAN of the individual who is authorized to sign documents on behalf of the business'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'aus_pan',
            type: 'input',
            placeholder: 'PAN (Authorised Signatory)',
            colSpan: 7,
            class: 'uppercase',
            rules: [
                {
                    required: true,
                    message: 'Please add your PAN (Authorised Signatory)',
                },
                {
                    pattern: RegExp(`^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$`),
                    message: 'Invalid PAN. Please enter a valid PAN number',
                },
            ],
        },
        {
            label: (
                <>
                    Authorised Signatory Name
                    <Tooltip
                        title={
                            'Provide the PAN of the individual who is authorized to sign documents on behalf of the business'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'aus_name',
            type: 'input',
            placeholder: 'Authorised Signatory Name',
            colSpan: 7,
            rules: [
                {
                    required: true,
                    message: 'Required',
                },
                {
                    validator: (_, value) => {
                        if (!value || value.trim() === '') {
                            return Promise.reject(new Error('Cannot be empty'))
                        }
                        // Trim leading and trailing spaces and check for only alphabetic characters and spaces, also no HTML tags
                        const trimmedValue = value.trim()
                        if (!/^[a-zA-Z\s]+$/.test(trimmedValue) || /<[^>]*>/g.test(trimmedValue)) {
                            return Promise.reject(new Error('Only alphabetic characters and spaces are allowed!'))
                        }
                        return Promise.resolve()
                    },
                },
            ],
        },
        {
            label: (
                <>
                    GSTIN
                    <Tooltip
                        title={
                            'Enter your Goods and Services Tax Identification Number, which is used for GST compliance and filings'
                        }
                    >
                        <QuestionCircleOutlined className='ml-2 text-[#002547] opacity-[0.4]' />
                    </Tooltip>
                </>
            ),
            name: 'gstin',
            type: 'input',
            placeholder: 'GSTIN',
            colSpan: 7,
            class: 'uppercase',
            rules: [
                {
                    required: true,
                    message: 'Please add your gstin',
                },
                {
                    pattern: RegExp('^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}$'),
                    message: 'Invalid GSTIN, Please enter a valid gstin.',
                },
            ],
        },
    ],
}
