import discoutCheck from '@library/images/icons/discount-check.svg'

export default function CongratsCard({ text }) {
    return (
        <div className='flex bg-[#F6FFED] items-center gap-2 p-2'>
            <img src={discoutCheck} alt='discount check' width={48} height={48} />
            <div className='flex flex-col justify-center gap-1'>
                <p className='flex items-center font-semibold text-[#135200]'>Congratulations</p>
                <p className='font-inter font-semibold text-[16px] leading-[24px] text-center'>
                    {text}
                </p>
            </div>
        </div>
    )
}