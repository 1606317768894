import { makeAPICall } from '@gokwik/utilities'
import AdjustIcon from '@library/images/icons/adjustIcon'
import CloseEye from '@library/images/icons/closeEye'
import Files from '@library/images/icons/files'
import OffersIcon from '@library/images/icons/offers'
import StarsIcons from '@library/images/icons/stars'
import VerifiedUser from '@library/images/icons/verifiedUser'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { getMerchantDetails, isMerchantSelector } from '@store/user/selectors'
import { ArrowRightOutlined, Button, Col, Drawer, Input, InputNumber, Row, message } from 'gokwik-ui-kit'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { navigateToUrl } from 'single-spa'
import { getUserDetails } from '@store/user/selectors'
import { stepConfiguration } from './stepsConfig'
import { useNavigate } from 'react-router-dom'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

export default function GetStarted() {
    const userData = useSelector(getUserDetails)
    const merchant_details = useSelector(getMerchantDetails)
    const isMerchantUser = useSelector(isMerchantSelector)
    const [openContactModal, setOpenContactModal] = useState(false)
    const [merchantEmail, setMerchantEmail] = useState(userData?.email)
    const [merchantContact, setMerchantContact] = useState(merchant_details?.business_phone || '')
    const [contactError, setContactError] = useState('')
    const [referralModal, setReferralModal] = useState(false)
    const [referralCode, setReferralCode] = useState('')
    const [referralError, setReferralError] = useState('')
    const [calendlyCallModal, setCalendlyCallModal] = useState(false)
    const [calendlyLinkClicked, setCalendlyLinkClicked] = useState(false)

    const navigate = useNavigate()
    const kwikCodCalendlyUrl = process.env.KWIK_COD_ONBOARDING_CALENDLY_URL

    const referralCodeAdded = async (hasReferralCode: boolean) => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.createLead + merchant_details?.m_id, //this url is salesforce leads wala
                ...(hasReferralCode ? { payload: { referral_code: referralCode } } : {}),
            })

            if (response?.data?.status_code === 200) {
                // document.getElementById('ent-plan-message').scrollIntoView({ behavior: 'smooth' })
                message.success('Our team will reach out to you in 1-2 business days')
            } else {
                message.error(response?.response?.data?.data)
            }
        } catch (error) {
            console.log(error)
        }
        if (hasReferralCode) {
            setReferralModal(false)
            setReferralCode('')
        }
    }

    const addReferralCode = () => {
        setReferralModal(true)
        setReferralCode('')
        setReferralError('')
    }

    const handleReferralSubmit = () => {
        if (!referralCode.trim()) {
            setReferralError('Referral code is required')
        } else {
            setReferralError('')
            referralCodeAdded(true)
        }
    }
    const handleReferralCodeChange = (e) => {
        const value = e.target.value.replace(/\s+/g, '')
        setReferralCode(value)
    }

    const addTeamMember = async () => {
        const payload = {
            name: userData?.name,
            email: userData?.email,
            phone: merchantContact.toString(),
            role_id: userData?.role?.id,
            status: 'active',
        }
        let queryString = ''
        if (isMerchantUser) {
            queryString = '?user_type=merchant'
        }
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateMember + queryString,
                payload,
                skipMode: true,
            })

            if (response?.data?.status_code === 200) {
                message.success(`${response?.data?.data}`)
                navigateToUrl('/onboarding/kc/onboarding-steps')
            } else {
                const errorMessage = response?.response?.data?.data ? response.response.data.data : "Something went wrong, please try again.";
                message.error(errorMessage);
            }
        } catch (err) {
            console.warn(err)
        }

        setOpenContactModal(false)
    }

    const getIntouch = () => {
        //Show calendly call modal to foreign merchants
        if (merchant_details?.country && merchant_details?.country?.toLowerCase() !== 'india') {
            setCalendlyCallModal(true)
            setOpenContactModal(false)
        } else {
            setOpenContactModal(true)
        }

        setContactError('')
    }

    const handleContactChange = (e) => {
        let filteredValue = e.target.value
        filteredValue = filteredValue ? filteredValue.toString().replace(/\D/g, '') : ''
        if (filteredValue.length > 0 && parseInt(filteredValue[0]) < 6) {
            filteredValue = ''
        }
        setMerchantContact(filteredValue)
    }

    const validateInput = (value: number) => {
        if (!value) return `Phone number is required`
        const regex = /^[0-9]{10}$/
        return regex.test(value.toString()) ? '' : 'Invalid phone number'
    }

    const handleContactSubmit = () => {
        const errorValue = validateInput(merchantContact)
        setContactError(errorValue)
        if (!errorValue) {
            addTeamMember()
        }
    }

    useEffect(() => {
        if (
            stepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.id >
            stepConfiguration['password_setup']?.id
        ) {
            navigate('/onboarding/kc/onboarding-steps')
        }
    }, [])

    return (
        <Row className='pt-20 px-12 bg-[#f7f9fa]'>
            <Col span={12} className='p-8 flex flex-col items-start'>
                <span
                    className='flex justify-center items-center px-[8px] py-[4px] pr-[6px] gap-[4px] rounded-[4px]'
                    style={{
                        background:
                            'linear-gradient(290deg, rgba(230, 244, 255, 0.30) 0%, rgba(145, 202, 255, 0.60) 100%)',
                    }}
                >
                    <StarsIcons /> New
                </span>
                <p className='text-[38px] font-bold leading-[46px] tracking-[-0.456px] text-[#00192F]'>
                    Boost Sales with COD
                </p>
                <p
                    className='rounded-custom bg-custom-gradient p-1 text-base my-2'
                    style={{
                        background: 'linear-gradient(270deg, rgba(255, 255, 255, 0) 77.45%, #fff 96.81%), #E5F3FD',
                    }}
                >
                    Get Checkout post KYC
                </p>
                <p className='text-[14px] font-normal leading-[22px] text-[rgba(0,0,0,0.65)] py-4'>
                    Offering Cash on Delivery (COD) attracts more customers who prefer to pay upon delivery, boosting
                    your sales. Enable COD to enhance trust and convenience, helping your business grow.
                </p>
                <ul className='list-none pl-0 '>
                    <li className='text-[14px] font-normal leading-[22px] text-[rgba(0,0,0,0.88)] w-4/5 flex items-start  justify-between gap-x-4 my-6'>
                        <CloseEye />
                        <p className='text-[14px] font-normal leading-[22px] text-text inter'>
                            Hide COD based on 10+ parameters: orders, products, pincodes, and more{' '}
                        </p>
                    </li>
                    <li className='text-[14px] font-normal leading-[22px] text-[rgba(0,0,0,0.88)] w-4/5 flex items-start  justify-between gap-x-4 my-6'>
                        <VerifiedUser />
                        <p className='text-[14px] font-normal leading-[22px] text-text inter'>
                            Verify customer intent at checkout with interventions like COD OTP, Captcha{' '}
                        </p>
                    </li>
                    <li className='text-[14px] font-normal leading-[22px] text-[rgba(0,0,0,0.88)] w-4/5 flex items-start  justify-between gap-x-4 my-6'>
                        <Files />
                        <p className='text-[14px] font-normal leading-[22px] text-text inter'>
                            Enable shipping fees based on price, weight, product, state, and other parameters{' '}
                        </p>
                    </li>
                    <li className='text-[14px] font-normal leading-[22px] text-[rgba(0,0,0,0.88)] w-4/5 flex items-start  justify-between gap-x-4 my-6'>
                        <AdjustIcon />
                        <p className='text-[14px] font-normal leading-[22px] text-text inter'>
                            Customize COD by reordering and renaming shipping methods for better flexibility{' '}
                        </p>
                    </li>
                    <li className='text-[14px] font-normal leading-[22px] text-[rgba(0,0,0,0.88)] w-4/5 flex items-start  justify-between gap-x-4 my-6'>
                        <OffersIcon />
                        <p className='text-[14px] font-normal leading-[22px] text-text inter'>
                            Use discounts like Buy X at Y, Bundled Discounts, and Free Gifts to attract customers{' '}
                        </p>
                    </li>
                </ul>
            </Col>
            <Col span={12} className='p-10'>
                <div className='flex flex-col items-start p-[16px] gap-[16px] self-stretch rounded-[40px] bg-gradient-to-b from-[#ECF6FD] to-[#F0F2FF]'>
                    <div className='bg-white items-center p-[16px] gap-[16px] border-solid border-[#004B8D] self-stretch rounded-[24px] bg-gradient-to-b from-[rgba(255,255,255,0.00)] to-[#FFF]'>
                        {/* <div className='flex items-center mb-4'>
                            <div className='flex justify-center items-center  top-0 left-0 right-0 bottom-0'>
                                <svg
                                    width='54'
                                    height='56'
                                    viewBox='0 0 54 56'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path
                                        d='M24.781 1.43907C25.9711 0.130895 28.0289 0.130894 29.219 1.43907L30.8859 3.27123C31.7307 4.19977 33.0643 4.50416 34.2283 4.0341L36.5251 3.10662C38.165 2.44439 40.0189 3.33722 40.5236 5.03223L41.2305 7.40619C41.5888 8.60932 42.6582 9.46218 43.9109 9.54372L46.3826 9.70461C48.1474 9.81949 49.4304 11.4283 49.1497 13.1744L48.7566 15.62C48.5573 16.8594 49.1508 18.0918 50.2441 18.7088L52.4012 19.9262C53.9414 20.7955 54.3993 22.8016 53.3888 24.253L51.9735 26.2858C51.2562 27.3161 51.2562 28.6839 51.9735 29.7142L53.3888 31.747C54.3993 33.1984 53.9414 35.2045 52.4012 36.0738L50.2441 37.2912C49.1508 37.9082 48.5573 39.1406 48.7566 40.38L49.1497 42.8256C49.4304 44.5717 48.1474 46.1805 46.3826 46.2954L43.9109 46.4563C42.6582 46.5378 41.5888 47.3907 41.2305 48.5938L40.5236 50.9678C40.0189 52.6628 38.165 53.5556 36.5251 52.8934L34.2283 51.9659C33.0643 51.4958 31.7307 51.8002 30.8859 52.7288L29.219 54.5609C28.0289 55.8691 25.9711 55.8691 24.781 54.5609L23.1141 52.7288C22.2693 51.8002 20.9357 51.4958 19.7717 51.9659L17.4749 52.8934C15.835 53.5556 13.9811 52.6628 13.4764 50.9678L12.7695 48.5938C12.4112 47.3907 11.3418 46.5378 10.0891 46.4563L7.61738 46.2954C5.85255 46.1805 4.56956 44.5717 4.85028 42.8256L5.24343 40.38C5.44268 39.1406 4.84918 37.9082 3.75593 37.2912L1.59879 36.0738C0.0585797 35.2045 -0.399316 33.1984 0.611221 31.747L2.02653 29.7142C2.74381 28.6839 2.74381 27.3161 2.02653 26.2858L0.611221 24.253C-0.399316 22.8016 0.0585787 20.7955 1.59879 19.9262L3.75593 18.7088C4.84918 18.0918 5.44268 16.8594 5.24343 15.62L4.85028 13.1744C4.56956 11.4283 5.85255 9.81949 7.61738 9.70461L10.0891 9.54372C11.3418 9.46218 12.4112 8.60932 12.7695 7.40619L13.4764 5.03223C13.9811 3.33722 15.835 2.44439 17.4749 3.10662L19.7717 4.0341C20.9357 4.50416 22.2693 4.19977 23.1141 3.27123L24.781 1.43907Z'
                                        fill='#004B8D'
                                    />
                                </svg>
                                <p className='absolute text-white font-semibold text-sm'>FREE</p>
                            </div>
                            <p className='text-[12px] font-normal leading-[18px] text-[#004B8D] inter w-4/5 ml-2'>
                                First 3 months for merchants on Shopify Basic and Shopify plan
                            </p>
                        </div> */}
                        <p className='text-[24px] font-semibold leading-[32px] text-[#00192F] inter'>
                            Instant COD Activation
                        </p>

                        <ul className='list-none pl-0 '>
                            <li className='text-[14px] font-normal leading-[22px] text-[rgba(0,0,0,0.65)] inter mb-4'>
                                <StarsIcons /> Hide COD for specific orders
                            </li>
                            <li className='text-[14px] font-normal leading-[22px] text-[rgba(0,0,0,0.65)] inter mb-4'>
                                <StarsIcons /> Increase prepaid conversions with COD fees
                            </li>
                            <li className='text-[14px] font-normal leading-[22px] text-[rgba(0,0,0,0.65)] inter mb-4'>
                                <StarsIcons /> Address validation for better delivery rates
                            </li>
                            <li className='text-[14px] font-normal leading-[22px] text-[rgba(0,0,0,0.65)] inter mb-4'>
                                <StarsIcons /> Seamless OTP integration for OCD
                            </li>
                        </ul>
                        <Button
                            onClick={() => getIntouch()}
                            variant='primary'
                            className='w-full p-8 flex items-center justify-center text-[24px] leading-[32px]'
                        >
                            {/* Click Here to Avail Offer */}
                            Activate COD
                        </Button>
                    </div>
                </div>
                <div className='bg-white rounded-lg p-4 mt-8 '>
                    <p className='text-[16px] font-semibold leading-[24px] text-[rgba(0,0,0,0.88)] inter'>
                        {/* If you do not offer Cash on Delivery */}I don't need COD
                    </p>
                    <p
                        onClick={() => referralCodeAdded(false)}
                        className='cursor-pointer text-[14px] font-medium leading-[22px] text-[#004B8D] inter mt-3'
                    >
                        Get in Touch <ArrowRightOutlined className='ml-2' />
                    </p>
                </div>
            </Col>
            <Col span={24} className='flex justify-center'>
                <p
                    onClick={addReferralCode}
                    className='cursor-pointer text-[16px] font-medium leading-[22px] text-[#004B8D] inter'
                >
                    Are you a Shopify partner? <ArrowRightOutlined className='ml-2' />
                </p>
            </Col>
            <Drawer
                title='Verify Your Contact Details'
                open={openContactModal ? true : false}
                footer={
                    <div>
                        <Button onClick={() => handleContactSubmit()} variant='primary'>
                            Let's Get Started
                        </Button>
                    </div>
                }
                width={600}
                closable={false}
                onClose={() => setOpenContactModal(false)}
                style={{
                    borderTopLeftRadius: '16px',
                    borderBottomLeftRadius: '16px',
                }}
            >
                <div className='mb-5 mt-0 text-[rgba(0,0,0,0.88)]'>
                    {merchant_details?.business_phone
                        ? "To ensure we can reach you, we've pre-filled your contact information from your Shopify store. Please verify or update these details below."
                        : " To ensure we can reach you, we've pre-filled your email address from your Shopify store. Please verify this information and provide your phone number below."}
                </div>
                <div className='mb-5'>
                    <label className='mb-2 font-semibold'>Email Address</label>
                    <Input value={merchantEmail} type='text' disabled placeholder='Email Address' />
                </div>
                <div className='mb-5'>
                    <label className='mb-2 font-semibold'>Mobile No.</label>
                    <Input
                        value={merchantContact}
                        onChange={handleContactChange}
                        maxLength={10}
                        className='w-full'
                        placeholder='Phone no.'
                        prefix={<span className='mr-1'>+91</span>}
                    />
                    {contactError && <div className='text-red-500 mt-1'>{contactError}</div>}
                </div>
            </Drawer>
            <Drawer
                title='Are You a Shopify Partner?'
                open={referralModal ? true : false}
                width={600}
                closable={false}
                onClose={() => setReferralModal(false)}
                style={{
                    borderTopLeftRadius: '16px',
                    borderBottomLeftRadius: '16px',
                }}
            >
                <div className='mb-5 mt-0 text-[rgba(0,0,0,0.88)]'>
                    Unlock exclusive benefits with your referral code
                </div>
                <div className='mb5'>
                    <label className='mb-2 font-semibold'>Enter Referral Code</label>
                    <Row>
                        <Col span={19}>
                            <Input
                                value={referralCode}
                                onChange={handleReferralCodeChange}
                                type='text'
                                placeholder='Referral Code'
                            />
                        </Col>
                        <Col span={3}>
                            <Button onClick={handleReferralSubmit} variant='primary' className='ml-2'>
                                Submit Now!
                            </Button>
                        </Col>
                    </Row>
                    {referralError && <div className='text-red-500 mt-1'>{referralError}</div>}
                </div>
            </Drawer>
            <Drawer
                title='Unlock growth. Schedule a call today.'
                open={calendlyCallModal}
                width={620}
                closable={false}
                onClose={() => setCalendlyCallModal(false)}
                style={{
                    borderTopLeftRadius: '16px',
                    borderBottomLeftRadius: '16px',
                }}
                footer={
                    <div>
                        <Button
                            onClick={() => {
                                if (!calendlyLinkClicked) {
                                    message.info(
                                        <span>
                                            Please book a meeting by clicking on{' '}
                                            <b>Schedule a quick call via Calendly</b> to proceed
                                        </span>,
                                    )
                                } else {
                                    navigateToUrl('/onboarding/kc/onboarding-steps')
                                }
                            }}
                            variant='primary'
                        >
                            Let's Get Started
                        </Button>
                    </div>
                }
            >
                <div className='space-y-4 text-gray-700'>
                    <p>
                        Hi <b>{merchant_details?.business_name}</b>,
                    </p>
                    <p>We're thrilled you've shown interest in our app.</p>
                    <p>To ensure you get the most out of it and achieve your business goals, let's connect.</p>
                    <Button
                        onClick={() => {
                            window.open(kwikCodCalendlyUrl, '_blank')
                            logEvent(
                                'calendly_link_clicked',
                                'click',
                                'get_started',
                                userData?.email,
                                merchant_details?.m_id,
                            )
                            setCalendlyLinkClicked(true)
                        }}
                        variant='primary'
                    >
                        Schedule a quick call via Calendly
                    </Button>
                    <p>During our call, we'll:</p>
                    <ul className='list-disc list-inside space-y-2'>
                        <li>
                            <b>Personalize</b> the app experience to your needs.
                        </li>
                        <li>
                            <b>Uncover growth opportunities</b> you might be missing.
                        </li>
                        <li>
                            <b>Provide dedicated support</b> every step of the way.
                        </li>
                    </ul>
                    <p>
                        Don't miss out. <b> It's Free!! Book your call now.</b>
                    </p>
                </div>
            </Drawer>
        </Row>
    )
}
