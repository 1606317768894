export default function KwikFlowsIcon() {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='6.75' cy='6.75' r='2.25' stroke='white' stroke-width='1.6' />
            <circle cx='17.25' cy='17.25' r='2.25' stroke='white' stroke-width='1.6' />
            <rect
                x='15'
                y='9'
                width='4.5'
                height='4.5'
                rx='1'
                transform='rotate(-90 15 9)'
                stroke='white'
                stroke-width='1.6'
            />
            <rect
                x='4.5'
                y='19.5'
                width='4.5'
                height='4.5'
                rx='1'
                transform='rotate(-90 4.5 19.5)'
                stroke='white'
                stroke-width='1.6'
            />
            <path d='M6.75 15V9M9 6.75H15M17.25 9V15.75' stroke='white' stroke-width='1.6' />
        </svg>
    )
}
