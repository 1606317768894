import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
const OAuthCallback = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const code = params.get('code')
        // we are using setTimeout here because - 'globalStoreChanged' in routes.tsx file forces useEffect to render twice
        if (code) {
            setTimeout(() => {
                navigate(`/data-integration`, {
                    state: {
                        code: code,
                    },
                })
            }, 0)
        } else {
            setTimeout(() => {
                navigate(`/data-integration`)
            }, 0)
        }
    }, [])

    return <div> Loading...</div>
}

export default OAuthCallback
