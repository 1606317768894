import React from 'react';
import { Modal, Button } from "gokwik-ui-kit";
import thumbsUp from '@library/images/icons/thumbsUp.svg';
import lightExternalLink from '@library/images/icons/light-external-link.svg';
import { getMerchantDetails, getUserDetails } from '@store/user/selectors';
import { useSelector } from 'react-redux';
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents';

const ButtonsWorkingModal = ({ showButtonsWorkingModal, onClose, setShowButtonsWorkingModal }) => {
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)

    const makeThemeLiveClicked = () => {
        logEvent(
            'make_theme_live_clicked',
            'click',
            'checkout-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            {Step : 'checkout 3'},
        )
        onClose();
        window.open('https://' + `${merchantDetails?.website}` + '/admin/themes', '_blank');
    }
    
    return (
        <Modal
            open={showButtonsWorkingModal}
            onCancel={() => setShowButtonsWorkingModal(false)}
            footer={null}
            centered
            width={800}
        >
            <div style={{ borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '60vh', background: 'rgba(255, 255, 225, 0.9)' }}>
                <div style={{ fontSize: '3rem' }}>
                    <img src={thumbsUp}></img>
                </div>
                <div style={{ fontWeight: 600, fontSize: '1.5rem' }}>Great!</div>
                <p style={{ fontSize: '1.5rem', fontWeight: 600 }}>
                    Now, make your duplicate theme live.
                </p>
                <p style={{ fontSize: '1rem', fontWeight: 400, lineHeight: '1.5rem', marginTop: '0.5rem' }}>
                    Your GoKwik buttons are added successfully, now make the duplicated theme live.
                </p>
                <Button
                    type="primary"
                    size="large"
                    style={{
                        marginTop: '1rem',
                        fontWeight: 'bold',
                        display: 'inline-flex',
                        alignItems: 'center',
                    }}
                    onClick={makeThemeLiveClicked}
                >
                    Make Theme Live <img style={{ marginLeft: '0.5rem' }} src={lightExternalLink} alt="external link"/>
                </Button>
            </div>
        </Modal>
    );
};

export default ButtonsWorkingModal;
