import React from 'react'

export default function ordersIcon() {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M12 2L21 7.0625V17.1875L12 22.25L3 17.1875V7.0625L12 2Z'
                stroke='white'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M12 12.125L21 7.0625'
                stroke='white'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path d='M12 12.125V22.25' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' />
            <path
                d='M12 12.125L3 7.0625'
                stroke='white'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
            <path
                d='M16.5 4.53125L7.5 9.59375'
                stroke='white'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
            />
        </svg>
    )
}
