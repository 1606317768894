import { Layout } from "gokwik-ui-kit";
import ConfigSteps from "./ConfigSteps";
import ConfigStepsWrapper from "./ConfigStepsWrapper";
import { useEffect, useState } from "react";
import { logEvent } from "@library/utilities/userLogsEvents/userLogEvents";
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux';
import { ProgressStepper } from "../CommonComponents/ProgressStepper";
import StartKycPrompt from "./StartKycPrompt";
import { navigateToUrl } from "single-spa";
import GoBackToSettings from "../CommonComponents/goBackToSettings";
import { newStepConfiguration } from "@pages/onboarding/stepsConfig";
import { fetchBillingStatus } from "../CommonComponents/Utils";

const { Content } = Layout;

const ButtonAdditionWrapper = () => {
  const userDetails = useSelector(getUserDetails)
  const merchantDetails = useSelector(getMerchantDetails)
  const currentStatus = merchantDetails?.onboarding_status?.kwik_checkout_app;
  const [approvedChargesStatus, setApprovedChargesStatus] = useState('');
  let currentConfigStep: number = 1;

  useEffect(() => {
    fetchBillingStatus((status: string) => {
      setApprovedChargesStatus(status);
      handleRedirection(status);
    }, '')
  }, [])

  if (['smart_cod_app_disabled', 'imp_checks_passed'].includes(currentStatus) || approvedChargesStatus === 'PENDING') {
    currentConfigStep = 1;
  } else if (currentStatus === 'gk_shopify_charges_approved') {
    currentConfigStep = 2;
  } else if (currentStatus === 'gk_checkout_button_added') {
    currentConfigStep = 3;
  } else if (['gk_checkout_buttons_not_working', 'gk_checkout_buttons_working'].includes(currentStatus)) {
    currentConfigStep = 4;
  }
  
  const [currentStep, setCurrentStep] = useState(currentConfigStep);

  const handleRedirection = (approvedChargesStatus: string) => {
    let kwikCheckoutAppStatus = merchantDetails?.onboarding_status?.kwik_checkout_app
    if (kwikCheckoutAppStatus && newStepConfiguration[kwikCheckoutAppStatus]?.id >=
        newStepConfiguration['kyc_viewed']?.id && (approvedChargesStatus !== 'PENDING' || newStepConfiguration[currentStatus]?.id > newStepConfiguration['kyc_verification_failed']?.id)) {
        navigateToUrl('/onboarding/kyc');
    } else if (newStepConfiguration[merchantDetails?.onboarding_status?.kwik_checkout_app]?.id <
      newStepConfiguration['imp_checks_passed']?.id) {
        navigateToUrl('/onboarding/cod-setup');
    } else if (!kwikCheckoutAppStatus) {
      navigateToUrl('/shopify-app/settings');
    }
  }

  useEffect(() => {
    logEvent(
      'enable_checkout_page_landed',
      'pageload',
      'checkout-setup',
      userDetails?.email,
      merchantDetails?.m_id,
      merchantDetails?.short_name,
      userDetails?.name
    )
  }, [])

  return (
    <Layout className='pt-16'>
        <ProgressStepper />
        <Content >
            {currentStep <= 3 ? (
                <Layout style={{ backgroundColor: "#f0f2f5", margin: '0 2rem' }}>
                    <GoBackToSettings heading={'Enable Gokwik Checkout'} />
                    <Content style={{ paddingTop: '0.75rem' }}>
                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <ConfigSteps currentStep={currentStep}/>
                            <ConfigStepsWrapper currentStep={currentStep} setCurrentStep={setCurrentStep} />
                        </div>
                    </Content>
                </Layout>
            ) : (
                <StartKycPrompt />
            )}
        </Content>
    </Layout>
  );
};

export default ButtonAdditionWrapper;
