import React from 'react';
import { Modal, Button, Divider } from 'gokwik-ui-kit';
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ActivationModal = ({ showActivationModal, setShowActivationModal }) => {
  const navigate = useNavigate()
  const userDetails = useSelector(getUserDetails)
  const merchantDetails = useSelector(getMerchantDetails)

  const handleActivation = () => {
    localStorage.setItem('showKycPromptFlag', 'true');
    navigate('/onboarding/kc/onboarding-steps', { state: { showKycPrompt: true } })
    logEvent(
      'plan_activation_started',
      'click',
      'get_started',
      userDetails?.email,
      merchantDetails?.m_id,
      merchantDetails?.short_name,
      userDetails?.name,
      { plan: 'cod_checkout' }
    )
    setShowActivationModal(false);
  };

  return (
    <>
        <Modal
            onCancel={() => setShowActivationModal(false)}
            title="Checkout & COD Plan"
            open={showActivationModal}
            footer={[
                <Button key="activate" type="primary" onClick={handleActivation}>
                    Let’s Get Started
                </Button>
            ]}
            centered
        >
            <div style={{ fontSize: "14px", lineHeight: "22px", marginTop: "0.5rem" }}>
              We will now begin activating COD, followed by the KYC process for Checkout Enhancer to unlock full access to both services.
            </div>
        </Modal>
    </>
  );
};

export default ActivationModal;
