import React from 'react'

export default function abcIcon() {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M7.16412 5.82925H18.335C19.4754 5.82925 20.2828 6.953 19.9082 8.03512M4.00098 3.33203H5.20796C5.97378 3.33203 6.63138 3.84812 6.82283 4.58064L9.02038 13.0961C9.23681 13.9286 10.0526 14.4613 10.9016 14.3198L14.0315 13.812'
                stroke='white'
                stroke-width='2'
                stroke-miterlimit='10'
                stroke-linecap='round'
            />
            <path
                d='M19.9998 13.9998L17 11'
                stroke='white'
                stroke-width='2'
                stroke-miterlimit='10'
                stroke-linecap='round'
            />
            <path
                d='M19.9998 11.0002L17 14'
                stroke='white'
                stroke-width='2'
                stroke-miterlimit='10'
                stroke-linecap='round'
            />
            <path
                d='M9.83643 14.1445L6.05731 17.9403C5.6744 18.3315 5.9491 18.9891 6.49016 18.9891H8.15497'
                stroke='white'
                stroke-width='2'
                stroke-miterlimit='10'
                stroke-linecap='round'
            />
            <path
                d='M16.8826 20.812C17.802 20.812 18.5474 20.0667 18.5474 19.1472C18.5474 18.2278 17.802 17.4824 16.8826 17.4824C15.9631 17.4824 15.2178 18.2278 15.2178 19.1472C15.2178 20.0667 15.9631 20.812 16.8826 20.812Z'
                fill='white'
            />
            <path
                d='M11.8884 20.812C12.8079 20.812 13.5533 20.0667 13.5533 19.1472C13.5533 18.2278 12.8079 17.4824 11.8884 17.4824C10.969 17.4824 10.2236 18.2278 10.2236 19.1472C10.2236 20.0667 10.969 20.812 11.8884 20.812Z'
                fill='white'
            />
        </svg>
    )
}
