import { Layout, Row, Col, Button } from 'gokwik-ui-kit'
import PlansSection from './upsell-flow/leftPanel';
import greenCheck from '@library/images/icons/greenCheck.svg';
import { useNavigate } from 'react-router-dom'
const { Content } = Layout;

export default function ContactSalesConfirmation() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate('/onboarding/get-started')
    }
    return (
        <Layout className='pt-16'>
            <Content >
                <Row style={{ border: '4px solid #0B1B2B', height: '100%',  width: "100%", margin: "0px" }} gutter={0}>
                    <Col xs={24} sm={24} md={7} lg={7}>
                        <PlansSection heading='Kwik Checkout with COD' subHeading='Get a complete solution with COD, prepaid options, and an enhanced checkout experience. Know more' />
                    </Col>
                    <Col xs={24} sm={24} md={17} lg={17} >
                        <div className="bg-[rgba(246,255,237,1)] text-center font-inter h-full flex flex-col justify-center items-center">
                            <div className='flex flex-col justify-center items-center w-full'>
                                <img src={greenCheck}/>
                                <div className="font-semibold mt-4 text-2xl">
                                    Thanks for contacting
                                </div>
                                <div className="mt-2 text-gray-800 text-base">
                                    Our team has received your request. <br/>
                                    Will be in touch within <span className="font-semibold">1-2 business days.</span>
                                </div>
                                <Button className="mt-2 text-sm outline-none border-0 p-0 cursor-pointer" onClick={handleButtonClick} type="link" style={{ color: 'rgba(22, 119, 255, 1)' }}>
                                    Continue with current plans
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}
