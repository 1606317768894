export default function AnalyticsRTOIcon() {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_828_8387)'>
                <path
                    d='M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19'
                    stroke='white'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M17 21H7C6.46957 21 5.96086 20.7893 5.58579 20.4142C5.21071 20.0391 5 19.5304 5 19V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H14L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21Z'
                    stroke='white'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path d='M9 17V12' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round' />
                <path d='M12 17V16' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round' />
                <path d='M15 17V14' stroke='white' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round' />
            </g>
            <defs>
                <clipPath id='clip0_828_8387'>
                    <rect width='24' height='24' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}









