import React, { useEffect, useState } from 'react';
import { Button, Divider } from "gokwik-ui-kit";
import previewPlaceholder1 from '@library/images/icons/checkoutStep1.png'; 
import darkExternalLink from '@library/images/icons/dark-external-link.svg';
import lightExternalLink from '@library/images/icons/light-external-link.svg';
import HowToStepsWithPreview from '../CommonComponents/howToStepsAndPreview';
import { logEvent } from "@library/utilities/userLogsEvents/userLogEvents";
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux';
import { fetchBillingStatus, fetchBillingSubscription, updateMerchantOnboardingCheckoutConfigsStatus } from '../CommonComponents/Utils';
import CheckoutStepsHeader from '../CommonComponents/checkoutStepsHeader';
import { fetchUserDetails } from '@store/user';
import { useAppDispatch } from '@library/utilities/hooks'
import ApproveChargesModal from './approveChargesModal';

const CheckoutConfigStep1 = ({ onStepComplete, currentStep, expandedStep, toggleStep }) => {
  const [siteVisited, setSiteVisited] = useState(false);
  const [approveChargesModal, setApproveChargesModal] = useState(false);
  const userDetails = useSelector(getUserDetails)
  const merchantDetails = useSelector(getMerchantDetails)
  const [showContent, setShowContent] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (expandedStep === 1 && currentStep !== 1) {
      setSiteVisited(true);
    }
    if (expandedStep == 1) {
      setShowContent(false);
      setTimeout(() => setShowContent(true), 100);
    }
  }, [expandedStep]);

  const steps = [
    {
      description: (<>Click on <b>“Open Shopify Dashboard”</b> button.</>) ,
      step: 1,
    },
    {
      description: (<>Click on <b>“Approve”</b> button on Shopify store.</>) 
    },
    {
      description: (<>After installing the app, <b>come back to this page</b>.</>)
    },
    {
      description: (<>Click on <b>"Charges Approved"</b> Button.</>) 
    }
  ]

  const handleInstallAppClick = () => {
    logEvent(
      siteVisited ? "approve_charges_clicked_again" : 'approve_charges_clicked',
      'click',
      'checkout-setup',
      userDetails?.email,
      merchantDetails?.m_id,
      merchantDetails?.short_name,
      userDetails?.name,
      {Step : 'checkout 1'},
    )
    fetchBillingSubscription((approveChergesUrl: string) => {
      setSiteVisited(true)
      window.open(approveChergesUrl, '_blank');
    })
  }

  const ApprovedClicked = () => {
    fetchBillingStatus((status: string) => {
      if (status === 'APPROVED') {
        updateOnboardingStatus();
      } else if (status === 'PENDING') {
        setApproveChargesModal(true);
      }
    }, 'Error occured while Approving Charges')
  }

  const updateOnboardingStatus = () => {
    logEvent(
      "approve_chrages_completed",
      'click',
      'checkout-setup',
      userDetails?.email,
      merchantDetails?.m_id,
      merchantDetails?.short_name,
      userDetails?.name,
      {Step : 'checkout 1'},
    )
    updateMerchantOnboardingCheckoutConfigsStatus('gk_shopify_charges_approved', () => {
      dispatch(fetchUserDetails())
      onStepComplete();
    }, merchantDetails)  
  }

  return (
    <div className="border border-gray-300 rounded-lg bg-white w-[74vw]">
        <CheckoutStepsHeader step={'Step 1'} heading={'Approve Charges'} toggleStep={toggleStep} stepId={1} currentStep={currentStep} />

        {expandedStep == 1 ? (
          <div style={{
            opacity: showContent ? 1 : 0,
            transform: showContent ? 'translateY(0)' : 'translateY(20px)',
            transition: 'opacity 0.5s ease, transform 0.5s ease',
          }}>
            <Divider className='m-0'/>
            <HowToStepsWithPreview steps={steps} previewImage={previewPlaceholder1} videoUrl={' https://pdp.gokwik.co/sa-login-ui/assets/ApproveCharges.mp4'} page={'checkout'} />
            <Divider  className='m-0' />

            <div className={`flex ${siteVisited ? "" : "flex-row-reverse"} justify-between items-center p-3`}>
                <Button
                    onClick={handleInstallAppClick}
                    variant={siteVisited ? 'default' : 'primary'}
                    className="flex items-center"
                    size="large"
                    disabled={expandedStep == 1 && currentStep !== 1}
                >
                    Open Shopify Dashboard <img style={{ marginLeft: '0.4rem' }} src={siteVisited ? darkExternalLink : lightExternalLink} />
                </Button>
                {siteVisited && (
                    <Button disabled={expandedStep == 1 && currentStep !== 1} onClick={ApprovedClicked} type="primary" size="large">
                        Charges Approved
                    </Button>
                )}
            </div>
          </div>
        ) : "" }
        <ApproveChargesModal approveChargesModal={approveChargesModal} setApproveChargesModal={setApproveChargesModal} setSiteVisited={setSiteVisited} />
    </div>
  );
};

export default CheckoutConfigStep1;
