import { logUserEvent } from '@gokwik/utilities'
import APIEndPoints from '../constants/apiEndpoints'

export const logEvent = (eventName, eventType, screen, email?, m_id?, m_name?, user_name?, data?) => {
    logUserEvent({
        url: process.env.REACT_APP_BASE_URL + APIEndPoints.userEvent,
        event_type: eventType,
        event_name: eventName,
        screen_name: screen,
        request_id: email,
        session_id: email + '_' + Date.now(),
        merchant_id: m_id,
        merchant_name: m_name,
        username: user_name,
        timestamp: new Date().toISOString(),
        data: data,
    })
}

