import React from 'react'

type Props = {}

const WhatsNewImg = (props: Props) => {
    return (
        <svg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_229_7279)'>
                <path
                    d='M17.3333 19.5002C17.908 19.5002 18.4591 19.7284 18.8654 20.1348C19.2717 20.5411 19.5 21.0922 19.5 21.6668C19.5 21.0922 19.7283 20.5411 20.1346 20.1348C20.5409 19.7284 21.092 19.5002 21.6667 19.5002C21.092 19.5002 20.5409 19.2719 20.1346 18.8656C19.7283 18.4592 19.5 17.9081 19.5 17.3335C19.5 17.9081 19.2717 18.4592 18.8654 18.8656C18.4591 19.2719 17.908 19.5002 17.3333 19.5002ZM17.3333 6.50016C17.908 6.50016 18.4591 6.72844 18.8654 7.13476C19.2717 7.54109 19.5 8.09219 19.5 8.66683C19.5 8.09219 19.7283 7.54109 20.1346 7.13476C20.5409 6.72844 21.092 6.50016 21.6667 6.50016C21.092 6.50016 20.5409 6.27189 20.1346 5.86556C19.7283 5.45923 19.5 4.90813 19.5 4.3335C19.5 4.90813 19.2717 5.45923 18.8654 5.86556C18.4591 6.27189 17.908 6.50016 17.3333 6.50016ZM9.75 19.5002C9.75 17.7763 10.4348 16.123 11.6538 14.904C12.8728 13.685 14.5261 13.0002 16.25 13.0002C14.5261 13.0002 12.8728 12.3153 11.6538 11.0964C10.4348 9.87737 9.75 8.22407 9.75 6.50016C9.75 8.22407 9.06518 9.87737 7.84619 11.0964C6.62721 12.3153 4.97391 13.0002 3.25 13.0002C4.97391 13.0002 6.62721 13.685 7.84619 14.904C9.06518 16.123 9.75 17.7763 9.75 19.5002Z'
                    fill='#4096FF'
                    stroke='#4096FF'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_229_7279'>
                    <rect width='26' height='26' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}

export default WhatsNewImg
