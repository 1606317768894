// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-container {
  display: flex;
}

.loader-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  z-index: 999999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.61);

  backdrop-filter: blur(3px);
}

.loader-icon-wrapper {
  margin: auto;
  margin-top: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-text {
  margin: auto;
  margin-top: 26%;
  position: relative;
  color: #fff;
  font-weight: 600;
}
.loader-spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #004b8d;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1.5s linear infinite;
  z-index: 9999999;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/library/components/common/loader/loader.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,MAAM;EACN,OAAO;EACP,qCAAqC;;EAErC,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,kBAAkB;EAClB,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,6BAA6B;EAC7B,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,gBAAgB;AAClB;AACA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loader-container {\n  display: flex;\n}\n\n.loader-container {\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  position: absolute;\n  z-index: 999999;\n  top: 0;\n  left: 0;\n  background-color: rgba(0, 0, 0, 0.61);\n\n  backdrop-filter: blur(3px);\n}\n\n.loader-icon-wrapper {\n  margin: auto;\n  margin-top: 25%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.loader-text {\n  margin: auto;\n  margin-top: 26%;\n  position: relative;\n  color: #fff;\n  font-weight: 600;\n}\n.loader-spinner {\n  border: 3px solid #f3f3f3;\n  border-top: 3px solid #004b8d;\n  border-radius: 50%;\n  width: 40px;\n  height: 40px;\n  animation: spin 1.5s linear infinite;\n  z-index: 9999999;\n}\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
