import { Col, Layout, Row, Button, Tooltip } from 'gokwik-ui-kit'
import leftTop from '@library/images/icons/leftTop.svg'
import freeCodOrder from '@library/images/icons/free-cod-order.svg'
import freeTrial from '@library/images/icons/free-30day-trial.svg'
import cash from '@library/images/icons/cash.svg'
import cart from '@library/images/icons/cart.svg'
import fingerprintPassword from '@library/images/icons/password-fingerprint.svg'
import cards from '@library/images/icons/cards.svg'
import number1 from '@library/images/icons/circle-1.svg'
import number2 from '@library/images/icons/circle-2.svg'
import number3 from '@library/images/icons/circle-3.svg'
import number4 from '@library/images/icons/circle-4.svg'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { newStepConfiguration } from '../stepsConfig'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { useSelector } from "react-redux";
import { getMerchantDetails, getUserDetails } from "@store/user/selectors";

const { Content } = Layout

const Steps = [
    {
        icon: cash,
        text: 'Install and activate COD module',
        stepIcon: number1,
        isInstant: true,
    },
    {
        icon: cart,
        text: 'Enable GoKwik Checkout',
        stepIcon: number2,
        isInstant: true,
    },
    {
        icon: fingerprintPassword,
        text: 'Verify Your Business(KYC)',
        stepIcon: number3,
        isInstant: false,
    },
    {
        icon: cards,
        text: 'Enable Payment Options',
        stepIcon: number4,
        isInstant: false,
    },
]

const Step = ({ icon, actionIcon, title, isInstant }) => {

    return (
        <div className='border rounded-lg flex flex-col items-center gap-4 w-[175px] h-[150px]'>
            <div className='flex items-center justify-center'>
                <img src={icon} alt='Icon' className='w-10 h-10' />
            </div>
            <div className='flex gap-2'>
                <div className='ml-auto flex items-start'>
                    <img src={actionIcon} alt='Action Icon' className='w-6 h-6' />
                </div>
                <div className='flex flex-col'>
                    <p className='font-medium text-[14px] leading-[22px]'>{title}</p>

                    {isInstant && (
                        <p className='bg-[#DAE9F7] rounded-md p-0.5 inline-block w-[105px] text-[12px] text-[#004B8D] font-normal'>
                            Instant Activation
                        </p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default function OnboardingHome() {
    const navigate = useNavigate()
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)

    useEffect(() => {
        if (
            newStepConfiguration[merchantDetails?.onboarding_status?.kwik_checkout_app]?.id >
            newStepConfiguration['password_setup']?.id || merchantDetails?.country?.toLowerCase() !== 'india'
        ) {
            navigate('/onboarding/cod-setup');
        } else if (!merchantDetails?.onboarding_status?.kwik_checkout_app) {
            navigate('/shopify-app/settings');
        }
    }, [])

    return (
        <Layout style={{ margin: '5rem 8rem', boxShadow: '4px 6px 16px 4px rgba(0, 0, 0, 0.2)'}} className="rounded-md">
            <Content>
                <Row gutter={16} style={{ height: '100%', margin: '0px' }}>
                    <Col xs={24} sm={24} md={6} lg={6} style={{ padding: '0' }}>
                        <div className='flex flex-col h-full bg-[#E6EEF5]'>
                            <img src={leftTop} alt='left top' />
                            <div className='flex flex-col p-8 justify-around h-full'>
                                <img src={freeCodOrder} alt='free cod' />
                                <div  className='mt-4'>
                                    <Tooltip
                                        title="Unlimited free Checkouts for the first 30 days or until KYC completion (whichever comes first)." 
                                        placement='bottom' 
                                        overlayInnerStyle={{ backgroundColor: "white", color: "black", width: 275, borderRadius: '0.5rem', padding: '0.5rem', boxShadow: '1px 1px 1px 1px rgb(102, 112, 133)'}}
                                    >
                                        <span style={{ background: "linear-gradient(90deg, rgba(0, 75, 141, 0.2) 0%, rgba(0, 75, 141, 0) 100%)", color: 'rgba(0, 75, 141, 1)', fontSize: '20px', fontWeight: '600', lineHeight: '26px', cursor: "pointer", padding: '0.2rem 0rem' }} >Free*</span>
                                    </Tooltip>
                                    <div style={{fontSize: '20px', fontWeight: '600', lineHeight: '28px'}} className='mt-1' ><span style={{ color: 'rgba(0, 75, 141, 1)' }}>30 days trial</span> for checkout</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={18} lg={18} style={{ padding: '0' }}>
                        <div className='flex flex-col justify-around h-full px-10'>
                            <div className='font-inter font-bold text-[36px] leading-[46px] tracking-[-1.2%]'>
                                Reduced RTO | Higher Conversions <br />| Faster Checkout
                            </div>
                            <div className='w-full flex flex-col gap-10'>
                                <p className='font-semibold text-[20px] leading-[28px]'>In just 4 simple steps</p>
                                <div className='flex justify-between'>
                                    {Steps.map((step, index) => (
                                        <Step
                                            key={index}
                                            icon={step.icon}
                                            actionIcon={step.stepIcon}
                                            title={step.text}
                                            isInstant={step.isInstant}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div className='flex flex-col gap-4'>
                                <Button
                                    variant='primary'
                                    className='w-full p-6 flex items-center justify-center'
                                    onClick={() => {
                                        navigate('/onboarding/cod-setup')
                                        logEvent(
                                            'get_started_clicked',
                                            'click',
                                            'get-started',
                                            userDetails?.email,
                                            merchantDetails?.m_id,
                                            merchantDetails?.short_name,
                                            userDetails?.name,
                                        )
                                    }}
                                >
                                    <p className='font-bold text-[16px]'>Let's Get Started</p>
                                </Button>
                                <div className="text-left">
                                    <b className="block ml-7">Charges after the trial period:</b>
                                    <ul className="space-y-1">
                                        <li className="font-normal text-[12px] text-[#004B8D]">
                                            <b>Completed KYC:</b> 2.5% charges (including payment gateway charges) applies to all prepaid orders.
                                        </li>
                                        <li className="font-normal text-[12px] text-[#004B8D]">
                                            <b>Incomplete KYC:</b> 2.5% charges applies to all prepaid orders + your payment gateway charges.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}