import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import {
    addBreadcrumb,
    convertIsoToLocaleString,
    filterDateFormatter,
    makeAPICall,
    setBreadcrumb,
    updateBreadcrumbs,
} from '@gokwik/utilities'
import { Table, Row, Col, Button, Tag, Modal, Tooltip, ColumnProps, message, PlusCircleOutlined } from 'gokwik-ui-kit'
import { previousCustomerFilters } from '@store/filters/selectors'
import { saveFiltersData } from '@store/filters'
import RenderSearchFilters from '@library/components/search-filter'
import { EyeOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import AddDrawer from './addDrawerPermissions'
const today = dayjs()
const formattedDate = filterDateFormatter(today)

export default function ({isModalOpen, setModal}) {
    const [total, setTotal] = useState(0)
    const [permissionData, setPermissionData] = useState([])
    const prevFilters = useSelector(previousCustomerFilters)
    const [openDrawer, setOpenDrawer] = useState(false)
    const [parameters, setParameters] = useState<any>({
        page: 1,
        sortKey: 'created_at',
        sortOrder: 25,
        pageSize: 25,
    })
    const [customerModalData, setCustomerModalData] = useState(null)
    const [modalOpen, setModalOpen] = useState<any>(false)

    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ')
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
        }
        return splitStr.join(' ')
    }

    const columns: ColumnProps<any>[] = [
        {
            title: <span>Name</span>,
            dataIndex: 'name',
            width: 30,
            ellipsis: true,
        },
        {
            title: <span>Guard</span>,
            dataIndex: 'guard_name',
            width: 20,
            ellipsis: true,
        },
        {
            title: <span>Description</span>,
            dataIndex: 'description',
            width: 25,
            ellipsis: true,
            render: (text) => (
                <Tooltip title={text}>
                  <span className='truncate text-ellipsis max-w-60 overflow-hidden whitespace-nowrap inline-block'>
                    {text}
                </span>
                </Tooltip>
              ),
        },
        {
            title: <span>Created At</span>,
            dataIndex: 'created_at',
            width: 30,
            ellipsis: true,
            render: (text: string) => (
                <Tooltip title={convertIsoToLocaleString(text)}>{convertIsoToLocaleString(text)}</Tooltip>
            ),
            sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
            sortDirections: ['ascend', 'descend'],
        },
    ]


    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'rolesAndPermissions',
                href: '/general/roles-and-permissions',
                text: 'Roles And Permissions',
            },
        ])
    }, [])

    async function makePermissionsApiCall() { 
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + '/api/dashboard/permissions/all',
                params: {
                    ...parameters,
                },
            })

            if (response.data?.data?.permissions?.length > 0) {
                setPermissionData(response.data?.data?.permissions)
                setTotal(response.data?.data?.totalCount)
            } else {
                setPermissionData([])
                setTotal(0)
            }
        } catch (err) {
            console.warn(err)
        }
    }

    const reset = (e) => {
        e.preventDefault()
        setParameters({
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
        })
        setTotal(0)
    }
    const handleSearchClick = (e) => {
        e?.preventDefault()
        makePermissionsApiCall()
    }

    useEffect(() => {
        if (Object.keys(prevFilters).length && JSON.stringify(prevFilters) !== JSON.stringify(parameters))
            setParameters({ ...prevFilters })
    }, [prevFilters])

    useEffect(() => {
        if (parameters.page >= 1) {
            makePermissionsApiCall()
        }
    }, [parameters.page])

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
    }

    return (
        <>
            <div className='w-full h-full'>
                <div className=' bg-white rounded overflow-clip'>
                    <Table
                        columns={columns}
                        dataSource={permissionData || []}
                        pagination={{
                            current: parameters.page, // Current page number
                            pageSize: parameters.pageSize, // Number of items to display per page
                            total: total, // Total number of items in the data array
                            showSizeChanger: false,
                            onChange: handlePaginationChange,
                            position: ['topLeft', 'bottomLeft'],
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                        }}
                    />
                </div>
            </div>
            { isModalOpen === 'Permissions' && (
                <AddDrawer
                title = {'Add Permission'}
                open = {!!isModalOpen}
                onClose={(refetch: boolean) => {
                    setModal(null)
                    if(refetch) makePermissionsApiCall();
                }}
                />

                )
            }
        </>
    )
}
