import React, { useEffect, useState } from 'react'

const Counter = ({ end, suffix }) => {
    const [count, setCount] = useState(0)

    useEffect(() => {
        let start = 0
        const duration = 1500 // Total duration for animation
        const increment = end / (duration / 16) // Adjust increment for smooth animation
        let frameId

        const animate = () => {
            start += increment
            if (start >= end) {
                setCount(end)
                cancelAnimationFrame(frameId)
            } else {
                setCount(start)
                frameId = requestAnimationFrame(animate)
            }
        }

        frameId = requestAnimationFrame(animate)

        return () => cancelAnimationFrame(frameId)
    }, [end])

    return (
        <div className='text-center'>
            {count ? count.toLocaleString() : '-'}&nbsp;{suffix}
        </div>
    )
}

export default Counter
