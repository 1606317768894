import React, { useEffect, useState } from 'react';
import { Checkbox, Button, Divider, DownOutlined } from "gokwik-ui-kit";
import previewPlaceholder3 from '@library/images/icons/checkoutStep3.png'; 
import HowToStepsWithPreview from '../CommonComponents/howToStepsAndPreview';
import darkExternalLink from '@library/images/icons/dark-external-link.svg';
import lightExternalLink from '@library/images/icons/light-external-link.svg';
import { useSelector } from 'react-redux';
import { getMerchantDetails, getUserDetails } from '@store/user/selectors';
import ButtonsWorkingModal from './ButtonsWorkingModal';
import ContactSupportModal from './ContactSupportModal';
import ContactSupportSuccessModal from './ContactSupportSuccessModal';
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents';
import { updateMerchantOnboardingCheckoutConfigsStatus } from '../CommonComponents/Utils';
import CheckoutStepsHeader from '../CommonComponents/checkoutStepsHeader';
import { navigateToUrl } from 'single-spa';
import { fetchUserDetails } from '@store/user';
import { useAppDispatch } from '@library/utilities/hooks'

const CheckoutConfigStep3 = ({ onStepComplete, currentStep, expandedStep, toggleStep }) => {
    const [siteVisited, setSiteVisited] = useState(false);
    const [showButtonsWorkingModal, setShowButtonsWorkingModal] = useState(false);
    const [showContactSupportModal, setShowContactSupportModal] = useState(false);
    const [contactSupportSuccessModal, setContactSupportSuccessModal] = useState(false);
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)
    const [showContent, setShowContent] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (expandedStep === 3 && currentStep !== 3) {
            setSiteVisited(true);
        }
        if (expandedStep == 3) {
            setShowContent(false);
            setTimeout(() => setShowContent(true), 100);
        }
    }, [expandedStep]);

    const onCloseButtonsWorkingModal = () => {
        setShowButtonsWorkingModal(false);
        updateMerchantOnboardingCheckoutConfigsStatus('gk_checkout_buttons_working', () => {
            dispatch(fetchUserDetails());
            onStepComplete()
        }, merchantDetails)
    }

    const onCloseContactSupportModal = () => {
        setShowContactSupportModal(false);
        setContactSupportSuccessModal(true);
    }

    const onCloseContactSupportSuccessModal = () => {
        setContactSupportSuccessModal(false);
        updateMerchantOnboardingCheckoutConfigsStatus('gk_checkout_buttons_not_working', () => {
            dispatch(fetchUserDetails());
            onStepComplete()
        }, merchantDetails)
    }

    const handleLaunchShpDashboard = () => {
        logEvent(
            siteVisited ? 'verify_buttons_clicked_again' : 'verify_buttons_clicked',
            'click',
            'checkout-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { Step: "checkout 3" }
        )
        setSiteVisited(true)
        window.open('https://' + `${merchantDetails?.website}` + '/admin/themes', '_blank');
    }

    const buttonsAreNotWorking = () => {
        setShowContactSupportModal(true)
        logEvent(
            'buttons_not_working_clicked',
            'click',
            'checkout-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { Step: "checkout 3" }
        )
    }

    const buttonsAreWorking = () => {
        setShowButtonsWorkingModal(true)
        logEvent(
            'buttons_working_clicked',
            'click',
            'checkout-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { Step: "checkout 3" }
        )
    }

    const steps = [
        { 
            description: (<>Go to your <b>“Shopify Dashboard”</b>, click on <b>“Online Store”</b>, then click <b>“Themes Library.”</b></>),
            step: 3,
        },
        { 
            description: (<>Click on <b>“Preview”</b> duplicated theme and check if <b>“GoKwik Pop-up”</b> appears when clicking Checkout/Buy now buttons on respective pages.</>) 
        },
        { 
            description: (<>Click on <b>“Buttons are working”</b> if everything is working correctly or click on <b>“Buttons are not working”</b> if not working correctly and click on <b>“Verify”</b> Button.</>) 
        }
    ]

    return (
        <div className="border border-[#D0D5DA] rounded-lg bg-white w-[74vw]">
            <CheckoutStepsHeader step={'Step 3'} heading={'Verify GoKwik Checkout'} toggleStep={toggleStep} stepId={3} currentStep={currentStep} />

            {expandedStep === 3 ? (
                <div 
                    style={{
                        opacity: showContent ? 1 : 0,
                        transform: showContent ? 'translateY(0)' : 'translateY(20px)',
                        transition: 'opacity 0.5s ease, transform 0.5s ease',
                    }}
                >
                    <Divider className='m-0'/>
                    <HowToStepsWithPreview steps={steps} previewImage={previewPlaceholder3} videoUrl={'https://pdp.gokwik.co/sa-login-ui/assets/3.VerifyGokwikCheckoutButtons.mp4'} page={'checkout'}/>
                    <Divider  className='m-0' />
                    <div className={`flex ${siteVisited ? "" : "flex-row-reverse"} justify-between items-center p-3`}>
                        <Button
                            onClick={handleLaunchShpDashboard}
                            variant={siteVisited ? 'default' : 'primary'}
                            className="flex items-center"
                            size="large"
                        >
                            Launch Shopify Dashboard <img style={{ marginLeft: '0.4rem' }} src={siteVisited ? darkExternalLink : lightExternalLink} />
                        </Button>
                        {siteVisited && (
                            <div className="flex items-center gap-3">
                                <Button disabled={(expandedStep === 3 && currentStep !== 3)} onClick={buttonsAreWorking} type="primary" size="large">
                                    Buttons are working
                                </Button>
                                <Button disabled={(expandedStep === 3 && currentStep !== 3)} onClick={buttonsAreNotWorking} type="primary" size="large">
                                    Buttons are not working
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            ) : "" }
            {showButtonsWorkingModal && <ButtonsWorkingModal showButtonsWorkingModal={showButtonsWorkingModal} onClose={onCloseButtonsWorkingModal} setShowButtonsWorkingModal={setShowButtonsWorkingModal} />}

            {showContactSupportModal && <ContactSupportModal showContactSupportModal={showContactSupportModal} onClose={onCloseContactSupportModal} setShowContactSupportModal={setShowContactSupportModal} />}
            {contactSupportSuccessModal && <ContactSupportSuccessModal contactSupportSuccessModal={contactSupportSuccessModal} onClose={onCloseContactSupportSuccessModal}/>}
        </div>
    )
};

export default CheckoutConfigStep3;
