import React from 'react'

export default function () {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
            <path
                d='M13.667 6.79594H11.7403C10.1603 6.79594 8.87366 5.50927 8.87366 3.92927V2.0026C8.87366 1.63594 8.57366 1.33594 8.20699 1.33594H5.38033C3.32699 1.33594 1.66699 2.66927 1.66699 5.04927V10.9559C1.66699 13.3359 3.32699 14.6693 5.38033 14.6693H10.6203C12.6737 14.6693 14.3337 13.3359 14.3337 10.9559V7.4626C14.3337 7.09594 14.0337 6.79594 13.667 6.79594ZM7.68699 9.0226C7.58699 9.1226 7.46033 9.16927 7.33366 9.16927C7.20699 9.16927 7.08033 9.1226 6.98033 9.0226L6.50033 8.5426V11.3359C6.50033 11.6093 6.27366 11.8359 6.00033 11.8359C5.72699 11.8359 5.50033 11.6093 5.50033 11.3359V8.5426L5.02033 9.0226C4.82699 9.21594 4.50699 9.21594 4.31366 9.0226C4.12033 8.82927 4.12033 8.50927 4.31366 8.31594L5.64699 6.9826C5.69366 6.9426 5.74032 6.90927 5.79366 6.8826C5.80699 6.87594 5.82699 6.86927 5.84033 6.8626C5.88033 6.84927 5.92033 6.8426 5.96699 6.83594C5.98699 6.83594 6.00033 6.83594 6.02033 6.83594C6.07366 6.83594 6.12699 6.84927 6.18033 6.86927C6.18699 6.86927 6.18699 6.86927 6.19366 6.86927C6.24699 6.88927 6.30033 6.92927 6.34033 6.96927C6.34699 6.97594 6.35366 6.97594 6.35366 6.9826L7.68699 8.31594C7.88032 8.50927 7.88032 8.82927 7.68699 9.0226Z'
                fill='#004B8D'
            />
            <path
                d='M11.6204 5.87365C12.2538 5.88032 13.1338 5.88032 13.8871 5.88032C14.2671 5.88032 14.4671 5.43365 14.2004 5.16699C13.2404 4.20032 11.5204 2.46032 10.5338 1.47365C10.2604 1.20032 9.78711 1.38699 9.78711 1.76699V4.09365C9.78711 5.06699 10.6138 5.87365 11.6204 5.87365Z'
                fill='#004B8D'
            />
        </svg>
    )
}
