import {
    convertIsoToLocaleString,
    makeAPICall,
    titleCase,
    updateBreadcrumbs,
    validatePermission,
} from '@gokwik/utilities'
import RenderSearchFilters from '@library/components/search-filter'
import { saveFiltersData } from '@store/filters'
import { previousUsersFilters } from '@store/filters/selectors'
import { Button, Col, ColumnProps, EditOutlined, Row, Table, Tag, Tooltip, message } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useAppDispatch } from '@library/utilities/hooks'
import { PermissionValues } from '@library/utilities/constants/constants'
import user from '@store/user'
import { handleError } from '@library/utilities/helpers/handleError'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

export default function ({ takeAction, isMerchantUser, userData, makeUserCall }) {
    const prevFilters = useSelector(previousUsersFilters)
    const [parameters, setParameters] = useState<any>({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        ...prevFilters,
    })
    const [total, setTotal] = useState(0)
    const [usersData, setUsersData] = useState([])
    const dispatch = useAppDispatch()
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)

    const getExpirationDate = (login_date) => {
        let date_1 = new Date()
        let date_2 = new Date(login_date)

        const days = (date_1, date_2) => {
            let difference = date_1.getTime() - date_2.getTime()
            let TotalDays = 90 - Math.ceil(difference / (1000 * 3600 * 24))
            return TotalDays <= 0 ? 0 : TotalDays
        }
        return days(date_1, date_2)
    }

    const columns: ColumnProps<any>[] = [
        {
            title: <span>Full Name</span>,
            dataIndex: 'name',
            width: 35,
            ellipsis: true,
            render: (text) => <span>{`${titleCase(text)}`}</span>,
        },
        {
            title: <span>Email</span>,
            dataIndex: 'email_mask',
            width: 40,
            ellipsis: true,
            render: (text: string) => (
                <Tooltip title={text} placement='topLeft'>
                    {text}
                </Tooltip>
            ),
        },

        {
            title: <span>Phone</span>,
            dataIndex: 'phone_mask',
            width: 25,
            render: (text: string) => <span>{text}</span>,
        },

        {
            title: <span>Role</span>,
            dataIndex: 'role',
            width: 20,
            render: (text: string) => <span>{text}</span>,
            align: 'left',
        },
        {
            title: <span>Last Login</span>,
            dataIndex: 'last_login',
            width: 25,
            render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
            align: 'left',
        },
        {
            title: <span>Password Expiry</span>,
            dataIndex: 'last_login',
            width: 20,
            render: (text: string) => <span>{getExpirationDate(text)}</span>,
            align: 'left',
        },
        {
            title: <span>Status</span>,
            dataIndex: 'status',
            width: 20,
            render: (text: string) => (
                <span>
                    {text === 'suspended' ? (
                        <Tag
                            variant={'error'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Suspended
                        </Tag>
                    ) : (
                        <Tag
                            variant={'success'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Active
                        </Tag>
                    )}
                </span>
            ),
            align: 'center',
        },
        {
            title: <span>Created At</span>,
            dataIndex: 'created_at',
            width: 25,
            ellipsis: true,
            render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
            sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: <span>Updated At</span>,
            dataIndex: 'updated_at',
            render: (text: string) => <span>{convertIsoToLocaleString(text)}</span>,
            sorter: (a, b) => (dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? -1 : 1),
            sortDirections: ['ascend', 'descend'],
            width: 25,
        },
        ...(validatePermission(PermissionValues.users.addUser)
            ? [
                  {
                      title: <span>Actions</span>,
                      dataIndex: 'id',
                      width: 20,
                      render: (id, record) => {
                          return (
                              <Row className={`m-0 `}>
                                  <Tooltip title='Edit User'>
                                      <EditOutlined
                                          onClick={() => takeAction(id, record, 'update_user')}
                                          className='ml-4 text-error-700'
                                      />
                                  </Tooltip>
                              </Row>
                          )
                      },
                  },
              ]
            : []),
    ]

    useEffect(() => {
        logEvent(
            'admin_teams_clicked',
            'click',
            'Admin Teams',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
        )
    }, [])

    async function makeUsersApiCall(parameters: any) {
        if (!parameters.page) return
        dispatch(saveFiltersData('users', { ...parameters }))

        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getUsers,
                params: parameters,
            })

            if (response.data?.data?.users?.length > 0) {
                setUsersData(response.data?.data?.users)
                setTotal(response.data?.data?.total_count)
            } else {
                setUsersData([])
                setTotal(0)
            }
        } catch (error) {
            handleError(error)
        }
    }

    const handleSearchClick = (e) => {
        e?.preventDefault()

        if (!isMerchantUser && parameters?.['status']) {
            makeUsersApiCall({ ...parameters, page: 1 })
        } else if (isMerchantUser) {
            makeUsersApiCall({ ...parameters, page: 1 })
        } else {
            message.error(`${isMerchantUser ? 'Please enter any search parameter' : 'Status is required'}`)
        }
    }

    useEffect(() => {
        if (parameters.page >= 1) {
            makeUsersApiCall({ ...parameters })
        }
    }, [makeUserCall])

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'teams',
                href: '/general/teams',
                text: 'Teams',
            },
        ])
    }, [])

    useEffect(() => {
        if (Object.keys(prevFilters).length && JSON.stringify(prevFilters) !== JSON.stringify(parameters))
            setParameters({ ...prevFilters })
    }, [prevFilters])

    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
        makeUsersApiCall({ ...parameters, page: current, pageSize })
    }
    const reset = (e) => {
        e.preventDefault()
        const newParams = {
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
        }
        setParameters({ ...newParams })
        makeUsersApiCall(newParams)
    }

    return (
        <>
            <div className='w-full h-full'>
                <Row className='bg-white rounded'>
                    <RenderSearchFilters
                        values={parameters}
                        setValues={(data, reset) =>
                            setParameters((prev: any) => (reset ? { ...data } : { ...prev, ...data }))
                        }
                        page='users'
                        onSearch={handleSearchClick}
                        onReset={reset}
                    />
                </Row>

                <div className='mt-5 bg-white rounded overflow-clip'>
                    <Table
                        columns={columns}
                        dataSource={usersData || []}
                        pagination={{
                            current: parameters.page, // Current page number
                            pageSize: parameters.pageSize, // Number of items to display per page
                            total: total, // Total number of items in the data array
                            showSizeChanger: false,
                            onChange: handlePaginationChange,
                            position: ['topLeft', 'bottomLeft'],
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                        }}
                        // scroll={{ x: 1150 }}
                    />
                </div>
            </div>
        </>
    )
}
