import React from 'react';
import { CustomerServiceOutlined } from '@ant-design/icons';
import logoGokwik from '@library/images/common/logo-gk-white.svg'
import securedIcon from '@library/images/icons/secured_icon.svg'
import verifiedIcon from '@library/images/icons/verified_icon.svg'
import pciDssIcon from '@library/images/icons/pci_dss_icon.svg'

const PlansSection = ({heading, subHeading}) => {
    return (
        <div style={{ height: 'calc(100vh - 70px)'}} className="bg-[#0B1B2B] flex flex-col items-start justify-between p-6 -ml-2">
            <img src={logoGokwik} alt="GoKwik Logo" className="w-20" />
            <div>
                <div className="text-white font-bold text-3xl mb-4">
                    {heading}
                </div>
                <div className="text-[#A0AEC0] text-sm leading-[18px]">
                    {subHeading}
                </div>
            </div>
            <div className="w-full flex items-center justify-start gap-3 text-26px">
                <img src={pciDssIcon} alt="PCI DSS Certified" />
                <img src={verifiedIcon} alt="Verified" />
                <img src={securedIcon} alt="Secured" />
            </div>
        </div>
    );
};

export default PlansSection;
