import React, { memo, useState } from 'react'
import { FilterCreatorModel } from '@library/utilities/interface'
import styles from './filter-creator.module.css'
import { Select as AntdSelect, Button, Checkbox, Dropdown, MenuProps } from 'gokwik-ui-kit'

const { Option } = AntdSelect
type Props = {
    filterCreatorState?: FilterCreatorModel
    options?: { key: string; title: string }[]
    selectedValues?: string[]
    setSelectedValues?: (key: string) => void
    addFilterBtnRef?: React.MutableRefObject<any>
    handleFilterCreatorStateChange?: (values: string, key?: any) => void
}

const Component: React.FC<Props> = (props) => {
    const {
        filterCreatorState,
        addFilterBtnRef,
        handleFilterCreatorStateChange,
        options,
        selectedValues,
        setSelectedValues,
    } = props
    const [openDropDown, setOpenDropDown] = useState(false)
    const [duplicateListener, setDuplicateListener] = useState<boolean>(false)

    const generateMenuProps = React.useMemo(() => {
        const menu: MenuProps['items'] = []
        if (options?.length) {
            return options.map((option) => ({
                key: option.key,
                label: (
                    <>
                        <Checkbox
                            key={option?.key}
                            onClick={(e) => setSelectedValues?.(option.key)}
                            checked={selectedValues.includes(option.key)}
                        >
                            {option?.title}
                        </Checkbox>
                    </>
                ),
            }))
        }
        filterCreatorState?.options &&
            filterCreatorState.options?.length > 0 &&
            filterCreatorState.options?.forEach((filter, index) => {
                menu.push({
                    key: filter?.id,
                    label: (
                        <>
                            <Checkbox
                                key={filter?.id}
                                onClick={(e) => {
                                    if (duplicateListener) {
                                        e.stopPropagation()
                                    }
                                    setDuplicateListener(false)
                                }}
                                checked={filterCreatorState?.value?.map((item) => `${item}`)?.includes(`${filter?.id}`)}
                            >
                                {filter?.text}
                            </Checkbox>
                        </>
                    ),
                })
            })
        return menu
    }, [filterCreatorState, selectedValues])

    const handleMenuClick: MenuProps['onClick'] = (info) => {
        console.log({ info })
        handleFilterCreatorStateChange?.(info?.key, null)
        // addFilterBtnRef.current.focus()
        // setOpenDropDown(true)
    }

    const handleOpenChange = (nextOpen, info) => {
        if (info?.source === 'trigger' || nextOpen) {
            setOpenDropDown(nextOpen)
        }
    }

    return (
        <div className={styles.addMoreFilterButton}>
            {generateMenuProps && (
                <Dropdown
                    // trigger={['hover']}
                    // open={openDropDown}
                    // onOpenChange={() => setOpenDropDown(true)}
                    className={`${styles.filterCreator} w-full`}
                    menu={{
                        items: generateMenuProps,
                        onClick: (info) => handleMenuClick(info),
                    }}
                    onOpenChange={handleOpenChange}
                    open={openDropDown}
                >
                    <Button className='w-full' variant='default'>
                        + Add More Filters
                    </Button>
                </Dropdown>
            )}
        </div>
    )
}

export const FilterCreatorComponent = memo(Component)
