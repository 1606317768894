import { Layout, Row, Col, message } from 'gokwik-ui-kit'
import PlansSection from './upsell-flow/leftPanel';
import BasicPlanCard from "./upsell-flow/basicPlan";
import AdvancePlanCard from './upsell-flow/advancePlan';
import whiteStars from '@library/images/icons/whiteStars.svg';
import contactSales from '@library/images/icons/contact_sales.svg';
import { makeAPICall } from '@gokwik/utilities';
import APIEndPoints from '@library/utilities/constants/apiEndpoints';
import { useSelector } from 'react-redux';
import { getMerchantDetails } from '@store/user/selectors'
import { useAppDispatch } from '@library/utilities/hooks';
import { fetchUserDetails } from '@store/user';
import { navigateToUrl } from 'single-spa';
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { stepConfiguration } from '@pages/onboarding/stepsConfig'
const { Content } = Layout;

export default function GetStarted() {
    const merchantDetails = useSelector(getMerchantDetails)
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage()
    const [showActivationModal, setShowActivationModal] = useState(false);
    const showThankyouPage = async () => {
        await updateMerchantOnboardingStatus('contact_sales_clicked')
    }

    const updateMerchantOnboardingStatus = async (status, selectedPlans = []) => {
        try {
            let payload = status ? { current_onboarding_status: status } : { gokwik_products: selectedPlans }
            const response = await makeAPICall({
                method: 'post',
                url:
                    process.env.REACT_APP_BASE_URL +
                    APIEndPoints.updateMerchantOnboardingStatus +
                    merchantDetails?.m_id,
                payload
            })
            if (response?.data?.status_code === 200 && status) {
                navigateToUrl('/onboarding/contactsales')
            } else {
                messageApi.open({
                    type: 'error',
                    content: 'Something went wrong. Try Later !',
                })
            }
            if (selectedPlans?.length === 2) {
                setShowActivationModal(true);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (
            stepConfiguration[merchantDetails?.onboarding_status?.kwik_checkout_app]?.id >
            stepConfiguration['password_setup']?.id
        ) {
            
            navigate('/onboarding/kc/onboarding-steps')
        }
    }, [])

    return (
        <Layout className='pt-16'>
            <Content >
                <Row gutter={16} style={{ border: '4px solid #0B1B2B', height: '100%', margin: "0px" }}>
                    <Col xs={24} sm={24} md={6} lg={6}>
                        <PlansSection heading='Plans' subHeading='GoKwik offers tailored solutions for your business with two powerful plans.' />
                    </Col>

                    <Col xs={24} sm={24} md={18} lg={18} >
                        <Row style={{ margin: "0px" }} gutter={16}>
                            <Col xs={24} sm={24} md={12} lg={12}>
                                <BasicPlanCard updateMerchantOnboardingStatus={updateMerchantOnboardingStatus}/>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={12}>
                                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', height: "3.5rem", background: "rgba(0, 75, 141, 1)", padding: '8px 16px', borderRadius: '10px 10px 0px 0px', margin: '8px' }}>
                                    <img src={whiteStars} style={{marginBottom: "1rem", marginRight: "0.5rem"}} />
                                    <span style={{ fontWeight: 500, color: '#fff', marginBottom: "1rem" }}>Recommended</span>
                                </div>
                                <AdvancePlanCard updateMerchantOnboardingStatus={updateMerchantOnboardingStatus} showActivationModal={showActivationModal} setShowActivationModal={setShowActivationModal} />
                            </Col>
                        </Row>
                        <div style={{ border: '0.8px solid rgba(0, 0, 0, 0.15)', borderRadius: '0.5rem', width: "50%", background: "#fff"}} className="flex items-center  justify-between mx-auto p-2 mt-[-10px]">
                            <div>
                                <div className="font-semibold ml-2">Sales beyond ₹40 Lakh/month?</div>
                                <div className="mt-1 ml-2" style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.65)' }}>Let’s talk about a tailored approach for your business.</div>
                            </div>
                            <button style={{
                                        background: 'none', 
                                        border: 'none', 
                                        padding: 0,
                                        cursor: 'pointer',
                                        color: 'rgba(0, 75, 141, 1)'
                                    }} className="font-semibold mr-2" onClick={showThankyouPage}>
                                Contact Sales <img className='ml-2' src={contactSales} width={20} height={20} />
                            </button>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}
