import CheckoutConfigStep1 from './CheckoutConfigStep1';
import CheckoutConfigStep2 from './CheckoutConfigStep2';
import CheckoutConfigStep3 from './CheckoutConfigStep3';
import { useEffect, useState } from 'react';

const ConfigStepsWrapper = ({currentStep, setCurrentStep }) => {
    const [expandedStep, setExpandedStep] = useState(currentStep);

    useEffect(() => {
        setExpandedStep(currentStep)
    }, [currentStep])
    
    const handleNextStep = () => {
        setExpandedStep(currentStep);
    };

    const toggleStep = (step: number) => {
        setExpandedStep(step);
    }

    return (
        <div className="flex flex-col gap-4 h-[73vh] overflow-y-auto pb-2" >
            <CheckoutConfigStep1 onStepComplete={handleNextStep} currentStep={currentStep} expandedStep={expandedStep} toggleStep={toggleStep} />
            <CheckoutConfigStep2 onStepComplete={handleNextStep} currentStep={currentStep} expandedStep={expandedStep} toggleStep={toggleStep} />
            <CheckoutConfigStep3 onStepComplete={handleNextStep} currentStep={currentStep} expandedStep={expandedStep} toggleStep={toggleStep} />
        </div>
    );
};

export default ConfigStepsWrapper;
