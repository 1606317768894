export const passwordValidationMessages = {
    isLong: 'Minimum 8 characters',
    casingCheck: 'At least one lowercase letter and one uppercase letter',
    alphaNumeric: 'At least one number and one letter',
    specialCharacter: 'At least one special character',
}
export const passwordValidation = (password: string): { [key: string]: boolean } => {
    const validations = {
        isLong: false,
        casingCheck: false,
        alphaNumeric: false,
        specialCharacter: false,
    }
    validations.isLong = password.length >= 8
    validations.casingCheck = /(?=.*[a-z])/.test(password) && /(?=.*[A-Z])/.test(password)
    validations.alphaNumeric = /(?=.*\d)/.test(password)
    validations.specialCharacter = /(?=.*\W])/.test(password) || /[-+_!@#$%^&*.,?]/.test(password)
    return validations
}
