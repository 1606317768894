import React, { useEffect, useState } from 'react'

// Import Styles
import './loader.css'

interface LoaderData {
    count: number
    text: string
}

const Loader: React.FC = () => {
    const [loader, setLoader] = useState<LoaderData>({ count: 0, text: '' })

    useEffect(() => {
        const handleLoaderEvent = ({ detail }: CustomEvent) => {
            setLoader((prev) => ({
                count: detail.data.reset ? 0 : prev.count + detail.data.count,
                text: detail.data.text || prev.text,
            }))
        }

        window.addEventListener('loader', handleLoaderEvent)

        return () => {
            window.removeEventListener('loader', handleLoaderEvent)
        }
    }, [])

    return loader.count > 0 ? (
        <div className='loader-container'>
            <div className='loader-icon-wrapper'>
                <div className='loader-spinner'></div>
                <div className='loader-text'>{loader.text}</div>
            </div>
        </div>
    ) : null
}

export default Loader
