import ReactDOM from 'react-dom'

const TestBanner = () => {
    return ReactDOM.createPortal(
        <div className='fixed inset-x-0 top-0 flex justify-center z-10'>
            <div className='w-full h-2 fixed top-0 z-2 bg-yellow-400'></div>
            <div className='font-inter text-sm bg-yellow-400 text-black w-48 h-14 absolute top-0 left-1/2 transform -translate-x-1/2 -mt-8 rounded-full overflow-hidden'>
                <p className='p-3 mt-6 text-xs text-center'>YOU'RE IN TEST MODE</p>
            </div>
        </div>,
        document.body,
    )
}

export default TestBanner
