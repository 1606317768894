import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useAppDispatch, useInterval } from '@library/utilities/hooks'
import Plans from '@pages/onboarding/pricing-details/plans'
import { getMerchantDetails, getUserData } from '@store/user/selectors'
import { Alert } from 'gokwik-ui-kit'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchUserDetails } from '@store/user'
import { getAllPricingPlans, getSelectedPlan } from './helper'
import { navigateToUrl } from 'single-spa'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

export const PricingPlan = ({ navigateToStep }) => {
    const [showAlert, setShowAlert] = useState(false)
    const merchant_details = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserData)
    const [interval, initiateInterval, removeInterval] = useInterval(() => fetchShopifyApprovalStatusOfMerchant(), 2000)
    const [planButtonStatus, setPlanButtonStatus] = useState(false)
    const [subscriptionsPlans, setSubscriptionsPlans] = useState([])
    const [selectedPlan, setSelectedPlan] = useState('')
    const dispatch = useAppDispatch()

    const getAllSelectedPlan = async (mid: string = null) => {
        const res = await getSelectedPlan(mid)
        setSelectedPlan(res)
    }
    const getPlans = async () => {
        const response = await getAllPricingPlans()
        if (response.success) {
            setSubscriptionsPlans(response.data.data)
        }
    }

    const completeOnboarding = async (merchant_details, userDetails) => {
        dispatch(fetchUserDetails())
        navigateToUrl('/kwikpass/configuration')
        logEvent(
            'kp_live',
            'click',
            'final_screen',
            userDetails?.userDetails?.email,
            merchant_details?.m_id,
            merchant_details?.short_name,
        )
    }
    const intervalHandling = useCallback(
        (result) => {
            const status_code = result?.data?.status_code
            const current_onboarding_status = result?.data?.data?.current_onboarding_status
            // dispatch(fetchUserDetails())
            if (status_code === 200 && current_onboarding_status === 'pricing_details_confirmed') {
                setPlanButtonStatus(false)
                removeInterval()
                navigateToStep(null, 1)
            } else if (current_onboarding_status === 'pricing_approval_pending') {
                !planButtonStatus && setPlanButtonStatus(true)
                initiateInterval()
                // Remove interval after 30 seconds
                setTimeout(() => {
                    removeInterval()
                    setPlanButtonStatus(false)
                }, 30000)
            } else if (current_onboarding_status === 'setup_completed') {
                completeOnboarding(merchant_details, userDetails)
                removeInterval()
            } else {
                setPlanButtonStatus(false)
                removeInterval()
            }
        },
        [merchant_details?.onboarding_status?.kwik_checkout_app],
    )

    useEffect(() => {
        if (merchant_details?.onboarding_status?.kwikpass_app === 'pricing_approval_pending') {
            fetchShopifyApprovalStatusOfMerchant()
        }
    }, [])
    useEffect(() => {
        getPlans()
        getAllSelectedPlan(merchant_details.m_id)
    }, [])
    const fetchShopifyApprovalStatusOfMerchant = async () => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url:
                    process.env.REACT_APP_BASE_URL +
                    APIEndPoints.getShopifyApprovalStatusForMerchant +
                    merchant_details?.m_id,
                params: { app_name: 'kwikpass_app' },
                skipLoader: true,
            })

            intervalHandling(response)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (
            merchant_details?.onboarding_status?.kwikpass_app === 'pricing_approval_pending' ||
            merchant_details?.onboarding_status?.kwikpass_app === 'pricing_approval_failed'
        )
            setShowAlert(true)
    }, [merchant_details?.onboarding_status?.kwikpass_app])
    return (
        <div className='mt-6 mx-12'>
            {merchant_details?.onboarding_status?.kwikpass_app === 'pricing_approval_pending' && showAlert && (
                <div className='flex justify-center'>
                    <Alert type='warning' message={'Please wait. Fetching latest payment status from Shopify.'} />
                </div>
            )}
            {merchant_details?.onboarding_status?.kwikpass_app === 'pricing_approval_failed' && showAlert && (
                <div className='flex justify-end'>
                    <Alert
                        type='error'
                        message={'Pricing approval reject. Please select plan and approve to move ahead.'}
                    />
                </div>
            )}
            <Plans
                setShowAlert={setShowAlert}
                navigateToStep={navigateToStep}
                planButtonStatus={planButtonStatus}
                subscriptionsPlans={subscriptionsPlans}
            />
        </div>
    )
}
