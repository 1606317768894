import React, { Ref, useEffect, useMemo, useState } from 'react'

// Import Gokwik UI Kit
import {
    Select,
    Breadcrumb,
    Switch,
    Tooltip,
    Button,
    DownOutlined,
    Modal,
    Input,
    message,
    Radio,
    Avatar,
    Divider,
    PictureOutlined,
    Tag,
    Skeleton,
} from 'gokwik-ui-kit'

// Import Styles
import './header.css'

// Import Icons
import { appRoutes } from '@gokwik/utilities'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
    getMerchantConfigs,
    getMerchantDetails,
    getMerchantPlatform,
    getMode,
    isMerchantSelector,
} from '@store/user/selectors'
import { getUserDetails } from '@store/user/selectors'
import { AllowedMerchant } from '@library/utilities/interface'
import user, { changeMode, fetchUserDetails, logout, switchDashboard, switchMerchant } from '@store/user'
import { useAppDispatch } from '@library/utilities/hooks'
import logo from '@library/images/common/GoKwik_Symbol.svg'
import { navigateToUrl } from 'single-spa'

interface HeaderProps {
    headerRef: any
}

const Header: React.FC<HeaderProps> = ({ headerRef }) => {
    const route = useLocation()
    const merchantConfigs = useSelector(getMerchantConfigs)
    const navigate = useNavigate()
    const [breadCrumbsData, setBreadCrumbsData] = useState<BreadCrumbInterface[]>(appRoutes.getState().appRoutes)
    const isMerchantUser = useSelector(isMerchantSelector)
    const showDashboardToggle = useMemo(() => {
        const selectedProducts = Object.entries(merchantConfigs.selected_products || {}).reduce(
            (acc, [key, value]) => [...acc, ...(value ? [key] : [])],
            [],
        )
        if (
            (merchantConfigs.selected_products?.kwik_pass && selectedProducts.length === 1) ||
            !merchantConfigs.enable_dashboard_switch
        ) {
            return false
        } else return true
    }, [merchantConfigs])
    const platform = useSelector(getMerchantPlatform)
    const userData = useSelector(getUserDetails)
    const allowedMerchants: AllowedMerchant[] = userData?.allowed_merchants
    const activeMerchantId = useSelector(getMerchantDetails)?.id
    const merchant_details = useSelector(getMerchantDetails)
    const mode = useSelector(getMode)
    const dispatch = useAppDispatch()
    const [newDashboard, setNewDashboard] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [merchantList, setMerchantList] = useState([])
    const [searchedList, setSearchedList] = useState([])
    const [searchValue, setSearchValue] = useState(null)
    const [refinedList, setRefinedList] = React.useState([])
    const [searchText, setSearchText] = useState('')
    const [selectedMerchant, setSelectedMerchant] = useState(merchant_details?.m_id)
    const location = useLocation()

    useEffect(() => {
        const unsubscribe = appRoutes.subscribe((state: { appRoutes: BreadCrumbInterface[] }) =>
            setBreadCrumbsData(state.appRoutes),
        )
        return () => unsubscribe()
    }, [])

    useEffect(() => {
        setSelectedMerchant(merchant_details?.m_id)
    }, [merchant_details])

    useEffect(() => {
        getRefinedList(searchedList)
    }, [searchedList])

    const getRefinedList = (searchedList) => {
        const first9 = searchedList?.slice(0, 9)
        const selectedMerchant = searchedList?.filter((item) => item?.merchant_id === merchant_details?.m_id)
        const result = selectedMerchant
            .concat(first9)
            .filter((item, index, self) => index === self.findIndex((t) => t.id === item.id))
        setRefinedList(result)
    }

    const onMerchantSelect = async () => {
        if (searchValue && !isMerchantUser) {
            let detailsPage = breadCrumbsData[2] ? breadCrumbsData[2].key : ''
            if (
                detailsPage &&
                (detailsPage === 'order-details' ||
                    detailsPage === 'payment-link-details' ||
                    detailsPage === 'abandoned_cart')
            ) {
                setIsModalOpen(false)
                navigateToUrl(breadCrumbsData[1].href)
            }
            localStorage.setItem('merchant-mid', searchValue || process.env.DEFAULT_MERCHANT_ID)
            dispatch(fetchUserDetails(null, null))
        }
        setIsModalOpen(false)
    }

    function searchWildCard(e) {
        let wildcard = e?.target?.value
        setSearchText(wildcard)
        const regex = new RegExp(wildcard.replace(/\*/g, '.*'), 'i')
        const results = merchantList.filter((item) => {
            return Object.values(item).some((value: any) => regex.test(value))
        })
        setSearchedList(results)
    }

    const onRadioChange = (e) => {
        setSearchValue(e.target.value)
        setSelectedMerchant(e.target.value)
    }

    const getMerchantName = useMemo(() => merchant_details?.short_name, [merchant_details])

    const showMerchantList = () => {
        setSearchedList(userData?.allowed_merchants)
        setMerchantList(userData?.allowed_merchants)
        setIsModalOpen(true)
    }
    const doesCurrentPathMatch = () => {
        const paths = [
            'access-control',
            'schedule-notifications',
            '/dashboard',
            'roles-and-permissions',
            '/global-settings',
            '/ab-testing',
            '/checkout/health',
        ]
        const currentPath = route?.pathname
        return paths.some((path) => currentPath.includes(path))
    }

    //========== dont write any non-jsx code below this line=====================================================
    const isKwikCheckoutOnboardingIncomplete =
        merchant_details?.onboarding_status?.kwik_checkout_app !== 'setup_completed'
    const isKwikPassOnboardingIncomplete = merchant_details?.onboarding_status?.kwikpass_app !== 'setup_completed'
    const isKwikPassOnboardingPage = route?.pathname?.includes('kp/onboarding')
    const isKwikCheckoutOnboardingPage = route?.pathname?.includes('/onboarding')

    if (
        isMerchantUser &&
        ((merchant_details?.onboarding_status?.kwik_checkout_app &&
            isKwikCheckoutOnboardingPage &&
            isKwikCheckoutOnboardingIncomplete) ||
            (merchant_details?.onboarding_status?.kwikpass_app &&
                isKwikPassOnboardingPage &&
                isKwikPassOnboardingIncomplete))
    )
        return (
            <div
                ref={headerRef}
                className={`flex justify-between overflow-x-clip items-center absolute top-0 bottom-0 h-16 px-5 bg-white z-10`}
            >
                <div className='flex items-end justify-end w-full   '>
                    <div className='flex items-center  border-solid border-gray-200 rounded-md p-2 px-3 '>
                        <span className='text-gray-900 inter font-medium capitalize truncate'>
                            {merchant_details?.short_name}
                        </span>
                    </div>
                </div>
            </div>
        )

    return (
        <div
            ref={headerRef}
            className={`flex justify-between overflow-x-clip items-center absolute top-0 bottom-0 h-16 px-5 bg-white z-10`}
        >
            <div className='breadcrum'>
                <Breadcrumb
                    separator='>'
                    className={route.pathname.includes('/dashboard') && !isMerchantUser && 'hidden'}
                >
                    {breadCrumbsData
                        ?.filter((itm) => itm)
                        .map((breadcrumb, i) => (
                            <Breadcrumb.Item key={breadcrumb.key}>
                                <Link className='flex items-center' to={breadcrumb.href}>
                                    {/* <HomeOutlined className='text-black-50' /> */}
                                    {breadcrumb.icon && <breadcrumb.icon className='text-black-50' />}
                                    {breadcrumb.text && (
                                        <span
                                            className={`uppercase ${
                                                i === breadCrumbsData.length - 1 ? 'text-black-50 font-bold' : ''
                                            } ${breadcrumb.icon && 'ml-1'}`}
                                        >
                                            {breadcrumb.text}
                                        </span>
                                    )}
                                </Link>
                            </Breadcrumb.Item>
                        ))}
                </Breadcrumb>
            </div>
            <div className='flex items-center gap-x-4'>
                {isMerchantUser && (
                    <React.Fragment key={route.pathname}>
                        {/* {showDashboardToggle && (
                            <Tooltip title='Click to switch to the old Gokwik Dashboard'>
                                <Switch
                                    onChange={async (checked) => {
                                        const res = await dispatch(switchDashboard())
                                        setNewDashboard(res.error)
                                        if (res.success) {
                                            navigate('/login')
                                        }
                                    }}
                                    value={newDashboard}
                                    checkedChildren={'You are using the new Gokwik Dashboard'}
                                    unCheckedChildren={'Switching to the old Gokwik Dashboard'}
                                />
                            </Tooltip>
                        )} */}
                        <Switch
                            onChange={(checked) => {
                                const mode = checked ? 'live' : 'test'
                                dispatch(changeMode(mode))
                                dispatch(fetchUserDetails(true))
                                if (route.pathname !== '/checkout/orders' && mode === 'test')
                                    navigate('/checkout/orders')
                            }}
                            checkedChildren={
                                <div className='flex justify-between gap-1 items-baseline'>
                                    <div className='bg-success-600 rounded-full w-2.5 h-2.5' />
                                    <span>Live Mode</span>
                                </div>
                            }
                            unCheckedChildren={
                                <div className='flex justify-between gap-1 items-baseline'>
                                    <div className='bg-warning-500 rounded-full w-2.5 h-2.5' /> <span>Test Mode</span>
                                </div>
                            }
                            checked={mode === 'live'}
                        />

                        <Select
                            value={activeMerchantId}
                            placeholder=''
                            onChange={(val) => dispatch(switchMerchant(val, breadCrumbsData))}
                            {...(allowedMerchants?.length === 1 && { suffixIcon: null })}
                            disabled={allowedMerchants?.length === 1}
                            key={route.pathname}
                        >
                            {allowedMerchants?.map((merchant) => (
                                <Select.Option
                                    className={'flex gap-x-2 items-center'}
                                    key={merchant.id}
                                    value={merchant.id}
                                >
                                    <div className='flex items-center'>
                                        <span className='text-gray-900 inter font-medium capitalize truncate'>
                                            {merchant.short_name}
                                        </span>
                                    </div>
                                </Select.Option>
                            ))}
                        </Select>
                    </React.Fragment>
                )}
            </div>
            {/*  merchant switcher for AD 2.0 */}
            {!doesCurrentPathMatch() && !isMerchantUser && (
                <React.Fragment key={route.pathname}>
                    <div className=''>
                        Switch merchant &nbsp;
                        <Button variant='default' className='w-80 h-10 pl-1 shadow-lg' onClick={showMerchantList}>
                            <div className='flex justify-between items-center align-middle'>
                                <span className=''>
                                    <img className='w-5' src={logo} />
                                    &nbsp;&nbsp;<span>{getMerchantName || 'Gokwikproduction'}</span>
                                </span>
                                <span>
                                    <DownOutlined />
                                </span>
                            </div>
                        </Button>
                    </div>

                    <Modal
                        width={555}
                        centered
                        title='Search merchant or merchant id'
                        open={isModalOpen}
                        onOk={onMerchantSelect}
                        onCancel={() => {
                            setIsModalOpen(false)
                        }}
                        okText='Set Merchant'
                        afterClose={() => {
                            setSelectedMerchant(merchant_details?.m_id)
                            setSearchValue(null)
                            setSearchText('')
                            setMerchantList([])
                            setSearchedList([])
                        }}
                    >
                        <header>
                            <Input type='text' onChange={searchWildCard} value={searchText} className=' mt-3 mb-1.5' />
                        </header>
                        <div className=''>
                            <ul className='p-0 list-none scroll-auto max-h-[calc(100vh-500px)] overflow-y-auto'>
                                <Radio.Group
                                    onChange={onRadioChange}
                                    className='flex flex-col'
                                    value={selectedMerchant}
                                >
                                    {searchedList?.length &&
                                        refinedList?.length &&
                                        refinedList?.map((item, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div className='flex justify-between'>
                                                        <Radio value={item?.merchant_id}>
                                                            <li className='w-full'>
                                                                <div className='flex justify-between w-full'>
                                                                    <div className='flex gap-2'>
                                                                        <div className='flex flex-col justify-between'>
                                                                            <span className='text-[#004B8D] text-sm font-semibold'>
                                                                                {item?.short_name}
                                                                            </span>
                                                                            <span className='text-xs text-gray-400'>
                                                                                {item?.merchant_id}&nbsp;({item?.id})
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </Radio>

                                                        <div className='flex flex-col gap-2'>
                                                            {item?.platform && (
                                                                <Tag className='flex self-end' variant='warning'>
                                                                    {item?.platform}
                                                                </Tag>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <Divider className='my-3' />
                                                </React.Fragment>
                                            )
                                        })}
                                </Radio.Group>
                            </ul>
                        </div>
                    </Modal>
                </React.Fragment>
            )}
        </div>
    )
}

export default Header
