// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
  /* position: absolute;
  top: 0;
  left: 0;
  width: 97%; */

  /* backdrop-filter: blur(10px); */
}

.header-timer-container {
  display: flex;
  align-items: center;
}
.logout-timer-wrapper {
  display: flex;
  justify-content: center;
}
.logout-timer-wrapper .timer {
  display: flex;
  align-items: center;
  padding: 12px 20px 12px 20px;
}

.timer p {
  margin-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
}
.timer span {
  /* color: #f09819; */
  /* font-family: Arial, Helvetica, sans-serif; */
}

.text-black-50 {
  color: #212529 !important;
  ont-weight: 700 !important;
}
`, "",{"version":3,"sources":["webpack://./src/library/components/common/header/header.css"],"names":[],"mappings":"AAAA;EACE;;;eAGa;;EAEb,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,4BAA4B;AAC9B;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,oBAAoB;EACpB,+CAA+C;AACjD;;AAEA;EACE,yBAAyB;EACzB,0BAA0B;AAC5B","sourcesContent":[".header-container {\n  /* position: absolute;\n  top: 0;\n  left: 0;\n  width: 97%; */\n\n  /* backdrop-filter: blur(10px); */\n}\n\n.header-timer-container {\n  display: flex;\n  align-items: center;\n}\n.logout-timer-wrapper {\n  display: flex;\n  justify-content: center;\n}\n.logout-timer-wrapper .timer {\n  display: flex;\n  align-items: center;\n  padding: 12px 20px 12px 20px;\n}\n\n.timer p {\n  margin-left: 10px;\n  margin-top: 0;\n  margin-bottom: 0;\n}\n.timer span {\n  /* color: #f09819; */\n  /* font-family: Arial, Helvetica, sans-serif; */\n}\n\n.text-black-50 {\n  color: #212529 !important;\n  ont-weight: 700 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
