import { Alert, AlertOutlined, Col, Row } from 'gokwik-ui-kit'
import KYCFormComponent from './kyc-form'
import KycStepper from './KycStepper'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { getMerchantDetails, getUserDetails, getUserData } from '@store/user/selectors'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getKYCStatusAlert, getStepFromStatus, kycFieldsMap, nameMap, scrollToText } from './utils'
import dayjs from 'dayjs'
import ComplienceModal from './kyc-form/components/ComplienceModal'
import WebsiteReadiness from './kyc-form/components/WebsiteReadiness'
import GokwikAgreement from './kyc-form/components/GokwikAgreement'
import StatusBanner from './kyc-form/components/StatusBanner'
import { stepConfiguration } from '../stepsConfig'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { navigateToUrl } from 'single-spa'
import { fetchUserDetails } from '@store/user'
import { useAppDispatch } from '@library/utilities/hooks'
import { ProgressStepper } from '../revamped-pages/CommonComponents/ProgressStepper'
import GoBackToSettings from '../revamped-pages/CommonComponents/goBackToSettings'

type Props = {}

const KYC = (props: Props) => {
    const [kycData, setKycData] = useState(null)
    const merchant_details = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const userData = useSelector(getUserData)
    const [currentStep, setCurrentStep] = useState(0)
    const [openComplienceModal, setOpenComplienceModal] = useState(false)
    const [complienceAgreed, setComplienceAgreed] = useState(false)
    const [isCpvMandatory, setIsCpvMandatory] = useState(false)
    const dispatch = useAppDispatch()
    const getKYCDetails = async (skiploader?) => {
        if (userData?.kycData?.is_cpv_mandatory) {
            setIsCpvMandatory(true)
        } else {
            setIsCpvMandatory(false)
        }
        setKycData({
            ...userData?.kycData,
            ...(userData?.kycData?.business_pan_registration_date && {
                business_pan_registration_date: dayjs(userData?.kycData?.business_pan_registration_date),
            }),
        })
        const stepNumberFromStatus = getStepFromStatus(userData?.merchantDetails?.onboarding_status?.kwik_checkout_app)

        //do not make any changes in this map otherwise it will affect scroll behaviour
        const ScrollToNextSectionMap = {
            kyc_basic_info_added: 'Bank Details',
            kyc_address_details: 'Bank Details',
            kyc_bank_details: 'Business Details',
            kyc_business_details: 'Document Upload',
            kyc_document_upload: 'Basic Information',
            kyc_vpc: 'Basic Information',
        }
        setCurrentStep(stepNumberFromStatus + 1) // +1 because we want to open the next step on page load
        scrollToText(ScrollToNextSectionMap[userData?.merchantDetails?.onboarding_status?.kwik_checkout_app])
    }

    const onKycSubmit = () => {}

    useEffect(() => {
        if (
            !userData?.merchantDetails?.onboarding_status?.kwik_checkout_app ||
            (userData?.merchantDetails?.onboarding_status?.kwik_checkout_app &&
                userData?.merchantDetails?.onboarding_status?.kwik_checkout_app === 'setup_completed')
        ) {
            navigateToUrl('/shopify-app/settings')
        }
        getKYCDetails()
        logEvent('kyc_landed', 'pageLoad', 'kyc', user_details?.email, merchant_details?.m_id)
    }, [userData?.merchantDetails?.onboarding_status?.kwik_checkout_app])

    const isPanelClickable = (stepNumber) => {
        const current_onboarding_status = merchant_details?.onboarding_status?.kwik_checkout_app
        if (
            current_onboarding_status !== 'kyc_verification_failed' &&
            current_onboarding_status !== 'kyc_verification_pending' &&
            current_onboarding_status !== 'setup_completed' &&
            stepConfiguration[current_onboarding_status]?.id < 14
        ) {
            if (stepNumber === 0) {
                return 'header'
            } else if (stepNumber === 1) {
                return stepConfiguration[current_onboarding_status]?.id >= 8 ? 'header' : 'disabled'
            } else if (stepNumber === 2) {
                return stepConfiguration[current_onboarding_status]?.id >= 9 ? 'header' : 'disabled'
            } else if (stepNumber === 3) {
                return stepConfiguration[current_onboarding_status]?.id >= 10 ? 'header' : 'disabled'
            } else if (stepNumber === 4) {
                return stepConfiguration[current_onboarding_status]?.id >= 11 ? 'header' : 'disabled'
            } else if (stepNumber === 5) {
                return stepConfiguration[current_onboarding_status]?.id >= 12 ? 'header' : 'disabled'
            }
        }
        return 'header'
    }

    const undeterminedKycErrorState =
        userData?.merchantDetails?.onboarding_status?.kwik_checkout_app === 'kyc_verification_failed' &&
        userData?.kycData?.kyc_rejection_reason

    const withdrawKYC = async () => {
        await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateMerchantOnboardingStatus + merchant_details.m_id,
            payload: {
                current_onboarding_status: 'kyc_business_details',
            },
        })

        dispatch(fetchUserDetails())
    }

    return (
        <div className='mt-[64px] w-full h-full'>
            <ProgressStepper />
            <div style={{ margin: '0 2rem' }}>
                <GoBackToSettings heading={'Verify Your Business (KYC)'} />
            </div>
            <div className='mx-7'>
                <br />
                <Row>
                    <Col span={24}>
                        {getKYCStatusAlert(
                            undeterminedKycErrorState,
                            userData?.kycData?.kyc_failure_reasons,
                            userData?.merchantDetails?.onboarding_status?.kwik_checkout_app,
                            userData,
                            kycFieldsMap,
                            setCurrentStep,
                            withdrawKYC,
                        )}
                    </Col>
                </Row>
                <br />
                <Row gutter={12} className='mb-3'>
                    <Col span={24}>
                        <WebsiteReadiness userData={userData} />
                    </Col>
                    {/* <Col span={12}>
                        <GokwikAgreement />
                    </Col> */}
                </Row>
                <Row gutter={12}>
                    <Col span={5}>
                        <KycStepper
                            currentStep={currentStep}
                            setCurrentStep={setCurrentStep}
                            isCpvMandatory={isCpvMandatory}
                            isPanelClickable={isPanelClickable}
                        />
                    </Col>
                    <Col span={19}>
                        <KYCFormComponent
                            kycData={kycData}
                            currentStep={currentStep}
                            setCurrentStep={setCurrentStep}
                            isCPVMandatory={isCpvMandatory}
                            isPanelClickable={isPanelClickable}
                            undeterminedKycErrorState={undeterminedKycErrorState}
                            setIsCpvMandatory={setIsCpvMandatory}
                            setKycData={setKycData}
                        />
                    </Col>
                    <ComplienceModal
                        isOpen={openComplienceModal}
                        setIsOpen={setOpenComplienceModal}
                        complienceAgreed={complienceAgreed}
                        setComplienceAgreed={setComplienceAgreed}
                        onKycSubmit={onKycSubmit}
                    />
                </Row>
            </div>
        </div>
    )
}

export default KYC
