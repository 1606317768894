import React, { useEffect, useState } from 'react';
import { Button, Divider } from "gokwik-ui-kit";
import previewPlaceholder1 from '@library/images/icons/signAgreementPreview.png';
import darkExternalLink from '@library/images/icons/dark-external-link.svg';
import lightExternalLink from '@library/images/icons/light-external-link.svg';
import HowToStepsWithPreview from '../CommonComponents/howToStepsAndPreview';
import { logEvent } from "@library/utilities/userLogsEvents/userLogEvents";
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux';
import CheckoutStepsHeader from '../CommonComponents/checkoutStepsHeader';
import { useAppDispatch } from '@library/utilities/hooks'
import { navigateToUrl } from 'single-spa';

const PaymentActivationStep1 = ({ onStepComplete, currentStep, expandedStep, toggleStep }) => {
    const [siteVisited, setSiteVisited] = useState(false);
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)
    const [showContent, setShowContent] = useState(false);
    const dispatch = useAppDispatch();

  useEffect(() => {
    if (expandedStep === 1 && currentStep !== 1) {
      setSiteVisited(true);
    }
    if (expandedStep == 1) {
      setShowContent(false);
      setTimeout(() => setShowContent(true), 100);
    }
  }, [expandedStep]);

  const steps = [
    { 
      description: (<>Click on <b>"Sign Agreement"</b> on the <b>GoKwik dashboard</b> to be redirected to the <b>Legalty dashboard</b>.</>) ,
      step: 1,
    },
    { 
      description: (<>Click <b>"Proceed"</b> on the Legalty dashboard and <b>enter the OTP</b> sent to your registered email.</>)
    },
    { 
      description: (<><b>Review, sign, and acknowledge</b> the document on the Legalty screen.</>)
    },
    { 
      description: (<><b>Return to the GoKwik dashboard</b> after signing the agreement.</>) 
    }
  ]

    const handleSignAgreementClick = () => {
        setSiteVisited(true)
        logEvent(
            siteVisited? "sign_agreement_clicked_again" : 'sign_agreement_clicked',
            'click',
            'payment-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            {Step: 'payments 1'}
        )
        navigateToUrl("/onboarding/gokwik-agreement");
    }

  return (
    <div className="border border-gray-300 rounded-lg bg-white w-[74vw]">
      <CheckoutStepsHeader step={'Step 1'} heading={'Agreement Signing'} toggleStep={toggleStep} stepId={1} currentStep={currentStep} />

      {expandedStep === 1 ? (
        <div style={{
          opacity: showContent ? 1 : 0,
          transform: showContent ? 'translateY(0)' : 'translateY(20px)',
          transition: 'opacity 0.5s ease, transform 0.5s ease',
        }}>
          <Divider className='m-0'/>
          <HowToStepsWithPreview steps={steps} previewImage={previewPlaceholder1} videoUrl={'https://pdp.gokwik.co/sa-login-ui/assets/Sign+Agreement.mp4'} page={'payments'}/>
          <Divider  className='m-0' />
          <div className="flex flex-row-reverse justify-between items-center p-3">
            <Button
              onClick={handleSignAgreementClick}
              variant={siteVisited ? 'default' : 'primary'}
              className="flex items-center"
              size="large"
              disabled={currentStep !== 1}
            >
              Sign Agreement <img style={{ marginLeft: '0.4rem' }} src={siteVisited ? darkExternalLink : lightExternalLink} />
            </Button>
          </div>
        </div>
      ) : "" }
    </div>
  );
};

export default PaymentActivationStep1;
