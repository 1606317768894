async function useAESEncryption(value, encryptionKey) {
    const IV_length = 16

    // Create a random 16-byte initialization vector
    const iv = crypto.getRandomValues(new Uint8Array(IV_length))

    // Convert the encryption key from hex to an array of bytes
    const key = hexToBytes(encryptionKey)

    // Import the key
    const importedKey = await crypto.subtle.importKey('raw', key, { name: 'AES-CBC' }, false, ['encrypt'])

    // Convert the plaintext value to an array of bytes
    const valueBytes = new TextEncoder().encode(value)

    // Encrypt the data
    const encrypted = await crypto.subtle.encrypt({ name: 'AES-CBC', iv: iv }, importedKey, valueBytes)

    // Combine IV and ciphertext with a pipe symbol
    const ivHex = bytesToHex(iv)
    const encryptedHex = bytesToHex(new Uint8Array(encrypted))
    return `${ivHex}|${encryptedHex}`
}

// Helper function to convert hex string to Uint8Array
function hexToBytes(hex) {
    return new Uint8Array(hex.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)))
}

// Helper function to convert Uint8Array to hex string
function bytesToHex(bytes) {
    return bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '')
}

export default useAESEncryption
