import { getMerchantDetails } from '@store/user/selectors'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { navigateToUrl } from 'single-spa'
import { KPSteps } from './steps'
import { Col, Row } from 'gokwik-ui-kit'

type Props = {}

export default function index({}: Props) {
    const merchant_details = useSelector(getMerchantDetails)

    useEffect(() => {
        if (
            merchant_details?.onboarding_status?.kwikpass_app &&
            merchant_details?.onboarding_status?.kwikpass_app === 'setup_completed'
        ) {
            navigateToUrl('/kwikpass/configuration')
        }
    }, [merchant_details])

    return (
        <div className='bg-[#f1f7ff] w-full h-full'>
            <Row className='gap-4 px-10 mt-4'>
                <div>
                    <p className='text-gray-900 font-inter text-2xl font-semibold leading-7'>Get Started</p>
                    <p className='text-gray-600 font-inter text-base font-normal leading-[22px]'>
                        You are just 5 steps away from unlocking higher revenue from anonymous shoppers!
                    </p>
                </div>
            </Row>

            <KPSteps />
        </div>
    )
}
