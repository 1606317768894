import { Link } from 'react-router-dom'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useEffect, useRef } from 'react'

export const NotFound = ({ hasPermission = true }) => {
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)
    const hasEventLogged = useRef(false)

    useEffect(() => {
        if (
            !hasEventLogged.current &&
            userDetails?.email &&
            merchantDetails?.m_id &&
            !sessionStorage.getItem('error_logged')
        ) {
            logEvent(
                hasPermission ? 'incorrect_url' : 'restricted_url',
                'pageLoad',
                'Error page',
                userDetails.email,
                merchantDetails.m_id,
                merchantDetails.short_name,
                userDetails.name,
            )
            hasEventLogged.current = true
            sessionStorage.setItem('error_logged', '1')
            setTimeout(() => {
                sessionStorage.removeItem('error_logged')
            }, 500)
        }
    }, [hasPermission, userDetails?.email, merchantDetails?.m_id])

    return (
        <div className='m-auto'>
            {hasPermission ? (
                <>
                    <h4 className='text-gray-400 text-center'>
                        It looks like the URL might be incorrect. Please double-check the link, or{' '}
                        <Link to={`/checkout/orders`}>
                            <a className='text-primary-500 no-underline'>click here</a>
                        </Link>{' '}
                        to continue browsing
                    </h4>
                </>
            ) : (
                <>
                    <h4 className='text-gray-400 text-center'>
                        It looks like you don’t have permission to view this page. Please connect with your POC to get
                        access or{' '}
                        <Link to={`/checkout/orders`}>
                            <a className='text-primary-500 no-underline'>click here</a>
                        </Link>{' '}
                        to continue browsing
                    </h4>
                </>
            )}
        </div>
    )
}
