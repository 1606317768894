export const stepConfiguration = {
    password_setup: { id: 0, stepNumber: 0 },
    feature_tour_done: { id: 1, stepNumber: 1 },
    smart_cod_app_installed: { id: 2, stepNumber: 2 },
    smart_cod_app_activated: { id: 3, stepNumber: 3 },
    gokwik_shipping_setup_done: { id: 4, stepNumber: 4 },
    smart_cod_app_disabled: { id: 5, stepNumber: 5 },
    imp_checks_passed: { id: 6, stepNumber: 6 },
    kyc_viewed: { id: 7, stepNumber: 7 },
    kyc_basic_info_added: { id: 8, stepNumber: 8 },
    kyc_address_details: { id: 9, stepNumber: 9 },
    kyc_bank_details: { id: 10, stepNumber: 10 },
    kyc_business_details: { id: 11, stepNumber: 11 },
    kyc_document_upload: { id: 12, stepNumber: 12 },
    kyc_vpc: { id: 13, stepNumber: 13 },
    kyc_verification_pending: { id: 14, stepNumber: 14 },
    kyc_verification_failed: { id: 15, stepNumber: 15 },
    kyc_completed: { id: 16, stepNumber: 16 },
    kwik_checkout_button_activated: { id: 17, stepNumber: 17 },
    setup_completed: { id: 18, stepNumber: 18 },
}

export const CODFirstStepConfig = {
    feature_tour_done: { id: 0, stepNumber: 0 },
    pricing_details_viewed: { id: 1, stepNumber: 1 },
    pricing_details_selected: { id: 2, stepNumber: 1 },
    pricing_approval_pending: { id: 3, stepNumber: 1 },
    pricing_details_confirmed: { id: 4, stepNumber: 1 },
    smart_cod_app_installed: { id: 5, stepNumber: 2 },
    smart_cod_app_activated: { id: 6, stepNumber: 3 },
    setup_completed: { id: 7, stepNumber: 4 },
}
