import BannerTopImg from '@library/images/common/executive-summary-images/BannerTopImg'
import NewLaunchImg from '@library/images/common/executive-summary-images/NewLaunchImg'
import { Button, CloseOutlined } from 'gokwik-ui-kit'
import { StickerSVG } from '../utils'

const TopBanner = ({ campaignConfigs }) => {
    console.log(campaignConfigs)

    return (
        <div className='p-3 bg-[#EBF6FF] rounded-lg flex gap-4 transform scale-90'>
            <div className='w-[95%] flex gap-1'>
                <div>
                    <BannerTopImg />
                </div>

                <div>
                    <div className='flex flex-col gap-2'>
                        <div className='inline-flex w-auto self-start gap-1 items-center bg-gradient-to-r from-blue-200 to-white p-1 rounded-md text-[#4096FF] text-[10px] font-bold'>
                            <div>
                                <StickerSVG />
                            </div>

                            <div>{campaignConfigs?.name}</div>
                        </div>

                        <div className='flex flex-col gap-2'>
                            <div className='font-semibold mb-1'>{campaignConfigs?.title}</div>
                            <div>{campaignConfigs?.description}</div>

                            <Button
                                onClick={() => window.open(campaignConfigs?.cta_url, '_blank')}
                                className='w-auto self-start'
                                variant='primary'
                            >
                                {campaignConfigs?.cta_text}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-[5%]'>
                <CloseOutlined className='bg-[#0000000D] rounded-full text-[#475467] p-2 font-semibold' />
            </div>
        </div>
    )
}

export default TopBanner
