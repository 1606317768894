import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { convertIsoToLocaleString, filterDateFormatter, makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import {
    Table,
    Row,
    Col,
    Button,
    Tag,
    Modal,
    Tooltip,
    ColumnProps,
    message,
    Upload,
    InboxOutlined,
    Select,
    DownloadOutlined,
    InfoCircleOutlined,
    PlusCircleOutlined,
    Drawer,
    Space,
    EyeOutlined,
} from 'gokwik-ui-kit'
import { previousCustomerFilters } from '@store/filters/selectors'
import { saveFiltersData } from '@store/filters'
import RenderSearchFilters from '@library/components/search-filter'
import { getMerchantDetails, isMerchantSelector } from '@store/user/selectors'
const { Dragger } = Upload
const today = dayjs()
const formattedDate = filterDateFormatter(today)
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { LABELS, ICONS } from './constants'
import { Link } from 'react-router-dom'

export default function () {
    const [total, setTotal] = useState(0)
    const [customerData, setCustomerData] = useState([])
    const prevFilters = useSelector(previousCustomerFilters)
    const [openDrawer, setOpenDrawer] = useState(false)
    const isMerchantUser = useSelector(isMerchantSelector)
    const [parameters, setParameters] = useState<any>({
        page: 1,
        sortKey: 'created_at',
        sortOrder: -1,
        pageSize: 25,
        ...prevFilters,
    })
    const [customerModalData, setCustomerModalData] = useState(null)
    const [modalOpen, setModalOpen] = useState('')
    const [isDrawerOpen, setDrawerOpen] = useState(false)
    const [uploadPincodesList, setUploadedPincodes] = useState([])
    const [uploadedFile, setUploadedFile] = useState('')
    const [file, setFile] = useState({ file: '', fileName: '' })
    const [fileSelected, setCurrentFileSeletecd] = useState<any>(null)
    const [showReplaceWarning, setReplaceWarning] = useState(false)
    const [showUpload, setShowUpload] = useState(false)
    const [CustomerDetails, setCustomerDetails] = useState([])
    const [searchClicked, setSearchClicked] = useState(false)
    const merchantDetails = useSelector(getMerchantDetails)
    const disptach = useDispatch()

    function titleCase(str) {
        var splitStr = str.toLowerCase().split(' ')
        for (var i = 0; i < splitStr.length; i++) {
            // You do not need to check if i is larger than splitStr length, as your for does that for you
            // Assign it back to the array
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
        }
        // Directly return the joined string
        return splitStr.join(' ')
    }

    const handleGetInfo = async (id) => {
        setOpenDrawer(true)

        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getCustomerDetails + id,
            })

            if (response?.data?.data?.customer_details) {
                setCustomerDetails(response?.data?.data?.customer_details)
            }
        } catch (err) {
            console.log('Error occured while fetching customer details:', err)
        }
    }

    const columns: ColumnProps<any>[] = [
        {
            title: <span>Customer Id</span>,
            dataIndex: 'id',
            width: 20,
            render: (text: string, record: any) => {
                return (
                    <div className='flex gap-3 items-baseline text-base'>
                        <Tooltip title={text}>
                            <Link to={`/${isMerchantUser ? 'admin' : 'access-control'}/customers/${record?.id}`}>
                                {text}
                            </Link>
                        </Tooltip>
                    </div>
                )
            },
        },
        {
            title: <span>Full Name</span>,
            dataIndex: 'firstnameAndLastname',
            width: 30,
            ellipsis: true,
            render: (text, record) => <span>{`${titleCase(record.firstname)} ${titleCase(record.lastname)}`}</span>,
        },
        {
            title: <span>Phone</span>,
            dataIndex: 'masked_phone',
            width: 20,
            render: (text: string, record) => <span>{record?.masked_phone || record?.phone_mask}</span>,
        },
        {
            title: <span>Orders count</span>,
            dataIndex: 'orders_count',
            width: 20,
            render: (text: string, record) => (
                <span>{record?.orders_count === 0 ? 'No Orders' : record?.orders_count}</span>
            ),
        },
        {
            title: <span>Customer Status</span>,
            dataIndex: 'is_customer_blocked',
            width: 30,
            render: (text: string, record) => (
                <span>
                    {text ? (
                        <Tag
                            variant={'error'}
                            className='!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs'
                        >
                            Blocked
                        </Tag>
                    ) : (
                        <Tag
                            variant={'success'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Unblocked
                        </Tag>
                    )}
                </span>
            ),
        },

        {
            title: <span>Updated At</span>,
            dataIndex: 'updated_at',
            width: 25,
            ellipsis: true,
            render: (text: string) => (
                <Tooltip title={convertIsoToLocaleString(text)}>{convertIsoToLocaleString(text)}</Tooltip>
            ),
            sorter: (a, b) => (dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? -1 : 1),
            sortDirections: ['ascend', 'descend'],
        },
        {
            title: <span>Created At</span>,
            dataIndex: 'created_at',
            width: 25,
            ellipsis: true,
            render: (text: string) => (
                <Tooltip title={convertIsoToLocaleString(text)}>{convertIsoToLocaleString(text)}</Tooltip>
            ),
            sorter: (a, b) => (dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? -1 : 1),
            sortDirections: ['ascend', 'descend'],
        },
        // {
        //     title: <span>Actions</span>,
        //     dataIndex: 'id',
        //     width: 20,
        //     render: (text: string, record: any) => {
        //         return <></>
        //     },
        // },
    ]

    async function customerBlock(customerCurrentStatus, customerId, type) {
        if (!customerId) return

        const params = { customer_id: customerId, is_customer_blocked: !customerCurrentStatus, type: type }
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.blockCustomer,
                payload: params,
            })
            if (response.success) {
                message.success('Successfully Updated')
                makeCustomersApiCall(parameters)
            } else {
                message.warning(
                    response?.data?.message ||
                        response?.data?.data?.message ||
                        response?.response?.data?.message ||
                        response?.response?.data?.data?.message,
                )
            }
        } catch (err) {
            console.warn(err)
        }
    }

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'customers',
                href: '/general/customers',
                text: 'Customers',
            },
        ])
        makeCustomersApiCall(parameters)
    }, [])

    async function makeCustomersApiCall(parameters) {
        if (!parameters.page) return
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getCustomer,
                params: {
                    ...parameters,
                },
            })

            if (response.data?.data?.customers?.length > 0) {
                setCustomerData(response.data?.data?.customers)
                setTotal(response.data?.data?.totalCount)
            } else {
                setCustomerData([])
                setTotal(0)
            }

            disptach<any>(saveFiltersData('customers', { ...parameters }))
        } catch (err) {
            console.warn(err)
        }
    }

    const reset = (e) => {
        e.preventDefault()
        const newParameters = {
            page: 1,
            sortKey: 'created_at',
            sortOrder: -1,
            pageSize: 25,
            start_dt: formattedDate,
            end_dt: formattedDate,
        }
        setParameters(newParameters)
        setTotal(0)
        setCustomerData([])
        disptach<any>(saveFiltersData('customers', {}))
    }
    const handleSearchClick = (e) => {
        e?.preventDefault()

        if (parameters?.['searchEmail']) {
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

            if (!parameters?.['searchEmail'].match(emailPattern)) {
                message.error('Invalid email format.')

                return
            }
        }

        if (parameters?.['searchPhone'] && parameters?.['searchPhone']?.toString()?.length < 10) {
            message.error('Phone number should be 10-digits.')
            return
        }

        setSearchClicked(true)
        makeCustomersApiCall({ ...parameters, page: 1 })
    }
    const handlePaginationChange: any = (current: number, pageSize?: number) => {
        setParameters((prev) => ({ ...prev, page: current, pageSize }))
        makeCustomersApiCall({ ...parameters, page: current, pageSize })
    }
    useEffect(() => {
        if (Object.keys(prevFilters).length && JSON.stringify(prevFilters) !== JSON.stringify(parameters))
            setParameters({ ...prevFilters })
    }, [prevFilters])

    const donwloadSample = () => {
        let csvContent = 'phone,type\r\n'
        csvContent += ['9999999999,cod', '9999998888,prepaid', '9999998887,all'].join('\r\n')

        try {
            var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
            var link = document.createElement('a')

            var url = URL.createObjectURL(blob)
            link.href = url

            link.setAttribute('download', 'block_sample.csv')

            document.body.appendChild(link)

            link.click()
            document.body.removeChild(link)

            URL.revokeObjectURL(url)

            message.success('Sample downloaded successfully!')
        } catch (error) {
            console.error('Error during file download:', error)
            message.error('Failed to download the sample.')
        }
    }

    function uploadPincodeCsv(file) {
        let reader = new FileReader()
        reader.readAsText(file)
        reader.onload = (e) => {
            const content = e.target.result as string
            const rows = content
                .replace(/\r\n/g, '\n')
                .replace(/\r/g, '\n')
                .split('\n')
                .filter((row) => row.trim() !== '')
                .map((row) => row.split(','))

            if (
                rows.length < 2 ||
                rows[0].length !== 2 ||
                rows[0][0].toLowerCase() !== 'phone' ||
                rows[0][1].toLowerCase() !== 'type'
            ) {
                message.error('Wrong CSV file structure. Please look at the sample to know the right structure!')
                return
            }
            // Remove header row
            rows.shift()

            // Validate and process each row
            for (let i = 0; i < rows.length; i++) {
                const [phoneNumber, textString] = rows[i].map((value) => value.trim())
                if (
                    !/^\d{10}$/.test(phoneNumber) ||
                    !textString ||
                    (textString !== 'cod' && textString !== 'prepaid' && textString !== 'all')
                ) {
                    message.error('Invalid value in CSV detected. Please check and upload.')
                    return
                }
            }

            // Convert rows to an array of objects
            const parsedData = rows.map((row) => ({
                phoneNumber: row[0],
                textString: row[1],
            }))

            // Handle the parsed data as needed
            setUploadedPincodes([...parsedData])
            setUploadedFile(file.name)
            setFile({
                file: file,
                fileName: file,
            })

            // Use the parsedData array as needed
            uploadPincodesData2(file, file.name)
        }
        reader.readAsText(file)
    }

    const getSignedUrl = async () => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.blockCustomerPresignedUrl,
            params: { file_name: 'customer_block.csv' },
        })
        if (response.success) {
            return response.data.data.url
        }
    }

    async function uploadPincodesData2(csvFile, fileName) {
        try {
            const url = await getSignedUrl()
            console.log(file, '>> Task')
            const awsResponse = await fetch(url, {
                method: 'PUT',
                body: csvFile,
            })
            if (awsResponse.status == 200) {
                const parsedUrl = new URL(url)
                const parts = parsedUrl.pathname.split('/')
                const nonEmptyParts = parts.filter((part) => part !== '')
                const filename = nonEmptyParts.pop()

                const postData = {
                    file_name: filename,
                    ...(!isMerchantUser && { merchant_id: merchantDetails?.id + '' }),
                }
                const response = await makeAPICall({
                    method: 'post',
                    url:
                        process.env.REACT_APP_BASE_URL +
                        APIEndPoints.blockCustomerUploadNotifyUrl +
                        '?file=' +
                        fileName,
                    payload: {
                        ...postData,
                    },
                }).then(() => {
                    message.success('Uploaded successfully!')
                    // getPincodeLists()
                    setDrawerOpen(false)
                    setShowUpload(null)
                    setReplaceWarning(false)
                    setCurrentFileSeletecd(null)
                    makeCustomersApiCall(parameters)
                    // getFilesUploadedList()
                })
                if (response.status != 200)
                    console.error('Failed to make POST request:', response.status, response.data, response.statusText)
            } else {
                console.error('Failed to make POST request:', awsResponse.status, awsResponse.statusText)
            }
        } catch {
            // toast.error('Upload Failed!', {
            //     position: 'top-center',
            //     autoClose: 1500,
            // })
        }
    }

    const getValue = (value) => (typeof value === 'boolean' ? (value ? 'Yes' : 'No') : value)
    const getLabel = (key) => LABELS[key] || key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')

    return (
        <>
            <Drawer
                title={'Customer Info'}
                placement='right'
                onClose={() => {
                    setOpenDrawer(false)
                    setCustomerDetails([])
                }}
                open={openDrawer}
            >
                <Space direction='vertical' className='w-full'>
                    <Row>
                        <Col className='flex flex-col gap-y-4' sm={24}>
                            {Object.entries(CustomerDetails).map(([key, value]) => {
                                const Icon = ICONS[key]
                                if (Icon) {
                                    return (
                                        <div
                                            key={key}
                                            className='flex items-center justify-between w-full text-sm inter font-normal'
                                        >
                                            <div className='text-gray-600'>
                                                <Icon className='mr-2' width={20} height={20} />
                                                <span>{getLabel(key)}</span>
                                            </div>
                                            <Tooltip placement='topLeft' title={getValue(value)}>
                                                <span className='text-black font-medium max-w-[50%] truncate'>
                                                    {getValue(value)}
                                                </span>
                                            </Tooltip>
                                        </div>
                                    )
                                }
                            })}
                        </Col>
                    </Row>
                </Space>
            </Drawer>
            <div className='w-full h-full'>
                {/* Commenting following part for phase 1 of customer page development */}
                {/* {isMerchantUser && (
                    <Row className='p-2 rounded bg-neutrals-15 mb-4' align={'middle'} justify={'space-between'}>
                        <Col sm={12} className='flex items-center'>
                            <div className='text-black inter m-0 tracking-wide items-center flex gap-2'>
                                <Tooltip
                                    title={
                                        'Create new roles or modify existing ones to fit specific job functions or responsibilities. Allocate specific permissions to each role, determining what actions users in those roles can perform'
                                    }
                                >
                                    <InfoCircleOutlined className='text-blue fs20' />
                                </Tooltip>

                                <span>Add files here to bulk block the customers</span>
                            </div>
                        </Col>
                        <Col className='flex justify-end' sm={2}>
                            <Button onClick={() => setDrawerOpen(true)} variant='primary'>
                                <PlusCircleOutlined />
                                Upload File
                            </Button>
                        </Col>
                    </Row>
                )} */}
                <div className='mt-2'>
                    <Row className='bg-white rounded'>
                        <RenderSearchFilters
                            values={parameters}
                            setValues={(data, reset) =>
                                setParameters((prev: any) => (reset ? { ...data } : { ...prev, ...data }))
                            }
                            page='customers'
                            onSearch={handleSearchClick}
                            onReset={reset}
                        />
                    </Row>
                </div>

                <div className=' bg-white rounded overflow-clip mt-5'>
                    <Table
                        columns={customerData?.length > 0 ? columns : []}
                        dataSource={customerData || []}
                        scroll={{ x: 800, y: 300 }}
                        pagination={{
                            current: parameters.page,
                            pageSize: parameters.pageSize,
                            total: total,
                            showSizeChanger: false,
                            onChange: handlePaginationChange,
                            position: ['topLeft', 'bottomLeft'],
                            showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                        }}
                    />
                </div>
            </div>

            <Modal
                title={<p>Are your sure, you want to {modalOpen} this customer ?</p>}
                open={modalOpen ? true : false}
                onOk={() => {
                    customerBlock(
                        customerModalData?.is_customer_blocked,
                        customerModalData?.id,
                        customerModalData?.type || '',
                    )
                    setModalOpen(null)
                }}
                onCancel={() => {
                    setModalOpen(null)
                }}
                centered
                width={'500px'}
                footer={() => (
                    <div>
                        {!(modalOpen === 'unblock') && (
                            <Row>
                                <Col span={10}>
                                    <label className='block text-left text-base font-semibold'>Select Block Type</label>
                                    <Select
                                        // label = {'Select Block Type'}
                                        className='w-full text-left mt-2'
                                        placeholder='Select an option'
                                        value={customerModalData?.type || 'cod'}
                                        options={[
                                            { value: 'cod', label: 'COD' },
                                            { value: 'prepaid', label: 'Prepaid' },
                                            { value: 'all', label: 'ALL' },
                                        ]}
                                        onChange={(value) => {
                                            setCustomerModalData((prev) => ({
                                                ...prev,
                                                type: value,
                                            }))
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Row className='flex justify-end gap-2'>
                            <Button onClick={() => setModalOpen(null)} variant='default'>
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    customerBlock(
                                        customerModalData?.is_customer_blocked && (modalOpen === 'edit' ? false : true),
                                        customerModalData?.id,
                                        customerModalData?.type || 'cod',
                                    )
                                    setModalOpen(null)
                                }}
                                variant='primary'
                            >
                                Confirm
                            </Button>
                        </Row>
                    </div>
                )}
            >
                <h3 className='mt-1'>Customer #{customerModalData?.id}</h3>
            </Modal>

            <Modal
                okText={null}
                title={'Block Bulk Customers'}
                className='w-[80vw] p-0'
                onCancel={() => {
                    setDrawerOpen(false)
                }}
                open={isDrawerOpen}
                footer={null}
                styles={{
                    content: {
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        width: '40vw',
                        paddingTop: '16px',
                        paddingBottom: '16px',
                        background: '#F3F3F3',
                    },
                    body: {
                        padding: '0px',
                        background: '#FFFFFF',
                    },
                    header: {
                        background: '#F3F3F3',
                        padding: '2px',
                    },
                }}
            >
                <div className='p-3'>
                    <Dragger
                        accept='.csv'
                        name='file'
                        maxCount={1}
                        multiple={true}
                        itemRender={(originNode, file) => {
                            return (
                                <div>
                                    <span>{file.name}</span>
                                </div>
                            )
                        }}
                        onChange={(info) => {
                            const { status, originFileObj } = info.file
                            info.file.status = 'done'
                            uploadPincodeCsv(originFileObj)
                        }}
                    >
                        <p className='ant-upload-drag-icon'>
                            <InboxOutlined />
                        </p>
                        <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                    </Dragger>
                    <div className='flex items-center justify-between border-solid rounded-md border-[#1677FF8C] p-2 bg-[#1677FF0D] my-2'>
                        <p className='text-xs text-gray-600'>
                            Please ensure that the uploaded file is in the suggested format as shown in the sample file
                            on the right
                        </p>
                        <Button onClick={donwloadSample} type='link' variant='default' className='ml-3 pr-0'>
                            <DownloadOutlined />
                            Download Sample
                        </Button>
                    </div>

                    <Row justify='space-between' className='my-2 mt-3'>
                        <p className='text-sm'>Supported Formats: CSV</p>
                        <p className='text-sm text-gray-500'>Maximum file size: 200 MB</p>
                    </Row>
                </div>
            </Modal>
        </>
    )
}
