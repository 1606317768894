import { Layout } from "gokwik-ui-kit";
import ConfigSteps from "./ConfigSteps";
import PaymentStepsWrapper from "./PaymentStepsWrapper";
import { useEffect, useState } from "react";
import { logEvent } from "@library/utilities/userLogsEvents/userLogEvents";
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux';
import { ProgressStepper } from "../CommonComponents/ProgressStepper";
import { navigateToUrl } from "single-spa";
import GoBackToSettings from "../CommonComponents/goBackToSettings";

const { Content } = Layout;

const PaymentsActivationWrapper = () => {
  const userDetails = useSelector(getUserDetails)
  const merchantDetails = useSelector(getMerchantDetails)
  const currentStatus = merchantDetails?.onboarding_status?.kwik_checkout_app;
  
  let currentConfigStep: number = 0;
  if (['setup_completed', 'kyc_completed', 'kwik_checkout_button_activated'].includes(currentStatus)) {
    currentConfigStep = 1;
  } else if (currentStatus === 'gk_payments_agreement_signed') {
    currentConfigStep = 2;
  } else if (["gk_payments_app_installed", 'gk_additional_payment_methods_configured', "merchant_onboarded"].includes(currentStatus)) {
    currentConfigStep = 3;
  }
    
  const [currentStep, setCurrentStep] = useState(currentConfigStep);

  useEffect(() => {
    let kwikCheckoutAppStatus = merchantDetails?.onboarding_status?.kwik_checkout_app
    if (kwikCheckoutAppStatus && (["gk_payments_app_installed", 'gk_additional_payment_methods_configured', "merchant_onboarded"].includes(kwikCheckoutAppStatus))) {
      navigateToUrl('/shopify-app/settings')
    } else if (!kwikCheckoutAppStatus) {
      navigateToUrl('/shopify-app/settings');
    }
  }, [merchantDetails?.onboarding_status?.kwik_checkout_app])

  useEffect(() => {
    logEvent(
      'enable_payment_page_landed',
      'pageload',
      'payment-setup',
      userDetails?.email,
      merchantDetails?.m_id,
      merchantDetails?.short_name,
      userDetails?.name
    )
  }, [])

  return (
    <Layout className='pt-16'>
      <ProgressStepper />
      <Content >
        <Layout style={{ backgroundColor: "#f0f2f5", margin: '0 2rem' }}>
          <GoBackToSettings heading={'Enable Payment Options'} />
          <Content style={{ paddingTop: '0.75rem' }}>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <ConfigSteps currentStep={currentStep} />
              <PaymentStepsWrapper currentStep={currentStep} setCurrentStep={setCurrentStep} />
            </div>
          </Content>
        </Layout>
      </Content>
    </Layout>
  );
};

export default PaymentsActivationWrapper;
