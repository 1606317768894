import { message } from 'gokwik-ui-kit'
import { stepConfiguration } from '../stepsConfig'
import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getMerchantDetails, isMerchantSelector } from '@store/user/selectors'
import { useSelector } from 'react-redux'

const OnboardingBanner = ({ userData, IsScheduleNtoficationActive }) => {
    const merchant_details = useSelector(getMerchantDetails)
    const isMerchantUser = useSelector(isMerchantSelector)
    const route = useLocation()
    const getMessage = useMemo(
        () => () => {
            if (userData?.merchantDetails?.onboarding_status?.kwik_checkout_app) {
                if (
                    stepConfiguration[userData?.merchantDetails?.onboarding_status?.kwik_checkout_app]?.stepNumber >=
                        stepConfiguration['kyc_viewed']?.stepNumber &&
                    stepConfiguration[userData?.merchantDetails?.onboarding_status?.kwik_checkout_app]?.stepNumber <
                        stepConfiguration['kyc_verification_pending']?.stepNumber
                ) {
                    return {
                        message: (
                            <>
                                KYC verification pending! To complete{' '}
                                <Link
                                    className='text-white'
                                    to='/onboarding/kyc'
                                    onClick={() =>
                                        logEvent(
                                            'dashboard_banner_resume_kyc',
                                            'click',
                                            'checkout_settings',
                                            null,
                                            merchant_details.m_id,
                                        )
                                    }
                                >
                                    {/* {stepConfiguration[userData?.merchantDetails?.onboarding_status?.kwik_checkout_app]?.stepNumber ===
                                    stepConfiguration['kyc_viewed']?.stepNumber
                                        ? 'To Start'
                                        : 'Resume KYC'} */}
                                    Click Here
                                </Link>
                            </>
                        ),
                        bannerColor: '#FA8C16',
                    }
                } else if (
                    stepConfiguration[userData?.merchantDetails?.onboarding_status?.kwik_checkout_app]?.stepNumber ===
                    stepConfiguration['kyc_verification_pending']?.stepNumber
                ) {
                    return {
                        message: (
                            <>
                                Thank you for document submissions! To Check KYC status&nbsp;
                                <Link
                                    className='text-white'
                                    to='/onboarding/kyc'
                                    onClick={() =>
                                        logEvent(
                                            'dashboard_banner_track_status',
                                            'click',
                                            'checkout_settings',
                                            null,
                                            merchant_details.m_id,
                                        )
                                    }
                                >
                                    Click Here
                                </Link>
                            </>
                        ),
                        bannerColor: '#1677FF',
                    }
                } else if (
                    stepConfiguration[userData?.merchantDetails?.onboarding_status?.kwik_checkout_app]?.stepNumber ===
                    stepConfiguration['kyc_verification_failed']?.stepNumber
                ) {
                    return {
                        message: (
                            <>
                                KYC verification failed! To re-submit{' '}
                                <Link
                                    className='text-white'
                                    to='/onboarding/kyc'
                                    onClick={() =>
                                        logEvent(
                                            'dashboard_banner_track_status',
                                            'click',
                                            'checkout_settings',
                                            null,
                                            merchant_details.m_id,
                                        )
                                    }
                                >
                                    Click Here
                                </Link>{' '}
                            </>
                        ),
                        bannerColor: '#F5222D',
                    }
                } else if (
                    stepConfiguration[userData?.merchantDetails?.onboarding_status?.kwik_checkout_app]?.stepNumber ===
                    stepConfiguration['kyc_completed']?.stepNumber
                ) {
                    return {
                        message: (
                            <>
                                Thank you for document submissions. To check KYC status&nbsp;
                                <Link
                                    className='text-white'
                                    to='/onboarding/kyc'
                                    onClick={() =>
                                        logEvent(
                                            'dashboard_banner_track_status',
                                            'click',
                                            'checkout_settings',
                                            null,
                                            merchant_details.m_id,
                                        )
                                    }
                                >
                                    Click Here
                                </Link>
                                .
                            </>
                        ),
                        bannerColor: '#52C41A',
                    }
                }
            }
        },
        [userData],
    )
    if (!getMessage()?.message) return null // Render nothing if no message

    return (
        <div key={route.pathname}>
            {/* these hidden spans were added because the tailwind wasnt loading the classes programtically */}
            <span className='hidden bg-[#FA8C16]'></span>
            <span className='hidden bg-[#1677FF]'></span>
            <span className='hidden bg-[#52C41A]'></span>
            <span className='hidden bg-[#F5222D]'></span>

            <div
                className={`fixed inset-x-0 top-0 flex justify-center ${
                    IsScheduleNtoficationActive() && isMerchantUser && 'mt-[25px] -z-[1]'
                }`}
            >
                <div className={`w-full h-2 absolute top-0 bg-[${getMessage().bannerColor}]`}></div>
                <div
                    className={`p-1 text-white bg-[${
                        getMessage().bannerColor
                    }] w-1/2 h-9 flex justify-center rounded-full relative -top-3 items-end`}
                >
                    <p className='text-xs font-medium'>{getMessage()?.message}</p>
                </div>
            </div>
        </div>
    )
}

export default OnboardingBanner
