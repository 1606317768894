import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { ArrowDownOutlined, ArrowUpOutlined, Card, Chart, EllipsisOutlined, Segmented, Skeleton } from 'gokwik-ui-kit'
import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getUserData } from '@store/user/selectors'
import { useSelector } from 'react-redux'

const CheckoutAnalytics = ({ timeFrame }) => {
    const userData = useSelector(getUserData)

    const [data, setData] = useState({
        max_y_axis: 29,
        metrics: [
            {
                cod: 15,
                codPercentage: 68.18,
                cr: 23,
                crPercentage: 38.98,
                endDate: '2025-02-05',
                id: 1,
                prepaid: 7,
                prepaidPercentage: 31.82,
                startDate: '2025-01-30',
                total: 22,
            },
            {
                cod: 9,
                codPercentage: 45,
                cr: 17,
                crPercentage: 22.97,
                endDate: '2025-02-12',
                id: 2,
                prepaid: 11,
                prepaidPercentage: 55,
                startDate: '2025-02-06',
                total: 20,
            },
            {
                cod: 23,
                codPercentage: 79.31,
                cr: 26,
                crPercentage: 26.53,
                endDate: '2025-02-19',
                id: 3,
                prepaid: 6,
                prepaidPercentage: 20.69,
                startDate: '2025-02-13',
                total: 29,
            },
        ],
        percentageChange: 45,
        total: 29,
    })
    const [loading, setLoading] = useState(false)
    const [apiURLPath, setapiURLPath] = useState('gmv')
    const getCheckoutData = async () => {
        try {
            let urlPath =
                apiURLPath === 'order'
                    ? APIEndPoints?.executiveSummary?.checkoutOrderAnalytics
                    : APIEndPoints?.executiveSummary?.checkoutGMVAnalytics
            setLoading(true)
            const response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + urlPath,
                params: { type: timeFrame },
                skipLoader: true,
            })
            console.log(response, 'checkout')
            setLoading(false)
            if (response?.status === 200) {
                setData(response?.data?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getCheckoutData()
    }, [timeFrame, apiURLPath])

    const sortedMetrics = data?.metrics?.sort((a, b) => a.id - b.id)
    const getYAxisScale = (minPercentage, maxPercentage) => {
        let min = minPercentage
        let max = maxPercentage

        if (min - 10 <= 0) {
            min = 0
        } else {
            min = min - 10
        }

        if (max + 10 >= 100) {
            max = 100
        } else {
            max = max + 10
        }
        return { min, max }
    }
    const formatValue = (value) => {
        if (value < 1000) return value.toFixed(2)
        if (value < 100000) return `${(value / 1000).toFixed(0)}K`
        if (value < 10000000) return `${(value / 100000).toFixed(1)}L`
        return `${(value / 10000000).toFixed(1)}Cr`
    }

    const options = {
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: { show: false },
            zoom: {
                enabled: false,
            },
        },

        stroke: {
            width: [0, 0],
        },

        xaxis: {
            categories: sortedMetrics.map(
                (metric) =>
                    `${dayjs(metric.startDate).format("D MMM'YY")} - ${dayjs(metric.endDate).format("D MMM'YY")}`,
            ),
        },
        yaxis: [
            {
                show: true,
                title: {
                    text: apiURLPath === 'gmv' ? 'Total GMV (₹)' : 'Total No. Of Orders',
                },
                min: 0,
                max: data?.max_y_axis,
                ...(data?.max_y_axis <= 100 ? { tickAmount: 5 } : {}),
                labels: {
                    formatter: (val) => formatValue(val),
                },
            },
            {
                show: false,
                title: {
                    text: apiURLPath === 'gmv' ? 'Total GMV (₹)' : 'Total No. Of Orders',
                },
                min: 0,
                // Math.min(...data.metrics.map((metric) => metric.total)),
                max: data?.max_y_axis,
                labels: {
                    formatter: (val) => formatValue(val),
                },
            },

            {
                show: false,
                opposite: true,
                ...getYAxisScale(
                    Math.min(
                        data.metrics[0]?.crPercentage,
                        data.metrics[1]?.crPercentage,
                        data.metrics[2]?.crPercentage,
                    ),
                    Math.max(
                        data.metrics[0]?.crPercentage,
                        data.metrics[1]?.crPercentage,
                        data.metrics[2]?.crPercentage,
                    ),
                ),
                labels: {
                    formatter: (val) => `${val.toFixed(2)}%`,
                },
            },
        ],
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                if (opts.seriesIndex === 2) {
                    return ''
                }

                for (const metric of data.metrics) {
                    for (const [key, value] of Object.entries(metric)) {
                        if (value === val) {
                            if (key === 'cod') {
                                return `${metric.codPercentage}%`
                            } else if (key === 'prepaid') {
                                return `${metric.prepaidPercentage}%`
                            }
                        }
                    }
                }
                return `${val}%`
            },
        },
        legend: {
            position: 'bottom',
        },
        colors: ['#006ED2', '#2DA8FF', '#0A2D59'], // Colors for Prepaid, COD, CR
        plotOptions: {
            bar: {
                ColumnHeight: '100%',
                columnWidth: '40%',
                dataLabels: {
                    position: 'center',
                },
            },
        },
        markers: {
            size: 0,
            hover: {
                size: 6,
            },
        },
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            followCursor: true,
            y: {
                formatter: function (val, opts) {
                    if (opts.seriesIndex === 2) {
                        return `${sortedMetrics[opts.dataPointIndex].crPercentage}%`
                    } else if (opts.seriesIndex === 1) {
                        return `${apiURLPath === 'gmv' ? '₹' : ''}${sortedMetrics[
                            opts.dataPointIndex
                        ].cod?.toLocaleString('en-IN')}`
                    } else if (opts.seriesIndex === 0) {
                        return `${apiURLPath === 'gmv' ? '₹' : ''}${sortedMetrics[
                            opts.dataPointIndex
                        ].prepaid?.toLocaleString('en-IN')}`
                    }
                    return val
                },
            },
        },
    }

    const series = [
        {
            name: 'Prepaid',
            type: 'column',
            data: sortedMetrics.map((metric) => metric.prepaid),
        },
        {
            name: 'COD',
            type: 'column',
            data: sortedMetrics.map((metric) => metric.cod),
        },
        {
            name: 'CR',
            type: 'line',
            data: sortedMetrics.map((metric) => metric.crPercentage),
        },
    ]

    const getTopData = useMemo(() => {
        return {
            lastCheckoutPc: data?.total,
            percentChange: data?.percentageChange,
        }
    }, [data])

    return (
        <div>
            <Card
                title={
                    <div className='flex justify-between items-center w-[85%]'>
                        <div className='flex-none'>Checkout Performance</div>

                        <div className='flex-grow flex justify-center'>
                            <Segmented
                                options={['GMV', 'Order']}
                                onChange={(value: string) => {
                                    logEvent(
                                        'es_analytics_gmv_orders_filter_applied',
                                        'click',
                                        'executive_summary',
                                        userData?.userDetails?.email,
                                        userData?.merchantDetails?.m_id,
                                        userData?.merchantDetails?.short_name,
                                        userData?.userDetails?.name,
                                        { filterApplied: value },
                                    )
                                    setapiURLPath(value.toLowerCase())
                                }}
                            />
                        </div>
                    </div>
                }
                extra={<EllipsisOutlined className='rotate-90' />}
            >
                {loading ? (
                    <Skeleton loading={loading} active avatar />
                ) : (
                    <div>
                        <div>
                            <span className='text-2xl font-semibold'>
                                {apiURLPath === 'gmv' && '₹'}
                                {formatValue(getTopData?.lastCheckoutPc)}
                            </span>
                            {'   '}
                            {getTopData?.percentChange > 0 ? (
                                <span className='text-green-600 text-base font-semibold'>
                                    {getTopData?.percentChange}% <ArrowUpOutlined />
                                </span>
                            ) : getTopData?.percentChange < 0 ? (
                                <span className='text-red-600 text-base font-semibold'>
                                    {getTopData?.percentChange}% <ArrowDownOutlined />
                                </span>
                            ) : (
                                <>{getTopData?.percentChange}%</>
                            )}
                            <br />
                            {apiURLPath === 'gmv' && (
                                <span className='text-[#98A2B3] text-xs'>
                                    GMV as on {dayjs(data?.metrics[2]?.startDate).format("Do MMM 'YY")} -{' '}
                                    {dayjs(data?.metrics[2]?.endDate).format("Do MMM 'YY")}
                                </span>
                            )}
                        </div>

                        <div>
                            <Chart
                                //@ts-ignore
                                options={options}
                                series={series}
                                type='bar'
                                height={300}
                            />
                        </div>
                        <div className='text-[#98A2B3] text-xs float-end'>Last 3 {timeFrame}s</div>
                    </div>
                )}
            </Card>
        </div>
    )
}

export default CheckoutAnalytics
