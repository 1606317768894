import { Row, Col, Button, message, Search, CheckCircleOutlined } from 'gokwik-ui-kit'
import React, { useState } from 'react'
import Step2_1 from '@library/images/common/kwikpass-onboarding-images/Step2_1.png'
import Step2_2 from '@library/images/common/kwikpass-onboarding-images/Step2_2.png'
import Step2_3 from '@library/images/common/kwikpass-onboarding-images/Step2_3.png'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { getMerchantDetails, getUserData } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import shop from '@library/images/icons/nav/shop'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

export const Activation = ({ navigateToStep }) => {
    const [loading, setLoading] = useState(false)
    const [disbleMutlitpassInput, setDisablemultipassInput] = useState(null)
    const [multipassSecret, setMultipassSecret] = useState('')
    const merchant_details = useSelector(getMerchantDetails)
    const [error, setError] = useState(false)
    const [tokenError, setTokenError] = useState('')
    const userDetails = useSelector(getUserData)

    // const user_details = useSelector(getUserDetails)
    const getStoreName = () => {
        const url = merchant_details?.website
        const storeName = url ? url?.split('.')[0] : url
        return storeName
    }
    const storeName = getStoreName()

    function maskString(input) {
        const length = input.length

        if (length === 3) {
            // If length is 3, show first and last character with * in the middle
            return `${input[0]}*${input[2]}`
        } else if (length > 10) {
            // For longer strings, keep first 6 and last 4 characters visible
            const firstPart = input.slice(0, 6) // First 6 characters
            const lastPart = input.slice(-4) // Last 4 characters
            const maskedLength = length - firstPart.length - lastPart.length // Length to mask
            return `${firstPart}${'*'.repeat(maskedLength)}${lastPart}`
        }

        // For shorter strings (length <= 10), mask the middle part
        const middleStart = Math.floor(length / 3) // Start masking at 1/3 length
        const middleEnd = Math.ceil((2 * length) / 3) // End masking at 2/3 length

        const firstPart = input.slice(0, middleStart)
        const lastPart = input.slice(middleEnd)
        const maskedLength = middleEnd - middleStart // Length to mask

        return `${firstPart}${'*'.repeat(maskedLength)}${lastPart}`
    }

    const handleActivation = async () => {
        if (!(await isStep2point1Completed())) {
            setError(true)
            message.error('Step 2.1 Incomplete')
            return
        }

        if (merchant_details?.is_shopify_plus_plan && !multipassSecret) {
            message.error('Please add Multipass Secret Token')
            return
        }
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateMerchantOnboardingStatus + merchant_details.m_id,
            payload: {
                kp_onboarding_status: 'login_preferences_activated',
            },
        })

        if (response?.data?.status_code === 200) {
            logEvent(
                'kp_activation_completed',
                'click',
                'activation_screen',
                userDetails?.userDetails?.email,
                merchant_details?.m_id,
                merchant_details?.short_name,
            )
            navigateToStep('login_preferences_activated')
        } else {
            message.error('Something went wrong. Please retry ! ')
        }
    }

    const validateMultipassSecretToken = async (token) => {
        if (!token) {
            message.error('Token is required')
            return
        }
        let url
        if (process.env.NODE_ENV === 'development') {
            url = 'https://api-gw-v4.dev.gokwik.io/dev/kp/api/v1/shopify-app/validate-multipass-token'
        } else if (window.location.href?.includes('qa-')) {
            url = 'https://api-gw-v4.dev.gokwik.in/qa/kp/api/v1/shopify-app/validate-multipass-token'
        } else {
            url = 'https://gkx.gokwik.co/kp/api/v1/shopify-app/validate-multipass-token'
        }
        const response = await makeAPICall({
            method: 'patch',
            url: url,
            headers: {
                shop: merchant_details?.website,
                // shop: 'dev-plus-gokwik.myshopify.com',
            },
            payload: {
                token,
                // token: '8ee482f0ce49bdbf344b3f88b827cbbc',
            },
        })
        setLoading(true)
        if (response?.data?.status_code === 200) {
            logEvent(
                'kp_activation_multipass_validate',
                'click',
                'activation_screen',
                userDetails?.userDetails?.email,
                merchant_details?.m_id,
                merchant_details?.short_name,
            )
            const maskedToken = maskString(token)
            setMultipassSecret(maskedToken)
            setDisablemultipassInput(true)
            setTokenError('')
        } else {
            setMultipassSecret('')
            setDisablemultipassInput(false)
            setTokenError(response?.response?.data?.error)
        }

        setLoading(false)
    }

    const handleChange = (e) => {
        const value = e?.target?.value

        // Regular expression for alphanumeric values only (no spaces or special characters)
        const isValid = /^[a-zA-Z0-9]*$/.test(value)

        if (isValid) {
            setMultipassSecret(value)
        } else {
            message.error('Token should be without space or special characters')
        }
    }

    const isStep2point1Completed = async () => {
        let url
        if (process.env.NODE_ENV === 'development') {
            url = 'https://api-gw-v4.dev.gokwik.io/dev/kp/api/v1/shopify-app/validate-customer-settings'
        } else if (window.location.href?.includes('qa-')) {
            url = 'https://api-gw-v4.dev.gokwik.in/qa/kp/api/v1/shopify-app/validate-customer-settings'
        } else {
            url = 'https://gkx.gokwik.co/kp/api/v1/shopify-app/validate-customer-settings'
        }
        const response = await makeAPICall({
            method: 'get',
            url: url,
            headers: {
                'kp-merchant-id': merchant_details?.m_id,
                // 'kp-merchant-id': '19g6ilkatpl78',
                shop: merchant_details?.website,
                // shop: 'temp-kwikpass.myshopify.coma',
            },
        })

        if (response?.data?.status_code === 200) {
            setError(false)
            return true
        } else {
            return false
        }
    }

    return (
        <div className='mt-6 mx-12 p-4 rounded bg-opacity-55 bg-slate-100'>
            <Row gutter={32}>
                <Col span={12}>
                    <div className='flex flex-col gap-6'>
                        <div>
                            <span className='text-xl font-semibold'>
                                Enable login functionality on Shopify and change preferences
                            </span>{' '}
                            <span className='text-slate-500 text-xs'>Step-by-step guide</span>
                        </div>

                        <div className='flex gap-1 items-end'>
                            <span className='text-xl font-semibold'>2.1</span>{' '}
                            <img src={Step2_1} alt='Step 2.1' className='w-3/4' />
                        </div>

                        <div className='flex gap-1 items-end'>
                            <span className='text-xl font-semibold'>2.2</span>{' '}
                            <img src={Step2_2} alt='Step 2.2' className='w-[90%] h-[90%]' />
                        </div>

                        {merchant_details?.is_shopify_plus_plan && (
                            <div className='flex gap-1 items-end'>
                                <span className='text-xl font-semibold'>2.3</span>{' '}
                                <img src={Step2_3} alt='Step 2.3' className='w-3/4 border border-black border-solid' />
                            </div>
                        )}
                    </div>
                </Col>
                <Col span={12}>
                    <div className='bg-white p-4 rounded-sm flex flex-col gap-16'>
                        <div className='flex flex-col gap-5'>
                            <div className='flex gap-2 items-center'>
                                <div className='w-4/5'>
                                    <div className='text-xl font-semibold'>Step 2.1</div>
                                    <ol>
                                        <li className='p-1'>Navigate to the settings on your Shopify store</li>
                                        <li className='p-1'>
                                            Select <strong>Customer</strong> Accounts
                                        </li>
                                        <li className='p-1'>
                                            Then ensure the <strong>login link</strong> is TURNED ON
                                        </li>
                                        <li className='p-1'>Make sure that the customer account version is classic</li>
                                    </ol>
                                </div>

                                <Button
                                    variant='primary'
                                    onClick={() => {
                                        logEvent(
                                            'kp_activation_redirect_2.1',
                                            'click',
                                            'activation_screen',
                                            userDetails?.userDetails?.email,
                                            merchant_details?.m_id,
                                            merchant_details?.short_name,
                                        )
                                        window.open(
                                            `https://admin.shopify.com/store/${storeName}/settings/customer_accounts`,
                                            '_blank',
                                            'noopener,noreferrer',
                                        )
                                    }}
                                >
                                    Go to Shopify
                                </Button>
                            </div>

                            <div className='flex gap-2 items-center'>
                                <div className='w-4/5'>
                                    <div className='text-xl font-semibold'>Step 2.2</div>
                                    <ol>
                                        <li className='p-1'>
                                            Navigate to <strong>Preferences</strong> under <strong>Online Store</strong>
                                        </li>
                                        <li className='p-1'>
                                            Check if the <strong>hCaptcha</strong> under{' '}
                                            <strong>Spam protection</strong> is disabled as shown in adjoining image. If
                                            not, please <strong>disable</strong> and then <strong>save</strong>.
                                        </li>
                                    </ol>
                                </div>
                                <Button
                                    variant='primary'
                                    onClick={() => {
                                        logEvent(
                                            'kp_activation_redirect_2.2',
                                            'click',
                                            'activation_screen',
                                            userDetails?.userDetails?.email,
                                            merchant_details?.m_id,
                                            merchant_details?.short_name,
                                        )

                                        window.open(
                                            `https://admin.shopify.com/store/${storeName}/online_store/preferences`,
                                            '_blank',
                                            'noopener,noreferrer',
                                        )
                                    }}
                                >
                                    Go to Shopify
                                </Button>
                            </div>

                            {merchant_details?.is_shopify_plus_plan && (
                                <div className='flex flex-col gap-2'>
                                    <div className='text-xl font-semibold'>Step 2.3</div>
                                    <div className='flex flex-col'>
                                        <div className='flex gap-2'>
                                            <div className='w-4/5'>
                                                <span className='text-sm'>
                                                    Navigate to the bottom of the customer account page and you'll find
                                                    the multipass secret as shown in the adjoining image
                                                </span>
                                            </div>

                                            <Button
                                                variant='primary'
                                                onClick={() => {
                                                    logEvent(
                                                        'kp_activation_redirect_2.3',
                                                        'click',
                                                        'activation_screen',
                                                        userDetails?.userDetails?.email,
                                                        merchant_details?.m_id,
                                                        merchant_details?.short_name,
                                                    )
                                                    window.open(
                                                        `https://admin.shopify.com/store/${storeName}/settings/customer_accounts`,
                                                        '_blank',
                                                        'noopener,noreferrer',
                                                    )
                                                }}
                                            >
                                                Go to Shopify
                                            </Button>
                                        </div>
                                        <br />
                                        <span className='text-xs'>Add Multipass Secret</span>
                                        <Search
                                            enterButton={
                                                typeof disbleMutlitpassInput === 'boolean' &&
                                                disbleMutlitpassInput === true ? (
                                                    <span className='text-green-500'>
                                                        Validated <CheckCircleOutlined className='text-green-500' />
                                                    </span>
                                                ) : (
                                                    <>Validate</>
                                                )
                                            }
                                            size='large'
                                            loading={loading}
                                            onSearch={validateMultipassSecretToken}
                                            disabled={disbleMutlitpassInput}
                                            value={multipassSecret}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    {tokenError && <span className='text-red-500'>{tokenError}</span>}
                                </div>
                            )}
                        </div>

                        <div className='flex flex-col gap-2 items-start'>
                            <div>Confirm once all changes are made</div>
                            <Button variant='primary' onClick={handleActivation}>
                                {merchant_details?.is_shopify_plus_plan
                                    ? 'All steps completed'
                                    : 'Step 2.1 and 2.2 done'}
                            </Button>
                            {error && <span className='text-red-500'>Step 2.1 Incomplete</span>}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
