import React, { useEffect, useState } from 'react';
import { Button, Divider } from "gokwik-ui-kit";
import previewPlaceholder2 from '@library/images/icons/checkoutStep2.png'; 
import darkExternalLink from '@library/images/icons/dark-external-link.svg';
import lightExternalLink from '@library/images/icons/light-external-link.svg';
import HowToStepsWithPreview from '../CommonComponents/howToStepsAndPreview';
import { getMerchantDetails, getUserDetails } from '@store/user/selectors';
import { useSelector } from 'react-redux';
import { logEvent } from "@library/utilities/userLogsEvents/userLogEvents";
import { updateMerchantOnboardingCheckoutConfigsStatus } from '../CommonComponents/Utils';
import CheckoutStepsHeader from '../CommonComponents/checkoutStepsHeader';
import { fetchUserDetails } from '@store/user';
import { useAppDispatch } from '@library/utilities/hooks'

const CheckoutConfigStep2 = ({ onStepComplete, currentStep, expandedStep, toggleStep }) => {
    const [siteVisited, setSiteVisited] = useState(false);
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)
    const [showContent, setShowContent] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (expandedStep === 2 && currentStep !== 2) {
            setSiteVisited(true);
        }
        if (expandedStep == 2) {
            setShowContent(false);
            setTimeout(() => setShowContent(true), 100);
        }
    }, [expandedStep]);

    const steps = [
        { 
            description: (<>Click on <b>“Launch Shopify Dashboard”</b> Button, on <b>Shopify Dashboard</b> click <b>“Duplicate”</b> on the live theme to test changes before going live.</>),
            step: 2,
        },
        { 
            description: (<>On new theme, click on <b>“Customize”</b> button.</>) 
        },
        { 
            description: (<>Select <b>“App Embed”</b> from the left menu, then toggle on <b>“Enable GoKwik SDK.”</b></>) 
        },
        { 
            description: (<>In the left menu, select <b>“Section”</b>, then select <b>“Product Page”</b> from the dropdown. Disable the <b>“Buy Button”</b> and click <b>“Add Block &gt; Apps &gt; Add GoKwik Buy Now Button.”</b></>)
        },
        { 
            description: (<>Now select <b>“Cart page”</b> from dropdown and delete <b>“Checkout Button”</b>, then click on <b>“Add block &gt; Apps &gt; GoKwik Checkout Button.”</b></>) 
        },
        { 
            description: (<>Come back to this page and click on <b>“Mark as done”</b> and click on <b>“Buttons Added”</b> Button.</>) 
        }
    ]

    const handleLaunchShpDashboard = () => {
        logEvent(
            siteVisited ? "add_gokwik_buttons_clicked_again" : 'add_gokwik_buttons_clicked',
            'click',
            'checkout-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { Step: "checkout 2" }
        )
        setSiteVisited(true)
        window.open('https://' + `${merchantDetails?.website}` + '/admin/themes', '_blank');
    }

    const handleButtonsAdded = () => {
        logEvent(
            'button_addition_completed',
            'click',
            'checkout-setup',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { Step: "checkout 2" }
        )
        updateMerchantOnboardingCheckoutConfigsStatus('gk_checkout_button_added', () => {
            dispatch(fetchUserDetails())
            onStepComplete();
        }, merchantDetails)
    }

    return (
        <div>
            <div className="border border-gray-300 rounded-lg bg-white w-[74vw]" >
                <CheckoutStepsHeader step={'Step 2'} heading={'Add GoKwik Checkout Buttons'} toggleStep={toggleStep} stepId={2} currentStep={currentStep} />

                {expandedStep === 2 ? (
                    <div style={{
                        opacity: showContent ? 1 : 0,
                        transform: showContent ? 'translateY(0)' : 'translateY(20px)',
                        transition: 'opacity 0.5s ease, transform 0.5s ease',
                    }}>
                        <Divider className='m-0'/>
                        <HowToStepsWithPreview steps={steps} previewImage={previewPlaceholder2} videoUrl={'https://pdp.gokwik.co/sa-login-ui/assets/2.ButtonAddition.mp4'} page={'checkout'}/>
                        <Divider  className='m-0' />

                        <div className={`flex ${siteVisited ? "" : "flex-row-reverse"} justify-between items-center p-3`}>
                            <Button
                                onClick={handleLaunchShpDashboard}
                                variant={siteVisited ? 'default' : 'primary'}
                                className="flex items-center"
                                size="large"
                            >
                                Launch Shopify Dashboard <img style={{ marginLeft: '0.4rem' }} src={siteVisited ? darkExternalLink : lightExternalLink} />
                            </Button>
                            {siteVisited && (
                                <Button disabled={expandedStep === 2 && currentStep !== 2} onClick={handleButtonsAdded} type="primary" size="large">
                                    Buttons Added
                                </Button>
                            )}
                        </div>
                    </div>
                ) : "" }
            </div>
        </div>
    );
};

export default CheckoutConfigStep2;
