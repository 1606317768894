export const PermissionValues = {
    roles: {
        view: 'view_roles',
        add_roles: 'add_roles',
        edit_roles: 'edit_roles',
    },
    permissions: {
        view: 'view_permissions',
        add_permissions: 'add_permissions',
    },
    dashboard: {
        view: 'view_dashboard',
    },
    orders: {
        view: 'view_order',
    },
    refunds: {
        view: 'view_refund',
    },
    settlements: {
        view: 'view_settlements',
    },
    reports: {
        view: 'view_reports',
        viewUnmaskedReport: 'view_unmasked_report',
    },
    transactions: {
        view: 'view_transactions',
    },
    abandonedCarts: {
        view: 'abandoned_cart',
    },
    users: {
        view: 'view_users',
        addUser: 'add_users',
    },
    settings: {
        view: 'setting',
    },
    checkoutAnalytics: {
        view: 'view_checkout_analytics',
    },
    paymentAnalytics: {
        view: 'view_payment_analytics',
    },
    paymentLinks: {
        view: 'view_payment_links',
    },
    marketing_analytics: {
        view: 'view_marketing_analytics',
    },
    billing_reports: {
        view: 'view_billing',
    },
    rate_capture: {
        view: 'view_agreement',
        edit: 'edit_agreement',
        edit_confirmed: 'edit_confirmed_agreement',
    },
}

export const MerchantConfigKeys = {
    checkoutAnalytics: 'enable_checkout_analytics',
    paymentAnalytics: 'show_payment_analytics_v2',
    marketingAnalytics: 'show_marketing_campaign',
    showReports: 'show_reports',
    paymentLinks: 'enable_payment_links',
    kwikCheckout: 'kwik_checkout',
    transactions: 'enable_transactions',
    showKwikAI: 'show_kwik_ai',
    kwikAiRiskNavigation: 'show_kwik_ai_risk_navigation',
    kcConfig: 'kc_config',
    includeRTORisk: 'include_rto_risk',
    showRTOAnalytics: 'show_rto_analytics',
    paymentsSplitSettlementDashboardEnabled: 'payments_split_settlement_dashboard_enabled',
    checkoutOne: 'checkout_1',
}

export const SuccessStatusCodes = [200, 201]

export const PaymentMethods = {
    CREDIT_CARD: 'credit_card',
}

export const merchantRoutes = [
    '/onboarding/pricing-details',
    '/onboarding/kyc',
    '/onboarding/feature-tour',
    '/onboarding/get-started',
    '/onboarding/kc/onboarding-steps',
    '/onboarding/congratulations',
    '/onboarding/kp/onboarding-steps',
    '/data-integration',
    '/oauth-redirect',
]

export const KPMerchantPlatform = {
    shopify: 'shopify',
    custom_full_stack: 'custom full stack',
    custom_headless: 'custom headless',
    custom_shopify: 'custom shopify',
}

export const KPPlatform = {
    web: 'web',
    app: 'app',
}
