import React from 'react'

export default function CloseEye() {
    return (
        <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='0.5' y='0.5' width='43' height='43' rx='15.5' fill='white' fill-opacity='0.24' />
            <rect x='0.5' y='0.5' width='43' height='43' rx='15.5' stroke='url(#paint0_linear_6246_24481)' />
            <g clip-path='url(#clip0_6246_24481)'>
                <path
                    d='M20.5856 20.5859C20.2105 20.9611 19.9999 21.4698 20 22.0003C20.0001 22.5307 20.2109 23.0394 20.5861 23.4144C20.9612 23.7895 21.47 24.0001 22.0004 24C22.5309 23.9999 23.0395 23.7891 23.4146 23.4139'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M26.681 26.673C25.2782 27.5507 23.6547 28.0109 22 28C18.4 28 15.4 26 13 22C14.272 19.88 15.712 18.322 17.32 17.326M20.18 16.18C20.779 16.0588 21.3888 15.9985 22 16C25.6 16 28.6 18 31 22C30.334 23.11 29.621 24.067 28.862 24.87'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M13 13L31 31'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
            </g>
            <defs>
                <linearGradient
                    id='paint0_linear_6246_24481'
                    x1='0.848501'
                    y1='2.2083'
                    x2='41.5344'
                    y2='34.45'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#91D0FF' stop-opacity='0.12' />
                    <stop offset='1' stop-color='#0094D5' stop-opacity='0.12' />
                </linearGradient>
                <clipPath id='clip0_6246_24481'>
                    <rect width='24' height='24' fill='white' transform='translate(10 10)' />
                </clipPath>
            </defs>
        </svg>
    )
}
