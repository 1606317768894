import CodOnboardingStep1 from './CodOnboardingStep1';
import CodOnboardingStep2 from './CodOnboardingStep2';
import CodOnboardingStep3 from './CodOnboardingStep3';
import { useState } from 'react';

const CodStepsWrapper = ({currentStep, setCurrentStep}) => {
    const [expandedStep, setExpandedStep] = useState(currentStep);

    const handleNextStep = () => {
        setExpandedStep(currentStep);
    };
    const toggleStep = (step: number) => {
        setExpandedStep(step);
    }

    return (
        <div className="flex flex-col gap-4 h-[73vh] overflow-y-auto pb-2" >
            <CodOnboardingStep1 onStepComplete={handleNextStep} currentStep={currentStep} expandedStep={expandedStep} toggleStep={toggleStep} />
            <CodOnboardingStep2 onStepComplete={handleNextStep} currentStep={currentStep} expandedStep={expandedStep} toggleStep={toggleStep} />
            <CodOnboardingStep3 onStepComplete={handleNextStep} currentStep={currentStep} expandedStep={expandedStep} toggleStep={toggleStep} />
        </div>
    );
};

export default CodStepsWrapper;
