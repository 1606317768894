export const merchantDetailFields = [
  {
    key: 'file_name',
    label: 'Merchant agreement',
    url: 'signed_url',
    input_details: {
      type: 'upload',
    },
  },
  {
    key: 'start_date',
    label: 'Agreement start date',
    input_details: {
      type: 'date',
    },
  },
  {
    key: 'end_date',
    label: 'Agreement end date',
    input_details: {
      type: 'date',
    },
  },
  {
    key: 'merchant_size',
    label: 'Merchant size',
    input_details: {
      type: 'select',
      options: [
        {
          label: 'ENT',
          value: 'ent',
        },
        {
          label: 'SMB',
          value: 'smb',
        },
        {
          label: 'Long Tail',
          value: 'long_tail',
        },
      ],
    },
  },
  {
    key: 'merchant_type',
    label: 'Merchant type',
    input_details: {
      type: 'select',
      options: [
        {
          label: 'D2C',
          value: 'd2c',
        },
        {
          label: 'Marketplace',
          value: 'marketplace',
        },
      ],
    },
  },
  {
    key: 'products_enabled',
    label: 'Purchased products',
    input_details: {
      type: 'select',
      mode: 'multiple',

      options: [
        {
          label: 'Checkout',
          value: 'checkout',
        },
        {
          label: 'RTO',
          value: 'rto',
        },
      ],
    },
  },
  {
    key: 'agency',
    label: 'Agency',
    input_details: {
      type: 'select',
      options: [
        {
          label: 'ANS',
          value: 'ans',
        },
        {
          label: 'Greenwich',
          value: 'greenwich',
        },
      ],
    },
  },
  {
    key: 'agency_commission',
    label: 'Agency comission %',
    input_details: {
      type: 'number',
      allowDecimals: true,
      range: [0, 100],
    },
  },
];
