import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    Card,
    Chart,
    EllipsisOutlined,
    InfoCircleFilled,
    Segmented,
    Skeleton,
} from 'gokwik-ui-kit'
import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'

const CheckoutAnalytics = ({ timeFrame }) => {
    const [data, setData] = useState({
        conversion: 2341,
        metrics: [
            {
                codPercentage: 97,
                crPercentage: 45,
                endDate: '2024-01-31',
                id: 1,
                prepaidPercentage: 13,
                startDate: '2024-01-02',
                cod: 17349,
                cr: 2532,
                prepaid: 4232,
            },
            {
                codPercentage: 94.87,
                crPercentage: 19,
                endDate: '2024-03-01',
                id: 2,
                prepaidPercentage: 15.13,
                startDate: '2024-02-01',
                cod: 35349,
                cr: 3532,
                prepaid: 2232,
            },
            {
                codPercentage: 95,
                crPercentage: 60,
                endDate: '2024-03-31',
                id: 3,
                prepaidPercentage: 10,
                startDate: '2024-03-02',
                cod: 23349,
                cr: 7532,
                prepaid: 6232,
            },
        ],
        percentageChange: 23.12,
    })
    const [loading, setLoading] = useState(false)
    const [apiURLPath, setapiURLPath] = useState('gmv')
    const getCheckoutData = async () => {
        try {
            let urlPath =
                apiURLPath === 'order'
                    ? APIEndPoints?.executiveSummary?.checkoutOrderAnalytics
                    : APIEndPoints?.executiveSummary?.checkoutGMVAnalytics
            setLoading(true)
            const response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + urlPath,
                params: { type: timeFrame },
                skipLoader: true,
            })
            console.log(response, 'checkout')
            setLoading(false)
            if (response?.status === 200) {
                setData(response?.data?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getCheckoutData()
    }, [timeFrame, apiURLPath])

    const sortedMetrics = data?.metrics?.sort((a, b) => a.id - b.id)

    const options = {
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: { show: false },
            zoom: {
                enabled: false,
            },
        },

        stroke: {
            width: [0, 0], // Bars without stroke, line with stroke
        },

        xaxis: {
            categories: sortedMetrics.map(
                (metric) =>
                    `${dayjs(metric.startDate).format("D MMM'YY")} - ${dayjs(metric.endDate).format("D MMM'YY")}`,
            ),
        },
        yaxis: {
            min: 0,
            max: 100,
            tickAmount: 10,
            labels: {
                formatter: (val) => val,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                console.log(opts)

                if (opts.seriesIndex === 2) {
                    return ''
                }
                return `${val}%`
            },
        },
        legend: {
            position: 'bottom',
        },
        colors: ['#006ED2', '#2DA8FF', '#0A2D59'], // Colors for Prepaid, COD, CR
        plotOptions: {
            bar: {
                ColumnHeight: '100%',
                columnWidth: '40%',
                dataLabels: {
                    position: 'center',
                },
            },
        },
        markers: {
            size: 0,
            hover: {
                size: 6,
            },
        },
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            followCursor: true,
            y: {
                formatter: function (val, opts) {
                    if (opts.seriesIndex === 2) {
                        return `${sortedMetrics[opts.dataPointIndex].cr}`
                    } else if (opts.seriesIndex === 1) {
                        return `${apiURLPath === 'gmv' ? '₹' : ''}${sortedMetrics[opts.dataPointIndex].cod}`
                    } else if (opts.seriesIndex === 0) {
                        return `${apiURLPath === 'gmv' ? '₹' : ''}${sortedMetrics[opts.dataPointIndex].prepaid}`
                    }
                    return val
                },
            },
        },
    }

    const series = [
        {
            name: 'Prepaid',
            type: 'column',
            data: sortedMetrics.map((metric) => metric.prepaidPercentage),
        },
        {
            name: 'COD',
            type: 'column',
            data: sortedMetrics.map((metric) => metric.codPercentage),
        },
        {
            name: 'CR',
            type: 'line',
            data: sortedMetrics.map((metric) => metric.crPercentage),
        },
    ]

    const getTopData = useMemo(() => {
        return {
            lastCheckoutPc: data?.conversion,
            percentChange: data?.percentageChange,
        }
    }, [data])

    return (
        <div>
            <Card
                title={
                    <div className='flex justify-between items-center w-[85%]'>
                        <div className='flex-none'>Checkout Performance</div>

                        <div className='flex-grow flex justify-center'>
                            <Segmented
                                options={['GMV', 'Order']}
                                onChange={(value: string) => {
                                    setapiURLPath(value.toLowerCase())
                                }}
                            />
                        </div>
                    </div>
                }
                extra={<EllipsisOutlined className='rotate-90' />}
            >
                {loading ? (
                    <Skeleton loading={loading} active avatar />
                ) : (
                    <div>
                        <div>
                            <span className='text-2xl font-semibold'>{getTopData?.lastCheckoutPc}</span>
                            {'   '}
                            {getTopData?.percentChange > 0 ? (
                                <span className='text-green-600 text-base font-semibold'>
                                    {getTopData?.percentChange}% <ArrowUpOutlined />
                                </span>
                            ) : getTopData?.percentChange < 0 ? (
                                <span className='text-red-600 text-base font-semibold'>
                                    {getTopData?.percentChange}% <ArrowDownOutlined />
                                </span>
                            ) : (
                                <>{getTopData?.percentChange}%</>
                            )}
                        </div>

                        <div>
                            <Chart
                                //@ts-ignore
                                options={options}
                                series={series}
                                type='bar'
                                height={300}
                            />
                        </div>
                        <div className='text-[#98A2B3] text-xs float-end'>Last 3 {timeFrame}s</div>
                    </div>
                )}
            </Card>
        </div>
    )
}

export default CheckoutAnalytics
