import { ColumnsType, Tag, Tooltip } from 'gokwik-ui-kit'
import dayjs from 'dayjs'
import { addBreadcrumb, updateBreadcrumbs } from '@gokwik/utilities'
import { Link } from 'react-router-dom'

function DateChange(data) {
    if (new Date(data).getFullYear() == 1) {
        return ''
    }

    if (data) {
        return `${new Date(data).toLocaleString().split(',')[0]}

        ${new Date(data).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        })}`
    } else return ''
}
const renderAmount = (val) => <span className='font-medium'>₹ {(+(val || 0)).toFixed(2)}</span>

function titleCase(str) {
    var splitStr = str.toLowerCase().split(' ')
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    // Directly return the joined string
    return splitStr.join(' ')
}

export const customerOrdersDetailsColumns: ColumnsType<any> = [
    {
        title: 'Order Number',
        dataIndex: 'order_number',
        width: 150,
        ellipsis: {
            showTitle: false,
        },
        render: (text: string) => (
            <Link
                to={`/checkout/orders/${text}`}
                onClick={() =>
                    updateBreadcrumbs((prev) => {
                        const index = prev?.findIndex((itm) => itm?.key === 'order-details')

                        return [
                            ...(index !== -1 ? prev.slice(0, index - 1) : prev),
                            {
                                key: 'order-details',
                                href: `/checkout/orders/${text}`,
                                text: `Order#${text}`,
                            },
                        ]
                    })
                }
            >
                <a style={{ width: 'min-content' }}>
                    <Tooltip placement='topLeft' title={text}>
                        {text?.toUpperCase()}
                    </Tooltip>
                </a>
            </Link>
        ),
        fixed: 'left',
    },
    {
        title: 'Merchant Name',
        dataIndex: 'business_name',
        width: 150,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Order Status',
        dataIndex: 'order_status',
        width: 150,
        render: (text: string) => (
            <div>
                <>
                    {' '}
                    {text === 'confirmed' && (
                        <Tag
                            variant={'success'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Confirmed
                        </Tag>
                    )}
                </>
                <>
                    {text === 'pending' && (
                        <Tag
                            variant={'warning'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Pending
                        </Tag>
                    )}
                </>
                <>
                    {text === 'cancelled' && (
                        <Tag
                            variant={'error'}
                            className={`!px-3 !py-1 rounded-2xl inter font-medium inline-block capitalize text-xs`}
                        >
                            Cancelled
                        </Tag>
                    )}
                </>

                <>
                    {text === 'verified' && (
                        <span
                            style={{
                                color: '#1570EF',
                                borderRadius: '15px',
                                border: '0.6px solid var(--blue-light-200, #B9E6FE)',
                                background: 'var(--blue-light-25, #F5FBFF)',
                                padding: '4px',
                            }}
                        >
                            Verified
                        </span>
                    )}
                </>
            </div>
        ),
    },
    {
        title: 'Payment Mode',
        dataIndex: 'payment_method',
        width: 150,
    },
    {
        title: 'Total Item Count',
        dataIndex: 'total_item_count',
        width: 150,
        key: 'price',
        render: (text) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
    },
    {
        title: 'Total Qty Ordered',
        dataIndex: 'total_qty_ordered',
        width: 150,
        key: 'total',
        render: (text) => (
            <Tooltip placement='topLeft' title={text}>
                {text}
            </Tooltip>
        ),
    },
    {
        title: 'Grand Total',
        dataIndex: 'grand_total',
        width: 100,
        render: (text) => (
            <Tooltip placement='topLeft' title={text}>
                {renderAmount(text)}
            </Tooltip>
        ),
    },
    {
        title: 'Coupon Code',
        dataIndex: 'coupon_code',

        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Payment Info',
        dataIndex: 'payment_staus',
        width: 150,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Customer Id',
        dataIndex: 'customer_id',

        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: 'Moid',
        dataIndex: 'moid',

        width: 200,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
    {
        title: <span>Created At</span>,
        dataIndex: 'created_at',
        width: 200,
        ellipsis: true,
        render: (text: string) => <span>{DateChange(text)}</span>,
        sorter: (a, b) => (dayjs(a.created_at).isBefore(dayjs(b.created_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
    },

    {
        title: <span>Updated At</span>,
        dataIndex: 'updated_at',
        width: 200,
        render: (text: string) => <span>{DateChange(text)}</span>,
        sorter: (a, b) => (dayjs(a.updated_at).isBefore(dayjs(b.updated_at)) ? -1 : 1),
        sortDirections: ['ascend', 'descend'],
    },
]

export const customerAddressesColumns: ColumnsType<any> = [
    {
        title: '#',
        dataIndex: 'index',
        width: 12,
        ellipsis: {
            showTitle: false,
        },
        render: (_, record) => <span>{record.id}</span>,
    },
    {
        title: 'Addresses',
        dataIndex: 'customer_addresses',
        width: 150,
        ellipsis: {
            showTitle: false,
        },
        render: (name) => (
            <Tooltip placement='topLeft' title={name}>
                {name}
            </Tooltip>
        ),
    },
]
