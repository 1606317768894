import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { generateTk, shopifyAppNotification, verifyTk } from '@pages/onboarding/kwikpass-onboarding/steps/helper'
import { getUserData } from '@store/user/selectors'
import { Alert, Radio, Form, message, Button, Input, Modal } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export default function CustomPlan({ plan, modalDisplay, onClose, handleCustomSubmit, merchant_details }) {
    const userDetails = useSelector(getUserData)
    const [form] = Form.useForm()
    const [onboardingConfirmation, setOnboardingConfirmation] = useState('')
    const [verificationCode, setVerificationCode] = useState('')
    const [modalStatus, setModalStatus] = useState(modalDisplay)
    const [error, setError] = useState(false)

    useEffect(() => {
        setModalStatus(modalDisplay)
        if (!modalDisplay) {
            setOnboardingConfirmation('')
            setVerificationCode('')
        }
    }, [modalDisplay])
    const generateKpTk = async () => {
        try {
            const res = await generateTk(merchant_details?.m_id)
            if (res?.isSuccess) {
                setError(false)
                message.success(res?.data?.message)
            } else {
                setError(true)
                message.error(res?.error || res?.message)
                setOnboardingConfirmation('')
                setVerificationCode('')
            }
        } catch (error) {
            setError(true)
            setOnboardingConfirmation('')
            setVerificationCode('')
            message.error(error.message)
        }
    }
    useEffect(() => {
        onboardingConfirmation === 'yes' && generateKpTk()
    }, [onboardingConfirmation])

    const handleSubmit = async () => {
        try {
            const res = await verifyTk(merchant_details?.m_id, verificationCode)
            if (res?.isSuccess && res?.data?.isValid) {
                logEvent(
                    'kp_custom_plan_code_verified',
                    'click',
                    'pricing_page',
                    userDetails?.userDetails?.email,
                    merchant_details?.m_id,
                    merchant_details?.short_name,
                )
                handleCustomSubmit(plan)
            } else {
                message.error('Invalid verification code, please try again.')
            }
        } catch (error) {
            console.error('Error creating subscription:', error)
        }
    }

    return (
        <>
            <Modal
                open={modalStatus}
                destroyOnClose
                title='Upgrade to Custom Plan'
                centered
                onCancel={onClose}
                footer={null}
                width={400}
            >
                {!onboardingConfirmation && (
                    <>
                        <p className='mb-2'>The Kwikpass team has completed my onboarding?</p>
                        <Radio.Group
                            onChange={(e) => {
                                setOnboardingConfirmation(e.target.value)
                                e.target.value === 'no' && shopifyAppNotification(merchant_details?.m_id)
                            }}
                            options={[
                                {
                                    value: 'yes',
                                    label: 'Yes',
                                },
                                {
                                    value: 'no',
                                    label: 'No',
                                },
                            ]}
                        />
                    </>
                )}
                {onboardingConfirmation === 'yes' && !error && (
                    <>
                        <Form form={form} name='verification-form' onFinish={handleSubmit} layout='vertical'>
                            <Form.Item
                                label='Enter access code shared by Kwikpass'
                                name='verificationCode'
                                rules={[{ required: true, message: 'Please input the verification code!' }]}
                            >
                                <Input
                                    defaultValue={verificationCode}
                                    value={verificationCode}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button className='w-full bg-[#004b8c] text-white' htmlType='submit' block>
                                    Verify
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                )}
                {onboardingConfirmation === 'no' && (
                    <Alert
                        message='Our customer support team will be in touch with in 48 hours! Keep an eye on your email.'
                        type='info'
                    />
                )}
            </Modal>
        </>
    )
}
