import { Button, CloudUploadOutlined, Col, Drawer, Input, message, Row, Select } from 'gokwik-ui-kit'
import { MethodsType, SelectDropdown, TerminalInterface } from '@library/utilities/interface'
import { useEffect, useState } from 'react'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { makeAPICall } from '@gokwik/utilities'
import { handleError } from '@library/utilities/helpers/handleError'

interface Props {
    method: MethodsType
    open: boolean
    onCancel?: (e) => void
    onSuccess?: () => void
    instruments: SelectDropdown[]
    mId: string
}

const initialTerminalState = [
    {
        provider: null,
        split: null,
        type: 'primary',
    },
    {
        provider: null,
        split: null,
        type: 'backup',
    },
]
export default function ({ method, open, onCancel, instruments, mId, onSuccess }: Props) {
    const [instrument, setInstrument] = useState<string>(null)
    const [providers, setProviders] = useState<SelectDropdown[]>([])
    const [terminal, setTerminals] = useState<TerminalInterface[]>(initialTerminalState)

    useEffect(() => {
        if (method?.method && instrument) {
            getSupportedProviders(method.method, instrument)
        }
    }, [instrument, method?.method])

    async function getSupportedProviders(methodName: string, instrumentName: string) {
        try {
            let response = await makeAPICall({
                method: 'get',
                url:
                    process.env.REACT_APP_AUX_BASE_URL +
                    APIEndPoints.routing.supportedProviders(mId, methodName, instrumentName),
            })
            if (response.data?.data?.length > 0) {
                const activeProviders = response.data.data
                const options = activeProviders.map(({ name, provider }) => ({
                    label: name,
                    value: provider,
                }))
                setProviders(options)
            } else {
                const errMessage =
                    response?.response?.data?.error?.reference?.message ??
                    response?.response?.data?.error?.message ??
                    'Failed to fetch providers'
                message.error(errMessage, 3)
            }
        } catch (error) {
            handleError(error)
        }
    }

    const handleTerminalChange = (index: number, field: 'provider' | 'split', value: string, type: string) => {
        setTerminals((prevTerminals) => {
            const updatedTerminals = prevTerminals.map((terminal) => {
                if (terminal?.type === type) {
                    return { ...terminal, [field]: value }
                }
                return { ...terminal }
            })
            return updatedTerminals
        })
    }

    async function handleAddRouting() {
        if (!mId) {
            message.error('Merchant is required')
            return
        }

        // Validate the details before creating the payload
        if (!method?.method || !instrument || !terminal[0].provider || !terminal[1].provider || !terminal[1].split) {
            message.error('Please fill in all required fields')
            return
        }

        // Create a new object to store the updated terminals
        const updatedTerminals = [...terminal]
        // Ensure the split percentages are valid
        const primaryTerminal = { ...updatedTerminals[0] }
        const backupTerminal = { ...updatedTerminals[1] }

        delete primaryTerminal.type
        delete backupTerminal.type

        if (backupTerminal.provider == '0' || !backupTerminal.provider) {
            primaryTerminal.split = 100 / 100
            backupTerminal.split = 0
        } else {
            // If both providers are filled, calculate primary split as 100 - backup split
            const backupSplit = parseInt(backupTerminal.split as string) || 0
            if (backupSplit < 0 || backupSplit > 100) {
                message.error('Backup percent value must be between 0 and 100')
                return
            }
            primaryTerminal.split = (100 - backupSplit) / 100
            backupTerminal.split = backupSplit / 100
        }

        const payload = {
            method: method.method,
            instrument: instrument,
            terminals: [primaryTerminal, backupTerminal],
        }
        try {
            let response = await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_AUX_BASE_URL + APIEndPoints.routing.createRoute(mId),
                payload,
            })
            if (response.data?.success) {
                message.success('Routes updated successfully')
                onSuccess?.()
            } else {
                message.error(response?.response?.data?.error?.message ?? 'Failed to update routes')
            }
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <>
            <Drawer title={`Add Routing For ${method?.name}`} open={open} onClose={() => onCancel(false)} width={300}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Select
                            className='w-full'
                            label='Instrument'
                            placeholder='Select instrument'
                            onChange={(value) => setInstrument(value)}
                            options={instruments}
                            value={instrument}
                        />
                    </Col>
                    <Col span={24}>
                        <Select
                            className='w-full'
                            label='Primary Provider'
                            placeholder='Select provider'
                            onChange={(value) => handleTerminalChange(0, 'provider', value, 'primary')}
                            options={providers}
                            value={terminal?.[0].provider}
                        />
                    </Col>
                    <Col span={24}>
                        <Select
                            className='w-full'
                            label='Backup Provider'
                            placeholder='Select provider'
                            onChange={(value) => handleTerminalChange(1, 'provider', value, 'backup')}
                            options={providers}
                            value={terminal?.[1].provider}
                        />
                    </Col>
                    <Col span={24}>
                        <Input
                            label='Backup Share %'
                            placeholder='Enter split percent'
                            value={terminal?.[1].split as string}
                            onChange={(e) => handleTerminalChange(1, 'split', e.target.value, 'backup')}
                        />
                    </Col>
                    <Col span={24}>
                        <Button
                            variant='primary'
                            className='flex items-center justify-center w-full mt-4'
                            onClick={handleAddRouting}
                        >
                            <CloudUploadOutlined style={{ fontSize: 16 }} />
                            Save
                        </Button>
                    </Col>
                </Row>
            </Drawer>
        </>
    )
}
