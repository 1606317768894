import { Layout } from "gokwik-ui-kit";
import ConfigSteps from "./ConfigSteps";
import CodStepsWrapper from "./CodStepsWrapper";
import { useEffect, useState } from "react";
import { getMerchantDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux';
import EnableGokwikCheckout from "./EnableGokwikCheckout";
import { ProgressStepper } from "../CommonComponents/ProgressStepper";
import { navigateToUrl } from "single-spa";
import { newStepConfiguration } from "@pages/onboarding/stepsConfig";
import { fetchUserDetails } from "@store/user";
import { useAppDispatch } from '@library/utilities/hooks'

const { Content } = Layout;

const CodModuleWrapper = () => {
  const dispatch = useAppDispatch();
  const params = new URLSearchParams(window.location.search)
  const addCodShippingRates = params.get('cod-shipping-rate') === 'true';
  if (addCodShippingRates) {
    dispatch(fetchUserDetails());
    navigateToUrl('/onboarding/cod-setup');
  }

  const merchantDetails = useSelector(getMerchantDetails)
  const currentStatus = merchantDetails?.onboarding_status?.kwik_checkout_app;
  const codModuleStatus = merchantDetails?.onboarding_status?.cod_module_status

  let currentConfigStep: number = 0;
  if (['contact_sales_clicked', 'onboarding_initiated', 'password_setup','feature_tour_done'].includes(currentStatus) || codModuleStatus === '') {
    currentConfigStep = 1;
  } else if (['smart_cod_app_installed','smart_cod_app_activated'].includes(currentStatus) || codModuleStatus === 'smart_cod_app_activated') {
    currentConfigStep = 2;
  } else if (currentStatus === 'gokwik_shipping_setup_done') {
    currentConfigStep = 3;
  } else if (['smart_cod_app_disabled'].includes(currentStatus)) {
    currentConfigStep = 4;
  }
    
  const [currentStep, setCurrentStep] = useState(currentConfigStep);

  useEffect(() => {
    let kwikCheckoutAppStatus = merchantDetails?.onboarding_status?.kwik_checkout_app
    if (kwikCheckoutAppStatus && (newStepConfiguration[kwikCheckoutAppStatus]?.id >=
        newStepConfiguration['imp_checks_passed']?.id)) {
        if (merchantDetails?.country && merchantDetails?.country?.toLowerCase() === 'india') {
          navigateToUrl('/onboarding/checkout-setup');
        } else {
          navigateToUrl('/shopify-app/settings')
        }
    } else if (!kwikCheckoutAppStatus) {
      navigateToUrl('/shopify-app/settings');
    }
  }, [merchantDetails?.onboarding_status?.kwik_checkout_app])

  return (
    <Layout className='pt-16'>
        <ProgressStepper />
        <Content >
            {currentStep <= 3 ? (
              <>
                <Layout style={{ backgroundColor: "#f0f2f5", margin: '0 2rem' }}>
                  <div style={{ fontSize: "1.5rem", fontWeight: "bold", lineHeight: '2rem', marginTop: '1.25rem' }} >Activate GoKwik COD</div>
                    <Content style={{ paddingTop: '0.75rem' }}>
                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <ConfigSteps currentStep={currentStep}/>
                            <CodStepsWrapper currentStep={currentStep} setCurrentStep={setCurrentStep} />
                        </div>
                    </Content>
                </Layout>
              </>
            ) : (
                <EnableGokwikCheckout />
            )}
        </Content>
    </Layout>
  );
};

export default CodModuleWrapper;
