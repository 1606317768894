import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useInterval, useAppDispatch } from '@library/utilities/hooks'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import Plans from '@pages/onboarding/pricing-details/plans'
import { fetchUserDetails } from '@store/user'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { Alert, message } from 'gokwik-ui-kit'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

export const PricingPlan = ({ navigateToStep }) => {
    const [showAlert, setShowAlert] = useState(false)
    const merchant_details = useSelector(getMerchantDetails)
    const user_details = useSelector(getUserDetails)
    const [interval, initiateInterval, removeInterval] = useInterval(() => fetchShopifyApprovalStatusOfMerchant(), 2000)

    const intervalHandling = useCallback(
        (result) => {
            const status_code = result?.data?.status_code
            const current_onboarding_status = result?.data?.data?.current_onboarding_status
            // dispatch(fetchUserDetails())
            if (status_code === 200 && current_onboarding_status === 'pricing_details_confirmed') {
                removeInterval()
                // message.success('Pricing Approved')
                navigateToStep(null, 1)
            } else if (current_onboarding_status === 'pricing_approval_pending') {
                initiateInterval()
            } else {
                removeInterval()
            }
        },
        [merchant_details?.onboarding_status?.kwik_checkout_app],
    )

    useEffect(() => {
        if (merchant_details?.onboarding_status?.kwikpass_app === 'pricing_approval_pending') {
            fetchShopifyApprovalStatusOfMerchant()
        }
    }, [])

    const fetchShopifyApprovalStatusOfMerchant = async () => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url:
                    process.env.REACT_APP_BASE_URL +
                    APIEndPoints.getShopifyApprovalStatusForMerchant +
                    merchant_details?.m_id,
                params: { app_name: 'kwikpass_app' },
                skipLoader: true,
            })

            intervalHandling(response)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (
            merchant_details?.onboarding_status?.kwikpass_app === 'pricing_approval_pending' ||
            merchant_details?.onboarding_status?.kwikpass_app === 'pricing_approval_failed'
        )
            setShowAlert(true)
    }, [merchant_details?.onboarding_status?.kwikpass_app])
    return (
        <div className='mt-6 mx-12'>
            {merchant_details?.onboarding_status?.kwikpass_app === 'pricing_approval_pending' && showAlert && (
                <div className='flex justify-end'>
                    <Alert type='warning' message={'Pricing Approval Pending'} />
                </div>
            )}
            {merchant_details?.onboarding_status?.kwikpass_app === 'pricing_approval_failed' && showAlert && (
                <div className='flex justify-end'>
                    <Alert
                        type='error'
                        message={'Pricing approval reject. Please select plan and approve to move ahead.'}
                    />
                </div>
            )}
            <Plans setShowAlert={setShowAlert} navigateToStep={navigateToStep} />
        </div>
    )
}
