import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { ArrowDownOutlined, ArrowUpOutlined, Card, EllipsisOutlined, InfoCircleFilled, Skeleton } from 'gokwik-ui-kit'
import { useEffect, useMemo, useState } from 'react'
import Chart from 'react-apexcharts'
import dayjs from 'dayjs'

const RTOAnalytics = ({ timeFrame }) => {
    const [data, setData] = useState({
        awb_fillRate: 5.09,
        awb_tillDate: '2025-01-21',
        metric1: {
            end_date: '2025-01-07',
            rtoPercentageRange: '29.23',
            start_date: '2024-12-31',
            total_orders: 22466,
            total_rto_orders: 399,
        },
        metric2: {
            end_date: '2025-01-14',
            rtoPercentageRange: '29.00',
            start_date: '2025-01-07',
            total_orders: 21989,
            total_rto_orders: 1,
        },
        metric3: {
            end_date: '2025-01-21',
            rtoPercentageRange: '24.00',
            start_date: '2025-01-14',
            total_orders: 4188,
            total_rto_orders: 0,
        },
    })

    const [loading, setLoading] = useState(false)

    const getRTOData = async () => {
        try {
            setLoading(true)
            const response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints?.executiveSummary?.RTOAnalytics,
                params: { type: timeFrame },
                skipLoader: true,
            })

            setLoading(false)
            if (response?.status === 200) {
                setData(response?.data?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getRTOData()
    }, [timeFrame])

    const options = {
        chart: {
            type: 'bar',
            stacked: false, // Not stacked since Total Orders and RTO Orders are independent
            toolbar: false,
            zoom: {
                enabled: false,
            },
        },

        stroke: {
            width: [0, 0, 1], // Ensure the line has a visible stroke width
        },
        xaxis: {
            categories: [
                `${dayjs(data.metric1.start_date).format("D MMM'YY")} - ${dayjs(data.metric1.end_date).format(
                    "D MMM'YY",
                )}`,
                `${dayjs(data.metric2.start_date).format("D MMM'YY")} - ${dayjs(data.metric2.end_date).format(
                    "D MMM'YY",
                )}`,
                `${dayjs(data.metric3.start_date).format("D MMM'YY")} - ${dayjs(data.metric3.end_date).format(
                    "D MMM'YY",
                )}`,
            ],
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                if (opts.seriesIndex === 2) {
                    return ``
                }
                return `${val > 999 ? (val / 1000).toFixed(2) + 'K' : val}`
            },
        },
        legend: {
            position: 'bottom',
        },
        colors: ['#2DA8FF', '#006ED2', '#0A2D59'], // Total Orders, RTO Orders, RTO %
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
            },
        },

        markers: {
            size: 0,
            hover: {
                size: 6,
            },
        },
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            followCursor: true,
            y: {
                formatter: function (val) {
                    return val // Show the full number in the tooltip
                },
            },
        },

        yaxis: [
            {
                title: {
                    text: '',
                },
                labels: {
                    formatter: (val) => {
                        if (val < 999) return val
                        if (val >= 1e9) return `${(val / 1e9).toFixed(1)}B`
                        if (val >= 1e6) return `${(val / 1e6).toFixed(1)}M`
                        if (val >= 1e3) return `${(val / 1e3).toFixed(1)}K`
                        return val.toString()
                    },
                },
                tickAmount: 6, // Adjust this to control the number of ticks
                min: 0,
                max: (() => {
                    const maxValue = Math.max(
                        data.metric1.total_orders,
                        data.metric2.total_orders,
                        data.metric3.total_orders,
                        data.metric1.total_rto_orders,
                        data.metric2.total_rto_orders,
                        data.metric3.total_rto_orders,
                    )
                    const magnitude = Math.pow(10, Math.floor(Math.log10(maxValue)))
                    return Math.ceil(maxValue / magnitude) * magnitude
                })(), // Calculate max and round up to the nearest significant figure
            },
            {
                show: false, // Hide the RTO Orders y-axis
            },

            {
                opposite: true,
                show: false,
                title: {
                    text: 'RTO %',
                },
                min: 0,
                max: 100,
                labels: {
                    formatter: (val) => `${val.toFixed(2)}%`,
                },
            },
        ],
    }

    const series = [
        {
            name: 'Total Orders',
            type: 'bar',
            data: [data.metric1?.total_orders, data.metric2?.total_orders, data.metric3?.total_orders], // Use fetched data
        },
        {
            name: 'RTO Orders',
            type: 'bar',
            data: [data.metric1?.total_rto_orders, data.metric2?.total_rto_orders, data.metric3?.total_rto_orders], // Use fetched data
        },
        {
            name: 'RTO %',
            type: 'line',
            data: [
                parseFloat(data.metric1?.rtoPercentageRange),
                parseFloat(data.metric2?.rtoPercentageRange),
                parseFloat(data.metric3?.rtoPercentageRange),
            ],
        },
    ]

    function percentageChange(a, b) {
        if (a === 0.0 || b === 0.0) {
            return 0.0
        }
        return Math.round(((a - b) / b) * 100.0 * 100) / 100
    }

    const getTopData = useMemo(() => {
        return {
            lastRTOPc: data?.metric3?.rtoPercentageRange,
            percentChange: percentageChange(
                Number(data?.metric3?.rtoPercentageRange),
                Number(data?.metric2?.rtoPercentageRange),
            ),
        }
    }, [data])

    return (
        <div>
            <Card title={<>RTO Performance</>} extra={<EllipsisOutlined className='rotate-90' />}>
                {loading ? (
                    <Skeleton loading={loading} active avatar />
                ) : (
                    <div>
                        <div>
                            <span className='text-2xl font-semibold'>
                                {Number(getTopData?.lastRTOPc) === 0
                                    ? Number(getTopData?.lastRTOPc).toFixed(0)
                                    : getTopData?.lastRTOPc}
                                %
                            </span>
                            {'   '}
                            {getTopData?.percentChange > 0 ? (
                                <span className='text-red-600 text-base font-semibold'>
                                    {getTopData?.percentChange.toFixed(2)}% <ArrowUpOutlined />
                                </span>
                            ) : getTopData?.percentChange < 0 ? (
                                <span className='text-green-600 text-base font-semibold'>
                                    {getTopData?.percentChange}% <ArrowDownOutlined />
                                </span>
                            ) : (
                                <>{getTopData?.percentChange.toFixed(0)}%</>
                            )}
                        </div>
                        <div>
                            <Chart
                                //@ts-ignore
                                options={options}
                                series={series}
                                type='bar'
                                height={300}
                            />
                        </div>

                        <div className='text-[#98A2B3] text-xs float-end'>Last 3 {timeFrame}s</div>
                        <div className='text-[#98A2B3] text-xs'>
                            AWB fill rate is {data?.awb_fillRate}%, till {data?.awb_tillDate}, and RTO data is prior to
                            this date.
                        </div>
                    </div>
                )}
            </Card>
        </div>
    )
}

export default RTOAnalytics
