import { Col, Row, Form, Input, Button, message, Alert, InputNumber, Select, Radio, Space } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'

const AddShippingRateForm = (props: {
    handleCloseModal
    configJSON
    action
    editShippingMethod
    addShippingMethod
    isOnBoardingFlow: boolean
}) => {
    const { handleCloseModal, configJSON, action, editShippingMethod, addShippingMethod } = props;
    const [addConditionalPricing, setAddConditionalPricing] = useState(false)
    const [selectedCondition, setSelectedCondition] = useState('order_price')
    const [form] = Form.useForm()

    const initialValues = () => {
        const shippingConfig = configJSON?.shippingConfig?.find(item => item?.id === action.id)
        if (action.name === 'edit') {
            return {
                shipping_name: shippingConfig?.shipping_name?.trim(),
                shipping_charge: Number(shippingConfig?.shipping_charge),
                paymentOption: shippingConfig?.paymentOption,
                minOrderPrice: shippingConfig?.minOrderPrice,
                maxOrderPrice: shippingConfig?.maxOrderPrice,
                minWeight: shippingConfig?.minWeight,
                maxWeight: shippingConfig?.maxWeight,
            }
        }
    }

    const onFinish = async (values) => {
        for (const key in values) {
            if (typeof values[key] === 'string') {
                values[key] = values[key].trim()
            }
        }

        if (!addConditionalPricing) {
            values.minOrderPrice = undefined
            values.maxOrderPrice = undefined
            values.minWeight = undefined
            values.maxWeight = undefined
        } else {
            //for weight based shipping condition remove price based shipping condition values and vice versa
            if (selectedCondition === 'weight') {
                values.minWeight = values?.minWeight ?? 0
                values.maxWeight = values?.maxWeight ?? undefined
                values.minOrderPrice = undefined
                values.maxOrderPrice = undefined
            } else {
                values.minOrderPrice = values?.minOrderPrice ?? 0
                values.maxOrderPrice = values?.maxOrderPrice ?? undefined
                values.minWeight = undefined
                values.maxWeight = undefined
            }
        }

        if (action?.name === 'edit') {
            editShippingMethod(values)
        } else {
            addShippingMethod(values)
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === '-' || e.key === '+' || e.key === 'e') {
            e.preventDefault()
        }
    }

    const handleRadioButtonChange = (e) => {
        if (e?.target?.value === 'weight') {
            setSelectedCondition('weight')
        } else {
            setSelectedCondition('order_price')
        }
    }

    useEffect(() => {
        if (action.name === 'edit' || action.name === 'view') {
            const shippingConfig = configJSON?.shippingConfig?.find((item) => item.id === action.id)
            setAddConditionalPricing(
                shippingConfig?.minOrderPrice ||
                    shippingConfig?.maxOrderPrice ||
                    shippingConfig?.minWeight ||
                    shippingConfig?.maxWeight,
            )
            if (shippingConfig?.minWeight || shippingConfig?.maxWeight) {
                setSelectedCondition('weight')
            } else {
                setSelectedCondition('order_price')
            }
        }
    }, [])

    return (
        <Form form={form} onFinish={onFinish} layout='vertical' initialValues={initialValues()}>
            <Row gutter={12} justify={'space-between'}>
                <Col span={24}>
                    <Form.Item
                        label={
                            <div className='flex flex-col gap-2 w-full'>
                                <span>Shipping Name</span>
                                <span className='flex w-full'>
                                    <Alert
                                        message={
                                            'Please ensure the shipping method is created on Shopify first and named exactly the same here'
                                        }
                                        showIcon
                                        type={'warning'}
                                        className='mb-2'
                                    />
                                </span>
                            </div>
                        }
                        name='shipping_name'
                        rules={[
                            {
                                validator(_, value) {
                                    if (value?.trim()?.length > 0) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(new Error('Shipping name is required and cannot be empty'))
                                },
                            },
                        ]}
                    >
                        <Input placeholder='Enter shipping name here' disabled={action?.name === 'view'} />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item
                        label='Shipping Price'
                        name='shipping_charge'
                        rules={[{ required: true, message: 'Please enter the shipping price' }]}
                    >
                        <InputNumber
                            className='w-full'
                            type='number'
                            min={0}
                            placeholder='Enter price here'
                            disabled={action?.name === 'view'}
                            onKeyDown={handleKeyDown}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} className='p-0 -mt-2'>
                    {action?.name !== 'view' && (
                        <Button
                            type='link'
                            onClick={() => setAddConditionalPricing((prev) => !prev)}
                            className='p-0 -mt-4 mb-2 items-left border-none focus:outline-none'
                        >
                            <span className='hover:underline'>
                                {addConditionalPricing ? 'Remove' : 'Add'} conditional pricing
                            </span>
                        </Button>
                    )}
                </Col>

                {addConditionalPricing && (
                    <>
                        <Col span={24}>
                            <Radio.Group
                                className='mb-4'
                                onChange={handleRadioButtonChange}
                                defaultValue={'order_price'}
                                value={selectedCondition}
                                disabled={action?.name === 'view'}
                            >
                                <Space direction='vertical'>
                                    <Radio value={'weight'}>Based on item weight </Radio>
                                    <Radio value={'order_price'}>Based on order price </Radio>
                                </Space>
                            </Radio.Group>
                        </Col>
                        {selectedCondition === 'order_price' ? (
                            <>
                                <Col span={12}>
                                    <Form.Item label='Minimum Order Price' name='minOrderPrice'>
                                        <InputNumber
                                            className='w-full'
                                            type='number'
                                            prefix={'₹'}
                                            disabled={action?.name === 'view'}
                                            min={0}
                                            defaultValue={0}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label='Maximum Order Price'
                                        name='maxOrderPrice'
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(_, maxOrderPrice) {
                                                    const minOrderPrice = form.getFieldValue('minOrderPrice')
                                                    if (
                                                        !minOrderPrice ||
                                                        typeof maxOrderPrice !== 'number' ||
                                                        maxOrderPrice >= minOrderPrice
                                                    ) {
                                                        return Promise.resolve()
                                                    }
                                                    return Promise.reject(
                                                        new Error(
                                                            'Maximum order price cannot be less than minimum order price',
                                                        ),
                                                    )
                                                },
                                            }),
                                        ]}
                                    >
                                        <InputNumber
                                            className='w-full'
                                            type='number'
                                            prefix={'₹'}
                                            min={1}
                                            placeholder='No Limit'
                                            disabled={action?.name === 'view'}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        ) : (
                            <>
                                <Col span={12}>
                                    <Form.Item label='Minimum Weight' name='minWeight'>
                                        <InputNumber
                                            className='w-full'
                                            type='number'
                                            suffix={'kg'}
                                            disabled={action?.name === 'view'}
                                            min={0}
                                            defaultValue={0}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label='Maximum Weight'
                                        name='maxWeight'
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                validator(_, maxWeight) {
                                                    const minWeight = form.getFieldValue('minWeight')

                                                    if (
                                                        !minWeight ||
                                                        typeof maxWeight !== 'number' ||
                                                        maxWeight >= minWeight
                                                    ) {
                                                        return Promise.resolve()
                                                    }
                                                    return Promise.reject(
                                                        new Error('Maximum weight cannot be less than minimum weight'),
                                                    )
                                                },
                                            }),
                                        ]}
                                    >
                                        <InputNumber
                                            className='w-full'
                                            type='number'
                                            suffix={'kg'}
                                            min={0.001}
                                            placeholder='No Limit'
                                            disabled={action?.name === 'view'}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                    </>
                )}

                <Col span={24}>
                    <Form.Item
                        label='Choose payment method'
                        name='paymentOption'
                        rules={[{ required: true, message: 'Please choose a payment method' }]}
                    >
                        <Select disabled={action?.name === 'view'}>
                            <Select.Option value='all'>All</Select.Option>
                            <Select.Option value='cod'>COD</Select.Option>
                            <Select.Option value='prepaid'>Prepaid</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <>
                    <Col span={12}>
                        <Form.Item className='mb-0'>
                            <Button className='w-full' variant='default' onClick={() => handleCloseModal()}>
                                Cancel
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item className='mb-0'>
                            <Button className='w-full' variant='primary' htmlType='submit'>
                                Add COD Fee
                            </Button>
                        </Form.Item>
                    </Col>
                </>
            </Row>
        </Form>
    )
}

export default AddShippingRateForm
