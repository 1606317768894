import { filterDateFormatter, makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { Card, Col, DatePicker, Row, Skeleton } from 'gokwik-ui-kit'
import BarChart from './BarChart'
import PlatformWiseChart from './PlatformWiseChart'
import ProductWiseChart from './ProductWiseChart'
import TotalTopStats from './TotalTopStats'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'

const today = dayjs()
const formattedTodayDate = filterDateFormatter(today)
const last30Days = today.subtract(30, 'day')
const formattedLast30DaysDate = filterDateFormatter(last30Days)

export default function Dashboard() {
    const [stats, setStats] = useState<any>({})
    const [loading, setLoading] = useState(true)
    const user_details = useSelector(getUserDetails)
    const merchant_details = useSelector(getMerchantDetails)
    const [parameters, setParameters] = useState<any>({
        start_dt: formattedLast30DaysDate,
        end_dt: formattedTodayDate,
    })

    const today = dayjs()
    const yesterday = today.subtract(1, 'day')
    const presets: {
        label: any
        value: any
    }[] = [
        { label: 'Today', value: [today, today] },
        { label: 'Yesterday', value: [yesterday, yesterday] },
        {
            label: 'Last 7 days',
            value: [today.subtract(6, 'days'), today],
        },
        {
            label: 'Last 30 days',
            value: [today.subtract(29, 'days'), today],
        },
        {
            label: 'Last 45 days',
            value: [today.subtract(46, 'days'), today],
        },
    ]

    useEffect(() => {
        getStats()
    }, [parameters])

    useEffect(() => {
        logEvent('dashboard_clicked', 'pageLoad', 'Dashboard', user_details?.email, merchant_details?.m_id, merchant_details?.short_name, user_details?.name)
    }, [])

    useEffect(() => {
        if (stats?.customer || stats?.merchant || stats?.order) {
            setLoading(false)
        }
    }, [stats])

    const getStats = async () => {
        setLoading(true)
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints?.admin_dashboard_stats,
            params: {
                start_dt: parameters?.start_dt,
                end_dt: parameters?.end_dt,
            },
            skipLoader: true,
        })
        if (response?.status === 200) {
            setStats(response?.data?.data)
        }
        setLoading(false)
    }

    const getTopTotalStats = useMemo(
        () => ({
            customer: stats?.customer,
            order: stats?.order,
            merchant: stats?.merchant,
        }),
        [stats?.customer, stats?.order, stats?.merchant],
    )

    const getPlatformWiseCount = useMemo(
        () => stats?.platform_wise_merchants_count,
        [stats?.platform_wise_merchants_count],
    )

    const getProductWiseCount = useMemo(
        () => stats?.product_wise_merchants_count,
        [stats?.product_wise_merchants_count],
    )

    return (
        <div className='w-full'>
            <Row gutter={24}>
                <TotalTopStats topTotalStats={getTopTotalStats} />
            </Row>
            <Row gutter={24} className='mt-5'>
                <Col span={12}>
                    <PlatformWiseChart platformWiseCount={getPlatformWiseCount} />
                </Col>
                <Col span={12}>
                    <ProductWiseChart productWiseCount={getProductWiseCount} />
                </Col>
            </Row>
            <Row className='mt-5'>
                <div className='flex rounded-lg w-full'>
                    <Col span={24}>
                        <Card
                            title='Signup to orders journey'
                            className='flex flex-col gap-2 p-2 bg-white rounded-2xl shadow-lg'
                        >
                            <div className='flex items-center justify-end w-full'>
                                <DatePicker.RangePicker
                                    className=' w-[20%]'
                                    size='middle'
                                    value={[
                                        dayjs(parameters?.start_dt?.split(',')[0]),
                                        dayjs(parameters?.end_dt?.split(',')[1]),
                                    ]}
                                    onChange={([start, end]) => {
                                        setParameters({
                                            start_dt: filterDateFormatter(start),
                                            end_dt: filterDateFormatter(end),
                                        })
                                    }}
                                    format={'MMM DD, YY'}
                                    allowClear={false}
                                    presets={presets}
                                />
                            </div>
                            {loading ? <Skeleton loading={loading} active avatar /> : <BarChart data={stats} />}
                        </Card>
                    </Col>
                </div>
            </Row>
        </div>
    )
}
