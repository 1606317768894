import { useState } from "react";
import { Modal } from "gokwik-ui-kit";

const TutorialVideoModal = ({ isModalOpen, handleCloseModal, videoUrl }) => {
    const closeModalAndStopVideo = () => {
        const videoElement = document.getElementById("tutorial-video") as HTMLVideoElement;;
        if (videoElement) {
            videoElement.pause();
            videoElement.currentTime = 0;
        }
        handleCloseModal();
    };

    return (
        <Modal
            title="Tutorial Video"
            open={isModalOpen}
            footer={null}
            onCancel={closeModalAndStopVideo}
            centered
            width={900}
        >
            <div style={{ position: "relative", paddingBottom: "57%", height: 0 }}>
                <video
                    id="tutorial-video"
                    src={videoUrl}
                    controls
                    className="absolute top-0 left-0 w-full h-full border-none"
                ></video>
            </div>
        </Modal>
    );
};

export default TutorialVideoModal;
