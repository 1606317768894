// Import Dependencies
import { navigateToUrl } from 'single-spa'
import React, { useState, useEffect } from 'react'
import welcomeBackImage from '@library/images/common/welcome-back.svg'
import LoginLayout from '@library/components/loginLayout'

export default function () {
    return (
        <LoginLayout showFooter={true}>
            <div className='flex flex-col w-min items-center px-3 py-32'>
                <img src={welcomeBackImage} className='' />
                <p className='font-semibold text-base my-2'>Welcome Back to GoKwik</p>
                <p className='text-gray-500 text-center w-[550px] leading-6'>
                    It seems we've been part of your journey before. We're excited to welcome you back! As a returning
                    partner, we're offering you a bespoke onboarding process for the Kwik Checkout app, tailored to the
                    unique needs of your store.
                </p>
                <p className='text-gray-700 text-center w-[500px] leading-6 my-2'>
                    For a smooth transition and dedicated support, please get in touch with us at{' '}
                    <a href='mailto:mybusiness@gokwik.co'>mybusiness@gokwik.co</a>. Let's continue to grow together
                </p>
            </div>
        </LoginLayout>
    )
}
