import React from 'react'

export default function AdjustIcon() {
    return (
        <svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect x='0.5' y='0.5' width='43' height='43' rx='15.5' fill='white' fillOpacity='0.24' />
            <rect x='0.5' y='0.5' width='43' height='43' rx='15.5' stroke='url(#paint0_linear_6250_24500)' />
            <g clipPath='url(#clip0_6250_24500)'>
                <path
                    d='M14 20H30'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M14 24H30'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M19 28L22 31L25 28'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
                <path
                    d='M19 16L22 13L25 16'
                    stroke='#004B8D'
                    stroke-width='1.6'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
            </g>
            <defs>
                <linearGradient
                    id='paint0_linear_6250_24500'
                    x1='0.848501'
                    y1='2.2083'
                    x2='41.5344'
                    y2='34.45'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stop-color='#91D0FF' stop-opacity='0.12' />
                    <stop offset='1' stop-color='#0094D5' stop-opacity='0.12' />
                </linearGradient>
                <clipPath id='clip0_6250_24500'>
                    <rect width='24' height='24' fill='white' transform='translate(10 10)' />
                </clipPath>
            </defs>
        </svg>
    )
}
