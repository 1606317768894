import { ArrowLeftOutlined, CheckCircleFilled, Popover, Row, Steps, StepsProps, message } from 'gokwik-ui-kit'
import { useCallback, useEffect, useState } from 'react'
import './onboarding.css'
import OnboardStep1 from './onboardStep1'
import OnboardStep2 from './onboardStep2'
import OnboardStep3 from './onboardStep3'
import OnboardStep4 from './onboardStep4'
import OnboardStep5 from './onboardStep5'
import { navigateToUrl } from 'single-spa'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useInterval } from '@library/utilities/hooks'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { fetchUserDetails } from '@store/user'
import { stepConfiguration } from './stepsConfig'
import OnboardStep6 from './onboardStep6'

export default function OnboardingSteps() {
    const subTitle = '30-60 Sec'
    const [currentStep, setCurrentStep] = useState(null)
    const [messageApi, contextHolder] = message.useMessage()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const merchant_details = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)
    const merchantName = getSubdomain(merchant_details?.website)

    const [interval, initiateInterval, removeInterval] = useInterval(
        () => fetchLatestOnboardingStatusWithPollingHandling(),
        5000,
    )
    const intervalHandling = useCallback(
        (result) => {
            const current_onboarding_status = result
            if (current_onboarding_status === 'smart_cod_app_installed') {
                setCurrentStep(1)
                removeInterval()
            } else if (current_onboarding_status === 'gokwik_shipping_setup_done') {
                setCurrentStep(3)
                removeInterval()
            } else {
                if (!interval) {
                    initiateInterval()
                }
            }
        },
        [merchant_details?.onboarding_status?.kwik_checkout_app],
    )
    const startPollingAndCheckStatus = () => {
        fetchLatestOnboardingStatusWithPollingHandling()
    }

    const fetchLatestOnboardingStatusWithPollingHandling = async () => {
        const res = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.userDetails,
            method: 'get',
            skipLoader: true,
        })
            .then((result) => {
                intervalHandling(result?.data?.data?.merchant_details?.onboarding_status?.kwik_checkout_app)
            })
            .catch((err) => {
                console.warn(err)
            })
    }

    useEffect(() => {
        if (
            stepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.id >=
            stepConfiguration['kyc_viewed']?.id
        ) {
            navigate('/checkout/settings')
        }
    }, [])

    function getSubdomain(url) {
        const match = url.match(/^[^.]+/)
        return match ? match[0] : null
    }

    function customDot(dot, { status, index, description, title, subTitle }) {
        return (
            <>
                <div className='absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center cursor-pointer'>
                    {/* <p className='mb-1 text-xs text-gray-400 w-20'>{title}</p> */}
                    <p className='mb-14 text-base text-gray-500 w-40 '>{description}</p>
                </div>
                {status === 'finish' ? <CheckCircleFilled className='text-green-500  ' /> : dot}
            </>
        )
    }

    const updateMerchantOnboardingStatus = async (status, skip) => {
        try {
            const response = await makeAPICall({
                method: 'post',
                url:
                    process.env.REACT_APP_BASE_URL +
                    APIEndPoints.updateMerchantOnboardingStatus +
                    merchant_details?.m_id,
                payload: {
                    current_onboarding_status: status,
                },
            })

            if (response?.data?.status_code === 200) {
                if (status === 'setup_completed') {
                    messageApi.open({
                        type: 'success',
                        content: 'All steps completed successfully',
                    })
                    navigateToUrl('/checkout/settings/pincode')
                } else if (status === 'one_time_login_activated') {
                    window.open(
                        merchant_details?.redirect_urls?.one_time_login_activation_url,
                        '_blank',
                        'rel=noopener noreferrer',
                    )
                }
            } else {
                messageApi.open({
                    type: 'error',
                    content: 'Something went wrong. Try Later !',
                })
            }
            dispatch(fetchUserDetails())
        } catch (error) {
            console.log(error)
        }
    }
    const CurrentStepsRender = [
        <OnboardStep1 website={merchantName} startPolling={startPollingAndCheckStatus} />,
        <OnboardStep2 website={merchantName} updateStatus={updateMerchantOnboardingStatus} />,
        <OnboardStep3
            website={merchantName}
            updateStatus={updateMerchantOnboardingStatus}
            step={currentStep}
            startPolling={startPollingAndCheckStatus}
        />,
        <OnboardStep4 website={merchantName} updateStatus={updateMerchantOnboardingStatus} />,
        // <OnboardStep5 website={merchantName} updateStatus={updateMerchantOnboardingStatus} />,
        // <OnboardStep6 updateStatus={updateMerchantOnboardingStatus} />,
    ]

    // useEffect(() => {
    //     let step = stepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.id
    //     if (step) {
    //         setCurrentStep(step)
    //     } else {
    //         setCurrentStep(0)
    //     }
    // }, [merchant_details?.onboarding_status?.kwik_checkout_app])

    const changeSteps = (stepUserWantToGoTo) => {
        if (stepUserWantToGoTo > stepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.stepNumber)
            return
        else setCurrentStep(stepUserWantToGoTo)
    }

    // useEffect(() => {
    //     fetchUserStatus()
    // }, [])
    // const fetchUserStatus = async () => {
    //     const res = await makeAPICall({
    //         url: process.env.REACT_APP_BASE_URL + APIEndPoints.userDetails,
    //         method: 'get',
    //     })
    //         .then((result) => {
    //             if (stepConfiguration[result.data.data.merchant_details.onboarding_status?.kwik_checkout_app].id !== currentStep) {
    //                 setCurrentStep(stepConfiguration[result.data.data.merchant_details.onboarding_status?.kwik_checkout_app].id)
    //             }
    //         })
    //         .catch((err) => {
    //             console.warn(err)
    //         })
    // }

    const getCurrentSteps = () => {
        const currentStepOfMerchant =
            stepConfiguration[merchant_details?.onboarding_status?.kwik_checkout_app]?.stepNumber

        if (currentStepOfMerchant < stepConfiguration['smart_cod_app_installed']?.stepNumber) {
            return 0
        } else if (
            currentStepOfMerchant < stepConfiguration['smart_cod_app_activated']?.stepNumber &&
            currentStepOfMerchant >= stepConfiguration['smart_cod_app_installed']?.stepNumber
        ) {
            return 1
        } else if (
            currentStepOfMerchant < stepConfiguration['gokwik_shipping_setup_done']?.stepNumber &&
            currentStepOfMerchant >= stepConfiguration['smart_cod_app_activated']?.stepNumber
        ) {
            return 2
        } else if (
            currentStepOfMerchant < stepConfiguration['smart_cod_app_disabled']?.stepNumber &&
            currentStepOfMerchant >= stepConfiguration['gokwik_shipping_setup_done']?.stepNumber
        ) {
            return 3
        } else if (
            currentStepOfMerchant < stepConfiguration['imp_checks_passed']?.stepNumber &&
            currentStepOfMerchant >= stepConfiguration['smart_cod_app_disabled']?.stepNumber
        ) {
            return 4
        } else if (
            currentStepOfMerchant >= stepConfiguration['imp_checks_passed']?.stepNumber &&
            currentStepOfMerchant < stepConfiguration['kyc_completed']?.stepNumber
        ) {
            return 5
        } else {
            return 6
        }
    }

    return (
        <div className='pt-20 px-3 bg-[#f1f7ff] w-full overflow-auto'>
            <Row className='gap-4 px-10 '>
                <div>
                    <p className='text-gray-900 font-inter text-2xl font-semibold leading-7'>Get Started</p>
                    <p className='text-gray-600 font-inter text-base font-normal leading-[22px]'>
                        You are just 4 simple steps away from experiencing a boost in your Checkout conversions!
                    </p>
                </div>
            </Row>
            <div className='mt-8 p-8'>
                <Steps
                    current={currentStep >= 0 && currentStep !== null ? currentStep : getCurrentSteps()}
                    className='pt-2 onboarding-steps'
                    progressDot={customDot}
                    onChange={changeSteps}
                    items={[
                        {
                            title: getCurrentSteps() === 0 ? 'Start' : 'Step 1',
                            subTitle,
                            description: 'App Installation ',
                        },

                        {
                            title: getCurrentSteps() === 1 ? 'In Progress' : 'Step 2',
                            subTitle: '1-2 Min',
                            description: ' App Activation',
                        },
                        {
                            title: getCurrentSteps() === 2 ? 'In Progress' : 'Step 3',
                            subTitle,
                            description: 'Setup Shipping',
                        },
                        {
                            title: getCurrentSteps() === 3 ? 'In Progress' : 'Step 4',
                            subTitle,
                            description: 'Disable COD',
                        },
                        // {
                        //     title: getCurrentSteps() === 4 ? 'In Progress' : 'Step 5',
                        //     subTitle,
                        //     description: 'Imp. Checks',
                        // },
                        // {
                        //     title: getCurrentSteps() === 5 ? 'In Progress' : 'Finish',
                        //     subTitle,
                        //     description: 'Get Checkout',
                        // },
                    ]}
                />
                <div className='py-8'>
                    {CurrentStepsRender[currentStep >= 0 && currentStep !== null ? currentStep : getCurrentSteps()]}
                </div>
            </div>
        </div>
    )
}
