import { UsergroupDeleteOutlined, ShoppingOutlined, ShopOutlined, Col, Skeleton } from 'gokwik-ui-kit'
import { memo } from 'react'
import Counter from './counter'

// Define the prop types
interface TopTotalStats {
    customer?: number
    order?: number
    merchant?: number
}

interface TotalTopStatsProps {
    topTotalStats: TopTotalStats
    loading?: boolean
}

const TotalTopStats: React.FC<TotalTopStatsProps> = (props) => {
    const { topTotalStats, loading = false } = props

    function formatNumber(num) {
        if (num >= 1e6) {
            return { number: (num / 1e6).toFixed(2), text: 'Million' }
        } else if (num >= 1e3) {
            return { number: (num / 1e3).toFixed(2), text: 'K' }
        } else {
            return { number: num, text: '' }
        }
    }

    const getTopStatsCard = () => {
        const total = [
            {
                data: topTotalStats?.customer,
                name: 'Customers',
                icon: <UsergroupDeleteOutlined className='text-[#efbb3d] text-4xl font-normal' />,
            },
            {
                data: topTotalStats?.order,
                name: 'Orders',
                icon: <ShoppingOutlined className='text-4xl text-[#efbb3d] font-normal' />,
            },
            {
                data: topTotalStats?.merchant,
                name: 'Merchants',
                icon: <ShopOutlined className='text-4xl text-[#efbb3d] font-normal' />,
            },
        ]?.map((item, index) => {
            return (
                <div key={index} className='bg-white rounded-xl shadow-lg p-2'>
                    <div className='flex justify-between items-center'>
                        <div className='flex flex-col justify-end items-start'>
                            <span className='text-[#003c71] text-xl font-extrabold'>
                                {
                                    <Counter
                                        end={formatNumber(item?.data)?.number}
                                        suffix={formatNumber(item?.data)?.text}
                                    />
                                }
                            </span>
                            <span className='text-gray-500 text-base'>{item?.name}</span>
                        </div>
                        <div>{item?.icon}</div>
                    </div>
                </div>
            )
        })

        return total
    }

    return (
        <>
            {getTopStatsCard()?.map((item, index) => (
                <Col key={index} span={8}>
                    {loading ? <Skeleton loading={loading} active avatar /> : item}
                </Col>
            ))}
        </>
    )
}

export default memo(TotalTopStats)
