export const stepConfiguration = {
    contact_sales_clicked: { id: -1, stepNumber: -1 },
    password_setup: { id: 0, stepNumber: 0 },
    feature_tour_done: { id: 1, stepNumber: 1 },
    smart_cod_app_installed: { id: 2, stepNumber: 2 },
    smart_cod_app_activated: { id: 3, stepNumber: 3 },
    gokwik_shipping_setup_done: { id: 4, stepNumber: 4 },
    smart_cod_app_disabled: { id: 5, stepNumber: 5 },
    imp_checks_passed: { id: 6, stepNumber: 6 },
    kyc_viewed: { id: 7, stepNumber: 7 },
    kyc_basic_info_added: { id: 8, stepNumber: 8 },
    kyc_address_details: { id: 9, stepNumber: 9 },
    kyc_bank_details: { id: 10, stepNumber: 10 },
    kyc_business_details: { id: 11, stepNumber: 11 },
    kyc_document_upload: { id: 12, stepNumber: 12 },
    kyc_vpc: { id: 13, stepNumber: 13 },
    kyc_verification_pending: { id: 14, stepNumber: 14 },
    kyc_verification_failed: { id: 15, stepNumber: 15 },
    kyc_completed: { id: 16, stepNumber: 16 },
    kwik_checkout_button_activated: { id: 17, stepNumber: 17 },
    setup_completed: { id: 18, stepNumber: 18 },
}

export const newStepConfiguration = {
    contact_sales_clicked: { id: -2, stepNumber: -2 },
    onboarding_initiated: { id: -1, stepNumber: -1 },
    password_setup: { id: 0, stepNumber: 0 },
    feature_tour_done: { id: 1, stepNumber: 1 },
    smart_cod_app_installed: { id: 2, stepNumber: 2 },
    smart_cod_app_activated: { id: 3, stepNumber: 3 },
    gokwik_shipping_setup_done: { id: 4, stepNumber: 4 },
    smart_cod_app_disabled: { id: 5, stepNumber: 5 },
    imp_checks_passed: { id: 6, stepNumber: 6 },
    gk_shopify_charges_approved: { id: 7, stepNumber: 7 },
    gk_checkout_button_added: { id: 8, stepNumber: 8 },
    gk_checkout_buttons_not_working: { id: 9, stepNumber: 9 },
    gk_checkout_buttons_working: { id: 10, stepNumber: 10 },
    kyc_viewed: { id: 11, stepNumber: 11 },
    kyc_basic_info_added: { id: 12, stepNumber: 12 },
    kyc_address_details: { id: 13, stepNumber: 13 },
    kyc_bank_details: { id: 14, stepNumber: 14 },
    kyc_business_details: { id: 15, stepNumber: 15 },
    kyc_document_upload: { id: 16, stepNumber: 16 },
    kyc_vpc: { id: 17, stepNumber: 17 },
    kyc_verification_pending: { id: 18, stepNumber: 18 },
    kyc_verification_failed: { id: 19, stepNumber: 19 },
    kyc_completed: { id: 20, stepNumber: 20 },
    kwik_checkout_button_activated: { id: 21, stepNumber: 21 },
    setup_completed: { id: 22, stepNumber: 22 },
    gk_payments_agreement_signed: { id: 23, stepNumber: 23 },
    gk_payments_app_installed: { id: 24, stepNumber: 24 },
    gk_additional_payment_methods_configured: { id: 25, stepNumber: 25 },
    merchant_onboarded: { id: 26, stepNumber: 26 },
}

export const KPStepConfiguration = {
    password_setup: { id: 0, stepNumber: 0 },
    onboarding_initiated: { id: 1, stepNumber: 0 },
    pricing_approval_pending: { id: 2, stepNumber: 0 },
    pricing_approval_failed: { id: 3, stepNumber: 0 },
    pricing_details_confirmed: { id: 4, stepNumber: 1 },
    login_preferences_activated: { id: 5, stepNumber: 1 },
    auto_theme_extension_selected: { id: 6, stepNumber: 2 },
    manual_theme_extension_selected: { id: 7, stepNumber: 2 },
    connect_with_support: { id: 8, stepNumber: 3 },
    kp_configuration_done: { id: 9, stepNumber: 4 },
    setup_completed: { id: 10, stepNumber: 5 },
}

export const CODFirstStepConfig = {
    feature_tour_done: { id: 0, stepNumber: 0 },
    pricing_details_viewed: { id: 1, stepNumber: 1 },
    pricing_details_selected: { id: 2, stepNumber: 1 },
    pricing_approval_pending: { id: 3, stepNumber: 1 },
    pricing_details_confirmed: { id: 4, stepNumber: 1 },
    smart_cod_app_installed: { id: 5, stepNumber: 2 },
    smart_cod_app_activated: { id: 6, stepNumber: 3 },
    setup_completed: { id: 7, stepNumber: 4 },
}
