import { makeAPICall } from '@gokwik/utilities'
import { merchantRoutes } from '../constants/constants'
import { SearchFilters, FilterCreatorModel } from '../interface'
import { getMerchantDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import APIEndPoints from '../constants/apiEndpoints'

const getDynamicSearchFilters = (searchFilters: SearchFilters[]): FilterCreatorModel => {
    const filterCreatorState: FilterCreatorModel = {
        title: 'Add More Filters +',
        options: searchFilters
            ?.filter((item) => item?.is_dynamic)
            ?.map((filter) => {
                return {
                    id: filter?.id,
                    text: filter?.label,
                }
            }),
    }
    return filterCreatorState
}

function getCurrencySymbol() {
    const merchantDetails = useSelector(getMerchantDetails)

    return merchantDetails?.currency_symbol ?? '₹'
}

function deepCopy(obj) {
    try {
        // Check if the input is not an object or is null
        if (obj === null || typeof obj !== 'object') {
            return obj
        }

        // Handle Array
        if (Array.isArray(obj)) {
            const copy = []
            for (let i = 0; i < obj.length; i++) {
                copy[i] = deepCopy(obj[i])
            }
            return copy
        }

        // Handle Object
        if (obj instanceof Object) {
            const copy = {}
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    copy[key] = deepCopy(obj[key])
                }
            }
            return copy
        }
    } catch (error) {
        return obj
    }
}

function getExperimentsPayload(payload) {
    const { name, description, start_date, end_date, is_active, merchants, exposure_percentage, variantsList } = payload

    const variantsPayload = variantsList.map((variant) => {
        const { ip_addresses, ...rest } = variant
        return {
            ...rest,
            forced_assignment_rules:
                ip_addresses?.length < 1
                    ? []
                    : [
                          {
                              conditions: [
                                  {
                                      attribute: 'ip_address',
                                      operator: 'ONE_OF',
                                      value: ip_addresses,
                                  },
                              ],
                          },
                      ],
        }
    })

    let payloadObj = {
        name,
        description,
        start_date: new Date(start_date),
        end_date: new Date(end_date),
        exposure_control_rules: [],
        exposure_percentage,
        is_active,
        filters: merchants?.includes('all')
            ? []
            : [
                  {
                      conditions: [
                          {
                              attribute: 'm_id',
                              operator: 'ONE_OF',
                              value: merchants,
                          },
                      ],
                  },
              ],
        variants: variantsPayload,
    }

    return payloadObj
}

function isMerchantRoute(path) {
    return merchantRoutes.includes(path)
}

const getPrimaryWebsiteUrl = async (url) => {
    if (!url) return ''
    try {
        const formattedUrl = url.endsWith('/') ? url.slice(0, -1) : url

        const response = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.verifyMerchantUrl,
            method: 'post',
            payload: {
                url: formattedUrl,
            },
            skipLoader: true,
            skipMode: true,
        })

        if (response?.data?.data?.status_code === 200) {
            return response?.data?.data?.final_url
        }

        return ''
    } catch (error) {
        console.error('DNS validation error:', error)
        return ''
    }
}

const initGoogleAuth = (state) => {
    const clientId = process.env.GOOLE_OAUTH_CREDS
    const redirectUri = `${window.location.origin}/oauth-redirect`

    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${encodeURIComponent(
        clientId,
    )}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=code&scope=${encodeURIComponent(
        'openid email profile',
    )}&state=${encodeURIComponent(state)}&prompt=select_account`

    window.location.href = authUrl
}

export {
    getDynamicSearchFilters,
    deepCopy,
    getExperimentsPayload,
    isMerchantRoute,
    getCurrencySymbol,
    initGoogleAuth,
    getPrimaryWebsiteUrl,
}

export const rangesOverlap = ([x1, x2], [y1, y2]) => x1 <= y2 && y1 <= x2
