import { useState } from 'react'
import RecommendedSection from './RecommendedSection'
import TopBanner from './TopBanner'
import XSellBanner from './XSellBanner'

const Preview = ({ previewImage, campaignType, campaignConfigs }) => {
    return (
        <div>
            <div className='w-full'>
                {campaignType === 'small' && <TopBanner campaignConfigs={campaignConfigs?.[campaignType]} />}

                {campaignType === 'modal' && (
                    <XSellBanner previewImage={previewImage} campaignConfigs={campaignConfigs?.[campaignType]} />
                )}

                {campaignType === 'recommended' && (
                    <RecommendedSection previewImage={previewImage} campaignConfigs={campaignConfigs?.[campaignType]} />
                )}
            </div>
        </div>
    )
}

export default Preview
