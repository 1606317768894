import React from 'react'

export default function MarketingAnalyticsIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.99972 3.19949C8.43542 3.56213 6.99664 4.33669 5.8326 5.44283C4.66856 6.54897 3.82166 7.9464 3.37975 9.49017C2.93783 11.0339 2.917 12.6678 3.31941 14.2224C3.72182 15.7769 4.53281 17.1955 5.66827 18.3309C6.80372 19.4664 8.22229 20.2774 9.77683 20.6798C11.3314 21.0822 12.9653 21.0614 14.509 20.6195C16.0528 20.1776 17.4502 19.3307 18.5564 18.1666C19.6625 17.0026 20.4371 15.5638 20.7997 13.9995C20.7997 13.7343 20.6944 13.4799 20.5068 13.2924C20.3193 13.1049 20.0649 12.9995 19.7997 12.9995H12.9997C12.4693 12.9995 11.9606 12.7888 11.5855 12.4137C11.2104 12.0386 10.9997 11.5299 10.9997 10.9995V3.99949C10.9873 3.88102 10.9515 3.76619 10.8943 3.66166C10.8372 3.55714 10.7599 3.46499 10.6669 3.39058C10.5738 3.31616 10.467 3.26095 10.3524 3.22815C10.2379 3.19535 10.118 3.18561 9.99972 3.19949Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15 3.5C16.2697 3.94708 17.423 4.6733 18.3748 5.62516C19.3267 6.57702 20.0529 7.73028 20.5 9H16C15.7348 9 15.4804 8.89464 15.2929 8.70711C15.1054 8.51957 15 8.26522 15 8V3.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

  )
}
