import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import {
    Button,
    Col,
    Drawer,
    Input,
    Row,
    Select,
    Space,
    TextArea,
    Tooltip,
    message,
    DatePicker,
    TimePicker,
    Tag, 
    Modal
} from 'gokwik-ui-kit'
import { ReactNode, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import permissions from './permissions'
// import TimePicker from 'react-time-picker';
interface Props {
    open: boolean
    title: string
    onClose: (refetch?: boolean) => void
}

interface NotificationsDetails {
    name: string | null
    description: string | null
    permission_type: string | null
    // permissions: any | null
}
const {Option} = Select
const addDrawerPermissions = ({ open, title, onClose }: Props) => {
    const [data, setData] = useState<NotificationsDetails>({
        name: '',
        description: '',
        permission_type: '',
    })
    const [permissionsOption, setPermissionOptions] = useState([])
    const [permissionArray, setPermissionArray] = useState([])
    async function makePermissionsApiCall() {
        try {
            let params = { page: 1, pageSize: 1000 }
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + '/api/dashboard/permissions/all',
                params: {
                    ...params,
                },
            })
            if (response.data?.data?.permissions?.length > 0) {
                let permissions = response.data.data.permissions
                let arr = []

                let obj = {
                    value: 'SelectAll',
                    label: 'SelectAll',
                }
                arr.push(obj)
                for (let i = 0; i < permissions.length; i++) {
                    let obj = {
                        value: permissions[i].name,
                        label: permissions[i].name + ' --> ' + permissions[i]?.description || 'Description not added',
                    }
                    arr.push(obj)
                }
                setPermissionOptions(arr)
            }
        } catch (err) {
            console.warn(err)
        }
    }
    useEffect(() => {
        makePermissionsApiCall()
    }, [])

    const [proceed, setProceed] = useState(false)
  
    const conditionsCheck = async() => {
       if(!data?.name?.trim()) {
            message.warning('Name is Required')
            return
        }
        if (!permissionArray.length) {
            message.warning('Permissions are Required')
            return
        }
        if (!data?.permission_type) {
            message.warning('Permission type is Required')
            return
        }
        if (!data?.description?.trim()) {
            message.warning('Description is Required')
            return
        }
      setProceed(true);
      return;
    }
    const onEditSubmit = async () => {
        setProceed(false)
        const payload = {
            name: data?.name?.trim(), // optional
            description: data?.description?.trim(),
            permissions: permissionArray,
            permission_type: data?.permission_type,
        }
        const url = `${process.env.REACT_APP_BASE_URL}${APIEndPoints.addRoles}`
        const response = await makeAPICall({
            method: 'post',
            url: url,
            payload: payload,
        })
        if (response.success) {
            message.success('Added Successfully')
            onClose(true)
        } else {
            message.warning(
                response?.data?.message ||
                    response?.data?.data?.message ||
                    response?.response?.data?.message ||
                    response?.response?.data?.data?.message,
            )
        }
    }

    return (
        <>
            <Modal
            title={
                <p> Are your sure, you want to add this role ?</p>
            }
            open={proceed ? true : false}
            onCancel={() => {
                setProceed(false)
            }}
            centered
            width={'500px'}
            footer={() => (
                <Row className='flex justify-end gap-2'>
                    <Button onClick={() => setProceed(null)} variant='default'>
                        Cancel
                    </Button>
                    <Button
                            onClick={() => {
                            onEditSubmit()
                            }}
                            variant='primary'
                        >
                            Confirm
                        </Button>
                </Row>
            )}
        >
        </Modal>
        <Drawer title={title} className='pt-0' placement='right' onClose={() => onClose()} open={open} width = {555} >
            <Col className='w-full'>
                <Space direction='vertical' className='w-full'>
                  <Row gutter={12}>
                    <Col span={12}>
                    <Input
                        label = {'Name*'}
                        value={data?.name}
                        placeholder='Name'
                        onChange={(e) => {
                            setData((prev) => ({ ...prev, name: e.target.value }))
                        }}
                    />
                    </Col>
                    <Col span={12}>
                    <Select
                        label = {'Permission Type*'}
                        className='w-full'
                        placeholder='Select Type'
                        value={data?.permission_type}
                        onChange={(e) => {
                            setData((prev) => ({ ...prev, permission_type: e }))
                        }}
                        // options =
                        options={[
                            { value: 'web', label: 'Internet Portal' },
                            { value: 'merchant', label: 'Merchant' },
                        ]}
                    />
                    </Col>
                  </Row>
                  <Row>
                    <Col span = {24}>
                    <Select
                        label = {'Permissions*'}
                        className='w-full'
                        mode='multiple'
                        placeholder='Select items'
                        value={permissionArray}
                        onChange={(value) => {setPermissionArray(value);}}
                        tagRender={({ label, value, closable, onClose }) => (
                            <Tag
                                color='green'
                                closable={closable}
                                onClose={onClose}
                            >
                                {value}
                            </Tag>
                        )}
                        >
                        {permissionsOption.map((permission,index) => (
                        <Option key={index} value={permission?.value}>
                            {permission?.label}
                        </Option>
                        ))}
                    </Select> 
                    </Col>
                  </Row>
                  <Row>
                    <Col span = {24}>
                    <TextArea
                        label = {'Description*'}
                        placeholder='Description'
                        value={data?.description}
                        onChange={(e) => {
                            setData((prev) => ({ ...prev, description: e.target.value }))
                        }}
                    />
                    </Col>
                  </Row>
                </Space>
            </Col>
            <Row className='mt-4'>
                <Col span={12}>
                    <Button onClick={conditionsCheck} variant='primary' className='w-[99%]'>
                        Submit
                    </Button>
                </Col>
                <Col span={12}>
                    <Button onClick={() => onClose()} variant='default' className='w-[99%] ml-1'>
                        Cancel
                    </Button>
                </Col>
            </Row>
        </Drawer>
    </>
    )
}

export default addDrawerPermissions
