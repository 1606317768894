import { Table, ColumnProps, Row, Button, TagTwoTone, Tag, Switch } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { convertIsoToLocaleString, makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { handleError } from '@library/utilities/helpers/handleError'

export default function () {
    const [skipLogin, setSkipLogin] = useState(false);

    useEffect(() => {
        getShopifyGlobalConfig()
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'providers',
                href: '/global-settings',
                text: 'Global Settings',
            },
            {
                key: 'providers',
                href: '/global-settings/global-config',
                text: 'Shopify Global Config',
            },
        ])
    }, [])

    async function getShopifyGlobalConfig() {
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.getShopifyGlobalConfig,
            })

            setSkipLogin(response?.data?.data?.skipEnhancerLogin || false);
        } catch (error) {
            handleError(error)
        }
    }

    async function updateShopifyGlobalConfig(val: boolean) {
        try {
            const body: any = {
                skipEnhancerLogin: val
            }
            await makeAPICall({
                method: 'post',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.updateShopifyGlobalConfig,
                payload: body,
            })
        } catch (error) {
            handleError(error)
        }
    }

    return (
        <div className='w-full h-full'>
            <div>
                <div>
                    <div className='flex items-baseline mb-1'>
                        <span className='pr-2'>
                            <TagTwoTone size={12} className='tag-icon'/>
                        </span>
                        <p className='text-base font-medium mb-1'>Global Config</p>
                    </div>
                </div>
                <div className='flex gap-2 mt-4'>
                <span className='pr-1 font-semibold text-gray-500'>Skip Gokwik Checkout</span>
                    <Switch
                        value={skipLogin}
                        onChange={async (val)=>{
                            await updateShopifyGlobalConfig(val)
                            getShopifyGlobalConfig()
                        }}
                    />
                </div>
            </div>
        </div>
    )
}
