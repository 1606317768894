import { Tooltip, QuestionCircleOutlined, message } from 'gokwik-ui-kit'

export const configs = {
    small: {
        name: 'Update Name',
        title: 'This is a sample text for the banner',
        description: 'This is a sample text for the description.',
        cta_text: 'Call to action',
        cta_url: 'https://example.com',
    },
    modal: {
        name: 'Update Name',
        title: 'This is a sample text for the banner',
        description: 'This is a sample text for the description.',
        cta_text: 'Call to action',
        cta_url: 'https://example.com',
        media_url: 'https://as1.ftcdn.net/v2/jpg/10/24/72/74/1000_F_1024727462_3IVfh9bIv5ehMqvggviGQPiL0sDulhhD.jpg',
    },
    recommended: {
        name: 'Update Name',
        title: 'This is a sample text for the banner',
        description: 'This is a sample text for the description.',
        cta_text: 'Call to action',
        cta_url: 'https://example.com',
    },
}

export const StickerSVG = () => {
    return (
        <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_315_12266)'>
                <path
                    d='M8 9C8.26522 9 8.51957 9.10536 8.70711 9.29289C8.89464 9.48043 9 9.73478 9 10C9 9.73478 9.10536 9.48043 9.29289 9.29289C9.48043 9.10536 9.73478 9 10 9C9.73478 9 9.48043 8.89464 9.29289 8.70711C9.10536 8.51957 9 8.26522 9 8C9 8.26522 8.89464 8.51957 8.70711 8.70711C8.51957 8.89464 8.26522 9 8 9ZM8 3C8.26522 3 8.51957 3.10536 8.70711 3.29289C8.89464 3.48043 9 3.73478 9 4C9 3.73478 9.10536 3.48043 9.29289 3.29289C9.48043 3.10536 9.73478 3 10 3C9.73478 3 9.48043 2.89464 9.29289 2.70711C9.10536 2.51957 9 2.26522 9 2C9 2.26522 8.89464 2.51957 8.70711 2.70711C8.51957 2.89464 8.26522 3 8 3ZM4.5 9C4.5 8.20435 4.81607 7.44129 5.37868 6.87868C5.94129 6.31607 6.70435 6 7.5 6C6.70435 6 5.94129 5.68393 5.37868 5.12132C4.81607 4.55871 4.5 3.79565 4.5 3C4.5 3.79565 4.18393 4.55871 3.62132 5.12132C3.05871 5.68393 2.29565 6 1.5 6C2.29565 6 3.05871 6.31607 3.62132 6.87868C4.18393 7.44129 4.5 8.20435 4.5 9Z'
                    fill='#4096FF'
                    stroke='#4096FF'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_315_12266'>
                    <rect width='12' height='12' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}

export const TopBannerRadio = () => {
    return (
        <svg width='180' height='129' viewBox='0 0 180 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M0 8C0 3.58173 3.58172 0 8 0H172C176.418 0 180 3.58172 180 8V121C180 125.418 176.418 129 172 129H8C3.58172 129 0 125.418 0 121V8Z'
                fill='#EAECF0'
            />
            <path
                d='M0.5 8C0.5 3.85787 3.85786 0.5 8 0.5H172C176.142 0.5 179.5 3.85786 179.5 8V121C179.5 125.142 176.142 128.5 172 128.5H8C3.85786 128.5 0.5 125.142 0.5 121V8Z'
                stroke='black'
                stroke-opacity='0.15'
            />
            <g filter='url(#filter0_d_934_7557)'>
                <rect x='12' y='12' width='156' height='105' rx='6' fill='white' shape-rendering='crispEdges' />
                <path
                    d='M37 22C37 19.7909 38.7909 18 41 18H155C157.209 18 159 19.7909 159 22V32C159 34.2091 157.209 36 155 36H41C38.7909 36 37 34.2091 37 32V22Z'
                    fill='black'
                />
                <rect x='55.8184' y='24' width='14.6699' height='2' rx='1' fill='white' />
                <rect x='55.8184' y='28' width='42.3972' height='2' rx='1' fill='white' />
                <rect x='41.9551' y='23' width='8.31818' height='8' rx='2' fill='white' />
                <rect x='143.288' y='25' width='8.54164' height='4' rx='1' fill='white' />
                <path
                    d='M12 18C12 14.6863 14.6863 12 18 12H30V117H18C14.6863 117 12 114.314 12 111V18Z'
                    fill='#F6F6F6'
                />
                <rect x='37' y='44' width='122' height='27' rx='4' fill='#F6F6F6' />
                <rect x='37' y='75' width='59' height='27' rx='4' fill='#F6F6F6' />
                <rect x='100' y='75' width='59' height='27' rx='4' fill='#F6F6F6' />
            </g>
            <defs>
                <filter
                    id='filter0_d_934_7557'
                    x='11'
                    y='12'
                    width='158'
                    height='110'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feMorphology radius='5' operator='erode' in='SourceAlpha' result='effect1_dropShadow_934_7557' />
                    <feOffset dy='4' />
                    <feGaussianBlur stdDeviation='3' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
                    <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_934_7557' />
                    <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_934_7557' result='shape' />
                </filter>
            </defs>
        </svg>
    )
}

export const XSellBannerRadio = () => {
    return (
        <svg width='180' height='129' viewBox='0 0 180 129' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M0 8C0 3.58173 3.58172 0 8 0H172C176.418 0 180 3.58172 180 8V121C180 125.418 176.418 129 172 129H8C3.58172 129 0 125.418 0 121V8Z'
                fill='#EAECF0'
            />
            <path
                d='M0.5 8C0.5 3.85787 3.85786 0.5 8 0.5H172C176.142 0.5 179.5 3.85786 179.5 8V121C179.5 125.142 176.142 128.5 172 128.5H8C3.85786 128.5 0.5 125.142 0.5 121V8Z'
                stroke='black'
                stroke-opacity='0.15'
            />
            <g filter='url(#filter0_d_934_11137)'>
                <rect x='12' y='12' width='156' height='105' rx='6' fill='white' shape-rendering='crispEdges' />
                <path
                    d='M12 18C12 14.6863 14.6863 12 18 12H30V117H18C14.6863 117 12 114.314 12 111V18Z'
                    fill='#F6F6F6'
                />
                <rect x='37' y='44' width='122' height='27' rx='4' fill='#F6F6F6' />
                <rect x='37' y='75' width='59' height='27' rx='4' fill='#F6F6F6' />
                <rect x='100' y='75' width='59' height='27' rx='4' fill='#F6F6F6' />
                <rect x='12' y='12' width='156' height='105' rx='6' fill='black' fill-opacity='0.3' />
                <path
                    d='M55 35C55 32.7909 56.7909 31 59 31H121C123.209 31 125 32.7909 125 35V95C125 97.2091 123.209 99 121 99H59C56.7909 99 55 97.2091 55 95V35Z'
                    fill='black'
                />
                <rect x='61' y='63' width='14.6699' height='2' rx='1' fill='white' />
                <rect x='61' y='67' width='52.3972' height='2' rx='1' fill='white' />
                <rect x='61' y='71' width='32.3972' height='2' rx='1' fill='white' />
                <rect x='61' y='37' width='58' height='22' rx='2' fill='white' />
                <rect x='61' y='85' width='58' height='8' rx='1' fill='white' />
            </g>
            <defs>
                <filter
                    id='filter0_d_934_11137'
                    x='11'
                    y='12'
                    width='158'
                    height='110'
                    filterUnits='userSpaceOnUse'
                    color-interpolation-filters='sRGB'
                >
                    <feFlood flood-opacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feMorphology radius='5' operator='erode' in='SourceAlpha' result='effect1_dropShadow_934_11137' />
                    <feOffset dy='4' />
                    <feGaussianBlur stdDeviation='3' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
                    <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_934_11137' />
                    <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_934_11137' result='shape' />
                </filter>
            </defs>
        </svg>
    )
}
export const editorFormConfig = [
    {
        label: (
            <div className='flex flex-col gap-1 pb-4'>
                <span className='font-semibold text-sm'>Banner Type</span>

                <span className='font-normal text-xs text-[#00000073]'>Select banner type you want to create.</span>
            </div>
        ),
        name: 'banner_type',
        type: 'radio',
        options: [
            { label: 'Banner', value: 'small' },
            { label: 'XSell', value: 'modal' },
            { label: 'Recommended for you section', value: 'recommended' },
        ],
        colSpan: 24,
    },
    {
        label: 'Product Type',
        explainer: 'Select the product type of the campaign',
        name: 'type',
        placeholder: 'Select Product Type',
        type: 'select',
        options: [
            { label: 'KwikCheckout', value: 'checkout' },
            { label: 'KwikPass', value: 'kwik_pass' },
            { label: 'KwikPayment', value: 'payments' },
            { label: 'RTO', value: 'rto' },
            { label: 'KwikShip', value: 'kwikship' },
            { label: 'KwikShip Shopify OMS', value: 'kwikship_shopify_o_m_s' },
            { label: 'KwikCart', value: 'kwik_cart' },
        ],
        colSpan: 24,
        divider: true,
    },
    {
        label: 'Update Type',
        explainer: 'Select the update type of the campaign',
        name: 'update_type',
        placeholder: 'Select Update Type',
        type: 'select',
        options: [
            { label: 'New Releases', value: 'new_release' },
            { label: 'Feature Release', value: 'feature_release' },
            { label: 'Campaign', value: 'campaign' },
        ],
        colSpan: 12,
        divider: true,
    },
    {
        label: 'Target Segment',
        explainer: 'Select the target segment for this campaign',
        name: 'segment_id',
        placeholder: 'Select Segment',
        type: 'select',
        options: ['seg_1', 'seg_2', 'seg_3'],
        colSpan: 12,
    },
    {
        label: 'Update Name',
        name: 'name',
        type: 'input',
        rules: [
            {
                validator: (_, value) => {
                    const wordCount = value ? value.trim().split(/\s+/).length : 0
                    if (wordCount > 5) {
                        return Promise.reject(new Error('Maximum 5 words allowed'))
                    }
                    return Promise.resolve()
                },
            },
        ],
        colSpan: 24,
        extra: 'Max 5 words',
        divider: true,
        section_heading: 'Title & Description',
    },
    {
        label: 'Banner Title',
        name: 'title',
        type: 'input',
        rules: [
            {
                validator: (_, value) => {
                    const wordCount = value ? value.trim().split(/\s+/).length : 0
                    if (wordCount > 15) {
                        return Promise.reject(new Error('Maximum 5 words allowed'))
                    }
                    return Promise.resolve()
                },
            },
        ],
        colSpan: 24,
        extra: 'Max 15 words',
    },
    {
        label: 'Description',
        name: 'description',
        type: 'text_area',
        rules: [
            {
                validator: (_, value) => {
                    const wordCount = value ? value.trim().split(/\s+/).length : 0
                    if (wordCount > 150) {
                        return Promise.reject(new Error('Maximum 5 words allowed'))
                    }
                    return Promise.resolve()
                },
            },
        ],
        colSpan: 24,
        extra: 'Max 150 words',
    },
    {
        label: 'Banner Image(Optional)',
        name: 'media_url',
        type: 'upload',
        colSpan: 24,
        divider: true,
        section_heading: (
            <div className='w-full'>
                <span>Media/Image</span>
            </div>
        ),
    },
    {
        label: 'Start Date',
        name: 'start_date',
        type: 'date',
        colSpan: 12,
        divider: true,
        section_heading: (
            <div className='w-full'>
                <span>Notification Duration</span> <br />{' '}
                <span className='text-xs font-normal w-full text-[#00000073]'>
                    Setup the duration of the banner you want to show
                </span>
            </div>
        ),
    },
    {
        label: 'End Date',
        name: 'end_date',
        type: 'date',
        colSpan: 12,
    },
    {
        label: 'Button Title',
        name: 'cta_text',
        type: 'input',
        colSpan: 24,
        divider: true,
        section_heading: (
            <div className='w-full'>
                <span>CTA (Call to action)</span>
            </div>
        ),
    },
    {
        label: 'CTA Link',
        name: 'cta_url',
        type: 'input',
        rules: [
            {
                required: true,
                message: 'CTA Link/URL required',
            },
            {
                pattern: new RegExp(
                    /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i,
                ),
                message: 'Please enter a valid URL',
            },
        ],
        colSpan: 24,
    },
    {
        label: 'Dismissible Banner?',
        explainer: 'Toggle on to make your banner dismissible, valid only for the current login session',
        name: 'is_dismissable',
        type: 'switch',
        options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
        ],
        // rules: [
        //     {
        //         required: true,
        //     },
        // ],
        colSpan: 24,
    },
    {
        label: 'Don’t show me again',
        explainer: 'If this is clicked, the campaign won’t be shown again after being dismissed',
        name: 'is_dismissed_permanently',
        type: 'switch',
        options: [
            { label: 'Yes', value: true },
            { label: 'No', value: false },
        ],
        // rules: [
        //     {
        //         required: true,
        //     },
        // ],
        colSpan: 24,
    },
]
