import { navigateToUrl } from 'single-spa'
import React, { useState, useEffect, useCallback } from 'react'
import { makeAPICall } from '@gokwik/utilities'
import { Button, Col, Row, Input, Form, Select, Popover, message, InputNumber, Tooltip } from 'gokwik-ui-kit'
import { useSelector } from 'react-redux'
import { getSignUpEmail } from '@store/user/selectors'
import LoginLayout from '@library/components/loginLayout'
import EmailIcon from '@library/images/icons/emailIcon'
import PasswordIcon from '@library/images/icons/passwordIcon'
import { CheckCircleFilled, LinkOutlined, TagsOutlined, UserOutlined } from '@ant-design/icons'
import { useSearchParams } from 'react-router-dom'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { handleError } from '@library/utilities/helpers/handleError'
import shileds from '@library/images/icons/shield.svg'
import shiledsCross from '@library/images/icons/shield-cross.svg'
import { passwordValidation, passwordValidationMessages } from '@library/utilities/helpers/validations'
import shieldGreen from '@library/images/icons/shield.svg'
import shieldCross from '@library/images/icons/shield-cross.svg'

import useAESEncryption from '@library/utilities/authorization/encryption'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

const { Option } = Select

const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/[^\s]*[^.\s])?$/i

const passwordValidationTexts = [
    { text: 'Minimum 8 characters', key: 'length' },
    { text: '1 lowercase and 1 uppercase letter', key: 'casing' },
    { text: '1 special character', key: 'specialChar' },
]

export default function SignupForm() {
    const [isMerchantExist, setMerchantExist] = useState({ websiteExist: false, brandNameExist: false })
    const [searchParams] = useSearchParams()
    const [teamInfoUpdated, setTeamInfoUpdated] = useState(false)
    const [memberEmail, setMemberEmail] = useState('')
    const [selectedMerchant, setSelectedMerchant] = useState('')
    const [urlFieldError, setUrlFieldError] = useState(false)
    const [formDetails, setFormDetails] = useState({
        business_categories: [],
        merchant_details: {},
        monthly_gmv: [],
        products: [],
        platforms: [],
    })
    const [validateFormState, setValidateFormState] = useState({
        phone: null,
    })

    const [showPasswordAssistance, setShowPasswordAssistance] = useState(false)
    const [goodPassword, setGoodPassword] = useState({
        'Minimum 8 Length': false,
        '1 Small and 1 Capital Letter': false,
        '1 Digit': false,
        '1 Special Character': false,
    })

    const [form] = Form.useForm()
    let email = localStorage.getItem('email')
    form.setFieldValue('email', email)
    const signupEmail = useSelector(getSignUpEmail)

    const password = Form.useWatch('password', form)
    const PopoverContent = useCallback(() => {
        const passwordStrength = passwordValidation(password || '')
        return (
            <>
                {Object.entries(passwordValidationMessages).map(([key, value]) => (
                    <div key={key} className={`text-xs flex justify-between p-1`}>
                        <p className='mr-2'>{value}</p>
                        {passwordStrength[key] ? (
                            <img src={shieldGreen} className='tick-icon' />
                        ) : (
                            <img src={shieldCross} className='tick-icon' />
                        )}
                    </div>
                ))}
            </>
        )
    }, [password])

    const validateFormData = () => {
        const fields = form.getFieldsValue()
        const keysToValidate = ['business_name', 'email', 'name', 'password']

        for (const key of keysToValidate) {
            if (!fields[key]) {
                message.error(`${key} is missing`)
                return false
            }
        }

        return true
    }

    const signin = async () => {
        logEvent('signup_page_submit_clicked', 'click', 'signup', localStorage?.getItem('email'))

        if (isMerchantExist.websiteExist && form.getFieldValue('platform')?.toLowerCase() === 'shopify') {
            logEvent('signup_page_redirecting_to_login', 'click', 'signup', localStorage?.getItem('email'))
            navigateToUrl('/login')
            return
        }

        const fields = form.getFieldsValue()
        const isValid = validateFormData()

        if (fields.password !== fields.confirm_password) {
            message.warning('Password does not match')
            return
        }

        if (!isValid) {
            message.warning('Please fill all the fields')
            return
        }

        if (fields.email.indexOf('@') === -1 || fields.email.indexOf('.') === -1) {
            message.error('Invalid email')
            return
        }

        if (!fields.password || fields.password.length < 8) {
            message.error('Invalid password')
            return
        }

        if (fields?.phone) {
            fields.phone = String(fields.phone)
        }

        const encryptedPassword = await useAESEncryption(fields.password, process.env.REACT_APP_PUBLIC_CRYPTO_KEY)
        const encryptedConfirmPassword = await useAESEncryption(
            fields.confirm_password,
            process.env.REACT_APP_PUBLIC_CRYPTO_KEY,
        )

        await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.signup,
            payload: { ...fields, password: encryptedPassword, confirm_password: encryptedConfirmPassword },
        })
            .then((response) => {
                if (response.success) {
                    logEvent('signup_page_submit_success', 'click', 'signup', localStorage?.getItem('email'))
                    localStorage.setItem('email', fields.email)
                    navigateToUrl('/verify-merchant')
                } else {
                    message.error(response?.response?.data?.data || 'Failed')
                    logEvent('signup_page_submit_failed', 'click', 'signup', localStorage?.getItem('email'))
                }
            })
            .catch((err) => {
                handleError(err)
                if (err.response.data.status_code === 403) {
                    // router.push('/verify-otp');
                    // return;
                }
                const errorMsg = err.response && err.response.data ? err.response.data.message : 'Something failed'
                message.error(errorMsg)
                logEvent('signup_page_submit_failed', 'click', 'signup', localStorage?.getItem('email'))
            })
    }

    // Custom debounce function
    const callDebounceFunction = useCallback((func, delay) => {
        let timer
        return function () {
            const context = this
            const args = arguments
            clearTimeout(timer)
            timer = setTimeout(() => {
                func.apply(context, args)
            }, delay)
        }
    }, [])

    const checkUserExist = async () => {
        const value = form.getFieldValue('business_name')
        const trimmedValue = value.trim()
        form.setFieldsValue({ business_name: trimmedValue })

        const business_name = form.getFieldValue('business_name')
        const website = form.getFieldValue('website')

        if ((!business_name && !website) || !urlRegex.test(website)) {
            return
        }

        const response = await makeAPICall({
            skipLoader: true,
            skipMode: true,
            url: process.env.REACT_APP_BASE_URL + '/api/dashboard/merchant/check-merchant',
            method: 'post',
            payload: {
                business_name,
                website,
            },
        })

        if (response.data?.data.website_already_exists) {
            setMerchantExist((prev) => ({ ...prev, websiteExist: true }))
            message.error('Website URL already exists')
            setTeamInfoUpdated(true)
        } else if (response?.data?.data.business_name_already_exists) {
            setMerchantExist((prev) => ({ ...prev, brandNameExist: true }))
            message.error('Brand Name already exists')
        } else {
            setMerchantExist(() => ({ brandNameExist: false, websiteExist: false }))
            setTeamInfoUpdated(true)
        }

        if (response.data?.data.existing_email_mask) {
            setMemberEmail(response.data?.data.existing_email_mask)
            form.setFieldValue('masked_email', response.data?.data.existing_email_mask)
        } else {
            setMemberEmail('')
        }
    }

    const debouncedCheckUserExist = useCallback(callDebounceFunction(checkUserExist, 500), [callDebounceFunction])

    const handleInputChange = () => {
        debouncedCheckUserExist()
    }

    useEffect(() => {
        // Clean up debounce timer on unmount
        return () => {
            // @ts-ignore
            debouncedCheckUserExist?.cancel()
        }
    }, [debouncedCheckUserExist])

    const getMerchantDetails = async () => {
        const m_id = searchParams.get('merchant_id')
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + '/api/dashboard/onboarding/signup-details',
        })

        if (response.data.status_code === 200) {
            setFormDetails(response.data.data)
            if (m_id) {
                const merchant_details = response.data.data.merchant_details
                form.setFieldsValue({
                    website: merchant_details.website,
                    business_name: merchant_details.brand_name,
                    platform: merchant_details.platform,
                    email: merchant_details.email,
                    products: merchant_details.products,
                })
            }
        }
    }

    useEffect(() => {
        getMerchantDetails()
        logEvent('signup_page_landed', 'event', 'signup', localStorage?.getItem('email'))
    }, [])

    const handleUsernameChange = (e) => {
        const { value } = e.target
        // Allow only alphabetic characters and spaces
        if (/^[a-zA-Z\s]*$/.test(value)) {
            form.setFieldsValue({ name: value })
        }
    }

    const validatePhoneNumber = (_, value) => {
        const regex = /^[6-9]\d{9}$/
        if (!value || (regex.test(value) && value.length == 10)) {
            return Promise.resolve()
        }
        return Promise.reject('Please enter a valid phone number')
    }
    const validateName = (_, value) => {
        if (!value || value.trim() === '') {
            return Promise.reject(new Error('Name cannot be empty or contain only spaces'))
        }
        // Trim leading and trailing spaces and check for only alphabetic characters and spaces, also no HTML tags
        const trimmedValue = value.trim()
        if (!/^[a-zA-Z\s]+$/.test(trimmedValue) || /<[^>]*>/g.test(trimmedValue)) {
            return Promise.reject(new Error('Only alphabetic characters and spaces are allowed!'))
        }
        return Promise.resolve()
    }

    const validateBrandName = (_, value) => {
        if (!value || value.trim() === '') {
            return Promise.reject(new Error('Name cannot be empty or contain only spaces'))
        }
        if (/<[^>]*>/g.test(value)) {
            return Promise.reject(new Error('HTML tags are not allowed!'))
        }
        return Promise.resolve()
    }

    const handlePlatformChange = (value) => {
        form.setFieldsValue({ platform: value })
        setSelectedMerchant(value)
    }

    return (
        <LoginLayout>
            <Form layout='vertical' className='h-full w-full min-w-[560px]' onFinish={signin} form={form}>
                <div className='grid gap-4'>
                    <Row className='gap-4 w-full'>
                        <Col span={11}>
                            <Form.Item
                                label='Platform'
                                className='body text-primary-750 mb-2'
                                name='platform'
                                rules={[{ required: true, message: 'Please select a platform' }]}
                            >
                                <Select
                                    // disabled={isMerchantExist}
                                    placeholder='Platform'
                                    onChange={(e) => handlePlatformChange(e)}
                                >
                                    {formDetails?.platforms?.map((e) => (
                                        <Option key={e} value={e}>
                                            {e}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label='Brand Name'
                                className='body text-primary-750 mb-2'
                                name='business_name'
                                rules={[
                                    {
                                        required: true,
                                        validator: validateBrandName,
                                    },
                                ]}
                            >
                                <Input
                                    onBlur={checkUserExist}
                                    prefix={<TagsOutlined />}
                                    placeholder='Brand Name'
                                    // disabled={isMerchantExist}
                                    onChange={handleInputChange}
                                    suffix={
                                        form.getFieldValue('business_name') &&
                                        !isMerchantExist.brandNameExist && (
                                            <CheckCircleFilled className='text-green-400' />
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className='gap-4 w-full'>
                        <Col span={24}>
                            <Form.Item
                                label={
                                    form.getFieldValue('platform') === 'shopify'
                                        ? 'Enter Shopify Store URL (e.g. https://www.example.myshopify.com)'
                                        : 'Website/Store URL (e.g. https://www.example.com)'
                                }
                                className='body text-primary-750 mb-2'
                                name='website'
                                validateFirst
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input a URL!',
                                    },
                                    {
                                        pattern: urlRegex,
                                        message: 'Please enter a valid URL!',
                                    },
                                    () => ({
                                        validator(_, value) {
                                            const platform = form.getFieldValue('platform') // assuming you have a field for platform
                                            if (platform === 'shopify' && value && !value.endsWith('.myshopify.com')) {
                                                setUrlFieldError(true)
                                                return Promise.reject(
                                                    new Error(
                                                        'Please enter a valid Shopify URL ending with ".myshopify.com".',
                                                    ),
                                                )
                                            } else {
                                                setUrlFieldError(false)
                                            }
                                            return Promise.resolve()
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    prefix={<LinkOutlined />}
                                    placeholder='Website URL'
                                    onChange={handleInputChange}
                                    suffix={
                                        form.getFieldValue('website') &&
                                        !isMerchantExist.websiteExist && (
                                            <CheckCircleFilled className='text-green-400' />
                                        )
                                    }
                                />
                            </Form.Item>
                            {form.getFieldValue('platform')?.toLowerCase() === 'shopify' &&
                                isMerchantExist.websiteExist &&
                                memberEmail.length > 0 && (
                                    <p className='font-bold text-red-500'>
                                        This store is already registered on GoKwik, please login via registered email
                                        id!
                                    </p>
                                )}
                            {form.getFieldValue('platform')?.toLowerCase() === 'shopify' &&
                                isMerchantExist.websiteExist &&
                                memberEmail === '' && (
                                    <div className='font-bold text-red-500'>
                                        <p>This store is already registered on GoKwik!</p>
                                        <p>*Tip: Use the email Id linked to the shopify store to login</p>
                                    </div>
                                )}
                        </Col>
                    </Row>
                    {form.getFieldValue('platform')?.toLowerCase() === 'shopify' &&
                        isMerchantExist.websiteExist &&
                        memberEmail.length > 0 && (
                            <Row className='gap-4 w-full'>
                                <Col span={11}>
                                    <Form.Item
                                        label={<p className='font-bold'>Registered Login Email</p>}
                                        className='body text-primary-750 mb-2'
                                        name='masked_email'
                                    >
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                    {((selectedMerchant?.toLowerCase() !== 'shopify' && selectedMerchant?.length > 0) ||
                        (!isMerchantExist.websiteExist &&
                            form.getFieldValue('platform')?.toLowerCase() === 'shopify' &&
                            teamInfoUpdated)) &&
                        !urlFieldError && (
                            <div className='grid gap-4'>
                                <Row className='gap-4 w-full'>
                                    <Col span={11}>
                                        <Form.Item
                                            label='Your Name'
                                            className='body text-primary-750 mb-2'
                                            name='name'
                                            rules={[
                                                {
                                                    required: true,
                                                    // whitespace:
                                                    // message: 'Alphabetic characters are allowed!',
                                                    validator: validateName,
                                                },
                                            ]}
                                        >
                                            <Input
                                                onChange={handleUsernameChange}
                                                prefix={<UserOutlined />}
                                                placeholder='Your Name'
                                                onBlur={(e) => {
                                                    const value = form.getFieldValue('name')
                                                    const trimmedValue = value.trim() // Remove all whitespaces
                                                    form.setFieldsValue({ name: trimmedValue })
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label='Login Email'
                                            className='body text-primary-750 mb-2'
                                            name='email'
                                            rules={[
                                                { required: true, message: 'Please enter your login email' },
                                                { type: 'email', message: 'Please enter a valid email' },
                                            ]}
                                        >
                                            <Input
                                                type='email'
                                                placeholder='Login Email'
                                                prefix={<EmailIcon />}
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className='gap-4 w-full'>
                                    <Col span={11}>
                                        <Form.Item
                                            label='Business Category'
                                            className='body text-primary-750 mb-2'
                                            name='business_category'
                                            rules={[{ required: false, message: 'Please select a business category' }]}
                                        >
                                            <Select placeholder='Business Category'>
                                                {formDetails?.business_categories?.map((e) => (
                                                    <Option key={e} value={e}>
                                                        {e}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label='Phone no.'
                                            className='body text-primary-750 mb-2'
                                            name='phone'
                                            rules={[
                                                { required: true, message: 'Please enter your phone number' },
                                                // {
                                                //     validator: validatePhoneNumber,
                                                // },
                                            ]}
                                        >
                                            <InputNumber
                                                maxLength={10}
                                                placeholder='Phone no.'
                                                prefix='+91'
                                                className='w-full'
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className='gap-4 w-full'>
                                    <Col span={11}>
                                        <Popover open={showPasswordAssistance} content={<PopoverContent />}>
                                            <Form.Item
                                                label='Password'
                                                className='body text-primary-750 mb-0 p-0'
                                                name='password'
                                                labelAlign='left'
                                                labelCol={{ style: { padding: 0 } }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your password!',
                                                    },
                                                    () => ({
                                                        validator(_, value) {
                                                            const isPasswordValid = Object.values(
                                                                passwordValidation(value || ''),
                                                            ).reduce((result, current) => current && result, true)
                                                            console.log({ isPasswordValid })

                                                            if (!value || isPasswordValid) {
                                                                return Promise.resolve()
                                                            }
                                                            return Promise.reject(
                                                                new Error('Please enter a strong password!'),
                                                            )
                                                        },
                                                    }),
                                                ]}
                                                hasFeedback
                                                required
                                            >
                                                <Input.Password
                                                    placeholder='Password'
                                                    prefix={<PasswordIcon />}
                                                    onChange={(e) => {
                                                        form.setFieldsValue({ password: e.target.value })
                                                        // checkGoodPassword(e.target.value)
                                                    }}
                                                    onFocus={() => setShowPasswordAssistance(true)}
                                                    onBlur={() => setShowPasswordAssistance(false)}
                                                />
                                            </Form.Item>
                                        </Popover>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label='Confirm Password'
                                            className='body text-primary-750 mb-2'
                                            name='confirm_password'
                                            dependencies={['password']}
                                            rules={[
                                                { required: true, message: 'Please confirm your password' },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve()
                                                        }
                                                        return Promise.reject(
                                                            new Error('The two passwords do not match!'),
                                                        )
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password placeholder='Confirm Password' prefix={<PasswordIcon />} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    <Row className='gap-4 w-full'>
                        <Col span={24}>
                            <Button
                                disabled={
                                    (isMerchantExist.websiteExist || isMerchantExist.brandNameExist) &&
                                    form.getFieldValue('platform')?.toLowerCase() !== 'shopify'
                                }
                                type='primary'
                                htmlType='submit'
                                className='w-full'
                            >
                                {form.getFieldValue('platform')?.toLowerCase() === 'shopify' &&
                                isMerchantExist.websiteExist
                                    ? 'Log In'
                                    : 'Sign Up'}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </LoginLayout>
    )
}
