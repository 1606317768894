export const DisountSVG = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='86' height='86' viewBox='0 0 86 86' fill='none'>
            <rect width='86' height='86' fill='white' />
            <circle cx='55.9897' cy='51.5078' r='28.2188' fill='#DBEEFF' fill-opacity='0.5' />
            <path
                d='M28.0301 25.4069C29.2637 26.2681 30.3143 27.7295 30.8036 29.0607C30.8675 28.9825 30.9227 28.8988 30.9954 28.8253C31.2242 28.596 31.486 28.4248 31.7593 28.2845C31.6455 27.0328 30.5021 24.9922 28.9918 23.9361C28.4305 23.5443 27.6861 23.203 26.8792 23.286C26.4754 23.4788 26.118 23.7088 25.8643 23.9644C25.6805 24.1478 25.5096 24.3832 25.3555 24.6502C26.2163 24.5289 27.1275 24.7764 28.0301 25.4069Z'
                fill='#003463'
            />
            <path
                d='M72.8369 48.1905L49.5511 24.8972C48.7213 24.0677 47.0843 23.3394 45.9133 23.2797L28.8682 22.4025C28.5478 22.3864 28.1993 22.4186 27.8467 22.485C28.1993 22.6158 28.5545 22.8002 28.9071 23.0463C30.4562 24.1267 31.7131 26.1533 31.9276 27.6743C32.917 27.4308 34.0023 27.685 34.7752 28.4576C35.9502 29.6325 35.9502 31.5391 34.7752 32.7146C33.6001 33.8896 31.695 33.8896 30.5199 32.7146C29.6398 31.8335 29.4186 30.5418 29.8583 29.4554C29.55 28.2161 28.5069 26.5322 27.1837 25.6081C26.5027 25.1333 25.5495 24.7336 24.544 25.087C24.3 25.7422 24.1599 26.4651 24.1921 27.0835L25.0896 44.1125C25.1506 45.2847 25.8806 46.9217 26.7091 47.7513L49.9955 71.0459C50.8254 71.8768 52.1815 71.8768 53.0107 71.0473L72.8375 51.209C73.6654 50.3794 73.6654 49.0214 72.8369 48.1905ZM43.5819 35.9953C47.0495 32.5262 49.4593 32.5061 51.79 34.8385C54.1415 37.1904 54.1415 39.6214 50.6739 43.0898C47.2258 46.5401 44.7764 46.5596 42.4249 44.2071C40.0942 41.8753 40.1344 39.4457 43.5819 35.9953ZM56.1203 59.3986C52.6527 62.8671 50.2422 62.8865 47.8921 60.5353C45.5406 58.1828 45.5801 55.7913 49.0477 52.3216C52.4965 48.8726 54.9258 48.7941 57.2766 51.1466C59.6274 53.4978 59.5691 55.9489 56.1203 59.3986ZM40.3885 52.7528L38.3513 50.7161L59.3345 42.6593L61.3911 44.7167L40.3885 52.7528Z'
                fill='#2297FF'
            />
            <path
                d='M51.2814 53.9894C49.0697 56.203 48.7988 57.3675 49.9043 58.4736C51.0291 59.599 52.2143 59.346 54.4266 57.1338C56.6576 54.9036 56.9099 53.7179 55.7844 52.5938C54.6783 51.487 53.513 51.7592 51.2814 53.9894Z'
                fill='#2297FF'
            />
            <path
                d='M49.1844 41.6064C51.4471 39.3398 51.7412 38.1771 50.6011 37.0341C49.4603 35.891 48.2993 36.1857 46.0373 38.453C43.7948 40.7 43.4994 41.8626 44.6408 43.007C45.7822 44.1488 46.9425 43.8541 49.1844 41.6064Z'
                fill='#2297FF'
            />
            <path
                d='M25.9034 22.7206C25.6033 22.1186 25.7524 21.2648 26.1095 19.8858C26.6481 17.7988 27.3868 14.9401 24.1154 12.8968C20.7751 10.8098 16.455 11.3873 13.8428 14.2718C10.9093 17.5113 10.0977 23.9764 16.6153 32.4087C24.3247 42.3829 20.2451 47.6247 16.2993 52.6922C14.6702 54.7852 13.1319 56.7623 12.5251 58.9858C11.4168 63.0479 12.1163 67.0153 14.3973 69.5985C15.6534 71.0212 17.8833 72.5651 21.6423 72.5651C22.328 72.5651 23.066 72.5141 23.8583 72.4008C28.0903 71.7961 31.0483 69.1083 31.9745 65.0283C32.8026 61.3762 31.7102 57.4776 29.4319 55.9602C29.1265 55.7569 28.7164 55.8383 28.513 56.1437C28.3096 56.4477 28.3924 56.859 28.6965 57.0624C30.5117 58.2718 31.3836 61.6431 30.6813 64.7362C29.8843 68.2513 27.3285 70.5668 23.6702 71.0894C20.0748 71.6014 17.2116 70.7841 15.391 68.7222C13.436 66.5087 12.8279 62.9115 13.8037 59.3342C14.341 57.3677 15.8005 55.4919 17.3454 53.5062C21.2024 48.5526 26.0015 42.3875 17.6634 31.5993C11.646 23.8128 12.2522 18.0028 14.8253 15.1607C17.0049 12.7524 20.6161 12.2722 23.4138 14.0201C25.8604 15.5495 25.3582 17.4948 24.8256 19.5547C24.4685 20.939 24.075 22.4617 24.8739 23.6241C24.97 23.4969 25.0694 23.3797 25.1734 23.2764C25.3728 23.0763 25.6219 22.8889 25.9034 22.7206Z'
                fill='#2297FF'
            />
        </svg>
    )
}

export const RTOSVG = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='86' height='86' viewBox='0 0 86 86' fill='none'>
            <rect width='86' height='86' fill='white' />
            <circle cx='55.9897' cy='51.5078' r='28.2188' fill='#DBEEFF' fill-opacity='0.5' />
            <path
                d='M66.5515 20.2193C62.266 15.9582 55.4255 15.9582 51.1399 20.2193L46.8544 24.4803V21.7762C46.8544 21.2845 46.5247 20.9568 46.0302 20.9568C45.5357 20.9568 45.2061 21.2845 45.2061 21.7762V26.447C45.2061 26.9387 45.5357 27.2664 46.0302 27.2664H50.8103C51.3048 27.2664 51.6344 26.9387 51.6344 26.447C51.6344 25.9553 51.3048 25.6276 50.8103 25.6276H48.0906L52.3762 21.3665C56.0024 17.7609 61.8539 17.7609 65.3977 21.3665C69.024 24.972 69.024 30.79 65.3977 34.3136C65.0681 34.6414 65.0681 35.1331 65.3977 35.4608C65.7274 35.7886 66.2219 35.7886 66.5515 35.4608C70.8371 31.2817 70.8371 24.3984 66.5515 20.2193Z'
                fill='#2297FF'
            />
            <path
                d='M38.6956 38.7399C39.2725 38.7399 39.3549 39.1496 41.5801 41.4441V32.9219H35.811V41.4441C38.0362 39.1496 38.1187 38.7399 38.6956 38.7399Z'
                fill='#2297FF'
            />
            <path
                d='M58.5577 32.9219H43.2285V43.4927C43.2285 43.8204 42.9812 44.1482 42.734 44.2302C42.4043 44.394 42.0747 44.3121 41.8274 44.0663L38.6956 40.7066L35.5639 44.0663C35.3166 44.3121 34.987 44.394 34.6573 44.2302C34.3277 44.0663 34.1628 43.8204 34.1628 43.4927V32.9219H18.7512C17.8447 32.9219 17.0205 33.6594 17.0205 34.6427V67.7481C17.0205 68.7314 17.8447 69.4689 18.7512 69.4689H58.6401C59.629 69.4689 60.3708 68.7314 60.3708 67.7481V34.7246C60.3708 33.7413 59.5466 32.9219 58.5577 32.9219ZM36.9649 53.8176C36.6353 54.1454 36.1408 54.1454 35.8111 53.8176L35.3166 53.3259V61.9301C35.3166 62.4217 34.987 62.7495 34.4925 62.7495C33.998 62.7495 33.6683 62.4217 33.6683 61.9301V53.4079L33.1738 53.8995C32.8442 54.2273 32.3497 54.2273 32.02 53.8995C31.6904 53.5718 31.6904 53.0801 32.02 52.7523L33.9156 50.8676C34.2452 50.5398 34.7397 50.5398 35.0694 50.8676L36.9649 52.7523C37.2946 52.9982 37.2946 53.5718 36.9649 53.8176ZM45.3713 53.8176C45.0416 54.1454 44.5471 54.1454 44.2174 53.8176L43.723 53.3259V61.9301C43.723 62.4217 43.3933 62.7495 42.8988 62.7495C42.4043 62.7495 42.0747 62.4217 42.0747 61.9301V53.4079L41.5802 53.8995C41.2505 54.2273 40.756 54.2273 40.4264 53.8995C40.0967 53.5718 40.0967 53.0801 40.4264 52.7523L42.3219 50.8676C42.6516 50.5398 43.1461 50.5398 43.4757 50.8676L45.3713 52.7523C45.7009 52.9982 45.7009 53.5718 45.3713 53.8176Z'
                fill='#2297FF'
            />
        </svg>
    )
}
