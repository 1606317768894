import chevronDown from '@library/images/icons/chevron-down.svg';
import { Button } from 'gokwik-ui-kit';

const CheckoutStepsHeader = ({step, heading, toggleStep, stepId, currentStep}) => {
  return (
    <div style={{ backgroundColor: currentStep < stepId ? "rgba(249, 249, 251, 1)" : "#fff", opacity: currentStep < stepId ? 0.70 : 1 }} className="flex rounded-lg justify-between p-[0.8rem_1rem]">
      <div>
        <p className="mb-1 text-[#004B8D] text-xs font-semibold">
            {step}
        </p>
        <span className={'text-lg font-semibold'}>
            {heading}
        </span>
      </div>
      <Button disabled={currentStep < stepId}  onClick={() => toggleStep(stepId)} className="border-none p-[0.25rem]">
        <img src={chevronDown} ></img>
      </Button>
    </div>
  );
};

export default CheckoutStepsHeader;
