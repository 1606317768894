import { useState, ReactNode, useMemo, useRef, useEffect, useCallback } from 'react'
import { Layout, Menu, Select } from 'gokwik-ui-kit'
import { useNavigate, Routes, Route, useLocation } from 'react-router-dom'
import GlobalTerminals from './payments/global-terminals'
import GlobalConfig from './shopify-app/global-config'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getMerchantDetails, getUserDetails} from '@store/user/selectors'
import { useSelector } from 'react-redux'
import GlobalRoutes from './payments/global-routes'

const { Sider } = Layout

interface MenuItem {
    key: string
    label: ReactNode
    icon?: ReactNode
    children?: MenuItem[]
    type?: 'group'
}

function createMenuItem(label: ReactNode, key: string, children?: MenuItem[], type?: 'group'): MenuItem {
    return {
        key,
        children,
        label,
        type,
    }
}
const globalSettingsOptions = [
    {
        label: 'Payments',
        value: 'payments',
    },
    {
        label: 'Shopify Apps',
        value: 'shopify-apps',
    },
]
export default function () {
    const route = useLocation()
    const navigate = useNavigate()
    const previousOpenedItems = useRef({
        openedKey: '',
        selectedKey: '',
    })

    const [menuKey, setMenuKey] = useState('payments')

    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)

    const settingsList = useMemo(
        () => [
            ...(menuKey === 'payments'
                ? [
                      {
                          title: 'Global Terminals',
                          path: '/global-terminals',
                          component: GlobalTerminals,
                          hideInMenu: false,
                      },
                      {
                          title: 'Global Routes',
                          path: '/global-routes',
                          component: GlobalRoutes,
                          hideInMenu: false,
                      },
                  ]
                : []),
            ...(menuKey === 'shopify-apps'
                ? [
                        {
                            title: 'Global Config',
                            path: '/global-config',
                            component: GlobalConfig,
                            hideInMenu: false,
                        },
                    ]
                : []),
        ],
        [menuKey],
    )

    useEffect(() => {
        logEvent('global_settings_clicked', 'click', 'Global Settings', userDetails?.email, merchantDetails?.m_id, merchantDetails?.short_name, userDetails?.name);
    }, [])

    const menuItems = useCallback(() => {
        const items = settingsList.reduce(
            (result, item) => [
                ...result,
                ...(item?.hideInMenu ? [] : [createMenuItem(item.title, `/global-settings${item.path}`)]),
            ],
            [],
        )
        return items
    }, [settingsList])

    const openItems = useMemo(() => {
        const menu = menuItems()

        const openedKey = menu.find((item) => {
            if (item.key === route.pathname) {
                return true
            }
            if (item.children) {
                return item.children.some((child) => child.key === route.pathname)
            }
            return false
        })

        if (openedKey) {
            previousOpenedItems.current = {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
            return {
                openedKey: openedKey.key,
                selectedKey: route.pathname,
            }
        } else if (previousOpenedItems.current.openedKey) {
            return {
                ...previousOpenedItems.current,
            }
        } else {
            let similarSelectedKey: MenuItem | undefined
            const similarKey = menu.find(
                (item) =>
                    !!item.children?.find((child) => {
                        if (route.pathname.includes(child.key)) {
                            similarSelectedKey = child
                            return true
                        } else return false
                    }),
            )
            previousOpenedItems.current = {
                openedKey: similarKey?.key || '',
                selectedKey: similarSelectedKey?.key || '',
            }
            return {
                openedKey: similarKey?.key || '',
                selectedKey: similarSelectedKey?.key || '',
            }
        }
    }, [route.pathname])

    const handleMenuSelection = ({ key }: any) => {
        navigate(key)
    }

    useEffect(() => {
        if (route.pathname === '/global-settings' && settingsList.length > 0) {
            navigate(`/global-settings${settingsList[0].path}`)
        } else if (settingsList.length > 0) {
            const currentPath = route.pathname.replace('/global-settings', '')
            const matchingRoute = settingsList.find(route => route.path === currentPath)
            if (matchingRoute) {
                navigate(`/global-settings${matchingRoute.path}`)
            } else {
                // If no matching route is found, navigate to the first item in the settingsList
                navigate(`/global-settings${settingsList[0].path}`)
            }
        }
    }, [route.pathname, settingsList])

    function handleMenuChange(value) {
        setMenuKey(value)
    }

    return (
        <div className='w-full bg-white h-full'>
            <Layout className='h-full'>
                <Sider
                    className='bg-white h-full mr-4 border-0 border-gray-100 border-r-2 fixed overflow-hidden'
                    style={{ background: 'white' }}
                >
                    <Select
                        className='w-full mb-1'
                        value={menuKey}
                        onChange={(value) => handleMenuChange(value)}
                        options={globalSettingsOptions}
                    />
                    <div className='setting-menu-custom-css'>
                        <Menu
                            onClick={handleMenuSelection}
                            openKeys={[openItems.openedKey]}
                            selectedKeys={[openItems.selectedKey]}
                            items={menuItems()}
                            inlineCollapsed={true}
                            mode='inline'
                        />
                    </div>
                </Sider>
                <Layout className='overflow-auto'>
                    <Routes>
                        {settingsList.map((item, index) => (
                            <Route key={index} path={item.path} element={<item.component />} />
                        ))}
                    </Routes>
                </Layout>
            </Layout>
        </div>
    )
}
