import { getMerchantDetails } from '@store/user/selectors'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { navigateToUrl } from 'single-spa'

type Props = {}

export default function index({}: Props) {
    const merchant_details = useSelector(getMerchantDetails)

    useEffect(() => {
        if (
            merchant_details?.latest_app_onboarding_in_progress &&
            merchant_details?.latest_app_onboarding_in_progress === 'setup_completed'
        ) {
            navigateToUrl('/kwikpass/configuration')
        }
    }, [merchant_details])

    return <div className='mt-32'>Kwikpass Onboarding Going On Here</div>
}
