// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vSdqDPuXQdmanIs3isOI {
    .ant-select-item-option-state {
        display: none !important;
    }
}

:where(.b6rPrpiBLW3WUvU7bRPg).xOuRJCTxBVoGkEFk6ZRn .p16Yk6ieVnSL2IG6JrnL {
    color: blue !important;
}

.WZwbTrbZaRJV680qzexd {
    color: var(--primary-blue-600);
    font-weight: 500;
   
}

._7m3YyhdQKYDBtYs6EpZ {
    color: white;
    border: 10;
    width: 19% ;
}

.hvnOywtQedvSRQsi_s2w {
    grid-column: 10;
    grid-row: 1;
    position: relative;
   
    border: 10;
}
`, "",{"version":3,"sources":["webpack://./src/library/components/filter-creator/filter-creator.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,wBAAwB;IAC5B;AACJ;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,8BAA8B;IAC9B,gBAAgB;;AAEpB;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,WAAW;AACf;;AAEA;IACI,eAAe;IACf,WAAW;IACX,kBAAkB;;IAElB,UAAU;AACd","sourcesContent":[".optionFilter {\n    :global(.ant-select-item-option-state) {\n        display: none !important;\n    }\n}\n\n:where(.css-dev-only-do-not-override-i0102m).ant-select-multiple .ant-select-selection-placeholder {\n    color: blue !important;\n}\n\n.filterCreator {\n    color: var(--primary-blue-600);\n    font-weight: 500;\n   \n}\n\n.addMoreFilter {\n    color: white;\n    border: 10;\n    width: 19% ;\n}\n\n.addMoreFilterButton {\n    grid-column: 10;\n    grid-row: 1;\n    position: relative;\n   \n    border: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionFilter": `vSdqDPuXQdmanIs3isOI`,
	"css-dev-only-do-not-override-i0102m": `b6rPrpiBLW3WUvU7bRPg`,
	"ant-select-multiple": `xOuRJCTxBVoGkEFk6ZRn`,
	"ant-select-selection-placeholder": `p16Yk6ieVnSL2IG6JrnL`,
	"filterCreator": `WZwbTrbZaRJV680qzexd`,
	"addMoreFilter": `_7m3YyhdQKYDBtYs6EpZ`,
	"addMoreFilterButton": `hvnOywtQedvSRQsi_s2w`
};
export default ___CSS_LOADER_EXPORT___;
