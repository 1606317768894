import { CheckCircleFilled, Steps } from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import { KPStepConfiguration } from '@pages/onboarding/stepsConfig'
import { PricingPlan } from './PricingPlan'
import { Activation } from './Activation'
import { Integration_1 } from './Integration_1'
import { Integration_2 } from './Integration_2'
import { getMerchantDetails, getUserData } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

export const KPSteps = () => {
    const [current, setCurrent] = useState(0)
    const [currentIntegrationScreen, setCurrentIntegrationScreen] = useState('')
    const [manualThemeScript, setManualThemeScript] = useState('')
    const merchant_details = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserData)
    const [manualThemeId, setManualThemeId] = useState(null)

    const stepsList = [
        {
            title: 'One click',
            subTitle: (
                <span className='flex flex-col gap-1 '>
                    <span className='text-xs'>Step 1</span>
                    <span className='text-base'>Pricing Plan</span>
                </span>
            ),
            description: <span className='text-[2px]'>One click</span>,
        },
        {
            title: '30-60 Sec',
            subTitle: (
                <span className='flex flex-col gap-1 '>
                    <span className='text-xs'>Step 2</span>
                    <span className='text-base'>Activation</span>
                </span>
            ),
            description: <span className='text-xs'>30-60 Sec</span>,
        },

        {
            title: '2-3 Min',
            subTitle: (
                <span className='flex flex-col gap-1 '>
                    <span className='text-xs'>Step 3</span>
                    <span className='text-base'>Integration</span>
                </span>
            ),
            description: '2-3 Min',
        },
        {
            title: '30-60 Sec',
            subTitle: (
                <span className='flex flex-col gap-1 '>
                    <span className='text-xs'>Step 4</span>
                    <span className='text-base'>Last Step</span>
                </span>
            ),
            description: '30-60 Sec',
        },
    ]

    const items = stepsList?.map((item) => ({
        key: item.title,
        title: item.title,
        description: item.description,
        subTitle: item.subTitle,
    }))

    const stepItems = stepsList?.map((item) => ({
        key: item.title,
        title: item.title,
    }))

    function customDot(dot, { status, index, description, title, subTitle }) {
        return (
            <>
                <div className='absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center cursor-pointer'>
                    {/* <p className='mb-2 text-xs text-gray-400 w-20'>{description}</p> */}
                    <p className='mb-20 text-base text-gray-500 w-44'>{items[index]?.subTitle}</p>
                </div>
                {status === 'finish' ? <CheckCircleFilled className='text-green-500' /> : dot}
            </>
        )
    }

    const fetchLatestOnboardingStatus = async () => {
        const res = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.userDetails,
            method: 'get',
            skipLoader: true,
        })
            .then((result) => {
                navigateToStep(result?.data?.data?.merchant_details?.onboarding_status?.kwikpass_app, null)
            })
            .catch((err) => {
                console.warn(err)
            })
    }

    useEffect(() => {
        fetchLatestOnboardingStatus()
    }, [])

    const navigateToStep = (kp_onboarding_status?: any, priorityStepUserWantsToJumpTo?: any) => {
        if (priorityStepUserWantsToJumpTo >= 0) {
            setCurrent(priorityStepUserWantsToJumpTo)
        }
        if (kp_onboarding_status) {
            const kpStepId = KPStepConfiguration[kp_onboarding_status]?.id
            if (kpStepId <= 3) {
                setCurrent(0)
            } else if (kpStepId > 3 && kpStepId < 5) {
                setCurrent(1)
            } else if (kpStepId === 5) {
                setCurrent(2)
            } else if (kpStepId >= 6 && kpStepId < 9) {
                if (kp_onboarding_status === 'auto_theme_extension_selected')
                    setCurrentIntegrationScreen('add_theme_automatically')
                else if (kp_onboarding_status === 'manual_theme_extension_selected') {
                    getCodeForManualTheme()
                    setCurrentIntegrationScreen('add_theme_manually')
                }
                setCurrent(3)
            }
        }
    }

    const changeSteps = (stepUserWantToGoTo) => {
        if (stepUserWantToGoTo === 0 || stepUserWantToGoTo === 3) {
            return // if user wants to go to the above 0th or 3rd steps then stop him/her
        }
        navigateToStep(null, stepUserWantToGoTo)
    }

    const getCodeForManualTheme = async () => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.KWIKPASS_DASHBOARD_BASE_URL + APIEndPoints.getSnippet,
            headers: { 'kp-merchant-id': merchant_details?.m_id },
        })

        if (response?.data?.status_code === 200) {
            logEvent(
                'kp_integration_manual',
                'click',
                'intergration_1_screen',
                userDetails?.userDetails?.email,
                merchant_details?.m_id,
                merchant_details?.short_name,
            )
            setManualThemeScript(response?.data?.data?.data)
            setManualThemeId(response?.data?.data?.theme_id)
        }
    }

    const stepContentMap = {
        0: <PricingPlan navigateToStep={navigateToStep} />,
        1: <Activation navigateToStep={navigateToStep} />,
        2: (
            <Integration_1
                setCurrentIntegrationScreen={setCurrentIntegrationScreen}
                navigateToStep={navigateToStep}
                getCodeForManualTheme={getCodeForManualTheme}
            />
        ),
        3: (
            <Integration_2
                currentIntegrationScreen={currentIntegrationScreen}
                setCurrentIntegrationScreen={setCurrentIntegrationScreen}
                manualThemeScript={manualThemeScript}
                manualThemeId={manualThemeId}
            />
        ),
    }

    return (
        <>
            <div className='mt-12 p-8'>
                <Steps
                    onChange={changeSteps}
                    className='kp-onboarding-steps'
                    current={current}
                    items={stepItems}
                    progressDot={customDot}
                />
            </div>

            <div>{stepContentMap[current]}</div>

            <div className='text-sm text-center flex items-end justify-center mt-12'>
                <span>
                    <span className='text-slate-500'>If you need any assistance, please reach us at</span>{' '}
                    <a className='font-semibold text-blue-500' href='mailto:kwikpass@gokwik.co'>
                        kwikpass@gokwik.co
                    </a>
                </span>
            </div>
        </>
    )
}
