import React, { useState } from 'react';
import { Button, Drawer } from 'gokwik-ui-kit'
import onlyCodPlan from '@library/images/icons/onlyCodPlan.mp4'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux';

const BasicPlanDrawer = ({drawerVisible, setDrawerVisible}) => {
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)

    const closeDrawer = () => {
        logEvent(
            'preview_closed',
            'click',
            'get_started',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { plan: 'cod' }
        )
        setDrawerVisible(false);
    }

    return (
        <Drawer
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <div style={{ color: "rgba(0, 110, 210, 1)", fontSize: '12px', fontWeight: '700' }}>Basic Plan</div>
                        <div style={{ fontSize: '24px', fontWeight: '700', marginBottom: '-10px' }}>COD Suite</div>
                    </div>
                    <Button type="text" onClick={closeDrawer} style={{fontSize: '16px', color: '#000'}}>✕</Button>
                </div>
            }
            open={drawerVisible}
            footer={
                <div style={{ padding: '0.5rem 0rem' }}>
                    <Button onClick={closeDrawer} variant="primary">
                        Understood
                    </Button>
                </div>
            }
            width={585}
            closable={false}
            onClose={closeDrawer}
            style={{
                borderTopLeftRadius: '16px',
                borderBottomLeftRadius: '16px',
            }}
        >
            <div className='mb-5' style={{ color: 'rgba(0, 110, 210, 1)', fontWeight: '500' }}>See how it will look on your Shopify store page</div>
            <div className='mb-10' style={{ width: '100%', textAlign: 'center' }}>
                <video src={onlyCodPlan} autoPlay loop muted style={{ width: '100%' }} />
            </div>
            <div className='mb-5'>
                <div className='mb-3 font-semibold' style={{ fontSize: "20px"}}>GoKwik cash on delivery</div>
                <ul style={{ fontSize: "12px", padding: 0, margin: 0, listStyleType: 'none' }}>
                    <li className='mb-2'>•  Enable COD Orders</li>
                    <li className='mb-2'>•  COD Interventions like COD OTP for Extra Security</li>
                    <li className='mb-2'>•  Show/Hide Payment Based on Cart, Customers, Products, Location and More</li>
                </ul>
            </div>
        </Drawer>
    )
};

export default BasicPlanDrawer;
