const KwikPassIcon = (props) => (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
        <path
            d='M15.98 4.4209C18.96 5.8909 21 8.9509 21 12.5009C21 17.4709 16.97 21.5009 12 21.5009C11.4 21.5009 10.81 21.4409 10.23 21.3309'
            stroke='black'
            stroke-width='2'
            stroke-miterlimit='10'
        />
        <path
            d='M8.02 20.571C5.04 19.101 3 16.041 3 12.491C3 7.53098 7.03 3.50098 12 3.50098C12.6 3.50098 13.19 3.56098 13.76 3.67098'
            stroke='black'
            stroke-width='2'
            stroke-miterlimit='10'
        />
        <path
            d='M14.2801 10.8204L16.3401 3.06042C16.5501 2.26042 15.5501 1.71042 14.9901 2.33042L5.74015 12.5004C5.15015 13.1404 5.61015 14.1804 6.48015 14.1804H9.72015L7.66015 21.9404C7.45015 22.7404 8.45015 23.2904 9.01015 22.6704L18.2601 12.4904C18.8501 11.8504 18.3901 10.8104 17.5201 10.8104H14.2801V10.8204Z'
            stroke='black'
            stroke-width='2'
            stroke-miterlimit='10'
            stroke-linecap='round'
            stroke-linejoin='round'
        />
    </svg>
)
export default KwikPassIcon
