import {
    BoldOutlined,
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    InboxOutlined,
    Input,
    ItalicOutlined,
    message,
    OrderedListOutlined,
    Radio,
    Row,
    Select,
    SmileFilled,
    Switch,
    TextArea,
    Tooltip,
    UnderlineOutlined,
    Upload,
    UploadOutlined,
} from 'gokwik-ui-kit'
import { configs, editorFormConfig, TopBannerRadio, XSellBannerRadio } from '../utils'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import React, { useEffect, useState } from 'react'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import dayjs from 'dayjs'
import { handleError } from '@library/utilities/helpers/handleError'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

const { Option } = Select

const Editor = ({ campaignType, setCampaignType, campaignConfigs, setCampaignConfigs, setPreviewImage }) => {
    const [form] = Form.useForm()
    const [segmentsList, setSegmentList] = useState([])
    const [mediaURL, setMediaURL] = useState('')
    const [switchValues, setSwitchValues] = useState({
        is_dismissable: false,
        is_dismissed_permanently: false,
    })
    const [mediaUrlLink, setMediaUrlLink] = useState('')
    const [isEmojiPickerVisible, setEmojiPickerVisible] = useState(false)
    const [activeFormats, setActiveFormats] = useState<string[]>([])
    const [addMedia, setAddMedia] = useState(false)

    useEffect(() => {
        if (mediaUrlLink) {
            setCampaignConfigs((prevValues) => ({
                ...prevValues,
                [campaignType]: {
                    ...prevValues[campaignType],
                    media_url: mediaUrlLink,
                },
            }))
        }
    }, [mediaUrlLink])

    const toggleEmojiPicker = () => {
        setEmojiPickerVisible(!isEmojiPickerVisible)
    }

    const getSegments = async () => {
        const response = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.marketingCampaigns.getSegments,
            method: 'get',
            skipLoader: true,
        })
        if (response?.status === 200) {
            setSegmentList(response?.data?.data)
        }
    }
    async function getCampaignById(id) {
        if (!id) return
        try {
            let response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + APIEndPoints.marketingCampaigns.campaignById(id),
            })

            if (response?.status === 200) {
                const campaignData = response?.data?.data

                if (campaignData?.start_date) {
                    campaignData.start_date = dayjs(campaignData.start_date)
                }
                if (campaignData?.end_date) {
                    campaignData.end_date = dayjs(campaignData.end_date)
                }
                if (campaignData?.description) {
                    const editor = document.querySelector('.wysiwyg-editor')
                    if (editor) {
                        editor.innerHTML = campaignData?.description
                    }
                    campaignData.description = (
                        <div dangerouslySetInnerHTML={{ __html: campaignData?.description }}></div>
                    )
                }
                if (campaignData?.product_name) {
                    campaignData.product_name = campaignData?.product_name?.split(', ')
                }
                if (campaignData?.segment_id && campaignData?.segment?.value) {
                    setSegmentList([{ id: campaignData?.segment_id, name: campaignData?.segment?.value }])
                }

                if (campaignData?.media_url) {
                    setMediaUrlLink(campaignData?.media_url)
                }

                setCampaignType(campaignData.banner_type)
                setCampaignConfigs((prev) => ({
                    ...prev,
                    [campaignData.banner_type]: campaignData,
                }))

                form.setFieldsValue(campaignData)
            }
        } catch (error) {
            handleError(error)
        }
    }

    const getCampaignByIdFromURL = () => {
        const parts = window.location.href.split('/')
        const idIndex = parts.indexOf('edit-campaign') + 1
        return idIndex > 0 && idIndex < parts.length ? parts[idIndex] : null
    }
    useEffect(() => {
        if (window?.location?.href?.includes('/general/edit-campaign')) {
            if (getCampaignByIdFromURL()) {
                getCampaignById(getCampaignByIdFromURL())
            }
            return
        }
        getSegments()
    }, [])

    const onCampaignSelect = (campaignType) => {
        setCampaignType(campaignType)
    }

    const onFieldsChange = (v) => {
        const { name, value } = v[0]
        console.log(campaignType, name, value, campaignConfigs[campaignType], '99')

        setCampaignConfigs((prevValues) => ({
            ...prevValues,
            [campaignType]: {
                ...prevValues[campaignType],
                [name[0]]: value,
            },
        }))
    }

    const onFormSubmit = async (formValue) => {
        if (formValue?.description?.props?.dangerouslySetInnerHTML?.__html) {
            formValue.description = formValue?.description?.props?.dangerouslySetInnerHTML?.__html
        }

        formValue.start_date = dayjs(formValue.start_date).format('YYYY-MM-DDTHH:mm:ss[Z]')
        formValue.end_date = dayjs(formValue.end_date).format('YYYY-MM-DDTHH:mm:ss[Z]')
        formValue.is_active = true
        // formValue.is_dismissable = switchValues?.is_dismissable
        // formValue.is_dismissed_permanently = switchValues?.is_dismissed_permanently
        formValue.banner_type = campaignType
        if (mediaUrlLink && (campaignType === 'recommended' || campaignType === 'modal')) {
            formValue.media_url = mediaUrlLink
        }

        if (formValue?.product_name?.length >= 1) {
            formValue.product_name = formValue?.product_name?.join(', ')
        }

        if (campaignType === 'recommended') {
            formValue.segment_id = '00000000-0000-0000-0000-000000000000'
        }

        if (window?.location?.href?.includes('edit')) {
            formValue.id = Number(getCampaignByIdFromURL())
            formValue.formValue = segmentsList[0]?.id
        }

        let api_url = window?.location?.href?.includes('edit')
            ? APIEndPoints.marketingCampaigns.updateCampaign(getCampaignByIdFromURL())
            : APIEndPoints.marketingCampaigns.createCampaign

        const response = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + api_url,
            method: window?.location?.href?.includes('edit') ? 'patch' : 'post',
            payload: formValue,
        })

        if (response?.success) {
            if (!window?.location?.href?.includes('edit')) {
                form.setFieldsValue({
                    name: '',
                    title: '',
                    description: '',
                    product_name: [],
                    segment_id: '',
                    type: '',
                    start_date: null,
                    end_date: null,
                    cta_text: '',
                    cta_url: '',
                    is_dismissable: false,
                    is_dismissed_permanently: false,
                })
                message.success('Campaign created successfully')
            } else {
                message.success('Campaign updated successfully')
            }
        }
    }

    function removeQueryAndOrigin(url) {
        const urlObj = new URL(url)
        return urlObj.href.split('.com/')[1].split('?')[0]
    }

    const getSignedUrl = async () => {
        const timestamp = Date.now()
        const awsPath = 'dashboard_campaigns/' + campaignType + '/creative_image/' + timestamp
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + '/api/dashboard/utility/presigned-url',
            payload: {
                path: awsPath,
                type: 'logo',
            },
        })
        if (response.success) {
            return { url: response.data.data.url, awsPath: awsPath }
        }
    }

    const handleUpload = async (info) => {
        const signedURl = await getSignedUrl()
        setMediaURL(signedURl?.url)
        const awsResponse = await fetch(signedURl?.url, {
            method: 'PUT',
            body: info.file,
        })
        if (awsResponse.status === 200) {
            message.success('Logo Uploaded Successfully')
        } else {
            message.error('Logo upload failed')
        }

        const reader = new FileReader()
        reader.onload = (e) => {
            if (e.target?.result) {
                setPreviewImage(e.target.result as string)
            }
        }
        reader.readAsDataURL(info.file)
        setMediaUrlLink(`https://${removeQueryAndOrigin(signedURl?.url)}`)
    }

    const handleSwitchChange = (switchName, value) => {
        setSwitchValues((prevValues) => ({
            ...prevValues,
            [switchName]: value,
        }))
    }

    const validateWordCount = (maxWords: number, message: string) => ({
        validator: (_: any, value: string) => {
            const wordCount = value ? value.trim().split(/\s+/).length : 0
            if (wordCount > maxWords) {
                return Promise.reject(new Error(message))
            }
            return Promise.resolve()
        },
    })
    const insertEmoji = (emoji) => {
        const editor = document.querySelector('.wysiwyg-editor')
        const selection = window.getSelection()
        if (!selection.rangeCount) return

        const range = selection.getRangeAt(0)
        range.deleteContents()

        const emojiNode = document.createTextNode(emoji.native)
        range.insertNode(emojiNode)

        // Move the cursor to the end of the inserted emoji
        range.setStartAfter(emojiNode)
        range.setEndAfter(emojiNode)
        selection.removeAllRanges()
        selection.addRange(range)

        // Trigger input event to update the form value
        const event = new Event('input', { bubbles: true })
        editor.dispatchEvent(event)
    }

    const handleCommand = (command, other?: any) => {
        document.execCommand(command, false, null)
        setActiveFormats((prevFormats) => {
            if (prevFormats.includes(command)) {
                return prevFormats.filter((format) => format !== command)
            } else {
                return [...prevFormats, command]
            }
        })
    }

    const handleEditorInput = (event) => {
        const htmlContent = event.currentTarget.innerHTML

        setCampaignConfigs((prevValues) => ({
            ...prevValues,
            [campaignType]: {
                ...prevValues[campaignType],
                description: (
                    <>
                        <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
                    </>
                ),
            },
        }))
        form.setFieldsValue({ description: htmlContent })
    }

    const handleImageRemove = () => {
        setCampaignConfigs((prevValues) => ({
            ...prevValues,
            [campaignType]: {
                ...prevValues[campaignType],
                media_url: '',
            },
        }))
    }

    return (
        <div className='bg-white w-full p-4 rounded-md'>
            {campaignConfigs[campaignType] && (
                <Form
                    form={form}
                    initialValues={campaignConfigs[campaignType]}
                    onFinish={(formValue) => {
                        onFormSubmit(formValue)
                    }}
                    layout='vertical'
                    requiredMark={false}
                    onFieldsChange={(v) => {
                        onFieldsChange(v)
                    }}
                    scrollToFirstError
                >
                    <div className='flex flex-col gap-1 pb-4'>
                        <span className='font-semibold text-sm'>Banner Type</span>

                        <span className='font-normal text-xs text-[#00000073]'>
                            Select banner type you want to create.
                        </span>
                    </div>
                    <div
                        className={`flex justify-between ${
                            window?.location?.href?.includes('edit') && 'pointer-events-none cursor-none'
                        }`}
                    >
                        <div>
                            <div
                                className={`cursor-pointer scale-90 ${
                                    campaignType === 'small' &&
                                    'flex items-center border-2 border-blue-400 border-solid rounded-lg'
                                }`}
                                onClick={() => onCampaignSelect('small')}
                            >
                                <TopBannerRadio />
                            </div>
                            <span className='text-xs font-semibold ml-3'>Small Banner</span>
                        </div>

                        <div>
                            <div
                                className={`cursor-pointer scale-90 ${
                                    campaignType === 'modal' &&
                                    'flex items-center border-2 border-blue-400 border-solid rounded-lg'
                                }`}
                                onClick={() => onCampaignSelect('modal')}
                            >
                                <XSellBannerRadio />
                            </div>
                            <span className='text-xs font-semibold ml-3'>Modal Banner</span>
                        </div>

                        <div>
                            <div
                                className={`cursor-pointer scale-90 ${
                                    campaignType === 'recommended' &&
                                    'flex items-center border-2 border-blue-400 border-solid rounded-lg'
                                }`}
                                onClick={() => onCampaignSelect('recommended')}
                            >
                                <XSellBannerRadio />
                            </div>
                            <span className='text-xs font-semibold ml-3'>Recommended Section</span>
                        </div>
                    </div>

                    <Divider />

                    <Form.Item
                        label='Product Type'
                        name='product_name'
                        rules={[{ required: true, message: 'Please select a product type' }]}
                    >
                        <Select
                            placeholder='Select Product Type'
                            mode='multiple'
                            disabled={window?.location?.href?.includes('edit')}
                        >
                            {[
                                { label: 'KwikCheckout', value: 'checkout' },
                                { label: 'KwikPass', value: 'kwik_pass' },
                                { label: 'KwikPayment', value: 'payments' },
                                { label: 'RTO', value: 'rto' },
                                { label: 'KwikShip', value: 'kwikship' },
                                { label: 'KwikShip Shopify OMS', value: 'kwikship_shopify_o_m_s' },
                                { label: 'KwikCart', value: 'kwik_cart' },
                            ]?.map((item, i) => (
                                <React.Fragment key={i}>
                                    <Option value={item?.value}>{item?.label}</Option>
                                </React.Fragment>
                            ))}
                        </Select>
                    </Form.Item>

                    <Row gutter={16}>
                        {campaignType !== 'recommended' && (
                            <Col span={12}>
                                <Form.Item
                                    label='Target Segment'
                                    name='segment_id'
                                    rules={[{ required: true, message: 'Please select a target segment' }]}
                                >
                                    <Select
                                        filterSort={null}
                                        placeholder={'Select Segment'}
                                        disabled={window?.location?.href?.includes('edit')}
                                    >
                                        {segmentsList?.map((option, index) => (
                                            <Select.Option key={index} value={option?.id}>
                                                <span className='capitalize'>{option?.name}</span>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                        <Col span={12}>
                            <Form.Item
                                label='Update Type'
                                name='type'
                                rules={[{ required: true, message: 'Please select an update type' }]}
                            >
                                <Select
                                    placeholder='Select Update Type'
                                    disabled={window?.location?.href?.includes('edit')}
                                >
                                    <Option value='new_release'>New Releases</Option>
                                    <Option value='feature_release'>Feature Release</Option>
                                    <Option value='campaign'>Campaign</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Form.Item
                        label={
                            <div className='flex flex-col gap-1 pb-4'>
                                <span className='font-semibold text-sm'>Title & Description</span>
                            </div>
                        }
                        style={{ marginBottom: 0 }}
                    >
                        <Col span={24}>
                            <Form.Item
                                label='Update Name'
                                name='name'
                                rules={[
                                    { required: true, message: 'Please enter an update name' },
                                    validateWordCount(5, 'Maximum 5 words allowed'),
                                ]}
                            >
                                <Input placeholder='Enter Update Name' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label='Banner Title'
                                name='title'
                                rules={[
                                    { required: true, message: 'Please enter a banner title' },
                                    validateWordCount(15, 'Maximum 15 words allowed'),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                label='Description'
                                name='description'
                                rules={[
                                    { required: true, message: 'Please enter a description' },
                                    // validateWordCount(150, 'Maximum 150 words allowed'),
                                ]}
                            >
                                <div className='wysiwyg-toolbar w-0 flex gap-1 scale-75'>
                                    <Tooltip title='Bold'>
                                        <Button
                                            variant={activeFormats.includes('bold') ? 'primary' : 'default'}
                                            onClick={() => handleCommand('bold')}
                                        >
                                            <b>B</b>
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title='Italic'>
                                        <Button
                                            variant={activeFormats.includes('italic') ? 'primary' : 'default'}
                                            onClick={() => handleCommand('italic')}
                                        >
                                            <ItalicOutlined />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title='Underline'>
                                        <Button
                                            variant={activeFormats.includes('underline') ? 'primary' : 'default'}
                                            onClick={() => handleCommand('underline')}
                                        >
                                            <UnderlineOutlined />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title='Unordered List'>
                                        <Button
                                            variant={
                                                activeFormats.includes('insertUnorderedList') ? 'primary' : 'default'
                                            }
                                            onClick={() => handleCommand('insertUnorderedList')}
                                        >
                                            <OrderedListOutlined />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip title='Paragraph'>
                                        <Button
                                            variant={activeFormats.includes('formatBlock') ? 'primary' : 'default'}
                                            onClick={() => handleCommand('formatBlock', '<p>')}
                                        >
                                            P
                                        </Button>
                                    </Tooltip>
                                    {/* <div className='relative'>
                                        <Tooltip title='Emoji Picker'>
                                            <Button
                                                variant={isEmojiPickerVisible ? 'primary' : 'default'}
                                                onClick={toggleEmojiPicker}
                                            >
                                                <SmileFilled />
                                            </Button>
                                        </Tooltip>
                                        {isEmojiPickerVisible && (
                                            <div className='emoji-picker-dropdown absolute z-50'>
                                                <Picker
                                                    data={data}
                                                    onEmojiSelect={insertEmoji}
                                                    onClickOutside={() => setEmojiPickerVisible(true)}
                                                />
                                            </div>
                                        )}
                                    </div> */}
                                </div>

                                <div
                                    contentEditable
                                    className='wysiwyg-editor border-solid border-gray-200 rounded-lg overflow-y-auto min-h-40 max-h-40 mt-4 p-2'
                                    onInput={handleEditorInput}
                                ></div>
                            </Form.Item>
                        </Col>
                    </Form.Item>

                    {(campaignType === 'modal' || campaignType === 'recommended') && (
                        <>
                            {campaignType === 'recommended' && (
                                <div className='flex gap-2'>
                                    <label htmlFor=''>Do you want to add image ?</label>
                                    <Radio.Group
                                        onChange={(e) => setAddMedia(e.target.value)}
                                        value={addMedia}
                                        disabled={window?.location?.href?.includes('edit')}
                                    >
                                        <Radio value={true}>Yes</Radio>
                                        <Radio value={false}>No</Radio>
                                    </Radio.Group>
                                    <br />
                                    <br />
                                </div>
                            )}
                            <Form.Item
                                label={
                                    <div className='flex flex-col gap-1 pb-4'>
                                        <span className='font-semibold text-sm'>Media/Image</span>

                                        <span className='font-normal text-xs text-[#00000073]'>
                                            Banner Image (Optional)
                                        </span>
                                    </div>
                                }
                                className={campaignType === 'recommended' && !addMedia && 'hidden'}
                            >
                                <Upload.Dragger
                                    accept='.png,.jpeg,.jpg'
                                    name='file'
                                    maxCount={1}
                                    multiple={false}
                                    customRequest={(info) => {
                                        info.onSuccess('')
                                        handleUpload(info)
                                    }}
                                    onRemove={handleImageRemove}
                                >
                                    <p className='ant-upload-drag-icon'>
                                        <InboxOutlined />
                                    </p>
                                    <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                                    <p className='ant-upload-hint'>
                                        Supports single upload of .jpeg or .png files only.
                                    </p>
                                </Upload.Dragger>
                            </Form.Item>
                        </>
                    )}

                    <Divider />

                    <Form.Item
                        label={
                            <div className='flex flex-col gap-1 pb-4'>
                                <span className='font-semibold text-sm'>Notification Duration</span>

                                <span className='font-normal text-xs text-[#00000073]'>
                                    Setup the duration of the banner you want to show
                                </span>
                            </div>
                        }
                        style={{ marginBottom: 0 }}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label='Start Date'
                                    name='start_date'
                                    rules={[
                                        { required: true, message: 'Please select a start date' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    !getFieldValue('end_date') ||
                                                    value.isBefore(getFieldValue('end_date'))
                                                ) {
                                                    return Promise.resolve()
                                                }
                                                return Promise.reject(new Error('Start date must be before end date'))
                                            },
                                        }),
                                    ]}
                                >
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        format='DD/MM/YYYY HH:mm A'
                                        showTime={{
                                            showHour: true,
                                            showMinute: true,
                                        }}
                                        className='w-full'
                                        placeholder='Select date & time'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label='End Date'
                                    name='end_date'
                                    rules={[
                                        { required: true, message: 'Please select an end date' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    !getFieldValue('start_date') ||
                                                    value.isAfter(getFieldValue('start_date'))
                                                ) {
                                                    return Promise.resolve()
                                                }
                                                return Promise.reject(new Error('End date must be after start date'))
                                            },
                                        }),
                                    ]}
                                >
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        format='DD/MM/YYYY HH:mm A'
                                        showTime={{
                                            showHour: true,
                                            showMinute: true,
                                        }}
                                        className='w-full'
                                        placeholder='Select date & time'
                                        disabledDate={(current) => current && current < dayjs().startOf('day')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>

                    <Divider />

                    <Form.Item
                        label={
                            <div className='flex flex-col gap-1 pb-4'>
                                <span className='font-semibold text-sm'>CTA (Call to action)</span>
                            </div>
                        }
                        style={{ marginBottom: 0 }}
                    >
                        <Form.Item
                            label='Button Title'
                            name='cta_text'
                            rules={[{ required: true, message: 'Please enter a button title' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label='CTA Link'
                            name='cta_url'
                            rules={[
                                { required: true, message: 'CTA Link/URL required' },
                                { type: 'url', message: 'Please enter a valid URL' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Form.Item>

                    <Divider />
                    {(campaignType === 'small' || campaignType === 'modal') && (
                        <>
                            <Form.Item
                                label={
                                    <div className='flex flex-col gap-1 pb-4'>
                                        <span className='font-semibold text-sm'>Dismissable Banner?</span>

                                        <span className='font-normal text-xs text-[#00000073]'>
                                            Toggle on to make your banner dismissible, valid only for the current login
                                            session
                                        </span>
                                    </div>
                                }
                                name='is_dismissable'
                                valuePropName='checked'
                            >
                                <Switch disabled={window?.location?.href?.includes('edit')} />
                            </Form.Item>

                            <Form.Item
                                label={
                                    <div className='flex flex-col gap-1 pb-4'>
                                        <span className='font-semibold text-sm'>Don’t show me again</span>

                                        <span className='font-normal text-xs text-[#00000073]'>
                                            If this is clicked, the campaign won’t be shown again after being dismissed
                                        </span>
                                    </div>
                                }
                                name='is_dismissed_permanently'
                                valuePropName='checked'
                            >
                                <Switch disabled={window?.location?.href?.includes('edit')} />
                            </Form.Item>
                            <Divider />
                        </>
                    )}
                    <Form.Item className='flex justify-end mb-0 w-full'>
                        <Button variant='primary' htmlType='submit'>
                            Post Banner
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    )
}

export default Editor
