import React, { useEffect, useState } from 'react'
import {
    message,
    Timeline,
    SettingOutlined,
} from 'gokwik-ui-kit'
import dayjs from 'dayjs'
import { filterDateFormatter, makeAPICall, updateBreadcrumbs } from '@gokwik/utilities'
import RenderSearchFilters from '@library/components/search-filter'
import { useSelector } from 'react-redux'
import { getMerchantDetails, getUserDetails} from '@store/user/selectors'
import { previousTimelineFilters } from '@store/filters/selectors'
import { useAppDispatch } from '@library/utilities/hooks'
import { saveFiltersData } from '@store/filters'

import './timeline.css'
import { timeLineData } from './timeLineFormat'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

const today = new Date();
const formattedDate = filterDateFormatter(today)
export default function () {
    const prevFilters = useSelector(previousTimelineFilters)
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)
    const dispatch = useAppDispatch()
    const [data, setTimelineData] = useState([])
    const [parameters, setParameters] = useState({
        page: 0,
        page_size: 100,
        end_date: formattedDate,
        start_date: formattedDate,
        modified_by: [],
        property_name: [],
        ...prevFilters,
    })

    useEffect(() => {
        if(parameters.start_date && parameters.end_date){
            getTimeLineData()
        }
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'timeline',
                href: '/general/timeline',
                text: 'Timeline',
            },
        ])
    }, [])

    useEffect(() => {
        logEvent('admin_timeline_clicked', 'click', 'Admin Timeline', userDetails?.email, merchantDetails?.m_id, merchantDetails?.short_name, userDetails?.name);
    }, [])

    const reset = (e) => {
        e.preventDefault()
        setParameters({
            page: 0,
            page_size: 100,
            end_date: formattedDate,
            start_date: formattedDate,
            modified_by: [],
            property_name: [],
        })
        dispatch(saveFiltersData('timeline', {}))
    }

    const handleSearchClick = (e) => {
        e?.preventDefault()
        //A merchant name should be selected to make api call
        if (parameters.end_date || parameters.start_date) {
            getTimeLineData()
        } else {
            message.error('Merchant name is required')
        }
    }

    const getTimeLineData = async () => {
        const res = await makeAPICall({
            url: process.env.AUDIT_SERVICEURL+ '/filter/'+ merchantDetails.id ,
            method: 'post',
            payload: {
                ...parameters,
                start_date: new Date(parameters.start_date).setHours(0, 0, 0, 0),
                end_date: new Date(parameters.end_date).setHours(23, 59, 59, 999),
            },
        })
        if (res.data.statusCode === 200) {
            setTimelineData(res.data?.data?.audit_data)
        }
    }

    return (
        <div className='w-full'>
            <div className='bg-white'>
                <RenderSearchFilters
                    values={parameters}
                    setValues={(data, reset) =>
                        setParameters((prev: any) => (reset ? { ...data } : { ...prev, ...data }))
                    }
                    page='timeline'
                    onSearch={handleSearchClick}
                    onReset={reset}
                />
            </div>
            <div className='p-10  relative overflow-auto w-full mt-5'>
                {data.length === 0 ? (
                    <div className='bg-white p-24 flex justify-center items-center flex-col w-100 border-solid border-gray-300 rounded-lg'>
                        <SettingOutlined className='text-[100px] text-gray-400 ' />
                        <div className='text-gray-700 text-lg mt-4 font-semibold'>No changes has been done in this duration.</div>
                    </div>
                ) : (
                    <Timeline items={timeLineData(data) || []} />
                )}
            </div>
        </div>
    )
}
