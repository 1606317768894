import { Layout, Row, Col } from 'gokwik-ui-kit'
import PlansSection from './upsell-flow/leftPanel';
import KYCActivation from './upsell-flow/kwikCheckoutKycPrompt';
const { Content } = Layout;

export default function CodCompletionPrompt() {
    return (
        <Layout className='pt-16'>
            <Content >
                <Row style={{ border: '4px solid #0B1B2B', height: '100%',  width: "100%", margin: "0px" }} gutter={0}>
                    <Col xs={24} sm={24} md={7} lg={7}>
                        <PlansSection heading='Kwik Checkout with COD' subHeading='Get a complete solution with COD, prepaid options, and an enhanced checkout experience.' />
                    </Col>
                    <Col xs={24} sm={24} md={17} lg={17} >
                        <KYCActivation />
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}
