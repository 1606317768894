import React, { useEffect, useId, useMemo, useRef, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Popconfirm, Row, Switch, Table, Tooltip,message } from 'gokwik-ui-kit';
import RenderInput, { inputSelector } from './formInputs';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { rangesOverlap } from '@library/utilities/helpers/helper';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  if (inputType?.input_details?.mode === 'multiple') inputType.input_details.mode = null;
  return (
    <td {...restProps} key={index}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
        >
          {/* @ts-ignore */}
          {inputSelector(inputType, () => {})}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const validateTiered = (existing, newRow :any) => {
  if (!newRow.commissionType) {
    return {
      status: false,
      message: `Comission type can't be empty`,
    };
  }
  // @ts-ignore
  if (isNaN(`${newRow.value}`)) {
    return {
      status: false,
      message: `Value can't be empty`,
    };
  }
  // @ts-ignore
  if (isNaN(`${newRow.lowerLimit}`)) {
    return {
      message: 'Please enter valid lower limit',
      status: false,
    };
  }
  if (newRow.upperLimit && newRow.lowerLimit >= newRow.upperLimit) {
    return { status: false, message: 'Upper limit should be greater than lower limit' };
  }
  if (!existing?.length) return { status: true };

  const isOverLapping = existing.reduce(
    (result, curr) =>
      rangesOverlap(
        [curr.lowerLimit, curr.upperLimit || Number.MAX_SAFE_INTEGER],
        [newRow.lowerLimit, newRow.upperLimit || Number.MAX_SAFE_INTEGER]
      ) || result,
    false
  );
  if (isOverLapping)
    return {
      status: false,
      message: 'Range already exists',
    };

  return { status: true };
};

const validateFlat = (existing, newRow) => {
  const arrayKey = Object.keys(newRow).find((key) => Array.isArray(newRow[key]));
  if (!newRow.commissionType) {
    return {
      status: false,
      message: `Comission type can't be empty`,
    };
  }
  // @ts-ignore
  if (isNaN(`${newRow.value}`)) {
    return {
      status: false,
      message: `Value can't be empty`,
    };
  }
  if (!existing.length) return { status: true };

  const exists = existing.some((itm) => newRow[arrayKey].includes(itm[arrayKey]));
  if (exists)
    return {
      status: false,
      message: 'Value exists',
    };
  return {
    status: true,
  };
};


let idCounter = 0;
const useUniqueId = () => {
    const [id] = useState(() => {
        idCounter += 1;
        return `unique-id-${idCounter}`;
    });
    return id;
};


const TieredControl = ({ details, disableAll, onChange }) => {
  const [tempData, setTempData] = useState({
    flat: [],
    gmv: [],
    order_value: [],
  });
  const [idsMapping, setIdsMapping] = useState({
    keysToIds: {},
    valueIds: {},
  });

  const tierId = useUniqueId();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [tieredValues, setTieredValues] :any = useState({ commissionType: 'percentage' });
  const [newRowKey, setNewRowKey] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [pricingType, setPricingType] = useState('flat');
  const tabularData = useMemo(() => !!details.children || pricingType !== 'flat', [details, pricingType]);
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      lowerLimit: '',
      upperLimit: '',
      commissionType: '',
      value: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  useEffect(() => {
    const initialData = details.children.reduce((result, curr, i) => {
      return [
        ...result,
        ...curr.children?.reduce((result, val, idx) => {
          setIdsMapping((prev) => ({
            keysToIds: { ...prev.keysToIds, [val.key]: val.id },
            valueIds: { ...prev.valueIds, [val.key]: val.value_id },
          }));
          return [
            ...result,
            ...(val.value
              ? val?.value?.reduce((res, itm, index) => {
                  if (itm.pricingType && pricingType !== itm.pricingType) setPricingType(itm.pricingType);
                  return [...res, { ...itm, [curr.key]: val.key, key: i + idx + index }];
                }, [])
              : []),
          ];
        }, []),
      ];
    }, []);

    if (initialData.length) setData([...initialData]);

    return () => {
      setTempData({
        flat: [],
        gmv: [],
        order_value: [],
      });
    };
  }, [details]);

  const sortedDataObj = useMemo(() => {
    if (!details.children) return {};
    const dataObj = data.reduce(
      (result, curr) => ({
        ...result,
        [curr[details.children[0].key]]: [...(result[curr[details.children[0].key]] || []), curr],
      }),
      {}
    );
    return dataObj;
  }, [data]);

  const cancel = () => {
    setEditingKey('');
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        const key = item[columns[0].dataIndex];
        onChange({
          tierId,
          ...(idsMapping.keysToIds[key] && { agreement_key_id: idsMapping.keysToIds[key] }),
          ...(idsMapping.valueIds[key] && { id: idsMapping.valueIds[key] }),
          value: newData.filter((itm) => key === itm[columns[0].dataIndex]),
        });
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const inputData = useMemo(
    () => [
      ...(details.children
        ? details.children.map((child) =>
            child.input_details.type === 'select'
              ? {
                  ...child,
                  input_details: {
                    ...child.input_details,
                    options: child.children.map((item) => ({ label: item.label, value: item.key })),
                    defaultValue: (() => {
                      const val = child.children.find((item) => item.key.toLowerCase().includes('default'));
                      setTieredValues((prev) => ({ ...prev, [child.key]: [val.key] }));
                      return [val.key];
                    })(),
                  },
                  children: null,
                }
              : child
          )
        : []),
      ...(pricingType !== 'flat'
        ? [
            {
              key: 'lowerLimit',
              label: 'Lower limit',
              input_details: {
                type: 'number',
              },
            },
            {
              key: 'upperLimit',
              label: 'Upper limit',
              input_details: {
                type: 'number',
              },
            },
          ]
        : []),
      {
        key: 'commissionType',
        label: 'Comission type',
        input_details: {
          type: 'select',
          options: [
            {
              label: 'Percentage',
              value: 'percentage',
            },
            {
              label: 'Fixed',
              value: 'fixed',
            },
          ],
        },
      },
      {
        key: 'value',
        label: 'Value',
        input_details: {
          type: 'number',
        },
      },
    ],
    [pricingType]
  );

  const columns = [
    ...(details.children?.map((child) => ({
      title: child.label,
      dataIndex: child.key,
      editable: false,
      render: (text) =>
        inputData.find((item) => item.key === child.key).input_details.options.find((itm) => itm.value === text)?.label,
      width: '20%',
      rowSpan: (record, index) => {
        let indexesCovered = 0;
        const indexMapping = Object.values(sortedDataObj).reduce((result:any, curr :any) => {
          const data = {
            ...result,
            [indexesCovered]: curr.length,
          };
          indexesCovered += curr.length;
          return data;
        }, {});
        return indexMapping[index] || 0;
      },
    })) || []),

    ...(pricingType !== 'flat'
      ? [
          {
            title: 'Lower limit',
            dataIndex: 'lowerLimit',

            editable: true,
          },
          {
            title: 'Upper limit',
            dataIndex: 'upperLimit',
            render: (text) => `${+text === Number.MAX_SAFE_INTEGER ? '' : text}`,
            editable: true,
          },
        ]
      : []),
    {
      title: 'Comission type',
      dataIndex: 'commissionType',
      render: (text) =>
        inputData.find((itm) => itm.key === 'commissionType').input_details.options.find((itm) => itm.value === text)
          ?.label,
      editable: true,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      editable: true,
    },
    ...(!disableAll
      ? [
          {
            title: 'Actions',
            dataIndex: 'operation',
            render: (_, record) => {
              const editable = isEditing(record);
              return editable ? (
                <>
                  <Row>
                    <Col className='mr-10' span={4}>
                      <Tooltip
                        color='white'
                        overlayClassName='inter font-semibold text-black text-sm lh-18'
                        overlayInnerStyle={{
                          color: '#000000',
                        }}
                        title={'Save'}
                      >
                        <span className='cursor-pointer' onClick={() => save(record.key)}>
                          <CheckOutlined className='text-black hover:text-red-400' />
                        </span>
                      </Tooltip>
                    </Col>
                    <Popconfirm title='Sure to cancel?' onConfirm={cancel}>
                      <a>
                        <Tooltip
                          color='white'
                          overlayClassName='inter font-semibold text-black text-sm lh-18'
                          overlayInnerStyle={{
                            color: '#000000',
                          }}
                          title={'Cancel'}
                        >
                          <CloseOutlined className='text-black hover:text-red-400' />
                        </Tooltip>
                      </a>
                    </Popconfirm>
                  </Row>
                </>
              ) : (
                <Row>
                  <Col span={4}>
                    <Tooltip
                      color='white'
                      overlayClassName='inter font-semibold text-black text-sm lh-18'
                      overlayInnerStyle={{
                        color: '#000000',
                      }}
                      title={'Edit'}
                    >
                      <span className='cursor-pointer' onClick={() => edit(record)}>
                        <EditOutlined className='text-black hover:text-blue-400' />
                      </span>
                    </Tooltip>
                  </Col>
                  <Popconfirm
                    title='Sure to delete?'
                    onConfirm={() => {
                      const filteredRecords = data.filter((itm) => JSON.stringify(itm) !== JSON.stringify(record));
                      setData(filteredRecords);
                      const key = record[columns[0].dataIndex];
                      onChange({
                        tierId,
                        ...(idsMapping.keysToIds[key] && { agreement_key_id: idsMapping.keysToIds[key] }),
                        ...(idsMapping.valueIds[key] && { id: idsMapping.valueIds[key] }),
                        value: filteredRecords.filter((itm) => key === itm[columns[0].dataIndex]),
                      });
                    }}
                  >
                    <a>
                      <Tooltip
                        color='white'
                        overlayClassName='inter font-semibold text-black text-sm lh-18'
                        overlayInnerStyle={{
                          color: '#000000',
                        }}
                        title={'Delete'}
                      >
                        <DeleteOutlined className='text-black hover:text-red-400' />
                      </Tooltip>
                    </a>
                  </Popconfirm>
                </Row>
              );
            },
          },
        ]
      : []),
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record, i) => {
        return {
          record,
          inputType: JSON.parse(JSON.stringify(inputData.find((item) => item.key === col.dataIndex) || {})),
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          rowSpan: col.rowSpan?.(record, i),
        };
      },
    };
  });
  const addButtonFunction = () => {
    const arrayKey = !['commissionType', 'lowerLimit'].includes(columns[0].dataIndex) ? columns[0].dataIndex : null;

    if (arrayKey && !tieredValues[arrayKey]) return message.warning(`${columns[0].title} can't be empty`);

    const isValid =
      pricingType !== 'flat'
        ? arrayKey
          ? tieredValues[arrayKey].reduce(
              (result, curr) => (!result.status ? result : validateTiered(sortedDataObj[curr], tieredValues)),
              { status: true }
            )
          : validateTiered(data, tieredValues)
        : validateFlat(data, tieredValues);
    const tempData = {
      ...tieredValues,
      ...(pricingType !== 'flat' && !tieredValues.upperLimit && { upperLimit: Number.MAX_SAFE_INTEGER }),
    };
    if (!isValid.status) return message.warning(isValid.message);

    const newKeys = [];
    setData((prev) =>
      [
        ...prev,
        ...(arrayKey
          ? tieredValues[arrayKey].map((val, index) => {
              newKeys.push(data.length + 1 + index);
              return {
                key: data.length + 1 + index,
                ...tempData,
                [arrayKey]: val,
              };
            })
          : [{ key: data.length + 1, ...tempData }]),
      ].sort((a, b) => a[arrayKey]?.localeCompare(b[arrayKey]))
    );
    tempData[arrayKey].forEach((key) => {
      onChange({
        tierId,
        ...(idsMapping.keysToIds[key] && { agreement_key_id: idsMapping.keysToIds[key] }),
        ...(idsMapping.valueIds[key] && { id: idsMapping.valueIds[key] }),
        value: [...(sortedDataObj[key] || []), { ...tempData, pricingType }],
      });
    });
    setTieredValues({
      commissionType: 'percentage',
      ...(arrayKey ? { [arrayKey]: inputData[0].input_details.defaultValue } : {}),
    });
    showWarning && setShowWarning(false);
    setNewRowKey(newKeys.length ? newKeys : [data.length + 1]);
    showWarning &&
      setTempData({
        flat: [],
        gmv: [],
        order_value: [],
      });
    setTimeout(() => {
      setNewRowKey?.([]);
    }, 2000);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Row className='flex items-center' gutter={15}>
            <Col className='mb-2'>
              <span className='lh-24'>{details.label}:</span>
            </Col>
            <RenderInput
              getValue={() => pricingType}
              onChange={(input, val) => {
                setTempData((prev) => ({ ...prev, [pricingType]: JSON.parse(JSON.stringify(data)) }));
                setPricingType(val);
                setData(tempData[val]);
                setShowWarning(!!data.length);
              }}
              disableAll={disableAll}
              data={[
                {
                  key: details.key,
                  input_details: {
                    type: 'select',
                    options: [
                      {
                        label: 'Flat',
                        value: 'flat',
                      },
                      {
                        label: 'Conditional',
                        options: [
                          {
                            label: 'GMV',
                            value: 'gmv',
                          },
                          {
                            label: 'Order value',
                            value: 'order_value',
                          },
                        ],
                      },
                    ],
                  },
                },
              ]}
            />
          </Row>
        </Col>
        <Row className='w-full items-end' gutter={16}>
          <RenderInput
            data={inputData}
            onChange={(item, val) => setTieredValues((prev) => ({ ...prev, [item.key]: val }))}
            getValue={(item) => tieredValues[item.key] || ''}
            disableAll={disableAll}
          />
          {tabularData && !disableAll && (
            <Col className='flex pb-2 justify-center'>
              {showWarning ? (
                <Popconfirm
                  title={
                    <>
                      Data for{' '}
                      <span className='capitalize'>
                        {Object.keys(tempData)
                          .filter((key) => !!tempData[key].length)
                          .reduce(
                            (result, curr, i, array) =>
                              `${result}${
                                tempData[curr].length
                                  ? `${!i ? ' ' : i === array.length - 1 ? ' and ' : ', '}${curr.replace('_', ' ')}`
                                  : ''
                              }`,
                            ''
                          )}
                      </span>{' '}
                      will be removed. Sure to proceed?
                    </>
                  }
                  onConfirm={addButtonFunction}
                >
                  <Button type='primary'>Add</Button>
                </Popconfirm>
              ) : (
                <Button type='primary' onClick={addButtonFunction}>
                  Add
                </Button>
              )}
            </Col>
          )}
        </Row>
      </Row>
      {tabularData && (
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={data}
            columns={mergedColumns}
            className='mb-25'
            rowClassName={(record) =>
              `${newRowKey.includes(record.key) ? 'animate-bg-complete' : ''} editable-row inter`
            }
            pagination={null}
          />
        </Form>
      )}
    </>
  );
};
export default TieredControl;
