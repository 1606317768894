import React, { useState } from 'react';
import { Button, Drawer } from 'gokwik-ui-kit'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents';
import { getMerchantDetails, getUserDetails } from '@store/user/selectors';
import { useSelector } from 'react-redux';

const CodPlusCheckoutDetails = ({codPlusCheckoutDetailsVisible, setCodPlusCheckoutDetailsVisible}) => {
    const userDetails = useSelector(getUserDetails)
    const merchantDetails = useSelector(getMerchantDetails)

    const closeDetailsDrawer = () => {
        logEvent(
            'know_more_closed',
            'click',
            'get_started',
            userDetails?.email,
            merchantDetails?.m_id,
            merchantDetails?.short_name,
            userDetails?.name,
            { plan: 'cod_checkout' }
        )
        setCodPlusCheckoutDetailsVisible(false);
    }

    const detailsSections = [
        {
            title: "What is Checkout + COD?",
            description:
                "Checkout + COD is an integrated solution combining Cash on Delivery (COD) with enhanced checkout features to streamline the purchase experience and boost conversion rates. Key features include:",
            points: [
                "Address Prefill: Automatically fills customer addresses, reducing entry time and errors.",
                "Prepaid Discounts & Partial Payment Options: Encourages prepaid orders and allows partial COD payments to improve order completion.",
                "Bank Offers & Incentives: Provides exclusive discounts for prepaid payments to increase conversions.",
                "Risk Management Tools: Reduces COD fraud with intelligent verification, increasing successful order rates.",
            ],
        },
        {
            title: "Why Choose Checkout + COD?",
            points: [
                "Higher Conversion Rates: A seamless checkout experience that leads to increased completed orders.",
                "Balanced Payment Mix: Boosts prepaid orders, helping merchants manage COD and prepaid more effectively.",
                "Reduced Abandonment: Features like address prefill and COD verification minimize cart drop-offs and encourage more completed purchases.",
                "Enhanced Customer Trust: Creates a smooth and secure checkout, leading to better customer satisfaction and loyalty.",
            ],
        },
        {
            title: "Impact on Your Business:",
            points: [
                "+20-25% Prepaid Conversions: Prepaid incentives drive more customers to complete purchases prepaid.",
                "+15% Order Completion: Fewer abandoned carts and streamlined checkout flow increase order success.",
                "Improved COD Reliability: Secure COD options reduce order rejections, enhancing cash flow and delivery success.",
            ],
        },
    ]

    return (
        <Drawer
            open={codPlusCheckoutDetailsVisible}
            footer={
                <div style={{ padding: '0.5rem 0rem' }}>
                    <Button onClick={closeDetailsDrawer} variant="primary">
                        Got It
                    </Button>
                </div>
            }
            width={550}
            closable={false}
            onClose={closeDetailsDrawer}
            style={{
                borderTopLeftRadius: '16px',
                borderBottomLeftRadius: '16px',
            }}
        >
            <div className='mt-5'>
                {detailsSections?.map((section, index) => (
                    <div key={index} className="mb-8">
                        <div className="text-[16px] mb-3 font-semibold">{section?.title}</div>
                        {section?.description && (
                            <p className="text-[14px] font-normal" style={{ color: 'rgba(0, 0, 0, 0.65)', lineHeight: '22px' }} >{section.description}</p>
                        )}
                        <ul className="text-[14px] font-normal w-full flex flex-col gap-1" style={{ paddingLeft: '1.2rem', color: 'rgba(0, 0, 0, 0.65)' }} >
                            {section?.points?.map((point, i) => (
                                <li key={i}>{point}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </Drawer>
    )
};

export default CodPlusCheckoutDetails;
