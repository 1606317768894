import { navigateToUrl } from 'single-spa'
import React, { useState, useEffect, useMemo, useCallback } from 'react'

import backArrow from '@library/images/icons/backArrow.svg'

// Import Images
import logoGokwik from '@library/images/common/logo-gokwik-business-suit.svg'
import shieldGreen from '@library/images/icons/shield.svg'
import shieldRed from '@library/images/icons/shield-cross.svg'

// Import Styles
// import './signup.css';

// Import Utilities
import { makeAPICall } from '@gokwik/utilities'
import { Button, Col, Row, Input, message } from 'gokwik-ui-kit'
import PasswordIcon from '@library/images/icons/passwordIcon'
import CompanyIcon from '@library/images/icons/companyIcon'
import UserIcon from '@library/images/icons/userIcon'
import { useDispatch, useSelector } from 'react-redux'
import { getSignUpEmail } from '@store/user/selectors'
import LoginLayout from '@library/components/loginLayout'
import EmailIcon from '@library/images/icons/emailIcon'
import { sendVerifyEmailOTP } from '@store/user'
import { useAppDispatch } from '@library/utilities/hooks'
import { useNavigate } from 'react-router-dom'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

function validateEmail(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

const ForgotPassword = () => {
    const [formData, setFormData] = useState({
        email: '',
    })
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const callBack = (err?: any) => {
        if (!err) {
            navigate('/verify-otp', { state: { signIn: false } })
        } else {
            message.error(err.errorMessage)
        }
    }

    const verifyEmail = (e) => {
        e.preventDefault()
        if (validateEmail(formData.email)) {
            dispatch(sendVerifyEmailOTP(formData, callBack, false))
        } else {
            message.error('Invalid Email')
            return
        }
    }

    useEffect(() => {
        if (formData?.email && formData?.email?.includes('gokwik.co')) {
            logEvent('login_page_password_reset_landed', 'click', 'login', formData?.email)
        } else {
            logEvent('login_page_password_reset_landed', 'click', 'login', localStorage.getItem('email'))
        }
    }, [formData.email])

    return (
        <LoginLayout
            titleContent={
                <>
                    {' '}
                    <p className='text-3xl text-[#060322] font-normal leading-normal inter tracking-tight'>
                        Password Assistance
                    </p>
                    <svg xmlns='http://www.w3.org/2000/svg' width='398' height='2' viewBox='0 0 398 2' fill='none'>
                        <path d='M1 1L397 0.999965' stroke='#828282' stroke-linecap='round' />
                    </svg>
                    <p className='font-normal text-normal'>
                        {' '}
                        Enter the email address associated with your GoKwik account
                    </p>
                </>
            }
        >
            <Row className=' rounded-lg inter   flex flex-col align-middle justify-center'>
                <div>
                    <form className='w-full flex flex-col gap-y-6 '>
                        <div className='flex flex-col gap-y-3'>
                            <h4 className='body text-[#002547] text-sm !leading-[22px] font-medium'>
                                Enter Your Company Email Address
                            </h4>
                            <Input
                                prefix={<EmailIcon />}
                                value={formData.email}
                                className='email-input gk-text-input'
                                onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))}
                                placeholder='example@email.com'
                                type='email'
                                name='email'
                                autoComplete='email'
                            />
                        </div>
                        <Button
                            variant={'primary'}
                            htmlType='submit'
                            className='next'
                            onClick={verifyEmail}
                            disabled={formData?.email ? false : true}
                        >
                            Request OTP
                        </Button>
                    </form>
                </div>
            </Row>
        </LoginLayout>
    )
}

export default ForgotPassword
