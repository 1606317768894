import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Import Components
import { Header, Loader, SideNav } from '@library/components'

// Import styles
import '@library/styles/styles.css'
// import './styles.css';

import 'gokwik-ui-kit/dist/esm/bundle.css'
import '@library/styles/global.css'
import { Provider } from 'react-redux'
import store from './store'
import './main.css'
import { dashboardStore, updateBreadcrumbs } from '@gokwik/utilities'
import { HomeOutlined } from '@ant-design/icons'
import { fetchUserDetails } from '@store/user'
import Routes from './routes'
import { useAppDispatch } from '@library/utilities/hooks'
import { getCookie } from '@gokwik/utilities'

const InitializeStore = ({ showOnAuth }) => {
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (showOnAuth) {
            dispatch(fetchUserDetails())
        }
    }, [])
    return <></>
}

const searchParams = new URL(window.location.href).searchParams
const token = searchParams.get('t')

if (token && localStorage.getItem('token') !== token) {
    // localStorage.setItem('token', token) //to be removed later
} else if (token && getCookie('token') && getCookie('token') !== token) {
    document.cookie = `token=${token}; path=/; secure; samesite=strict`
}

const trackingId = searchParams.get('request_id')
if (trackingId) {
    sessionStorage.setItem('tracking_id', trackingId)
}

const getSideNavWidth = () => document.getElementById('side-nav')?.getBoundingClientRect()?.width

interface RootProps {
    // Define any props required by the Root component
}

const Root: React.FC<RootProps> = (props) => {
    const location = useLocation()

    const [isMenuCollapsed, setIsCollapsedMenu] = useState(false)
    const appContainerRef = useRef<HTMLDivElement>()
    const headerRef = useRef<HTMLDivElement>()
    const [globalStoreChanged, setGlobalStoreChanged] = useState(false)

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            {
                key: 'home',
                href: '/',
                icon: HomeOutlined,
            },
            ...(prev?.filter((itm) => itm) || []),
        ])
        const unsubscribe = dashboardStore.subscribe((state) => {
            setGlobalStoreChanged((prev) => !prev)
        })
        return () => {
            unsubscribe()
        }
    }, [])
    const adjustHeaderRef = useCallback(() => {
        if (!headerRef.current) return
        const sideNavWidth = getSideNavWidth()
        const calcWidth = `calc(100vw - ${sideNavWidth}px)`
        headerRef.current.style.width = calcWidth
        headerRef.current.style.maxWidth = calcWidth
        headerRef.current.style.left = `${sideNavWidth}px`
    }, [headerRef.current])
    const adjustAppContainer = () => {
        const appContainer = document.querySelector<HTMLElement>('.app-container')
        if (!appContainer) return
        const sideNavWidth = getSideNavWidth()
        const calcWidth = `calc(100vw - ${sideNavWidth}px)`
        appContainer.style.maxWidth = calcWidth
        appContainer.style.width = calcWidth
    }

    const showOnAuth = useMemo(
        () =>
            ![
                'signup',
                'login',
                'forgot-password',
                'change-password',
                'verify-otp',
                'verify-merchant',
                'set-password',
                'existing-merchant',
                '/invitation/',
            ].reduce((result, curr) => result || location.pathname.includes(curr), false),
        [location.pathname],
    )
    useEffect(() => {
        showOnAuth &&
            setTimeout(() => {
                adjustAppContainer()
                adjustHeaderRef()
            }, 100)
    }, [location.pathname])

    useEffect(() => {
        if (showOnAuth) {
            adjustAppContainer()
            adjustHeaderRef()
        }
    }, [isMenuCollapsed])

    const toggleMenuCollapse = () => {
        setIsCollapsedMenu((prev) => !prev)
    }

    return (
        <Provider store={store}>
            <div className='relative inter flex bg-[#003c7138]' style={{ zIndex: '9' }}>
                <Loader />
                <InitializeStore showOnAuth={showOnAuth} />
                {/* Render SideNav and Header components except on the login page */}
                {showOnAuth && (
                    <SideNav
                        key={`${globalStoreChanged}`}
                        isMenuCollapsed={isMenuCollapsed}
                        toggleMenuCollapse={toggleMenuCollapse}
                        setIsCollapsedMenu={setIsCollapsedMenu}
                    />
                )}
                {showOnAuth && <Header headerRef={headerRef} />}
                <div className={`flex flex-col w-full relative `}>
                    <Routes globalStoreChanged={globalStoreChanged} />
                </div>
            </div>
        </Provider>
    )
}

export default Root
