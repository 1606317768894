import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getUserData } from '@store/user/selectors'
import {
    Card,
    Segmented,
    EllipsisOutlined,
    Skeleton,
    ArrowUpOutlined,
    ArrowDownOutlined,
    Chart,
    Button,
    NodeIndexOutlined,
    SyncOutlined,
    Alert,
} from 'gokwik-ui-kit'
import { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import GA4DataFetch from '@pages/integrations/google-analytics'
import { PlaceHolderImageForSessionsAnalytics } from '../utils'

const SessionAnalytics = ({ timeFrame }) => {
    const userData = useSelector(getUserData)

    const [data, setData] = useState({
        max_y_axis: 29,
        metrics: [
            {
                cart: 0,
                checkout: 0,
                endDate: '2025-02-27',
                id: 1,
                purchased: 0,
                sessions: 0,
                startDate: '2025-02-20',
                total: 0,
            },
            {
                cart: 0,
                checkout: 0,
                endDate: '2025-03-06',
                id: 2,
                purchased: 0,
                sessions: 0,
                startDate: '2025-02-27',
                total: 0,
            },
            {
                cart: 0,
                checkout: 0,
                endDate: '2025-03-13',
                id: 3,
                purchased: 0,
                sessions: 0,
                startDate: '2025-03-06',
                total: 0,
            },
        ],
        percentageChange: 45,
        total: 29,
    })
    const [loading, setLoading] = useState(false)
    const [apiURLPath, setapiURLPath] = useState('')
    const getSessionsData = async () => {
        try {
            let urlPath = APIEndPoints?.executiveSummary?.gaSessions
            setLoading(true)
            const response = await makeAPICall({
                method: 'get',
                url: process.env.REACT_APP_BASE_URL + urlPath,
                params: { date_range: `3 ${timeFrame + 's'}` },
                skipLoader: true,
            })

            setLoading(false)
            if (response?.status === 200) {
                setData(response?.data?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getSessionsData()
    }, [timeFrame, apiURLPath])

    const sortedMetrics = data?.metrics?.sort((a, b) => a.id - b.id)

    const formatValue = (value) => {
        if (value < 1000) return value.toFixed(2)
        if (value < 100000) return `${(value / 1000).toFixed(1)}K`
        if (value < 10000000) return `${(value / 100000).toFixed(1)}L`
        return `${(value / 10000000).toFixed(1)}Cr`
    }

    const options = {
        chart: {
            type: 'bar',
            stacked: false,
            toolbar: { show: false },
            zoom: {
                enabled: false,
            },
        },

        xaxis: {
            categories: sortedMetrics.map(
                (metric) =>
                    `${dayjs(metric.startDate).format("D MMM'YY")} - ${dayjs(metric.endDate).format("D MMM'YY")}`,
            ),
        },
        yaxis: [
            {
                show: true,
                title: {
                    text: '',
                },
                min: 0,
                max: data?.max_y_axis,
                ...(data?.max_y_axis <= 100 ? { tickAmount: 5 } : { tickAmount: 4 }),
                labels: {
                    formatter: (val) => formatValue(val),
                },
            },
        ],
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                return formatValue(val)
            },
        },
        legend: {
            position: 'bottom',
        },
        colors: ['#004B8D', '#006ED2', '#2DA8FF', '#B6DEFF'],
        plotOptions: {
            bar: {
                ColumnHeight: '100%',
                columnWidth: '94%',
                borderRadius: 4,
                borderRadiusApplication: 'end',
                dataLabels: {
                    position: 'center',
                },
            },
        },

        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            followCursor: true,
            // y: {},
        },
    }

    const series = [
        {
            name: 'Session',
            type: 'column',
            data: sortedMetrics.map((metric) => metric.sessions),
        },
        {
            name: 'Cart',
            type: 'column',
            data: sortedMetrics.map((metric) => metric.cart),
        },
        {
            name: 'Checkout',
            type: 'column',
            data: sortedMetrics.map((metric) => metric.checkout),
        },
        {
            name: 'Purchase',
            type: 'column',
            data: sortedMetrics.map((metric) => metric.purchased),
        },
    ]

    const getTopData = useMemo(() => {
        return {
            lastSessionData: data?.total,
            percentChange: data?.percentageChange,
        }
    }, [data])

    return (
        <div>
            <Card
                title={
                    <div className='flex justify-between items-center w-[85%]'>
                        <div className='flex-none'>Session Performance</div>

                        <div className='flex-grow flex justify-center'>
                            <Segmented
                                options={['Google Analytics']}
                                onChange={(value: string) => {
                                    // logEvent(
                                    //     'es_analytics_gmv_orders_filter_applied',
                                    //     'click',
                                    //     'executive_summary',
                                    //     userData?.userDetails?.email,
                                    //     userData?.merchantDetails?.m_id,
                                    //     userData?.merchantDetails?.short_name,
                                    //     userData?.userDetails?.name,
                                    //     { filterApplied: value },
                                    // )
                                    // setapiURLPath(value.toLowerCase())
                                }}
                            />
                        </div>
                    </div>
                }
                extra={<EllipsisOutlined className='rotate-90' />}
            >
                {loading ? (
                    <Skeleton loading={loading} active avatar />
                ) : (
                    <div>
                        {data?.total === 0 ? (
                            <div className='relative'>
                                <div className='pointer-events-none'>
                                    <PlaceHolderImageForSessionsAnalytics />
                                </div>
                                <div className='absolute inset-0 flex flex-col items-center justify-center backdrop-blur-lg'>
                                    <GA4DataFetch />
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className={`${data?.total === 0 ? 'blur-sm pointer-events-none' : ''}`}>
                                    <div>
                                        <span className='text-2xl font-semibold'>
                                            {formatValue(getTopData?.lastSessionData)}
                                        </span>
                                        {'   '}
                                        {getTopData?.percentChange > 0 ? (
                                            <span className='text-green-600 text-base font-semibold'>
                                                {getTopData?.percentChange}% <ArrowUpOutlined />
                                            </span>
                                        ) : getTopData?.percentChange < 0 ? (
                                            <span className='text-red-600 text-base font-semibold'>
                                                {getTopData?.percentChange}% <ArrowDownOutlined />
                                            </span>
                                        ) : (
                                            <>{getTopData?.percentChange}%</>
                                        )}
                                        <br />
                                    </div>

                                    <div>
                                        <Chart
                                            //@ts-ignore
                                            options={options}
                                            series={series}
                                            type='bar'
                                            height={300}
                                        />
                                    </div>
                                </div>
                                <div className='text-[#98A2B3] text-xs float-end'>Last 3 {timeFrame}s</div>
                            </>
                        )}
                    </div>
                )}
            </Card>
        </div>
    )
}

export default SessionAnalytics
