import { Header } from '@library/components'
import { Col, Flex, Layout, Row } from 'gokwik-ui-kit'
import Banner from './Banner'
import WhatsNew from './WhatsNew'
import Charts from './Charts'
import { useEffect, useMemo, useRef, useState } from 'react'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import { getUserData } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { newLaunchesArray } from './utils'

const ExecutiveSummary = () => {
    const userData = useSelector(getUserData)
    const chartsRef = useRef<HTMLDivElement>(null)
    const [chartComponentHieght, setChartsComponentHieght] = useState(null)
    const [allCampaigns, setAllCampaigns] = useState(null)

    // async function getCampaignById(id) {
    //     if (!id) return
    //     try {
    //         let response = await makeAPICall({
    //             method: 'get',
    //             url: process.env.REACT_APP_BASE_URL + APIEndPoints.marketingCampaigns.campaignById(id),
    //         })

    //         if (response?.status === 200) {
    //             const campaignData = response?.data?.data
    //             setAllCampaigns((prev) => [...prev, campaignData])
    //         }
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    const getEligibleCampaigns = async () => {
        const response = await makeAPICall({
            method: 'get',
            url: process.env.REACT_APP_BASE_URL + '/api/dashboard/campaign/eligible-campaigns',
        })

        if (response?.status === 200) {
            const campaignMap = response?.data?.data
            setAllCampaigns(campaignMap)
        }
    }
    useEffect(() => {
        logEvent(
            'exec_summary_landed',
            'visit',
            'executive_summary',
            userData?.userDetails?.email,
            userData?.merchantDetails?.m_id,
            userData?.merchantDetails?.short_name,
            userData?.userDetails?.name,
        )
        if (chartsRef.current) {
            const height = chartsRef.current.offsetHeight
            setChartsComponentHieght(height.toFixed(0))
        }
        getEligibleCampaigns()
    }, [])

    const recordInteraction = async (interaction_type, campaign_id) => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + '/api/dashboard/campaign/user-interaction',
            payload: {
                interaction_type,
                campaign_id: Number(campaign_id),
            },
        })

        console.log(response, 'post')
    }

    return (
        <Row gutter={12} className='w-full'>
            <Col span={17}>
                <Row>
                    {allCampaigns?.smallBanner?.length === 1 && (
                        <Col span={24}>
                            <Banner bannerContent={allCampaigns?.smallBanner[0]} />
                        </Col>
                    )}
                </Row>

                <Row>
                    <Col span={24}>
                        <div ref={chartsRef}>
                            <Charts />
                        </div>
                    </Col>
                </Row>
            </Col>
            {allCampaigns?.recommendedBanners?.length >= 1 ? (
                <Col span={7} className='-mt-4'>
                    <WhatsNew
                        chartComponentHieght={chartComponentHieght}
                        recommendedCampaigns={allCampaigns?.recommendedBanners}
                        recordInteraction={recordInteraction}
                    />
                </Col>
            ) : (
                <Col span={7} className='-mt-4'>
                    <WhatsNew
                        chartComponentHieght={chartComponentHieght}
                        recommendedCampaigns={newLaunchesArray}
                        recordInteraction={recordInteraction}
                    />
                </Col>
            )}
        </Row>
    )
}

export default ExecutiveSummary
