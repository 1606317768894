import {
    Button,
    Col,
    ColumnProps,
    DatePicker,
    DeleteOutlined,
    Drawer,
    Form,
    Input,
    InputNumber,
    message,
    PlusCircleOutlined,
    Popover,
    Row,
    Select,
    Space,
    Steps,
    Table,
    Tag,
    TextArea,
    Tooltip,
} from 'gokwik-ui-kit'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { getExperimentsPayload } from '@library/utilities/helpers/helper'

export default function EditAndCreateDrawer({
    isModalOpen,
    setModalOpen,
    action,
    experiment,
    setExperiment,
    merchantList,
    createExperiment,
    variantsList,
    setVariantList,
    variantData,
    setVariantData,
    exposure,
    setExposure,
    updateExperiment,
}) {
    const [error, setError] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [current, setCurrent] = useState(0)
    const [inputValue, setInputValue] = useState('')

    const next = () => {
        setCurrent(current + 1)
    }

    const prev = () => {
        setCurrent(current - 1)
    }

    const resetFormData = () => {
        setExposure(0)
        setVariantList([])
        setExperiment({
            name: '',
            description: '',
            start_date: '',
            end_date: '',
            is_active: '',
            merchants: [],
            exposure_percentage: '',
        })
    }

    const { experiment_id, name, description, start_date, end_date, is_active, merchants, exposure_percentage } =
        experiment

    const addVariant = () => {
        const { name, description, variant_percentage } = variantData

        if (name.length < 1 || description.length < 1 || variant_percentage < 1 || variant_percentage > 100) {
            setError(true)
            setIsSubmitting(true)
            return
        }

        const remainingExposure = 100 - exposure

        if (variant_percentage > remainingExposure) {
            setError(true)
            setIsSubmitting(true)
            return
        }

        setVariantList((prev) => [...prev, { ...variantData }])
        setExposure((prev) => prev + variant_percentage)

        form.resetFields()
        setVariantData({ name: '', description: '', variant_percentage: 0, ip_addresses: [] })
        setInputValue('')
        setError(false)
        setIsSubmitting(false)
    }
    const columns: ColumnProps<any>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (text) => (
                <Tooltip title={text} color='#fff' overlayInnerStyle={{ color: '#000' }}>
                    <span className='truncate itm.name-ellipsis max-w-20 overflow-hidden whitespace-nowrap inline-block'>
                        {text}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',

            render: (text) => (
                <Tooltip title={text} placement='topLeft' color='#fff' overlayInnerStyle={{ color: '#000' }}>
                    <p>
                        <span className='text-blue fs12'>View</span>
                    </p>
                </Tooltip>
            ),
        },
        {
            title: 'Value',
            dataIndex: 'variant_percentage',
            render: (text) => <span>{text}</span>,
        },
        {
            title: 'IPs',
            dataIndex: 'ip_addresses',
            render: (value) => <>{renderIps(value)}</>,
        },
        {
            title: 'Action',
            dataIndex: 'action',
            render: (_, record, index) => (
                <Tooltip title={'Delete Variant'} color='#fff' overlayInnerStyle={{ color: '#000' }}>
                    <DeleteOutlined className='cursor-pointer' onClick={() => removeVariant(index)} />
                </Tooltip>
            ),
        },
    ]

    const handleSubmit = async () => {
        setIsSubmitting(true)

        if (exposure !== 100) {
            message.error('Total exposure must be exactly 100%')
            setError(true)
            setIsSubmitting(false)
            return
        }

        if (action === 'create_exp') {
            try {
                await createExperiment(getExperimentsPayload({ ...experiment, variantsList }))
                setModalOpen(false)
                message.success('Experiment created successfully')
            } catch (error) {
                message.error('Failed to create experiment')
            } finally {
                setIsSubmitting(false)
                resetFormData()
            }
        } else {
            try {
                await updateExperiment(experiment_id, getExperimentsPayload({ ...experiment, variantsList }))
                setModalOpen(false)
                message.success('Experiment updated successfully')
            } catch (error) {
                message.error('Failed to update experiment')
            } finally {
                setIsSubmitting(false)
                resetFormData()
            }
        }
    }

    const handleMerchantChange = (selectedMerchants) => {
        if (selectedMerchants.includes('all')) {
            setExperiment((prev) => ({
                ...prev,
                merchants: ['all'],
            }))
        } else {
            setExperiment((prev) => ({
                ...prev,
                merchants: selectedMerchants,
            }))
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo)
    }

    const removeVariant = (index) => {
        setVariantList((prevList) => {
            let tempList = [...prevList]

            if (tempList[index]) {
                setExposure(exposure - tempList[index].variant_percentage)
                tempList.splice(index, 1)
            }

            return tempList
        })
    }

    const handleStartDateChange = (date) => {
        setExperiment((prev) => ({
            ...prev,
            start_date: date ? dayjs(date) : null,
        }))
    }

    const handleEndDateChange = (date) => {
        setExperiment((prev) => ({
            ...prev,
            end_date: date ? dayjs(date) : null,
        }))
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value)
    }

    const handleInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            const trimmedValue = inputValue.trim()

            if (isValidIP(trimmedValue)) {
                if (!variantData.ip_addresses.includes(trimmedValue)) {
                    setVariantData((prev) => ({ ...prev, ip_addresses: [...variantData.ip_addresses, trimmedValue] }))
                    setInputValue('')
                } else {
                    message.warning('IP address already added')
                }
            } else {
                message.error('Invalid IP address')
            }
        }
    }

    const handleTagClose = (ipToRemove) => {
        setVariantData((prev) => ({
            ...prev,
            ip_addresses: variantData.ip_addresses.filter((ip) => ip !== ipToRemove),
        }))
    }

    const handleFirstSubmit = () => {
        next()
    }

    const isValidIP = (ip) => {
        const ipRegex =
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
        return ipRegex.test(ip)
    }

    const [form] = Form.useForm()

    const steps = [
        {
            title: 'Step 1',
            description: <span className='text-xs'>Add experiment details</span>,
        },
        {
            title: 'Step 2',
            description: <span className='text-xs'>Add variant details</span>,
        },
    ]

    useEffect(() => {
        form.setFieldsValue({
            name,
            description,
            start_date: start_date ? dayjs(start_date) : null,
            end_date: end_date ? dayjs(end_date) : null,
            exposure_percentage: exposure_percentage,
            merchants,
            status: is_active ? 'active' : 'disabled',
        })
    }, [experiment, form])

    const renderIps = (ips) => {
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {ips.length < 1 ? (
                    <></>
                ) : (
                    <Popover
                        placement='topLeft'
                        content={ips?.map((each) => (
                            <p> {each} </p>
                        ))}
                    >
                        <p>
                            <span className='text-blue fs12'>View</span>
                        </p>
                    </Popover>
                )}
            </div>
        )
    }

    const items = steps.map((item) => ({ key: item.title, title: item.title, description: item.description }))

    return (
        <Drawer
            title={action === 'create_exp' ? 'Create Experiment' : 'Update Experiment'}
            placement='right'
            onClose={() => {
                setModalOpen(false)
                resetFormData()
            }}
            open={isModalOpen}
            width={500}
        >
            <Space direction='vertical' className='w-full'>
                <Steps current={current} items={items} className='mb-2' />
                {current === 0 ? (
                    <Form
                        layout='vertical'
                        className='h-full w-full'
                        onFinish={handleFirstSubmit}
                        onFinishFailed={onFinishFailed}
                        form={form}
                    >
                        <Row>
                            <Col className='flex flex-col gap-y-4' sm={24}>
                                <Form.Item
                                    name='name'
                                    label={'Name'}
                                    className='m-0'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter experiment name',
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (!value || value.trim() === '') {
                                                    return Promise.reject('Name cannot be empty')
                                                }
                                                return Promise.resolve()
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        type='text'
                                        value={name}
                                        placeholder='Name'
                                        onChange={(e) => setExperiment((prev) => ({ ...prev, name: e.target.value }))}
                                        allowClear
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={'Description'}
                                    name='description'
                                    className='m-0'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter valid description',
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (!value || value.trim() === '') {
                                                    return Promise.reject('Description cannot be empty')
                                                }
                                                return Promise.resolve()
                                            },
                                        },
                                    ]}
                                >
                                    <TextArea
                                        value={description}
                                        placeholder='Description'
                                        onChange={(e) =>
                                            setExperiment((prev) => ({ ...prev, description: e.target.value }))
                                        }
                                        allowClear
                                    />
                                </Form.Item>
                                <div className='flex gap-2 w-full my-[-20px]'>
                                    <Form.Item
                                        label='Start Date'
                                        name='start_date'
                                        className='mt-5 w-[50%]'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter start date',
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            placeholder={'Start Date'}
                                            value={start_date}
                                            onChange={handleStartDateChange}
                                            disabledDate={(current) => {
                                                return current && current < dayjs().startOf('day')
                                            }}
                                            allowClear={false}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label='End Date'
                                        name={'end_date'}
                                        className='mt-5 w-[50%]'
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter end date',
                                            },
                                        ]}
                                    >
                                        <DatePicker
                                            placeholder={'End Date'}
                                            value={end_date}
                                            onChange={handleEndDateChange}
                                            disabledDate={(current) => {
                                                return form.getFieldValue('start_date')
                                                    ? current <= form.getFieldValue('start_date').startOf('day')
                                                    : current <= dayjs().startOf('day')
                                            }}
                                            allowClear={false}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </div>

                                <Form.Item
                                    label='Exposure percentage'
                                    name='exposure_percentage'
                                    className='mb-0'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a valid exposure',
                                        },
                                        {
                                            type: 'number',
                                            min: 1,
                                            max: 100,
                                            message: 'Exposure must be between 1 and 100',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        type='number'
                                        placeholder='Value (%)'
                                        value={exposure_percentage || null}
                                        onChange={(value) =>
                                            setExperiment((prev) => ({
                                                ...prev,
                                                exposure_percentage: value !== undefined ? value : null,
                                            }))
                                        }
                                        className='w-full'
                                        step={1}
                                        onKeyDown={(e) => {
                                            if (
                                                !/[0-9]/.test(e.key) &&
                                                e.key !== 'Backspace' &&
                                                e.key !== 'ArrowLeft' &&
                                                e.key !== 'ArrowRight' &&
                                                e.key !== 'Delete'
                                            ) {
                                                e.preventDefault()
                                            }
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label={'Merchants'}
                                    name={'merchants'}
                                    className='mb-2'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select merchants',
                                        },
                                    ]}
                                >
                                    <Select
                                        mode='multiple'
                                        className={'w-full'}
                                        showSearch={true}
                                        value={merchants}
                                        placeholder='Select merchants'
                                        onChange={handleMerchantChange}
                                        filterOption={(input, option) => {
                                            return (option?.name as string)?.toLowerCase().includes(input.toLowerCase())
                                        }}
                                        maxTagCount='responsive'
                                        allowClear
                                    >
                                        {merchantList?.map((merchant) => (
                                            <Select.Option
                                                className={'flex gap-x-2 items-center'}
                                                key={merchant.id}
                                                value={merchant.m_id}
                                                name={merchant.short_name}
                                                disabled={merchants?.includes('all')}
                                            >
                                                <div className='flex items-center'>
                                                    <span className='text-gray-900 inter font-medium capitalize truncate'>
                                                        {merchant.short_name}
                                                    </span>
                                                </div>
                                            </Select.Option>
                                        ))}
                                        <Select.Option
                                            className={'flex gap-x-2 items-center'}
                                            key={'all'}
                                            value={'all'}
                                            name={'All'}
                                        >
                                            <div className='flex items-center'>
                                                <span className='text-gray-900 inter font-medium capitalize truncate'>
                                                    {'All'}
                                                </span>
                                            </div>
                                        </Select.Option>
                                    </Select>
                                </Form.Item>

                                <div className='flex gap-2 w-full'>
                                    <Button
                                        onClick={async () => {
                                            try {
                                                await form.validateFields()
                                                setCurrent(current + 1)
                                            } catch (error) {
                                                console.log('Validation failed:', error)
                                            }
                                        }}
                                        variant='primary'
                                        className='mt-5 w-[50%]'
                                    >
                                        Next
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setModalOpen(false)
                                            resetFormData()
                                        }}
                                        variant='default'
                                        className='mt-5 w-[50%]'
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    <Form
                        layout='vertical'
                        className='h-full w-full '
                        onFinish={handleSubmit}
                        onFinishFailed={onFinishFailed}
                        form={form}
                    >
                        {exposure !== 100 && (
                            <div className='mt-3 rounded-lg w-full'>
                                <Form.Item
                                    name='variant-name'
                                    className='mb-2'
                                    label={'Variant Name'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter varinat name',
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (!value || value.trim() === '') {
                                                    return Promise.reject('Variant name cannot be empty')
                                                }
                                                return Promise.resolve()
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder='Name'
                                        onChange={(e) => {
                                            setVariantData((prev) => ({ ...prev, name: e.target.value }))
                                        }}
                                        allowClear
                                    />
                                </Form.Item>

                                <Form.Item
                                    name='variant-desc'
                                    className='mb-2'
                                    label={'Variant Description'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter description',
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (!value || value.trim() === '') {
                                                    return Promise.reject('Description cannot be empty')
                                                }
                                                return Promise.resolve()
                                            },
                                        },
                                    ]}
                                >
                                    <TextArea
                                        placeholder='Description'
                                        onChange={(e) => {
                                            setVariantData((prev) => ({
                                                ...prev,
                                                description: e.target.value,
                                            }))
                                        }}
                                        allowClear
                                    />
                                </Form.Item>

                                <Form.Item
                                    name='weightage'
                                    className='mb-2'
                                    label={'Variant Percentage'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a value',
                                        },
                                        {
                                            type: 'number',
                                            min: 1,
                                            max: 100,
                                            message: 'Value must be between 1 and 100',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        type='number'
                                        placeholder='Value (%)'
                                        onChange={(value) => {
                                            setVariantData((prev) => ({
                                                ...prev,
                                                variant_percentage: Number(value),
                                            }))
                                        }}
                                        className='w-full'
                                        step={1}
                                        onKeyDown={(e) => {
                                            if (
                                                !/[0-9]/.test(e.key) && // Only allow numbers
                                                e.key !== 'Backspace' && // Allow backspace
                                                e.key !== 'ArrowLeft' && // Allow left arrow for navigation
                                                e.key !== 'ArrowRight' && // Allow right arrow for navigation
                                                e.key !== 'Delete' // Allow delete
                                            ) {
                                                e.preventDefault() // Prevent other inputs
                                            }
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item name='ip-addresses' className='mb-2' label={'IP Addresses'}>
                                    <Input
                                        value={inputValue}
                                        onChange={handleInputChange}
                                        onKeyDown={handleInputKeyDown}
                                        placeholder='Enter IP address and press Enter'
                                    />
                                    <div>
                                        {variantData.ip_addresses.map((ip) => (
                                            <Tag
                                                key={ip}
                                                closable
                                                onClose={() => handleTagClose(ip)}
                                                style={{ margin: '4px' }}
                                            >
                                                {ip}
                                            </Tag>
                                        ))}
                                    </div>
                                </Form.Item>
                                <div className='flex justify-end'>
                                    <Button
                                        onClick={async () => {
                                            try {
                                                await form.validateFields()
                                                addVariant()
                                            } catch (error) {
                                                console.log('Validation failed:', error)
                                            }
                                        }}
                                        variant='primary'
                                    >
                                        <PlusCircleOutlined />
                                        Add Variant
                                    </Button>
                                </div>

                                {isSubmitting && error && (
                                    <p className='text-xs flex items-center justify-center mt-4 text-red-600 mb-1'>
                                        {exposure >= 100
                                            ? 'Total exposure is already 100%'
                                            : 'Invalid exposure percentage or exceeding allowed limit'}
                                    </p>
                                )}
                            </div>
                        )}
                        {variantsList.length > 0 && (
                            <Table
                                columns={columns}
                                dataSource={variantsList || []}
                                pagination={false}
                                bordered
                                className='mt-4'
                            />
                        )}
                        <div className='flex gap-2 w-full'>
                            <Button
                                onClick={async () => {
                                    try {
                                        handleSubmit()
                                    } catch (error) {
                                        console.log('Validation failed:', error)
                                    }
                                }}
                                variant='primary'
                                className='mt-5 w-[50%]'
                            >
                                Submit
                            </Button>
                            <Button
                                onClick={() => {
                                    prev()
                                }}
                                variant='default'
                                className='mt-5 w-[50%]'
                            >
                                Back
                            </Button>
                        </div>
                    </Form>
                )}
            </Space>
        </Drawer>
    )
}
