import {
    Table,
    ColumnsType,
    Button,
    Row,
    Col,
    Breadcrumb,
    Popconfirm,
    DatePicker,
    Modal,
    Tooltip,
    EyeOutlined,
    FileAddOutlined,
    FormOutlined,
    BackwardFilled,
    ArrowLeftOutlined,
    message,
} from 'gokwik-ui-kit'
// import AgreementForm from './agreement/agreementForm';
import { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { makeAPICall, updateBreadcrumbs, validatePermission } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { getMerchantDetails, getUserDetails } from '@store/user/selectors'
import AgreementForm from './agrrementForm'
import { PermissionValues } from '@library/utilities/constants/constants'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'

const RateCapture = ({}) => {
    const [openForm, setOpenForm] = useState('')
    const [mode, setMode] = useState(null)
    const [agreementId, setAgreementId] = useState(null)
    const [addendumId, setAddendumId] = useState(null)
    const [showDownloadModal, setShowDownloadModal] = useState(false)
    const [data, setData] = useState([])
    const merchantDetails = useSelector(getMerchantDetails)
    const userDetails = useSelector(getUserDetails)
    const [pricingReports, setOpenPricingReport] = useState(false)
    const [reportDateRange, setReportDateRange]: any = useState({
        end_date: '',
        start_date: '',
    })

    const columns = [
        {
            title: 'Agreement / Addendum',
            dataIndex: 'file_name',
            key: 'name',
            render: (text, record) => (
                <p
                    onClick={() => {
                        window.open(record.signed_url, '_blank')
                    }}
                    className='cursor-pointer hover:text-blue-400'
                >
                    {text}
                </p>
            ),
        },
        {
            title: 'Products Purchased',
            dataIndex: 'products_enabled',
            key: 'products_enabled',
            render: (text, record) => (
                <p>
                    {record.products_enabled?.map((itm, i, array) => (
                        <span key={itm} className='mr-1'>
                            {itm}
                            {array.length - 1 !== i ? ',' : ''}
                        </span>
                    ))}
                </p>
            ),
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            render: (text, record) => <p>{dayjs(text).format('DD/MM/YYYY')}</p>,
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'endDate',
            render: (text, record) => <p>{dayjs(text).format('DD/MM/YYYY')}</p>,
            sorter: (a, b) => dayjs(a.end_date).diff(b.end_date),
        },
        {
            title: 'Created By',
            dataIndex: 'created_by',
            key: 'created_by',
        },
        {
            title: 'Updated By',
            dataIndex: 'updated_by',
            key: 'updated_by',
        },
        {
            title: 'Action',
            key: 'action',
            width: '15%',
            render: (text, record) => (
                <Row className='text-black'>
                    <Col span={'5'}>
                        <Tooltip
                            color='white'
                            overlayClassName='inter font-semibold text-black text-sm lh-18'
                            overlayInnerStyle={{
                                color: '#000000',
                            }}
                            title={'View'}
                        >
                            <span
                                className='cursor-pointer'
                                onClick={() => {
                                    setMode('view')
                                    setOpenForm(record.isAddendum ? 'View addendum' : 'View agreement')
                                    setAgreementId(record.isAddendum ? record.parent_id : record.id)
                                    record.isAddendum && setAddendumId(record.id)
                                }}
                            >
                                <EyeOutlined className='text-black hover:text-blue-300' />
                            </span>
                        </Tooltip>
                    </Col>
                    {validatePermission(
                        record.status === 'confirmed'
                            ? PermissionValues.rate_capture.edit_confirmed
                            : PermissionValues.rate_capture.edit,
                    ) && (
                        <Col span={'5'} offset={4}>
                            <Tooltip
                                color='white'
                                overlayClassName='inter font-semibold text-black text-sm lh-18'
                                overlayInnerStyle={{
                                    color: '#000000',
                                }}
                                title={'Edit'}
                            >
                                <span
                                    className=' cursor-pointer'
                                    onClick={() => {
                                        setMode('edit')
                                        setOpenForm(record.isAddendum ? 'Edit addendum' : 'Edit agreement')
                                        setAgreementId(record.isAddendum ? record.parent_id : record.id)
                                        record.isAddendum && setAddendumId(record.id)
                                    }}
                                >
                                    <FormOutlined className='text-black hover:text-blue-300' />
                                </span>
                            </Tooltip>
                        </Col>
                    )}
                    {record.type !== 'addendum' && validatePermission(PermissionValues.rate_capture.edit) && (
                        <Col span={'5'} offset={4}>
                            <Tooltip
                                color='white'
                                overlayClassName='inter font-semibold text-black text-sm lh-18'
                                overlayInnerStyle={{
                                    color: '#000000',
                                }}
                                title={'Add addendum'}
                            >
                                <span
                                    className='cursor-pointer'
                                    onClick={() => {
                                        setOpenForm('Add addendum')
                                        setAgreementId(record.id)
                                    }}
                                >
                                    <FileAddOutlined className='text-black hover:text-blue-300' />
                                </span>
                            </Tooltip>
                        </Col>
                    )}
                </Row>
            ),
        },
    ]

    useEffect(() => {
        logEvent('admin_rate_capture_clicked', 'click', 'Admin Rate Capture', userDetails?.email, merchantDetails?.m_id, merchantDetails?.short_name, userDetails?.name);
    }, [])

    const saveAgreement = async () => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.rateCapture.agreement,
            payload: {
                id: agreementId,
                status: 'confirmed',
            },
        })
        if (response.success) {
            setAgreementId('')
            setAddendumId('')
            setOpenForm(null)
            setMode('')
        }
    }

    const fetchAgreements = useCallback(async () => {
        const response = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.rateCapture.agreement,
            method: 'get',
            params: {
                merchant_id: merchantDetails?.m_id,
            },
        })
        if (response.success) {
            const addendums = response.data.data.reduce(
                (result, itm) => ({
                    ...result,
                    [itm.parent_id]: [...(result[itm.parent_id] || []), { ...itm, key: itm.id, isAddendum: true }],
                }),
                {},
            )

            setData(
                response.data.data
                    ?.reduce(
                        (result, curr) => [
                            ...result,
                            ...(!curr.parent_id ? [{ ...curr, children: addendums[curr.id], key: curr.id }] : []),
                        ],
                        [],
                    )
                    //   @ts-ignore
                    .sort((a, b) => new Date(b.end_date) - new Date(a.end_date)),
            )
        }
    }, [merchantDetails.id])

    useEffect(() => {
        !openForm && fetchAgreements()
    }, [openForm])

    useEffect(() => {
        updateBreadcrumbs((prev) => [
            prev[0],
            {
                key: 'rateCapture',
                href: '/rateCapture',
                text: 'Rate Capture',
            },
        ])
    }, [])

    const handleOk = () => {
        fetchReport()
    }

    const handleCancel = () => {
        setOpenPricingReport(false)
    }

    const fetchReport = async () => {
        if (!reportDateRange.start_date || !reportDateRange.end_date) return message.error('Date range not valid')
        const start_date = dayjs(reportDateRange.start_date).format('YYYY-MM-DD')
        const end_date = dayjs(reportDateRange.end_date).format('YYYY-MM-DD')
        const response = await makeAPICall({
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.billing.pricingReport,
            method: 'get',
            params: {
                start_date,
                end_date,
            },
            responseType:"blob"
        })
        setOpenPricingReport(false)
        setReportDateRange({
            end_date: '',
            start_date: '',
        })
        if (response.success) {
            const href = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = href
            link.setAttribute('download', `merchant_pricing-${start_date}-${end_date}.xlsx`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            window.URL.revokeObjectURL(href)
        }
    }

    return (
        <div className='w-full'>
            <Row className='flex  mb-2'>
                {openForm && (
                    <Row className='w-full bg-white rounded-lg p-3 py-3 flex items-center border-solid border-gray-200 border-[1px] '>
                        <Col span={20}>
                            <p
                                className='text-base text-gray-500 cursor-pointer'
                                onClick={() => {
                                    setOpenForm(null)
                                    setMode(null)
                                    setAgreementId(null)
                                    setAddendumId(null)
                                }}
                            >
                                <ArrowLeftOutlined /> Agreements
                            </p>
                        </Col>
                        {openForm && mode !== 'view' && (
                            <Col span={4} className='flex justify-end'>
                                <Popconfirm
                                    title='No changes in agreement will be allowed post confirmation?'
                                    onConfirm={() => {
                                        saveAgreement()
                                    }}
                                >
                                    <Button type='primary'>Confirm</Button>
                                </Popconfirm>
                            </Col>
                        )}
                    </Row>
                )}
            </Row>
            {openForm ? (
                <AgreementForm
                    mode={mode}
                    merchantId={merchantDetails.m_id}
                    id={agreementId}
                    pageAddendumId={addendumId}
                    setPageAgreementId={setAgreementId}
                    setPageAddendumId={setAgreementId}
                    type={openForm.includes('addendum') ? 'addendum' : null}
                />
            ) : (
                <>
                    {validatePermission(PermissionValues.rate_capture.edit) && (
                        <Row className='w-full bg-white rounded-lg p-3 py-3 flex items-center border-solid border-gray-200 border-[1px]'>
                            <Col className='flex justify-end mr-2'>
                                <Button type='primary' onClick={() => setOpenForm('Add agreement')}>
                                    <span className='mr-1 text-sm'> + </span>
                                    Add agreement
                                </Button>
                            </Col>
                            {validatePermission(PermissionValues.billing_reports.view) && (
                                <Col>
                                    <Button variant='default' onClick={() => setOpenPricingReport(true)}>
                                        Pricing Report
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    )}

                    <div className='mt-3 bg-white rounded overflow-clip'>
                        <Table
                            className='w-full'
                            columns={columns}
                            dataSource={data || []}
                            pagination={{
                                position: ['topLeft', 'bottomLeft'],
                                showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} Records`,
                            }}
                        />
                    </div>
                </>
            )}
            <Modal
                open={pricingReports}
                title=''
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key='back' onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key='submit' type='primary' onClick={handleOk}>
                        Download
                    </Button>,
                ]}
            >
                <div className='my-4'>
                    <p className='text-xl font-semibold '>Pricing Report</p>
                    <p className='text-base my-2'>Download report of pricing for specific date range</p>
                </div>
                <DatePicker.RangePicker
                    className='w-full my-2'
                    onChange={(val) => {
                        setReportDateRange((prev) => ({ ...prev, end_date: val[1], start_date: val[0] }))
                    }}
                    format={'DD/MM/YYYY'}
                />
            </Modal>
        </div>
    )
}

export default RateCapture
