import {
    Button,
    Col,
    Collapse,
    Divider,
    DownOutlined,
    EyeOutlined,
    Form,
    Input,
    InputNumber,
    Row,
    Upload,
    UploadOutlined,
} from 'gokwik-ui-kit'
import { bankDetailsFormConfig } from './config'
import {
    CollapsePanelStatusChips,
    customRequest,
    getFormStatusConfigs,
    getKycDoc,
    getPreviewImageForDoc,
    handleFieldChange,
    kycFieldsMap,
    UploadedFileComponent,
    UploadSuccessSVG,
} from '../../../utils'
import { useEffect, useState } from 'react'
import { getUserData, getUserDetails } from '@store/user/selectors'
import { useSelector } from 'react-redux'
import { makeAPICall } from '@gokwik/utilities'
import APIEndPoints from '@library/utilities/constants/apiEndpoints'
import { fetchUserDetails } from '@store/user'
import { useAppDispatch } from '@library/utilities/hooks'
import { stepConfiguration } from '@pages/onboarding/stepsConfig'
import { logEvent } from '@library/utilities/userLogsEvents/userLogEvents'
import DocumentPreview from '../../components/DocumentPreview'
import { DEFAULT_FORMAT_REQUIREMENTS } from '../../constants'

const BankDetails = ({
    kycData,
    onFinish,
    setCurrentStep,
    currentStep,
    isPanelClickable,
    undeterminedKycErrorState,
}) => {
    const [form] = Form.useForm()
    const [bankProofUpload, setBankProofUploaded] = useState(false)
    const userData = useSelector(getUserData)
    const dispatch = useAppDispatch()
    const [bank_details_fields, set_bank_details_fields] = useState(bankDetailsFormConfig?.items)
    const [errors, setError] = useState([])
    const [showPreview, setShowPreview] = useState(false)
    const [previewDocs, setPreviewDocs] = useState([])
    const current_onboarding_status = userData?.merchantDetails?.onboarding_status?.kwik_checkout_app

    useEffect(() => {
        const getStatus = getFormStatusConfigs(userData, bank_details_fields, 'bank_details')
        setError(getStatus?.generateErrorChips)
        set_bank_details_fields(getStatus?.updateConfigWithErrors)
        setBankProofUploaded(userData?.kycData?.bank_proof_uploaded)
    }, [userData])

    const customRequestHandler = async (options, item) => {
        await customRequest(options, item, form, setBankProofUploaded, null)
    }

    const deleteFile = async (name) => {
        const response = await makeAPICall({
            method: 'post',
            url: process.env.REACT_APP_BASE_URL + APIEndPoints.deleteKycDocument,
            payload: {
                file_name: name,
            },
        })

        if (response?.status === 200) {
            setBankProofUploaded(false)
        }
    }

    const handleDelete = (item) => {
        deleteFile('bank_proof')
        // Clear the validation error
        form.setFields([
            {
                name: item.name,
                errors: [],
            },
        ])
    }

    const isFieldEnabled = (fieldName) => {
        if (current_onboarding_status === 'kyc_verification_failed') {
            if (
                Object.keys(userData?.kycData?.kyc_failure_reasons)?.includes(fieldName) ||
                undeterminedKycErrorState ||
                undeterminedKycErrorState
            ) {
                return false //dont-disable-the-field
            } else {
                return true //yes-disable-the-field
            }
        } else if (
            current_onboarding_status === 'kyc_verification_pending' ||
            current_onboarding_status === 'kyc_completed'
        ) {
            return true //yes-disable-the-field
        }
    }

    const items = [
        {
            key: '1',
            label: (
                <>
                    <div className='flex flex-col gap-1'>
                        <div className='text-[#004B8D] text-xs font-semibold'>Step 3</div>

                        <div className='flex gap-4 items-center'>
                            <div className={`text-xl font-semibold ${errors?.length && 'text-red-700'}`}>
                                Bank Details
                            </div>{' '}
                            <CollapsePanelStatusChips
                                errors={errors}
                                stepConfiguration={stepConfiguration}
                                current_onboarding_status={
                                    userData?.merchantDetails?.onboarding_status?.kwik_checkout_app
                                }
                                sectionName={'bank_details'}
                            />
                        </div>
                    </div>
                </>
            ),
            children: (
                <Form
                    form={form}
                    initialValues={kycData || {}}
                    scrollToFirstError={{ behavior: 'auto', block: 'center' }}
                    onFinish={(v) => {
                        logEvent(
                            'bank_details_save_continue_clicked',
                            'click',
                            'kyc',
                            userData?.userDetails?.email,
                            userData?.merchantDetails?.m_id,
                        )
                        onFinish(v, 2)
                    }}
                    layout='vertical'
                    onFieldsChange={(changedField, allFields) =>
                        handleFieldChange(changedField, bank_details_fields, set_bank_details_fields)
                    }
                >
                    <style>
                        {`
                            .custom-label-styles label {
                                width: 100%
                            }
                        `}
                    </style>
                    <Row gutter={16}>
                        {bank_details_fields?.map((item, index) => (
                            <Col span={item?.span} key={index} className={'custom-label-styles'}>
                                <Form.Item
                                    {...(item?.name !== 'bank_proof' && { label: item.label })}
                                    name={item.name}
                                    rules={item.type === 'upload' && bankProofUpload ? [] : item.rules}
                                    {...((item as any)?.validateStatus && {
                                        validateStatus: (item as any)?.validateStatus,
                                    })}
                                    {...((item as any)?.help && {
                                        help: (item as any)?.help,
                                    })}
                                    {...(item?.type === 'upload' && { valuePropName: 'fileList' })}
                                >
                                    {item.type === 'input' && (
                                        <Input
                                            placeholder={item.placeholder}
                                            disabled={isFieldEnabled(item?.name)}
                                            className={item?.class}
                                        />
                                    )}
                                    {item.type === 'inputNumber' && (
                                        <InputNumber
                                            placeholder={item.placeholder}
                                            disabled={isFieldEnabled(item?.name)}
                                        />
                                    )}
                                </Form.Item>
                                {item.type === 'upload' && (
                                    <>
                                        <div className='mb-4 flex justify-between'>
                                            <label htmlFor=''>{item?.label}</label>
                                            <div
                                                className='px-2 text-sm border-solid border-gray-300 rounded-md cursor-pointer min-w-max'
                                                onClick={() => {
                                                    setShowPreview(true)
                                                    setPreviewDocs([
                                                        { label: 'Cancelled cheque', value: 'cancelled_cheque' },
                                                    ])
                                                }}
                                            >
                                                <EyeOutlined />
                                                <span className='ml-2'>View Sample Document</span>
                                            </div>
                                        </div>
                                        <Row
                                            gutter={24}
                                            className='border border-solid border-[#D0D5DA] rounded-md !mx-auto py-2 mt-auto'
                                        >
                                            <Col
                                                span={10}
                                                className={`flex flex-col align-middle justify-center items-start`}
                                            >
                                                {bankProofUpload ? (
                                                    <UploadedFileComponent
                                                        item={item}
                                                        handleDelete={handleDelete}
                                                        itemName={'bank_proof'}
                                                        text={'Cancelled Cheque'}
                                                        disableDeleteBtn={isFieldEnabled(item?.name)}
                                                        loadingAI={null}
                                                        inputImageName={null}
                                                        ocrResult={null}
                                                    />
                                                ) : (
                                                    <div className='ml-6'>
                                                        <div className='text-base font-semibold'>
                                                            Front Side of Cancelled Cheque
                                                        </div>
                                                        <div className='text-xs text-[#004B8D] mt-1'>
                                                            Accepted Format: Accepted Format: .jpg, .jpeg, .png or .pdf
                                                            <br />
                                                            Max size: 5 MB
                                                        </div>
                                                        <Upload
                                                            accept='.jpg, .jpeg, .png, .pdf'
                                                            customRequest={(options) =>
                                                                customRequestHandler(options, {
                                                                    ...item,
                                                                    upload_url: kycData.bank_proof_upload_url,
                                                                    field: item.name,
                                                                }).then(() => {
                                                                    form.setFields([
                                                                        {
                                                                            name: item.name,
                                                                            errors: [],
                                                                        },
                                                                    ])
                                                                })
                                                            }
                                                            method='PUT'
                                                            name={item.name}
                                                            listType='text'
                                                            maxCount={1}
                                                        >
                                                            <Button
                                                                variant='primary'
                                                                className='my-4 text-sm font-normal inter rounded-md shadow-none'
                                                            >
                                                                <UploadOutlined /> Upload Document
                                                            </Button>
                                                        </Upload>
                                                    </div>
                                                )}
                                            </Col>
                                            <Col span={8} className=''>
                                                <div className='bg-[#F8F8F8] rounded-md p-1 min-h-40 align-middle justify-center items-center'>
                                                    <img
                                                        src={getPreviewImageForDoc(item.name)}
                                                        className='flex m-auto mt-6'
                                                    />
                                                </div>
                                            </Col>
                                            <Col span={6} className='flex flex-col align-top justify-start items-start'>
                                                <div className='inter text-xs font-normal text-[#000000A6] mr-4 ml-[-20px]'>
                                                    <ol>
                                                        <li> {DEFAULT_FORMAT_REQUIREMENTS}</li>
                                                    </ol>
                                                </div>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </Col>
                        ))}
                    </Row>

                    <Divider className='my-4' />
                    <Form.Item className='flex justify-end mb-0'>
                        <Button
                            variant='primary'
                            htmlType='submit'
                            disabled={
                                (errors?.length === 0 &&
                                    current_onboarding_status === 'kyc_verification_failed' &&
                                    !userData?.kycData?.kyc_rejection_reason) ||
                                userData?.merchantDetails?.onboarding_status?.kwik_checkout_app ===
                                    'kyc_verification_pending' ||
                                current_onboarding_status === 'kyc_completed'
                            }
                        >
                            Save & Continue
                        </Button>
                    </Form.Item>
                </Form>
            ),
        },
    ]

    const onChange = (e) => {
        setCurrentStep(2)
    }

    return (
        <div className=''>
            <Collapse
                collapsible={isPanelClickable(2)}
                activeKey={currentStep === 2 && ['1']}
                className='bg-white'
                onChange={onChange}
                items={items}
                accordion
                expandIconPosition='right'
                expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
            />
            {showPreview && (
                <DocumentPreview
                    isOpen={showPreview}
                    setIsOpen={setShowPreview}
                    previewDocs={previewDocs}
                    setPreviewDocs={setPreviewDocs}
                />
            )}
        </div>
    )
}

export default BankDetails
