import { Tooltip, Divider, EditOutlined, DeleteOutlined, Col, Row, Button, Tag } from 'gokwik-ui-kit'
import discountCheck from '@library/images/icons/discount-check.svg'

const AddedShipping = ({
    listItems,
    onEdit,
    onDelete,
    handleNewShipping,
    showNewShipping,
    handleShippingSetupDone,
}) => {
    return (
        <>
            {showNewShipping && (
                <>
                    <img
                        style={{
                            display: 'block',
                            position: 'absolute',
                            WebkitUserSelect: 'none',
                            bottom: '36%',
                            left: '10%',
                            backgroundColor: 'none',
                        }}
                        src='https://s3-alpha-sig.figma.com/img/73db/2aa5/449ff2c1591fee4844f77047ecd9910c?Expires=1740960000&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=gh3o-xFibB2EuFlMH70vTeD3kPzFgU8tfRjwoOpgE4w-RmBXDP8nzTR68HyLXg9jdLYP-qDXWMb~thu51LU6SYYon6H65f395Wz4Ow327lNN5is6~ZCvIwEsa0gD~uWsDzghaHW-8qclnDlVzkKmy4oDz4Gshp7K~Jcc9IgXMzCXugd-ae91vgOHF8Qrhwe~64kpXoqa4gsteyXBatAUak2g5hTnRG9hq9rLFfqUH891DbdMDq4fKwljM8i3xu7pHGlpdV3wt0yiQ8uyQfz8Ve5AFAZfmoFduF6ZVVNBO54a0HuyVqa71O~HSnPbeyHO88RiHAgsU1EptF15gHlukQ__'
                        alt='Image'
                    />
                    <div className='flex flex-col items-center bg-[#F6FFED] p-10 mb-2'>
                        <img src={discountCheck} alt='' />
                        <p className='flex items-center font-semibold text-[#135200]'>Yay!</p>
                        <p className='flex items-center font-semibold'>New shipping method has been added</p>
                    </div>
                </>
            )}
            {listItems && listItems?.length > 0 && (
                <Row gutter={24} justify={'space-between'}>
                    <Col className=' rounded-lg' span={24}>
                        <div className={`max-h-[300px] h-[250px] overflow-y-auto ${showNewShipping ? '' : 'mt-6'}`}>
                            {listItems?.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`my-2 border border-solid border-[#00000026] bg-[#FFF] rounded-lg pt-3 pb-4 px-4 transition-all duration-200 ease-in-out list-item-class`}
                                    >
                                        <Row align={'middle'}>
                                            <Col className='w-full flex flex-col gap-2'>
                                                <div className='flex w-full justify-between'>
                                                    <div className='flex gap-2 items-center'>
                                                        <div className='text-lg font-semibold'>
                                                            {item?.shipping_name?.trim()}
                                                        </div>
                                                        <div className='flex items-center gap-2'>
                                                            {item?.paymentOption === 'all' ? (
                                                                <>
                                                                    <Tag
                                                                        variant={'default'}
                                                                        className='border border-solid border-[#1677FF] rounded flex items-center'
                                                                    >
                                                                        <span className='text-[#1677FF]'>COD</span>
                                                                    </Tag>
                                                                    <Tag
                                                                        variant={'default'}
                                                                        className='border border-solid border-[#1677FF] rounded flex items-center'
                                                                    >
                                                                        <span className='text-[#1677FF]'>Prepaid</span>
                                                                    </Tag>
                                                                </>
                                                            ) : (
                                                                item?.paymentOption && (
                                                                    <Tag
                                                                        variant={'default'}
                                                                        className='border border-solid border-[#1677FF] rounded flex items-center'
                                                                    >
                                                                        <span className='text-[#1677FF]'>
                                                                            {item?.paymentOption?.toUpperCase()}
                                                                        </span>
                                                                    </Tag>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='flex border opacity-60 border-solid border-gray-300 rounded-md p-1 cursor-pointer'>
                                                        <Tooltip title={'Edit'}>
                                                            <EditOutlined onClick={() => onEdit('edit', item.id)} />
                                                        </Tooltip>
                                                        <Divider type='vertical' />
                                                        <Tooltip title={'Delete'}>
                                                            <DeleteOutlined
                                                                onClick={() => onDelete('delete', item.id)}
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                                <div className='text-[#000] flex gap-1 flex-wrap items-center justify-between text-sm font-normal opacity-60'>
                                                    <div className='flex flex-col'>
                                                        <span>Shipping Price </span>
                                                        <span className='flex items-center font-semibold'>
                                                            {item?.shipping_charge}
                                                        </span>
                                                    </div>
                                                    <Divider type='vertical' />
                                                    <div className='flex flex-col'>
                                                        <span>Payment Method</span>
                                                        <span className='flex items-center font-semibold'>COD</span>
                                                    </div>
                                                    <Divider type='vertical' />
                                                    {item?.minWeight !== null && item?.minWeight >= 0 ? (
                                                        <div className='flex flex-col'>
                                                            <span>Min Max Order weight</span>
                                                            <span className='flex items-center font-semibold'>
                                                                {item?.minWeight > 0 && !item?.maxWeight
                                                                    ? `${item?.minWeight}kg and Above`
                                                                    : item?.minWeight >= 0 && item?.maxWeight
                                                                    ? `${item?.minWeight}kg - ${item?.maxWeight}kg`
                                                                    : '-'}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className='flex flex-col'>
                                                            <span>Min Max Order Price</span>
                                                            <span className='flex items-center font-semibold'>
                                                                {item?.minOrderPrice > 0 && !item?.maxOrderPrice
                                                                    ? `₹${item?.minOrderPrice} and Above`
                                                                    : item?.minOrderPrice >= 0 && item?.maxOrderPrice
                                                                    ? `₹${item?.minOrderPrice} - ₹${item?.maxOrderPrice}`
                                                                    : '-'}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='flex flex-col gap-2 pt-2'>
                            <Button variant='primary' onClick={() => handleShippingSetupDone()}>
                                Save & Continue
                            </Button>
                            <Button variant='default' onClick={() => handleNewShipping()}>
                                Add Another
                            </Button>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    )
}
export default AddedShipping
